import { PropTypes } from '@lyfta/components-ui'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { LeftSidebarComponent } from './LeftSidebar'
import { RightSidebarComponent } from './RightSidebar'
import { NoPointerEventsContainer, SidebarsContainer } from './styles'

const Sidebars = ({ loadLessons }) => {
  const location = useLocation()

  return (
    <NoPointerEventsContainer>
      <SidebarsContainer>
        <LeftSidebarComponent loadLessons={loadLessons} />
        {location.pathname !== '/lesson-selection' && <RightSidebarComponent />}
      </SidebarsContainer>
    </NoPointerEventsContainer>
  )
}

Sidebars.propTypes = {
  loadLessons: PropTypes.func.isRequired,
}

export default Sidebars
