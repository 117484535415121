import { api } from '@lyfta/components-config'
import { TransLink, getTranslation } from '@lyfta/components-i18n'
import { css, styled, themeGet } from '@lyfta/components-theme'
import { forEach, isEmpty } from 'lodash'
import debounce from 'lodash/debounce'
import React from 'react'
import Request from 'superagent'
import validate from 'validate.js'

import { Flex } from '../Components/Flex'
import { LinkContactUs } from '../Components/LinkContactUs'

const handleClick = () => {
  window.open('https://www.lyfta.com/contact', '_blank')
}

validate.validators.emailAsyncValidator = debounce((email, options) => {
  return new validate.Promise(async resolve => {
    if (isEmpty(email)) {
      return resolve()
    }

    const { userId } = options

    const attributes = {
      email,
    }

    if (userId) {
      attributes.id = userId
    }

    const request = Request.post(`${api.url}/user/validate_addresses`).send({
      _jsonapi: {
        data: {
          type: 'validateAddresses',
          attributes,
        },
      },
    })

    await request.then(({ text }) => {
      const { exists } = JSON.parse(text)
      if (exists) {
        return resolve({
          message: (
            <Flex color="roseRed">
              {getTranslation('errors.form.emailNotUnique')}
            </Flex>
          ),
        })
      }
      return resolve()
    })

    return resolve()
  })
}, 1000)

export const buildRequiredFieldsConstraints = fields => {
  const constraints = {}

  forEach(fields, field => {
    constraints[field] = {
      presence: {
        allowEmpty: false,
      },
    }
  })

  return constraints
}

export const emailConstraint = {
  email: {
    presence: true,
    email: {
      message: {
        message: (
          <Flex color="roseRed" fontSize="14px">
            {getTranslation('errors.form.emailInvalid')}
          </Flex>
        ),
      },
    },
  },
}

const ErrorWrapper = styled(Flex).attrs(() => ({
  mt: 1,
}))`
  background-color: ${props => props.legibleColor};
  border-radius: 2px !important;
  color: ${themeGet('colors.error_100')};
  flex-wrap: wrap;
  font-size: 16px;
  padding: ${props =>
    props.legibleColor === 'transparent' ? 0 : css`0 8px 0 8px`};
  width: fit-content;
`

export const combinedEmailOrganizationConstraint = formId => {
  const legibleColor =
    formId === 'input-teacher-proceed-sign-up'
      ? css`
          ${themeGet('colors.neutral_100')}
        `
      : 'transparent'
  return {
    email: {
      presence: true,
      email: {
        message: {
          message: (
            <ErrorWrapper legibleColor={legibleColor}>
              {getTranslation('errors.form.emailInvalid')}
            </ErrorWrapper>
          ),
        },
      },
      format: {
        pattern: /^((?!@hotmail\.|@yahoo\.|@gmail\.|@btinternet\.|@googlemail\.|@msn\.|@me\.|@live\.|@icloud\.|@cloud\.|@aol\.|@sky\.|@talk21\.|@outlook\.).)*$/,
        message: {
          message: (
            <ErrorWrapper legibleColor={legibleColor}>
              <TransLink
                components={{
                  Link: (
                    <LinkContactUs
                      backgroundColor={legibleColor}
                      color="error_100"
                      textDecoration="underline"
                      onClick={handleClick}
                    />
                  ),
                }}
                i18nKey="auth.contactUs2"
              />
            </ErrorWrapper>
          ),
        },
      },
    },
  }
}

export const emailUniqueConstraint = userId => ({
  email: {
    presence: true,
    email: {
      message: {
        message: (
          <Flex color="roseRed">
            {getTranslation('errors.form.emailInvalid')}
          </Flex>
        ),
      },
    },
    emailAsyncValidator: { userId },
  },
})

export const emailOrganizationUniqueConstraint = userId => ({
  email: {
    presence: true,
    email: {
      message: {
        message: (
          <Flex color="roseRed" fontSize="14px">
            {getTranslation('errors.form.emailInvalid')}
          </Flex>
        ),
      },
    },
    format: {
      pattern: /^((?!@hotmail\.|@yahoo\.|@gmail\.|@btinternet\.|@googlemail\.|@msn\.|@me\.|@live\.|@icloud\.|@cloud\.|@aol\.|@sky\.|@talk21\.|@outlook\.).)*$/,
      message: {
        message: (
          <Flex color="roseRed" fontSize="14px">
            {getTranslation('errors.form.emailOrganizationInvalid')}
          </Flex>
        ),
      },
    },
    emailAsyncValidator: { userId },
  },
})

export const heardAboutConstraint = {
  whereDidYouHearAboutUs: {
    presence: {
      allowEmpty: false,
      message: { message: 'errors.form.heardAbout' },
    },
  },
}

export const nameEnConstraint = {
  nameEn: {
    presence: {
      allowEmpty: false,
      message: '^Name cannot be blank',
    },
  },
}

export const nameFiConstraint = {
  nameFi: {
    presence: {
      allowEmpty: false,
      message: '^Nimi ei voi olla tyhjä',
    },
  },
}

const passwordMinimumLength = 6

export const passwordConstraint = {
  password: {
    presence: true,
    length: {
      minimum: passwordMinimumLength,
      message: `^${getTranslation('errors.form.passwordLength', {
        length: passwordMinimumLength,
      })}`,
    },
  },
}

export const confirmPasswordConstraint = {
  confirmPassword: {
    equality: 'password',
    presence: true,
  },
}

export const passwordConfirmationConstraint = {
  passwordConfirmation: {
    equality: {
      attribute: 'password',
      message: '^constraints.passwordConfirmation',
    },
    presence: true,
  },
}

export const startDateConstraint = {
  startDate: {
    presence: {
      allowEmpty: false,
    },
  },
}
export const meetingDurationConstraint = {
  duration: {
    presence: {
      allowEmpty: false,
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 15,
      lessThanOrEqualTo: 120,
    },
  },
}

export const firstNameConstraint = {
  firstName: {
    presence: {
      allowEmpty: false,
    },
  },
}
export const lastNameConstraint = {
  lastName: {
    presence: {
      allowEmpty: false,
    },
  },
}
export const enteredSchoolNameConstraint = {
  enteredSchoolName: {
    presence: {
      allowEmpty: false,
    },
  },
}

export const nameConstraint = {
  name: {
    presence: {
      allowEmpty: false,
    },
  },
}
export const nameCollectionConstraint = {
  name: {
    presence: true,
    length: {
      maximum: 50,
    },
  },
}

export const countryConstraint = {
  country: {
    presence: {
      allowEmpty: false,
    },
  },
}

export const classNameConstraint = {
  className: {
    presence: {
      allowEmpty: false,
    },
  },
}

export const descriptionConstraint = {
  description: {
    presence: {
      allowEmpty: false,
    },
  },
}

export const durationConstraint = {
  duration: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
    },
  },
}

export const filmDurationConstraint = {
  formFilmDuration: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      message: '^Duration must be a whole number greater than 0',
    },
  },
}

export const latitudeConstraint = {
  latitude: {
    numericality: true,
  },
}

export const longitudeConstraint = {
  longitude: {
    numericality: true,
  },
}

export const usernameConstraint = {
  username: {
    presence: {
      allowEmpty: false,
    },
  },
}

export const ageConstraint = {
  age: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      lessThan: 150,
    },
  },
}

export const ageFlexibleConstraint = {
  age: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      lessThan: 150,
    },
  },
}

export const teachersLimitConstraint = totalTeachers => {
  const tLimitConstraint = {
    teachersLimit: {
      presence: true,
      numericality(limitTeacher) {
        const lTeacher = Number(limitTeacher)

        if (lTeacher >= totalTeachers || !lTeacher) {
          return {
            onlyInteger: true,
            greaterThanOrEqualTo: 0,
          }
        }
        return {
          greaterThanOrEqualTo: 0,
          message: 'need to be 0 or higher that the current number of teachers',
        }
      },
    },
  }

  return tLimitConstraint
}

export const studentsLimitConstraint = totalStudents => {
  const sLimitConstraint = {
    studentsLimit: {
      presence: true,
      numericality(limitStudent) {
        const lStudent = Number(limitStudent)
        if (lStudent >= totalStudents || !lStudent) {
          return {
            onlyInteger: true,
            greaterThanOrEqualTo: 0,
          }
        }
        return {
          greaterThanOrEqualTo: 0,
          message: 'need to be 0 or higher that the current number of students',
        }
      },
    },
  }

  return sLimitConstraint
}
export const termsAndConditionsConstraint = {
  termsAndConditions: {
    presence: true,
    inclusion: {
      within: [true],
      message: 'you need to check the checkbox',
    },
  },
}
export const privacyPolicyConstraint = {
  privacyPolicy: {
    presence: true,
    inclusion: {
      within: [true],
      message: 'you need to check the checkbox',
    },
  },
}
export const freeTrialTermsConstraint = {
  freeTrialTerms: {
    presence: true,
    inclusion: {
      within: [true],
      message: 'you need to check the checkbox',
    },
  },
}
export const asyncValidator = (value, allValues, meta) => {
  const {
    data: { error: localError },
  } = meta
  return localError
}
export const setFieldData = ([fieldName, err], state) => {
  const { fields } = state
  const touched = !!err
  const field = fields[fieldName]
  field.error = err
  field.data.error = err
  field.touched = touched
}

export const fieldConstraint = field => ({
  [field]: {
    presence: {
      allowEmpty: false,
      message: { message: 'errors.form.fieldNoBlank' },
    },
  },
})

export const bodyTextConstraint = {
  bodyText: {
    presence: {
      allowEmpty: false,
    },
  },
}

export const campaignUrlConstraint = {
  campaignUrl: {
    presence: {
      allowEmpty: false,
    },
  },
}
