import { createReducer } from '../../helpers'
import {
  createDeleteReducerHandlers,
  createEntityFields,
  createReducerHandlers,
} from '../../utils'
import {
  ARCHIVE_CLASS,
  CREATE_CLASS,
  DELETE_CLASS,
  LOAD_CLASS,
  LOAD_CLASSES,
  UPDATE_CLASS,
} from '../Actions/classes'

const initialState = {
  ...createEntityFields('classes'),
}

const handlers = {
  ...createDeleteReducerHandlers('classes', DELETE_CLASS, {
    mapToKey: 'classes',
  }),
  ...createDeleteReducerHandlers('classes', ARCHIVE_CLASS, {
    mapToKey: 'classes',
  }),
  ...createReducerHandlers('classes', LOAD_CLASSES, {
    withReplace: true,
    mapToKey: 'classes',
  }),
  ...createReducerHandlers('classes', LOAD_CLASS, {
    mapToKey: 'currentClass',
    singular: true,
  }),
  ...createReducerHandlers('classes', CREATE_CLASS, {
    mapToKey: 'currentClass',
    singular: true,
  }),
  ...createReducerHandlers('classes', UPDATE_CLASS, {
    mapToKey: 'currentClass',
    singular: true,
  }),
}

export default createReducer(initialState, handlers)
