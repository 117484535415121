import * as React from 'react'

function SvgClose(props) {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.59 20L20 18.59L13.41 12L20 5.41L18.59 4L12 10.59L5.41 4L4 5.41L10.59 12L4 18.59L5.41 20L12 13.41L18.59 20Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgClose
