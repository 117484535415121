import { createAsyncAction } from '../../helpers'
import apiCall from '../../services/Api'

export const CREATE_MEETING_OCCURRENCE = createAsyncAction(
  'meetingOccurrences/CREATE_MEETING_OCCURRENCE',
)
export const UPDATE_MEETING_OCCURRENCE = createAsyncAction(
  'meetingOccurrences/UPDATE_MEETING_OCCURRENCE',
)
export const LOAD_MEETING_OCCURRENCE = createAsyncAction(
  'meetingOccurrences/LOAD_MEETING_OCCURRENCE',
)
export const LOAD_MEETING_OCCURRENCES = createAsyncAction(
  'meetingOccurrences/LOAD_MEETING_OCCURRENCES',
)

export const clearCurrent = () => ({ type: LOAD_MEETING_OCCURRENCE.CLEAR })

export const loadMeetingOccurrence = (id, { meetingId }) => {
  if (id === 'new') return { type: LOAD_MEETING_OCCURRENCE.CLEAR }

  return apiCall({
    method: 'GET',
    endpoint: `/meetings/${meetingId}/occurrences/${id}?include=host,meeting`,
    types: LOAD_MEETING_OCCURRENCE,
    payload: { id },
  })
}

export const loadMeetingOccurrences = ({
  meetingId,
  filter,
  page,
  include = 'host,meeting,attendees',
  sort = 'name',
  ...props
} = {}) =>
  apiCall({
    method: 'GET',
    endpoint: `/meetings/${meetingId}/occurrences`,
    query: {
      include,
      page,
      filter,
      sort,
    },
    types: LOAD_MEETING_OCCURRENCES,
    paged: true,
    payload: { page, filter, include, sort, ...props },
  })

export const createMeetingOccurrence = ({
  meetingId,
  ...meetingOccurrence
}) => {
  return apiCall({
    method: 'POST',
    endpoint: `/meetings/${meetingId}/occurrences?include=host,meeting`,
    types: CREATE_MEETING_OCCURRENCE,
    query: {
      data: {
        type: 'meetingOccurrences',
        attributes: {
          ...meetingOccurrence,
        },
      },
    },
  })
}

export const updateMeetingOccurrence = ({ meetingId, ...meetingOccurrence }) =>
  apiCall({
    method: 'PATCH',
    endpoint: `/meetings/${meetingId}/occurrences/${meetingOccurrence.id}?include=host,meeting`,
    types: UPDATE_MEETING_OCCURRENCE,
    query: {
      data: {
        type: 'meetingOccurrences',
        attributes: {
          ...meetingOccurrence,
        },
      },
    },
  })
