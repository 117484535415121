import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'
import { filter, find, findIndex, map, uniqBy } from 'lodash'

import {
  ADVANCE_ACTIVITY,
  ANIMATE_ACTIVITY,
  CLOSE_ACTIVITY,
  CLOSE_TASK,
  CLOSE_TASK_NAVIGATION,
  COMPLETE_ACTIVITY,
  HIDE_ACHIEVEMENT,
  LOAD_DATA,
  LOAD_LESSONS_DATA,
  OPEN_TASK,
  REQUEST_ADVANCE_ACTIVITY,
  RESET_CURRENT_LESSON,
  SELECT_ACTIVITY,
  SELECT_TASK,
  SET_IS_TEACHING_IN_FRONT_OF_CLASS,
  SHOW_ACHIEVEMENT,
  UPDATE_TASK_EXPERIENCES,
} from '../Actions/questTracker'

const initialState = {
  isTeachingInFrontOfClass: false,
  lessonId: null,
  currentActivityId: null,
  previousActivityId: null,
  taskId: null,
  lessonPlan: null,
  activities: [],
  tasks: [],
  showAchievement: false,
  lessonCompleted: false,
}

const handleLoad = (state, { lessonData }) =>
  state.merge({ ...state, ...lessonData })
const handleLoadLessons = (state, { lessonData }) =>
  state.merge({ ...lessonData })
const handleOpenTask = (state, { id }) =>
  state.merge({
    tasks: map(state.tasks, t =>
      t.id === id ? { ...t, open: true } : { ...t, open: false },
    ),
  })
const handleCloseTask = state =>
  state.merge({
    tasks: map(state.tasks, t => ({ ...t, open: false })),
  })
const handleCloseTaskNavigation = state =>
  state.merge({
    tasks: map(state.tasks, t => ({ ...t, open: false, selected: false })),
  })
const handleSelectTask = (state, { id, open = false }) =>
  state.merge({
    taskId: id,
    tasks: map(state.tasks, t =>
      t.id === id
        ? { ...t, selected: true, open }
        : { ...t, selected: false, open: false },
    ),
    activities: map(state.activities, t =>
      t.id === state.currentActivityId
        ? { ...t, shown: true, animated: false }
        : { ...t, shown: false, animated: false },
    ),
  })

const handleAnimateActivity = (state, { id }) => {
  let nextActivityId = id

  if (nextActivityId === undefined) {
    const currentActivityIndex = findIndex(state.activities, a => a.selected)
    if (currentActivityIndex < state.activities.length) {
      nextActivityId = state.activities[currentActivityIndex + 1]?.id
    }
  }

  return state.merge({
    activities: map(state.activities, t =>
      t.id === nextActivityId
        ? { ...t, animated: true }
        : { ...t, animated: false },
    ),
  })
}

const handleSelectActivity = (state, { id }) => {
  let nextActivityId = id

  if (nextActivityId === undefined) {
    const currentActivityIndex = findIndex(state.activities, a => a.selected)

    if (currentActivityIndex < state.activities.length) {
      nextActivityId = state.activities[currentActivityIndex + 1]?.id
    }
  }

  return state.merge({
    currentActivityId: nextActivityId,
    lessonCompleted: nextActivityId ? false : state.lessonCompleted,
    previousActivityId: find(state.activities, a => a.selected)?.id,
    tasks: map(state.tasks, t => ({ ...t, open: false, selected: false })),
    activities: map(state.activities, t =>
      t.id === nextActivityId
        ? { ...t, selected: true, shown: false }
        : { ...t, selected: false, shown: false },
    ),
  })
}

const handleRequestAdvanceActivity = state => {
  return state
}

const handleCloseActivity = state =>
  state.merge({
    activities: map(state.activities, t => ({ ...t, shown: true })),
  })
const handleCompleteActivity = (state, { id }) =>
  state.merge({
    activities: map(state.activities, a =>
      a.id === id ? { ...a, complete: true } : a,
    ),
  })

const handleShowAchievement = state =>
  state.merge({
    showAchievement: true,
    lessonCompleted: true,
  })

const handleHideAchievement = state =>
  state.merge({
    showAchievement: false,
  })

const handleUpdateExperiences = (state, { data }) => {
  const filteredData = filter(data, d => d?.verb === 'answered')

  const tasks = uniqBy(
    map(state.tasks, t => ({
      ...t,
      complete:
        findIndex(filteredData, d => {
          return d.entity && d.entity.id === t.id
        }) >= 0,
      answerData: find(filteredData, d => {
        return d.entity && d.entity.id === t.id
      }),
    })),
    'id',
  )

  const allComplete = a => {
    const activityTasks = filter(tasks, t => t.activityId === a.id)
    if (activityTasks.length > 0) {
      return (
        filter(activityTasks, t => t.complete).length === activityTasks.length
      )
    }
    return true
  }

  return state.merge({
    tasks,
    activities: map(state.activities, a => ({
      ...a,
      complete: allComplete(a),
    })),
  })
}

const handleSetIsTeachingInFrontOfClass = (
  state,
  { isTeachingInFrontOfClass },
) =>
  state.merge({
    isTeachingInFrontOfClass,
  })

const handleResetCurrentLesson = state =>
  state.merge({
    isTeachingInFrontOfClass: false,
    lessonId: null,
    currentActivityId: null,
    previousActivityId: null,
    taskId: null,
    lessonPlan: null,
    showAchievement: false,
    lessonCompleted: false,
    tasks: map(state.tasks, t => ({ ...t, selected: false, open: false })),
    activities: map(state.activities, t => ({
      ...t,
      shown: false,
      animated: false,
    })),
  })

const handlers = {
  [LOAD_DATA]: handleLoad,
  [LOAD_LESSONS_DATA]: handleLoadLessons,
  [OPEN_TASK]: handleOpenTask,
  [CLOSE_ACTIVITY]: handleCloseActivity,
  [CLOSE_TASK]: handleCloseTask,
  [CLOSE_TASK_NAVIGATION]: handleCloseTaskNavigation,
  [SELECT_TASK]: handleSelectTask,
  [ADVANCE_ACTIVITY]: handleSelectActivity,
  [SELECT_ACTIVITY]: handleSelectActivity,
  [ANIMATE_ACTIVITY]: handleAnimateActivity,
  [REQUEST_ADVANCE_ACTIVITY]: handleRequestAdvanceActivity,
  [COMPLETE_ACTIVITY]: handleCompleteActivity,
  [UPDATE_TASK_EXPERIENCES]: handleUpdateExperiences,
  [SHOW_ACHIEVEMENT]: handleShowAchievement,
  [HIDE_ACHIEVEMENT]: handleHideAchievement,
  [SET_IS_TEACHING_IN_FRONT_OF_CLASS]: handleSetIsTeachingInFrontOfClass,
  [RESET_CURRENT_LESSON]: handleResetCurrentLesson,
  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
