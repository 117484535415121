import { css, styled, themeGet } from '@lyfta/components-theme'

import { Flex } from '../Flex'

const PILL_HEIGHT = 48
const ACTIVE_HEIGHT = 2

export const Container = styled(Flex).attrs(() => ({
  mb: 3,
}))`
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  height: ${PILL_HEIGHT + ACTIVE_HEIGHT}px;
  ${props => props.title && 'margin-top: 14px'};
  border-bottom: 1px solid ${themeGet('colors.alto')};
  user-select: none;
`

export const Content = styled(Flex)`
  flex-direction: row;
  position: relative;
  flex-wrap: nowrap;
  height: ${PILL_HEIGHT + ACTIVE_HEIGHT}px;
  overflow-x: scroll;
  white-space: nowrap;
  user-select: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`

export const ActiveTab = styled(Flex)`
  justify-content: center;
  align-items: center;
  height: ${ACTIVE_HEIGHT}px;
  border-radius: 4px;
  background: ${themeGet('colors.primary')};
  position: absolute;
  left: ${props => props.index * props.width}px;
  top: ${PILL_HEIGHT - ACTIVE_HEIGHT + 1}px;
  z-index: 0;
  transition: all ${themeGet('transitionTime')};
`

export const Tab = styled(Flex).attrs(() => ({
  fontSize: 3,
  pb: 1,
}))`
  height: ${PILL_HEIGHT}px;
  padding-left: 2px;
  flex-shrink: 0;
  justify-content: center;
  align-items: last baseline;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 0.5px;
  transition: all ${themeGet('transitionTime')};

  ${props =>
    props.active
      ? css`
          color: ${themeGet('colors.primary_100')};
        `
      : css`
          color: ${themeGet('colors.black_600')};
        `};
`
