import { BREAKPOINTS, styled } from '@lyfta/components-theme'
import { breakpoints } from '@lyfta/components-theme/src/themes/Main'

import { Flex } from '../../Components'

export const PublicContainer = styled(Flex)`
  flex-direction: row;
  flex: 1;
  height: 100vh;
`

export const ImageContainer = styled(Flex).attrs(() => ({
  p: 3,
}))`
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media screen and (max-width: ${breakpoints[BREAKPOINTS.MOBILE]}) {
    display: none;
  }
`

export const ContentContainer = styled(Flex).attrs(() => ({
  p: 3,
  flex: [3, 3, 1],
}))`
  flex-direction: column;
  overflow-y: scroll;
  justify-content: center;
  align-items: stretch;
`
