import { setStudentLessonID } from '@lyfta/components-data/src/Store/Actions/auth'
import {
  getLessonPlanId,
  getResourceId,
} from '@lyfta/components-data/src/Store/Selectors/persist'
import { getViewerType } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { loadLesson, loadLessons } from '../../Store/Actions/lessons'
import { questTrackerData } from '../../Store/Actions/questTracker'
import { quest } from '../../Store/Actions/ui'
import {
  getCurrentLesson,
  getIsLoaded,
  getLessons,
} from '../../Store/Selectors/lessons'
import { getIsQuest, getLessonId } from '../../Store/Selectors/UI/quest'
import LessonSelection from './LessonSelection'

const selector = createStructuredSelector({
  lesson: getCurrentLesson,
  lessonLoaded: getIsLoaded,
  lessons: getLessons,
  lessonId: getLessonId,
  previewLessonId: getLessonPlanId,
  resourceId: getResourceId,
  showQuest: getIsQuest,
  viewerType: getViewerType,
})

const actions = {
  onLoadLessons: loadLessons,
  onLoadLesson: loadLesson,
  onPauseWebGL: quest.pauseWebGL,
  onResumeWebGL: quest.resumeWebGL,
  onSelectLesson: quest.questSelect,
  onSetIsTeachingInFrontOfClass: questTrackerData.setIsTeachingInFrontOfClass,
  onSetStudentLessonId: setStudentLessonID,
  resetCurrentLesson: questTrackerData.resetCurrentLesson,
}

export default connect(selector, actions)(LessonSelection)
export { PosterImage } from './home-styles'
export { ImageContainer } from './home-styles'
export { HomeLessonInfo } from './home-styles'
