import { filter, map } from 'lodash'

import { getCurrentLanguage } from './locale'

export * from './translation'
export * from './locale'
export * from './date'
export { i18next, initialise } from './configure'

const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/fi.json'))
countries.registerLocale(require('i18n-iso-countries/langs/es.json'))
countries.registerLocale(require('i18n-iso-countries/langs/pt.json'))

export const getCountryName = (locale, countryCode) =>
  countries.getName(countryCode, locale, {
    select: 'official',
  })

export const generateCountryList = ({
  locale = getCurrentLanguage(),
  includeBlank = true,
} = {}) => {
  const sortToTop = ['gb', 'fi', 'us']
  const topValues = map(sortToTop, c => ({
    id: c,
    value: countries.getName(c, locale, { select: 'official' }),
    name: countries.getName(c, locale, { select: 'official' }),
  }))
  const countryValues = [
    ...topValues,
    ...filter(
      map(countries.getNames(locale, { select: 'official' }), (value, id) => ({
        value,
        name: value,
        id: id.toLowerCase(),
      })),
      c => !sortToTop.includes(c.id),
    ),
  ]

  if (includeBlank) {
    countryValues.unshift({ id: null, value: '', name: '' })
  }
  return countryValues
}
