import apiCall from '../../../services/Api'

const includes = [
  'organization',
  'organization.licensings',
  'organization.licensings.license',
  'organization.licensings.license.suggestedMeeting',
  'userLicensing',
  'userPermissions',
]

const generateIncludes = additionalIncludes => {
  const newIncludes = additionalIncludes ? additionalIncludes.split(',') : []

  return [...includes, ...newIncludes].join(',')
}

export const generateLoadUsersAction = (
  types,
  role,
  endpoint = '/users',
  include,
) => ({ filter, page, sort = 'lastName', ...props } = {}) =>
  apiCall({
    method: 'GET',
    endpoint,
    query: {
      include: generateIncludes(include),
      page,
      filter: { ...filter, role },
      sort,
    },
    types,
    paged: true,
    payload: { page, filter, include, sort, ...props },
  })
export const generateLoadUserAction = (
  types,
  endpoint = '/users',
  include = 'organization,userLicensing,klasses,children',
) => id =>
  apiCall({
    method: 'GET',
    endpoint: `${endpoint}/${id}`,
    types,
    payload: { id },
    query: { include: generateIncludes(include) },
  })
export const generateCreateUser = ({
  types,
  endpoint = '/users',
  entityName = null,
  customMessage,
}) => (user, relationships = {}) =>
  apiCall({
    method: 'POST',
    endpoint: `${endpoint}?include=${generateIncludes()}`,
    entityName,
    types,
    query: {
      data: {
        type: 'users',
        attributes: {
          ...user,
        },
        relationships,
      },
    },
    customMessage,
  })
export const generateUpdateUser = ({
  types,
  endpoint = '/users',
  customMessage,
  entityName = null,
}) => user => {
  return apiCall({
    method: 'PATCH',
    endpoint: `${endpoint}/${user.id}?include=${generateIncludes()}`,
    entityName,
    types,
    query: {
      data: {
        type: 'users',
        attributes: {
          ...user,
        },
      },
    },
    customMessage,
  })
}

export const generateDeleteUser = ({
  types,
  customMessage,
  endpoint = '/users',
  deleteType = null,
  updateData = null,
  entityName = null,
}) => user => {
  return apiCall({
    method: 'DELETE',
    deleteType,
    endpoint: `${endpoint}/${user}?include=${generateIncludes()}`,
    entityName,
    types,
    query: {
      include: generateIncludes(),
    },
    customMessage,
    updateData,
  })
}
