import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'

import { renderField } from './FieldRenderer'

const AnimatedTypeAhead = ({
  autoFocus,
  name,
  label,
  description,
  placeholder,
  type,
  id,
  ...rest
}) => {
  return (
    <Field
      autoFocus={autoFocus}
      description={description}
      id={id}
      label={label}
      name={name}
      placeholder={placeholder}
      render={renderField}
      type={type}
      {...rest}
    />
  )
}

AnimatedTypeAhead.defaultProps = {
  autoFocus: false,
  id: null,
  keepValueOnNoItem: false,
  label: '',
  placeholder: '',
  description: '',
  type: 'text',
  onInputValueChange: null,
}

AnimatedTypeAhead.propTypes = {
  autoFocus: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  id: PropTypes.string,
  items: PropTypes.array.isRequired,
  keepValueOnNoItem: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onInputValueChange: PropTypes.func,
}

export default AnimatedTypeAhead
