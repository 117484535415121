import PropTypes from 'prop-types'
import React from 'react'

import { Flex } from '../../Flex'
import { ViewOnlyField, ViewOnlyLabel } from './styles'

const ViewOnly = props => {
  const { input, label, viewOnlyProps, addIcon, appendText } = props
  const { hideLabel, labelTag, fieldTag } = viewOnlyProps
  const { preText, postText } = appendText || ''
  const LabelContainer = labelTag || ViewOnlyLabel
  const FieldContainer = fieldTag || ViewOnlyField

  return (
    <Flex {...props} flexDirection="column">
      {label && !hideLabel && input.value && (
        <LabelContainer mt={2}>{label}</LabelContainer>
      )}
      {input.value && (
        <FieldContainer>
          {preText && preText} {input.value} {postText && postText}
          {addIcon}
        </FieldContainer>
      )}
    </Flex>
  )
}
ViewOnly.defaultProps = {
  hideLabel: false,
  label: '',
  viewOnlyProps: {},
  labelTag: <></>,
  fieldTag: <></>,
  addIcon: null,
  appendText: '',
}

ViewOnly.propTypes = {
  hideLabel: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  viewOnlyProps: PropTypes.object,
  labelTag: PropTypes.element,
  fieldTag: PropTypes.element,
  input: PropTypes.object.isRequired,
  addIcon: PropTypes.object,
  appendText: PropTypes.string,
}

export default ViewOnly
