/* eslint-disable */

import { styled } from '@lyfta/components-theme'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'

import { Label as BaseLabel } from '../../../BaseComponents/index'
import { Container, Description, Row, Wrapper } from './styles'

const Label = styled(BaseLabel)``

const renderField = ({
  checked,
  input,
  meta,
  label,
  type,
  description,
  id,
  style,
  onClick,
}) => {
  const descriptionLabel = <Description>{description}</Description>
  return (
    <Wrapper {...style}>
      <Row>
        <Container
          checked={checked || input.checked}
          error={(meta.touched && meta.error) || undefined}
        >
          <input onClick={() => onClick()} id={id} type={type} {...input} />
        </Container>
        {label && <Label ml={2}>{label}</Label>}
      </Row>
      {description && descriptionLabel}
    </Wrapper>
  )
}

renderField.defaultProps = {
  id: null,
}

renderField.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
}

const Checkbox = ({
  id,
  label,
  name,
  type,
  checked,
  value,
  description,
  style,
  onClick,
}) => {
  return (
    <Field
      description={description}
      checked={checked}
      label={label}
      name={name}
      render={renderField}
      type={type}
      value={value}
      id={id}
      style={style}
      onClick={onClick}
    />
  )
}

Checkbox.defaultProps = {
  checked: false,
  id: null,
  label: null,
  name: '',
  type: 'checkbox',
  onClick: () => {},
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
}

export default Checkbox
