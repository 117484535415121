import colors from './colors'
import shadows from './shadows'

// TODO: To add Link btn (Card5). Ask to put it in DS ?

export default {
  buttonType: {
    cta: {
      primary: {
        default: {
          backgroundColor: colors.secondary_100,
          borderColor: colors.secondary_100,
          borderRadius: '50px',
          borderStyle: 'solid',
          borderWidth: '2px',
          boxShadow: shadows.lifted,
          color: colors.neutral_100,
          fill: colors.neutral_100,
          maxHeight: '40px',
          padding: '8px 30px',
        },
        focusVisible: {
          backgroundColor: colors.secondary_80,
          borderColor: colors.neutral_100,
          boxShadow: shadows.raised,
          color: colors.neutral_100,
          outlineColor: colors.secondary_100,
          outlineStyle: 'solid',
          outlineWidth: '1px',
        },
        hover: {
          backgroundColor: colors.secondary_80,
          borderColor: colors.secondary_80,
          boxShadow: shadows.raised,
          color: colors.neutral_100,
          fill: colors.neutral_100,
        },
        pressed: {
          backgroundColor: colors.secondary_130,
          borderColor: colors.secondary_130,
          boxShadow: shadows.skim,
          color: colors.neutral_100,
          fill: colors.neutral_100,
        },
      },
      secondary: {
        default: {
          backgroundColor: colors.neutral_100,
          borderColor: colors.secondary_100,
          borderRadius: '50px',
          borderStyle: 'solid',
          borderWidth: '2px',
          boxShadow: shadows.lifted,
          color: colors.secondary_100,
          fill: colors.secondary_100,
          fillHover: 'white',
          maxHeight: '40px',
          padding: '8px 30px',
        },
        focusVisible: {
          backgroundColor: colors.secondary_100,
          borderColor: colors.neutral_100,
          boxShadow: shadows.raised,
          color: colors.neutral_100,
          outlineColor: colors.secondary_100,
          outlineStyle: 'solid',
          outlineWidth: '1px',
        },
        hover: {
          backgroundColor: colors.secondary_100,
          borderColor: colors.secondary_100,
          boxShadow: shadows.raised,
          color: colors.neutral_100,
          fill: colors.neutral_100,
        },
        pressed: {
          backgroundColor: colors.secondary_130,
          borderColor: colors.secondary_130,
          boxShadow: shadows.skim,
          color: colors.neutral_100,
          fill: colors.neutral_100,
        },
      },
    },
    task: {
      primary: {
        default: {
          backgroundColor: colors.secondary_100,
          borderColor: colors.secondary_100,
          borderRadius: '5px',
          borderStyle: 'solid',
          borderWidth: '2px',
          boxShadow: shadows.lifted,
          color: colors.neutral_100,
          fill: colors.neutral_100,
          maxHeight: '36px',
          padding: '6px 18px',
        },
        focusVisible: {
          backgroundColor: colors.secondary_80,
          borderColor: colors.neutral_100,
          boxShadow: shadows.raised,
          color: colors.neutral_100,
          outlineColor: colors.secondary_100,
          outlineStyle: 'solid',
          outlineWidth: '1px',
        },
        hover: {
          backgroundColor: colors.secondary_80,
          borderColor: colors.secondary_80,
          boxShadow: shadows.raised,
          fill: colors.neutral_100,
        },
        pressed: {
          backgroundColor: colors.secondary_130,
          borderColor: colors.secondary_130,
          boxShadow: shadows.skim,
          fill: colors.neutral_100,
        },
      },
      secondary: {
        default: {
          backgroundColor: colors.neutral_100,
          boxShadow: shadows.lifted,
          borderColor: colors.secondary_100,
          borderRadius: '5px',
          borderStyle: 'solid',
          borderWidth: '2px',
          color: colors.secondary_100,
          fill: colors.secondary_100,
          maxHeight: '36px',
          padding: '6px 18px',
        },
        focusVisible: {
          backgroundColor: colors.secondary_100,
          borderColor: colors.neutral_100,
          boxShadow: shadows.raised,
          color: colors.neutral_100,
          outlineColor: colors.secondary_100,
          outlineStyle: 'solid',
          outlineWidth: '1px',
        },
        hover: {
          backgroundColor: colors.secondary_100,
          boxShadow: shadows.raised,
          color: colors.neutral_100,
          fill: colors.neutral_100,
        },
        pressed: {
          backgroundColor: colors.secondary_130,
          borderColor: colors.secondary_130,
          boxShadow: shadows.skim,
          color: colors.neutral_100,
          fill: colors.neutral_100,
        },
      },
    },
    icon: {
      primary: {
        default: {
          backgroundColor: colors.secondary_100,
          borderColor: colors.secondary_100,
          borderRadius: '50%',
          borderStyle: 'solid',
          borderWidth: '2px',
          // boxShadow: shadows.lifted,
          fill: colors.neutral_100,
          maxHeight: '44px',
          maxWidth: '44px',
          minHeight: '44px',
          minWidth: '44px',
        },
        focusVisible: {
          backgroundColor: colors.secondary_80,
          borderColor: colors.neutral_100,
          // boxShadow: shadows.raised,
          color: colors.neutral_100,
          outlineColor: colors.secondary_100,
          outlineStyle: 'solid',
          outlineWidth: '1px',
        },
        hover: {
          backgroundColor: colors.secondary_80,
          borderColor: colors.secondary_80,
          // boxShadow: shadows.raised,
          fill: colors.neutral_100,
        },
        pressed: {
          backgroundColor: colors.secondary_130,
          borderColor: colors.secondary_130,
          // boxShadow: shadows.skim,
          fill: colors.neutral_100,
        },
      },
      secondary: {
        default: {
          backgroundColor: colors.neutral_100,
          borderColor: colors.secondary_100,
          borderRadius: '50%',
          borderStyle: 'solid',
          borderWidth: '2px',
          // boxShadow: shadows.lifted,
          fill: colors.secondary_100,
          maxHeight: '44px',
          maxWidth: '44px',
          minHeight: '44px',
          minWidth: '44px',
          padding: '8px 20px',
        },
        focusVisible: {
          backgroundColor: colors.secondary_100,
          borderColor: colors.neutral_100,
          // boxShadow: shadows.raised,
          color: colors.neutral_100,
          outlineColor: colors.secondary_100,
          outlineStyle: 'solid',
          outlineWidth: '1px',
        },
        hover: {
          backgroundColor: colors.secondary_100,
          borderColor: colors.secondary_100,
          // boxShadow: shadows.raised,
          fill: colors.neutral_100,
        },
        pressed: {
          backgroundColor: colors.secondary_130,
          borderColor: colors.secondary_130,
          // boxShadow: shadows.skim,
          fill: colors.neutral_100,
        },
      },
    },
    link: {
      primary: {
        default: {
          backgroundColor: 'transparent',
          border: 'none',
          color: colors.secondary_100,
          letterSpacing: '2.5px',
          maxHeight: '40px',
          padding: '10px 32px',
        },
        hover: {
          textDecorationLine: 'underline',
        },
      },
      secondary: {
        default: {
          backgroundColor: 'transparent',
          border: 'none',
          color: colors.secondary_100,
          letterSpacing: '2.5px',
          maxHeight: '40px',
          padding: '10px 32px',
        },
        hover: {
          textDecorationLine: 'underline',
        },
      },
    },
  },
}
