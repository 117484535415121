import * as React from 'react'

function SvgTutorial(props) {
  return (
    <svg height="24" viewBox="0 0 24 24" width="24" {...props}>
      <path d="M12 2C14.4128 2 16.626 2.8545 18.3535 4.27738L14.0602 8.57066C13.4585 8.20838 12.7536 8 12 8C11.2576 8 10.5625 8.20223 9.96671 8.5546L5.67011 4.258C7.39415 2.84674 9.59819 2 12 2Z" />
      <path d="M19.7632 5.69609L15.4628 9.99646C15.8044 10.5856 16 11.27 16 12C16 12.7306 15.8041 13.4155 15.462 14.005L19.7622 18.3052C21.1613 16.5848 22 14.3903 22 12C22 9.61024 21.1617 7.41627 19.7632 5.69609Z" />
      <path d="M18.3522 19.7236L14.0588 15.4302C13.4574 15.7919 12.753 16 12 16C11.2582 16 10.5636 15.7981 9.96813 15.4462L5.67135 19.743C7.39521 21.1537 9.59877 22 12 22C14.4122 22 16.6249 21.1459 18.3522 19.7236Z" />
      <path d="M2 12C2 9.59927 2.84599 7.39612 4.25611 5.67243L8.55304 9.96936C8.20163 10.5646 8 11.2587 8 12C8 12.7418 8.20195 13.4365 8.55387 14.0321L4.25712 18.3288C2.84639 16.6049 2 14.4013 2 12Z" />
    </svg>
  )
}

export default SvgTutorial
