import { AUTHENTICATE } from '@lyfta/components-data/src/Store/Actions/auth'
import {
  LOAD_VIEWER,
  loadViewer,
  logOut,
} from '@lyfta/components-data/src/Store/Actions/viewer'
import { getJWT } from '@lyfta/components-data/src/Store/Selectors/persist'
import { getIsDataLoaded } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { getTranslation } from '@lyfta/components-i18n'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import { put, race, select, take } from 'redux-saga/effects'
import { COMPLETE_REHYDRATION, completeRefetch } from 'Store/Actions/app'

export default function* restoreSession() {
  while (true) {
    yield take([COMPLETE_REHYDRATION, AUTHENTICATE.SUCCESS])

    const isDataLoaded = yield select(getIsDataLoaded)
    const jwt = yield select(getJWT)

    if (jwt) {
      yield put(loadViewer())
      if (isDataLoaded) {
        yield put(completeRefetch())
      } else {
        yield put(loadViewer())
        const { success } = yield race({
          success: take(LOAD_VIEWER.SUCCESS),
          failure: take(LOAD_VIEWER.FAILURE),
        })
        const userType = get(success, `payload.data.meta['/user'].data[0].type`)
        if (userType === 'parents') {
          toast.error(getTranslation('auth.dontAllowedUser'))
          yield put(logOut())
        } else {
          yield put(completeRefetch())
        }
      }
    }
  }
}
