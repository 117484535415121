import { useAnimation } from 'framer-motion'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  DRAWER_HIDE_COMPLETE,
  DRAWER_SHOW_COMPLETE,
} from '../../../Store/Actions/ui'
import { getIsOpen } from '../../../Store/Selectors/UI/drawer'
import Component from './Component'

const Activities = () => {
  const dispatch = useDispatch()
  const isDrawerOpen = useSelector(getIsOpen)
  const questControls = useAnimation()
  const questAnimation = {
    animate: questControls,
    initial: { right: '0px', display: 'none' },
  }

  useEffect(() => {
    if (!isDrawerOpen) {
      questControls
        .start({
          right: '-450px',
          transitionEnd: {
            display: 'none',
          },
        })
        .then(() =>
          dispatch({
            type: DRAWER_HIDE_COMPLETE,
          }),
        )
    } else {
      questControls
        .start({
          right: '0px',
          display: 'flex',
        })
        .then(() =>
          dispatch({
            type: DRAWER_SHOW_COMPLETE,
          }),
        )
    }
  }, [isDrawerOpen])

  return (
    <Component isDrawerOpen={isDrawerOpen} questAnimation={questAnimation} />
  )
}

export default Activities
