import * as React from 'react'

function SvgCheckboxEmpty(props) {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M6.11111 4C4.94518 4 4 4.94518 4 6.11111V13.8889C4 15.0548 4.94518 16 6.11111 16H13.8889C15.0548 16 16 15.0548 16 13.8889V6.11111C16 4.94518 15.0548 4 13.8889 4H6.11111ZM2 6.11111C2 3.84061 3.84061 2 6.11111 2H13.8889C16.1594 2 18 3.84061 18 6.11111V13.8889C18 16.1594 16.1594 18 13.8889 18H6.11111C3.84061 18 2 16.1594 2 13.8889V6.11111Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCheckboxEmpty
