import * as React from 'react'

function SvgNewSearch(props) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 141 137"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M126.537 107.81L99.5717 81.6094C95.5533 87.6828 90.2425 92.8429 83.9917 96.7473L110.945 122.936C115.246 127.114 122.225 127.114 126.525 122.936C130.837 118.758 130.837 111.988 126.537 107.81Z"
        fill="#3333CC"
      />
      <path
        d="M100.382 54.7239C100.382 31.0809 80.655 11.9131 56.3217 11.9131C31.9885 11.9131 12.261 31.0809 12.261 54.7239C12.261 78.3669 31.9885 97.5347 56.3217 97.5347C80.655 97.5347 100.382 78.3669 100.382 54.7239ZM56.3217 86.8263C38.0747 86.8263 23.2821 72.4533 23.2821 54.7239C23.2821 36.9945 38.0747 22.6101 56.3217 22.6101C74.5688 22.6101 89.3732 36.9945 89.3732 54.7239C89.3497 72.4533 74.5688 86.8149 56.3217 86.8263Z"
        fill="#3333CC"
      />
      <path
        d="M30.6255 54.723H37.9689C37.9807 44.8708 46.1936 36.8909 56.3334 36.8795V29.7444C42.1283 29.7672 30.6372 40.9437 30.6255 54.723Z"
        fill="#3333CC"
      />
    </svg>
  )
}

export default SvgNewSearch
