import * as React from 'react'

function SvgRadioUnselected(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0005 18.001C8.88878 18.001 7.84981 17.7926 6.88357 17.3759C5.91734 16.9585 5.07022 16.3861 4.34221 15.6588C3.61483 14.9307 3.04246 14.0836 2.6251 13.1174C2.20837 12.1512 2 11.1122 2 10.0005C2 8.87544 2.20837 7.83329 2.6251 6.87404C3.04246 5.91416 3.61483 5.07054 4.34221 4.34316C5.07022 3.61515 5.91734 3.04246 6.88357 2.6251C7.84981 2.20837 8.88878 2 10.0005 2C11.1255 2 12.1677 2.20837 13.1269 2.6251C14.0868 3.04246 14.9307 3.61515 15.6588 4.34316C16.3861 5.07054 16.9585 5.91734 17.3759 6.88357C17.7926 7.84981 18.001 8.88878 18.001 10.0005C18.001 11.1122 17.7926 12.1512 17.3759 13.1174C16.9585 14.0836 16.3861 14.9307 15.6588 15.6588C14.9307 16.3861 14.0836 16.9585 13.1174 17.3759C12.1512 17.7926 11.1122 18.001 10.0005 18.001ZM10.0005 16.0552C11.6947 16.0552 13.1272 15.4695 14.298 14.298C15.4695 13.1272 16.0552 11.6947 16.0552 10.0005C16.0552 8.30624 15.4695 6.87373 14.298 5.70294C13.1272 4.53152 11.6947 3.94581 10.0005 3.94581C8.30624 3.94581 6.87373 4.53152 5.70294 5.70294C4.53152 6.87373 3.94581 8.30624 3.94581 10.0005C3.94581 11.6947 4.53152 13.1272 5.70294 14.298C6.87373 15.4695 8.30624 16.0552 10.0005 16.0552Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgRadioUnselected
