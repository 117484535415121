import { I18n, getTranslation } from '@lyfta/components-i18n'
import { themeGet } from '@lyfta/components-theme'
import PropTypes from 'prop-types'
import React from 'react'

import { ReactSelectDropdown } from '../../../Components'
import { Flex } from '../../../Components/Flex'
import {
  ArrowContainer,
  ArrowText,
  Current,
  Next,
  NumberContainer,
  PageSize,
  PaginationWrapper,
  Previous,
  RightSide,
} from './styles'

const Pagination = ({
  className,
  pager: {
    next,
    choosePage,
    previous,
    current: page,
    total,
    size: pageSize,
    setSize,
    canPrevious,
    canNext,
  },
  isAtBottom,
  paddingLeft,
  modelName,
}) => {
  const totalPages = Math.ceil(total / pageSize)
  const paginationOptions = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 250, label: '250' },
    { value: 500, label: '500' },
  ]

  const defaultOptionIndex = paginationOptions.findIndex(
    ({ value }) => value === pageSize,
  )
  let pages = [...Array(totalPages).keys()].map(i => (
    <NumberContainer onClick={() => choosePage(i + 1)} key={i + 1}>
      {page === i + 1 ? (
        <Current active>{i + 1}</Current>
      ) : (
        <Current>{i + 1}</Current>
      )}
    </NumberContainer>
  ))

  const displayPagesLimit = 5
  const currentPage = page

  if (totalPages > displayPagesLimit) {
    const halfPagesToShow = Math.floor((displayPagesLimit - 2) / 2)

    let startPage = Math.max(2, currentPage - halfPagesToShow)
    let endPage = Math.min(currentPage + halfPagesToShow, totalPages - 1)

    if (currentPage - startPage < halfPagesToShow) {
      endPage += halfPagesToShow - (currentPage - startPage)
    }

    if (endPage - currentPage < halfPagesToShow) {
      startPage -= halfPagesToShow - (endPage - currentPage)
    }

    pages = [
      pages[0],
      startPage > 2 ? <span key="ellipsis-start">...</span> : null,
      ...pages.slice(startPage - 1, endPage),
      endPage < totalPages - 1 ? <span key="ellipsis-end">...</span> : null,
      pages[totalPages - 1],
    ].filter(Boolean) // not null
  }

  return (
    total > 1 && (
      <PaginationWrapper
        paddingLeft={paddingLeft || 0}
        isAtBottom={isAtBottom}
        className={className}
      >
        <PageSize>
          <Flex mr={3}>
            <I18n
              i18nKey="search.totalItems"
              options={{
                number: total,
                modelName: modelName
                  ? getTranslation(`core.models.${modelName}`, {
                      count: total,
                    }).toLowerCase()
                  : getTranslation(`search.results`, { count: total }),
              }}
            />
          </Flex>

          <I18n
            i18nKey="search.rowsPerPage"
            options={{
              number: total,
              modelName: modelName
                ? getTranslation(`core.models.${modelName}`, {
                    count: total,
                  }).toLowerCase()
                : getTranslation(`search.results`, { count: total }),
            }}
          />
          <ReactSelectDropdown
            defaultValue={paginationOptions[defaultOptionIndex]}
            extendStyles={{
              container: defaultStyles => ({
                ...defaultStyles,
                margin: '0 0 0 8px',
              }),
              singleValue: defaultStyles => ({
                ...defaultStyles,
                color: themeGet('colors.black_700'),
              }),
            }}
            id="dropdown-rows-per-page"
            menuBorderRadius="top"
            menuPosition="fixed"
            options={paginationOptions}
            onChange={setSize}
          />
        </PageSize>
        {totalPages > 1 && (
          <RightSide>
            {page > 1 && (
              <ArrowContainer onClick={previous} mr={4}>
                <Previous
                  disabled={canPrevious}
                  id="div-show-previous-page"
                  height="9px"
                  width="9px"
                />{' '}
                <I18n tag={ArrowText} i18nKey="actions.previous" />
              </ArrowContainer>
            )}
            {pages}

            {page !== totalPages && (
              <ArrowContainer ml={4} onClick={next}>
                <I18n tag={ArrowText} i18nKey="actions.next" />
                <Next
                  disabled={canNext}
                  id="div-show-next-page"
                  height="9px"
                  width="9px"
                />
              </ArrowContainer>
            )}
          </RightSide>
        )}
      </PaginationWrapper>
    )
  )
}

Pagination.defaultProps = {
  className: '',
  isAtBottom: false,
  paddingLeft: '',
  modelName: '',
}

Pagination.propTypes = {
  className: PropTypes.string,
  pager: PropTypes.shape({
    canNext: PropTypes.bool.isRequired,
    canPrevious: PropTypes.bool.isRequired,
    current: PropTypes.number.isRequired,
    next: PropTypes.func.isRequired,
    choosePage: PropTypes.func.isRequired,
    previous: PropTypes.func.isRequired,
    setSize: PropTypes.func.isRequired,
    size: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  isAtBottom: PropTypes.bool,
  paddingLeft: PropTypes.string,
  modelName: PropTypes.string,
}

export default Pagination
