import { styled, withTheme } from '@lyfta/components-theme'
import { Button, Flex, Icon, TextNode } from '@lyfta/components-ui'
import { themeGet as theme } from '@styled-system/theme-get'
import React from 'react'

export const BaseContainer = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'flex-end',
})`
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: 1;
`

export const BaseCloseIcon = styled(Icon)`
  stroke: transparent;
  fill: #fff;
  position: absolute;
  top: 24px;
  right: 24px;
  height: 40px;
  width: 40px;
  z-index: 1001;
  padding: 2px;
`

export const BaseCloseIconTab = styled(Icon)`
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 10px;
`

export const BaseHelpMeFind = styled(Icon)`
  &:hover {
    stroke: #000;
    fill: #000;
  }
`

export const BaseLabel = styled(TextNode)`
  margin-left: 10px;
  white-space: nowrap;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.15;
  letter-spacing: 0.4px;
  color: ${theme('colors.white')};
  position: absolute;
  top: 0;
  transform: translate(0, 50%);
  left: 0;
  padding-left: 28%;
`

export const BaseSubmit = withTheme(p => <Button monochrome my={3} {...p} />)
