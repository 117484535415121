import spacing from '../Main/space'
import colors from './colors'
import shadows from './shadows'

const { space } = spacing

export default {
  buttonType: {
    cta: {
      primary: {
        default: {
          background: colors.sa_gradient_a,
          border: 'none',
          maxHeight: 'none',
          padding: `${space[3]} ${space[5]}`,
        },
        disabledStyle: {
          background: colors.sa_gradient_a,
          color: colors.neutral_100,
          cursor: 'default',
          fill: colors.neutral_100,
          opacity: 0.3,
        },
        hover: {
          background: colors.sa_gradient_c,
        },
        pressed: {
          background: colors.sa_gradient_b,
        },
        focusVisible: {
          background: colors.sa_gradient_c,
          outlineColor: colors.sa_primary_90,
          outlineOffset: '3px',
        },
      },
      secondary: {
        default: {
          background: colors.neutral_100,
          border: 'none',
          color: colors.sa_primary_110,
          fill: colors.sa_primary_110,
        },
        disabledStyle: {
          backgroundColor: colors.black_200,
          color: colors.sa_primary_110,
          cursor: 'default',
          fill: colors.sa_primary_110,
          opacity: 0.3,
        },
        hover: {
          background: colors.sa_gradient_c,
          color: colors.neutral_100,
        },
        pressed: {
          background: colors.sa_gradient_b,
          color: colors.neutral_100,
        },
        focusVisible: {
          background: colors.sa_gradient_c,
          color: colors.neutral_100,
          outlineColor: colors.sa_primary_90,
          outlineOffset: '2px',
        },
      },
    },
    icon: {
      primary: {
        default: {
          backgroundColor: colors.black_700_50,
          borderColor: colors.neutral_100,
          fill: colors.neutral_100,
          maxHeight: space[9],
          maxWidth: space[9],
          minHeight: space[9],
          minWidth: space[9],
        },
        hover: {
          backgroundColor: colors.neutral_100,
          borderColor: colors.neutral_100,
          fill: colors.black_600,
        },
        pressed: {
          backgroundColor: colors.black_700_50,
          borderColor: colors.neutral_100,
        },
        focusVisible: {
          outlineColor: colors.neutral_100,
          backgroundColor: colors.neutral_100,
          borderColor: colors.neutral_100,
          boxShadow: shadows.primaryInnerIndicator,
          fill: colors.black_600,
          outlineOffset: space[2],
          outlineWidth: '2px',
        },
      },
      secondary: {
        default: {
          backgroundColor: colors.black_700_50,
          borderColor: colors.neutral_100,
          borderWidth: '1px',
          fill: colors.neutral_100,
        },
        hover: {
          backgroundColor: colors.neutral_100,
          borderColor: colors.neutral_100,
          fill: colors.black_600,
        },
        pressed: {
          backgroundColor: colors.black_700_50,
          borderColor: colors.neutral_100,
        },
        focusVisible: {
          backgroundColor: colors.neutral_100,
          borderColor: colors.neutral_100,
          boxShadow: shadows.secondaryInnerIndicator,
          fill: colors.black_600,
          outlineColor: colors.neutral_100,
          outlineOffset: space[1],
        },
      },
    },
  },
}
