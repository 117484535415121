import { initialStateFilter } from '@lyfta/components-content/src/Constants/contents'
import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'
import Immutable from 'seamless-immutable'

import {
  CHANGE_DASHBOARD,
  CHANGE_PAGE,
  CHANGE_TYPE,
  CLEAR,
  CLEAR_SEARCHEDITORS,
  LOAD_CONTENTS_COUNT,
  SEARCH_TEXT,
  TOGGLE_FILTERS,
  TOGGLE_KEYWORD,
  TOGGLE_NEW_SEARCH_TAGS_AND_KEYWORDS,
  TOGGLE_SEARCHEDITORS,
  TOGGLE_SEARCHEDITORS_FILTERS,
  TOGGLE_SEARCHEDITORS_KEYWORDS,
  TOGGLE_SEARCHEDITORS_SORT,
  TOGGLE_SEARCHEDITORS_TOTALRESULT,
  TOGGLE_SORT,
  TOGGLE_TAG,
  UPDATE_SIZE,
} from '../Actions/search'

const INITIAL_STATE = Immutable({
  type: 'all_content',
  filters: initialStateFilter,
  searchEditors: {
    filters: initialStateFilter,
    sort: '',
    keywords: [{ id: '3GPP7L2ozp-o4swEupxY3', type: 'query', value: 'awra' }],
    totaResult: null,
  },

  sort: '',
  tags: [],
  keywords: [],
  newSearch: [],
  totalResult: null,
  searchText: '',
  number: 1,
  size: 100,
})

const toggleNewSearchTagsAndKeywords = (state, { newSearch }) => {
  return state.merge({
    newSearch,
  })
}

const toggleTag = (state, { tag }) =>
  state.update('tags', tags =>
    tags.includes(tag) ? tags.filter(item => item !== tag) : [...tags, tag],
  )
const toggleFilters = (state, { filters }) => {
  return state.merge({
    filters,
  })
}
const toggleSort = (state, { sort }) => {
  return state.merge({
    sort,
  })
}
const toggleTotalResult = (state, data) => {
  return state.merge({
    totalResult: data?.payload?.data?.data?.count || null,
  })
}
const toggleKeyword = (state, { keyword }) =>
  state.update('keywords', keywords =>
    keywords.includes(keyword)
      ? keywords.filter(item => item !== keyword)
      : [...keywords, keyword],
  )

const searchText = (state, { text }) =>
  state.merge({
    searchText: text,
  })

const updateSize = (state, { size }) =>
  state.merge({
    size,
  })

const changePage = (state, { page }) =>
  state.merge({
    number: page,
  })

const changeType = (state, { contentType: type }) =>
  state.merge({
    type,
  })

const changeDashboard = (state, { dashboard }) =>
  state.merge({
    dashboard,
  })

const toggleSearchEditors = (state, { searchEditors }) => {
  return state.merge(
    {
      searchEditors,
    },
    { deep: true },
  )
}

const toggleSearchEditorsFilters = (state, { filters }) => {
  return state.merge(
    {
      searchEditors: {
        ...state.searchEditors,
        filters,
      },
    },
    { deep: true },
  )
}

const toggleSearchEditorsKeywords = (state, { keywords }) => {
  return state.merge(
    {
      searchEditors: {
        ...state.searchEditors,
        keywords,
      },
    },
    { deep: true },
  )
}

const toggleSearchEditorsSort = (state, { sort }) => {
  return state.merge(
    {
      searchEditors: {
        ...state.searchEditors,
        sort,
      },
    },
    { deep: true },
  )
}

const toggleSearchEditorsTotalResult = (state, { totalResult }) => {
  return state.merge(
    {
      searchEditors: {
        ...state.searchEditors,
        totalResult,
      },
    },
    { deep: true },
  )
}

const clear = state => state.merge(INITIAL_STATE)
const clearSearchEditors = state => {
  return state.merge(
    {
      searchEditors: {
        filters: initialStateFilter,
        sort: '',
        keywords: [],
      },
    },
    { deep: true },
  )
}

const HANDLERS = {
  [TOGGLE_NEW_SEARCH_TAGS_AND_KEYWORDS]: toggleNewSearchTagsAndKeywords,
  [TOGGLE_TAG]: toggleTag,
  [TOGGLE_FILTERS]: toggleFilters,
  [LOAD_CONTENTS_COUNT.SUCCESS]: toggleTotalResult,
  [TOGGLE_SORT]: toggleSort,
  [TOGGLE_KEYWORD]: toggleKeyword,
  [SEARCH_TEXT]: searchText,
  [UPDATE_SIZE]: updateSize,
  [CHANGE_PAGE]: changePage,
  [CHANGE_TYPE]: changeType,
  [CLEAR]: clear,
  [CLEAR_SEARCHEDITORS]: clearSearchEditors,
  [CHANGE_DASHBOARD]: changeDashboard,
  [TOGGLE_SEARCHEDITORS]: toggleSearchEditors,
  [TOGGLE_SEARCHEDITORS_FILTERS]: toggleSearchEditorsFilters,
  [TOGGLE_SEARCHEDITORS_KEYWORDS]: toggleSearchEditorsKeywords,
  [TOGGLE_SEARCHEDITORS_SORT]: toggleSearchEditorsSort,
  [TOGGLE_SEARCHEDITORS_TOTALRESULT]: toggleSearchEditorsTotalResult,
}

export default createReducer(INITIAL_STATE, HANDLERS, {
  resetOn: [LOG_OUT],
})
