import * as React from 'react'

function SvgRadioSelected(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 13.8113C11.0589 13.8113 11.9587 13.4407 12.6994 12.6994C13.4407 11.9587 13.8113 11.0589 13.8113 10C13.8113 8.94108 13.4407 8.04129 12.6994 7.30062C11.9587 6.55931 11.0589 6.18866 10 6.18866C8.94108 6.18866 8.04129 6.55931 7.30062 7.30062C6.55931 8.04129 6.18866 8.94108 6.18866 10C6.18866 11.0589 6.55931 11.9587 7.30062 12.6994C8.04129 13.4407 8.94108 13.8113 10 13.8113ZM10 18C8.88836 18 7.84945 17.7916 6.88328 17.3749C5.9171 16.9576 5.07003 16.3853 4.34207 15.6579C3.61474 14.93 3.0424 14.0829 2.62506 13.1167C2.20835 12.1505 2 11.1116 2 10C2 8.87502 2.20835 7.83293 2.62506 6.87375C3.0424 5.91393 3.61474 5.07035 4.34207 4.34302C5.07003 3.61505 5.9171 3.0424 6.88328 2.62506C7.84945 2.20835 8.88836 2 10 2C11.125 2 12.1671 2.20835 13.1262 2.62506C14.0861 3.0424 14.93 3.61505 15.6579 4.34302C16.3853 5.07035 16.9576 5.9171 17.3749 6.88328C17.7916 7.84945 18 8.88836 18 10C18 11.1116 17.7916 12.1505 17.3749 13.1167C16.9576 14.0829 16.3853 14.93 15.6579 15.6579C14.93 16.3853 14.0829 16.9576 13.1167 17.3749C12.1505 17.7916 11.1116 18 10 18ZM10 16.0543C11.6941 16.0543 13.1266 15.4686 14.2973 14.2973C15.4686 13.1266 16.0543 11.6941 16.0543 10C16.0543 8.30586 15.4686 6.87343 14.2973 5.70271C13.1266 4.53136 11.6941 3.94569 10 3.94569C8.30586 3.94569 6.87343 4.53136 5.70271 5.70271C4.53136 6.87343 3.94569 8.30586 3.94569 10C3.94569 11.6941 4.53136 13.1266 5.70271 14.2973C6.87343 15.4686 8.30586 16.0543 10 16.0543Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgRadioSelected
