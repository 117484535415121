import PT from 'prop-types'
import React from 'react'

import { Container, RangeSlider, Wrapper } from './styles'

const Slider = ({ disabled, setValue, value, ...props }) => {
  // because using state/updating prop on value change causes a render and makes the slider unusable and sticky and this seems to work
  // (suggestions from a real React dev welcome as this is not my bag)
  let internalValue = value

  const setInternalValue = val => {
    internalValue = val
  }

  const updateValue = () => {
    setValue(internalValue)
  }

  return (
    <Container {...props}>
      <Wrapper disabled={disabled}>
        <RangeSlider
          disabled={disabled}
          maxValue={1.0}
          minValue={0.0}
          value={value}
          onSlidingComplete={updateValue}
          onValueChange={v => {
            setInternalValue(v)
          }}
        />
      </Wrapper>
    </Container>
  )
}
Slider.propTypes = {
  disabled: PT.bool,
  setValue: PT.func.isRequired,
  value: PT.number.isRequired,
}
Slider.defaultProps = {
  disabled: false,
}
export default Slider
