import React from 'react'

import { ButtonNew } from '../../ButtonNew'
import { modalButtonsDefaultProps, modalButtonsPropTypes } from './prop-types'

export const ModalButtons = ({
  deleteOrRemove,
  cancelText,
  handleCancel,
  handleOk,
  invalid,
  isLoading,
  okDisabled,
  okText,
  pristine,
  buttonContainer,

  showButtons,
}) => {
  let disabled
  if (!okDisabled) {
    disabled = isLoading
  } else if (typeof okDisabled === 'boolean') {
    disabled = okDisabled
  } else {
    disabled = okDisabled(invalid, pristine)
  }
  return (
    <>
      {showButtons && (
        <>
          {cancelText && (
            <ButtonNew
              id="btn-modal-cancel"
              secondary
              type="task"
              onClick={handleCancel}
              minWidth="150px"
              {...buttonContainer}
            >
              {cancelText}
            </ButtonNew>
          )}
          <ButtonNew
            minWidth="150px"
            delete={deleteOrRemove}
            type="task"
            disabled={disabled}
            id="btn-modal-confirm"
            onClick={handleOk}
            {...buttonContainer}
          >
            {okText}
          </ButtonNew>
        </>
      )}
    </>
  )
}

ModalButtons.defaultProps = {
  ...modalButtonsDefaultProps,
}

ModalButtons.propTypes = {
  ...modalButtonsPropTypes,
}
