import { styled, themeGet as theme } from '@lyfta/components-theme'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'

import { Search } from '../../Assets/Sidebar'
import { BasicInput, Sup } from '../../BaseComponents'
import { Box, Flex } from '../../Components'

export const Container = styled(Flex)`
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`
export const Content = styled(Flex)`
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`
export const Controls = styled(Flex).attrs(() => ({ mb: 2 }))`
  display: inline-block;
`

export const SearchResults = styled(Flex).attrs(() => ({
  m: 3,
  pl: 4,
  pt: 4,
}))`
  flex-direction: column;
  position: relative;
  flex: 1;
  overflow: hidden;
  background: ${theme('colors.white')};
  border-radius: 8px;
  justify-content: start;
  box-shadow: ${shadows.skim};
`

export const SearchIcon = styled(Box).attrs(() => ({
  as: Search,
}))`
  fill: ${theme('colors.gray')};
  position: absolute;
  left: 12px;
`

export const InputContainer = styled(Flex).attrs(() => ({
  py: 1,
  pl: 44,
  pr: 3,
  m: 3,
}))`
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  min-height: 48px;
  border-radius: 30px;
  background-color: ${theme('colors.white')};
  box-shadow: ${shadows.skim};
  position: relative;
`

export const InputText = styled(BasicInput)`
  flex-grow: 1;
  max-width: unset;
  min-width: 100px;
  margin-left: 15px;
  border: none;
  outline: none;
  color: ${theme('colors.black_600')};
`
export const Title = styled(Flex).attrs(() => ({}))`
  font-size: 23px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.5px;
  align-items: flex-end;
  color: ${props => (props.color ? props.color : theme('colors.black'))};
`
export const SubTitle = styled(Flex).attrs(() => ({ ml: 2 }))`
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  height: 100%;
  letter-spacing: 0.5px;
  color: ${props => (props.color ? props.color : theme('colors.black'))};
  align-items: flex-end;
`
export const Interpunct = styled(Sup).attrs(() => ({ mb: '8px', mr: 2 }))`
  font-size: 30px;
`
export const TitleContainer = styled(Flex).attrs(() => ({}))`
  flex-direction: row;
  margin: auto 0;
  height: unset;
  width: unset;
  border-radius: 8px;
  align-items: flex-end;
`
