import { styled, themeGet as theme } from '@lyfta/components-theme'
import { AnimatedFlex, Flex } from '@lyfta/components-ui'

export { Submit } from '../../../styles'

export const Container = styled(AnimatedFlex).attrs(p => ({
  flexDirection: 'column',
  alignItems: 'center',
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  background: theme('colors.sa_gradient_h')(p),
  px: 5,
  pt: 5,
  pb: 6,
  minHeight: 350,
}))``

export const HeaderContainer = styled(Flex).attrs({
  flexDirection: 'row',
  flex: 0,
  alignItems: 'flex-start',
  pb: 4,
})`
  border-bottom-color: ${theme('colors.black_600')};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  justify-content: space-between;
`

export const BodyContainer = styled(Flex).attrs({
  flexDirection: 'column',
  flex: 1,
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'left',
  padding: 0,
  margin: 0,
  width: '100%',
  minHeight: 190,
})``
