import * as React from 'react'

function SvgSave(props) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.3723 5.91786L18.0821 2.62769C17.8836 2.42807 17.6475 2.2698 17.3874 2.16206C17.1273 2.05432 16.8485 1.99924 16.567 2.00001H4.85714C4.09938 2.00001 3.37266 2.30103 2.83684 2.83685C2.30102 3.37266 2 4.09939 2 4.85715V19.1429C2 19.9006 2.30102 20.6273 2.83684 21.1632C3.37266 21.699 4.09938 22 4.85714 22H19.1429C19.9006 22 20.6273 21.699 21.1632 21.1632C21.699 20.6273 22 19.9006 22 19.1429V7.43304C22.0008 7.15153 21.9457 6.87266 21.8379 6.61257C21.7302 6.35249 21.5719 6.11636 21.3723 5.91786V5.91786ZM5.92857 5.57144H13.7857C13.8804 5.57144 13.9713 5.60906 14.0382 5.67604C14.1052 5.74302 14.1429 5.83386 14.1429 5.92858V8.07143C14.1429 8.16615 14.1052 8.257 14.0382 8.32397C13.9713 8.39095 13.8804 8.42858 13.7857 8.42858H5.92857C5.83385 8.42858 5.74301 8.39095 5.67603 8.32397C5.60905 8.257 5.57143 8.16615 5.57143 8.07143V5.92858C5.57143 5.83386 5.60905 5.74302 5.67603 5.67604C5.74301 5.60906 5.83385 5.57144 5.92857 5.57144V5.57144ZM12.1674 19.8531C11.4476 19.8869 10.7343 19.7022 10.1214 19.3231C9.50852 18.9441 9.02462 18.3885 8.73331 17.7294C8.44199 17.0702 8.35688 16.3384 8.48917 15.63C8.62146 14.9216 8.96496 14.2698 9.47453 13.7602C9.9841 13.2507 10.6359 12.9072 11.3443 12.7749C12.0527 12.6426 12.7845 12.7277 13.4437 13.019C14.1028 13.3103 14.6584 13.7942 15.0374 14.4071C15.4164 15.0201 15.6012 15.7333 15.5674 16.4531C15.5251 17.3412 15.1533 18.1817 14.5246 18.8103C13.8959 19.439 13.0555 19.8108 12.1674 19.8531V19.8531Z"
        fill="#0C0C0C"
      />
      <path
        d="M12 18.75C13.2426 18.75 14.25 17.7426 14.25 16.5C14.25 15.2574 13.2426 14.25 12 14.25C10.7574 14.25 9.75 15.2574 9.75 16.5C9.75 17.7426 10.7574 18.75 12 18.75Z"
        fill="#0C0C0C"
      />
    </svg>
  )
}

export default SvgSave
