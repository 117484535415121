import React from 'react'

function SvgMascotDefault(props) {
  return (
    <svg
      width="252"
      height="270"
      viewBox="0 0 252 270"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.7105 75.0631C61.1978 77.8318 68.182 86.6301 68.182 96.9819V141.152C68.182 153.705 57.9107 163.976 45.3572 163.976H25.0113C12.4578 163.976 2.18652 153.705 2.18652 141.152V96.9819C2.18652 84.8422 11.7921 74.8373 23.7777 74.191L25.4125 70.8922C34.9832 51.5832 50.065 35.3521 68.5997 24.3617C66.773 18.5055 69.5465 12.3344 75.5645 9.32639C80.7413 6.73797 88.0094 4.83566 93.635 3.54582C102.727 1.46035 112.247 0.141429 121.578 0.0106025C122.997 -0.00974828 124.416 -0.00102653 125.834 0.0387059C127.251 -0.00102653 128.67 -0.00974828 130.089 0.0106025C139.42 0.141429 148.941 1.46035 158.032 3.54582C163.658 4.83566 170.925 6.73797 176.103 9.32639C182.121 12.3354 184.894 18.5065 183.068 24.3617C201.602 35.3521 216.683 51.5832 226.255 70.8922L227.89 74.191C239.875 74.8373 249.48 84.8422 249.48 96.9819V141.152C249.48 153.705 239.209 163.976 226.655 163.976H206.309C193.756 163.976 183.484 153.705 183.484 141.152V96.9819C183.484 86.6301 190.469 77.8328 199.956 75.0631C191.739 62.8275 180.615 52.8334 167.531 45.9723L162.676 43.4265L162.167 43.379L161.111 42.9284C149.954 38.1711 137.959 35.7484 125.833 35.7484C113.706 35.7484 101.71 38.1702 90.5533 42.9284L89.498 43.379L88.9882 43.4265L84.1331 45.9723C71.0495 52.8343 59.9254 62.8275 51.7086 75.0631H51.7105Z"
        fill="#050B15"
      />
      <path
        d="M226.656 81.5078H206.31C197.764 81.5078 190.836 87.3851 190.836 94.635V143.499C190.836 150.749 197.764 156.626 206.31 156.626H226.656C235.202 156.626 242.13 150.749 242.13 143.499V94.635C242.13 87.3851 235.202 81.5078 226.656 81.5078Z"
        fill="#C3AAF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.454 77.832H206.31C195.771 77.832 187.161 86.4423 187.161 96.9811V141.151C187.161 151.69 195.771 160.301 206.31 160.301H228.454L229.225 157.654C232.838 145.262 234.38 131.954 234.38 119.067C234.38 106.179 232.838 92.8722 229.225 80.4786L228.454 77.832Z"
        fill="#050B15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M225.697 81.5078H206.31C197.8 81.5078 190.836 88.4716 190.836 96.9821V141.152C190.836 149.663 197.8 156.626 206.31 156.626H225.697C228.869 145.746 230.704 132.871 230.704 119.068C230.704 105.264 228.868 92.3877 225.696 81.5088L225.697 81.5078Z"
        fill="#455368"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.063 30.5312C196.705 40.9227 210.791 56.2439 219.67 74.1564H208.114C198.922 59.2306 186.019 47.3671 170.946 39.4623L179.062 30.5312H179.063Z"
        fill="#C3AAF5"
      />
      <path
        d="M45.3583 81.5078H25.0124C16.4662 81.5078 9.53809 87.3851 9.53809 94.635V143.499C9.53809 150.749 16.4662 156.626 25.0124 156.626H45.3583C53.9045 156.626 60.8326 150.749 60.8326 143.499V94.635C60.8326 87.3851 53.9045 81.5078 45.3583 81.5078Z"
        fill="#C3AAF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.215 77.832H45.3585C55.8973 77.832 64.5086 86.4423 64.5086 96.9811V141.151C64.5086 151.69 55.8983 160.301 45.3585 160.301H23.215L22.4436 157.654C18.8309 145.262 17.2891 131.954 17.2891 119.067C17.2891 106.179 18.8309 92.8722 22.4436 80.4786L23.215 77.832Z"
        fill="#050B15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9721 81.5078H45.3586C53.8691 81.5078 60.8329 88.4716 60.8329 96.9821V141.152C60.8329 149.663 53.8691 156.626 45.3586 156.626H25.9721C22.8003 145.746 20.9648 132.871 20.9648 119.068C20.9648 105.264 22.8013 92.3877 25.9731 81.5088L25.9721 81.5078Z"
        fill="#455368"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.1779 177.435C39.1045 165.839 33.3588 152.797 30.8751 138.517C27.4775 118.988 30.6745 98.8188 39.6268 81.1621C56.141 48.5941 89.3466 28.3984 125.834 28.3984C162.32 28.3984 195.527 48.5941 212.04 81.1621C220.994 98.8188 224.19 118.988 220.792 138.517C218.307 152.797 212.563 165.84 204.489 177.435C207.639 180.891 214.961 182.732 219.135 183.206C230.613 184.513 251.906 185.135 251.665 201.316C251.614 204.779 250.7 208.085 248.82 211.008C241.129 222.96 220.902 223.047 208.355 222.314C200.453 221.853 192.465 220.829 184.596 219.467C186.187 222.545 188.087 225.552 190.205 228.551C193.223 232.823 196.601 236.879 199.515 241.206C202.468 245.592 205.045 250.66 204.056 256.116C203.504 259.161 201.938 261.762 199.7 263.869C197.024 266.388 193.861 268.195 190.295 269.106C172.967 273.536 154.571 255.585 144.951 243.264C140.277 237.278 134.974 229.16 131.367 220.786C127.826 221.135 123.838 221.135 120.298 220.786C116.692 229.161 111.389 237.278 106.714 243.264C97.0944 255.585 78.6983 273.535 61.3702 269.106C57.8039 268.194 54.6408 266.388 51.9652 263.869C49.7276 261.762 48.1615 259.161 47.6092 256.116C46.6197 250.66 49.1975 245.592 52.1503 241.206C55.0643 236.879 58.4426 232.824 61.4603 228.551C63.5777 225.552 65.4781 222.545 67.0693 219.467C59.2004 220.829 51.2122 221.853 43.3103 222.314C30.7626 223.047 10.535 222.96 2.84531 211.008C0.965287 208.086 0.0524107 204.78 8.02809e-05 201.316C-0.241221 185.134 21.0524 184.513 32.5302 183.206C36.7041 182.732 44.0265 180.891 47.176 177.435H47.1779Z"
        fill="#050B15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.834 35.75C49.7761 35.75 1.78225 128.441 68.7439 191.055C63.1281 188.794 57.9609 185.701 53.4799 181.334C53.4799 181.334 48.3137 188.81 33.3637 190.511C18.4127 192.212 7.23043 193.063 7.35156 201.207C7.67814 223.06 60.0328 213.52 73.9615 210.689C76.9831 205.916 78.4764 200.633 79.3098 195.13C82.7113 230.824 45.3541 247.548 57.0054 258.519C76.4442 276.823 117.072 226.245 117.234 203.274C118.051 206.409 118.416 209.645 118.343 213.105C122.521 213.869 129.147 213.869 133.325 213.105C133.252 209.644 133.616 206.409 134.433 203.274C134.595 226.245 175.223 276.823 194.662 258.519C206.313 247.548 168.956 230.825 172.357 195.13C173.191 200.634 174.684 205.916 177.706 210.689C191.634 213.52 243.989 223.06 244.316 201.207C244.438 193.063 233.254 192.213 218.303 190.511C203.352 188.809 198.187 181.334 198.187 181.334C193.706 185.702 188.539 188.794 182.923 191.055C249.886 128.441 201.891 35.75 125.835 35.75H125.834Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.9788 145.43C44.3746 161.229 53.6177 176.908 68.7441 191.053C63.2116 188.826 58.1152 185.791 53.6807 181.526C54.3949 184.343 59.6415 207.251 41.3966 207.666C22.6468 208.093 15.8031 205.907 7.75488 204.15C12.64 222.153 60.7046 213.382 73.9616 210.687C76.9832 205.914 78.4766 200.631 79.31 195.128C78.9592 192.239 90.8033 187.092 97.0462 197.15C114.372 225.066 72.0923 256.319 62.5352 251.83C58.938 250.141 59.9458 242.955 59.9545 242.893C55.2021 249.398 52.6011 254.369 57.0056 258.516C76.4444 276.82 117.072 226.242 117.234 203.271C118.051 206.406 118.416 209.642 118.344 213.102C120.433 213.484 123.134 213.675 125.835 213.675C128.536 213.675 131.236 213.484 133.326 213.102C133.253 209.641 133.617 206.406 134.434 203.271C134.596 226.242 175.224 276.82 194.663 258.516C199.067 254.368 196.467 249.398 191.714 242.893C191.723 242.955 192.73 250.141 189.133 251.83C179.577 256.319 137.296 225.066 154.622 197.15C160.865 187.092 172.708 192.239 172.358 195.128C173.192 200.632 174.685 205.914 177.707 210.687C190.964 213.382 239.028 222.152 243.914 204.15C235.864 205.907 229.021 208.092 210.272 207.666C192.027 207.251 197.273 184.343 197.987 181.526C193.552 185.792 188.456 188.826 182.923 191.053C198.05 176.908 207.294 161.229 211.689 145.43C197.355 168.882 161.381 184.938 125.834 184.938C90.2868 184.938 54.3125 168.882 39.9788 145.43Z"
        fill="#DAD6D4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.517 167.906C118.064 168.388 121.972 168.754 125.834 168.825C129.696 168.754 133.604 168.388 137.151 167.906C134.019 170.816 129.835 172.329 125.834 172.335C121.831 172.33 117.648 170.817 114.517 167.906Z"
        fill="#DAD6D4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.6714 36.1653L87.6597 36.1663L76.4203 23.7989C76.4203 23.7989 73.0169 18.8159 78.8517 15.8979C84.6866 12.981 105.779 6.78179 125.835 7.3894C145.891 6.78082 166.983 12.981 172.818 15.8979C178.652 18.8149 175.248 23.7989 175.248 23.7989L164.009 36.1663L163.997 36.1653C152.159 31.1174 139.218 28.3962 125.835 28.3962C112.452 28.3962 99.5107 31.1164 87.6723 36.1653H87.6714Z"
        fill="#455368"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.6045 30.5312C54.9634 40.9227 40.8768 56.2439 31.998 74.1564H43.5544C52.7461 59.2306 65.6485 47.3671 80.7216 39.4623L72.6055 30.5312H72.6045Z"
        fill="#C3AAF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.9355 144.215C103.573 154.994 114.653 160.066 125.833 159.758C137.015 160.067 148.094 154.994 152.731 144.215C145.045 150.841 135.478 154.123 125.833 154.06C116.189 154.123 106.621 150.842 98.9355 144.215Z"
        fill="#050B15"
      />
      <path
        d="M78.1079 131.499C90.3005 131.499 100.185 120.262 100.185 106.4C100.185 92.5381 90.3005 81.3008 78.1079 81.3008C65.9153 81.3008 56.0312 92.5381 56.0312 106.4C56.0312 120.262 65.9153 131.499 78.1079 131.499Z"
        fill="#050B15"
      />
      <path
        d="M80.3981 131.498C91.326 131.498 100.185 121.427 100.185 109.003C100.185 96.5793 91.326 86.5078 80.3981 86.5078C69.4701 86.5078 60.6113 96.5793 60.6113 109.003C60.6113 121.427 69.4701 131.498 80.3981 131.498Z"
        fill="white"
      />
      <path
        d="M83.5279 123.716C89.1481 123.716 93.7042 118.432 93.7042 111.913C93.7042 105.394 89.1481 100.109 83.5279 100.109C77.9077 100.109 73.3516 105.394 73.3516 111.913C73.3516 118.432 77.9077 123.716 83.5279 123.716Z"
        fill="#050B15"
      />
      <path
        d="M79.348 109.006C82.3265 109.006 84.741 107.196 84.741 104.964C84.741 102.732 82.3265 100.922 79.348 100.922C76.3696 100.922 73.9551 102.732 73.9551 104.964C73.9551 107.196 76.3696 109.006 79.348 109.006Z"
        fill="white"
      />
      <path
        d="M173.56 131.499C185.753 131.499 195.637 120.262 195.637 106.4C195.637 92.5381 185.753 81.3008 173.56 81.3008C161.367 81.3008 151.483 92.5381 151.483 106.4C151.483 120.262 161.367 131.499 173.56 131.499Z"
        fill="#050B15"
      />
      <path
        d="M171.27 131.498C182.198 131.498 191.057 121.427 191.057 109.003C191.057 96.5793 182.198 86.5078 171.27 86.5078C160.342 86.5078 151.483 96.5793 151.483 109.003C151.483 121.427 160.342 131.498 171.27 131.498Z"
        fill="white"
      />
      <path
        d="M168.14 123.716C173.76 123.716 178.317 118.432 178.317 111.913C178.317 105.394 173.76 100.109 168.14 100.109C162.52 100.109 157.964 105.394 157.964 111.913C157.964 118.432 162.52 123.716 168.14 123.716Z"
        fill="#050B15"
      />
      <path
        d="M172.32 109.006C175.298 109.006 177.713 107.196 177.713 104.964C177.713 102.732 175.298 100.922 172.32 100.922C169.341 100.922 166.927 102.732 166.927 104.964C166.927 107.196 169.341 109.006 172.32 109.006Z"
        fill="white"
      />
    </svg>
  )
}

export default SvgMascotDefault
