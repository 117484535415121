import { styled } from '@lyfta/components-theme'
import { themeGet as theme } from '@styled-system/theme-get'

import { ButtonNew } from '../../../Components'

export const TableButton = styled(ButtonNew).attrs(() => ({
  pl: 0,
  pt: 0,
  pb: 0,

  hover: {
    textDecorationLine: 'none',
    fontWeight: 700,
  },
}))`
  color: ${p => (p.colorText ? p.colorText : theme('colors.secondary'))};
  fill: ${theme('colors.secondary')};
  background: none;
  box-shadow: none;
  min-height: 100%;
  &:hover:enabled {
    background: none;
    font-weight: bold;
  }
`
