import { lighten, styled } from '@lyfta/components-theme'
import {
  RadioSelected,
  RadioUnselected,
  TouchableFlex,
} from '@lyfta/components-ui'
import PropTypes from 'prop-types'
import * as React from 'react'

function SvgCheckRadio({ checked, disabled, ...props }) {
  return (
    <TouchableFlex alignItems="center" disabled={disabled} {...props}>
      {checked ? (
        <RadioSelected width="24px" height="24px" />
      ) : (
        <RadioUnselected width="24px" height="24px" />
      )}
    </TouchableFlex>
  )
}
SvgCheckRadio.defaultProps = {
  disabled: false,
}
SvgCheckRadio.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
}
const StyledComponent = styled(SvgCheckRadio)`
  ${({ disabled }) => {
    if (disabled) {
      return ''
    }
    return `
  svg:hover {
    #check {
      visibility: visible;
      fill: ${p => (p.checked ? p.outlineColor : lighten(0.6, p.outlineColor))};
    }
  }
`
  }}
`

StyledComponent.defaultProps = SvgCheckRadio.defaultProps

export default StyledComponent
