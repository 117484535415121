import get from 'lodash/get'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { Box } from '../../Box'

class YearMonthForm extends PureComponent {
  handleChangeMonth = e => {
    const { date, onChange } = this.props
    const month = get(e, 'target.value')

    onChange(new Date(new Date(date).getFullYear(), month))
  }

  handleChangeYear = e => {
    const { date, onChange } = this.props
    const year = get(e, 'target.value')

    onChange(new Date(year, new Date(date).getMonth()))
  }

  render = () => {
    const { date, localeUtils } = this.props

    const months = localeUtils.getMonths()
    const currentYear = new Date().getFullYear()
    const fromMonth = new Date(currentYear, 0)
    const toMonth = new Date(currentYear + 10, 11)

    const years = []
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      years.push(i)
    }

    return (
      <Box className="DayPicker-Caption" id="YearMonthForm">
        <select
          name="month"
          value={date.getMonth()}
          onChange={this.handleChangeMonth}
        >
          {map(months, (month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>
        <select
          name="year"
          value={date.getFullYear()}
          onChange={this.handleChangeYear}
        >
          {map(years, year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </Box>
    )
  }
}

YearMonthForm.propTypes = {
  date: PropTypes.object.isRequired,
  localeUtils: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default YearMonthForm
