import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import { ADD_CONTENT_HIDE, ADD_CONTENT_SHOW } from '../../Actions/ui'

const initialState = {
  open: false,
  contentId: null,
}

const show = (state, { id }) => state.merge({ open: true, contentId: id })
const hide = state => state.merge({ open: false, contentId: null })

const handlers = {
  [ADD_CONTENT_SHOW]: show,
  [ADD_CONTENT_HIDE]: hide,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
