import { getTranslation } from '@lyfta/components-i18n'
import PT from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { questTrackerData } from '../../../../../Store/Actions/questTracker'
import { InformationText } from '../InformationText'
import { Submit } from '../Submit'
import { DiscussionText, DiscussionTextContainer } from './styles'

const Discussion = ({
  disabled,
  task: { description, callToAction, id, answerData },
}) => {
  const [discussionText, setDiscussionText] = useState('')
  const dispatch = useDispatch()

  const saveResponse = useCallback(() => {
    dispatch(questTrackerData.saveResponse(id, { answer: discussionText }))
  }, [discussionText])

  useEffect(() => {
    setDiscussionText(answerData?.data?.answer)
  }, [answerData])

  let submitDisabled
  if (disabled) {
    // Then no text can be entered, so enable the button by default
    submitDisabled = false
  } else {
    submitDisabled = !discussionText
  }

  return (
    <>
      <InformationText textSource={description} />
      <DiscussionTextContainer>
        <DiscussionText
          aria-label={getTranslation('quest.writeAnswer')}
          disabled={disabled}
          id="textarea-write-answer"
          rows={8}
          value={discussionText?.toString()}
          onChangeText={setDiscussionText}
        />
      </DiscussionTextContainer>
      <Submit
        buttonText={callToAction}
        submitDisabled={submitDisabled}
        onClick={saveResponse}
      />
    </>
  )
}
Discussion.propTypes = {
  disabled: PT.bool,
  task: PT.object.isRequired,
}
Discussion.defaultProps = {
  disabled: false,
}
export default Discussion
