import { css, styled, themeGet } from '@lyfta/components-theme'

import { Box } from '../../Box'
import { Flex } from '../../Flex'

export const Wrapper = styled(Box)``
export const Row = styled(Flex)`
  flex-direction: row;
`
export const Container = styled(Flex)`
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid ${themeGet('colors.gray')};
  border-radius: 3px;
  user-select: none;

  ${props =>
    props.error &&
    css`
      border: 2px solid ${themeGet('colors.error')};
    `};

  > input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    margin: 0;
    width: 18px;
    height: 18px;
  }

  :before {
    cursor: pointer;
    opacity: 0;
    ${props =>
      props.checked &&
      css`
        opacity: 1;
      `};
    content: '';
    position: absolute;
    width: 11px;
    height: 8px;
    left: 3px;
    top: 2px;
    border-style: solid;
    border-width: 2px;
    border-bottom-color: ${themeGet('colors.gray')};
    border-left-color: ${themeGet('colors.gray')};
    border-top-color: ${themeGet('colors.clear')};
    border-right-color: ${themeGet('colors.clear')};
    transform: rotate(-45deg);
  }

  :hover:before {
    opacity: 0.1;
    ${props =>
      props.checked &&
      css`
        opacity: 1;
      `};
  }

  > input[type='checkbox']:checked {
    border: 2px solid #0e34a0;

    :after {
      opacity: 1;
      border: 2px solid #0e34a0;
      border-top: none;
      border-right: none;
    }
  }
`
export const Description = styled(Box).attrs(() => ({}))`
  font-weight: normal;
  color: ${themeGet('colors.font.accent')};
  transition: all ${themeGet('transitionTime')} ease;
  user-select: none;

  ${props =>
    props.active &&
    css`
      color: ${themeGet('colors.font.accent')};
    `};
`
