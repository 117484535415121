import { I18n, getTranslation } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import {
  Body1Text,
  ControlsContainer,
  SelectedIcon,
  SelectedIconContainer,
  SubtitleButton,
  SubtitleButtonContainer,
} from './styles'

// import theme from '@lyfta/components-theme/src/themes/Main'

const speed = [
  {
    label: getTranslation('videoPlayer.labels.speed.050x'),
    value: 0.5,
  },
  {
    label: getTranslation('videoPlayer.labels.speed.075x'),
    value: 0.75,
  },
  {
    label: getTranslation('videoPlayer.labels.speed.normal'),
    value: 1.0,
  },
  {
    label: getTranslation('videoPlayer.labels.speed.125x'),
    value: 1.25,
  },
  {
    label: getTranslation('videoPlayer.labels.speed.150x'),
    value: 1.5,
  },
  {
    label: getTranslation('videoPlayer.labels.speed.200x'),
    value: 2,
  },
]

export const SpeedSelector = ({ player }) => {
  const myTextTrackSettings = player.textTrackSettings.getValues()

  const [currentSpeed, setCurrentSpeed] = useState(
    myTextTrackSettings.speed || 1.0,
  )

  useEffect(() => {
    // const newTextTrackSettingsValues = {
    //   speed: currentSpeed,
    // }
    player.playbackRate(currentSpeed)

    // player.textTrackSettings.setValues(newTextTrackSettingsValues)
    // player.textTrackSettings.updateDisplay()
  }, [currentSpeed])

  const handleClick = speedValue => {
    player.options_.updateUserPreferences({
      speedValue,
    })
    setCurrentSpeed(speedValue)
  }

  return (
    <>
      <Body1Text>
        <I18n i18nKey="videoPlayer.labels.speedLabel" />
      </Body1Text>
      <ControlsContainer className="subtitle-controls-container">
        {speed.map(sp => (
          <SubtitleButtonContainer
            className="subtitle-button-container"
            minWidth="100%"
            onClick={() => handleClick(sp.value)}
          >
            <SelectedIconContainer className="subtitle-selected-icon-container">
              {sp.value === currentSpeed ? <SelectedIcon /> : null}
            </SelectedIconContainer>
            <SubtitleButton
              className="subtitle-button"
              selected={sp.value === currentSpeed}
            >
              {sp.label}
            </SubtitleButton>
          </SubtitleButtonContainer>
        ))}
      </ControlsContainer>
    </>
  )
}

SpeedSelector.propTypes = {
  player: PropTypes.object.isRequired,
}
