import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { LogOut } from '../../../Assets'
import { LogoutButton } from '../../../Blocks'
import { Icon } from '../../../Components'
import Modal from '../../../Components/Modal'
import { MenuButton } from './styles'

export const LogoutItem = ({ t }) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  const toggleLogoutModal = () => {
    setShowLogoutModal(state => !state)
  }

  return (
    <>
      <LogoutButton>
        {performLogout => (
          <>
            {showLogoutModal && (
              <Modal
                hideClose
                isOpen={showLogoutModal}
                key="tableLogoutModal"
                content={{
                  title: 'actions.logout',
                  description: 'core.modals.logoutText1',
                }}
                buttons={{
                  okText: t('core.modals.logoutConfirm'),
                }}
                onCallback={performLogout}
                onDismissModal={toggleLogoutModal}
              />
            )}
            <MenuButton
              id="logoutBtn"
              style={{ transform: 'rotate(180deg)' }}
              title={t('actions.logout')}
              onClick={toggleLogoutModal}
            >
              <Icon fill="white" glyph={LogOut} height="20px" />
            </MenuButton>
          </>
        )}
      </LogoutButton>
    </>
  )
}
LogoutItem.propTypes = {
  t: PropTypes.func.isRequired,
}
