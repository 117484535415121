import { css, styled } from '@lyfta/components-theme'
import { themeGet as theme } from '@styled-system/theme-get'
import { get, merge } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { Save as save } from '../../Assets'
import { Button } from '../Button'
import { Flex } from '../Flex'
import { Icon } from '../Icon'

/* eslint-disable */

const SaveButton = styled(Flex).attrs(p => {
  return merge(
    {
      as: Button,
    },
    p,
  )
})`
  ${p => (p.float ? `float: ${p.float}` : '')};
  color: ${p =>
    p.color
      ? theme(`colors.${p.color}`)
      : !p.rank && !p.delete
      ? theme('colors.white')
      : ''};
  ${p =>
    p.rank === 'cta'
      ? css`
          padding-right: 32px;
          padding-left: 28px;
        `
      : css`
          padding-right: 20px;
          padding-left: 16px;
        `}
  &:focus,
  &:hover {
    ${p =>
      !p.onColouredBg && !p.delete && !p.rank
        ? 'opacity: 0.7;'
        : !p.disabled && !p.delete && !p.rank
        ? 'background-color: rgba(255, 255, 255, 0.2);'
        : ''}
  }
`
/* eslint-enable */

export const ButtonWithIcon = ({
  onClick,
  children,
  glyph,
  fill,
  ...props
}) => {
  return (
    <SaveButton onClick={onClick} {...props}>
      <>
        {glyph && (
          <Icon
            fill={fill}
            glyph={glyph}
            height={20}
            key={`${get(props, 'key', 'btn')}-icon`}
            mr={children ? '10px' : 0}
            width={20}
          />
        )}
        {React.Children.map(children, child => {
          if (child.key) {
            return child
          }
          return <React.Fragment key={props.id}>{child}</React.Fragment>
        })}
      </>
    </SaveButton>
  )
}

ButtonWithIcon.defaultProps = {
  fill: 'white',
  glyph: save,
  id: null,
  onClick: null,
}

ButtonWithIcon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.func, PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  fill: PropTypes.string,
  glyph: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  id: PropTypes.string,
  onClick: PropTypes.func,
}

SaveButton.displayName = 'SaveButton'
