export default {
  root: '/',
  lessonSelection: '/lesson-selection',
  lessonPlan: id => `/lesson-plan/${id || ':lessonPlanId'}`,

  // Public
  forgot: '/forgot-password',
  loginWithPassword: '/login-with-password',
  loginToLesson: '/login-to-lesson',
  chooseStudent: '/choose-student',
  confirm: '/confirm',
  student: '/student',
}
