import { getTranslation } from '@lyfta/components-i18n'
import {
  RotateOff as RotateOffIcon,
  RotateOn as RotateOnIcon,
} from '@lyfta/components-student'
import { rotation } from '@lyfta/components-student/src/Store/Actions/ui'
import {
  getIsRotationActive,
  getIsRotationEnabled,
} from '@lyfta/components-student/src/Store/Selectors/UI/rotation'
import { ButtonNew } from '@lyfta/components-ui'
import React from 'react'
import { useSelector } from 'react-redux'

import { callbackDispatch } from '../../../../utils/callbackDispatch'
import { PrimaryButtonIcon } from '../styles'

export const RotateButton = () => {
  const isRotationEnabled = useSelector(getIsRotationEnabled)
  const rotating = useSelector(getIsRotationActive)

  return (
    <ButtonNew
      id="btn-rotate-student-app"
      leadingIcon={
        !isRotationEnabled ? (
          <PrimaryButtonIcon glyph={RotateOnIcon} />
        ) : (
          <PrimaryButtonIcon glyph={RotateOffIcon} svgProps={{ rotating }} />
        )
      }
      title={getTranslation('header.rotation')}
      type="icon"
      onClick={callbackDispatch(rotation, 'toggle')}
    >
      {getTranslation('header.rotation')}
    </ButtonNew>
  )
}
