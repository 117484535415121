import * as React from 'react'

function SvgPg(props) {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.6762 20.2159L15.1116 1.26138C14.6704 0.474074 13.886 0 13.0036 0C12.1211 0 11.3368 0.474074 10.8955 1.26138L0.330911 20.2159C-0.110304 21.0032 -0.110304 21.9513 0.330911 22.7386C0.772125 23.5259 1.55651 24 2.43894 24H23.56C24.4425 24 25.2268 23.5259 25.6681 22.7386C26.1093 21.9429 26.1093 21.0032 25.6762 20.2159Z"
        fill="black"
      />
      <path
        d="M25.3167 20.4275L14.7603 1.47302C14.3926 0.812698 13.7389 0.42328 13.0036 0.42328C12.2682 0.42328 11.6146 0.812698 11.2469 1.47302L0.682249 20.4275C0.31457 21.0878 0.31457 21.8751 0.682249 22.527C1.04993 23.1873 1.70358 23.5767 2.43894 23.5767H23.5601C24.2954 23.5767 24.9491 23.1873 25.3167 22.527C25.6844 21.8667 25.6844 21.0794 25.3167 20.4275Z"
        fill="white"
      />
      <path
        d="M13.8778 1.99788C13.6817 1.65079 13.3386 1.47302 13.0036 1.47302C12.6686 1.47302 12.3254 1.65079 12.1293 1.99788L1.56468 20.9524C1.17249 21.655 1.66273 22.527 2.43894 22.527H23.5601C24.3363 22.527 24.8265 21.655 24.4343 20.9524L13.8778 1.99788Z"
        fill="#FBAD00"
      />
      <path
        d="M8.34631 16.4487H9.38398C9.6863 16.4487 9.95593 16.4487 10.201 16.381C11.0998 16.1524 11.157 15.3058 11.157 15.018C11.157 14.4254 10.9037 14.1206 10.773 13.9937C10.4135 13.672 9.9069 13.6635 9.44118 13.6635H8.34631V16.4487ZM6.88376 12.3852H9.4902C9.91507 12.3852 10.3645 12.3852 10.8138 12.5122C11.5655 12.7238 12.6277 13.4349 12.6277 15.0519C12.6277 16.1185 12.1457 17.2614 10.7648 17.5831C10.3399 17.6847 9.93141 17.6847 9.46569 17.6847H8.33814V20.3937H6.88376V12.3852Z"
        fill="white"
      />
      <path
        d="M20.0058 16.0169V20.3937H18.7721V19.7164C18.0857 20.419 17.3013 20.5968 16.6559 20.5968C14.27 20.5968 13.0117 18.6243 13.0117 16.4656C13.0117 13.9513 14.6214 12.182 16.7457 12.182C18.3554 12.182 19.5401 13.2063 20.0058 14.5439L18.625 15.1196C18.4861 14.6794 18.0694 13.528 16.7539 13.528C15.2995 13.528 14.5887 14.8402 14.5887 16.4233C14.5887 18.1841 15.5201 19.1915 16.7784 19.1915C17.5873 19.1915 18.5514 18.7513 18.6822 17.3291H16.7539V16.0169H20.0058Z"
        fill="white"
      />
    </svg>
  )
}

export default SvgPg
