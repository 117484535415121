import { omit, pick } from '@styled-system/props'
import PropTypes from 'prop-types'
import React from 'react'

import { StyledReactSelect, Wrapper } from './styles'

const ReactSelectDropdown = props => {
  const { title, showRadioIcon } = props
  const styledSystemProps = pick(props)
  const propsWithoutStyledSystemProps = omit(props)
  return (
    <Wrapper
      className="reactselectdropdown-wrapper"
      title={title}
      {...styledSystemProps}
    >
      <StyledReactSelect
        {...propsWithoutStyledSystemProps}
        showRadioIcon={showRadioIcon}
      />
    </Wrapper>
  )
}

ReactSelectDropdown.defaultProps = {
  extendComponents: {},
  extendStyles: {},
  isSearchable: false,
  showRadioIcon: false,
  title: null,
}

ReactSelectDropdown.propTypes = {
  extendComponents: PropTypes.object,
  extendStyles: PropTypes.object,
  isSearchable: PropTypes.bool,
  title: PropTypes.string,
  showRadioIcon: PropTypes.bool,
}

export default ReactSelectDropdown
