import PropTypes from 'prop-types'
import React from 'react'
import { renderRoutes } from 'react-router-config'
import { useHistory } from 'react-router-dom'

import { useRouteSetup } from '../../Hooks/useRouteSetup'
import { Container, ContentContainer, TabContainer } from './styles'
import { Tabs } from './Tabs'

export const TabbedLayout = ({ route, children }) => {
  const history = useHistory()

  const { isNew, accessAllowed, permissions } = useRouteSetup(route)

  const showTabs = route.hideTabsOnNew ? isNew : true
  if (!route) return null
  if (accessAllowed === false) history.push('/404')
  return (
    <Container>
      {showTabs && (
        <TabContainer>
          <Tabs route={route} userPermissions={permissions} />
        </TabContainer>
      )}
      {children}
      <ContentContainer>{renderRoutes(route.routes)}</ContentContainer>
    </Container>
  )
}

TabbedLayout.defaultProps = { children: null }
TabbedLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
  route: PropTypes.object.isRequired,
}
