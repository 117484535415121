import { styled, themeGet } from '@lyfta/components-theme'

import { Box } from '../../Box'
import { Flex } from '../../Flex'

export const ShareBox = styled(Flex).attrs({ px: 3, py: 2, mt: 2 })`
  border-radius: 5px;
  border: 1px solid ${themeGet('colors.black_800')};
  align-items: center;
  justify-content: space-between;
`
export const LinkSocialMedia = styled.a`
  text-decoration: none;
`
export const LinkBox = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
`

export const TitleModal = styled(Box).attrs(() => ({}))`
  width: 100%;
  letter-spacing: 0.5px;
  width: 100%;
  font-weight: 700;
  font-size: 23px;
  line-height: 32px;
  color: ${props =>
    props.color ? themeGet(props.color) : themeGet('colors.black_800')};
`
export const DescriptionModal = styled(Box).attrs(() => ({}))`
  width: 100%;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '500')};
  font-size: 16px;
  line-height: 24px;
  color: ${themeGet('colors.black')};
`
export const InputText = styled.input.attrs(({ placeholder }) => ({
  fontSize: '13px',
  placeholder,
}))`
  flex-grow: 1;
  max-width: unset;
  min-width: 100px;
  border: none;
  outline: none;
  padding: 0;
  line-height: 24px;
`
