import { styled } from '@lyfta/components-theme'
import { map, merge } from 'lodash'
import PT from 'prop-types'
import React from 'react'

import Text from '../Text'

const base = (value, index) => ({
  as: `h${index}`,
  fontWeight: 'bold',
  fontSize: value,
  lineHeight: value,
  my: 3,
})

const AvailableHeaders = map([5, 4, 3, 2, 2, 2], (value, index) => {
  return styled(Text).attrs(p =>
    merge(p, {
      ...base(value, index + 1),
    }),
  )``
})

const HeaderComponent = ({ level, ...props }) => {
  const Component = AvailableHeaders[level - 1]
  return <Component {...props} />
}

HeaderComponent.propTypes = {
  level: PT.number,
}
HeaderComponent.defaultProps = {
  level: 1,
}

export default HeaderComponent
