import { styled } from '@lyfta/components-theme'
import { Flex, TouchableFlex } from '@lyfta/components-ui'

import { CheckBox, CheckRadio } from '../../../../../../Assets/QuestTracker'

export const Container = styled(TouchableFlex).attrs({ pr: 6 })`
  max-width: 50%;
  min-width: 300px;
  width: 100%;
  min-height: 36px;
`
export const Wrapper = styled(Flex).attrs({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'flex-start',
  pt: 2,
})`
  min-height: 36px;
`

export const Checkbox = styled(CheckBox)`
  width: 36px;
  height: 36px;
`
export const Radio = styled(CheckRadio)`
  width: 36px;
  height: 36px;
  margin-bottom: 8px;
  margin-right: 12px;
`
