import { createEvent } from '@lyfta/components-data/src/Store/Actions/events'
import {
  getAuthorId,
  getViewerName,
} from '@lyfta/components-data/src/Store/Selectors/viewer'
import {
  I18n,
  getTranslatedField,
  getTranslation,
} from '@lyfta/components-i18n'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import facebook from '../../../Assets/Images/facebook.png'
// import instagram from '../../../Assets/Images/instagram.png'
import linkedin from '../../../Assets/Images/linkedin.png'
// import whatsapp from '../../../Assets/Images/whatsapp.png'
import twitter from '../../../Assets/Images/x.png'
import { showNotification } from '../../../Services/Utils'
import { ButtonNew } from '../../ButtonNew'
import { Flex } from '../../Flex'
import Modal from '../../Modal'
import {
  DescriptionModal,
  InputText,
  LinkBox,
  LinkSocialMedia,
  ShareBox,
  TitleModal,
} from './styles'

const ShareLink = ({ isOpen, onChooseNegative, item, url, ...props }) => {
  const dispatch = useDispatch()
  const [emailText, setEmailText] = useState('')
  const authorId = String(useSelector(getAuthorId))
  const itemAuthorId = get(item, 'content.author.id')
  const contentType = get(item, 'content.type')
  const contentId = get(item, 'content.id')

  const isOwner = itemAuthorId === authorId
  const SHARED_LINK = url ? window.location.host + url : window.location.href
  const handleCopyText = e => {
    e.stopPropagation()
    navigator.clipboard.writeText(SHARED_LINK)
    createEventClick()

    showNotification(getTranslation('toast.success.urlCopied'))
    onChooseNegative()
  }

  const name = getTranslatedField(item, 'name')

  const handleChange = ({ target: { value } }) => {
    setEmailText(value)
  }

  const signupLink = 'registration.lyfta.com'
  const senderName = useSelector(getViewerName)
  const collectionLink = `${SHARED_LINK}`

  const subjectMessage = isOwner
    ? getTranslation('core.modals.shareModal.email.subjectOwnCollection')
    : getTranslation('core.modals.shareModal.email.subjectLyftaCollection')

  const bodyMessage = isOwner
    ? getTranslation('core.modals.shareModal.email.bodyOwnCollection', {
        collectionLink,
        signupLink,
        senderName,
      })
    : getTranslation('core.modals.shareModal.email.bodyLyftaCollection', {
        collectionLink,
        signupLink,
        senderName,
      })
  const socialMessage = isOwner
    ? getTranslation('core.modals.shareModal.social.ownCollection', {
        collectionLink,
        name,
      })
    : getTranslation('core.modals.shareModal.social.lyftaCollection', {
        collectionLink,
        name,
      })

  const createEventClick = () => {
    if (contentType === 'collection' && contentId)
      dispatch(createEvent({ contentId }))
  }

  return (
    <Modal
      isOpen={isOpen}
      showFooter={false}
      onCallback={() => onChooseNegative()}
      maxWidth="500px"
      {...props}
    >
      <Flex flexDirection="column" maxWidth="500px">
        <TitleModal>
          {' '}
          <I18n i18nKey="core.modals.shareModal.title" />
        </TitleModal>
        <DescriptionModal mt={2}>
          <I18n i18nKey="core.modals.shareModal.description" />
        </DescriptionModal>
        <DescriptionModal mt={3} fontWeight="bold">
          <I18n i18nKey="core.modals.shareModal.emailLabel" />

          <ShareBox border="1px solid gray" alignItem="center" height="56px">
            <Flex width="100%" fontSize="13px" pr={3}>
              <InputText width="100%" onChange={handleChange} />
            </Flex>

            <LinkSocialMedia
              target="_blank"
              href={`mailto:${emailText}?subject=${encodeURIComponent(
                subjectMessage,
              )}&body=${encodeURIComponent(bodyMessage)}`}
            >
              <ButtonNew buttonDiv type="task" onClick={createEventClick}>
                <I18n i18nKey="actions.share" />
              </ButtonNew>
            </LinkSocialMedia>
          </ShareBox>
        </DescriptionModal>
        <DescriptionModal mt={3}>
          <I18n i18nKey="core.modals.shareModal.description2" />
        </DescriptionModal>

        <Flex mt={3} justifyContent="center">
          <LinkSocialMedia
            target="_blank"
            href={`https://x.com/intent/post?text=${encodeURIComponent(
              socialMessage,
            )}`}
            onClick={() => createEventClick()}
          >
            <img alt="twitter" src={twitter} width="60px" />
          </LinkSocialMedia>

          <LinkSocialMedia
            target="_blank"
            onClick={() => createEventClick()}
            href={`https://www.facebook.com/sharer/sharer.php?u=${SHARED_LINK}&quote=${encodeURIComponent(
              socialMessage,
            )}`}
          >
            <img alt="facebook" src={facebook} width="60px" />
          </LinkSocialMedia>
          {/* <LinkSocialMedia
            target="_blank"
            href={`https://www.instagram.com/?url=${SHARED_LINK}&caption=${name}`}
          >
            <img alt="instagram" src={instagram} width="60px" />
          </LinkSocialMedia> */}
          <LinkSocialMedia
            onClick={() => createEventClick()}
            target="_blank"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
              collectionLink,
            )}`}
          >
            <img alt="linkedin" src={linkedin} width="60px" />
          </LinkSocialMedia>
          {/* <LinkSocialMedia
            target="_blank"
            href={`https://web.whatsapp.com/send?text=${SHARED_LINK}%3Fssm=whatsapp_CC`}
          >
            <img alt="whatsapp" src={whatsapp} width="60px" />
          </LinkSocialMedia> */}
        </Flex>

        <ShareBox border="1px solid gray" alignItem="center" height="56px">
          <LinkBox>{SHARED_LINK}</LinkBox>
          <ButtonNew buttonDiv onClick={handleCopyText} type="task">
            <I18n i18nKey="actions.copy" />
          </ButtonNew>
        </ShareBox>
      </Flex>
    </Modal>
  )
}

ShareLink.defaultProps = {
  url: '',
  item: '',
}

ShareLink.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.string,
  url: PropTypes.string,
  onChooseNegative: PropTypes.func.isRequired,
}

export default ShareLink
