import { getViewerType } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { getTranslation } from '@lyfta/components-i18n'
import { Journal as JournalIcon } from '@lyfta/components-student'
import { quest } from '@lyfta/components-student/src/Store/Actions/ui'
import { getIsInScene } from '@lyfta/components-student/src/Store/Selectors/UI/scene'
import { ButtonNew, USER_TYPES } from '@lyfta/components-ui'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { PrimaryButtonIcon } from '../styles'

export const LessonSelectionButton = () => {
  const dispatch = useDispatch()
  const isInScene = useSelector(getIsInScene)
  const location = useLocation()
  const viewerType = useSelector(getViewerType)

  const toggleQuest = useCallback(() => {
    dispatch(quest.hide())
    if (viewerType !== USER_TYPES.homeStudents) {
      dispatch(quest.questSelect(-1))
    }
    dispatch(quest.requestLessonsPopupOpen())
  }, [viewerType])

  return (
    <ButtonNew
      id="btn-open-quest-menu-student-app"
      leadingIcon={<PrimaryButtonIcon glyph={JournalIcon} />}
      secondary={!isInScene && location.pathname !== '/lesson-selection'}
      subtype={!isInScene ? 'navigation' : null}
      title={getTranslation('header.lessonSelection')}
      type="icon"
      onClick={toggleQuest}
    >
      {getTranslation('header.lessonSelection')}
    </ButtonNew>
  )
}
