export const USERS_ROLE = {
  student: 'student',
  homeStudent: 'home_student',
  teacher: 'teacher',
  parent: 'parent',
}

export const TEACHER_ROLE = {
  teacher: 'teacher',
  executive: 'exec_leader',
  leader: 'leadership_team',
  senior: 'senior_leader',
  teacher_assistant: 'teaching_assistant',
}

export const USER_TYPES = {
  students: 'students',
  homeStudents: 'homeStudents',
  teachers: 'teachers',
  parents: 'parents',
  admins: 'admins',
}

export default {}
