import { createAsyncAction } from '../../../helpers'
import apiCall from '../../../services/Api'
import {
  generateCreateUser,
  generateDeleteUser,
  generateLoadUserAction,
  generateLoadUsersAction,
  generateUpdateUser,
} from './helpers'

export const RESET_PASSWORD = createAsyncAction('users/RESET_PASSWORD')
export const LOAD_USER = createAsyncAction('users/LOAD_USER')
export const LOAD_USERS = createAsyncAction('users/LOAD_USERS')
export const CREATE_USER = createAsyncAction('users/CREATE_USER')
export const UPDATE_USER = createAsyncAction('users/UPDATE_USER')
export const DELETE_USER = createAsyncAction('users/DELETE_USER')

export const loadUsers = generateLoadUsersAction(LOAD_USERS)
export const loadUser = generateLoadUserAction(LOAD_USER)
export const createUser = generateCreateUser({ types: CREATE_USER })
export const updateUser = generateUpdateUser({ types: UPDATE_USER })
export const deleteUser = generateDeleteUser({ types: DELETE_USER })

export const requestCreateUser = (user, relationships) => ({
  type: CREATE_USER.REQUEST_PERFORM,
  payload: { user, relationships },
})
export const requestUpdateUser = (user, relationships) => ({
  type: UPDATE_USER.REQUEST_PERFORM,
  payload: { user, relationships },
})
export const requestDeleteUser = user => ({
  type: DELETE_USER.REQUEST_PERFORM,
  payload: { user },
})

export const requestResetPassword = user => ({
  type: RESET_PASSWORD.REQUEST_PERFORM,
  payload: { user },
})

export const resetPassword = email =>
  apiCall({
    customMessage: { success: { message: 'toast.success.passwordResetEmail' } },
    method: 'POST',
    endpoint: `/user/reset_password`,
    types: RESET_PASSWORD,
    query: {
      data: {
        attributes: {
          email,
        },
      },
    },
  })
