import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import {
  BACK_TOGGLE,
  QUEST_CONTENT_FIND,
  QUEST_HANDLE_LESSONS_POPUP_OPEN,
  QUEST_HELP_FIND,
  QUEST_HELP_FOUND,
  QUEST_HIDE,
  QUEST_HIDE_RICH_MEDIA,
  QUEST_REQUEST_LESSONS_POPUP_OPEN,
  QUEST_SELECT,
  QUEST_SHOW,
  QUEST_SHOW_RICH_MEDIA,
  SPECIAL_LOADING,
  UNITY_PAUSE,
  UNITY_REDUCE,
  UNITY_RESUME,
} from '../../Actions/ui'

const initialState = {
  helpMeFind: '-1',
  helpMeFound: '-1',
  findContentId: '-1',
  lessonId: -1,
  pauseWebGL: false,
  reduceWebGL: false,
  open: true,
  richMediaOpen: false,
  lessonsPopupOpenRequested: false,
  isSpecialLoading: false,
}

const show = state => state.merge({ open: true })
const hide = state => state.merge({ open: false })
const showRichMedia = state => state.merge({ richMediaOpen: true })
const hideRichMedia = state => state.merge({ richMediaOpen: false })

const pauseWebGL = state => state.merge({ pauseWebGL: true })
const resumeWebGL = state =>
  state.merge({ reduceWebGL: false, pauseWebGL: false })
const reduceWebGL = state => state.merge({ reduceWebGL: true })

const helpMeFind = (state, contents) =>
  state.merge({ helpMeFind: contents.contentId, helpMeFound: '-1' })

const toggleSpecialLoading = (state, content) =>
  state.merge({ isSpecialLoading: content.value })

const helpMeFound = (state, contents) =>
  state.merge({ helpMeFound: contents.contentId, helpMeFind: '-1' })
const findContentId = (state, contents) =>
  state.merge({ findContentId: contents.contentId })
const clearHelpMeFind = state =>
  state.merge({ helpMeFind: '-1', helpMeFound: '-1' })
const questSelect = (state, contents) =>
  state.merge({ lessonId: contents.lessonId })
const requestLessonsPopupOpen = state =>
  state.merge({ lessonsPopupOpenRequested: true })
const handleLessonsPopupOpen = state =>
  state.merge({ lessonsPopupOpenRequested: false })

const handlers = {
  [QUEST_HELP_FIND]: helpMeFind,
  [QUEST_HELP_FOUND]: helpMeFound,
  [QUEST_CONTENT_FIND]: findContentId,
  [QUEST_SHOW]: show,
  [QUEST_HIDE]: hide,
  [QUEST_SHOW_RICH_MEDIA]: showRichMedia,
  [QUEST_HIDE_RICH_MEDIA]: hideRichMedia,
  [QUEST_SELECT]: questSelect,
  [UNITY_PAUSE]: pauseWebGL,
  [UNITY_RESUME]: resumeWebGL,
  [UNITY_REDUCE]: reduceWebGL,
  [BACK_TOGGLE]: clearHelpMeFind,
  [SPECIAL_LOADING]: toggleSpecialLoading,
  [QUEST_REQUEST_LESSONS_POPUP_OPEN]: requestLessonsPopupOpen,
  [QUEST_HANDLE_LESSONS_POPUP_OPEN]: handleLessonsPopupOpen,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
