import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import {
  PREVIEW_HIDE,
  PREVIEW_LESSON_PLAN_HIDE,
  PREVIEW_LESSON_PLAN_SHOW,
  PREVIEW_SHOW,
} from '../../Actions/ui'

const initialState = {
  open: false,
  contentId: null,
  isLessonPlanPreviewOpened: false,
  lessonPlanId: null,
}

const show = (state, { id }) => state.merge({ open: true, contentId: id })
const hide = state => state.merge({ open: false, contentId: null })

const showLessonPlan = (state, { id }) =>
  state.merge({ isLessonPlanPreviewOpened: true, lessonPlanId: id })
const hideLessonPlan = state =>
  state.merge({ isLessonPlanPreviewOpened: false, lessonPlanId: null })

const handlers = {
  [PREVIEW_SHOW]: show,
  [PREVIEW_HIDE]: hide,
  [PREVIEW_LESSON_PLAN_SHOW]: showLessonPlan,
  [PREVIEW_LESSON_PLAN_HIDE]: hideLessonPlan,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
