import { I18n } from '@lyfta/components-i18n'
import { map } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Bin, Plus } from '../../../Assets'
import { Flex } from '../../../BaseComponents'
import Modal from '../../../Components/Modal'
import { TableActionButton } from '../ActionButton'

export const TableControls = ({
  selections,
  controls,
  modelName,
  newRoute,
  onCreateButtonPress,
  deleteAction,
  createLabelKey,
  createGoogleEvent,
  disableNewRoute,
  disableNewRouteClick,
  tableData,
  deleteActionLabel,
  disableRow,
  modalProps,
}) => {
  const dispatch = useDispatch()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { selected } = selections
  const history = useHistory()
  const params = useParams()
  const performDelete = useCallback(() => {
    const deleteFunc = id => dispatch(deleteAction(id))

    if (disableRow) {
      map(selected, id => {
        const foundObject = tableData.find(obj => obj.id === id)
        const matchDisabled = Object.keys(disableRow).find(item => {
          return disableRow[item] === foundObject?.[item]
        })
        if (matchDisabled) return ''
        if (id) deleteFunc(id)
        return ''
      })
    } else {
      map(selected, deleteFunc)
    }

    toggleDeleteModal()
    selections.setSelected([])
  }, [selected])

  const toggleDeleteModal = () => {
    setShowDeleteModal(state => !state)
  }
  const count = selected.length
  const modifiedSelections = selections
  const tableItem = tableData?.[0]
  let disableNewRouteBoolean
  if (typeof disableNewRoute === 'function') {
    disableNewRouteBoolean = disableNewRoute(tableItem)
    modifiedSelections.data = tableData
  } else {
    disableNewRouteBoolean = disableNewRoute
  }

  return (
    <Flex>
      {selected.length > 0 && deleteAction && (
        <I18n
          delete
          buttonDiv
          glyph={Bin}
          i18nKey={deleteActionLabel || 'core.delete'}
          id="btn-delete-row"
          key="tableDeleteButton"
          options={{ modelName, count: selected.length }}
          secondary
          type="task"
          sentenceCase
          tag={TableActionButton}
          onClick={toggleDeleteModal}
        />
      )}
      {(newRoute || onCreateButtonPress) && selected.length === 0 && (
        <I18n
          disabled={disableNewRouteBoolean && !disableNewRouteClick}
          glyph={Plus}
          i18nKey={createLabelKey}
          googleEvent={createGoogleEvent ? { action: createGoogleEvent } : ''}
          id="btn-add-row"
          key="tableNewButton"
          options={{ modelName }}
          type="task"
          sentenceCase
          tag={TableActionButton}
          onClick={
            onCreateButtonPress ||
            (() => {
              // eslint-disable-next-line
              disableNewRouteBoolean && disableNewRouteClick
                ? disableNewRouteClick()
                : history.push(newRoute('new', params))
            })
          }
        />
      )}

      {controls(modifiedSelections)}

      {showDeleteModal && (
        <Modal
          buttons={{
            deleteOrRemove: true,
            okText: modalProps?.buttons?.okText,
          }}
          content={{
            sentenceCase: true,
            modelName,
            count,
            title: modalProps?.content?.title,
            description:
              modalProps?.content?.description || 'core.modals.delete',
          }}
          hideClose
          // i18nText="core.modals.delete"
          isOpen={showDeleteModal}
          key="tableDeleteModal"
          modalStyle={{
            maxWidth: '500px',
          }}
          onCallback={performDelete}
          onDismissModal={toggleDeleteModal}
        />
      )}
    </Flex>
  )
}

TableControls.defaultProps = {
  controls: () => {},
  createLabelKey: 'core.create',
  deleteAction: null,
  deleteActionLabel: '',
  disableRow: {},
  disableNewRoute: false,
  disableNewRouteClick: () => {},
  modalProps: {},
  modelName: null,
  newRoute: null,
  selections: { selected: [] },
  tableData: [],
  onCreateButtonPress: null,
  createGoogleEvent: '',
}

TableControls.propTypes = {
  controls: PropTypes.func,
  createLabelKey: PropTypes.string,
  deleteAction: PropTypes.func,
  deleteActionLabel: PropTypes.string,
  disableRow: PropTypes.object,
  disableNewRoute: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disableNewRouteClick: PropTypes.func,
  modalProps: PropTypes.object,
  modelName: PropTypes.string,
  newRoute: PropTypes.func,
  selections: PropTypes.object,
  tableData: PropTypes.array,
  onCreateButtonPress: PropTypes.func,
  createGoogleEvent: PropTypes.string,
}
