import { getTranslation } from '@lyfta/components-i18n'
import PT from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { questTrackerData } from '../../../../../Store/Actions/questTracker'
import { menu, overlay, quest } from '../../../../../Store/Actions/ui'
import { getIsOpen } from '../../../../../Store/Selectors/UI/overlay'
import { getHelpFindContents } from '../../../../../Store/Selectors/UI/quest'
import { InformationText } from '../InformationText'
import { Submit } from '../Submit'

const Content = ({
  task: {
    description,
    callToAction,
    id,
    content: { id: contentId, type: contentType },
  },
}) => {
  const dispatch = useDispatch()
  const articleOpen = useSelector(getIsOpen)
  const helpMeFindId = useSelector(getHelpFindContents)

  const helpMeFind = useCallback(() => {
    dispatch(quest.helpMeFind(contentId))
    dispatch(questTrackerData.closeTask(id))
  })

  const saveResponse = useCallback(() => {
    dispatch(overlay.hide())
    dispatch(questTrackerData.saveResponse(id, { answer: { viewed: true } }))
    dispatch(menu.show())
  })

  const articleOpenAndId = articleOpen && helpMeFindId === contentId

  let submitAction = helpMeFind
  let submitText = callToAction

  if (contentType === 'article') {
    submitText = articleOpenAndId
      ? getTranslation('quest.doneTask')
      : callToAction
    submitAction = articleOpenAndId ? saveResponse : helpMeFind
  }

  return (
    <>
      <InformationText textSource={description} />
      <Submit buttonText={submitText} onClick={submitAction} />
    </>
  )
}
Content.propTypes = {
  task: PT.object.isRequired,
}
export default Content
