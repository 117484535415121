import { styled, themeGet as theme } from '@lyfta/components-theme'
import { Arrow, Box, Flex } from '@lyfta/components-ui'

export const Container = styled(Flex).attrs(() => ({
  pl: 4,
  pr: 4,
}))`
  width: 300px;

  flex-direction: column;
  overflow-y: scroll;
  background-color: ${theme('colors.white')};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  transition: transform ease 0.3s;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);
`
export const Header = styled(Flex).attrs(() => ({
  fontSize: 3,
}))`
  align-items: center;
  justify-content: center;
  height: 64px;
  font-weight: 600;
  position: relative;
  border-bottom: 1px solid ${theme('colors.tableRowBottomBorder')};
  color: ${theme('colors.primary')};
  position: relative;
`

export const CloseButton = styled(Box).attrs({
  as: Arrow,
  color: 'black',
  direction: 'right',
  size: 10,
})`
  position: absolute;
  right: 16px;
  top: 26px;
`
