import { styled } from '@lyfta/components-theme'
import { Flex, Subtitle1, TextArea } from '@lyfta/components-ui'
import { themeGet as theme } from '@styled-system/theme-get'

export const DiscussionText = styled(TextArea)`
  width: 100%;
  border: 2px solid red;
  padding: ${theme('spacingSmaller')};
  border: 1px solid #979797;
`
export const RatingContainer = styled(Flex).attrs({
  flex: 1,
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  fontSize: 2,
})``

export const RatingLabelContainer = styled(Flex).attrs({
  flex: 1,
  flexDirection: 'row',
  width: '33%',
  justifyContent: 'center',
  alignItems: 'flex-end',
  alignSelf: 'center',
  flexGrow: 3,
  p: '0 16px',
})``

export const RatingLabelContainerRight = styled(RatingLabelContainer).attrs({
  alignItems: 'flex-start',
})``

export const RatingLabelLeft = styled(Subtitle1).attrs({
  fontSize: '18px',
  textAlign: 'right',
  paddingRight: 0,
  marginRight: '0',
  width: '100%',
})``

export const RatingLabelRight = styled(RatingLabelLeft).attrs({
  textAlign: 'left',
  paddingLeft: 0,
  marginLeft: '0',
})``

export const RatingBlock = styled(Flex).attrs({
  flexGrow: 0,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
})``
