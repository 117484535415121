import React from 'react'

import { LyftaLogo } from '../../Assets/Logo'
import { Flex } from '../../Components'
import { PropTypes } from '../../Helpers/prop-types'
import { ContentContainer, ImageContainer, PublicContainer } from './styles'

const backgroundImages = [
  { logoFill: '#000', image: 'amazonian-treats-1.jpg' },
  { logoFill: '#fff', image: 'amazonian-treats-2.jpg' },
  { logoFill: '#fff', image: 'amazonian-treats-3.jpg' },
  { logoFill: '#fff', image: 'andrea-1.jpg' },
  { logoFill: '#000', image: 'awra-amba-1.jpg' },
  { logoFill: '#fff', image: 'awra-amba-2.jpg' },
  { logoFill: '#fff', gradient: true, image: 'awra-amba-3.jpg' },
  { logoFill: '#fff', image: 'awra-amba-4.jpg' },
  { logoFill: '#fff', image: 'awra-amba-5.jpg' },
  { logoFill: '#fff', image: 'awra-amba-6.jpg' },
  { logoFill: '#fff', image: 'awra-amba-7.jpg' },

  { logoFill: '#000', image: 'beachcomber-1.jpg' },
  { logoFill: '#000', image: 'becoming-me-deenpal-1.jpg' },
  { logoFill: '#000', image: 'becoming-me-natalie-1.jpg' },
  { logoFill: '#fff', image: 'beekeeper-1.jpg' },
  { logoFill: '#fff', image: 'circus-1.jpg' },
  { logoFill: '#fff', image: 'circus-2.jpg' },
  { logoFill: '#000', image: 'cossack-1.jpg' },
  { logoFill: '#fff', image: 'dinnertime-anni-1.jpg' },
  { logoFill: '#fff', image: 'dinnertime-habiba-1.jpg' },
  { logoFill: '#fff', image: 'dinnertime-habiba-2.jpg' },

  { logoFill: '#fff', image: 'dinnertime-muhammed-1.jpg' },
  { logoFill: '#fff', image: 'everyone-has-a-story-1.jpg' },
  { logoFill: '#fff', image: 'fashion-photographer-1.jpg' },
  { logoFill: '#fff', image: 'fish-tales-1.jpg' },
  { logoFill: '#fff', image: 'for-the-love-of-the-game-1.jpg' },
  { logoFill: '#fff', image: 'for-the-love-of-the-game-2.jpg' },
  { logoFill: '#fff', image: 'kids-cup-afnan-1.jpg' },
  { logoFill: '#fff', image: 'kids-cup-anna-1.jpg' },
  { logoFill: '#000', image: 'kids-cup-yu-1.jpg' },
  { logoFill: '#fff', image: 'lost-jewish-garden-1.jpg' },

  { logoFill: '#fff', image: 'mother-of-the-forest-1.jpg' },
  { logoFill: '#fff', image: 'phonebox-1.jpg' },
  { logoFill: '#fff', gradient: true, image: 'pride-1.jpg' },
  { logoFill: '#fff', image: 'secrets-of-the-opera-1.jpg' },
  { logoFill: '#fff', image: 'secrets-of-the-opera-2.jpg' },
  { logoFill: '#fff', image: 'secrets-of-the-opera-3.jpg' },
  { logoFill: '#fff', image: 'secrets-of-the-opera-4.jpg' },
  { logoFill: '#fff', image: 'secrets-of-the-opera-5.jpg' },
  { logoFill: '#fff', image: 'secrets-of-the-opera-6.jpg' },
  { logoFill: '#fff', image: 'sig-1.jpg' },

  { logoFill: '#000', image: 'strudel-sisters-1.jpg' },
  { logoFill: '#fff', image: 'strudel-sisters-2.jpg' },
  { logoFill: '#000', image: 'tamara-1.jpg' },
  { logoFill: '#000', image: 'voice-of-the-streets-1.jpg' },
]

const getRandomLoginImage = names => {
  return names[Math.floor(Math.random() * names.length)]
}

export const AuthLayout = ({ children, containerFlex }) => {
  const { image, logoFill, gradient } = getRandomLoginImage(backgroundImages)
  return (
    <PublicContainer>
      <ImageContainer
        backgroundImage={
          gradient
            ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(255, 255, 255, 0.1)), url(https://assets-lyfta.imgix.net/login-images/${image}?h=${window.innerHeight})`
            : `url(https://assets-lyfta.imgix.net/login-images/${image}?h=${window.innerHeight})`
        }
        flex={containerFlex}
      >
        <LyftaLogo color={logoFill} width={125} />
      </ImageContainer>
      <ContentContainer>
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          maxHeight="100%"
          overflowY="scroll"
        >
          {children}
        </Flex>
      </ContentContainer>
    </PublicContainer>
  )
}
AuthLayout.defaultProps = {
  containerFlex: [5, 2],
}
AuthLayout.propTypes = {
  children: PropTypes.children.isRequired,
  containerFlex: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
}
