import {
  I18n,
  TranslatedField,
  getTranslation,
  globalFormatDate,
} from '@lyfta/components-i18n'
import { Flex, Icon, Lock, capitalizeFirstLetter } from '@lyfta/components-ui'
import get from 'lodash/get'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import {
  BodyContainer,
  Container,
  Controls,
  FooterContainer,
  HeaderContainer,
  LineClamp,
  StyledButton,
  SubTitle,
  ThumbHolder,
  Title,
} from './styles'

// TODO: IMAGE BG HARD CODED.

const Card = ({
  height,
  index,
  isLoading,
  isLesson,
  item,
  isLocked,
  locale,
  onClick,
  width,
}) => {
  const handleClick = () => {
    onClick(item)
  }

  const localeCapitalized = capitalizeFirstLetter(locale)
  const contentId = get(item, 'id')
  const lessonPlan = get(item, 'lessonPlan')
  const titleComponent = item && (
    <TranslatedField object={lessonPlan} field="name" />
  )
  const shortDescription = get(
    item,
    `lessonPlan.content.shortDescription${localeCapitalized}`,
  )
  const klasses = get(item, 'klass')

  return (
    <Container
      width={width}
      height={height}
      onClick={handleClick}
      data-intercom-target={contentId}
      id={`card-${index}`}
    >
      <HeaderContainer>
        {/* {isLoading && <Skeleton height={height} />} */}
        {!isLoading && (
          <ThumbHolder
            image={
              isLesson
                ? 'https://assets.lyfta.com/lesson-plan-default.png'
                : item.posterUrlSmall
            }
            height={200}
          />
        )}
      </HeaderContainer>

      <BodyContainer>
        <Title>
          {isLoading && <Skeleton />}
          {!isLoading && titleComponent}
        </Title>
        <SubTitle pt={2}>
          {getTranslation('dashboard.lessons.started', {
            date: globalFormatDate(get(item, 'createdAt')),
          })}
        </SubTitle>
        {klasses && (
          <SubTitle pt={2}>
            {getTranslation('dashboard.lessons.class', {
              klass: klasses.name,
            })}
          </SubTitle>
        )}
        {shortDescription && (
          <SubTitle py={2}>
            <LineClamp>{shortDescription}</LineClamp>
          </SubTitle>
        )}
      </BodyContainer>

      <FooterContainer>
        <Controls>
          {isLocked ? (
            <Icon glyph={Lock} fill="black_600" />
          ) : (
            <>
              <Flex
                width="50%"
                margin={2}
                justifyContent="center"
                color="red_100"
                fontWeight="bold"
              >
                <I18n i18nKey="actions.end" />
              </Flex>
              <StyledButton inverse noborder type="link">
                <I18n i18nKey="actions.view" />
              </StyledButton>
            </>
          )}
        </Controls>
      </FooterContainer>
    </Container>
  )
}

Card.defaultProps = {
  height: 200,
  isLesson: false,
  isLoading: false,
  index: '0',
  item: null,
  width: 300,
  onClick: noop,
}

Card.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.string,
  isLesson: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLocked: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    type: PropTypes.string,
    posterUrlSmall: PropTypes.string,
  }),
  locale: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
}

export default Card
