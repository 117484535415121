import { all, put, select, takeLatest } from 'redux-saga/effects'

import { loadClasses } from '../Actions/classes'
import { UPLOAD_FILE } from '../Actions/files'
import { loadStudentUsers } from '../Actions/users'
import { getFilterStudentSetting } from '../Selectors/settings'

function* loadStudents() {
  const filterStudent = yield select(getFilterStudentSetting)
  yield put(
    loadStudentUsers({
      filter: { state: filterStudent },
      page: { size: 50, number: 1 },
    }),
  )
  yield put(loadClasses({ filter: {}, page: { size: 50, number: 1 } }))
}

export default function* root() {
  yield all([takeLatest(UPLOAD_FILE.SUCCESS, loadStudents)])
}
