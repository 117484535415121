import { filter, kebabCase, map, reverse } from 'lodash'
import { match } from 'path-to-regexp'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { TabLabel } from './label'
import { ActiveTab, Container, Content, Tab } from './styles'

export const Tabs = ({ route, userPermissions, ...props }) => {
  const [activeTab, setActiveTab] = useState(0)
  const location = useLocation()
  const { id } = useParams()
  const isNew = id === 'new' || id === undefined

  const tabWidth = route.tabWidth || 275

  const routesBaseOnPermissions = route.routes.filter(r => {
    const found = r.removeTabsBaseOnUserPermissions?.filter(item => {
      if (userPermissions[item] === false) return true

      return false
    })

    if (typeof found !== 'undefined' && Object.keys(found).length !== 0)
      return false

    return true
  })
  const routes = filter(
    reverse([...routesBaseOnPermissions]),
    r => !r.hide && !(isNew && r.hideOnNew),
  )
  const animate = { left: tabWidth * activeTab }
  let tabId = ''
  const tabIdMaker = tab => {
    if (tab.id) {
      tabId = `tab-${tab.id}`
    } else if (tab.breadcrumb) {
      tabId = `tab-${kebabCase(tab.breadcrumb.split('.').slice(-1)[0])}`
    } else if (tab.path && typeof tab.path === 'string') {
      tabId = `tab-${kebabCase(tab.path.split('/').slice(-1)[0])}`
    }
  }

  useEffect(() => {
    map(routes, (tab, index) => {
      const matcher = match(tab.path)
      if (matcher(location.pathname)) setActiveTab(index)
    })
  }, [location])

  return (
    <Container>
      <Content>
        {map(routes, (tab, index) => {
          tabIdMaker(tab)
          return (
            <TabLabel
              active={activeTab === index}
              id={tabId}
              key={tab.path}
              route={tab}
              tag={Tab}
              width={tabWidth}
              {...props}
              data-intercom-target={tab.breadcrumb}
            />
          )
        })}
        <ActiveTab animate={animate} index={activeTab} width={tabWidth} />
      </Content>
    </Container>
  )
}
Tabs.defaultProps = {
  userPermissions: {},
}

Tabs.propTypes = {
  route: PropTypes.object.isRequired,
  userPermissions: PropTypes.object,
}
