import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Field } from 'react-final-form'

import FieldError from '../FieldError'
import {
  Container,
  Description,
  Label,
  TextArea,
  TextAreaNoResize,
  Wrapper,
} from './styles'

/*
 * This is a workaround for a known issue (we should monitor and remove this
 * once the issue is resolved)
 * https://github.com/final-form/react-final-form/issues/130
 *
 * Asana ticket the workaround is trying to fix:
 * https://app.asana.com/0/1133701735540444/1179765034749968/f
 */
const identity = value => value

const renderField = ({
  description,
  rows,
  maxRows,
  noResize,
  input,
  meta,
  label,
  placeholder,
  type,
  focused,
  fieldRef,
  onBlur,
  onFocus,
  onLabelClick,
  inputId,
  disabled,
  ...rest
}) => {
  const active = focused || !isEmpty(input?.value?.toString())
  const hasError = meta.touched && (meta.error || meta.submitError)

  const ChosenTextArea = noResize ? TextAreaNoResize : TextArea

  return (
    <Wrapper {...rest}>
      <Container active={active} error={hasError ? 1 : 0}>
        {label && (
          <Label active={active} onClick={onLabelClick}>
            {label}
          </Label>
        )}
        <ChosenTextArea
          ref={fieldRef}
          type={type}
          {...input}
          disabled={disabled}
          id={inputId}
          placeholder={placeholder}
          rows={rows}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </Container>
      {hasError ? (
        <FieldError meta={meta} />
      ) : (
        description && <Description active={active}>{description}</Description>
      )}
    </Wrapper>
  )
}

renderField.defaultProps = {
  disabled: false,
  inputId: '',
  noResize: false,
  maxRows: undefined,
  rows: 1,
}

renderField.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  disabled: PropTypes.bool,
  fieldRef: PropTypes.object.isRequired,
  focused: PropTypes.bool.isRequired,
  input: PropTypes.object.isRequired,
  inputId: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  maxRows: PropTypes.number,
  meta: PropTypes.object.isRequired,
  noResize: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  rows: PropTypes.number,
  type: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onLabelClick: PropTypes.func.isRequired,
}

class AnimatedTextArea extends PureComponent {
  textField = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      focused: false,
    }
  }

  handleFocus = () => {
    this.setState({ focused: true })
  }

  handleBlur = () => {
    this.setState({ focused: false })
  }

  handleLabelClick = () => {
    this.textField.current.focus()
  }

  render() {
    const {
      allowEmptyField,
      label,
      name,
      placeholder,
      type,
      description,
      ...rest
    } = this.props
    const { focused } = this.state

    return (
      <Field
        description={description}
        fieldRef={this.textField}
        focused={focused}
        label={label}
        name={name}
        placeholder={placeholder}
        render={renderField}
        type={type}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onLabelClick={this.handleLabelClick}
        {...(allowEmptyField && { parse: identity })}
        {...rest}
      />
    )
  }
}

AnimatedTextArea.defaultProps = {
  allowEmptyField: true,
  label: '',
  noResize: false,
  placeholder: '',
  description: '',
  type: 'text',
}

AnimatedTextArea.propTypes = {
  allowEmptyField: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string.isRequired,
  noResize: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
}

export default AnimatedTextArea
