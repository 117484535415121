/* eslint-disable import/no-named-as-default */
import {
  getJWT,
  getLocale,
  getResourceId,
} from '@lyfta/components-data/src/Store/Selectors/persist'
import {
  getViewer,
  getViewerOrganization,
  getViewerType,
} from '@lyfta/components-data/src/Store/Selectors/viewer'
import { loadLessons } from '@lyfta/components-student/src/Store/Actions/lessons'
import { getCurrentLesson } from '@lyfta/components-student/src/Store/Selectors/lessons'
import {
  getIsBackToGlobe,
  getIsBackToGlobeHighlighted,
} from '@lyfta/components-student/src/Store/Selectors/UI/back'
import { getIsOpen } from '@lyfta/components-student/src/Store/Selectors/UI/drawer'
import { getIsFullScreen } from '@lyfta/components-student/src/Store/Selectors/UI/fullscreen'
import { getIsGyroEnabled } from '@lyfta/components-student/src/Store/Selectors/UI/gyro'
import {
  getIsMuted,
  getIsMutedDuringTemporaryUnmute,
} from '@lyfta/components-student/src/Store/Selectors/UI/mute'
import { getIsOpen as getIsOverlayOpen } from '@lyfta/components-student/src/Store/Selectors/UI/overlay'
import {
  getFindContents,
  getHelpFindContents,
  getIsSpecialLoading,
  getLessonId,
  getLessonsPopupOpenRequested,
  getWebGLPause,
  getWebGLReduced,
} from '@lyfta/components-student/src/Store/Selectors/UI/quest'
import { getIsRotationEnabled } from '@lyfta/components-student/src/Store/Selectors/UI/rotation'
import {
  getInPressed as getZoomInPressed,
  getOutPressed as getZoomOutPressed,
} from '@lyfta/components-student/src/Store/Selectors/UI/zoom'
import { PreviewBanner, Sidebars } from 'Components/Blocks'
import paths from 'Constants/paths'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { renderRoutes } from 'react-router-config'
import { useRouteMatch } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import { getCurrentLessonPlan } from '../../../Store/Selectors/lessonPlans'
import UnityApp from '../../../UnityApp/UnityApp'
import { Container, Content, Main } from './styles'

const Private = ({
  route,
  helpMeFind,
  findContentId,
  isOverlayOpen,
  isDrawerOpen,
  isMuted,
  isRotationEnabled,
  isGyroEnabled,
  isMutedDuringTemporaryUnmute,
  isFullScreen,
  isBackToGlobe,
  isBackToGlobeHighlighted,
  doLoadLessons,
  lesson,
  lessonPlan,
  lessonsPopupOpenRequested,
  locale,
  resourceId,
  token,
  webGLPaused,
  webGLReduced,
  zoomInPressed,
  zoomOutPressed,
  viewerType,
  viewer,
  viewerOrganization,
  isSpecialLoading,
}) => {
  const isSelectedLesson = useRouteMatch('/lesson-selection')

  if (isSelectedLesson && resourceId) return <Redirect to={paths.root} />

  return (
    <Container>
      <div
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          background: isSpecialLoading ? 'black' : 'rgb(25,25,25)',
          overflow: 'hidden',
        }}
      >
        {!isSelectedLesson && viewerOrganization && (
          <UnityApp
            findContentId={findContentId.toString()}
            helpMeFind={helpMeFind.toString()}
            isBackToGlobe={isBackToGlobe}
            isBackToGlobeHighlighted={isBackToGlobeHighlighted}
            isFullScreen={isFullScreen}
            isMutedDuringTemporaryUnmute={isMutedDuringTemporaryUnmute}
            isMutedUI={isMuted}
            isOverlayOpen={isOverlayOpen}
            isRotationEnabled={isRotationEnabled}
            isGyroEnabled={isGyroEnabled}
            lesson={lesson}
            lessonPlan={lessonPlan}
            lessonsPopupOpenRequested={lessonsPopupOpenRequested}
            locale={locale}
            resourceId={resourceId}
            token={token}
            viewer={viewer}
            viewerType={viewerType}
            webGLPaused={webGLPaused}
            webGLReduced={webGLReduced}
            zoomInPressed={zoomInPressed}
            zoomOutPressed={zoomOutPressed}
          />
        )}
      </div>

      <Main>
        <Sidebars
          loadLessons={() =>
            doLoadLessons(
              null,
              viewer?.permissions?.organizationAdmin ? viewer.id : null,
            )
          }
        />
        <PreviewBanner />
        <Content opened={isDrawerOpen ? 1 : 0}>
          {renderRoutes(route.routes)}
        </Content>
      </Main>
    </Container>
  )
}
Private.defaultProps = {
  findContentId: null,
  helpMeFind: null,
  lesson: null,
  lessonPlan: null,
  resourceId: null,
  viewerOrganization: null,
  viewerType: null,
}

Private.propTypes = {
  doLoadLessons: PropTypes.func.isRequired,
  findContentId: PropTypes.string,
  helpMeFind: PropTypes.string,
  isBackToGlobe: PropTypes.bool.isRequired,
  isBackToGlobeHighlighted: PropTypes.bool.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  isGyroEnabled: PropTypes.bool.isRequired,
  isMuted: PropTypes.bool.isRequired,
  isMutedDuringTemporaryUnmute: PropTypes.bool.isRequired,
  isOverlayOpen: PropTypes.bool.isRequired,
  isRotationEnabled: PropTypes.bool.isRequired,
  isSpecialLoading: PropTypes.bool.isRequired,
  lesson: PropTypes.object,
  lessonPlan: PropTypes.object,
  lessonsPopupOpenRequested: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  resourceId: PropTypes.string,
  route: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  viewer: PropTypes.object.isRequired,
  viewerOrganization: PropTypes.object,
  viewerType: PropTypes.string,
  webGLPaused: PropTypes.bool.isRequired,
  webGLReduced: PropTypes.bool.isRequired,
  zoomInPressed: PropTypes.bool.isRequired,
  zoomOutPressed: PropTypes.bool.isRequired,
}

export default connect(
  createStructuredSelector({
    findContentId: getFindContents,
    helpMeFind: getHelpFindContents,
    isOverlayOpen: getIsOverlayOpen,
    isBackToGlobe: getIsBackToGlobe,
    isBackToGlobeHighlighted: getIsBackToGlobeHighlighted,
    isDrawerOpen: getIsOpen,
    isFullScreen: getIsFullScreen,
    isMuted: getIsMuted,
    isMutedDuringTemporaryUnmute: getIsMutedDuringTemporaryUnmute,
    isRotationEnabled: getIsRotationEnabled,
    isGyroEnabled: getIsGyroEnabled,
    lesson: getCurrentLesson,
    lessonPlan: getCurrentLessonPlan,
    lessonsPopupOpenRequested: getLessonsPopupOpenRequested,
    selectedLessonId: getLessonId,
    locale: getLocale,
    resourceId: getResourceId,
    token: getJWT,
    webGLPaused: getWebGLPause,
    webGLReduced: getWebGLReduced,
    zoomInPressed: getZoomInPressed,
    zoomOutPressed: getZoomOutPressed,
    viewerType: getViewerType,
    viewer: getViewer,
    viewerOrganization: getViewerOrganization,
    isSpecialLoading: getIsSpecialLoading,
  }),
  {
    doLoadLessons: loadLessons,
  },
)(Private)
