import { useCallback } from 'react'

// This cannot be a part of Utils because BaseComponents uses useConditionalCallback and that would create circular
// dependency.
export const useConditionalCallback = (disabled, callback) =>
  useCallback(
    (...args) => {
      if (disabled) {
        return
      }

      if (callback) {
        callback(...args)
      }
    },
    [disabled, callback],
  )
