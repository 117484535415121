import { css, themeGet } from '@lyfta/components-theme'

const style = css`
  > p {
    color: ${props => props.color || themeGet('colors.black')(props)};

    ${p => {
      const fSize =
        typeof p.fontSize === 'string'
          ? p.fontSize
          : themeGet(`fontSizes.${p.fontSize || 3}`)(p)

      const lineHeight = themeGet(`lineHeights.${p.fontSize || 3}`)

      return css`
        font-size: ${fSize};
        line-height: ${lineHeight};
      `
    }}
  }

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    color: ${props => props.color || themeGet('colors.primary')(props)};
    font-size: ${themeGet('fontSize.heading4')};
    line-height: ${themeGet('lineHeights.4')};
    margin-bottom: 0px;
  }

  > h1 {
    font-size: ${themeGet('fontSize.heading1')};
    line-height: ${themeGet('lineHeights.9')};
  }
  > h2 {
    font-size: ${themeGet('fontSize.heading2')};
    line-height: ${themeGet('lineHeights.8')};
  }
  > h3 {
    font-size: ${themeGet('fontSize.heading3')};
    line-height: ${themeGet('lineHeights.6')};
  }
`
const dashboardStyle = css`
font-family: 'Walsheim';

> h1,
> h2,
> h3,
> h4,
> h5,
> h6 {
  color: ${props => props.color || themeGet('colors.black')(props)};
  /* font-size: ${themeGet('fontSize.heading4')}; */
  /* line-height: ${themeGet('lineHeights.5')}; */
  margin-bottom: 30px;
}

> h1 {
  font-size: 24px;
  line-height: 27px;
  font-weight: 400

}
> h2 {
  font-size: 20px;
  line-height: 23px;
  font-weight: 500
}
> h3 {
  font-size: ${themeGet('fontSize.heading3')};
  line-height: ${themeGet('lineHeights.6')};
}
> ul {
  padding: 0;
}

> ul li {
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 1rem;
  list-style-type: none;
  padding-left: 1.5em;
  position: relative;
}

> ul li::before {
  content: '-'; /* Unicode for right arrow */
  position: absolute;
  left: 0;
}

`

const quickLinksStyle = css`
font-family: 'Walsheim';

> h1,
> h2,
> h3,
> h4,
> h5,
> h6 {
  color: ${props => props.color || themeGet('colors.black')(props)};
  /* font-size: ${themeGet('fontSize.heading4')}; */
  /* line-height: ${themeGet('lineHeights.5')}; */
  margin-bottom: 30px;
}

> h1 {
  font-size: 20px;
  line-height: 23px;
  font-weight: 400

}
> h2 {
  font-size: 20px;
  line-height: 23px;
  font-weight: 500
}
> h3 {
  font-size: ${themeGet('fontSize.heading3')};
  line-height: ${themeGet('lineHeights.6')};
}
> ul {
  padding: 0;
}

> ul li {
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 1rem;
  list-style-type: none;
  padding-left: 1.5em;
  position: relative;
}

> ul li::before {
  content: '-'; /* Unicode for right arrow */
  position: absolute;
  left: 0;
}

`

const inheritStyle = css`
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > p {
    font-size: inherit;
    line-height: inherit;
    margin: inherit;
  }
`

const MarkdownStyles = { style, dashboardStyle, inheritStyle, quickLinksStyle }

export default MarkdownStyles
