import PropTypes from 'prop-types'
import * as React from 'react'

const LyftaLogo = ({ color, width, ...props }) => {
  const height = parseInt(width * (184 / 451), 10)
  return (
    <svg
      height={height}
      viewBox="0 0 451 184"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill={color} fillRule="nonzero">
        <path d="M283.87 12.04v-12h-42.64v70.41h12.87V41.24h26.75V29.37H254.1V12.04h29.77zm-73.32-12h-15.19l-14.68 30.28h-.3L165.7.04h-15.19L174 43.55v26.86h13v-27L210.55.04zm-111 0H86.67v70.41h44.2V58.24H99.54l.01-58.2zM0 113.52v70.41h44.2v-12.17H12.87v-58.24zM118 113.52h12.87v70.41h-44.2v-12.17H118zM12.89 70.4H.02V0h44.21v12.17H12.89zM405.18 42.75l10.26-27.56 10.25 27.56h-20.51zm30.67 27.66h14.28L421.87.04H409l-28.26 70.41H395l5.93-16h29l5.92 15.96zM362.28.04H311v12h19.2v58.34h12.87V12.04h19.21v-12z" />
      </g>
    </svg>
  )
}

LyftaLogo.defaultProps = {
  color: '#fff',
  width: 100,
}
LyftaLogo.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
}

export default LyftaLogo
