import { setLocaleSetting } from '@lyfta/components-data/src/Store/Actions/settings'
import {
  I18n,
  getCurrentLanguage,
  setLanguage,
  validLanguages,
} from '@lyfta/components-i18n'
import { map } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { ReactSelectLocale } from './styles'

const Locale = props => {
  const dispatch = useDispatch()

  const [localeSelected, setLocaleItem] = useState(getCurrentLanguage())

  const handleChangeLocale = setLocale => ({ value }) => {
    setLanguage(value).then(() => setLocale(value))

    dispatch(setLocaleSetting(value))
  }
  const { fill } = props

  const localeOptions = map(validLanguages, value => ({
    label: <I18n i18nKey={`locale.options.${value}`} />,
    selectedLabel: <I18n i18nKey={`locale.shortcut.${value}`} />,
    value,
  }))

  const defaultOptionIndex = localeOptions.findIndex(
    ({ value }) => value === localeSelected,
  )

  return (
    <ReactSelectLocale
      defaultValue={localeOptions[defaultOptionIndex]}
      fill={fill}
      id="dropdown-select-locale"
      menuBorderRadius="bottom"
      options={localeOptions}
      onChange={handleChangeLocale(setLocaleItem)}
    />
  )
}

Locale.defaultProps = { fill: 'colors.neutral_200' }

Locale.propTypes = {
  fill: PropTypes.string,
}
export default Locale
