import { styled, themeGet } from '@lyfta/components-theme'
import PropTypes from 'prop-types'

import { ErrorSvg } from '../../Assets/Icons'
import { Flex } from '../../BaseComponents'
import { Box } from '../../Components/Box'
import TextNode from '../../Components/TextNode'
import { getIsNative } from '../../Helpers/getIsNative'

const noopComponent = () => {
  // Hack: Do not show anything for native.
  return null
}

export const Header = styled(TextNode).attrs(() => ({ as: 'h1' }))`
  font-size: 18px;
  line-height: 1.39;
  letter-spacing: normal;
  color: ${themeGet('colors.primary')};
`

export const NoWrapContainer = styled(Flex)`
  flex-direction: column;
  form {
    flex: 0;
  }
`

export const ErrorIcon = styled(ErrorSvg)`
  flex-shrink: 0;
  height: 24px;
  margin-right: 16px;
  width: 24px;

  > path {
    fill: ${themeGet('colors.error_100')};
  }
`

export const ErrorMessage = getIsNative() ? noopComponent : styled.li``

export const ErrorMessageContainer = getIsNative()
  ? noopComponent
  : styled.ul`
      list-style-type: ${props =>
        props.numberOfMessages > 1 ? 'disc' : 'none'};
      padding-left: ${props => (props.numberOfMessages > 1 ? '16px' : '0')};
    `

export const WholeFormErrorListItem = getIsNative()
  ? noopComponent
  : styled.li``

WholeFormErrorListItem.propTypes = {
  children: PropTypes.node.isRequired,
}

export const ErrorListContainer = styled(Box)``

export const WholeFormErrorList = getIsNative()
  ? noopComponent
  : styled.ul`
      list-style-type: none;
      margin: 0;
      padding: 0;

      > * + * {
        margin-top: 16px;
      }

      > li {
        background-color: ${themeGet('colors.error_60')};
        border: 1px solid ${themeGet('colors.error_100')};
        border-radius: 5px;
        color: ${themeGet('colors.error_120')};
        display: flex;
        font-size: 16px; // Body 1
        font-weight: 400; // Body 1
        letter-spacing: 0.5px; // Body 1
        line-height: 24px; // Body 1
        padding: 16px;
      }
    `
