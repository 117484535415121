import { getTranslation } from '@lyfta/components-i18n'
import {
  FullscreenExpand as FullscreenExpandIcon,
  FullscreenMinimise as FullscreenMinimiseIcon,
} from '@lyfta/components-student'
import { fullscreen } from '@lyfta/components-student/src/Store/Actions/ui'
import { getIsFullScreen } from '@lyfta/components-student/src/Store/Selectors/UI/fullscreen'
import { ButtonNew } from '@lyfta/components-ui'
import React from 'react'
import { useSelector } from 'react-redux'

import { callbackDispatch } from '../../../../utils/callbackDispatch'
import { PrimaryButtonIcon } from '../styles'

export const FullscreenButton = () => {
  const fullScreenStatus = useSelector(getIsFullScreen)

  return (
    <ButtonNew
      id="btn-fullscreen-student-app"
      leadingIcon={
        !fullScreenStatus ? (
          <PrimaryButtonIcon glyph={FullscreenExpandIcon} />
        ) : (
          <PrimaryButtonIcon glyph={FullscreenMinimiseIcon} />
        )
      }
      title={getTranslation('header.fullscreen')}
      type="icon"
      onClick={callbackDispatch(fullscreen, 'toggle')}
    >
      {getTranslation('header.fullscreen')}
    </ButtonNew>
  )
}
