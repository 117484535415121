import PropTypes from 'prop-types'
import React, { Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'

import { Container, Step, StepDivider, StepLabel, StepNumber } from './styles'

class Stepper extends PureComponent {
  constructor(props) {
    super(props)
    const { activeStep } = props
    this.state = {
      activeStep,
    }
  }

  handleStep = step => () => {
    this.setState({
      activeStep: step,
    })
  }

  render() {
    const { activeStep } = this.state
    const { steps } = this.props

    return (
      <Container>
        {steps.map((label, index) => {
          const step = index + 1
          const active = step <= activeStep ? 'true' : 'false'
          return (
            <Fragment key={step}>
              <Step onClick={this.handleStep(step)}>
                <StepNumber active={active}>{step}</StepNumber>
                <StepLabel active={active}>{label}</StepLabel>
              </Step>
              {step < steps.length && <StepDivider />}
            </Fragment>
          )
        })}
      </Container>
    )
  }
}

Stepper.defaultProps = {
  activeStep: 1,
  steps: ['Build lesson', 'Name', 'Share'],
}

Stepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.array,
}

export default connect(null, {})(Stepper)
