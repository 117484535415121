import { createSelector } from 'reselect'

export const getState = state => state.ui.quest

export const getIsQuest = createSelector(getState, state => state.open)
export const getIsRichMediaOpen = createSelector(
  getState,
  state => state.richMediaOpen,
)
export const getIsSpecialLoading = createSelector(
  getState,
  state => state.isSpecialLoading,
)

export const getHelpFindContents = createSelector(
  getState,
  state => state.helpMeFind,
)

export const getHelpFoundContents = createSelector(
  getState,
  state => state.helpMeFound,
)

export const getFindContents = createSelector(
  getState,
  state => state.findContentId,
)

export const getLessonId = createSelector(getState, state => state.lessonId)

export const getWebGLPause = createSelector(getState, state => state.pauseWebGL)

export const getWebGLReduced = createSelector(
  getState,
  state => state.reduceWebGL,
)

export const getLessonsPopupOpenRequested = createSelector(
  getState,
  state => state.lessonsPopupOpenRequested,
)
