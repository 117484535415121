/* eslint-disable */
import { get, map, mergeWith, omit, pick, reject, set, values } from 'lodash'

import { mergers } from '../../../utils'
import { LOG_OUT } from '../../Actions/viewer'
import handlersReducer from './handlers'

const initialState = {
  withoutImmutable: true,
}

export default (state = initialState, action) => {
  const data = get(action, 'payload.data')
  const deleteType = get(action, 'options.deleteType', null)
  const ok = get(action, 'ok', false)
  const isRaw = get(action, 'isRaw', false)
  const updateRelationships = get(action, 'options.updateRelationships')
  const updateData = get(action, 'options.updateData')
  const method = get(action, 'options.method')

  if (ok) {
    const newObjectRelationship = {}
    const newObjectUpdateData = {}

    // Dealing with updateData: updating store->data->modelName
    if (updateData) {
      const { modelName } = updateData

      const modelData = get(state, `${modelName}`)

      const dataValues = get(data, `${modelName}`)

      newObjectUpdateData[modelName] = { ...modelData, ...dataValues }
    }

    // Dealing with updateRelationship: updating store->data->modelName->relationShip
    if (updateRelationships) {
      updateRelationships.map(itemRelationship => {
        const {
          modelName,
          id,
          relationship,
          relationshipModel,
          idToAdd,
          typeToAdd,
        } = itemRelationship

        const item = get(state, `${modelName}.${id}`)
        const relationshipArray = get(
          item,
          `relationships.${relationship}.data`,
          [],
        )

        const dataValues =
          idToAdd && typeToAdd
            ? [{ id: idToAdd, type: typeToAdd }]
            : map(
                values(get(data, `${relationshipModel || relationship}`)),
                itm => pick(itm, ['id', 'type']),
              )
        let newRelationshipArray = [...relationshipArray, ...dataValues]

        if (method === 'DELETE') {
          newRelationshipArray = [
            ...reject(relationshipArray, itm =>
              map(dataValues, v => v.id).includes(itm.id),
            ),
          ]
        }

        newObjectRelationship[modelName] = {}
        newObjectRelationship[modelName][id] = { ...item }

        set(
          newObjectRelationship,
          [modelName, id, 'relationships', relationship, 'data'],
          newRelationshipArray,
        )
      })
    }
    if (updateData || updateRelationships) {
      const { modelName } = updateData || updateRelationships
      if (method === 'DELETE' && deleteType !== 'soft') {
        const newObjectFiltered = {
          ...reject(newObjectUpdateData[`${modelName}`], itm =>
            map(data[`${modelName}`], v => v.id).includes(itm.id),
          ),
        }
        const newObjectOrdered = {}

        map(newObjectFiltered, el => {
          newObjectOrdered[el.id] = el
        })

        newObjectUpdateData[`${modelName}`] = newObjectOrdered
      }

      let nextState = mergeWith(
        {},
        state,
        { ...newObjectRelationship },
        { ...newObjectUpdateData },
        mergers.latestArrayMerger,
      )
      if (method === 'DELETE' && modelName === 'tasks') {
        /* This seems to stop things loading correctly after
         * 1. deleting a Favourite or
         * 2. removing a student from a class
         * so restricting it to just task deletions for now
         */
        nextState = { ...nextState, ...newObjectUpdateData }
      }
      return nextState
    }
  }

  if (action.type === LOG_OUT) {
    return mergeWith({}, initialState, mergers.latestArrayMerger)
  }

  if (data && ok && !isRaw) {
    const nextState = mergeWith(
      {},
      state,
      omit(data, 'meta'),
      mergers.latestArrayMerger,
    )
    // Listen only for API success events
    return handlersReducer(nextState, action)
  }

  return state
}
