import { getTranslation } from '@lyfta/components-i18n'
import { makeSentenceCase } from '@lyfta/components-i18n/src/services/utils'
import { showNotification } from '@lyfta/components-ui/src/Services/Utils'
import { camelCase } from 'lodash'
import { all, takeLatest } from 'redux-saga/effects'

import {
  FAILURE_MESSAGE_ACTION,
  SUCCESS_MESSAGE_ACTION,
} from '../Actions/messages'

const ACTION_MESSAGE_MAP = { POST: 'create', PATCH: 'update', DELETE: 'delete' }

function success({ options }) {
  const { method, entityName, endpoint, customMessage } = options
  const modelName =
    entityName || camelCase(endpoint.split('?')[0].split('/')[1])
  const customSuccessMessage = customMessage?.success?.message
  const blockDefault =
    customMessage?.blockDefaultMessages ||
    customMessage?.success?.blockDefaultMessage
  if (customSuccessMessage && typeof customSuccessMessage === 'string')
    return showNotification(
      getTranslation(customSuccessMessage, { modelName }),
      'success',
    )

  if (customSuccessMessage && typeof customSuccessMessage === 'object') {
    Object.values(customSuccessMessage).forEach(message => {
      return showNotification(getTranslation(message, { modelName }), 'success')
    })
  }
  if (!customSuccessMessage) {
    if (!ACTION_MESSAGE_MAP[method] || blockDefault) return true

    return showNotification(
      getTranslation(`core.messages.success.${ACTION_MESSAGE_MAP[method]}`, {
        modelName,
      }),
      'success',
    )
  }

  return true
}

function failure({ failureData, options }) {
  const { statusCode } = failureData

  const { entityName, endpoint, customMessage } = options
  const modelName =
    entityName || camelCase(endpoint.split('?')[0].split('/')[1])
  const customErrorMessage = customMessage?.error?.message
  const blockDefault =
    customMessage?.blockDefaultMessages ||
    customMessage?.error?.blockDefaultMessage
  if (customErrorMessage && typeof customErrorMessage === 'string')
    return showNotification(
      getTranslation(customErrorMessage, { modelName }),
      'error',
    )

  if (
    failureData?.data?.body &&
    customErrorMessage === undefined &&
    !blockDefault
  ) {
    const {
      data: { body },
    } = failureData
    if (body.errors) {
      const { detail } = body.errors[0]
      let displayMessage = detail

      if (typeof detail === 'object') {
        const { error_identifier: identifier, message } = detail
        const messageKey = `core.messages.errors.${identifier}`

        const translatedMessage = getTranslation(messageKey)
        displayMessage =
          translatedMessage === messageKey ? message : translatedMessage
      }
      if (displayMessage)
        return showNotification(makeSentenceCase(displayMessage), 'error')
      return false
    }
  }
  if (
    customErrorMessage === undefined &&
    statusCode !== 401 &&
    statusCode !== 404 &&
    !blockDefault
  ) {
    return showNotification(
      getTranslation('core.messages.errors.default'),
      'error',
    )
  }
  return true
}

export default function* root() {
  yield all([
    takeLatest(SUCCESS_MESSAGE_ACTION, success),
    takeLatest(FAILURE_MESSAGE_ACTION, failure),
  ])
}
