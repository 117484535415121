import * as React from 'react'

function SvgClose(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 100 100"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#000" fillRule="nonzero">
        <path d="M6.23 73.453L73.522 6.16a2 2 0 012.828 0l17.05 17.05a2 2 0 010 2.828L26.108 93.331a2 2 0 01-2.829 0L6.23 76.281a2 2 0 010-2.828z" />
        <path d="M26.108 6.16L93.4 73.453a2 2 0 010 2.828l-17.05 17.05a2 2 0 01-2.828 0L6.229 26.038a2 2 0 010-2.828L23.28 6.16a2 2 0 012.829 0z" />
      </g>
    </svg>
  )
}

export default SvgClose
