import { I18n, getTranslation, validLanguages } from '@lyfta/components-i18n'
import { capitalizeFirstLetter } from '@lyfta/components-i18n/src/services/utils'
import React from 'react'

import meetingAttendeeStatuses from './meetingAttendeeStatuses'

export * from './permissions'

export const userJobRoles = [
  'exec_leader',
  'leadership_team',
  'senior_leader',
  'subject_leader',
  'teacher',
  'teaching_assistant',
  'student',
  'parent',
  'other',
]
export const HS_JOB_ROLES = {
  exec_leader: 'Executive Leader',
  parent: 'Parent / Carer',
  leadership_team: 'Leadership Team',
  teacher: 'Teacher',
  principal: 'Principal',
  teaching_assistant: 'Support Staff',
}

export const jobRoleFilterOptions = [
  { value: 'all', label: <I18n i18nKey="filters.status.all" /> },
  {
    value: 'exec_leader',
    label: <I18n i18nKey="users.jobRoles.exec_leader" />,
  },
  {
    value: 'leadership_team',
    label: <I18n i18nKey="users.jobRoles.leadership_team" />,
  },
  { value: 'teacher', label: <I18n i18nKey="users.jobRoles.teacher" /> },
  {
    value: 'teaching_assistant',
    label: <I18n i18nKey="users.jobRoles.teaching_assistant" />,
  },
]

export const licenceTypeFilterOptions = [
  { value: 'all', label: <I18n i18nKey="fields.licenceType.all" /> },
  {
    value: 'subscribed',
    label: <I18n i18nKey="fields.licenceType.subscribed" />,
  },
  {
    value: 'supplementary',
    label: <I18n i18nKey="fields.licenceType.supplementary" />,
  },
  {
    value: 'fullTrial',
    label: <I18n i18nKey="fields.licenceType.fullTrial" />,
  },
  { value: 'trial', label: <I18n i18nKey="fields.licenceType.trial" /> },
]

export const statusFilterOptions = [
  { value: 'all', label: <I18n i18nKey="filters.status.all" /> },
  { value: 'active', label: <I18n i18nKey="filters.status.active" /> },
  { value: 'deleted', label: <I18n i18nKey="filters.status.archived" /> },
]

export const classesFilterOptions = [
  { value: 'all', label: <I18n i18nKey="filters.status.all" /> },
  { value: 'active', label: <I18n i18nKey="filters.status.active" /> },
  { value: 'archived', label: <I18n i18nKey="filters.status.archived" /> },
]

export const lessonFilterOptions = [
  {
    value: 'all',
    label: <I18n i18nKey="lessons.states.filters.state.all" />,
  },
  {
    value: 'active',
    label: <I18n i18nKey="lessons.states.filters.state.active" />,
  },
  {
    value: 'archived',
    label: <I18n i18nKey="lessons.states.filters.state.archived" />,
  },
]

export const publishedFilterOptions = [
  { value: 'all', label: <I18n i18nKey="filters.status.all" /> },
  { value: 'published', label: <I18n i18nKey="filters.status.published" /> },
  { value: 'draft', label: <I18n i18nKey="filters.status.draft" /> },
]

const formatValidLangs = validLanguages.map(locale => {
  return { value: locale, label: <I18n i18nKey={`locale.options.${locale}`} /> }
})

export const validLanguagesFilterOptions = [
  { value: 'all', label: <I18n i18nKey="filters.status.all" /> },
  ...formatValidLangs,
]

export const modelNames = [
  'licensedContents',
  'licensings',
  'subscriptions',
  'userPermissions',
  'activities',
  'users',
  'admins',
  'assetDetails',
  'authors',
  'contents',
  'courses',
  'courseLessons',
  'curriculums',
  'discountCodes',
  'experiences',
  'experienceContexts',
  'favorites',
  'homeStudents',
  'klasses',
  'lessons',
  'lessonPlans',
  'licenses',
  'meetings',
  'meetingAttendees',
  'meetingInvites',
  'meetingOccurrences',
  'oAuthCredentials',
  'organizations',
  'lookupResults',
  'parents',
  'schoolValidationData',
  'students',
  'subscriptionPayments',
  'tags',
  'tagGroups',
  'taggedContents',
  'tasks',
  'teachers',
  'userLicensings',
  'usersImports',
  'usersKlasses',
  'subscriptionPlans',
  'values',
]

export const relationshipTypeMap = {
  occurrence: 'meetingOccurrences',
  attendee: 'meetingAttendees',
  suggestedMeeting: 'meetings',
  defaultHost: 'adminUsers',
  host: 'adminUsers',
}

export { meetingAttendeeStatuses }

export const ageRanges = [
  {
    value: '1',
    label: capitalizeFirstLetter(getTranslation(`content.ageRanges.ages5_7`)),
  },
  {
    value: '2',
    label: capitalizeFirstLetter(getTranslation(`content.ageRanges.ages7_11`)),
  },
  {
    value: '3',
    label: capitalizeFirstLetter(getTranslation(`content.ageRanges.ages11_14`)),
  },
  {
    value: '4',
    label: capitalizeFirstLetter(getTranslation(`content.ageRanges.ages14_16`)),
  },
  {
    value: '5',
    label: capitalizeFirstLetter(
      getTranslation(`content.ageRanges.ages16plus`),
    ),
  },
]

export const subjects = [
  {
    value: '1',
    label: capitalizeFirstLetter(getTranslation(`content.subjects.subject1`)),
  },
  {
    value: '2',
    label: capitalizeFirstLetter(getTranslation(`content.subjects.subject2`)),
  },
  {
    value: '3',
    label: capitalizeFirstLetter(getTranslation(`content.subjects.subject3`)),
  },
  {
    value: '4',
    label: capitalizeFirstLetter(getTranslation(`content.subjects.subject4`)),
  },
  {
    value: '5',
    label: capitalizeFirstLetter(getTranslation(`content.subjects.subject5`)),
  },

  {
    value: '6',
    label: capitalizeFirstLetter(getTranslation(`content.subjects.subject6`)),
  },
  {
    value: '7',
    label: capitalizeFirstLetter(getTranslation(`content.subjects.subject7`)),
  },
  {
    value: '8',
    label: capitalizeFirstLetter(getTranslation(`content.subjects.subject8`)),
  },
  {
    value: '9',
    label: capitalizeFirstLetter(getTranslation(`content.subjects.subject9`)),
  },
  {
    value: '10',
    label: capitalizeFirstLetter(getTranslation(`content.subjects.subject10`)),
  },
  {
    value: '11',
    label: capitalizeFirstLetter(getTranslation(`content.subjects.subject11`)),
  },
  {
    value: '12',
    label: capitalizeFirstLetter(getTranslation(`content.subjects.subject12`)),
  },
  {
    value: '13',
    label: capitalizeFirstLetter(getTranslation(`content.subjects.subject13`)),
  },
]

export const lessonPlanTypes = [
  {
    value: '1',
    label: <I18n i18nKey="content.lessonPlanTypes.lessons" />,
  },
  {
    value: '2',
    label: <I18n i18nKey="content.lessonPlanTypes.assemblies" />,
  },
  {
    value: '3',
    label: <I18n i18nKey="content.lessonPlanTypes.lyftaTimes" />,
  },
  {
    value: '4',
    label: <I18n i18nKey="content.lessonPlanTypes.accessibleLessons" />,
  },
]

export const honorificTitles = {
  en: [
    {
      label: 'Mx',
      value: 'mx',
    },
    {
      label: 'Ms',
      value: 'ms',
    },
    {
      label: 'Miss',
      value: 'miss',
    },
    {
      label: 'Mrs',
      value: 'mrs',
    },
    {
      label: 'Mr',
      value: 'mr',
    },
    {
      label: 'None',
      value: 'none',
    },
  ],
}
