import { getTranslation } from '@lyfta/components-i18n'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Field } from 'react-final-form'

import { Flex } from '../../Flex'
import FieldErrorNew from '../FieldErrorNew'
import ViewOnly from '../ViewOnly'
import {
  Container,
  Description,
  ErrorIcon,
  Label,
  LabelAndTextareaContainer,
  TextArea,
  TextAreaNoResize,
  Wrapper,
} from './styles'

/*
 * This is a workaround for a known issue (we should monitor and remove this
 * once the issue is resolved)
 * https://github.com/final-form/react-final-form/issues/130
 *
 * Asana ticket the workaround is trying to fix:
 * https://app.asana.com/0/1133701735540444/1179765034749968/f
 */
const identity = value => value

const renderField = props => {
  const {
    description,
    disabled,
    fieldRef,
    focused,
    input,
    inputId,
    label,
    maxRows,
    meta,
    noResize,
    onBlur,
    onFocus,
    onLabelClick,
    placeholder,
    type,
    rows,
    viewOnly,
    hideLabel,
    autosave,
    ...rest
  } = props

  const active = focused || !isEmpty(input?.value?.toString())
  const hasError = meta.touched && (meta.error || meta.submitError)

  const ChosenTextArea = noResize ? TextAreaNoResize : TextArea

  const renderAutosave = () => {
    return (
      focused &&
      autosave && (
        <Flex
          fontSize="12px"
          fontStyle="italic"
          justifyContent="center"
          alignItems="center"
        >
          {getTranslation('actions.autoSaveOn')}
        </Flex>
      )
    )
  }
  if (viewOnly) return <ViewOnly {...props} />

  return (
    <Wrapper className="animatedtextareanew-wrapper" {...rest}>
      <Container
        active={active}
        className="animatedtextareanew-container"
        disabled={disabled}
        error={hasError ? 1 : 0}
        focused={focused}
      >
        <LabelAndTextareaContainer className="animatedtextareanew-labelandtextareacontainer">
          {label && (
            <Label
              active={active}
              className="animatedtextareanew-label"
              onClick={onLabelClick}
            >
              {label}
            </Label>
          )}
          <ChosenTextArea
            ref={fieldRef}
            type={type}
            {...input}
            disabled={disabled}
            id={inputId}
            placeholder={placeholder}
            rows={rows}
            onBlur={onBlur}
            onFocus={onFocus}
            label={label}
          />
        </LabelAndTextareaContainer>
        {hasError ? <ErrorIcon className="animatedinputnew-erroricon" /> : null}
      </Container>
      {hasError ? (
        <FieldErrorNew meta={meta} />
      ) : (
        (description || autosave) && (
          <Flex justifyContent="space-between" alignItems="center">
            <Description focused={focused}>{description}</Description>
            {renderAutosave()}
          </Flex>
        )
      )}
    </Wrapper>
  )
}

renderField.defaultProps = {
  rows: 1,
  maxRows: undefined,
  inputId: '',
  disabled: false,
  noResize: false,
  hideLabel: false,
  viewOnly: false,
  autosave: false,
}

renderField.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  autosave: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldRef: PropTypes.object.isRequired,
  focused: PropTypes.bool.isRequired,
  hideLabel: PropTypes.bool,
  input: PropTypes.object.isRequired,
  inputId: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  maxRows: PropTypes.number,
  meta: PropTypes.object.isRequired,
  noResize: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onLabelClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  rows: PropTypes.number,
  type: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool,
}

class AnimatedTextAreaNew extends PureComponent {
  textField = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      focused: false,
    }
  }

  handleFocus = () => {
    this.setState({ focused: true })
  }

  handleBlur = event => {
    if (event?.relatedTarget) {
      if (event.relatedTarget.contains(event.target)) {
        return
      }
    }
    this.setState({ focused: false })
  }

  handleLabelClick = () => {
    this.textField.current.focus()
  }

  render() {
    const {
      allowEmptyField,
      label,
      name,
      placeholder,
      type,
      handleBlur,
      description,
      autosave,
      ...rest
    } = this.props
    const { focused } = this.state

    return (
      <Field
        autosave={autosave}
        description={description}
        fieldRef={this.textField}
        focused={focused}
        label={label}
        name={name}
        onFocus={this.handleFocus}
        onBlur={event => {
          this.handleBlur(event)
          if (handleBlur) {
            handleBlur()
          }
        }}
        onLabelClick={this.handleLabelClick}
        placeholder={placeholder}
        render={renderField}
        type={type}
        {...(allowEmptyField && { parse: identity })}
        {...rest}
      />
    )
  }
}

AnimatedTextAreaNew.defaultProps = {
  allowEmptyField: true,
  autosave: false,
  description: '',
  label: '',
  noResize: false,
  placeholder: '',
  type: 'text',
  handleBlur: noop,
}

AnimatedTextAreaNew.propTypes = {
  allowEmptyField: PropTypes.bool,
  autosave: PropTypes.bool,
  handleBlur: PropTypes.func,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string.isRequired,
  noResize: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
}

export default AnimatedTextAreaNew
