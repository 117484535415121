import { styled, themeGet } from '@lyfta/components-theme'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'react-final-form'

import { Label as BaseLabel } from '../../../BaseComponents/index'
import { Flex } from '../../Flex'

const Container = styled(Flex).attrs(() => ({
  mb: 3,
}))`
  flex-direction: column;

  input {
    margin: 0;
    padding: 8px 16px;
    width: 100%;
    border: 1px solid ${themeGet('colors.primary')};
    border-radius: 4px;
  }
`

const Label = styled(BaseLabel)``

const renderField = ({ input, meta, label, placeholder, type, autoFocus }) => (
  <Container>
    {label && <Label>{label}</Label>}
    {/* eslint-disable */}

    <input
      type={type}
      {...input}
      placeholder={placeholder}
      autoFocus={autoFocus}
    />
    {/* eslint-enable */}

    {meta.touched && meta.error && <span>{meta.error}</span>}
  </Container>
)

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool.isRequired,
}

const Input = ({ label, name, placeholder, type, autoFocus }) => (
  <Field
    autoFocus={autoFocus}
    label={label}
    name={name}
    placeholder={placeholder}
    render={renderField}
    type={type}
  />
)

Input.defaultProps = {
  autoFocus: false,
  label: '',
  placeholder: '',
  type: 'text',
}

Input.propTypes = {
  autoFocus: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
}

export default Input
