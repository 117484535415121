import { all, takeLatest } from 'redux-saga/effects'

import { CREATE_MEETING_ATTENDEE } from '../Actions/meetingAttendees'

// eslint-disable-next-line
function* redirectAfterAddMeetingAttendee() {
  window.location.reload()
}

export default function* root() {
  yield all([
    takeLatest(
      CREATE_MEETING_ATTENDEE.SUCCESS,
      redirectAfterAddMeetingAttendee,
    ),
  ])
}
