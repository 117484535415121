import { I18n } from '@lyfta/components-i18n'
import { kebabCase } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { Tab } from './styles'

class Item extends PureComponent {
  handleChangeIndex = () => {
    const { active, index, onChangeIndex } = this.props
    if (!active && onChangeIndex) onChangeIndex(index)
  }

  render() {
    const { active, index, text, width, i18nRootKey } = this.props
    const i18nKey = `${i18nRootKey}.${text}`
    const id = `tab-${kebabCase(text?.props?.i18nKey?.split('.').slice(-1)[0])}`
    return (
      <Tab
        active={active}
        id={id}
        index={index}
        width={width}
        onClick={this.handleChangeIndex}
      >
        {i18nRootKey && <I18n i18nKey={i18nKey} />}
        {!i18nRootKey && text}
      </Tab>
    )
  }
}

Item.defaultProps = {
  active: false,
  i18nRootKey: null,
}

Item.propTypes = {
  active: PropTypes.bool,
  i18nRootKey: PropTypes.string,
  index: PropTypes.number.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  width: PropTypes.number.isRequired,
  onChangeIndex: PropTypes.func.isRequired,
}

export default Item
