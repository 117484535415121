export const ITEMS_PER_PAGE = 'settings/ITEMS_PER_PAGE'
export const LOCALE = 'settings/LOCALE'
export const LOCALE_EDITING = 'settings/LOCALE_EDITING'
export const FILTER_STUDENT = 'settings/FILTER_STUDENT'

export const setItemsPerPage = number => {
  return { type: ITEMS_PER_PAGE, itemsPerPage: number }
}
export const setFilterStudentSetting = filterStudent => {
  return { type: FILTER_STUDENT, filterStudent }
}
export const setLocaleSetting = locale => {
  return { type: LOCALE, locale }
}

export const setLocaleEditingSetting = localeEditing => {
  return { type: LOCALE_EDITING, localeEditing }
}
