import { getTranslation } from '@lyfta/components-i18n'
import { makeSentenceCase } from '@lyfta/components-i18n/src/services/utils'
import { pick } from '@styled-system/props'
import PropTypes from 'prop-types'
import React from 'react'

import {
  ErrorIcon,
  ErrorListContainer,
  ErrorMessage,
  ErrorMessageContainer,
  WholeFormErrorList,
  WholeFormErrorListItem,
} from './styles'

const renderListItems = (
  customErrors,
  defaultError,
  submitErrors,
  showSeparateErrorBlocks,
) => {
  const allErrorMessages = []

  if (submitErrors?.data?.body?.errors) {
    const { detail } = submitErrors.data.body.errors[0]
    let displayMessage = detail

    if (typeof detail === 'object') {
      const { error_identifier: identifier, message } = detail
      const messageKey = `core.messages.errors.${identifier}`

      const translatedMessage = getTranslation(messageKey)
      displayMessage =
        translatedMessage === messageKey ? message : translatedMessage
    }
    if (displayMessage) {
      allErrorMessages.push(makeSentenceCase(displayMessage))
    }
  }
  if (customErrors) {
    customErrors.forEach(error => {
      allErrorMessages.push(error)
    })
  }

  if (defaultError) {
    allErrorMessages.unshift(getTranslation('core.messages.errors.default'))
  }
  /* eslint-disable react/no-array-index-key */
  return (
    <>
      {showSeparateErrorBlocks ? (
        allErrorMessages.map((errorMessage, index) => {
          return (
            <WholeFormErrorListItem
              className="form-error-list-item"
              key={index}
            >
              <ErrorIcon />
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </WholeFormErrorListItem>
          )
        })
      ) : (
        <WholeFormErrorListItem className="form-error-list-item">
          <ErrorIcon />
          <ErrorMessageContainer numberOfMessages={allErrorMessages.length}>
            {allErrorMessages.map((errorMessage, index) => {
              return <ErrorMessage key={index}>{errorMessage}</ErrorMessage>
            })}
          </ErrorMessageContainer>
        </WholeFormErrorListItem>
      )}
    </>
  )
}

export const InlineErrorMessages = ({
  showSeparateErrorBlocks,
  customErrors,
  defaultError,
  submitErrors,
  ...rest
}) => {
  const styledSystemProps = pick(rest)

  return (
    <ErrorListContainer
      className="form-errors-list-container"
      {...styledSystemProps}
    >
      <WholeFormErrorList className="form-errors-list">
        {renderListItems(
          customErrors,
          defaultError,
          submitErrors,
          showSeparateErrorBlocks,
        )}
      </WholeFormErrorList>
    </ErrorListContainer>
  )
}

InlineErrorMessages.defaultProps = {
  customErrors: null,
  defaultError: false,
  showSeparateErrorBlocks: false,
  submitErrors: null,
}

InlineErrorMessages.propTypes = {
  customErrors: PropTypes.array,
  defaultError: PropTypes.bool,
  showSeparateErrorBlocks: PropTypes.bool,
  submitErrors: PropTypes.object,
}
