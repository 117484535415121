import * as React from 'react'

function SvgContentWarning(props) {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3332_6664)">
        <rect
          fill="#050B15"
          height="24"
          rx="5"
          transform="rotate(-180 17 24)"
          width="10"
          x="17"
          y="24"
        />
        <circle
          cx="12"
          cy="19"
          fill="#37D07B"
          r="3"
          transform="rotate(-180 12 19)"
        />
        <circle
          cx="12"
          cy="5"
          fill="#E3462D"
          r="3"
          transform="rotate(-180 12 5)"
        />
        <circle
          cx="12"
          cy="12"
          fill="#ECE424"
          r="3"
          transform="rotate(-180 12 12)"
        />
      </g>
      <defs>
        <clipPath id="clip0_3332_6664">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgContentWarning
