import { styled } from '@lyfta/components-theme'

import { Flex } from '../../Components'

export const PageContainer = styled(Flex)`
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: 100vh;
`
