import * as React from 'react'

function SvgFlag(props) {
  return (
    <svg height="1em" viewBox="0 0 25 34" width="1em" {...props}>
      <path
        d="M2.5 0C3.88 0 5 1.159 5 2.587A2.61 2.61 0 013.929 4.71v27.813c0 .816-.64 1.478-1.429 1.478-.79 0-1.429-.662-1.429-1.478V4.708A2.61 2.61 0 010 2.587C0 1.157 1.12 0 2.5 0zM5 4.791c7.607-2.741 12.393 2.742 20 0l-3.027 6.256L25 16.21c-7.607 2.741-12.393-2.742-20 0z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgFlag
