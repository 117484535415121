import { createAsyncAction } from '../../helpers'
import apiCall from '../../services/Api'

export const LOAD_EVENTS = createAsyncAction('events/LOAD_EVENTS')
export const CREATE_EVENT = createAsyncAction('events CREATE_EVENT')

export const loadEvents = ({ filter, page, sort = '-createdAt' } = {}) =>
  apiCall({
    method: 'GET',
    endpoint: '/events',
    query: {
      page,
      filter,
      sort,
    },
    types: LOAD_EVENTS,
    paged: true,
    payload: { page, filter, sort },
  })
export const createEvent = ({ contentId } = {}) =>
  apiCall({
    method: 'POST',
    customMessage: { blockDefaultMessages: true },
    endpoint: `/contents/${contentId}/shares`,
    types: CREATE_EVENT,
  })
