import { map, times } from 'lodash'
import PT from 'prop-types'
import React, { useState } from 'react'

import { Container, Star, Wrapper } from './styles'

const Checkbox = ({ disabled, setValue, value, ...props }) => {
  const [tempValue, setTempValue] = useState(value)

  const updateValue = val => () => {
    setValue(val)
    setTempValue(val)
  }
  const updateHoverValue = val => () => {
    setTempValue(val)
  }
  return (
    <Container {...props}>
      <Wrapper>
        {map(
          times(5, i => i + 1),
          i => {
            const key = `star-${i}`
            return (
              <Star
                disabled={disabled}
                key={key}
                selected={tempValue >= 0.2 * i}
                onClick={updateValue(0.2 * i)}
                onMouseOut={updateHoverValue(value)}
                onMouseOver={updateHoverValue(0.2 * i)}
              />
            )
          },
        )}
      </Wrapper>
    </Container>
  )
}
Checkbox.propTypes = {
  disabled: PT.bool,
  setValue: PT.func.isRequired,
  value: PT.number.isRequired,
}
Checkbox.defaultProps = {
  disabled: false,
}
export default Checkbox
