import * as React from 'react'

function SvgFilter(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 20V14H11V16L20 16.0556V18H11V20H9ZM0 18V16H7V18H0ZM5 13V11H0V9H5V7H7V13H5ZM9 11V9H20V11H9ZM13 6V0H15V2H20V3.94444L15 4V6H13ZM0 4V2H11V4H0Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgFilter
