import { getTranslation } from '@lyfta/components-i18n'
import { filter } from 'lodash'

import { createReducer } from '../../helpers'
import {
  AUTHENTICATE,
  CLEAR_ERRORS,
  PASSPHRASE,
  PASSPHRASE_TOKEN,
  SET_EMAIL,
  SET_STUDENT_LESSON_ID,
} from '../Actions/auth'
import { LOG_OUT } from '../Actions/viewer'
import { getLocale } from '../Selectors/persist'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  students: null,
  studentLessonId: null,
  email: null,
}

const humanizeError = (type, error) => {
  const locale = getLocale
  switch (type) {
    case PASSPHRASE.FAILURE: {
      if (error.status === 404) {
        return getTranslation('auth.incorrectPassphrase', { locale })
      }
      return null
    }
    case AUTHENTICATE.FAILURE: {
      if (error.status === 401) {
        return getTranslation('auth.expiredSubscription', { locale })
      }
      if (error.status === 404) {
        return getTranslation('auth.incorrectEmailOrPassword', { locale })
      }
      if (error.status === 422) {
        return getTranslation('auth.contactSupport', { locale })
      }
      if (error.status === 470) {
        return ''
      }

      return getTranslation('auth.checkCorrectly', { locale })
    }
    default: {
      return null
    }
  }
}

const handleFailure = (state, { type, error }) =>
  state.merge({
    isLoading: false,
    isLoaded: false,
    error: humanizeError(type, error),
  })

const handleRequest = state =>
  state.merge({ error: null, isLoading: true, isLoaded: false })

const handleSuccess = state =>
  state.merge({
    isLoading: false,
    isLoaded: true,
    error: null,
  })

const handlePassphraseToken = state =>
  state.merge({
    isLoading: false,
    isLoaded: true,
    error: null,
  })

const handlePassphraseSuccess = (state, { payload }) => {
  const students = filter(payload.data.included, { type: 'students' })
  const lessons = filter(payload.data.data, { type: 'lessons' })
  const studentLessonId = lessons.length ? lessons[0].id : null

  // not a valid passphrase
  if (!students || students.length < 1) {
    return state.merge({
      isLoading: false,
      isLoaded: false,
      error: 'Invalid passphrase',
    })
  }

  return state.merge({
    isLoading: false,
    isLoaded: false,
    error: null,
    students,
    studentLessonId,
  })
}

const handlers = {
  [AUTHENTICATE.REQUEST]: handleRequest,
  [AUTHENTICATE.SUCCESS]: handleSuccess,
  [AUTHENTICATE.FAILURE]: handleFailure,

  [PASSPHRASE_TOKEN.SUCCESS]: handlePassphraseToken,

  [PASSPHRASE.REQUEST]: handleRequest,
  [PASSPHRASE.SUCCESS]: handlePassphraseSuccess,
  [PASSPHRASE.FAILURE]: handleFailure,

  [CLEAR_ERRORS]: state => state.merge({ error: null }),
  [SET_STUDENT_LESSON_ID]: (state, { studentLessonId }) =>
    state.merge({ studentLessonId }),
  [SET_EMAIL]: (state, { email }) => state.merge({ email }),

  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers)
