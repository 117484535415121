import {
  styled,
  themeGet,
  walsheimAndFallbackFontFamily,
} from '@lyfta/components-theme'
import FaAngleLeft from 'react-icons/lib/fa/angle-left'
import FaAngleRight from 'react-icons/lib/fa/angle-right'
import { NavLink } from 'react-router-dom'

import { Box, ButtonNew, Flex, Text } from '../../Components'
import { getIsNative } from '../../Helpers/getIsNative'

export const ContainerWrapper = styled(Box).attrs(() => ({}))`
  position: relative;
  min-height: 343px;
  max-height: 343px;
  :hover {
    > svg {
      color: rgba(255, 255, 255, 0.5);
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`

export const EmptyLessonContainer = styled(Flex).attrs(() => ({
  px: 4,
  py: 8,
}))`
  border: 3px dashed ${themeGet('colors.black_200')};
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Container = styled(Box).attrs(() => ({}))`
  width: 100%;
`

export const Row = styled(Box).attrs(() => ({}))`
  display: flex;
  justify-content: space-between;
`

export const Title = styled(Box).attrs(() => ({
  mb: 3,
}))`
  font-size: 28px;
  font-weight: 500;
  color: ${themeGet('colors.font.accent')};
`
export const TitleEmpty = styled(Box).attrs(() => ({ mt: 3 }))`
  font-size: 28px;
  font-weight: 700;
  color: ${themeGet('colors.black')};
`

export const DescriptionEmpty = styled(Box).attrs(() => ({ mt: 3 }))`
  font-size: 19px;
  font-weight: 400;
  color: ${themeGet('colors.black')};
`

export const Subtitle = styled(Flex).attrs(() => ({}))`
  font-family: ${walsheimAndFallbackFontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  color: #656a73;
`

export const ShowAll = styled(NavLink)`
  text-decoration: none;
  font-size: 18px;
  color: ${props => (props.color ? props.color : themeGet('colors.font.link'))};
`

export const RowContainer = styled(Flex).attrs(() => ({}))`
  overflow-x: hidden;
  flex: 0 0 auto;
  width: ${props => (props.bg ? '105%' : '100%')};

  ${props => props.rowContainerHeight && `height: ${props.rowContainerHeight};`}
  overflow-y: hidden;

  justify-content: ${props => (props.centered ? 'center' : 'flex-started')};
  align-items: center;

  ::-webkit-scrollbar {
    width: 5px;
    display: none;
  }
`
export const ButtonAngleLeft = styled(Flex)`
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  color: ${themeGet('colors.primary')};
  border-radius: 50%;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`
export const ButtonAngleRight = styled(Flex)`
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: ${themeGet('colors.primary')};
  border-radius: 50%;
  :hover {
    opacity: 0.7;
  }
`
export const StyledFaAngleLeft = styled(FaAngleLeft)`
  position: absolute;
  top: 0px;
  left: 1px;
  width: 46px;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  color: rgba(131, 129, 129, 0);
  transition: all 0.3s;

  :hover {
    color: ${themeGet('colors.primary')} !important;
  }
`

export const StyledFaAngleRight = styled(FaAngleRight)`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 46px;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  color: rgba(131, 129, 129, 0);
  transition: all 0.3s;

  :hover {
    color: ${themeGet('colors.primary')} !important;
  }
`
export const Label = getIsNative()
  ? styled(Box)
  : styled.span`
      white-space: nowrap;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.15;
      letter-spacing: 0.4px;
      color: ${themeGet('secondary')};
      &:hover {
        text-decoration: underline;
      }
    `

export const ContainerWrapperCard = styled(Box).attrs(() => ({}))`
  min-width: 100%;
  max-width: 100%;
`

export const ContainerCard = styled(Flex).attrs(() => ({}))`
  min-width: 100%;
  height: 343px;
  flex-direction: row;
  justify-content: ${props => (props.isLicensed ? 'flex-end' : 'center')};
  border-radius: 6px;
  background-color: ${p =>
    themeGet(!p.isLoading ? 'colors.grayHover' : 'colors.white')(p)};
  box-shadow: 0 3px 13px 0 rgba(86, 94, 105, 0.47);
  background-image: ${props => (props.bg ? `url('${props.bg}')` : 'none')};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`
export const FooterContainer = styled(Flex).attrs(() => ({}))`
  width: 100%;
  flex-direction: column;
  justify-content: center;
`
export const OverlayLeft = styled(Flex).attrs(() => ({
  p: 5,
}))`
  border-radius: 6px 0px 0 6px;
  width: 100%;
  height: 100%;
  flex: 5;
  flex-direction: column;
  justify-content: flex-end;
  background-color: ${themeGet('colors.black_400')};

  background-image: ${props => (props.bg ? `url('${props.bg}')` : 'none')};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`
export const OverlayRight = styled(Flex).attrs(() => ({
  p: 5,
}))`
  flex: 3;
  border-radius: 6px 0 0 6px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: ${props => (props.isLicensed ? 'flex-end' : 'center')};
`

export const Header = styled(Flex).attrs(() => ({}))`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  font-size: 33px;
  line-height: 40px;
  letter-spacing: 1px;
  color: ${themeGet('colors.white')};
`

export const Body = styled(Flex).attrs(() => ({}))`
  width: 100%;

  flex-direction: column;
  justify-content: center;
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  color: ${themeGet('colors.white')};
  letter-spacing: 0.5px;
`

export const TitleCard = styled(Flex).attrs(() => ({}))`
  font-weight: 700;
  font-size: 33px;
  line-height: 40px;
  letter-spacing: 1px;

  color: ${themeGet('colors.white')};
`

export const Headline = styled(Text).attrs(() => ({
  fontSize: 6,
}))`
  line-height: 1.22;
  font-weight: 900;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  color: ${themeGet('colors.white')};
`

export const SubTitle = styled(Text).attrs(() => ({
  fontSize: 5,
}))`
  color: ${themeGet('colors.white')};
`

export const Description = styled(Text).attrs(() => ({
  fontSize: 3,
  mb: 2,
  mt: 1,
}))`
  max-width: 80%;
  font-weight: 300;
  line-height: 1.4;
  color: ${themeGet('colors.white')};
`

export const Interactions = styled(Flex).attrs(() => ({
  mt: 2,
  mb: 2,
}))``

export const StyledButton = styled(ButtonNew).attrs(() => ({
  mr: 3,
  px: 3,
}))`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.05em;
`

export const GoToGettingStarted = styled(Flex).attrs(() => ({
  width: '700px',
}))`
  font-weight: 800;
  font-size: 55px;
  line-height: 76px;
  font-style: normal;
  letter-spacing: 1.5px;
  cursor: pointer;

  color: ${themeGet('colors.white')};

  justify-content: center;

  > span {
    letter-spacing: 1.5px;
  }
  :hover {
    text-decoration-line: underline;
  }
`
