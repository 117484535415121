import { createSelector } from 'reselect'

export const getState = state => state.search

export const getTags = createSelector(getState, state => state.tags)

export const getKeywords = createSelector(getState, state =>
  state.keywords.asMutable(),
)
export const getFilters = createSelector(getState, state => state.filters)
export const getNewSearch = createSelector(getState, state => state.newSearch)
export const getSort = createSelector(getState, state => state.sort)
export const getTotalResult = createSelector(
  getState,
  state => state.totalResult,
)

export const getSearchEditors = createSelector(
  getState,
  state => state.searchEditors,
)
export const getSearchEditorsFilters = createSelector(
  getState,
  state => state.searchEditors.filters,
)
export const getSearchEditorsSort = createSelector(
  getState,
  state => state.searchEditors.sort,
)
export const getSearchEditorsKeywords = createSelector(
  getState,
  state => state.searchEditors.keywords,
)
// Getting from getFilters, the total number of filters (ignoring the first one) and the string
export const getFiltersInfo = createSelector(getFilters, filters => {
  const filtersInfo = Object.values(filters).reduce(
    (acc, group) => {
      const groupDefault = group.default

      const values = Object.entries(group.options)

      values.forEach(([key, value]) => {
        if (value && key !== groupDefault) {
          // eslint-disable-next-line
          acc.totalTrueItems++
          acc.items += acc.items ? `,${key}` : key
        }
      })
      return acc
    },
    { totalTrueItems: 0, itemsString: '' },
  )

  return filtersInfo
})
export const getFiltersInfoEditor = createSelector(
  getSearchEditorsFilters,
  filters => {
    const filtersInfo = Object.values(filters).reduce(
      (acc, group) => {
        const groupDefault = group.default

        const values = Object.entries(group.options)

        values.forEach(([key, value]) => {
          if (value && key !== groupDefault) {
            // eslint-disable-next-line
            acc.totalTrueItems++
            acc.items += acc.items ? `,${key}` : key
          }
        })
        return acc
      },
      { totalTrueItems: 0, itemsString: '' },
    )

    return filtersInfo
  },
)

export const getDashboard = createSelector(getState, state => state.dashboard)

export const getType = createSelector(getState, state => state.type)

export const getNumber = createSelector(getState, state => state.number)

export const getSize = createSelector(getState, state => state.size)

export const getSearchText = createSelector(getState, state => state.searchText)
