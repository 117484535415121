import { styled, themeGet } from '@lyfta/components-theme'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'
import { Box, Flex, Lock } from '@lyfta/components-ui'

import Curve from './curve.png'

const containerPadding = '16px'

export const Card = styled(Flex)`
  position: relative;
  min-width: 240px;
  height: 140px;
  background-image: url(${Curve}),
    linear-gradient(90.49deg, #1e2c91 0.42%, #0094ac 99.58%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
  justify-content: flex-start;
  align-items: flex-end;
  box-shadow: ${shadows.lifted};
  cursor: pointer;
  flex: 1;
  overflow: hidden;
  &:hover {
    box-shadow: ${shadows.raised};
  }
  &:focus-visible {
    box-shadow: ${shadows.raised};
  }
`

export const NumberCard = styled(Box).attrs(() => ({ px: 3, pt: 2, pb: 3 }))`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 40px;
  line-height: 48px;
`
export const IconContainer = styled(Box).attrs(() => ({ px: 3, pt: 2, pb: 3 }))`
  position: absolute;
  top: 5px;
  right: 0;
`

export const Label = styled.span`
  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: ${themeGet('secondary')};
  &:hover {
    text-decoration: underline;
  }
`
export const LabelMouseOver = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.5px;
  color: ${themeGet('secondary')};
  text-decoration: underline;
`

export const Container = styled(Box)`
  padding: ${containerPadding};
`

export const Locked = styled(Flex)`
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
`

export const LockedIcon = styled(Lock)`
  bottom: ${containerPadding};
  position: absolute;
  right: ${containerPadding};
  & > path {
    fill: ${themeGet('colors.neutral_100')};
  }
`
