import { setItemsPerPage } from '@lyfta/components-data/src/Store/Actions/settings'
import { forEach, get, isEmpty, map } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useDebouncededValue } from '../../../Hooks/useDebouncedValue'
import { useTableData } from './useTableData'

const usePagedTable = ({
  action,
  columns,
  selector,
  loadingSelector,
  pageSelector,
  dataParser,
  defaultPageSize,
  defaultSort,
  defaultFilter,
  searchColumn,
  disableRow,
  permissions,
  columnActions,
  tableId,
  sortProperty,
}) => {
  const dispatch = useDispatch()
  const routeParams = useParams()
  const [tableFilters, setTableFilters] = useState([])
  const {
    tableData,
    tableColumns,
    loading,
    selections,
    pageSize,
    setPageSize,
  } = useTableData(
    columns,
    selector,
    loadingSelector,
    dataParser,
    defaultPageSize,
    disableRow,
    permissions,
    columnActions,
    tableFilters,
  )

  // double () here allows us to get the options passed to the action,
  // which we can use below to clear the dataset when the table is unmounted
  // this resolves paging issues when navigating between pages with tables
  const { types: actionTypes } = action({})()
  useEffect(() => () => dispatch({ type: actionTypes.CLEAR }), [])

  const { number, count: totalPages, totalRecords } = (pageSelector &&
    useSelector(pageSelector)) || {
    number: 1,
    totalPages: 0,
    totalRecords: 0,
  }

  const resetScrollInsideTable = tId => {
    const tableBody = document.querySelector(`[id="${tId}"]  .rt-tbody`)
    if (tableBody) tableBody.scrollTop = 0
  }

  const [reset, setReset] = useState(true)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchString, setSearchString] = useState('')
  const debouncedSearchTerm = useDebouncededValue(searchString, 500)

  const [canPrevious, setCanPrevious] = useState(false)
  const [canNext, setCanNext] = useState(true)
  const [sortedArray, setSortedArray] = useState([])

  const processSort = sort => {
    const [field, direction] = sort.split('.')
    const desc = direction === 'desc'

    setSortedArray(arr => [...arr, { id: field, desc }])
    return `${desc ? '-' : ''}${field}`
  }

  const initialSort = useMemo(() => {
    if (defaultSort) {
      return map(defaultSort.split(','), s => processSort(s)).join(',')
    }
    return ''
  }, [defaultSort])

  const [sort, setSort] = useState(initialSort)

  useEffect(() => {
    if (number) setPageNumber(number)
    return () => setPageNumber(1)
  }, [number])

  useEffect(() => {
    setCanPrevious(pageNumber > 1)
    setCanNext(pageNumber + 1 <= totalPages)
  }, [pageNumber, totalPages])

  useEffect(() => {
    if (sortProperty) {
      setSort(sortProperty)
    }
  }, [sortProperty])

  const updatePageSize = useCallback(
    ({ value: newSize }) => {
      if (!loading) {
        setPageSize(newSize)
        dispatch(setItemsPerPage(newSize))
        setPageNumber(1)
        resetScrollInsideTable(tableId)

        setReset(true)
      }
    },
    [setPageSize, loading, tableId],
  )
  const nextPage = useCallback(() => {
    if (pageNumber + 1 <= totalPages && !loading) {
      setPageNumber(pageNumber + 1)
      resetScrollInsideTable(tableId)
    }
  }, [pageNumber, totalPages, loading, tableId])

  const choosePage = useCallback(
    pageSelected => {
      if (!loading) {
        setPageNumber(pageSelected)
        resetScrollInsideTable(tableId)
      }
    },
    [pageNumber, totalPages, loading, tableId],
  )

  const previousPage = useCallback(() => {
    if (pageNumber > 1 && !loading) {
      setPageNumber(pageNumber - 1)
      resetScrollInsideTable(tableId)
    }
  }, [pageNumber, totalPages, loading, tableId])

  const performSearch = useCallback(
    e => {
      const inputText = get(e, 'target.value') || ''
      setSearchString(inputText)

      if (inputText.length >= 2 || inputText.length === 0) {
        setPageNumber(1)
        setReset(true)
      }
    },
    [pageNumber, totalPages],
  )

  const updateSort = sortArray => {
    const sortColumns = map(sortArray, ({ id: fieldName, desc }) =>
      desc ? `-${fieldName}` : fieldName,
    )
    resetScrollInsideTable()
    setPageNumber(1)
    setSort(sortColumns.join(','))
  }

  const prevDeps = useRef({
    pageSize,
    pageNumber,
    sort,
    debouncedSearchTerm,
    routeParams,
    defaultFilter,
    tableFilters,
  })

  const loadData = useCallback(() => {
    const currentDeps = {
      pageSize,
      pageNumber,
      sort,
      debouncedSearchTerm,
      routeParams,
      defaultFilter,
      tableFilters,
    }
    const changedDeps = Object.keys(prevDeps.current).filter(
      key => prevDeps.current[key] !== currentDeps[key],
    )
    if (loading) return

    const resetNumber = () => {
      if (changedDeps.length === 1 && changedDeps.includes('tableFilters')) {
        setPageNumber(1)
        return true
      }
      return false
    }
    let actionParams = {
      ...routeParams,
      reset,
      page: { size: pageSize, number: resetNumber() ? 1 : pageNumber || 1 },
    }
    const filter = { ...defaultFilter }

    forEach(tableFilters, ({ id: field, value }) => {
      if (isEmpty(value) || value === 'all') {
        if (filter[field]) delete filter[field]
      } else {
        filter[field] = value
      }
    })

    if (searchColumn && debouncedSearchTerm.length >= 2) {
      filter[searchColumn] = searchString
    }
    if (searchColumn && debouncedSearchTerm.length === 0) {
      delete filter[searchColumn]
    }

    actionParams = { ...actionParams, filter }

    if (sort && sort.length > 0) {
      actionParams = { ...actionParams, sort }
    }
    // Update the ref with the new values for the next run
    prevDeps.current = currentDeps

    dispatch(action(actionParams))
    selections.setSelected([])
    selections.setAllSelected(false)
    setReset(false)
  }, [
    pageSize,
    pageNumber,
    sort,
    debouncedSearchTerm,
    routeParams,
    defaultFilter,
    tableFilters,
  ])

  useEffect(() => {
    loadData()
  }, [
    pageSize,
    pageNumber,
    sort,
    debouncedSearchTerm,
    routeParams,
    defaultFilter,
    tableFilters,
  ])

  return {
    tableData,
    tableColumns,
    selections,
    loading,
    routeParams,
    reload: loadData,
    filters: {
      change: filters => {
        setTableFilters([...filters])
      },
    },
    pager: {
      next: nextPage,
      choosePage,
      previous: previousPage,
      current: pageNumber,
      total: totalRecords || 0,
      totalPages,
      size: pageSize,
      setSize: updatePageSize,
      canPrevious,
      canNext,
    },
    search: {
      string: searchString,
      perform: performSearch,
    },
    sort: {
      column: sort,
      update: updateSort,
      array: sortedArray,
    },
  }
}

export default usePagedTable
