import { createSelector } from 'reselect'

import { getJWT } from './persist'

export const getState = state => state.auth

export const getJWTHeader = createSelector(getJWT, jwt =>
  jwt ? `Bearer ${jwt}` : null,
)

export const isAuthenticated = createSelector(getJWT, jwt => jwt !== null)

export const getIsLoaded = createSelector(getState, state => state.isLoaded)
export const getIsLoading = createSelector(getState, state => state.isLoading)
export const getError = createSelector(getState, state => state.error)
export const getStudents = createSelector(getState, state => state.students)
export const getStudentLessonId = createSelector(
  getState,
  state => state.studentLessonId,
)
export const getEmail = createSelector(getState, state => state.email)
