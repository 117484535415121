import colors from './colors'
import shadows from './shadows'

export default {
  subtypes: {
    registration: {
      primary: {
        default: {
          backgroundColor: colors.primary,
          color: colors.white,
          borderColor: colors.primary,
          minHeight: '50px',
          width: '250px',
          fill: colors.secondary,
        },
        hover: {
          opacity: 0.8,
          backgroundColor: colors.primary,
          color: colors.white,
          borderColor: colors.primary,
          fill: colors.secondary,
        },
        pressed: {
          backgroundColor: colors.primary,
          color: colors.white,
          borderColor: colors.primary,
        },
      },
      secondary: {
        default: {
          minHeight: '50px',
          width: '250px',
          backgroundColor: colors.white,
          color: colors.primary,
          borderColor: colors.primary,
        },
        hover: {
          backgroundColor: colors.primary,
          color: colors.white,
          borderColor: colors.primary,
        },
        pressed: {
          backgroundColor: colors.primary,
          color: colors.white,
          borderColor: colors.primary,
        },
      },
    },
    filter: {
      primary: {
        // i.e. selected
        default: {
          backgroundColor: colors.primary_100,
          borderColor: colors.primary_100,
          color: colors.neutral_100,
          minHeight: '40px',
          letterSpacing: '1px',
          boxShadow: 'none',
          borderWidth: '1px',
          cursor: 'default',
        },
        focusVisible: {
          backgroundColor: colors.primary_100,
          borderColor: colors.neutral_100,
          boxShadow: shadows.skim,
          color: colors.neutral_100,
          outlineColor: colors.secondary_100,
          outlineStyle: 'solid',
          outlineWidth: '1px',
        },
        hover: {
          backgroundColor: colors.primary_100,
          borderColor: colors.primary_100,
          boxShadow: 'none',
          color: colors.neutral_100,
        },
        pressed: {
          backgroundColor: colors.primary_100,
          borderColor: colors.primary_100,
          boxShadow: 'none',
          color: colors.neutral_100,
        },
      },
      secondary: {
        // i.e. unselected
        default: {
          backgroundColor: colors.neutral_100,
          borderColor: colors.black_300,
          color: colors.black_600,
          lineHeight: '1',
          minHeight: '40px',
          letterSpacing: '1px',
          boxShadow: 'none',
          fill: colors.black_600,
          borderWidth: '1px',
        },
        focusVisible: {
          backgroundColor: colors.primary_60,
          borderColor: colors.neutral_100,
          boxShadow: shadows.skim,
          color: colors.black_600,
          outlineColor: colors.secondary_100,
          outlineStyle: 'solid',
          outlineWidth: '1px',
        },
        hover: {
          backgroundColor: colors.primary_60_50,
          borderColor: colors.black_300,
          boxShadow: shadows.skim,
          fill: colors.black_600,
          color: colors.black_600,
        },
        pressed: {
          backgroundColor: colors.neutral_100,
          borderColor: colors.black_300,
          color: colors.black_600,
          boxShadow: 'none',
          fill: colors.black_600,
        },
      },
    },
  },
}
