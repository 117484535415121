import { adminPermissions, teacherPermissions } from '@lyfta/components-data'
import { I18n } from '@lyfta/components-i18n'
import { map } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useField } from 'react-final-form'

import Checkbox from '../../Components/Form/Checkbox'
import { Container, PermissionContainer, PermissionLabel } from './styles'

const PermissionsManagement = ({ name, admin }) => {
  const {
    input: { value },
  } = useField(name)

  const permissions = admin ? adminPermissions : teacherPermissions

  return (
    <Container>
      {value &&
        map(permissions, permission => (
          <PermissionContainer key={permission}>
            <Checkbox mb={2} mt={2} name="userPermissions" value={permission} />
            <I18n tag={PermissionLabel} text={`permissions.${permission}`} />
          </PermissionContainer>
        ))}
    </Container>
  )
}

PermissionsManagement.defaultProps = { admin: false }
PermissionsManagement.propTypes = {
  admin: PropTypes.bool,
  name: PropTypes.string.isRequired,
}

export default PermissionsManagement
