import {
  createDeleteReducerHandlers,
  createReducerHandlers,
} from '../../../utils'
import {
  CREATE_CHILD,
  DELETE_CHILD,
  LOAD_CHILD,
  LOAD_CHILDREN,
  UPDATE_CHILD,
} from '../../Actions/users'

export default {
  ...createDeleteReducerHandlers('homeStudents', DELETE_CHILD, {
    mapToKey: 'children',
  }),
  ...createReducerHandlers('homeStudents', LOAD_CHILDREN, {
    withReplace: true,
    mapToKey: 'children',
  }),
  ...createReducerHandlers('homeStudents', LOAD_CHILD, {
    singular: true,
    mapToKey: 'currentChild',
  }),
  ...createReducerHandlers('homeStudents', CREATE_CHILD, {
    singular: true,
    mapToKey: 'currentChild',
  }),
  ...createReducerHandlers('homeStudents', UPDATE_CHILD, {
    singular: true,
    mapToKey: 'currentChild',
  }),
}
