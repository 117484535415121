import { createSelector } from 'reselect'

import { getAllEntities, getEntity } from '../../../utils'

export const getParents = getAllEntities('parents', 'users', 'parentUsers')
export const getCurrentParent = getEntity('parents', 'users', 'parentUsers')

export const getParentUserName = createSelector(
  getCurrentParent,
  (user = {}) => {
    const { firstName, lastName, email } = user

    if (firstName || lastName) {
      return `${firstName} ${lastName} `
    }

    return email
  },
)
