import PropTypes from 'prop-types'
import React from 'react'

import { Title } from './styles'

export const RibbonRowTitle = ({ tag, children, ...restProps }) => {
  const Tag = tag

  return <Tag {...restProps}>{children}</Tag>
}
RibbonRowTitle.defaultProps = {
  tag: Title,
  children: '',
}

RibbonRowTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  tag: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.string,
  ]),
}
export default RibbonRowTitle
