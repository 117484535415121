import { noop } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef } from 'react'

const IframeComm = ({
  attributes,
  targetOrigin,
  serializeMessage,
  handleReady,
  handleReceiveMessage,
  postMessageData,
}) => {
  const _frame = useRef(null)

  const serializePostMessageData = useCallback(
    data => {
      // Rely on the browser's built-in structured clone algorithm for serialization of the
      // message as described in
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
      if (!serializeMessage) {
        return data
      }

      // To be on the safe side we can also ignore the browser's built-in serialization feature
      // and serialize the data manually.
      if (typeof data === 'object') {
        return JSON.stringify(data)
      }
      return `${data}`
    },
    [serializeMessage],
  )
  const sendMessage = useCallback(
    msg => {
      const serializedData = serializePostMessageData(msg)
      if (_frame.current) {
        _frame.current.contentWindow.postMessage(serializedData, targetOrigin)
      } else {
        // eslint-disable-next-line no-console
        console.error('No _frame.current found')
      }
    },
    [_frame.current, targetOrigin, serializePostMessageData],
  )

  const onLoad = useCallback(() => {
    if (handleReady) {
      handleReady()
    }
    // TODO: Look into doing a syn-ack TCP-like handshake
    //       to make sure iFrame is ready to REALLY accept messages, not just loaded.
    // send intial props when iframe loads
    sendMessage(postMessageData)
  }, [handleReady, postMessageData, sendMessage])

  const onReceiveMessage = useCallback(
    event => {
      if (handleReceiveMessage) {
        handleReceiveMessage(event)
      }
    },
    [handleReceiveMessage],
  )

  useEffect(() => {
    window.addEventListener('message', onReceiveMessage)
    _frame.current.addEventListener('load', onLoad)
    return () => window.removeEventListener('message', onReceiveMessage, false)
  }, [])

  useEffect(() => {
    sendMessage(postMessageData)
  }, [postMessageData])

  // define some sensible defaults for our  iframe attributes
  const defaultAttributes = {
    allowFullScreen: false,
    frameBorder: 0,
  }
  // then merge in the user's attributes with our defaults
  const mergedAttributes = {
    ...defaultAttributes,
    ...attributes,
  }
  // eslint-disable-next-line jsx-a11y/iframe-has-title
  return <iframe ref={_frame} {...mergedAttributes} />
}

IframeComm.defaultProps = {
  serializeMessage: true,
  targetOrigin: '*',
  handleReady: noop,
  handleReceiveMessage: noop,
}

IframeComm.propTypes = {
  /*
      Iframe Attributes
      https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#Attributes
      React Supported Attributes
      https://facebook.github.io/react/docs/dom-elements.html#all-supported-html-attributes
      Note: attributes are camelCase, not all lowercase as usually defined.
  */
  attributes: PropTypes.shape({
    allowFullScreen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    frameBorder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    scrolling: PropTypes.string,
    // https://www.html5rocks.com/en/tutorials/security/sandboxed-iframes/
    sandbox: PropTypes.string,
    srcDoc: PropTypes.string,
    src: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,

  /*
    Callback function called when iframe loads.
    We're simply listening to the iframe's `window.onload`.
    To ensure communication code in your iframe is totally loaded,
    you can implement a syn-ack TCP-like handshake using `postMessageData` and `handleReceiveMessage`.
*/
  handleReady: PropTypes.func,

  // Callback function called when iFrame sends the parent window a message.
  handleReceiveMessage: PropTypes.func,

  /*
      You can pass it anything you want, we'll serialize to a string
      preferablly use a simple string message or an object.
      If you use an object, you need to follow the same naming convention
      in the iframe so you can parse it accordingly.
   */
  postMessageData: PropTypes.any.isRequired,

  /*
      Enable use of the browser's built-in structured clone algorithm for serialization
      by settings this to `false`.
      Default is `true`, using our built in logic for serializing everything to a string.
  */
  serializeMessage: PropTypes.bool,

  /*
      Always provide a specific targetOrigin, not *, if you know where the other window's document should be located. Failing to provide a specific target discloses the data you send to any interested malicious site.
   */
  targetOrigin: PropTypes.string,
}

export default IframeComm
