import { Api as apiCall, createAsyncAction } from '@lyfta/components-data'

export const LOAD_EXPERIENCES = createAsyncAction(
  'experiences/LOAD_EXPERIENCES',
)

export const SAVE_EXPERIENCE = createAsyncAction('experiences/SAVE_EXPERIENCES')

export const REQUEST_SAVE_EXPERIENCE = 'experiences/REQUEST_SAVE_EXPERIENCE'
export const REQUEST_UPDATE_EXPERIENCE = 'experiences/REQUEST_UPDATE_EXPERIENCE'

export const requestSave = payload => ({
  type: REQUEST_SAVE_EXPERIENCE,
  payload,
})

export const saveExperience = ({
  experienceId,
  lessonId,
  lessonPlanId,
  entityId,
  entityType = 'Task',
  activityId,
  actor,
  experienceData = null,
  additionalContexts = [],
  verb = 'answered',
}) => {
  const method = experienceId ? 'PATCH' : 'POST'
  const endpoint = experienceId
    ? `/experiences/${experienceId}?include=entity,actor,contexts`
    : '/experiences?include=entity,actor,contexts'

  const testAttr = {
    verb,
    data: experienceData,
    contexts_attributes: [
      ...additionalContexts,
      { entity_id: parseInt(lessonId, 10), entity_type: 'Lesson' },
      { entity_id: parseInt(lessonPlanId, 10), entity_type: 'LessonPlan' },
    ],
  }

  if (activityId && parseInt(activityId, 10)) {
    testAttr.contexts_attributes.push({
      entity_id: parseInt(activityId, 10),
      entity_type: 'Activity',
    })
  }

  const expData = {
    data: {
      type: 'experiences',
      relationships: {
        actor: { data: { id: actor, type: 'Actor' } },
      },
      attributes: testAttr,
    },
  }

  if (entityId) {
    expData.data.relationships.entity = {
      data: { id: entityId, type: entityType },
    }
  }

  return apiCall({
    method,
    endpoint,
    query: {
      data: expData.data,
    },
    types: SAVE_EXPERIENCE,
  })
}

export const loadExperiences = (
  lessonId,
  verb = null,
  customCallback = null,
) => {
  const filter = {
    lesson_id: parseInt(lessonId, 10),
    own_experiences: true,
  }

  if (verb) {
    filter.verb = verb
  }

  return apiCall({
    method: 'GET',
    endpoint: '/experiences',
    query: {
      include: 'entity,actor,contexts',
      filter,
    },
    types: LOAD_EXPERIENCES,
    customCallback,
  })
}
