import arrayMutators from 'final-form-arrays'
import { get, merge } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import validate from 'validate.js'

import { ScrollableColumn } from '../../Containers/ScrollableColumn'
import { enforceSubmitClearedFormKeys } from './enforceSubmitClearedValues'
import { FormRenderer } from './FormRenderer'
import { NoWrapContainer } from './styles'
import { reactSelectPostLoadFormat, reactSelectPreSaveFormat } from './Utils'

export const LyftaForm = ({
  buttons,
  constraints,
  createAction,
  createInitialValues,
  modelName,
  excludeSave,
  fields,
  loadAction,
  mutators,
  modalProps,
  noWrap,
  pageTitle,
  recordSelector,
  recordNameSelector,
  submitMethod,
  updateAction,
  withoutHeader,
  wrapperProps,
  promptActivated,
  includeAutoSave,
  inlineErrorMessages,
  forceRefresh,
  selectOptionsToFormat,
  disableModalFormTitle,
  topBar,
  ...props
}) => {
  const { recordId: passedId } = props
  const dispatch = useDispatch()
  const { id, ...restParams } = useParams()
  const recordId = passedId || id || 'new'
  // double () here allows us to get the options passed to the action,
  // which allows us to clear the currentMODEL setting when new record
  // and when unmounting the form
  const { types: actionTypes } = loadAction ? loadAction(0, restParams)() : {}
  useEffect(
    () => () => {
      if (actionTypes) dispatch({ type: actionTypes.CLEAR })
    },
    [],
  )

  const history = useHistory()
  const location = useLocation()

  let selectedRecord =
    recordSelector && useSelector(state => recordSelector(state, restParams))

  if (recordId === 'new') selectedRecord = {}

  const record = selectedRecord || {}

  const defaultNameSelector = () => get(record, 'name')

  const nameSelector = recordNameSelector || defaultNameSelector

  const recordName = useSelector(state => nameSelector(state, restParams))

  useEffect(() => {
    if (recordId !== 'new' && loadAction)
      dispatch(loadAction(recordId, restParams))
    if (id === 'new' && actionTypes) dispatch({ type: actionTypes.CLEAR })

    return () => {
      if (actionTypes) dispatch({ type: actionTypes.CLEAR })
    }
  }, [recordId])

  const validateForm = async values => {
    const result = await validate
      .async(values, constraints)
      .then(() => {})
      .catch(errors => errors)

    return result
  }

  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    if (recordId === 'new') {
      setInitialValues(
        (() => {
          if (createInitialValues) {
            return selectOptionsToFormat
              ? reactSelectPostLoadFormat(
                  createInitialValues(record),
                  selectOptionsToFormat,
                )
              : createInitialValues(record)
          }
          return record
        })(),
      )
    } else if (record?.id) {
      setInitialValues(
        createInitialValues ? createInitialValues(record) : record,
      )
    }

    const editPath = location.pathname.replace('new', `${record.id}/edit`)

    if (location.pathname !== editPath && record.id && recordId !== 'new') {
      history.replace(editPath)
    }
  }, [record?.id, recordId, record[`${forceRefresh}`]])

  useEffect(() => {
    if (recordId !== 'new' && record.id) {
      const formattedInitialValues = selectOptionsToFormat
        ? reactSelectPostLoadFormat(record, selectOptionsToFormat)
        : record

      setInitialValues(
        createInitialValues
          ? createInitialValues(formattedInitialValues)
          : formattedInitialValues,
      )
    }
  }, [record])

  const Container = noWrap ? NoWrapContainer : ScrollableColumn
  const containerParams = noWrap
    ? { noWrap, ...wrapperProps }
    : { noWrap, m: 3, ...wrapperProps }

  const save = useCallback(
    // eslint-disable-next-line consistent-return
    (values, form) => {
      const attributes = enforceSubmitClearedFormKeys(initialValues, values)

      const formattedAttributes = selectOptionsToFormat
        ? reactSelectPreSaveFormat(attributes, selectOptionsToFormat)
        : attributes

      if (submitMethod) return submitMethod(formattedAttributes)

      if ((recordId === 'new' || recordId === undefined) && createAction) {
        setTimeout(form.initialize(attributes))
        return dispatch(createAction({ ...restParams, ...formattedAttributes }))
      }
      if (recordId !== 'new' && recordId && updateAction) {
        setTimeout(form.initialize(attributes))
        return dispatch(
          updateAction({
            ...restParams,
            ...formattedAttributes,
            id: recordId,
            initialValues,
          }),
        )
      }
    },
    [initialValues, updateAction, createAction, recordId],
  )
  const mutatorsMerged = merge(mutators, arrayMutators)

  return (
    <Container {...containerParams}>
      <Form
        initialValues={initialValues}
        keepDirtyOnReinitialize
        mutators={mutatorsMerged}
        render={FormRenderer({
          topBar,
          excludeSave,
          buttons,
          fields,
          pageTitle,
          recordId,
          recordName,
          modelName,
          withoutHeader,
          modalProps,
          promptActivated,
          includeAutoSave,
          inlineErrorMessages,
          disableModalFormTitle,
        })}
        validate={validateForm}
        onSubmit={save}
        {...props}
      />
    </Container>
  )
}

LyftaForm.defaultProps = {
  buttons: null,
  constraints: {},
  createAction: null,
  createInitialValues: null,
  disableModalFormTitle: false,
  modelName: null,
  excludeSave: false,
  includeAutoSave: false,
  loadAction: null,
  mutators: {},
  noWrap: false,
  pageTitle: null,
  recordId: null,
  recordSelector: null,
  recordNameSelector: null,
  submitMethod: null,
  updateAction: null,
  withoutHeader: false,
  modalProps: null,
  wrapperProps: {},
  promptActivated: false,
  inlineErrorMessages: false,
  forceRefresh: '',
  topBar: {},
  selectOptionsToFormat: null,
}

LyftaForm.propTypes = {
  buttons: PropTypes.func,
  constraints: PropTypes.object,
  createAction: PropTypes.func,
  createInitialValues: PropTypes.func,
  disableModalFormTitle: PropTypes.bool,
  excludeSave: PropTypes.bool,
  fields: PropTypes.func.isRequired,
  forceRefresh: PropTypes.string,
  includeAutoSave: PropTypes.bool,
  inlineErrorMessages: PropTypes.bool,
  loadAction: PropTypes.func,
  modalProps: PropTypes.object,
  modelName: PropTypes.string,
  mutators: PropTypes.object,
  noWrap: PropTypes.bool,
  pageTitle: PropTypes.string,
  promptActivated: PropTypes.bool,
  recordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  recordNameSelector: PropTypes.func,
  recordSelector: PropTypes.func,
  selectOptionsToFormat: PropTypes.object,
  submitMethod: PropTypes.func,
  updateAction: PropTypes.func,
  withoutHeader: PropTypes.bool,
  wrapperProps: PropTypes.object,
  topBar: PropTypes.object,
}
