import { createSelector } from 'reselect'

import { denormalize, getAllEntities, getEntity } from '../../../utils'
import { getData } from '../data'

export const getChildren = getAllEntities('homeStudents', 'users', 'children')

export const getHomeStudents = createSelector(
  getData,
  data => denormalize(data, 'homeStudents') || [],
)

export const getTotalHomeStudents = createSelector(
  getHomeStudents,
  data => data.length,
)

export const getCurrentChild = getEntity(
  'homeStudents',
  'users',
  'currentChild',
  'child',
)

export const getChildUserName = createSelector(getCurrentChild, (user = {}) => {
  const { firstName, email } = user

  if (firstName) {
    return `${firstName}  `
  }

  return email
})
