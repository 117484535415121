import { color, css, styled, themeGet } from '@lyfta/components-theme'

import { Flex, ParamLink } from '../../../Components'

const PILL_HEIGHT = 48
const ACTIVE_HEIGHT = 2

export const Container = styled(Flex).attrs(() => ({
  backgroundColor: 'white',
}))`
  flex-direction: row;
  align-items: center;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: ${PILL_HEIGHT + ACTIVE_HEIGHT}px;
  ${props => props.title && 'margin-top: 14px'};
  border-bottom: 1px solid ${themeGet('colors.alto')};
  user-select: none;
  justify-content: center;

  ::-webkit-scrollbar {
    display: none;
  }

  ${color};
`
export const Content = styled(Flex)`
  position: relative;
  flex-wrap: nowrap;
  height: 100%;
`
export const Tab = styled(Flex).attrs(() => ({
  fontSize: 4,
  px: 2,
  as: ParamLink,
}))`
  height: ${PILL_HEIGHT}px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  color: ${themeGet('colors.black')};
  letter-spacing: 1.3px;

  ${props =>
    props.active &&
    css`
      color: ${themeGet('colors.font.link')};
    `};
`
export const ActiveTab = styled(Flex)`
  justify-content: center;
  align-items: center;
  height: ${ACTIVE_HEIGHT}px;
  border-radius: 4px;
  background: ${themeGet('colors.font.link')};
  position: absolute;
  left: ${props => props.index * props.width}px;
  top: ${PILL_HEIGHT - ACTIVE_HEIGHT + 1}px;
  z-index: 0;
`
