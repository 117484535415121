import * as React from 'react'

function SvgSeries(props) {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 1.60004V5.33346C0 5.75782 0.168575 6.16479 0.46864 6.46486C0.768705 6.76492 1.17568 6.9335 1.60004 6.9335H5.33346C5.75782 6.9335 6.16479 6.76492 6.46486 6.46486C6.76492 6.16479 6.9335 5.75782 6.9335 5.33346V1.60004C6.9335 1.17568 6.76492 0.768705 6.46486 0.46864C6.16479 0.168575 5.75782 0 5.33346 0H1.60004C1.17568 0 0.768705 0.168575 0.46864 0.46864C0.168575 0.768705 0 1.17568 0 1.60004ZM2.13338 2.13338H4.80011V4.80011H2.13338V2.13338Z"
        fill="black"
      />
      <path
        d="M0 14.3999C0 14.8242 0.168575 15.2312 0.46864 15.5313C0.768705 15.8313 1.17568 15.9999 1.60004 15.9999H5.33346C5.75782 15.9999 6.16479 15.8313 6.46486 15.5313C6.76492 15.2312 6.9335 14.8242 6.9335 14.3999V10.6664C6.9335 10.2421 6.76492 9.83511 6.46486 9.53505C6.16479 9.23498 5.75782 9.06641 5.33346 9.06641H1.60004C1.17568 9.06641 0.768705 9.23498 0.46864 9.53505C0.168575 9.83511 0 10.2421 0 10.6664V14.3999ZM2.13338 11.1998H4.80011V13.8665H2.13338V11.1998Z"
        fill="black"
      />
      <path
        d="M15.9999 14.3999V10.6664C15.9999 10.2421 15.8313 9.83511 15.5313 9.53505C15.2312 9.23498 14.8242 9.06641 14.3999 9.06641H10.6664C10.2421 9.06641 9.83511 9.23498 9.53505 9.53505C9.23498 9.83511 9.06641 10.2421 9.06641 10.6664V14.3999C9.06641 14.8242 9.23498 15.2312 9.53505 15.5313C9.83511 15.8313 10.2421 15.9999 10.6664 15.9999H14.3999C14.8242 15.9999 15.2312 15.8313 15.5313 15.5313C15.8313 15.2312 15.9999 14.8242 15.9999 14.3999ZM13.8665 13.8665H11.1998V11.1998H13.8665V13.8665Z"
        fill="black"
      />
      <path
        d="M13.5998 6.9335V4.53344H15.9999V2.40006H13.5998V0H11.4665V2.40006H9.06641V4.53344H11.4665V6.9335H13.5998Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgSeries
