import { TranslatedField, getCurrentLanguage } from '@lyfta/components-i18n'
import { capitalizeFirstLetter } from '@lyfta/components-ui/src/Services/Utils'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loadTagGroups } from '../../../Store/Actions/tagGroups'
import { loadTags } from '../../../Store/Actions/tags'
import { getTagGroups } from '../../../Store/Selectors/tagGroups'
import Category from '../Category'

const Tags = ({ tags, setTags }) => {
  const locale = capitalizeFirstLetter(getCurrentLanguage())
  const dispatch = useDispatch()
  const tagGroups = useSelector(getTagGroups)
  useEffect(() => {
    dispatch(loadTagGroups())
    dispatch(
      loadTags({
        page: { size: 250 },
      }),
    )
  }, [])

  // Paths which should display tag groups for lesson plans
  const formatedTagGroups = []

  tagGroups.sort(function (a, b) {
    if (a[`name${locale}`] > b[`name${locale}`]) {
      return 1
    }
    if (a[`name${locale}`] < b[`name${locale}`]) {
      return -1
    }
    return 0
  })
  tagGroups.map(tg => {
    formatedTagGroups.push(
      <Category
        data-intercom-target={tg.id}
        key={`tag-${tg.id}`}
        name={
          <TranslatedField field="name" id={`cat-${tg.nameEn}`} object={tg} />
        }
        setTags={setTags}
        tagGroupId={tg.id}
        tags={tags}
      />,
    )
    return true
  })

  return <>{formatedTagGroups}</>
}

Tags.propTypes = {
  setTags: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
}

export default Tags
