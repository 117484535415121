import {
  createDeleteReducerHandlers,
  createEntityFields,
  createReducer,
  createReducerHandlers,
} from '@lyfta/components-data'

import {
  CREATE_TAG_GROUP,
  DELETE_TAG_GROUP,
  LOAD_TAG_GROUP,
  LOAD_TAG_GROUPS,
  UPDATE_TAG_GROUP,
} from '../Actions/tagGroups'

const initialState = {
  ...createEntityFields('tagGroups'),
}

const handlers = {
  ...createDeleteReducerHandlers('tagGroups', DELETE_TAG_GROUP),
  ...createReducerHandlers('tagGroups', LOAD_TAG_GROUPS, {
    withReplace: true,
    mapToKey: 'tagGroups',
  }),
  ...createReducerHandlers('tagGroups', LOAD_TAG_GROUP, {
    mapToKey: 'currentTagGroup',
    singular: true,
  }),
  ...createReducerHandlers('tagGroups', CREATE_TAG_GROUP, {
    mapToKey: 'currentTagGroup',
    singular: true,
  }),
  ...createReducerHandlers('tagGroups', UPDATE_TAG_GROUP, {
    mapToKey: 'currentTagGroup',
    singular: true,
  }),
}

export default createReducer(initialState, handlers)
