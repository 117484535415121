import spacing from '../Main/space'
import colors from './colors'
import shadows from './shadows'

const { space } = spacing

export default {
  subtypes: {
    navigation: {
      primary: {
        // i.e. selected
        default: {
          backgroundColor: colors.neutral_100,
          border: 'none',
          borderRadius: '0% 50% 50% 0%',
          boxShadow: shadows.none,
          cursor: 'default',
          fill: colors.sa_primary_90,
          padding: '10px 10px 10px 18px',
        },
        hover: {
          backgroundColor: colors.neutral_100,
          border: 'none',
          borderRadius: '0% 50% 50% 0%',
          boxShadow: shadows.none,
          fill: colors.sa_primary_90,
        },
        pressed: {
          backgroundColor: colors.neutral_100,
          border: 'none',
          borderRadius: '0% 50% 50% 0%',
          boxShadow: shadows.none,
          fill: colors.sa_primary_90,
        },
        focusVisible: {
          backgroundColor: colors.neutral_100,
          borderRadius: '0% 50% 50% 0%',
          boxShadow: shadows.none,
          fill: colors.sa_primary_90,
          outlineOffset: space[1],
        },
      },
      secondary: {
        // i.e. unselected
        default: {
          backgroundColor: 'transparent',
          border: 'none',
          borderRadius: '0% 50% 50% 0%',
          boxShadow: shadows.none,
          fill: colors.neutral_100,
          maxHeight: space[9],
          maxWidth: space[9],
          minHeight: space[9],
          minWidth: space[9],
          padding: '10px 10px 10px 18px',
        },
        hover: {
          backgroundColor: colors.sa_primary_60,
          fill: colors.sa_primary_90,
        },
        pressed: {
          backgroundColor: colors.neutral_100,
          boxShadow: shadows.none,
          fill: colors.sa_primary_90,
        },
        focusVisible: {
          backgroundColor: colors.sa_primary_60,
          boxShadow: shadows.none,
          fill: colors.sa_primary_90,
          outlineWidth: '2px',
        },
      },
    },
    taskMenu: {
      primary: {
        // i.e. unlocked
        default: {
          backgroundColor: colors.sa_primary_90,
          border: 'none',
          boxShadow: shadows.none,
          fill: colors.neutral_100,
          padding: '16px',
          maxWidth: 'none',
        },
        hover: {
          backgroundColor: colors.sa_primary_60,
          border: 'none',
          boxShadow: shadows.none,
          fill: colors.sa_primary_90,
        },
        pressed: {
          backgroundColor: colors.sa_primary_90,
          border: 'none',
          boxShadow: shadows.none,
          fill: colors.neutral_100,
        },
        focusVisible: {
          backgroundColor: colors.sa_primary_60,
          boxShadow: shadows.none,
          fill: colors.sa_primary_90,
          outlineColor: colors.sa_primary_90,
          outlineOffset: '-8px',
          outlineWidth: '4px',
        },
      },
      secondary: {
        // i.e. locked
        default: {
          backgroundColor: colors.black_800,
          border: 'none',
          borderRadius: '0%',
          boxShadow: shadows.none,
          cursor: 'default',
          fill: colors.neutral_100,
          maxHeight: space[9],
          maxWidth: 'none',
          minHeight: space[9],
          minWidth: space[9],
          padding: '10px 10px 10px 18px',
        },
        hover: {
          backgroundColor: colors.black_800,
          boxShadow: shadows.none,
          fill: colors.neutral_100,
        },
        pressed: {
          backgroundColor: colors.black_800,
          boxShadow: shadows.none,
          fill: colors.neutral_100,
        },
        focusVisible: {
          backgroundColor: colors.black_800,
          boxShadow: shadows.none,
          fill: colors.neutral_100,
          outlineColor: colors.neutral_100,
          outlineOffset: '-8px',
          outlineWidth: '4px',
        },
      },
    },
  },
}
