import 'react-rangeslider/lib/index.css'

import { styled } from '@lyfta/components-theme'
import { Flex } from '@lyfta/components-ui'
import { themeGet } from '@styled-system/theme-get'
import PT from 'prop-types'
import React, { useCallback } from 'react' // eslint-disable-line no-unused-vars
import Slider from 'react-rangeslider'

export { Container } from './_styles'

export const Wrapper = styled(Flex).attrs({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  width: '300px',
  position: 'relative',
})`
  & > div {
    width: 100%;
  }

  .rangeslider-horizontal .rangeslider__handle {
    outline: none;
  }

  .rangeslider-horizontal {
    height: 20px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  .rangeslider-horizontal .rangeslider__fill {
    background-color: ${themeGet('colors.sa_primary_90')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  .rangeslider-horizontal .rangeslider__handle {
    ${({ disabled }) => {
      if (disabled) {
        return 'cursor: not-allowed'
      }
      return ''
    }}
  }

  .rangeslider-horizontal .rangeslider__labels {
    cursor: default;
  }
`

export const RangeSlider = React.forwardRef((props, ref) => {
  const { value, onValueChange, onSlidingComplete, disabled } = props
  // because this component doesn't seem to work with a range of 0 - 1.0
  const valC = value * 100

  const onChange = useCallback(
    val => {
      if (disabled) {
        return
      }
      onValueChange(val / 100)
      // seems to require this here too (rather than this component's onChangeComplete prop) or the handle sticks in place
      onSlidingComplete(val / 100)
    },
    [disabled, onValueChange, onSlidingComplete],
  )

  return (
    <Slider
      max={100}
      min={0}
      ref={ref}
      tooltip={false}
      value={valC}
      onChange={onChange}
    />
  )
})

RangeSlider.propTypes = {
  disabled: PT.bool,
  value: PT.number,
  onSlidingComplete: PT.func,
  onValueChange: PT.func,
}

RangeSlider.defaultProps = {
  disabled: false,
  value: 0,
  onSlidingComplete: null,
  onValueChange: null,
}
