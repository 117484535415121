import PropTypes from 'prop-types'
import React from 'react'
import { renderRoutes } from 'react-router-config'

import { Container } from './styles'

const Public = ({ route }) => (
  <Container>{renderRoutes(route.routes)}</Container>
)

Public.propTypes = {
  route: PropTypes.object.isRequired,
}

export default Public
