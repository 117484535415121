import { ageRanges, subjects } from '@lyfta/components-data'
import {
  getTranslatedField,
  getTranslation,
  validLanguages,
} from '@lyfta/components-i18n'
import { reactSelectPostLoadFormat } from '@lyfta/components-ui/src/Blocks/Form/Utils'
import {
  capitalizeFirstLetter,
  createAgeRangeLabel,
  createStringFromArray,
} from '@lyfta/components-ui/src/Services/Utils'
import { get, pick } from 'lodash'

export const generateInitialValues = (record, viewOnly) => {
  const formSelectOptions = { ageRanges, subjects }

  return {
    ...pick(record, [
      'id',
      ...validLanguages.map(l => `name${capitalizeFirstLetter(l)}`),
    ]),
    name:
      getTranslatedField(record, 'name') ||
      getTranslation('collections.newCollection'),
    description:
      getTranslatedField(record, 'content.shortDescriptionEn') ||
      getTranslation('collections.newCollectionDescription'),
    posterUrl: getTranslatedField(record, 'content.posterUrl'),
    teacherNotes: getTranslatedField(record, 'teacherNotes'),
    subscribedCount: get(record, 'subscribedCount') || '0',
    viewedCount: get(record, 'content.viewedCount') || '0',
    keywords: (get(record, `keywordsEn`) || []).join(', '),
    ageRangesLength: get(record, 'ageRanges')
      ? get(record, 'ageRanges').length
      : '0',
    favorites: get(record, 'content.favorites') || false,
    contentWarningDescription:
      get(record, 'content.contentWarningDescription') || '',
    ageRangesLabel: createAgeRangeLabel(get(record, 'ageRanges')),
    ageRanges: viewOnly
      ? createStringFromArray(get(record, 'ageRanges'), 'name')
      : reactSelectPostLoadFormat(
          { ageRanges: get(record, 'ageRanges') },
          formSelectOptions,
        ).ageRanges,
    subjects: viewOnly
      ? createStringFromArray(get(record, 'subjects'), 'name')
      : reactSelectPostLoadFormat(
          { subjects: get(record, 'subjects') },
          formSelectOptions,
        ).subjects,
    tags: createStringFromArray(get(record, 'content.tags'), 'nameEn'),
    state: get(record, 'state'),
    authorId: get(record, 'content.author.id'),
    authorName: get(record, 'content.authorName'),
    contentId: get(record, 'content.id'),
    createdAt: get(record, 'content.createdAt'),
    longDescriptionEn: get(record, 'content.longDescriptionEn'),
    availableLocales: get(record, 'availableLocales'),
    totalContent: get(record, 'contents') ? get(record, 'contents').length : 0,

    createdByLyfta: get(record, 'content.createdByLyfta'),
  }
}
