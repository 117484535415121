import * as React from 'react'

function SvgDoubleArrowRight(props) {
  return (
    <svg height="24" viewBox="0 0 24 24" width="24" {...props}>
      <path
        clipRule="evenodd"
        d="M19.1698 12L11.5645 4.41114L12.9787 3L21.9983 12L12.9787 21L11.5645 19.5889L19.1698 12Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9.60735 12L2.00195 4.41114L3.41617 3L12.4358 12L3.41617 21L2.00195 19.5889L9.60735 12Z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgDoubleArrowRight
