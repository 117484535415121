import { getPermissions } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { filter } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath, useHistory, useLocation, useParams } from 'react-router-dom'

export const useRouteSetup = route => {
  const permissions = useSelector(getPermissions)
  const routePermissions = route.permissions
    ? ['super_user', ...route.permissions]
    : undefined
  const userPermissions = permissions?.permissions

  const accessAllowed = routePermissions?.some(r =>
    userPermissions?.includes(r),
  )

  const [isNew, setIsNew] = useState(false)
  const { id, ...props } = useParams()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const history = useHistory()

  useEffect(() => {
    setIsNew(id !== 'new')
  }, [id])

  useEffect(() => {
    if (route?.action && !isNew) {
      dispatch(route.action(id, props))
    }
  }, [route.action, id, isNew])

  const matchingRoutes = filter(route.routes, r =>
    matchPath(pathname, { ...r, exact: true }),
  )

  useEffect(() => {
    if (
      matchingRoutes.length === 0 &&
      pathname.endsWith(id) &&
      pathname !== `${pathname.replace('/edit', '')}/edit`
    )
      history.push(`${pathname.replace('/edit', '')}/edit`)
  }, [matchingRoutes.length, pathname, history])

  return { id, isNew, accessAllowed, permissions }
}
