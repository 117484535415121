import { styled } from '@lyfta/components-theme'

import {
  AchievementContainer as BaseAchievementContainer,
  AchievementImage as BaseAchievementImage,
  Container as BaseContainer,
  Content as BaseContent,
  MainTitle,
  Paragraph,
  Submit,
  TitleText,
  TrophyImage,
} from './_styles'

export { TitleText, Submit, Paragraph, MainTitle, TrophyImage }

export const AchievementContainer = styled(BaseAchievementContainer)``

export const Container = styled(BaseContainer)`
  width: 100%;
  height: 100%;
  cursor: pointer;
`

export const Content = styled(BaseContent).attrs({
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})`
  cursor: pointer;
  overflow-y: auto;
`

export const AchievementImage = styled(BaseAchievementImage)`
  width: 80%;
  position: absolute;
  height: 80%;
  resize-mode: contain;
`
