import { styled, themeGet } from '@lyfta/components-theme'

import { Flex } from '../../../BaseComponents'

export const Container = styled(Flex).attrs(() => ({ p: 2, pl: 3 }))`
  flex-direction: row;
  background-color: ${themeGet('colors.white')};
  border-bottom: 1px solid ${themeGet('colors.alto')};
  height: 36px;
  overflow-y: hidden;
  overflow-x: hidden;
  align-items: center;
  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
  > svg {
    flex-shrink: 0;
    margin-left: 2px;
    margin-right: 2px;
  }
`

export const Label = styled(Flex).attrs(() => ({}))`
  flex-direction: row;
  white-space: nowrap;
  flex-shrink: 0;
  user-select: none;
  color: ${themeGet('colors.doveGray')};
  > a {
    display: inline;
    flex-direction: row;
    color: ${themeGet('colors.font.link')};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

Container.displayName = 'Container'
Label.displayName = 'Label'
