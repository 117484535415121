import {
  TYPE_360_VIDEO,
  TYPE_ARTICLE,
  TYPE_COLLECTION,
  TYPE_LESSON_PLAN_TEMPLATE,
  TYPE_SERIES,
  TYPE_STORY_WORLD,
  TYPE_VIDEO,
} from '@lyfta/components-content/src/Constants/contents'
import {
  Collection,
  Film,
  Icon,
  Lessons,
  RMA,
  Series,
  Storyworld,
  VR360,
} from '@lyfta/components-ui'
import natsort from 'natsort'
import React from 'react'

export const fixedContentUrl = url => {
  // noinspection HttpUrlsUsage
  if (url) {
    return url
      .replace('https://sw-cdn.lyfta.com/https://', 'https://')
      .replace('https://sw-cdn.lyfta.com/http://', 'http://')
      .replace(newStoryworldsDomain, newStoryworldsCloudfrontDomain)
  }

  return url
}

export const newStoryworldsDomain = `lyfta-storyworlds-test.s3.eu-west-1.amazonaws.com`
export const newStoryworldsCloudfrontDomain = `d1fs078958sjvr.cloudfront.net`

export const isNewStoryworld = contentUrl => {
  if (!contentUrl) {
    return false
  }
  return (
    contentUrl.startsWith(`https://${newStoryworldsDomain}`) ||
    contentUrl.startsWith(`https://${newStoryworldsCloudfrontDomain}`)
  )
}

export const getIcon = type => {
  switch (type) {
    case TYPE_VIDEO:
      return Film
    case TYPE_360_VIDEO:
      return VR360
    case TYPE_LESSON_PLAN_TEMPLATE:
      return Lessons
    case TYPE_ARTICLE:
      return RMA
    case TYPE_STORY_WORLD:
      return Storyworld
    case TYPE_COLLECTION:
      return Collection
    default:
      return Series
  }
}

export const getContentIcon = ({ type, width, height }) => {
  switch (type) {
    case TYPE_VIDEO:
      return <Icon mr={2} glyph={Film} height={height} width={width} />
    case TYPE_360_VIDEO:
      return <Icon mr={2} glyph={VR360} height={height} width={width} />
    case TYPE_LESSON_PLAN_TEMPLATE:
      return <Icon mr={2} glyph={Lessons} height={height} width={width} />
    case TYPE_ARTICLE:
      return <Icon mr={2} glyph={RMA} height={height} width={width} />
    case TYPE_STORY_WORLD:
      return <Icon mr={2} glyph={Storyworld} height={height} width={width} />
    case TYPE_SERIES:
      return <Icon mr={2} glyph={Series} height={height} width={width} />
    case TYPE_COLLECTION:
      return <Icon mr={2} glyph={Collection} height={height} width={width} />
    default:
      return ''
  }
}

export const naturalSort = (arrayToSort, field) => {
  const sorter = natsort()
  return arrayToSort.sort((a, b) => sorter(a[field], b[field]))
}
