import * as React from 'react'

function SvgTick(props) {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M18 7.55856L10.8746 18L6 14.5985L7.42103 12.3674L10.2318 14.3287L15.9154 6L18 7.55856Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgTick
