/* eslint-disable no-unused-vars */
// noinspection JSUnusedLocalSymbols

import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import mergeRefs from 'react-merge-refs'

import { FakeUnityContent } from '../UnityApp/FakeUnityContent'
import { debugLog } from '../utils/debugLog'
import { useMessageHandler, useMessageListener } from './MessageHandler'

const MESSAGE_TYPES = {
  WEB_APP_SEND: 3,
  EMIT: 4,
  EMIT_RESPONSE: 5,
}

export const StudentWebApp = React.forwardRef((props, ref) => {
  const {
    /**
     * @type {FakeUnityContent}
     */
    unityContent,
  } = props

  /**
   * @type {React.MutableRefObject<HTMLIFrameElement>}
   */
  const frame = useRef()

  const [frameWindow, setFrameWindow] = useState(null)

  const messageHandler = useMessageHandler(frameWindow)

  useMessageListener(
    messageHandler,
    MESSAGE_TYPES.EMIT,
    useCallback(payload => {
      unityContent
        .emit(payload.eventName, ...payload.params)
        .then(responseData => {
          messageHandler.sendMessage({
            type: MESSAGE_TYPES.EMIT_RESPONSE,
            emitId: payload.emitId,
            responseData,
          })
        })
    }, []),
  )

  useEffect(() => {
    return unityContent.on('WEB_APP_SEND', params => {
      debugLog('WEB_APP_SEND', params.obj, params.method, params.parameter)

      messageHandler.sendMessage({
        type: MESSAGE_TYPES.WEB_APP_SEND,
        params,
      })
    })
  }, [])

  return (
    <iframe
      allowFullScreen
      ref={mergeRefs([frame, ref])}
      src={unityContent.url}
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
      }}
      id="iframe-student-web-app"
      title="Student Web App"
      onError={() => {
        // eslint-disable-next-line no-console
        console.error('failed loading...')
      }}
      onLoad={() => {
        setFrameWindow(frame.current.contentWindow)
      }}
      onLoadStart={() => {
        debugLog('onLoadStart', frame.current.contentWindow)
      }}
    />
  )
})

StudentWebApp.propTypes = {
  unityContent: PropTypes.instanceOf(FakeUnityContent).isRequired,
}

StudentWebApp.displayName = 'FakeUnity'
