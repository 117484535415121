import {
  AUTHENTICATE,
  PASSPHRASE,
  PASSPHRASE_TOKEN,
} from '@lyfta/components-data/src/Store/Actions/auth'
import {
  LOG_OUT,
  SET_JWT,
  loadViewer,
  setJWT,
} from '@lyfta/components-data/src/Store/Actions/viewer'
import { QUEST_SELECT } from '@lyfta/components-student/src/Store/Actions/ui'
import { USERS_ROLE } from '@lyfta/components-ui'
import { push } from 'connected-react-router'
import paths from 'Constants/paths'
import jwtDecode from 'jwt-decode'
import { all, fork, put, take, takeEvery } from 'redux-saga/effects'

function* redirectAfterAuthentication({
  payload: {
    data: { jwt },
  },
}) {
  const { type } = jwtDecode(jwt)

  yield put(
    setJWT(
      jwt,
      type === USERS_ROLE.teacher || type === USERS_ROLE.homeStudent
        ? paths.lessonSelection
        : paths.root,
    ),
  )
}

function* redirectAfterPassphrase() {
  yield put(push(paths.chooseStudent))
}

function* redirectAfterPassphraseToken() {
  // yield put(push(paths.root))
}

function* redirectAfterToken({ payload: { token, route } }) {
  yield put(loadViewer())

  const { type } = jwtDecode(token)

  if (type === USERS_ROLE.teacher || type === USERS_ROLE.homeStudent) {
    yield put(push(route || paths.lessonSelection))
  } else {
    yield put(push(route || paths.root))
  }
}

function* redirectLogOut() {
  while (true) {
    const logoutArgs = yield take(LOG_OUT)
    yield put(push(logoutArgs.redirectPath || paths.root))
  }
}

function* redirectToQuest() {
  yield put(push(paths.root))
}

export default function* root() {
  yield all([
    takeEvery(QUEST_SELECT, redirectToQuest),
    takeEvery(AUTHENTICATE.SUCCESS, redirectAfterAuthentication),
    takeEvery(PASSPHRASE.SUCCESS, redirectAfterPassphrase),
    takeEvery(PASSPHRASE_TOKEN.SUCCESS, redirectAfterPassphraseToken),
    takeEvery(SET_JWT, redirectAfterToken),
    fork(redirectLogOut),
  ])
}
