import { getTranslation } from '@lyfta/components-i18n'
import React, { useEffect, useRef, useState } from 'react'

import { closeAllToast, showNotification } from '../Utils'

export const Connection = () => {
  const [online, setOnline] = useState(navigator.onLine)
  const prevOnline = useRef(online)

  useEffect(() => {
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  useEffect(() => {
    if (online && !prevOnline.current) {
      closeAllToast()
    }
    prevOnline.current = online
  }, [online])

  const handleOnline = () => {
    setOnline(true)
  }

  const handleOffline = () => {
    setOnline(false)
  }

  if (!online && prevOnline.current)
    showNotification(getTranslation('core.noConnection'), 'error', {
      autoClose: false,
    })

  return <></>
}
