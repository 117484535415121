export const reactSelectPreSaveFormat = (values, selectOptionsToFormat) => {
  const valuesToReturn = { ...values }

  if (selectOptionsToFormat) {
    Object.keys(selectOptionsToFormat).forEach(fieldName => {
      valuesToReturn[fieldName] = values[fieldName].value
    })
  }

  return valuesToReturn
}

export const reactSelectPostLoadFormat = (values, selectOptionsToFormat) => {
  const valuesToReturn = { ...values }

  if (selectOptionsToFormat) {
    Object.keys(selectOptionsToFormat).forEach(fieldName => {
      if (Array.isArray(values[fieldName])) {
        valuesToReturn[fieldName] = []

        values[fieldName].forEach(option => {
          const index = selectOptionsToFormat[fieldName].findIndex(
            ({ value }) => value === String(option.id),
          )
          valuesToReturn[fieldName].push(
            selectOptionsToFormat[fieldName][index],
          )
        })
      } else if (typeof values[fieldName === 'string']) {
        const index = selectOptionsToFormat[fieldName].findIndex(
          ({ value }) => value === values[fieldName],
        )
        valuesToReturn[fieldName] = selectOptionsToFormat[fieldName][index]
      }
    })
  }

  return valuesToReturn
}
