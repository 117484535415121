import { jsx } from '@emotion/react'
/** @jsx jsx */
import { css, styled, themeGet } from '@lyfta/components-theme'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'
import PropTypes from 'prop-types'
import Select, { components, mergeStyles } from 'react-select'

import { ChevronDown, ChevronUp, Tick } from '../../Assets/Icons'
import { Box } from '../Box'

export const Description = styled(Box)`
  color: ${props =>
    props.focused
      ? themeGet('colors.primary_130')
      : themeGet('colors.black_700')};
  font-size: 16px; // Body 1
  font-weight: 400; // Body 1
  letter-spacing: 0.5px; // Body 1
  line-height: 24px; // Body 1
  margin-top: 4px;
`

export const Wrapper = styled(Box)``

const statusIndicator = (props, color) => ({
  ':before': {
    backgroundColor: themeGet(color)(props),
    borderRadius: '10px',
    content: '" "',
    display: 'block',
    height: '10px',
    margin: 'auto 8px auto 0',
    minHeight: '10px',
    minWidth: '10px',
    width: '10px',
  },
})

const CustomControlWrapper = styled(Box).attrs(props => ({
  margin: props.margin ? props.margin : 'auto 8px auto 16px',
}))`
  display: flex;
  flex-direction: column;
`

const CustomControlLabel = styled(Box).attrs(props => ({
  as: 'label',
  margin: props.margin ? props.margin : 'initial',
}))`
  color: ${themeGet('colors.black_900')};
  font-size: 16px; // Body 1
  font-weight: 400; // Body 1
  letter-spacing: 0.5px; // Body 1
  line-height: 24px; // Body 1
  transition: all ${themeGet('transitionTime')} ease;
  user-select: none;
  z-index: 1;

  ${props =>
    props.hasValue &&
    css`
      color: ${themeGet('colors.primary_130')};
      font-size: 13px; // Body 5
      line-height: 20px; // Body 5
    `};
`

const CustomControl = props => {
  const { children, hasValue, selectProps } = props

  return (
    <components.Control {...props}>
      <CustomControlWrapper
        className="react-select-control-wrapper"
        margin={selectProps.wrapperMargin}
      >
        {selectProps.label && (
          <CustomControlLabel
            className="react-select-control-label"
            hasValue={hasValue}
            margin={selectProps.labelMargin}
          >
            {selectProps.label}
          </CustomControlLabel>
        )}
        {children[0]}
      </CustomControlWrapper>
      {children[1]}
    </components.Control>
  )
}

CustomControl.propTypes = {
  children: PropTypes.node.isRequired,
  hasValue: PropTypes.bool.isRequired,
  selectProps: PropTypes.object.isRequired,
}

const CustomDropdownIndicator = props => {
  const { selectProps } = props

  return (
    <components.DropdownIndicator {...props}>
      {selectProps.menuIsOpen ? <ChevronUp /> : <ChevronDown />}
    </components.DropdownIndicator>
  )
}

CustomDropdownIndicator.propTypes = {
  selectProps: PropTypes.object.isRequired,
}

const CustomOptionLabelContainer = styled(Box)`
  margin-right: 8px;
`

const CustomOptionIconContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: end;
`

const CustomOptionIconPlaceholder = styled(Box)`
  height: 24px;
  width: 24px;
`

const CustomOption = props => {
  const { children, isSelected, selectProps } = props

  return (
    <components.Option {...props}>
      <CustomOptionLabelContainer className="react-select-option-label-container">
        {children}
      </CustomOptionLabelContainer>
      <CustomOptionIconContainer className="react-select-option-icon-container">
        {isSelected ? (
          <Tick
            css={{
              '& > path': {
                fill: themeGet('colors.black_600')(selectProps.allProps),
              },
            }}
          />
        ) : (
          <CustomOptionIconPlaceholder className="react-select-option-icon-placeholder" />
        )}
      </CustomOptionIconContainer>
    </components.Option>
  )
}

CustomOption.propTypes = {
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectProps: PropTypes.object.isRequired,
}

const controlStyles = (baseStyles, state, props) => ({
  ...baseStyles,
  backgroundColor: state.isDisabled
    ? themeGet('colors.black_200')(props)
    : themeGet('colors.neutral_100')(props),
  borderColor: state.isDisabled
    ? 'transparent'
    : themeGet('colors.black_600')(props),
  borderBottom: state.isFocused
    ? `2px solid ${themeGet('colors.primary_100')(props)}`
    : null,
  borderRadius: state.menuIsOpen ? '5px 5px 0 0' : '5px',
  boxShadow: state.menuIsOpen ? shadows.lifted : 'none',
  boxSizing: 'content-box',
  flexWrap: 'nowrap',
  marginBottom: state.isFocused ? '0' : '1px',
  minHeight: '56px',
  '&:hover': {
    boxShadow: state.menuIsOpen ? shadows.lifted : shadows.skim,
  },
})

const menuStyles = (baseStyles, state, props) => ({
  ...baseStyles,
  // top: 'unset',
  position: 'absolute',
  top: '100%',
  left: '0',
  backgroundColor: themeGet('colors.neutral_100')(props),
  border: `1px solid ${themeGet('colors.black_600')(props)}`,
  borderRadius: '0 0 5px 5px',
  borderTop: 'none',
  boxShadow: !state.menuIsOpen ? shadows.lifted : 'none',
  marginTop: '0',
  overflow: 'hidden',
  maxHeight: '150px',
  zIndex: 200,
})

const optionStyles = (baseStyles, state, props) => ({
  ...baseStyles,
  ...(state.data.statusIndicator &&
    statusIndicator(props, state.data.statusIndicator)),
  backgroundColor: (() => {
    if (state.isSelected) {
      return themeGet('colors.black_200')(props)
    }
    if (state.isFocused) {
      return themeGet('colors.primary_60_50')(props)
    }
    return themeGet('colors.neutral_100')(props)
  })(),
  color: themeGet('colors.black_700')(props),
  display: 'flex',
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: state.isSelected
      ? themeGet('colors.black_200')(props)
      : themeGet('colors.primary_60_50')(props),
  },
})

const stylesFunction = props => {
  const { extendStyles } = props

  const stylesObject = {
    container: baseStyles => ({
      ...baseStyles,
      height: '59px',
    }),
    control: (baseStyles, state) => controlStyles(baseStyles, state, props),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      display: state.isDisabled ? 'none' : 'flex',
      padding: '0 16px 0 0',
    }),
    indicatorsContainer: baseStyles => ({
      ...baseStyles,
      '& svg > path': {
        fill: themeGet('colors.black_700')(props),
      },
    }),
    indicatorSeparator: baseStyles => ({
      ...baseStyles,
      display: 'none',
    }),
    menu: (baseStyles, state) => menuStyles(baseStyles, state, props),
    menuList: baseStyles => ({
      ...baseStyles,
      backgroundColor: themeGet('colors.neutral_100')(props),
      padding: '0',
      maxHeight: '100px',
      zIndex: 200,
    }),
    menuPortal: base => ({
      ...base,
      zIndex: 200, // Set the z-index to 200
    }),
    option: (baseStyles, state) => optionStyles(baseStyles, state, props),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      ...(state.data.statusIndicator &&
        statusIndicator(props, state.data.statusIndicator)),
      color: themeGet('colors.black_900')(props),
      display: 'flex',
      marginLeft: '0',
    }),
    valueContainer: (baseStyles, state) => ({
      ...baseStyles,
      maxHeight: state.hasValue ? 'fit-content' : '0',
      padding: '0',
    }),
  }

  return mergeStyles(stylesObject, extendStyles)
}

export const StyledReactSelect = styled(Select).attrs(props => ({
  allProps: props,
  components: {
    Control: CustomControl,
    DropdownIndicator: CustomDropdownIndicator,
    Option: CustomOption,
    ...props.extendComponents,
  },
  styles: stylesFunction(props),
}))``
