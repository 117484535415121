import {
  createDeleteReducerHandlers,
  createReducerHandlers,
} from '../../../utils'
import {
  CREATE_STUDENT_USER,
  DELETE_STUDENT_USER,
  LOAD_STUDENT_USER,
  LOAD_STUDENT_USERS,
  UPDATE_STUDENT_USER,
} from '../../Actions/users'

export default {
  ...createDeleteReducerHandlers('students', DELETE_STUDENT_USER, {
    mapToKey: 'studentUsers',
  }),
  ...createReducerHandlers('students', LOAD_STUDENT_USERS, {
    withReplace: true,
    mapToKey: 'studentUsers',
  }),
  ...createReducerHandlers('students', LOAD_STUDENT_USER, {
    singular: true,
    mapToKey: 'currentStudentUser',
  }),
  ...createReducerHandlers('students', CREATE_STUDENT_USER, {
    singular: true,
    mapToKey: 'currentStudentUser',
  }),
  ...createReducerHandlers('students', UPDATE_STUDENT_USER, {
    singular: true,
    mapToKey: 'currentStudentUser',
  }),
}
