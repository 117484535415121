import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'

import Modal from '../../Modal'

const UnlockModal = ({ isOpen, content, onCallback }) => {
  const { title, description, subtitle } = content || ''

  const modalTitle = title || 'core.modals.unlockThisContent'
  const modalDescription = description || 'user.unlockContent'

  return (
    <Modal
      hideClose
      isOpen={isOpen}
      buttons={{
        okText: <I18n text="core.modals.getInTouch" />,
      }}
      modalStyle={{
        maxWidth: '500px',
      }}
      onCallback={onCallback}
      content={{
        title: modalTitle,
        description: modalDescription,
        subtitle,
      }}
    />
  )
}

UnlockModal.defaultProps = {
  content: {},
}

UnlockModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.object,
  onCallback: PropTypes.func.isRequired,
}

export default UnlockModal
