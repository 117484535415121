import { styled, themeGet as theme } from '@lyfta/components-theme'
import { Icon } from '@lyfta/components-ui'

export {
  LeftColumnContainer,
  Container,
  Percent,
  PercentText,
  ProgressBar,
  ProgressFill,
  QuestTitle,
  TitleText,
  RightColumnContainer,
  UpperContainer,
} from './_styles'

export const MascotIcon = styled(Icon)`
  > svg > path {
    fill: ${theme('colors.neutral_100')};
  }
`

MascotIcon.displayName = 'MascotIcon'
