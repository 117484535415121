import * as React from 'react'

function SvgOpen(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.95071 18C3.41115 18 2.95115 17.8098 2.57069 17.4293C2.19023 17.0489 2 16.5888 2 16.0493V3.97147C2 3.43191 2.19023 2.96844 2.57069 2.58106C2.95115 2.19369 3.41115 2 3.95071 2H8.4332V2.98573V3.97147L3.95071 3.97147V16.0493H16.0285L16.0285 11.6H18V16.0493C18 16.5888 17.8063 17.0489 17.4189 17.4293C17.0316 17.8098 16.5681 18 16.0285 18H3.95071ZM8.4332 12.9364L7.08431 11.5668L14.6796 3.97147L10.5333 3.97147V2H18V9.46667H16.0285L16.0285 5.34112L8.4332 12.9364Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgOpen
