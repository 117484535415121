import { styled } from '@lyfta/components-theme'
import { themeGet } from '@styled-system/theme-get'
import PropTypes from 'prop-types'
import * as React from 'react'

function SvgClose({ fillColor: iconFillColor, ...props }) {
  const fillColor = iconFillColor || themeGet('colors.primary')(props)

  return (
    <svg height="1em" viewBox="0 0 28 28" width="1em" {...props}>
      <path
        d="M26.494 0L28 1.532 15.518 14 28 26.468 26.494 28 14 15.517 1.506 28 0 26.468 12.481 14 0 1.532 1.506 0 14 12.482 26.494 0z"
        fill={fillColor}
        fillRule="nonzero"
        id="check"
      />
    </svg>
  )
}

SvgClose.defaultProps = {
  fillColor: null,
}
SvgClose.propTypes = {
  fillColor: PropTypes.string,
}

const StyledComponent = styled(SvgClose)`
  &:hover {
    #check {
      fill: ${p =>
        p.hoverFillColor ? p.hoverFillColor : themeGet('colors.primary')(p)};
    }
  }
`

export default StyledComponent
