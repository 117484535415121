import { createSelector } from 'reselect'

export const getState = state => {
  return state.users
}

export const getIsLoaded = createSelector(getState, state => state.isLoaded)
export const getIsLoading = createSelector(getState, state => state.isLoading)
export const getTotal = createSelector(getState, state => state.total)
export const getPaged = createSelector(getState, state => state.paged)
export const getFilter = createSelector(getState, state => state.filter)
export const getSort = createSelector(getState, state => state.sort)
