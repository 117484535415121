import { styled, withTheme } from '@lyfta/components-theme'
import { themeGet } from '@styled-system/theme-get'
import * as React from 'react'

function SvgStar(props) {
  const fillColor = themeGet('colors.primary')(props)
  return (
    <svg height="1em" viewBox="0 0 32 31" width="1em" {...props}>
      <path
        d="M17.037.652l4.318 8.88 9.656 1.427c.435.063.797.372.932.797.136.424.023.89-.292 1.201l-6.989 6.907 1.65 9.762c.074.44-.104.884-.46 1.147a1.14 1.14 0 01-1.216.09l-8.635-4.603-8.637 4.606c-.39.207-.86.172-1.216-.09a1.182 1.182 0 01-.46-1.147l1.65-9.757-6.989-6.906a1.184 1.184 0 01-.292-1.202c.135-.424.497-.733.932-.797l9.656-1.433 4.318-8.88C15.158.254 15.56 0 16 0c.44 0 .841.253 1.037.652z"
        fill={fillColor}
        id="fill"
      />
      <path
        d="M17.037.652l4.318 8.88 9.656 1.427c.435.063.797.372.932.797.136.424.023.89-.292 1.201l-6.989 6.907 1.65 9.762c.074.44-.104.884-.46 1.147a1.14 1.14 0 01-1.216.09l-8.635-4.603-8.637 4.606c-.39.207-.86.172-1.216-.09a1.182 1.182 0 01-.46-1.147l1.65-9.757-6.989-6.906a1.184 1.184 0 01-.292-1.202c.135-.424.497-.733.932-.797l9.656-1.433 4.318-8.88C15.158.254 15.56 0 16 0c.44 0 .841.253 1.037.652zm-.899.44A.156.156 0 0016 1a.155.155 0 00-.137.091l-4.55 9.354-10.179 1.512a.16.16 0 00-.125.112.184.184 0 00.043.186l7.358 7.272-1.736 10.267a.183.183 0 00.068.177.142.142 0 00.151.013l9.108-4.857 9.106 4.853a.141.141 0 00.152-.012.181.181 0 00.067-.175L23.59 19.518l7.358-7.272a.184.184 0 00.043-.185.16.16 0 00-.126-.113l-10.179-1.504-4.548-9.353z"
        fill="#4A4A4A"
        id="outline"
      />
    </svg>
  )
}

const StyledComponent = styled(SvgStar)`
  #outline {
    visibility: ${({ selected }) => (!selected ? 'visible' : 'hidden')};
  }
  #fill {
    visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
  }
  &:hover {
    #outline {
      visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
    }
    #fill {
      visibility: ${({ selected }) => (!selected ? 'visible' : 'visible')};
    }
  }
`

export default withTheme(StyledComponent)
