import { styled, themeGet } from '@lyfta/components-theme'

import { Box } from '../Box'

export const LinkContactUs = styled(Box)`
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  color: ${props => (props.color ? props.color : themeGet('colors.primary'))};
  cursor: pointer;
  ${props =>
    props.textDecoration ? `text-decoration: ${props.textDecoration} ` : ''};
`

LinkContactUs.defaultProps = {}

LinkContactUs.propTypes = {}
