import { styled } from '@lyfta/components-theme'
import { Body1, Flex, Text, TouchableFlex } from '@lyfta/components-ui'
import { themeGet } from '@styled-system/theme-get'

export const getTextColor = p => {
  if (p.selected) {
    return themeGet('colors.white')(p)
  }

  if (p.complete) {
    return 'rgb(131, 131, 131)'
  }

  return themeGet('colors.font.primary')(p)
}

export const ActivityRow = styled(TouchableFlex).attrs(() => ({
  flexDirection: 'row',
  borderColor: 'black_600',
  borderBottomWidth: '1px',
  flex: 0,
}))`
  min-height: 110px;
`

export const ActivityContent = styled(Flex).attrs(() => ({
  px: 3,
  flexDirection: 'row',
}))`
  gap: 16px;
`

export const ActivityDetails = styled(Flex).attrs(() => ({
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'center',
}))`
  height: 100%;
`

export const H3Text = styled(Text).attrs({
  level: 4,
  fontSize: 5,
  color: 'black_800',
  m: 0,
  my: 1,
  pr: 2,
})`
  font-weight: 600;
`

export const ActivityTime = styled(Body1).attrs({
  color: 'black_800',
})``

ActivityRow.displayName = 'ActivityRow'
ActivityContent.displayName = 'ActivityContent'
ActivityDetails.displayName = 'ActivityDetails'
H3Text.displayName = 'H3Text'
ActivityTime.displayName = 'ActivityTime'
