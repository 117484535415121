import { indexOf, map } from 'lodash'
import PT from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { questTrackerData } from '../../../../../Store/Actions/questTracker'
import { InformationText } from '../InformationText'
import { Submit } from '../Submit'
import Checkbox from './Checkbox'
import { CheckContainer } from './styles'

const useSelections = choiceStyle => {
  const [selections, setSelections] = useState([])

  const checkItem = item => () => {
    if (indexOf(selections, item) >= 0) {
      const newSelections = selections.filter(i => i !== item)
      setSelections(newSelections)
    } else if (choiceStyle === 'single') {
      setSelections([item])
    } else {
      setSelections([...selections, item])
    }
  }

  return [selections, setSelections, checkItem]
}

const MultipleChoice = ({
  disabled,
  task: {
    description,
    callToAction,
    id,
    i18NProperties: { choices },
    properties: { choiceStyle },
    answerData,
  },
}) => {
  const [selections, setSelections, checkItem] = useSelections(choiceStyle)
  const dispatch = useDispatch()

  const saveResponse = useCallback(() => {
    dispatch(questTrackerData.saveResponse(id, { answer: selections }))
  }, [selections])

  useEffect(() => {
    setSelections(answerData?.data?.answer || [])
  }, [answerData])

  return (
    <>
      <InformationText textSource={description} />
      <CheckContainer>
        {map(choices, (c, i) => {
          const key = `key-${c}`
          return (
            <Checkbox
              choice={c}
              choiceStyle={choiceStyle}
              disabled={disabled}
              even={i % 2}
              key={key}
              selections={selections}
              value={i}
              onClick={checkItem(i)}
            />
          )
        })}
      </CheckContainer>
      <Submit buttonText={callToAction} onClick={saveResponse} />
    </>
  )
}

MultipleChoice.propTypes = {
  disabled: PT.bool,
  task: PT.object.isRequired,
}

MultipleChoice.defaultProps = {
  disabled: false,
}

export default MultipleChoice
