import { createReducer } from '../../helpers'
import {
  createDeleteReducerHandlers,
  createEntityFields,
  createReducerHandlers,
} from '../../utils'
import {
  CREATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  LOAD_ORGANIZATION,
  LOAD_ORGANIZATIONS,
  UPDATE_ORGANIZATION,
} from '../Actions/organizations'

const initialState = {
  ...createEntityFields('organizations'),
}

const handlers = {
  ...createReducerHandlers('organizations', LOAD_ORGANIZATIONS, {
    withReplace: true,
    mapToKey: 'organizations',
  }),
  ...createReducerHandlers('organizations', LOAD_ORGANIZATION, {
    mapToKey: 'currentOrganization',
    singular: true,
  }),
  ...createReducerHandlers('organizations', CREATE_ORGANIZATION, {
    mapToKey: 'currentOrganization',
    singular: true,
  }),
  ...createReducerHandlers('organizations', UPDATE_ORGANIZATION, {
    mapToKey: 'currentOrganization',
    singular: true,
  }),
  ...createDeleteReducerHandlers('organizations', DELETE_ORGANIZATION),
}

export default createReducer(initialState, handlers)
