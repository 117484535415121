import range from 'lodash/range'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { Container, Content, Label, Step } from './styles'

class Rate extends PureComponent {
  constructor(props) {
    super(props)
    const { current } = props
    this.state = {
      current,
      currentTemp: null,
    }
  }

  handleRate = step => () => {
    const { current } = this.state
    this.setState({
      current: current === step ? 0 : step,
    })
  }

  handleMouseEnter = step => () => {
    this.setState({
      currentTemp: step,
    })
  }

  handleMouseLeave = () => {
    this.setState({
      currentTemp: null,
    })
  }

  render() {
    const { current, currentTemp } = this.state
    const { total, labelLeft, labelRight } = this.props

    const currentRate = currentTemp || current

    return (
      <Container>
        {labelLeft && <Label mr={2}>{labelLeft}</Label>}
        {range(total).map((label, index) => {
          const step = index + 1
          const active = step <= currentRate ? 'true' : 'false'
          return (
            <Content
              key={step}
              onClick={this.handleRate(step)}
              onMouseEnter={this.handleMouseEnter(step)}
              onMouseLeave={this.handleMouseLeave}
            >
              <Step active={active} />
            </Content>
          )
        })}
        {labelRight && <Label ml={2}>{labelRight}</Label>}
      </Container>
    )
  }
}

Rate.defaultProps = {
  current: 0,
  labelRight: '',
  labelLeft: '',
  total: 5,
}

Rate.propTypes = {
  current: PropTypes.number,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  total: PropTypes.number,
}

export default connect(null, {})(Rate)
