import { useCombobox } from 'downshift'

export const stateReducer = (onBlur, keepValueOnNoItem) => (
  state,
  actionAndChanges,
) => {
  const { type, changes } = actionAndChanges

  switch (type) {
    case useCombobox.stateChangeTypes.InputChange:
      return {
        // return normal changes.
        ...changes,
        ...(changes.selectedItem &&
          changes.inputValue.length === 0 && {
            selectedItem: null,
            inputValue: '',
          }),
      }

    case useCombobox.stateChangeTypes.InputBlur:
      onBlur()

      if (!keepValueOnNoItem) {
        return {
          ...changes,
          inputValue: changes.selectedItem?.value || '',
        }
      }

      return changes
    default:
      return changes // otherwise business as usual.
  }
}
