import { getViewerType } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  addToFavorites,
  tryRemoveFromFavorites,
} from '../../Store/Actions/favorites'
import { getContentFavoriteState } from '../../Store/Selectors/contents'
import Favorite from './Favorite'

const selector = createStructuredSelector({
  favorite: getContentFavoriteState,
  viewerType: getViewerType,
})

const actions = {
  onAddToFavorites: addToFavorites,
  onTryRemoveFromFavorites: tryRemoveFromFavorites,
}

export default connect(selector, actions)(Favorite)
