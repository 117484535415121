import { getTranslation } from '@lyfta/components-i18n'
import {
  VolumeOff as VolumeOffIcon,
  VolumeOn as VolumeOnIcon,
} from '@lyfta/components-student'
import { muted } from '@lyfta/components-student/src/Store/Actions/ui'
import {
  getIsMuted,
  getIsTemporarilyUnmuted,
} from '@lyfta/components-student/src/Store/Selectors/UI/mute'
import { ButtonNew } from '@lyfta/components-ui'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PrimaryButtonIcon } from '../styles'

export const MuteButton = () => {
  const dispatch = useDispatch()
  const muteState = useSelector(getIsMuted)
  const temporaryUnmuteState = useSelector(getIsTemporarilyUnmuted)
  let showMutedGraphics

  // If it's not muted, then ignore temporary unmutes
  if (!muteState) {
    showMutedGraphics = false
  } else {
    // If it's muted, but is temporarily unmuted, pretend it's not muted
    showMutedGraphics = !temporaryUnmuteState
  }

  const toggleMuted = useCallback(() => {
    if (temporaryUnmuteState) {
      dispatch(muted.mutedDuringTemporaryUnmute())
    } else {
      dispatch(muted.toggle())
    }
  }, [temporaryUnmuteState])

  return (
    <ButtonNew
      id="btn-mute-student-app"
      leadingIcon={
        showMutedGraphics ? (
          <PrimaryButtonIcon glyph={VolumeOnIcon} />
        ) : (
          <PrimaryButtonIcon glyph={VolumeOffIcon} />
        )
      }
      title={getTranslation('header.mute')}
      type="icon"
      onClick={toggleMuted}
    >
      {getTranslation('header.mute')}
    </ButtonNew>
  )
}
