import studentSagas from '@lyfta/components-student/src/Store/Sagas'
import { map } from 'lodash'
import { all, fork } from 'redux-saga/effects'

import restoreSession from './restoreSession'
import students from './students'
import viewer from './viewer'

const rootSaga = function* root() {
  yield all([
    fork(restoreSession),
    fork(viewer),
    fork(students),
    ...map(studentSagas, s => fork(s)),
  ])
}

export default rootSaga
