import {
  createEntityFields,
  createLoadHandler,
  createReducer,
  createReducerHandlers,
} from '@lyfta/components-data'

import {
  LOAD_CONTENT,
  LOAD_CONTENTS,
  UPDATE_CONTENT,
} from '../Actions/contents'

/* eslint-disable */

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  contentId: null,
  ...createEntityFields('contents'),
  filter: {},
  sort: {},
  currentContent: {},
}

const humanizeError = (type, error) => {
  switch (type) {
    case LOAD_CONTENTS.FAILURE: {
      if (error.status === 401) {
        return 'User is not authorized' // TODO: i18n-ify this
      }
      return null
    }
    default:
      return null
  }
}

const handleFailure = (state, { type, error }) =>
  state.merge({
    isLoading: false,
    isLoaded: false,
    error: humanizeError(type, error),
  })

const handleRequest = state =>
  state.merge({
    error: null,
    isLoading: true,
    isLoaded: false,
    currentContent: {},
  })

const handlers = {
  ...createReducerHandlers('contents', LOAD_CONTENTS, {
    withReplace: true,
    mapToKey: 'contents',
  }),

  ...createReducerHandlers('contents', LOAD_CONTENT, {
    singular: true,
    mapToKey: 'currentContent',
  }),

  [UPDATE_CONTENT.REQUEST]: handleRequest,
  [UPDATE_CONTENT.SUCCESS]: (state, action) =>
    createLoadHandler('contents', {
      mapToKey: 'currentContent',
      singular: true,
    })(state, action),
  [UPDATE_CONTENT.FAILURE]: handleFailure,
}

export default createReducer(initialState, handlers)
