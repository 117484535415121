import { styled, themeGet } from '@lyfta/components-theme'
import { Flex } from '@lyfta/components-ui'

export const Container = styled(Flex).attrs(() => ({
  px: 3,
}))`
  width: 100%;
  height: 88px;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  transition: all ${themeGet('transitionTime')} ease;
  border-bottom: 1px solid ${themeGet('colors.bg')};
  justify-content: space-between;
  align-items: center;

  :hover {
    background: ${themeGet('colors.bg')};
  }
`

export const Tag = styled(Flex).attrs(() => ({
  fontSize: 3,
}))`
  flex-shrink: 0;
  font-weight: 600;
  text-align: left;
  width: 300px;
`
export const Category = styled(Flex).attrs(() => ({
  fontSize: 2,
}))`
  text-align: right;
`
