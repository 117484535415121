import { getTranslation } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

export const Breadcrumb = ({ selector, match, modelName }) => {
  let title = useSelector(state => selector(state, { match }))

  if (modelName && !title) title = getTranslation('core.create', { modelName })

  return <>{title}</>
}

Breadcrumb.defaultProps = { modelName: null }
Breadcrumb.propTypes = {
  match: PropTypes.object.isRequired,
  modelName: PropTypes.string,
  selector: PropTypes.func.isRequired,
}
