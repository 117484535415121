import { createAsyncAction } from '../../../helpers'
import { Api as apiCall } from '../../../services'

export * from './admins'
export * from './parents'
export * from './students'
export * from './teachers'
export * from './users'
export * from './children'

export const VALIDATE_EMAIL = createAsyncAction('users/VALIDATE_EMAIL')
export const SIGN_IN_DURING_SIGNUP = 'users/SIGN_IN_DURING_SIGNUP'
export const loginDuringSignUp = (email, password) => ({
  type: SIGN_IN_DURING_SIGNUP,
  email,
  password,
})
export const validateEmail = email =>
  apiCall({
    method: 'POST',
    endpoint: `/user/validate_addresses`,
    types: VALIDATE_EMAIL,
    needsNormalization: false,
    query: {
      data: {
        attributes: {
          email,
        },
      },
    },
  })
