import { styled, themeGet } from '@lyfta/components-theme'

import { AnimatedFlex, Flex } from '../../BaseComponents'

export const Container = styled(Flex)`
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`

export const SidebarContainer = styled(AnimatedFlex).attrs(() => ({
  backgroundColor: 'white',
  borderLeftWidth: 1,
  borderLeftStyle: 'solid',
  borderLeftColor: 'tableRowBottomBorder',
  pl: 3,
}))`
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  flex-direction: column;
  overflow-y: auto;
  position: relative;
`

export const SidebarTitle = styled(AnimatedFlex).attrs(() => ({
  px: 1,
  fontSize: 5,
}))`
  cursor: pointer;
  flex-direction: row;
  height: 50px;
  justify-items: center;
  align-items: center;
  font-weight: bold;
  white-space: nowrap;
  color: ${themeGet('colors.font.accent')};
`

export const SidebarContent = styled(AnimatedFlex).attrs(() => ({
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderTopColor: 'tableRowBottomBorder',
}))`
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`
