import { denormalize } from '@lyfta/components-data'
import { getData } from '@lyfta/components-data/src/Store/Selectors/data'
import { DateTime } from '@lyfta/components-i18n'
import { createSelector } from 'reselect'

export const getState = state => state.lessons

export const getLessons = createSelector(getData, getState, (data, state) => {
  const result = denormalize(data, 'lessons', state.lessons) || []

  // results are sorted by:
  // latest front of class lesson
  // ... earlier front of class lessons, sorted by date
  // latest lesson with class
  // ... earlier lessons with class, sorted by date

  result.sort((a, b) => {
    if (a.frontOfClass === b.frontOfClass) {
      return (
        DateTime.fromISO(b.createdAt).toMillis() -
        DateTime.fromISO(a.createdAt).toMillis()
      )
    }
    if (a.frontOfClass) {
      return -1
    }
    return 1
  })

  return result
})

export const getCurrentLesson = createSelector(
  getData,
  getState,
  (data, state) =>
    state.currentLesson
      ? denormalize(data, 'lessons', state.currentLesson)
      : null,
)
export const getCurrentLessonClassName = createSelector(
  getData,
  getState,
  (data, state) => {
    const lesson = state.currentLesson
      ? denormalize(data, 'lessons', state.currentLesson)
      : null
    return lesson?.className
  },
)
export const getIsLoaded = createSelector(getState, state => state.isLoaded)
export const getIsLoading = createSelector(getState, state => state.isLoading)
export const getError = createSelector(getState, state => state.error)

export const getNumber = createSelector(getState, state => state.number)
export const getSize = createSelector(getState, state => state.size)
export const getTotal = createSelector(getState, state => state.total)
export const getLessonsRaw = createSelector(
  getData,
  getState,
  (data, state) => denormalize(data, 'lessons', state.lessonsRaw) || [],
)
