import { space, styled, themeGet } from '@lyfta/components-theme'
import { AnimatedFlex, Box, ButtonNew, Flex } from '@lyfta/components-ui'
import { Search } from '@lyfta/components-ui/src/Assets/Sidebar'
import MdCancel from 'react-icons/lib/md/cancel'

export const Container = styled(Flex).attrs(props => ({
  mt: 3,
  mb: 0,
  mx: props.mx ? props.mx : 3,
}))`
  flex-direction: column;
  margin: auto 0;
  height: unset;
  width: unset;
  border-radius: 30px;
  box-shadow: 0px 0px 2px rgba(69, 83, 104, 0.25),
    0px 0px 10px rgba(69, 83, 104, 0.2);
  ${space};
`

export const SearchContainer = styled(Flex)`
  justify-content: space-between;
`

export const InputContainer = styled(Flex)`
  flex: 1;
  width: 100%;
  align-items: center;
  min-height: 56px;
  border-radius: 30px;
  background-color: ${themeGet('colors.white')};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  position: relative;
`

export const SearchIcon = styled(Box).attrs(() => ({
  as: Search,
  m: 3,
}))`
  fill: ${themeGet('colors.black_600')};
`

export const SearchLabel = styled(ButtonNew).attrs(() => ({
  my: 2,
  mr: 2,
}))``

export const Tag = styled(Flex).attrs(() => ({
  pr: 36,
  pl: 3,
  m: 1,
  fontSize: 3,
}))`
  position: relative;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  min-height: 18px;
  border-radius: 8px;
  color: ${themeGet('colors.black_600')};
  &:hover {
    opacity: 0.8;
  }
`

export const TagRemove = styled(Box).attrs(({ id }) => ({
  id,
  as: MdCancel,
  size: 20,
}))`
  position: absolute;
  right: 10px;
  color: ${themeGet('colors.black_600')};
`

export const InputText = styled.input.attrs(({ placeholder }) => ({
  fontSize: 3,
  placeholder,
}))`
  flex-grow: 1;
  max-width: unset;
  min-width: 100px;
  border: none;
  outline: none;
  padding: 0;
  line-height: 24px;
  color: ${themeGet('colors.primary')};
`

export const SidebarTitle = styled(AnimatedFlex).attrs(() => ({
  mt: 2,
  px: 1,
  fontSize: 4,
}))`
  cursor: pointer;
  flex-direction: row;
  height: 48px;
  justify-items: center;
  align-items: center;
  font-weight: bold;
  white-space: nowrap;
`
