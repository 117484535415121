import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import {
  HIDE_SEARCH_MODAL,
  SEARCH_TAB,
  SHOW_SEARCH_MODAL,
} from '../../Actions/ui'

const initialState = {
  activeTab: 0,
  searchModal: false,
}

const setActiveTab = (state, { index }) => state.merge({ activeTab: index })

const showSearchModal = state => state.merge({ searchModal: true })

const hideSearchModal = state => state.merge({ searchModal: false })

const handlers = {
  [SEARCH_TAB]: setActiveTab,
  [SHOW_SEARCH_MODAL]: showSearchModal,
  [HIDE_SEARCH_MODAL]: hideSearchModal,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
