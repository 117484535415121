import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'

export const modalButtonsDefaultProps = {
  deleteOrRemove: false,
  cancelText: <I18n i18nKey="actions.cancel" />,
  isLoading: false,
  okText: <I18n i18nKey="actions.ok" />,
}

export const modalButtonsPropTypes = {
  deleteOrRemove: PropTypes.bool,
  cancelText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  isLoading: PropTypes.bool,
  okText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}
