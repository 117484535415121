import { resetPassword } from '@lyfta/components-data/src/Store/Actions/auth'
import { I18n } from '@lyfta/components-i18n'
import {
  AnimatedInput,
  AuthLayout,
  ButtonNew,
  Locale,
} from '@lyfta/components-ui'
import { usernameConstraint } from '@lyfta/components-ui/src/Constants/constraints'
import paths from 'Constants/paths'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import validate from 'validate.js'

import {
  ErrorBlock,
  FormBlock,
  Link,
  LocaleWrapper,
  Panel,
  TextBlock,
  Title,
} from './styles'

class Forgot extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      sent: false,
      error: null,
    }
  }

  componentDidMount() {
    document.title = 'Lyfta student - Forgot password'
  }

  // TODO: refactor this
  handleSubmit = ({ username }) => {
    const { onResetPassword } = this.props
    onResetPassword(username).then(data => {
      if (data.ok) {
        this.setState({
          sent: true,
        })
      } else {
        this.setState({
          error: <I18n text="auth.forgotError" />,
        })
      }
    })
  }

  validate = values =>
    validate(values, {
      ...usernameConstraint,
    })

  // TODO: refactor this
  renderForm = ({ handleSubmit, pristine, invalid }) => {
    const { error, sent } = this.state
    if (sent) {
      return (
        <Panel flexDirection="column">
          <TextBlock>
            <I18n tag={Title} text="auth.emailSent" />
            <I18n tag="p" text="auth.sentEmailLink" />
          </TextBlock>
        </Panel>
      )
    }

    return (
      <>
        <I18n m={4} ml={0} tag={Title} text="auth.resetPassword" />
        <FormBlock onSubmit={handleSubmit}>
          <AnimatedInput
            autoComplete="off"
            label={<I18n text="auth.email_or_username" />}
            name="username"
          />
          <ErrorBlock>{error}</ErrorBlock>

          <ButtonNew
            disabled={pristine || invalid}
            mb={3}
            mt={2}
            type="task"
            width={1}
          >
            <I18n text="auth.resetPassword" />
          </ButtonNew>
          <I18n
            href={paths.loginWithPassword}
            tag={Link}
            text="auth.rememberPassword"
            to={paths.loginWithPassword}
          />
        </FormBlock>
      </>
    )
  }

  render() {
    return (
      <AuthLayout>
        <LocaleWrapper>
          <Locale fill="colors.black_900" />
        </LocaleWrapper>
        <Panel>
          <Form
            render={this.renderForm}
            validate={this.validate}
            onSubmit={this.handleSubmit}
          />
        </Panel>
      </AuthLayout>
    )
  }
}

Forgot.propTypes = {
  onResetPassword: PropTypes.func.isRequired,
}

export default connect(createStructuredSelector({}), {
  onResetPassword: resetPassword,
})(Forgot)
