import { getStudentLessonId } from '@lyfta/components-data/src/Store/Selectors/auth'
import { getResourceId } from '@lyfta/components-data/src/Store/Selectors/persist'
import { getViewerType } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { loadLessonPlan } from '../../Store/Actions/lessonPlans'
import { loadStudentLessons } from '../../Store/Actions/lessons'
import { getCurrentLesson } from '../../Store/Selectors/lessons'
import { getIsTeachingInFrontOfClass } from '../../Store/Selectors/questTracker'
import { getIsQuest } from '../../Store/Selectors/UI/quest'
import QuestTracker from './QuestTracker'

const selector = createStructuredSelector({
  lesson: getCurrentLesson,
  resourceId: getResourceId,
  showQuest: getIsQuest,
  studentLessonId: getStudentLessonId,
  viewerType: getViewerType,
  isTeachingInFrontOfClass: getIsTeachingInFrontOfClass,
})

const actions = {
  onLoadStudentLesson: loadStudentLessons,
  onLoadLessonPlan: loadLessonPlan,
}

export default connect(selector, actions)(QuestTracker)
