import { capitalizeFirstLetter } from '@lyfta/components-ui/src/Services/Utils'
import _ from 'lodash'
import PT from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { questTrackerData } from '../../../../../Store/Actions/questTracker'
import { InformationText } from '../InformationText'
import { Submit } from '../Submit'
import Slider from './Slider'
import Stars from './Stars'
import {
  RatingBlock,
  RatingContainer,
  RatingLabelContainer,
  RatingLabelContainerRight,
  RatingLabelLeft,
  RatingLabelRight,
} from './styles'

const Rating = ({ disabled, task }) => {
  const {
    description,
    callToAction,
    id,
    properties: { ratingStyle },
    answerData,
  } = task

  const minValue = _.get(task, 'i18NProperties.minValue', '')
  const maxValue = _.get(task, 'i18NProperties.maxValue', '')

  const capitalizedMinValue = capitalizeFirstLetter(minValue)
  const capitalizedMaxValue = capitalizeFirstLetter(maxValue)

  const hasAnswer = typeof answerData?.data?.answer === 'number'
  const defaultValue = ratingStyle === 'numeric' ? 0.5 : 0

  const initialValue = hasAnswer ? answerData?.data?.answer : defaultValue

  const [value, setValue] = useState(initialValue)
  const dispatch = useDispatch()

  const saveResponse = useCallback(() => {
    dispatch(questTrackerData.saveResponse(id, { answer: value }))
  }, [value])
  useEffect(() => {
    setValue(answerData?.data?.answer || initialValue)
  }, [answerData])

  return (
    <>
      <InformationText textSource={description} />
      <RatingContainer>
        <RatingLabelContainer>
          <RatingLabelLeft>{capitalizedMinValue}</RatingLabelLeft>
        </RatingLabelContainer>
        <RatingBlock>
          {ratingStyle === 'star' && (
            <Stars disabled={disabled} setValue={setValue} value={value} />
          )}
          {ratingStyle === 'numeric' && (
            <Slider disabled={disabled} setValue={setValue} value={value} />
          )}
        </RatingBlock>
        <RatingLabelContainerRight>
          <RatingLabelRight>{capitalizedMaxValue}</RatingLabelRight>
        </RatingLabelContainerRight>
      </RatingContainer>
      <Submit buttonText={callToAction} onClick={saveResponse} />
    </>
  )
}

Rating.propTypes = {
  disabled: PT.bool,
  task: PT.object.isRequired,
}
Rating.defaultProps = {
  disabled: false,
}
export default Rating
