import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import {
  ZOOM_HANDLED,
  ZOOM_IN_PRESSED,
  ZOOM_OUT_PRESSED,
} from '../../Actions/ui'

const initialState = {
  inPressed: false,
  outPressed: false,
}

const inPressed = state => state.merge({ inPressed: true })
const outPressed = state => state.merge({ outPressed: true })
const handled = state => state.merge({ inPressed: false, outPressed: false })

const handlers = {
  [ZOOM_IN_PRESSED]: inPressed,
  [ZOOM_OUT_PRESSED]: outPressed,
  [ZOOM_HANDLED]: handled,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
