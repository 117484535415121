import './Styles/fonts.css'

import { BREAKPOINTS } from '../../Constants/breakpoints'
import { walsheimAndFallbackFontFamily } from '../../styling'
import buttons from './buttons'
import buttonsNew from './buttonsNew'
import buttonsSubtypes from './buttonsSubtypes'
import colors from './colors'
import fontSizes from './fontSizes'
import sizes from './sizes'
import space from './space'
import zIndexes from './zIndexes'

export const breakpoints = {
  [BREAKPOINTS.MOBILE]: '420px',
  [BREAKPOINTS.TABLET]: '940px',
  [BREAKPOINTS.DESKTOP]: '1200px',
  [BREAKPOINTS.LARGE]: '1920px',
}
export const breakpointsNumber = {
  [BREAKPOINTS.MOBILE]: 420,
  [BREAKPOINTS.TABLET]: 940,
  [BREAKPOINTS.DESKTOP]: 1200,
  [BREAKPOINTS.LARGE]: 1920,
}
const theme = {
  colors,
  sizes,
  zIndexes,
  breakpoints: Object.values(breakpoints),

  ...fontSizes,
  ...space,

  name: 'Lyfta Teacher main theme',
  font: walsheimAndFallbackFontFamily,
  fontTitle: walsheimAndFallbackFontFamily,
  transitionTime: '.17s',
  ...buttons,
  ...buttonsNew,
  ...buttonsSubtypes,
}

export default theme
