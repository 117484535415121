import enLocale from 'date-fns/locale/en-GB'
import esLocale from 'date-fns/locale/es'
import fiLocale from 'date-fns/locale/fi'
import ptLocale from 'date-fns/locale/pt'
import ruLocale from 'date-fns/locale/ru'
import svLocale from 'date-fns/locale/sv'

import mainEn from './translations/en.json'
import mainEs from './translations/es.json'
import mainFi from './translations/fi.json'
import mainPt from './translations/pt.json'
import mainRu from './translations/ru.json'
import mainSv from './translations/sv.json'

export const dateLocales = {
  en: enLocale,
  ru: ruLocale,
  fi: fiLocale,
  sv: svLocale,
  es: esLocale,
  pt: ptLocale,
}

export const resources = {
  en: {
    common: mainEn,
  },
  fi: {
    common: mainFi,
  },
  ru: {
    common: mainRu,
  },
  sv: {
    common: mainSv,
  },
  es: {
    common: mainEs,
  },
  pt: {
    common: mainPt,
  },
}
