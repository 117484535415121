export default {
  space: [
    '0px', // 0
    '4px', // 1
    '8px', // 2
    '16px', // 3
    '24px', // 4
    '32px', // 5
    '40px', // 6
    '48px', // 7
    '64px', // 8
    '72px', // 9
    '80px', // 10
    '160px', // 11
  ],
  spacingLargest: '160px',
  spacingLarger: '80px',
  spacingLarge: '64px',
  spacingMedium: '48px',
  spacingSmall: '24px',
  spacingSmaller: '16px',
  spacingSmallest: '8px',
}
