import { css, styled, themeGet } from '@lyfta/components-theme'

import { Container as BaseContainer } from '../../BaseComponents'
import { Box } from '../Box'
import { Flex } from '../Flex'

export const Container = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
`

export const Content = styled(Flex)`
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 23px;
`

export const Step = styled(BaseContainer)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: ${themeGet('colors.dustyGray')};
  background-image: linear-gradient(
    to top,
    ${themeGet('colors.silver')},
    rgba(216, 216, 216, 0)
  );
  border: 1px solid ${themeGet('colors.silver')};
  text-align: center;
  color: ${themeGet('colors.white')};
  margin-right: 3px;

  ${props =>
    props.active === 'true' &&
    css`
      background-image: linear-gradient(
        to top,
        ${themeGet('colors.primary')},
        rgba(216, 216, 216, 0)
      );
    `};
`

export const Label = styled(Box)`
  font-size: 14px;
  line-height: 1.71;
`
