import { isTeacherApp } from '@lyfta/components-config'
import { Translation } from '@lyfta/components-i18n'
import { without } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Flex } from '../../Components'
import { PropTypes } from '../../Helpers/prop-types'
import { reactEvent } from '../../Services/Utils'
import { PageTitlePortal } from '../Portals'
import Table from './Component'
import { TableControls } from './Controls'
import usePagedTable from './hook/usePagedTable'
import PaginationNew from './PaginationNew'
import {
  Container,
  Content,
  Controls,
  InputContainer,
  InputText,
  Interpunct,
  SearchIcon,
  SearchResults,
  SubTitle,
  Title,
  TitleContainer,
} from './styles'

export { selectionColumn } from './SelectionColumn'
export { StatusIndicator } from './StatusIndicator'
export { TableActionButton } from './ActionButton'
export { TableButton } from './TableButton'
const DataTable = ({
  action,
  children,
  columns,
  controls,
  customHeader,
  customHeaderWord,
  createLabelKey,
  googleEvent,
  defaultPageSize,
  dataParser,
  deleteAction,
  editRoute,
  newRoute,
  pageSelector,
  loadingSelector,
  modelName,
  noDataText,
  selector,
  defaultSort,
  filter,
  rowAction,
  searchColumn,
  searchPlaceholderKey,
  withControls,
  hideHeader,
  searchComponent,
  hover,
  disableNewRoute,
  disableNewRouteClick,
  onCreateButtonPress,
  title,
  disableRow,
  id,
  permissions,
  columnActions,
  modalProps,
  showSubtitle,
  deleteActionLabel,
  sortProperty,
  removePagination,
  clickRowCheckbox,
  variableRowHeight,
  ...props
}) => {
  const tableId = id || Math.floor(Math.random() * (10000 - 1)) + 1

  const { createGoogleEvent, clickRowGoogleEvent } = googleEvent || {}

  // Detecting when the user reaches the bottom of the table
  const tableParentRef = useRef(null)
  const [isAtBottom, setIsAtBottom] = useState(false)

  useEffect(() => {
    if (!tableParentRef.current) return () => {}

    const scrollableNode = tableParentRef.current.querySelector('.rt-tbody')

    if (!scrollableNode) return () => {}

    const onScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollableNode
      const atBottom = scrollTop + clientHeight >= scrollHeight
      setIsAtBottom(atBottom)
    }

    scrollableNode.addEventListener('scroll', onScroll)

    return () => {
      scrollableNode.removeEventListener('scroll', onScroll)
    }
  }, [])
  // end

  const {
    tableData,
    tableColumns,
    pager,
    reload,
    selections,
    sort,
    search,
    routeParams,
    filters,
  } = usePagedTable({
    defaultPageSize,
    columns,
    action,
    selector,
    loadingSelector,
    pageSelector,
    dataParser,
    defaultSort,
    defaultFilter: filter,
    searchColumn,
    disableRow,
    permissions,
    columnActions,
    tableId,
    sortProperty,
  })
  const history = useHistory()

  const customProps = { id: tableId }

  const clickRow = (state, rowInfo) => {
    let addClassName = ''
    const { setSelected, selected } = selections
    const idRow = rowInfo?.original?.id
    const matchDisabled = Object.keys(disableRow).find(item => {
      return disableRow[item] === rowInfo.original[item]
    })
    if (rowInfo && selections.selected.includes(idRow))
      addClassName = '-selected'

    if (rowInfo && matchDisabled) addClassName += ' disabled'
    return {
      className: addClassName,
      onClick: e => {
        e.stopPropagation()
        if (clickRowCheckbox) {
          let nextSelected = selected
          if (selected.includes(idRow)) {
            nextSelected = without(nextSelected, idRow)
          } else {
            nextSelected = [...nextSelected, idRow]
          }
          setSelected(nextSelected)

          return null
        }

        if (clickRowGoogleEvent) {
          reactEvent({
            action: clickRowGoogleEvent,
          })
        }

        if (editRoute)
          return history.push(
            editRoute(rowInfo.original.id, {
              ...rowInfo.original,
              ...routeParams,
            }),
          )
        if (rowAction)
          return rowAction(rowInfo.original.id, {
            ...rowInfo.original,
            ...routeParams,
          })
        return null
      },
    }
  }
  return (
    <Translation>
      {t => (
        <Container {...props}>
          <Content>
            {!hideHeader && modelName && !customHeader && !isTeacherApp && (
              // using count 10 to ensure the plural version of the model name is used
              <PageTitlePortal
                title={t(`mainHeader.manage`, {
                  count: 10,
                  modelName,
                  customHeaderWord,
                })}
              />
            )}
            {!hideHeader && customHeader && !isTeacherApp && (
              // using count 10 to ensure the plural version of the model name is used
              <PageTitlePortal
                title={t(customHeader, {
                  count: 10,
                  modelName,
                  customHeaderWord,
                })}
              />
            )}
            {title && (
              <TitleContainer pl={3}>
                <Title>{title}</Title>
                {showSubtitle && (
                  <SubTitle>
                    <Interpunct>.</Interpunct>{' '}
                    {`${pager.total || tableData.length} ${t(
                      `core.models.${modelName.toLowerCase()}`,
                      {
                        count: pager.total || tableData.length,
                      },
                    ).toLowerCase()}`}
                  </SubTitle>
                )}
              </TitleContainer>
            )}
            {searchColumn && !searchComponent && (
              <InputContainer>
                <SearchIcon />
                <InputText
                  data-intercom-target={t(searchPlaceholderKey)}
                  id={`search-${modelName}`}
                  placeholder={t(searchPlaceholderKey)}
                  value={search.string}
                  onChange={search.perform}
                />
              </InputContainer>
            )}

            {searchComponent &&
              typeof searchComponent === 'object' &&
              searchComponent}
            {searchComponent &&
              typeof searchComponent === 'function' &&
              searchComponent(pager)}

            {!children && (
              <SearchResults ref={tableParentRef}>
                {withControls && (
                  <Controls>
                    <TableControls
                      controls={controls}
                      createLabelKey={createLabelKey}
                      createGoogleEvent={createGoogleEvent}
                      deleteAction={deleteAction}
                      deleteActionLabel={deleteActionLabel}
                      disableNewRoute={disableNewRoute}
                      disableNewRouteClick={disableNewRouteClick}
                      modalProps={modalProps}
                      modelName={modelName}
                      newRoute={newRoute}
                      reload={reload}
                      selections={selections}
                      tableData={tableData}
                      disableRow={disableRow}
                      onCreateButtonPress={onCreateButtonPress}
                    />
                  </Controls>
                )}
                {/* if no children are passed, render the standard table */}

                <Table
                  className=""
                  columnActions={columnActions}
                  columns={tableColumns}
                  data={tableData}
                  modelName={modelName}
                  defaultFilterMethod={() => true}
                  defaultSorted={sort.array}
                  disableRow={disableRow}
                  getProps={() => customProps}
                  getTrProps={clickRow}
                  hasAction={!!rowAction || !!editRoute}
                  hover={hover}
                  minRows={0}
                  noDataText={noDataText}
                  pageSize={pageSelector && pager.size}
                  pager={pageSelector && pager}
                  permissions={permissions}
                  onFilteredChange={filters.change}
                  onSortedChange={sort.update}
                  isAtBottom={isAtBottom}
                  variableRowHeight={variableRowHeight}
                />
                {/* NOTE: defaultFilterMethod={() => true} */}
                {/* essentially disable client side filtering, */}
                {/* all filtering should be done server side */}
              </SearchResults>
            )}

            {children && (
              <Flex flex={1} flexDirection="column" ml={2} overflow="hidden">
                {children(tableData, filter)}
                {!removePagination && (
                  <Flex flex={0} flexDirection="column">
                    <PaginationNew
                      paddingLeft="32px"
                      pager={pager}
                      isAtBottom
                      modelName={modelName}
                    />
                  </Flex>
                )}
              </Flex>
            )}
          </Content>
        </Container>
      )}
    </Translation>
  )
}
DataTable.defaultProps = {
  children: null,
  createLabelKey: 'core.create',
  columns: () => [],
  controls: () => {},
  customHeader: null,
  customHeaderWord: null,
  dataParser: data => data,
  defaultPageSize: false,
  defaultSort: null,
  deleteActionLabel: '',
  filter: {},
  disableRow: {},
  loadingSelector: null,
  modelName: null,
  searchColumn: null,
  searchComponent: null,
  searchPlaceholderKey: 'core.search.placeholderName',
  withControls: false,
  deleteAction: null,
  editRoute: null,
  newRoute: null,
  hideHeader: false,
  pageSelector: null,
  rowAction: null,
  hover: true,
  showSubtitle: false,
  disableNewRoute: false,
  disableNewRouteClick: null,
  title: null,
  id: '',
  sortProperty: '',
  permissions: {},
  columnActions: {},
  onCreateButtonPress: null,
  modalProps: {},
  removePagination: false,
  clickRowCheckbox: false,
  variableRowHeight: false,
  googleEvent: {},
}
DataTable.propTypes = {
  action: PropTypes.func.isRequired,
  children: PropTypes.func,
  columnActions: PropTypes.object,
  columns: PropTypes.func,
  controls: PropTypes.func,
  createLabelKey: PropTypes.string,
  customHeader: PropTypes.string,
  customHeaderWord: PropTypes.string,
  dataParser: PropTypes.func,
  defaultPageSize: PropTypes.number,
  defaultSort: PropTypes.string,
  deleteAction: PropTypes.func,
  deleteActionLabel: PropTypes.string,
  disableNewRoute: PropTypes.bool,
  disableNewRouteClick: PropTypes.func,
  disableRow: PropTypes.object,
  editRoute: PropTypes.func,
  filter: PropTypes.object,
  hideHeader: PropTypes.bool,
  hover: PropTypes.bool,
  showSubtitle: PropTypes.bool,
  id: PropTypes.string,
  loadingSelector: PropTypes.func,
  modalProps: PropTypes.object,
  modelName: PropTypes.string,
  newRoute: PropTypes.func,
  noDataText: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  pageSelector: PropTypes.func,
  permissions: PropTypes.object,
  rowAction: PropTypes.func,
  searchColumn: PropTypes.string,
  searchComponent: PropTypes.children,
  searchPlaceholderKey: PropTypes.string,
  selector: PropTypes.func.isRequired,
  title: PropTypes.string,
  sortProperty: PropTypes.string,
  withControls: PropTypes.bool,
  onCreateButtonPress: PropTypes.func,
  removePagination: PropTypes.bool,
  clickRowCheckbox: PropTypes.bool,
  variableRowHeight: PropTypes.bool,
  googleEvent: PropTypes.object,
}

export { DataTable }
