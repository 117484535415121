import { ButtonNew, Icon } from '@lyfta/components-ui'
import PropTypes from 'prop-types'
import React from 'react'

import { CloseButtonIcon } from './styles'

export const CloseButton = ({ defaultFill, id, text, onClick }) => {
  return (
    <ButtonNew
      default={{
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        boxShadow: 'none',
        fill: defaultFill,
      }}
      id={id}
      leadingIcon={<Icon glyph={<CloseButtonIcon />} />}
      secondary
      title={text}
      type="icon"
      onClick={onClick}
    >
      {text}
    </ButtonNew>
  )
}

CloseButton.displayName = 'CloseButton'

CloseButton.defaultProps = {
  defaultFill: null,
}

CloseButton.propTypes = {
  defaultFill: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}
