import { noop, omit } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Field } from 'react-final-form'

import { renderField } from './FieldRenderer'
import {
  ContainerSearch,
  InputContainer,
  SearchContainer,
  SearchIcon,
} from './styles'

const Search = ({
  onSearchChange,
  id,
  items,
  customButton,
  placeholder,
  ...rest
}) => {
  const [searchText, setSearchText] = useState({})
  const [addBtnClicked, setAddBtnClicked] = useState(false)
  const handleChange = value => {
    setSearchText(value)
  }
  const onInputValueChange = ({ inputValue: name }) => {
    setSearchText(name)
  }
  return (
    <ContainerSearch
      {...omit(rest, [
        'searchType',
        'searchText',
        'onLoadContents',
        'onClearSearch',
      ])}
    >
      <SearchContainer>
        <InputContainer>
          <SearchIcon />

          <Field
            id={id}
            items={items}
            keepValueOnNoItem
            name="searchDropdown"
            placeholder={placeholder}
            render={renderField}
            resetField={{ addBtnClicked, setAddBtnClicked }}
            type="text"
            onChange={handleChange}
            onInputValueChange={onInputValueChange}
            {...rest}
          />
          {customButton && customButton(searchText, setAddBtnClicked)}
        </InputContainer>
      </SearchContainer>
    </ContainerSearch>
  )
}

Search.defaultProps = {
  id: null,
  items: [],
  customButton: noop,
  placeholder: '',
}

Search.propTypes = {
  customButton: PropTypes.func,
  id: PropTypes.string,
  items: PropTypes.array,
  placeholder: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
}

export default Search
