import { css, styled, themeGet } from '@lyfta/components-theme'

import { Box } from '../../Box'

export { Span } from '../../../BaseComponents/index'

const activeCss = css`
  color: ${themeGet('colors.font.accent')};
`

export const Container = styled(Box)`
  color: ${themeGet('colors.error_100')};
  transition: all ${themeGet('transitionTime')} ease;
  font-size: 16px; // Body 1
  font-weight: 400; // Body 1
  letter-spacing: 0.5px; // Body 1
  line-height: 24px; // Body 1
  margin-top: 4px;

  ${props => props.active && activeCss};
`

Container.displayName = 'FieldErrorNewContainer'
