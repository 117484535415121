import colors from '@lyfta/components-theme/src/themes/Main/colors'

export const getDeleteStyle = p => {
  if (!p.delete) {
    return {}
  }
  if (!p.secondary) {
    return {
      default: {
        backgroundColor: colors.error_100,
        color: colors.neutral_100,
        borderColor: colors.error_100,
        fill: colors.neutral_100,
      },
      focusVisible: {
        backgroundColor: colors.error_90,
        borderColor: colors.neutral_100,
        color: colors.neutral_100,
        outlineColor: colors.error_100,
      },
      hover: {
        backgroundColor: colors.error_90,
        borderColor: colors.error_90,
        color: colors.neutral_100,
      },
      pressed: {
        backgroundColor: colors.error_110,
        borderColor: colors.error_110,
        color: colors.neutral_100,
      },
    }
  }
  return {
    default: {
      color: colors.error_100,
      borderColor: colors.error_100,
      fill: colors.error_100,
    },
    focusVisible: {
      backgroundColor: colors.error_100,
      borderColor: colors.neutral_100,
      color: colors.neutral_100,
      outlineColor: colors.error_100,
    },
    hover: {
      backgroundColor: colors.error_100,
      borderColor: colors.error_100,
      color: colors.neutral_100,
      fill: colors.neutral_100,
    },
    pressed: {
      backgroundColor: colors.error_120,
      borderColor: colors.error_120,
      color: colors.neutral_100,
    },
  }
}
