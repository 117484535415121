import { styled } from '@lyfta/components-theme/src/styling/components'
import { merge } from 'lodash'
import React from 'react'

import { ButtonNew, Icon } from '../../../Components'

export const TableActionButton = styled(ButtonNew).attrs(props => {
  const leadingIcon = props.glyph ? (
    <Icon mr="10px" glyph={props.glyph} height={20} width={20} />
  ) : null
  return merge(
    {
      leadingIcon,
      mb: 2,
      mr: 3,
      pl: '14px',
      float: 'left',
      width: 'fit-content',
    },
    props,
  )
})``
