import { getError } from '@lyfta/components-data/src/Store/Selectors/auth'
import { I18n, TransLink, getTranslation } from '@lyfta/components-i18n'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import { AnimatedInput, ButtonNew } from '../../../../Components'
import { ErrorBlock, LinkMailto } from '../../styles'

const SignInFormFieldset = ({
  handleSubmit,
  pristine,
  invalid,
  customButton,
}) => {
  const error = useSelector(getError)
  return (
    <>
      <AnimatedInput
        autoComplete="off"
        containerProps={{
          activeBottomBorderColor: 'colors.secondary',
        }}
        id="input-username"
        label={<I18n text="auth.email_or_username" />}
        mb={3}
        name="username"
        type="text"
      />

      <AnimatedInput
        autoComplete="off"
        containerProps={{
          activeBottomBorderColor: 'colors.secondary',
        }}
        id="input-password"
        label={<I18n text="auth.password" />}
        mb={2}
        name="password"
        type="password"
      />
      <ErrorBlock>
        {error && (
          <TransLink
            catchString={getTranslation('auth.contactUs')}
            components={{
              Link: (
                <LinkMailto href={getTranslation('auth.lyftaContactUsLink')} />
              ),
            }}
            wrapper={`<Link>${getTranslation('auth.contactUs')}</Link>`}
          >
            {error}
          </TransLink>
        )}
      </ErrorBlock>
      {customButton ? (
        customButton({ handleSubmit })
      ) : (
        <ButtonNew
          disabled={pristine || invalid}
          id="btn-login"
          mt={2}
          type="task"
          width={1}
          onClick={handleSubmit}
        >
          <I18n text="auth.login" />
        </ButtonNew>
      )}
    </>
  )
}

SignInFormFieldset.defaultProps = {
  customButton: noop,
}

SignInFormFieldset.propTypes = {
  customButton: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
}

export default SignInFormFieldset
