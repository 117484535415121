import React from 'react'

function SvgLogout(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <path
        d="M6 7L10.8384 7L10.8384 37L6 37L6 7ZM26.6336 27.1731L15.7664 27.1731L15.7664 16.8952L26.6336 16.8952L26.6336 11.1959L38 22.0342L26.6336 32.8724L26.6336 27.1731Z"
        fill="#050B15"
      />
    </svg>
  )
}

export default SvgLogout
