import { changePassword } from '@lyfta/components-data/src/Store/Actions/auth'
import { I18n } from '@lyfta/components-i18n'
import get from 'lodash/get'
import qs from 'qs'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Header, Title } from '../../../Components'
import { PropTypes } from '../../../Helpers/prop-types'
import { Link, Panel, TextBlock } from '../styles'
import ChangePasswordForm from './Form'

const checkToken = location => {
  const query = qs.parse(location.search.replace('?', ''))

  return get(query, 'reset_token')
}
export const ChangePasswordScreen = ({
  initialValues,
  changePasswordAction,
  paths,
  withHeader,
}) => {
  const location = useLocation()
  const [sent, setSent] = useState(false)
  const dispatch = useDispatch()
  const performReset = ({ password, confirmPassword }) => {
    dispatch(
      changePasswordAction({
        token: checkToken(location),
        password,
        passwordConfirmation: confirmPassword,
      }),
    )
    setSent(true)
  }

  return (
    <Panel>
      {sent && (
        <Panel flexDirection="column">
          <TextBlock>
            <I18n tag={Title} text="auth.passwordChanged" />
            <I18n tag="p" text="auth.loginNewPassword" />
            <I18n
              exact
              href={paths.signIn}
              tag={Link}
              text="auth.login"
              to={paths.signIn}
            />
          </TextBlock>
        </Panel>
      )}
      {!sent && (
        <>
          {withHeader && <I18n tag={Header} text="auth.changePassword" />}
          <ChangePasswordForm
            initialValues={initialValues}
            onSubmit={performReset}
          />
        </>
      )}
    </Panel>
  )
}

ChangePasswordScreen.defaultProps = {
  initialValues: {},
  paths: null,
  changePasswordAction: changePassword,
  withHeader: false,
}
ChangePasswordScreen.propTypes = {
  changePasswordAction: PropTypes.func,
  initialValues: PropTypes.object,
  paths: PropTypes.object,
  withHeader: PropTypes.bool,
}
