import { styled, themeGet } from '@lyfta/components-theme'

import { Flex } from '../Flex'

const Panel = styled(Flex).attrs(() => ({
  mx: 3,
  mb: 3,
  px: 4,
  py: 2,
}))`
  flex: 0 0 auto;
  flex-grow: 1;
  flex-basis: auto;
  flex-shrink: 0;
  max-width: 100%;

  background-color: ${themeGet('colors.white')};
  box-shadow: 10px 10px 20px -16px rgba(0, 0, 0, 0.5);
`

export default Panel
