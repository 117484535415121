import { setStudentLessonID } from '@lyfta/components-data/src/Store/Actions/auth'
/* eslint-disable */
import { clearResourceId } from '@lyfta/components-data/src/Store/Actions/viewer'
import { getTranslation } from '@lyfta/components-i18n'
import { Globe as GlobeIcon } from '@lyfta/components-student'
import { loadLesson } from '@lyfta/components-student/src/Store/Actions/lessons'
import { questTrackerData } from '@lyfta/components-student/src/Store/Actions/questTracker'
import { back, quest } from '@lyfta/components-student/src/Store/Actions/ui'
import { getIsBackToGlobeHighlighted } from '@lyfta/components-student/src/Store/Selectors/UI/back'
import { getIsInScene } from '@lyfta/components-student/src/Store/Selectors/UI/scene'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { BouncyButton, PrimaryButtonIcon } from '../styles'

export const HomeButton = () => {
  const dispatch = useDispatch()
  const highlight = useSelector(getIsBackToGlobeHighlighted)
  const isInScene = useSelector(getIsInScene)
  const location = useLocation()

  const handleClick = lessonId => {
    if (location.pathname === '/lesson-selection') {
      dispatch(questTrackerData.resetCurrentLesson())
      if (lessonId > 0) {
        dispatch(loadLesson(lessonId))
        dispatch(setStudentLessonID(lessonId))
        dispatch(questTrackerData.setIsTeachingInFrontOfClass(true))
      } else {
        dispatch(setStudentLessonID(null))
        dispatch(questTrackerData.setIsTeachingInFrontOfClass(false))
      }
      dispatch(quest.pauseWebGL())
      dispatch(quest.questSelect())
    } else {
      dispatch(back.show())
      dispatch(clearResourceId())
    }
  }

  return (
    <BouncyButton
      highlight={highlight}
      id="btn-home-student-app"
      leadingIcon={<PrimaryButtonIcon glyph={GlobeIcon} />}
      subtype={!isInScene ? 'navigation' : null}
      title={getTranslation('header.home')}
      type="icon"
      onClick={() => {
        handleClick(0)
      }}
      secondary={location.pathname === '/lesson-selection'}
    >
      {getTranslation('header.home')}
    </BouncyButton>
  )
}
