import * as React from 'react'

function SvgSaTick(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
  <path fillRule="evenodd" clipRule="evenodd" d="M20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38ZM18.6844 28.2784L29.2244 13.5223L26.1408 11.3198L17.7334 23.09L13.5757 20.3182L11.4737 23.4713L18.6844 28.2784Z" fill="#050B15"/>
</svg>
  )
}

export default SvgSaTick