import { USER_TYPES } from '@lyfta/components-ui'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import Achievement from './Achievement'
import Activities from './Activities'
import { Container } from './styles'
import Tasks from './Tasks'
import Welcome from './Welcome'

const QuestTracker = ({
  isTeachingInFrontOfClass,
  onLoadStudentLesson,
  onLoadLessonPlan,
  lesson,
  lessonPlanId,
  resourceId,
  showQuest,
  studentLessonId,
  viewerType,
}) => {
  useEffect(() => {
    if (!resourceId && viewerType) {
      if (viewerType === USER_TYPES.students || isTeachingInFrontOfClass) {
        onLoadStudentLesson(studentLessonId)
      }
      if (
        (viewerType === USER_TYPES.teachers ||
          viewerType === USER_TYPES.admins) &&
        lessonPlanId
      ) {
        onLoadLessonPlan(lessonPlanId)
      }
    }
  }, [viewerType, lessonPlanId])

  if ((!lesson && !lessonPlanId) || resourceId) return null

  if (lesson && lesson.state === 'archived') return null
  if (!showQuest) return null

  const editingDisabled =
    (viewerType === USER_TYPES.teachers && !isTeachingInFrontOfClass) ||
    viewerType === USER_TYPES.admins

  return (
    <Container>
      <Activities />
      <Achievement />
      <Welcome />
      <Tasks editingDisabled={editingDisabled} />
    </Container>
  )
}
QuestTracker.defaultProps = {
  lesson: null,
  lessonPlanId: null,
  resourceId: null,
  studentLessonId: null,
  viewerType: null,
}
QuestTracker.propTypes = {
  isTeachingInFrontOfClass: PropTypes.bool.isRequired,
  lesson: PropTypes.object,
  lessonPlanId: PropTypes.string,
  resourceId: PropTypes.string,
  showQuest: PropTypes.bool.isRequired,
  studentLessonId: PropTypes.string,
  viewerType: PropTypes.string,
  onLoadLessonPlan: PropTypes.func.isRequired,
  onLoadStudentLesson: PropTypes.func.isRequired,
}

export default QuestTracker
