import {
  resetPassword,
  setEmail,
} from '@lyfta/components-data/src/Store/Actions/auth'
import { I18n } from '@lyfta/components-i18n'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Header, Title } from '../../../Components'
import { PropTypes } from '../../../Helpers/prop-types'
import { Link, Links, Panel, TextBlock } from '../styles'
import ResetForm from './Form'

export const ResetPasswordScreen = ({
  initialValues,
  resetAction,
  paths,
  withHeader,
  withLinks,
}) => {
  const [sent, setSent] = useState(false)
  const dispatch = useDispatch()
  const performReset = ({ email }) => {
    dispatch(resetAction(email))
    dispatch(setEmail(null))
    setSent(true)
  }

  return (
    <Panel>
      {sent && (
        <Panel flexDirection="column">
          <TextBlock>
            <I18n tag={Title} text="auth.emailSent" />
            <I18n tag="p" text="auth.sentEmailLink" />
          </TextBlock>
        </Panel>
      )}
      {!sent && (
        <>
          {withHeader && <I18n tag={Header} text="auth.resetPassword" />}
          <ResetForm initialValues={initialValues} onSubmit={performReset} />
          {withLinks && (
            <Links>
              <I18n
                href={paths.signIn}
                tag={Link}
                text="auth.rememberPassword"
                to={paths.signIn}
              />
            </Links>
          )}
        </>
      )}
    </Panel>
  )
}

ResetPasswordScreen.defaultProps = {
  initialValues: {},
  paths: null,
  resetAction: resetPassword,
  withHeader: false,
  withLinks: false,
}
ResetPasswordScreen.propTypes = {
  initialValues: PropTypes.object,
  paths: PropTypes.object,
  resetAction: PropTypes.func,
  withHeader: PropTypes.bool,
  withLinks: PropTypes.bool,
}
