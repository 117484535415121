import { createAsyncAction } from '../../../helpers'
import {
  generateCreateUser,
  generateDeleteUser,
  generateLoadUserAction,
  generateLoadUsersAction,
  generateUpdateUser,
} from './helpers'

export const LOAD_CHILD = createAsyncAction('users/LOAD_CHILD')
export const LOAD_CHILDREN = createAsyncAction('users/LOAD_CHILDREN')
export const CREATE_CHILD = createAsyncAction('users/CREATE_CHILD')
export const UPDATE_CHILD = createAsyncAction('users/UPDATE_CHILD')
export const DELETE_CHILD = createAsyncAction('users/DELETE_CHILD')

export const loadChildUsers = generateLoadUsersAction(
  LOAD_CHILDREN,
  'home_student',
)
export const loadChildUser = generateLoadUserAction(LOAD_CHILD)
export const createChild = generateCreateUser({ types: CREATE_CHILD })
export const updateChild = generateUpdateUser({
  types: UPDATE_CHILD,
  endpoint: '/users',
})
export const deleteChild = generateDeleteUser({ types: DELETE_CHILD })

export const updateChildUser = updateChild

export const requestCreateChild = (user, relationships) => ({
  type: CREATE_CHILD.REQUEST_PERFORM,
  payload: { user, relationships },
})

export const requestUpdateChild = user => ({
  type: UPDATE_CHILD.REQUEST_PERFORM,
  payload: { user },
})
