import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import { GYRO_TOGGLE } from '../../Actions/ui'

const initialState = {
  enabled: false,
}

const toggle = state => state.update('enabled', enabled => !enabled)
const handlers = {
  [GYRO_TOGGLE]: toggle,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
