import { useAnimation } from 'framer-motion'
import PT from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  CLOSE_TASK_COMPLETE,
  OPEN_TASK_COMPLETE,
} from '../../../../Store/Actions/questTracker'
import { quest } from '../../../../Store/Actions/ui'
import { getCurrentTask } from '../../../../Store/Selectors/questTracker'
import ContainerTag from './Container'

const TaskContainer = ({ animationComplete, ...props }) => {
  const task = useSelector(getCurrentTask)
  const dispatch = useDispatch()

  const containerControls = useAnimation()

  const containerAnimation = {
    animate: containerControls,
    initial: { y: '100%', display: 'none' },
  }

  const animateIn = () => {
    // reduced Unity frame rate, not a complete stop
    dispatch(quest.reduceWebGL())
    containerControls
      .start({
        y: '0%',
        display: 'block',
        transition: {
          ease: 'easeOut',
          duration: 0,
          type: 'spring',
          stiffness: 150,
          bounceStiffness: 1,
          damping: 15,
          min: 0,
          max: 5,
        },
      })
      .then(() => {
        dispatch(quest.resumeWebGL())
        dispatch({ type: OPEN_TASK_COMPLETE })
      })
  }

  const animateOut = () => {
    dispatch(quest.reduceWebGL())
    containerControls
      .start({
        y: '100%',
        transition: { ease: 'easeIn', duration: 0.15 },
        transitionEnd: {
          display: 'none',
        },
      })
      .then(() => {
        dispatch(quest.resumeWebGL())
        dispatch({ type: CLOSE_TASK_COMPLETE })
      })
  }

  useEffect(() => {
    if (task?.open && animationComplete) {
      animateIn()
    } else if (!task?.open) {
      animateOut()
    }
  }, [task?.open, animationComplete])

  if (!task) return null

  return <ContainerTag animation={containerAnimation} task={task} {...props} />
}

TaskContainer.propTypes = {
  animationComplete: PT.bool.isRequired,
}

export default TaskContainer
