import { appName } from '@lyfta/components-config'
import { getJWT } from '@lyfta/components-data/src/Store/Selectors/persist'
import { getViewer } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { I18n, Translation } from '@lyfta/components-i18n'
import { filter, map } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import {
  DoubleArrowLeft as doubleArrowLeft,
  DoubleArrowRight as doubleArrowRight,
} from '../../../Assets/Svg'
import { Icon } from '../../../Components/Icon'
import { USER_TYPES } from '../../../Constants/users'
import { withAppContext } from '../../../Services'
import { findRole, isTrialing } from '../../../Services/Utils'
import { HelpIntercom } from './helpIntercom'
import { SidebarLabel } from './label'
// import { LogoutItem } from './logout'
import { OpenInStudentApp } from './openInStudentApp'
import {
  BottomMenuBar,
  Container,
  Content,
  Label,
  Menu,
  MenuBar,
  TopMenuBar,
} from './styles'
import { TutorialLink } from './tutorial'
import { useBreakpointAnimation } from './useBreakpointAnimation'

const Sidebar = ({
  open: isOpen,
  breakpoint,
  closeSidebar,
  routes,
  staticContent,
  onDrawerToggle,
}) => {
  const jwt = useSelector(getJWT)
  const { licenseType, type, organizationAdmin, permissions } = useSelector(
    getViewer,
  )
  const [variants, animationState] = useBreakpointAnimation(
    breakpoint,
    closeSidebar,
    isOpen,
  )
  const CustomSidebar = staticContent
  return (
    <Container
      animate={animationState}
      id="div-left-sidebar-navigation"
      layout
      open={isOpen}
      variants={variants}
    >
      <Content>
        <Translation>
          {t => (
            <MenuBar>
              <TopMenuBar>
                <Menu>
                  {staticContent && <CustomSidebar jwt={jwt} t={t} />}
                  {!staticContent &&
                    map(
                      filter(routes, r => !r.excludeFromMenu),
                      (route, index) => {
                        const roleFound = findRole(route?.roles, type)

                        const allowNewFeaturesRoute =
                          route.newFeatures === permissions?.newFeatures ||
                          route.newFeatures === undefined

                        if (
                          !allowNewFeaturesRoute ||
                          !roleFound ||
                          (route.onlyOrganizationAdmin && !organizationAdmin)
                        ) {
                          return null
                        }

                        return (
                          <SidebarLabel
                            isOpen={isOpen}
                            isTrialing={isTrialing(licenseType)}
                            key={`sidebar-${index}`}
                            route={route}
                          />
                        )
                      },
                    )}

                  {type !== USER_TYPES.parents && (
                    <OpenInStudentApp isOpen={isOpen} t={t} />
                  )}
                  {appName === '@lyfta/web-teacher' &&
                    type !== USER_TYPES.parents && (
                      <TutorialLink isOpen={isOpen} t={t} />
                    )}
                  {/* <LogoutItem t={t} /> */}
                  {type === USER_TYPES.parents && <HelpIntercom t={t} />}
                </Menu>
              </TopMenuBar>

              <BottomMenuBar
                isOpen={isOpen}
                tabIndex={0}
                onClick={onDrawerToggle}
                onKeyDown={e => (e.keyCode === 13 ? onDrawerToggle() : null)}
              >
                {' '}
                <Icon
                  fill="white"
                  glyph={isOpen ? doubleArrowLeft : doubleArrowRight}
                  height={24}
                  width={24}
                />
                {isOpen === true ? (
                  <Label>
                    <I18n text="actions.collapseMenu" />
                  </Label>
                ) : null}
              </BottomMenuBar>
            </MenuBar>
          )}
        </Translation>
      </Content>
    </Container>
  )
}

Sidebar.defaultProps = {
  staticContent: null,
  onDrawerToggle: () => {},
}

Sidebar.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  routes: PropTypes.array.isRequired,
  staticContent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
  onDrawerToggle: PropTypes.func,
}

export default withAppContext(Sidebar)
