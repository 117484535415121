import { I18n, TransLink, getTranslation } from '@lyfta/components-i18n'
import get from 'lodash/get'
import Papa from 'papaparse'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useRouteMatch, withRouter } from 'react-router'

import { InlineErrorMessages } from '../../Blocks/Form/inlineErrorMessages'
import Dropzone from '../Dropzone'
import { ModalButtons } from '../Modal/ModalButtons'
import Title from '../Title'
import {
  ButtonContainer,
  Container,
  Content,
  InformationIcon,
  InstructionBlock,
  InstructionMessage,
  InstructionMessageContainer,
  SubTitleContainer,
  UploadBox,
} from './styles'
import UploadFile from './UploadFile'
import { validateCsv } from './validateCsv'

const instructions = ['fileType', 'thousandLimit', 'ignoreDuplicates']
// header should be consistent with relevant template and with the format
// described in app/actions/api/v1/users/imports/create_action.rb of lyfta-api
const headerFormat = {
  admins: 'first_name,last_name,email',
  teachers: 'first_name,last_name,email',
  students: 'first_name,last_name,class_name',
}

const UploadFileContainer = ({
  remainSeatsAvailable,
  setModalOpen,
  templateCSV,
  uploading,
  userRole,
  onUploadFile,
}) => {
  const [chosenFile, setChosenFile] = useState(null)
  const [okDisabled, setOkDisabled] = useState(true)
  const [customErrors, setCustomErrors] = useState([])
  const handleCancel = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    if (chosenFile && customErrors.length < 1) {
      setOkDisabled(false)
    } else {
      setOkDisabled(true)
    }
  }, [chosenFile, customErrors, uploading])
  const match = useRouteMatch()
  const handleDrop = files => {
    const file = files[0]
    setCustomErrors([])
    setChosenFile(file)
    const SUPPORTED_FORMATS = [
      'application/vnd.ms-excel',
      'text/csv',
      'text/plain',
    ]

    if (
      SUPPORTED_FORMATS.includes(file?.type) ||
      file?.name?.includes('.csv') ||
      file?.name?.includes('.txt') ||
      file?.name?.includes('.xsl') ||
      file?.name?.includes('.xslx')
    ) {
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: results => {
          const { data } = results
          validateCsv(
            data,
            headerFormat,
            userRole,
            setCustomErrors,
            remainSeatsAvailable,
          )
        },
      })
    } else {
      setCustomErrors(previousValues => [
        ...previousValues,
        <I18n text="files.upload.notSupported" />,
      ])
    }
  }
  const handleUpload = () => {
    const id = get(match, 'params.organizationId')
    if (chosenFile) {
      onUploadFile('/users/imports', chosenFile, 'file', userRole, {
        organization_id: parseInt(id, 10) || 'current',
      }).then(data => {
        if (data.error) {
          if (data.data.statusCode === 422) {
            setCustomErrors(() => [<I18n text="files.upload.notSupported" />])
          }
        } else {
          handleCancel()
        }
      })
    }
  }

  return (
    <Container>
      <Title>
        <I18n
          text="files.upload.bulkUpload"
          options={{
            userRole: getTranslation(`core.models.${userRole}`, {
              count: 0,
            }).toLowerCase(),
          }}
        />
      </Title>
      <SubTitleContainer>
        <TransLink
          components={{
            /* eslint-disable jsx-a11y/anchor-has-content */
            Link: (
              <a
                aria-label={getTranslation('files.downloadTemplateCsv')}
                href={templateCSV}
              />
            ),
            /* eslint-enable jsx-a11y/anchor-has-content */
          }}
          values={{
            userRole: getTranslation(`core.models.${userRole}`, {
              count: 0,
            }).toLowerCase(),
            context: templateCSV ? 'withTemplate' : null,
          }}
          i18nKey="files.upload.csvBulk"
        />
      </SubTitleContainer>
      <Content>
        <Dropzone
          accept="text/csv"
          // accept="text/plain,application/vnd.ms-excel,text/csv"
          multiple={false}
          id="dropZone"
          onDrop={handleDrop}
        />
      </Content>
      {!customErrors.length > 0 && !chosenFile && (
        <InstructionBlock>
          <InformationIcon />
          <InstructionMessageContainer>
            {instructions.map(instruction => {
              return (
                <InstructionMessage key={`instruction-${instruction}`}>
                  <TransLink
                    components={{
                      italic: <i />,
                    }}
                    values={{
                      headerFormat: headerFormat[userRole],
                    }}
                    i18nKey={`files.upload.instructions.${instruction}`}
                  />
                </InstructionMessage>
              )
            })}
          </InstructionMessageContainer>
        </InstructionBlock>
      )}
      {!customErrors.length > 0 && chosenFile && (
        <UploadBox>
          <UploadFile
            key={chosenFile.name}
            name={chosenFile.name}
            uploading={uploading}
          />
        </UploadBox>
      )}
      {customErrors.length > 0 && (
        <InlineErrorMessages
          customErrors={customErrors}
          showSeparateErrorBlocks={false}
        />
      )}
      <ButtonContainer>
        <ModalButtons
          okText={<I18n text="actions.save" />}
          showButtons
          handleCancel={handleCancel}
          handleOk={handleUpload}
          okDisabled={okDisabled}
        />
      </ButtonContainer>
    </Container>
  )
}

UploadFileContainer.defaultProps = {
  remainSeatsAvailable: undefined,
}

UploadFileContainer.propTypes = {
  remainSeatsAvailable: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  setModalOpen: PropTypes.func.isRequired,
  templateCSV: PropTypes.string.isRequired,
  uploading: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  onUploadFile: PropTypes.func.isRequired,
}

export default withRouter(UploadFileContainer)
