import { intercomAppId } from '@lyfta/components-config'
import { CoreStore } from '@lyfta/components-data'
import { I18nProvider } from '@lyfta/components-i18n'
import { withTheme } from '@lyfta/components-theme'
import PropTypes from 'prop-types'
import React from 'react'
import Intercom from 'react-intercom'
import { useSelector } from 'react-redux'

import { useGoogleAnalytics, useIntercom, useResizeHandler } from '../../Hooks'
import { useBingTracking } from '../../Hooks/useBingTracking'
import { useHubSpotAnalytics } from '../../Hooks/useHubSpotAnalytics'
import { useQSToken } from '../../Hooks/useQSToken'
import { useTwitter } from '../../Hooks/useTwitter'
import { AppContext } from '../../Services/AppContext'
import { StyledToast } from './styles'

const AppBaseContainer = ({
  bingTrackingId,
  children,
  googleAnalyticsId,
  history,
  withIntercom,
  withAnalytics,
  withHubspot,
  withBingTracking,
  theme: { breakpoints },
}) => {
  const isAuthenticated = useSelector(
    CoreStore.Selectors.Viewer.getIsAuthenticated,
  )
  const viewer = useSelector(CoreStore.Selectors.Viewer.getViewer)
  const [intercomUser, hideIntercomOnMobile] = useIntercom(withIntercom)
  const breakpoint = useResizeHandler(breakpoints, hideIntercomOnMobile)
  const hubSpotAnalyticsCode = useHubSpotAnalytics(withHubspot)
  const bingTrackingCode = useBingTracking(withBingTracking, bingTrackingId)
  const twitterCode = useTwitter()

  useGoogleAnalytics(withAnalytics, googleAnalyticsId, viewer)
  useQSToken(history)

  return (
    <I18nProvider>
      <AppContext.Provider
        value={{
          breakpoint,
          history,
          viewer,
          isAuthenticated,
        }}
      >
        {hubSpotAnalyticsCode}
        {bingTrackingCode}
        {twitterCode}
        {children}
        <StyledToast />
        {intercomUser && <Intercom appID={intercomAppId} {...intercomUser} />}
      </AppContext.Provider>
    </I18nProvider>
  )
}

AppBaseContainer.defaultProps = {
  bingTrackingId: null,
  googleAnalyticsId: null,
  withAnalytics: false,
  withBingTracking: false,
  withIntercom: false,
  withHubspot: false,
}
AppBaseContainer.propTypes = {
  bingTrackingId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  googleAnalyticsId: PropTypes.string,
  history: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  withAnalytics: PropTypes.bool,
  withBingTracking: PropTypes.bool,
  withHubspot: PropTypes.bool,
  withIntercom: PropTypes.bool,
}
export const AppContainer = withTheme(AppBaseContainer)
