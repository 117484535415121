import { styled, themeGet } from '@lyfta/components-theme'

import { ArrowLeft, ArrowRight, Tick } from '../../../Assets'
import { Box } from '../../Box'
import { Flex } from '../../Flex'

export const NextPageButton = styled.div`
  align-items: center;
  border: 2px solid transparent;
  border-radius: 2px;
  color: hsla(0, 0%, 100%, 1);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: space-between;
  line-height: 20px;
  letter-spacing: 1.5px;
  margin: 6px;
  padding: 16px;
  user-select: none;

  &:hover {
    background: linear-gradient(
        0deg,
        ${themeGet('colors.black_700')},
        ${themeGet('colors.black_700')}
      ),
      linear-gradient(
        0deg,
        ${themeGet('colors.neutral_100')},
        ${themeGet('colors.neutral_100')}
      );
    border: 2px solid ${themeGet('colors.neutral_100')};
  }
`

export const MenuPageContainer = styled.div`
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* @keyframes slideinfromright {
    from {
      left: 100%;
      position: relative;
    }
    to {
      left: 0%;
      position: unset;
    }
  } */

  /* animation:
    fadein 0.25s,
    slideinfromright 0.5s; */
  /* animation:
    fadein 0.25s; */
  background-color: ${themeGet('colors.black_800')};
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  font-family: 'Walsheim', 'Helvetica Neue', Arial, Helvetica, sans-serif;

  height: 100%;
  overflow-y: auto;

  scrollbar-color: ${themeGet('colors.neutral_100')};
  scrollbar-width: thin;

  width: 280px;
`

export const MenuPageTitle = styled.div`
  border-bottom: 1px solid hsla(216, 5%, 43%, 0.5);
  font-size: 19px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.5px;
  padding: 16px;
  user-select: none;
`

export const SelectedIcon = styled(Tick)`
  & > path {
    fill: ${themeGet('colors.neutral_100')};
  }
`

export const SelectedIconContainer = styled.div`
  /* background-color: rgba(50, 50, 50, 0.2); */
  /* color: white; */
  height: 24px;
  margin: 14px 16px 14px 11px;
  width: 24px;
`

// color: ${props => props.selected ? 'red' : 'green'};

export const SubtitleButton = styled(Flex)`
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  user-select: none;
`

export const SubtitleButtonContainer = styled.div`
  border: 2px solid transparent;
  border-radius: 2px;
  cursor: pointer;
  display: flex;

  &:hover {
    background: linear-gradient(
        0deg,
        ${themeGet('colors.black_700')},
        ${themeGet('colors.black_700')}
      ),
      linear-gradient(
        0deg,
        ${themeGet('colors.neutral_100')},
        ${themeGet('colors.neutral_100')}
      );
    border: 2px solid ${themeGet('colors.neutral_100')};
  }
`

export const ControlsContainer = styled(Box)`
  border-bottom: 1px solid hsla(216, 5%, 43%, 0.5);
  color: ${themeGet('colors.neutral_100')};
  /* display: flex; */
  padding: 6px;
`

export const ResetButton = styled.div`
  border: 2px solid ${themeGet('colors.neutral_100')};
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.5px;
  padding: 8px 20px;
  /* text-align: center; */
  user-select: none;

  &:hover {
    background: linear-gradient(
        0deg,
        ${themeGet('colors.black_700')},
        ${themeGet('colors.black_700')}
      ),
      linear-gradient(
        0deg,
        ${themeGet('colors.neutral_100')},
        ${themeGet('colors.neutral_100')}
      );
    border: 2px solid ${themeGet('colors.neutral_100')};
  }
`

export const ResetButtonContainer = styled.div`
  padding: 16px 0px;
  text-align: center;
`

export const PageRightIconContainer = styled(ArrowRight)`
  /* height: 24px;
  width: 24px; */
  margin-left: 16px;

  & > path {
    fill: ${themeGet('colors.neutral_100')};
  }
`

export const PageBackIconContainer = styled(ArrowLeft)`
  margin-right: 16px;

  & > path {
    fill: ${themeGet('colors.neutral_100')};
  }
`

export const PrevPageButton = styled(NextPageButton)`
  justify-content: left;
`

export const PrevPageButtonContainer = styled.div`
  border-bottom: 1px solid hsla(216, 5%, 43%, 0.5);
`

export const SettingsContainer = styled.div`
  padding: 24px;
`

export const ColourSelectorButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-width: 40px;
  max-width: 40px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
`

export const ColourSelectorSwatch = styled.div`
  background-color: ${props => themeGet(`colors.${props.themeColourName}`)};
  border: ${props =>
    props.selected
      ? `1px solid ${themeGet('colors.black_800')(props)}`
      : `1px solid ${themeGet('colors.neutral_100')(props)}`};
  border-radius: 12px;
  outline: ${props =>
    props.selected
      ? `2px solid ${themeGet('colors.neutral_100')(props)}`
      : 'none'};
  height: 24px;
  width: 24px;
`

export const SettingSelectorContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 8px;
`

export const Body1Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
`

export const SizeSelectorButton = styled.div`
  background-color: ${props =>
    props.selected
      ? themeGet('colors.black_300')(props)
      : themeGet('colors.black_600')(props)};
  border-radius: 2px;
  color: ${props =>
    props.selected
      ? themeGet('colors.black_800')(props)
      : themeGet('colors.neutral_100')(props)};
  min-width: 40px;
  max-width: 40px;
  text-align: center;
  font-size: 13px; // Body 5
  font-weight: 400; // Body 5
  letter-spacing: 0.5px; // Body 5
  line-height: 20px; // Body 5
  cursor: pointer;

  padding: 6px 4px;
`

export const SelectorButton = styled.div`
  background-color: ${props =>
    props.selected
      ? themeGet('colors.black_300')(props)
      : themeGet('colors.black_600')(props)};
  border-radius: 2px;
  color: ${props =>
    props.selected
      ? themeGet('colors.black_800')(props)
      : themeGet('colors.neutral_100')(props)};
  min-width: 40px;
  width: 100%;
  /* max-width: 40px; */
  height: 100%;
  text-align: center;
  font-size: 13px; // Body 5
  font-weight: 400; // Body 5
  letter-spacing: 0.5px; // Body 5
  line-height: 20px; // Body 5
  cursor: pointer;

  padding: 6px 4px;
`
