import { styled } from '@lyfta/components-theme'

import { Flex } from '../../Components'

export const Container = styled(Flex)`
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`
export const TabContainer = styled(Flex)`
  flex-direction: row;
`
export const ContentContainer = styled(Flex)`
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
`
