import { styled } from '@lyfta/components-theme'
import { AnimatedAbsoluteFlex } from '@lyfta/components-ui'

export const Container = styled(AnimatedAbsoluteFlex).attrs({
  top: 'auto',
  mx: 'auto',
  pt: 7,
})`
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  pointer-events: none;
  width: min(70vw, 1200px);
`
