export const ADD_CONTENT_SHOW = 'ui/ADD_CONTENT_SHOW'
export const ADD_CONTENT_HIDE = 'ui/ADD_CONTENT_HIDE'

export const DRAWER_SHOW = 'ui/DRAWER_SHOW'
export const DRAWER_SHOW_COMPLETE = 'ui/DRAWER_SHOW_COMPLETE'
export const DRAWER_HIDE = 'ui/DRAWER_HIDE'
export const DRAWER_HIDE_COMPLETE = 'ui/DRAWER_HIDE_COMPLETE'
export const DRAWER_TOGGLE = 'ui/DRAWER_TOGGLE'

export const MENU_SHOW = 'ui/MENU_SHOW'
export const MENU_HIDE = 'ui/MENU_HIDE'
export const MENU_TOGGLE = 'ui/MENU_TOGGLE'
export const MENU_TOGGLE_SETTINGS = 'ui/MENU_TOGGLE_SETTINGS'

export const MUTED_MUTE = 'ui/MUTED_MUTE'
export const MUTED_UNMUTE = 'ui/MUTED_UNMUTE'
export const MUTED_TOGGLE = 'ui/MUTED_TOGGLE'
export const MUTED_TEMPORARY_UNMUTE_STARTED =
  'ui/MUTED_TEMPORARY_UNMUTE_STARTED'
export const MUTED_TEMPORARY_UNMUTE_ENDED = 'ui/MUTED_TEMPORARY_UNMUTE_ENDED'
export const MUTED_MUTED_DURING_TEMP_UNMUTE =
  'ui/MUTED_MUTED_DURING_TEMP_UNMUTE'
export const MUTED_MUTED_DURING_TEMP_UNMUTE_HANDLED =
  'ui/MUTED_MUTED_DURING_TEMP_UNMUTE_HANDLED'

export const ROTATION_TOGGLE = 'ui/ROTATION_TOGGLE'
export const GYRO_TOGGLE = 'ui/GYRO_TOGGLE'
export const ROTATION_STARTED = 'ui/ROTATION_STARTED'
export const ROTATION_ENDED = 'ui/ROTATION_ENDED'

export const PASSPHRASE_SET = 'ui/PASSPHRASE_SET'

export const QUEST_CONTENT_FIND = 'ui/QUEST_CONTENT_FIND'
export const QUEST_HELP_FIND = 'ui/QUEST_HELP_FIND'
export const QUEST_HELP_FOUND = 'ui/QUEST_HELP_FOUND'
export const QUEST_SELECT = 'ui/QUEST_SELECT'
export const QUEST_SHOW = 'ui/QUEST_SHOW'
export const QUEST_HIDE = 'ui/QUEST_HIDE'
export const QUEST_SHOW_RICH_MEDIA = 'ui/QUEST_SHOW_RICH_MEDIA'
export const QUEST_HIDE_RICH_MEDIA = 'ui/QUEST_HIDE_RICH_MEDIA'
export const QUEST_REQUEST_LESSONS_POPUP_OPEN =
  'ui/QUEST_REQUEST_LESSONS_POPUP_OPEN'
export const QUEST_HANDLE_LESSONS_POPUP_OPEN =
  'ui/QUEST_HANDLE_LESSONS_POPUP_OPEN'

export const UNITY_PAUSE = 'ui/UNITY_PAUSE'
export const UNITY_RESUME = 'ui/UNITY_RESUME'
export const UNITY_REDUCE = 'ui/UNITY_REDUCE'
export const SPECIAL_LOADING = 'ui/SPECIAL_LOADING'

export const FULLSCREEN_ON = 'ui/FULLSCREEN_ON'
export const FULLSCREEN_OFF = 'ui/FULLSCREEN_OFF'
export const FULLSCREEN_TOGGLE = 'ui/FULLSCREEN_TOGGLE'

export const ZOOM_IN_PRESSED = 'ui/ZOOM_IN_PRESSED'
export const ZOOM_OUT_PRESSED = 'ui/ZOOM_OUT_PRESSED'
export const ZOOM_HANDLED = 'ui/ZOOM_HANDLED'

export const BACK_SHOW = 'ui/BACK_SHOW'
export const BACK_HIDE = 'ui/BACK_HIDE'
export const BACK_TOGGLE = 'ui/BACK_TOGGLE'
export const BACK_HIGHLIGHT = 'ui/BACK_HIGHLIGHT'

export const PREVIEW_SHOW = 'ui/ADD_CONTENT_SHOW'
export const PREVIEW_HIDE = 'ui/ADD_CONTENT_HIDE'
export const PREVIEW_LESSON_PLAN_SHOW = 'ui/PREVIEW_LESSON_PLAN_SHOW'
export const PREVIEW_LESSON_PLAN_HIDE = 'ui/PREVIEW_LESSON_PLAN_HIDE'

export const STORYWORLD_TAB = 'ui/STORYWORLD_TAB'

export const OVERLAY_OPEN = 'ui/ARTICLE_OPEN'
export const OVERLAY_CLOSE = 'ui/ARTICLE_CLOSE'

export const IS_IN_SCENE = 'ui/IS_IN_SCENE'
export const IS_NOT_IN_SCENE = 'ui/IS_NOT_IN_SCENE'

export const drawer = {
  show: () => ({ type: DRAWER_SHOW }),
  hide: () => ({ type: DRAWER_HIDE }),
  toggle: () => ({ type: DRAWER_TOGGLE }),
}

export const menu = {
  show: () => ({ type: MENU_SHOW }),
  hide: () => ({ type: MENU_HIDE }),
  toggle: () => ({ type: MENU_TOGGLE }),
  toggleSettings: () => ({ type: MENU_TOGGLE_SETTINGS }),
}

export const muted = {
  mute: () => ({ type: MUTED_MUTE }),
  unmute: () => ({ type: MUTED_UNMUTE }),
  toggle: () => ({ type: MUTED_TOGGLE }),
  temporaryUnmuteStarted: () => ({ type: MUTED_TEMPORARY_UNMUTE_STARTED }),
  temporaryUnmuteEnded: () => ({ type: MUTED_TEMPORARY_UNMUTE_ENDED }),
  mutedDuringTemporaryUnmute: () => ({ type: MUTED_MUTED_DURING_TEMP_UNMUTE }),
  mutedDuringTemporaryUnmuteHandled: () => ({
    type: MUTED_MUTED_DURING_TEMP_UNMUTE_HANDLED,
  }),
}

export const rotation = {
  toggle: () => ({ type: ROTATION_TOGGLE }),
  rotationStarted: () => ({ type: ROTATION_STARTED }),
  rotationEnded: () => ({ type: ROTATION_ENDED }),
}

export const gyro = {
  toggle: () => ({ type: GYRO_TOGGLE }),
}

export const quest = {
  findContentId: contentId => ({ type: QUEST_CONTENT_FIND, contentId }),
  helpMeFind: contentId => ({ type: QUEST_HELP_FIND, contentId }),
  helpMeFound: contentId => ({ type: QUEST_HELP_FOUND, contentId }),
  questSelect: lessonId => ({ type: QUEST_SELECT, lessonId }),
  show: () => ({ type: QUEST_SHOW }),
  hide: () => ({ type: QUEST_HIDE }),
  showRichMedia: () => ({ type: QUEST_SHOW_RICH_MEDIA }),
  hideRichMedia: () => ({ type: QUEST_HIDE_RICH_MEDIA }),
  pauseWebGL: () => ({ type: UNITY_PAUSE }),
  resumeWebGL: () => ({ type: UNITY_RESUME }),
  reduceWebGL: () => ({ type: UNITY_REDUCE }),
  toggleSpecialLoading: value => ({ type: SPECIAL_LOADING, value }),
  requestLessonsPopupOpen: () => ({ type: QUEST_REQUEST_LESSONS_POPUP_OPEN }),
  handleLessonsPopupOpen: () => ({ type: QUEST_HANDLE_LESSONS_POPUP_OPEN }),
}

export const passphrase = {
  storePassphrase: phrase => ({
    type: PASSPHRASE_SET,
    passphrase: phrase,
  }),
}

export const back = {
  show: () => ({ type: BACK_SHOW }),
  hide: () => ({ type: BACK_HIDE }),
  toggle: () => ({ type: BACK_TOGGLE }),
  highlight: value => ({ type: BACK_HIGHLIGHT, value }),
}

export const zoom = {
  inPressed: () => ({ type: ZOOM_IN_PRESSED }),
  outPressed: () => ({ type: ZOOM_OUT_PRESSED }),
  handled: () => ({ type: ZOOM_HANDLED }),
}

export const fullscreen = {
  on: () => ({ type: FULLSCREEN_ON }),
  off: () => ({ type: FULLSCREEN_OFF }),
  toggle: () => ({ type: FULLSCREEN_TOGGLE }),
}

export const preview = {
  show: id => ({ type: PREVIEW_SHOW, id }),
  hide: () => ({ type: PREVIEW_HIDE }),
  showLessonPlan: id => ({ type: PREVIEW_LESSON_PLAN_SHOW, id }),
  hideLessonPlan: () => ({ type: PREVIEW_LESSON_PLAN_HIDE }),
}

export const storyworld = {
  tab: index => ({ type: STORYWORLD_TAB, index }),
}

export const overlay = {
  show: () => ({ type: OVERLAY_OPEN }),
  hide: () => ({ type: OVERLAY_CLOSE }),
}

export const scene = {
  isInScene: () => ({ type: IS_IN_SCENE }),
  isNotInScene: () => ({ type: IS_NOT_IN_SCENE }),
}
