import { useEffect } from 'react'

/**
 *
 * @param {EventTarget} eventTarget
 * @param {string} eventName
 * @param {(...params: any[]) => void} callback
 * @returns {() => void} the cleanup function.
 */
export const useListener = (eventTarget, eventName, callback) => {
  const wantedCallback = callback

  useEffect(() => {
    if (!eventTarget || !wantedCallback) {
      return undefined
    }

    eventTarget.addEventListener(eventName, wantedCallback)

    return () => {
      eventTarget.removeEventListener(eventName, wantedCallback)
    }
  }, [eventTarget, eventName, wantedCallback])
}
