import * as React from 'react'

function SvgCreate(props) {
  return (
    <svg
      id="svg-create"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M14.2731 21.9856C17.0178 21.9856 19.8518 20.5311 19.8518 18.0995C19.8518 16.756 18.3889 15.4492 17.1951 14.7497L15.6638 16.2735C16.5006 16.6399 17.6203 17.5777 17.6203 18.0995C17.6203 18.9544 15.9913 19.765 14.2731 19.765C13.6595 19.765 13.1574 20.2646 13.1574 20.8753C13.1574 21.486 13.6595 21.9856 14.2731 21.9856Z"
        fill="black"
      />
      <path
        d="M2 10.8825C2 12.0594 2.26905 12.3864 3.35132 13.0082L4.72632 11.6399C4.16134 11.4115 4.18061 11.2858 4.21329 11.0728C4.22192 11.0166 4.23147 10.9543 4.23147 10.8825C4.23147 10.3273 5.74003 9.4207 6.78882 8.96547C8.66382 7.93923 9.41382 7.44167 9.88257 5.33093C9.88257 3.46561 8.9622 2 6.46295 2C3.9637 2 2.81449 3.37679 2.69176 3.53223C2.31241 4.00966 2.40167 4.68695 2.85912 5.06446C3.32773 5.44196 4.03064 5.38645 4.42115 4.93122C4.42115 4.93122 4.42389 4.92842 4.42614 4.92606C4.49414 4.85511 5.10226 4.22062 6.46295 4.22062C7.82415 4.22062 7.91382 4.8757 7.91382 5.33093C7.91382 5.95271 7.12123 6.6411 5.97202 7.15184C4.10874 7.96237 2 8.88393 2 10.8825Z"
        fill="black"
      />
      <path
        d="M21.3783 7.68384C22.2683 6.79575 22.1733 5.23722 21.2832 4.28569L19.7574 2.76324C18.8674 1.87514 17.4051 1.87514 16.4515 2.76324L14.5664 4.64587L19.4932 9.56647L21.3783 7.68384Z"
        fill="black"
      />
      <path
        d="M3.29214 15.8952L2.0206 21.4775C1.92136 21.7601 2.19526 22.0709 2.52921 21.985L8.22513 20.822L18.084 10.9756L13.151 6.04883L3.29214 15.8952Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgCreate
