import * as React from 'react'

function SvgNotificationPlus(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3108 9.7204C15.0827 9.77274 14.8444 9.80008 14.5999 9.80008C12.8327 9.80008 11.3999 8.36726 11.3999 6.60008C11.3999 5.79696 11.696 5.06258 12.185 4.50088C12.0304 4.42901 11.871 4.36417 11.7085 4.30635C11.66 3.35557 10.8741 2.60011 9.91082 2.60011C8.94832 2.60011 8.16238 3.35557 8.11394 4.30635C6.01474 5.04697 4.51074 7.04775 4.51074 9.40015V11.3252C4.51074 12.1611 4.29278 12.9814 3.8787 13.7072L3.25292 14.8017C3.10058 15.0681 3.29355 15.4001 3.60058 15.4001H16.2216C16.5286 15.4001 16.7216 15.0681 16.5692 14.8017L15.9435 13.7072C15.5294 12.9814 15.3106 12.1611 15.3106 11.3252L15.3108 9.7204ZM11.9108 15.8C11.9108 16.7938 11.1054 17.6 10.1108 17.6H9.71082C8.71706 17.6 7.91082 16.7938 7.91082 15.8H11.9108ZM17.1998 6.6C17.1998 8.03594 16.0358 9.2 14.5998 9.2C13.1639 9.2 11.9998 8.03594 11.9998 6.6C11.9998 5.16406 13.1639 4 14.5998 4C16.0358 4 17.1998 5.16406 17.1998 6.6ZM14.8998 5.6C14.8998 5.43437 14.7654 5.3 14.5998 5.3C14.4342 5.3 14.2998 5.43437 14.2998 5.6V6.3H13.5998C13.4342 6.3 13.2998 6.43437 13.2998 6.6C13.2998 6.76562 13.4342 6.9 13.5998 6.9H14.2998V7.6C14.2998 7.76562 14.4342 7.9 14.5998 7.9C14.7654 7.9 14.8998 7.76562 14.8998 7.6V6.9H15.5998C15.7654 6.9 15.8998 6.76562 15.8998 6.6C15.8998 6.43437 15.7654 6.3 15.5998 6.3H14.8998V5.6Z"
        fill="#2E34CE"
      />
    </svg>
  )
}

export default SvgNotificationPlus
