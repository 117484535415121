import { space, styled, themeGet as theme } from '@lyfta/components-theme'
import {
  Title as BaseTitle,
  Box,
  ButtonNew,
  Flex,
  Title3,
} from '@lyfta/components-ui'
import { NavLink } from 'react-router-dom'

export const Title = styled(BaseTitle)`
  margin-top: 16px;
  margin-bottom: 32px;
  text-transform: initial;
`

export const LocaleWrapper = styled(Flex)`
  position: absolute;
  top: 10px;
  right: 10px;
`

/* Note: i added the last 2 styles to make the dialog appear */
export const Container = styled(Flex)`
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-grow: 1;
  flex-shrink: 0;
  z-index: 1;
  position: relative;
`

export const ContainerStudents = styled(Flex)`
  background-color: ${theme('colors.black_900')};
  width: 100%;
`

export const Link = styled(NavLink)`
  font-size: 14px;
  padding-top: 2px;
  text-align: center;
  display: block;
  color: ${theme('colors.font.tertiary')};
`

export const Panel = styled(Flex).attrs(() => ({
  mx: [2, 3],
  pt: [2, 4],
  maxWidth: [325, 480],
}))`
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: stretch;
  padding-top: 0;
  > form {
    display: flex;
    flex-direction: column;
  }
`

export const StudentBlock = styled(Flex).attrs({
  p: '60px',
})`
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  gap: ${theme('space.9')};
`

export const SelectionInstructions = styled(Title3)`
  color: ${theme('colors.neutral_100')};
  display: flex;
`

export const Buttons = styled(Flex).attrs({
  p: [0, 5, 5],
})`
  flex-direction: row;
  flex-wrap: wrap;
`

export const NameContainer = styled(Flex)`
  column-gap: ${theme('space.4')};
  row-gap: ${theme('space.5')};
  flex-wrap: wrap;
  justify-content: center;
  max-width: 80vw;
  align-items: center;
`

export const StudentNameButton = styled(ButtonNew)`
  font-size: max(23px, 1vw);
  line-height: 28px;
`

export const FormBlock = styled.form.attrs({
  px: [3, 0, 0],
  pt: [4, 0, 0],
})`
  text-align: center;
  width: 100%;

  ${space};

  button {
    margin: auto;
  }
`

export const ButtonContainer = styled(Flex).attrs({
  mb: 2,
  flexDirection: 'column',
  width: '100%',
  textAlign: 'center',
})``

export const InfoBlock = styled(Box).attrs({
  mb: 2,
})`
  min-height: 20px;
  text-align: center;

  ${space};
`

export const ErrorBlock = styled(Box).attrs({ mb: 3 })`
  min-height: 20px;
  font-size: 14px;
  text-align: left;
  color: ${theme('colors.scarletRed')};

  ${space};
`

export const TextBlock = styled(Flex)`
  width: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${theme('colors.success')};
    margin: 16px 0;
  }

  p {
    margin: 8px 0;
  }
`

export const Links = styled(Flex).attrs({
  px: 3,
})`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`

export const Divider = styled(Box)`
  width: 1px;
  height: 14px;
  background-color: ${theme('colors.primary')};
`

export const Logo = styled(NavLink)`
  background: none;
  position: absolute;
  top: 45px;
  left: 45px;

  &.active {
    background: none;
  }

  img {
    height: 64px;
  }
`

ContainerStudents.displayName = 'ContainerStudents'
NameContainer.displayName = 'NameContainer'
SelectionInstructions.displayName = 'SelectionInstructions'
StudentBlock.displayName = 'StudentBlock'
StudentNameButton.displayName = 'StudentNameButton'
