import * as React from 'react'

function SvgFilm(props) {
  return (
    <svg
      fill="none"
      height="12"
      viewBox="0 0 20 12"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 3.15789V10.7368C13.9996 11.0717 13.859 11.3928 13.609 11.6296C13.3591 11.8664 13.0202 11.9996 12.6667 12H3.33333C2.44959 11.999 1.60233 11.666 0.977427 11.074C0.352525 10.482 0.00101103 9.67934 0 8.8421V1.26316C0.000404282 0.928265 0.14101 0.607198 0.390971 0.370393C0.640932 0.133588 0.979835 0.000383004 1.33333 0H10.6667C11.5504 0.000957822 12.3977 0.333971 13.0226 0.925984C13.6475 1.518 13.999 2.32066 14 3.15789ZM19.668 1.66429C19.567 1.60879 19.4524 1.57937 19.3356 1.579C19.2188 1.57862 19.104 1.60729 19.0026 1.66214L16.3411 3.48173C16.2391 3.53697 16.1543 3.61679 16.0953 3.71309C16.0363 3.8094 16.0052 3.91877 16.0052 4.03012V8C16.0052 8.11135 16.0363 8.22072 16.0953 8.31703C16.1543 8.41333 16.2391 8.49315 16.3411 8.54839L19.0026 10.3379C19.104 10.3927 19.2188 10.4214 19.3356 10.421C19.4524 10.4207 19.567 10.3912 19.668 10.3357C19.769 10.2802 19.8528 10.2005 19.911 10.1046C19.9693 10.0088 20 9.9001 20 9.78947V2.21053C20 2.0999 19.9693 1.99122 19.9111 1.89535C19.8528 1.79949 19.769 1.71981 19.668 1.66429Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgFilm
