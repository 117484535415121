import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import Item from './Item'
import { ActiveTab, Container, Content, Tab } from './styles'

class Tabs extends PureComponent {
  input = null

  handleChangeIndex = index => {
    const { onChangeIndex } = this.props

    if (onChangeIndex) onChangeIndex(index)
  }

  renderItems = () => {
    const { items, width, activeIndex, i18nRootKey } = this.props

    return items.map((item, index) => {
      const key = `${item}-${index}`
      return (
        <Item
          active={index === activeIndex}
          i18nRootKey={i18nRootKey}
          index={index}
          key={key}
          text={item}
          width={width}
          onChangeIndex={this.handleChangeIndex}
        />
      )
    })
  }

  render() {
    const { items, title, width, buttons, ...rest } = this.props

    return (
      <Container
        items={items.length}
        title={title}
        {...omit(rest, ['onChangeIndex', 'activeIndex', 'i18nRootKey'])}
      >
        <Content>
          <ActiveTab index={rest.activeIndex} width={width} />
          {this.renderItems()}
        </Content>
        {buttons}
      </Container>
    )
  }
}

Tabs.displayName = 'Tabs'

Tabs.defaultProps = {
  activeIndex: 0,
  i18nRootKey: null,
  title: '',
  width: 177,
  buttons: null,
  onChangeIndex: null,
}

Tabs.propTypes = {
  activeIndex: PropTypes.number,
  i18nRootKey: PropTypes.string,
  items: PropTypes.array.isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
  buttons: PropTypes.element,
  onChangeIndex: PropTypes.func,
}

Tabs.Item = Tab

export default Tabs
