import { css, styled, themeGet } from '@lyfta/components-theme'
import { Box, Flex, Text } from '@lyfta/components-ui'
import Collapse from 'react-css-collapse'
import MdClose from 'react-icons/lib/md/close'

export const Container = styled(Flex)`
  flex-direction: column;
  flex-wrap: wrap;
`

export const Tag = styled(Flex).attrs(() => ({
  pr: 2,
  pt: 2,
  pb: 2,
}))`
  position: relative;
  align-items: center;
  min-height: 48px;
  cursor: pointer;
  padding-left: 30px;

  :before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 13px;
    border: 2px solid ${themeGet('colors.gray')};
    border-radius: 3px;
  }
  ${props =>
    props.active &&
    css`
      :after {
        content: '';
        position: absolute;
        width: 11px;
        height: 8px;
        left: 4.5px;
        top: 15.5px;
        border-style: solid;
        border-width: 2px;
        border-bottom-color: ${themeGet('colors.gray')};
        border-left-color: ${themeGet('colors.gray')};
        border-top-color: ${themeGet('colors.clear')};
        border-right-color: ${themeGet('colors.clear')};
        transform: rotate(-45deg);
      }
    `};
`

export const TagText = styled(Text).attrs({
  as: 'span',
})``

export const Header = styled(Flex).attrs(() => ({
  pl: 1,
  fontSize: 3,
}))`
  flex: 1;
  align-items: center;
  min-height: 48px;
  font-weight: 600;
  position: relative;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 5px;
  border-bottom: 1px solid ${themeGet('colors.tableRowBottomBorder')};
  cursor: pointer;
`

export const StyledMdClose = styled(Box).attrs(() => ({
  as: MdClose,
  size: 22,
}))`
  position: absolute;
  right: 12px;
  top: 12px;
  transform: rotate(45deg);
  transition: transform ${themeGet('transitionTime')};
  color: ${themeGet('colors.gray')};

  ${props =>
    props.opened &&
    css`
      transform: rotate(90deg);
    `}
`

export const StyledCollapse = styled(Box).attrs({
  as: Collapse,
  className: 'collapse-transition',
})``
