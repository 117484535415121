import { styled } from '@lyfta/components-theme'

import { Flex } from '../../Components/Flex'

export const Container = styled(Flex).attrs(props => ({
  mx: 5,
  mt: 3,
  ...props,
}))`
  flex: 1;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  max-height: ${p => p.height};
`

export const LeftContainer = styled(Flex)`
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`

export const RightContainer = styled(Flex)`
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`
