import { BREAKPOINTS } from '@lyfta/components-theme'
import { useEffect, useState } from 'react'

export const useResizeHandler = (breakpoints, onResize) => {
  const [breakpoint, setBreakpoint] = useState(BREAKPOINTS.DESKTOP)

  const handleResize = () => {
    const width = window.innerWidth
    if (width < parseInt(breakpoints[0], 10)) {
      setBreakpoint(BREAKPOINTS.MOBILE)
    } else if (width < parseInt(breakpoints[1], 10)) {
      setBreakpoint(BREAKPOINTS.TABLET)
    } else if (width < parseInt(breakpoints[2], 10)) {
      setBreakpoint(BREAKPOINTS.DESKTOP)
    } else {
      setBreakpoint(BREAKPOINTS.LARGE)
    }
  }

  useEffect(() => {
    if (onResize) onResize(breakpoint)
  }, [breakpoint])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return breakpoint
}
