import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Field } from 'react-final-form'

import ReactSelect from '../../ReactSelect'

const renderField = ({
  description,
  emptyOption,
  focused,
  id,
  input,
  options,
  ...rest
}) => {
  return (
    <ReactSelect
      description={description}
      emptyOption={emptyOption}
      focused={focused}
      id={id}
      input={input}
      options={options}
      {...rest}
    />
  )
}

renderField.defaultProps = {
  emptyOption: false,
  id: '',
}

renderField.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  emptyOption: PropTypes.bool,
  focused: PropTypes.bool.isRequired,
  id: PropTypes.string,
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
}

const ReactSelectForm = ({
  description,
  label,
  name,
  placeholder,
  ...rest
}) => {
  const [focused, setFocused] = useState(false)

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    setFocused(false)
  }

  return (
    <Field
      description={description}
      focused={focused}
      label={label}
      name={name}
      placeholder={placeholder}
      render={renderField}
      onBlur={handleBlur}
      onFocus={handleFocus}
      {...rest}
    />
  )
}

ReactSelectForm.defaultProps = {
  id: undefined,
  label: '',
  placeholder: '',
  description: '',
}

ReactSelectForm.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
}

export default ReactSelectForm
