import { I18nProvider } from '@lyfta/components-i18n'
import { LyftaThemeProvider } from '@lyfta/components-theme'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import videojs from 'video.js'

import { BackgroundPage } from './backgroundPage'
import { CustomisePage } from './customisePage'
import { SpeedPage } from './speedPage'
import { MenuPageContainer } from './styles'
import { SubtitleSelectionPage } from './subtitleSelectionPage'
import { TextPage } from './textPage'

const Settings = ({ vjsBridgeComponent }) => {
  const player = vjsBridgeComponent.player()

  const ref = useRef(null)

  // Pages:
  // 1: "selection" (choose language or turn them off)
  // 2: "customise" (intermediary: choose which part to customise)
  // 2.1: "text" (all text settings that'll fit; might need a 2nd page (3b)?)
  // 2.2: "background"
  // 2.3: "speed"
  const [currentPage, setCurrentPage] = useState('selection')

  useEffect(() => {
    ref.current.scrollTop = 0
  }, [currentPage])

  return (
    <LyftaThemeProvider>
      <I18nProvider>
        <MenuPageContainer className="menu-page-container" ref={ref}>
          {currentPage === 'selection' ? (
            <SubtitleSelectionPage
              player={player}
              setCurrentPage={setCurrentPage}
            />
          ) : null}
          {currentPage === 'customise' ? (
            <CustomisePage player={player} setCurrentPage={setCurrentPage} />
          ) : null}
          {currentPage === 'text' ? (
            <TextPage player={player} setCurrentPage={setCurrentPage} />
          ) : null}
          {currentPage === 'background' ? (
            <BackgroundPage player={player} setCurrentPage={setCurrentPage} />
          ) : null}
          {currentPage === 'speed' ? (
            <SpeedPage player={player} setCurrentPage={setCurrentPage} />
          ) : null}
        </MenuPageContainer>
      </I18nProvider>
    </LyftaThemeProvider>
  )
}

Settings.propTypes = {
  vjsBridgeComponent: PropTypes.object.isRequired,
}

const Component = videojs.getComponent('Component')

class SettingsBridgeComponent extends Component {
  constructor(player, options) {
    super(player, options)

    // Bind the current class context to the mountReactComponent method
    this.mountReactComponent = this.mountReactComponent.bind(this)

    // When player is ready, call method to mount the React component
    player.ready(() => this.mountReactComponent(options.props))

    // Remove the React root when this component is destroyed
    this.on('dispose', () => ReactDOM.unmountComponentAtNode(this.el()))
  }

  mountReactComponent(props) {
    ReactDOM.render(
      <Settings vjsBridgeComponent={this} {...props} />,
      this.el(),
    )
  }
}

export default SettingsBridgeComponent
