import { getViewerType } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { getTranslation } from '@lyfta/components-i18n'
import { USER_TYPES } from '@lyfta/components-ui'
import { getIsPreviewMode } from '@lyfta/components-ui/src/Helpers/getIsPreviewMode'
import { map } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { questTrackerData } from '../../../../Store/Actions/questTracker'
import { getCurrentLessonPlanId } from '../../../../Store/Selectors/lessonPlans'
import {
  getCurrentActivity,
  getCurrentTask,
  getIsTeachingInFrontOfClass,
  getTasksForCurrentActivity,
} from '../../../../Store/Selectors/questTracker'
import { getIsQuest } from '../../../../Store/Selectors/UI/quest'
import { Navigation, NavigationTab, TabContainer } from './styles'
import TabIcon from './TabIcon'

export const TaskNavigation = () => {
  const currentActivity = useSelector(getCurrentActivity)
  const currentTask = useSelector(getCurrentTask)
  const isQuest = useSelector(getIsQuest)
  const isTeachingInFrontOfClass = useSelector(getIsTeachingInFrontOfClass)
  const lessonPlanId = useSelector(getCurrentLessonPlanId)
  const tasks = useSelector(getTasksForCurrentActivity)
  const viewerType = useSelector(getViewerType)

  const dispatch = useDispatch()

  const isPreviewMode = getIsPreviewMode({
    viewerType,
    isTeachingInFrontOfClass,
    lessonPlanId,
    isQuest,
  })

  const tabPress = (taskId, active) => () => {
    if (currentTask?.open && currentTask?.id === taskId) {
      dispatch(questTrackerData.closeTask(taskId))
    } else {
      if (!active) {
        dispatch(questTrackerData.requestOpenTask(taskId, true))
      }
      if (active) {
        dispatch(questTrackerData.openTask(taskId))
      }
    }
  }
  const completeFraction = Number.parseFloat(
    tasks.findIndex(task => task.complete === false) / tasks.length,
  )
  const completePercentage = (completeFraction * 100 + 10).toFixed(2)
  let lastTaskId = 0
  return (
    <Navigation
      completePercentage={completePercentage}
      isPreviewMode={isPreviewMode}
    >
      {map(tasks, (task, index) => {
        const taskId = task.id
        const active = taskId === currentTask?.id
        const key = `task-${taskId}`
        const lastActive = lastTaskId === currentTask?.id
        lastTaskId = taskId
        const prevTask = index > 0 ? tasks[index - 1] : null
        const nextTask = index !== tasks.length ? tasks[index + 1] : null
        const nextTaskUnlocked =
          nextTask?.complete ||
          task.complete ||
          viewerType === USER_TYPES.admins ||
          isPreviewMode
        const unlocked =
          task.complete ||
          prevTask?.complete ||
          index === 0 ||
          viewerType === USER_TYPES.admins ||
          isPreviewMode

        return (
          <TabContainer key={key} nextTaskUnlocked={nextTaskUnlocked}>
            <NavigationTab
              active={active}
              id={`button-navigation-tab-${taskId}`}
              nextTask={nextTask}
              unlocked={unlocked}
              onClick={unlocked ? tabPress(taskId, active) : null}
              leadingIcon={
                <TabIcon
                  active={active}
                  afterActive={lastActive}
                  first={index === 0}
                  last={index === currentActivity.tasks.length - 1}
                  taskId={taskId}
                  unlocked={unlocked}
                />
              }
              secondary={!unlocked}
              subtype="taskMenu"
              type="icon"
            >
              {getTranslation('quest.openTaskWithName', {
                taskName: task.name,
              })}
            </NavigationTab>
          </TabContainer>
        )
      })}
    </Navigation>
  )
}
