import { getCurrentLanguage } from '@lyfta/components-i18n'

import { createReducer } from '../../helpers'
import {
  FILTER_STUDENT,
  ITEMS_PER_PAGE,
  LOCALE,
  LOCALE_EDITING,
} from '../Actions/settings'

const initialState = {
  itemsPerPage: 100,
  locale: getCurrentLanguage(),
  localeEditing: getCurrentLanguage(),
  filterStudent: 'active',
}

const itemsPerPageSetting = (state, { itemsPerPage }) => {
  return state.merge({ itemsPerPage })
}

const filterStudentSetting = (state, { filterStudent }) => {
  return state.merge({ filterStudent })
}

const localeSetting = (state, { locale }) => {
  return state.merge({ locale })
}

const localeEditingSetting = (state, { localeEditing }) => {
  return state.merge({ localeEditing })
}

const handlers = {
  [ITEMS_PER_PAGE]: itemsPerPageSetting,
  [FILTER_STUDENT]: filterStudentSetting,
  [LOCALE]: localeSetting,
  [LOCALE_EDITING]: localeEditingSetting,
}

export default createReducer(initialState, handlers)
