import { PropTypes, Subtitle1 } from '@lyfta/components-ui'
import React from 'react'

import { InformationTextContainer, InformationTextMarkdown } from '../styles'

export const InformationText = ({ textSource }) => {
  return (
    <InformationTextContainer>
      <Subtitle1>
        <InformationTextMarkdown source={textSource} />
      </Subtitle1>
    </InformationTextContainer>
  )
}

InformationText.propTypes = {
  textSource: PropTypes.string.isRequired,
}
