import * as React from 'react'

function SvgSaLock(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
    <path d="M12.9952 12.5427C11.3342 12.9872 9.85364 13.9221 8.75551 15.2197C7.65735 16.5174 6.99479 18.1148 6.85815 19.7942L6.03022 29.0384C5.89812 30.562 6.20079 32.0914 6.90441 33.4568C7.60804 34.8221 8.685 35.9697 10.0156 36.7717C11.3462 37.5739 12.878 37.9991 14.441 38H25.559C27.1219 37.9991 28.6537 37.5738 29.9844 36.7717C31.315 35.9697 32.392 34.8221 33.0956 33.4568C33.7992 32.0915 34.1019 30.562 33.9698 29.0384L33.1418 19.7942C33.0052 18.1147 32.3426 16.5173 31.2445 15.2197C30.1462 13.9221 28.6657 12.9872 27.0048 12.5427V8.85286C27.0048 7.03529 26.2668 5.29225 24.9531 4.00721C23.6395 2.72217 21.858 2 20.0003 2C18.1426 2 16.3609 2.72203 15.0474 4.00721C13.7339 5.29239 12.9958 7.03536 12.9958 8.85286L12.9952 12.5427ZM21.4193 25.6702V28.3702C21.4193 28.8663 21.1488 29.3247 20.7096 29.5728C20.2705 29.8208 19.7295 29.8208 19.2903 29.5728C18.8512 29.3247 18.5807 28.8663 18.5807 28.3702V25.6702C17.8487 25.2593 17.3404 24.5524 17.1924 23.7396C17.0444 22.9267 17.272 22.0917 17.8138 21.4587C18.3556 20.8258 19.1562 20.4602 20 20.4602C20.8438 20.4602 21.6443 20.8258 22.1862 21.4587C22.728 22.0917 22.9555 22.9267 22.8075 23.7396C22.6595 24.5524 22.1512 25.2593 21.4193 25.6702ZM15.6236 8.85286C15.6236 7.32326 16.4577 5.90974 17.8117 5.14516C19.1657 4.38028 20.8339 4.38028 22.1881 5.14516C23.5421 5.90991 24.3762 7.32339 24.3762 8.85286V12.2472H15.6238L15.6236 8.85286Z" fill="#050B15"/>
    </svg>
  )
}

export default SvgSaLock