import {
  SMALL_SCREEN_HEIGHT,
  SMALL_SCREEN_WIDTH,
} from '@lyfta/components-config'
import paths from 'Constants/paths'
import Private from 'Containers/Layout/Private'
import Public from 'Containers/Layout/Public'
import Dashboard from 'Containers/Pages/Dashboard'
import LessonSelector from 'Containers/Pages/LessonSelector'
import {
  Forgot,
  LoginToLesson,
  LoginWithPassword,
  StudentSelector,
} from 'Containers/Pages/Public/Auth'
import Confirm from 'Containers/Pages/Public/Auth/Confirm'
import NotSupported from 'Containers/Pages/Public/NotSupported'

function getIsSmallScreen() {
  return (
    window.screen.width <= SMALL_SCREEN_WIDTH ||
    window.screen.height <= SMALL_SCREEN_HEIGHT
  )
}

const isSmallScreen = getIsSmallScreen()

const privateRoutes = [
  {
    path: paths.root,
    exact: true,
    component: isSmallScreen ? NotSupported : Dashboard,
  },
  {
    path: paths.lessonPlan(),
    exact: true,
    component: isSmallScreen ? NotSupported : Dashboard,
  },
  {
    path: paths.lessonSelection,
    exact: true,
    component: isSmallScreen ? NotSupported : LessonSelector,
  },
]

const publicRoutes = [
  {
    path: paths.root,
    exact: true,
    component: isSmallScreen ? NotSupported : LoginToLesson,
  },
  {
    path: paths.loginToLesson,
    exact: true,
    component: isSmallScreen ? NotSupported : LoginToLesson,
  },
  {
    path: paths.loginWithPassword,
    exact: true,
    component: isSmallScreen ? NotSupported : LoginWithPassword,
  },
  {
    path: paths.chooseStudent,
    exact: true,
    component: isSmallScreen ? NotSupported : StudentSelector,
  },
  {
    path: paths.forgot,
    exact: true,
    component: isSmallScreen ? NotSupported : Forgot,
  },
  {
    path: paths.confirm,
    exact: true,
    component: isSmallScreen ? NotSupported : Confirm,
  },
]

export default {
  build: authenticated => [
    authenticated
      ? { component: Private, routes: privateRoutes }
      : { component: Public, routes: publicRoutes },
  ],
}
