import { I18n } from '@lyfta/components-i18n'
import { ENTER_KEY, Icon, SPACE_KEY } from '@lyfta/components-ui'
import { reactEvent } from '@lyfta/components-ui/src/Services/Utils'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Card,
  Container,
  IconContainer,
  Label,
  LabelMouseOver,
  Locked,
  LockedIcon,
  NumberCard,
} from './styles'

const Card7 = ({
  number,
  icon,
  label,
  labelMouseOver,
  link,
  locked,
  ga,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const history = useHistory()

  const handleBlur = () => {
    setIsFocused(false)
    if (!isMouseOver) setIsHovered(false)
  }

  const handleFocus = () => {
    setIsFocused(true)
    if (!isMouseOver) setIsHovered(true)
  }

  const handleKeyDown = event => {
    if (event.keyCode === SPACE_KEY) {
      event.preventDefault()
    }
  }

  const handleKeyUp = event => {
    if (event.keyCode === ENTER_KEY || event.keyCode === SPACE_KEY) {
      handleOnClick(event, link)
      event.preventDefault()
    }
  }

  const handleMouseOver = () => {
    setIsMouseOver(true)
    if (!isFocused) setIsHovered(true)
  }

  const handleMouseOut = () => {
    setIsMouseOver(false)
    if (!isFocused) setIsHovered(false)
  }

  const handleOnClick = (event, linkCard) => {
    if (onClick) onClick(event)

    if (locked) {
      event.currentTarget.blur()
      return
    }

    if (ga) reactEvent({ action: ga })

    if (linkCard) history.push(linkCard)

    event.currentTarget.blur()
  }

  return (
    <Card
      onBlur={() => handleBlur()}
      onFocus={() => handleFocus()}
      onKeyDown={event => handleKeyDown(event)}
      onKeyUp={event => handleKeyUp(event)}
      onMouseEnter={() => handleMouseOver()}
      onMouseLeave={() => handleMouseOut()}
      onClick={event => handleOnClick(event, link)}
      tabIndex="0"
    >
      {locked && (
        <Locked className="card-locked">
          <LockedIcon />
        </Locked>
      )}
      <Container className="card-contents-container">
        {!isHovered && (
          <>
            <NumberCard>{number || 0}</NumberCard>
            <IconContainer>
              <Icon glyph={icon} height={40} width={40} fill="primary_60_50" />
            </IconContainer>
            <Label>
              <I18n i18nKey={label} />
            </Label>
          </>
        )}
        {isHovered && labelMouseOver && (
          <LabelMouseOver>
            <I18n i18nKey={labelMouseOver} />
          </LabelMouseOver>
        )}
      </Container>
    </Card>
  )
}
Card7.defaultProps = {
  number: 0,
  icon: null,
  label: '',
  labelMouseOver: '',
  link: null,
  locked: false,
  ga: '',
  onClick: null,
}

Card7.propTypes = {
  number: PropTypes.number,
  icon: PropTypes.element,
  label: PropTypes.string,
  labelMouseOver: PropTypes.string,
  link: PropTypes.func,
  locked: PropTypes.bool,
  ga: PropTypes.string,
  onClick: PropTypes.func,
}

export default Card7
