import {
  alignItems,
  color,
  flexDirection,
  flexWrap,
  justifyContent,
  maxHeight,
  styled,
} from '@lyfta/components-theme'

import { createBox } from './Box'

export function createFlex(StyledElement) {
  return createBox(
    styled(StyledElement`
    display: flex;
    ${alignItems}
    ${flexDirection}
    ${flexWrap}
    ${justifyContent}
    ${maxHeight}
    ${color}
  `),
  )
}
