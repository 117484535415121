import { createReducer } from '../../../helpers'
import { createEntityFields } from '../../../utils'
import admins from './admins'
import children from './children'
import parents from './parents'
import students from './students'
import teachers from './teachers'
import users from './users'

const initialState = {
  ...createEntityFields('users'),
  ...createEntityFields('users', 'children', 'child'),
  ...createEntityFields('users', 'teacherUsers'),
  ...createEntityFields('users', 'studentUsers'),
  ...createEntityFields('users', 'parentUsers'),
  ...createEntityFields('users', 'adminUsers'),
}

const handlers = {
  ...admins,
  ...children,
  ...parents,
  ...students,
  ...teachers,
  ...users,
}

export default createReducer(initialState, handlers)
