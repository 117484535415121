import { ButtonNew, PropTypes, Subtitle2 } from '@lyfta/components-ui'
import React from 'react'

import { SubmitContainer } from '../styles'

export const Submit = ({ submitDisabled, onClick, buttonText }) => {
  return (
    <SubmitContainer>
      <Subtitle2>
        <ButtonNew disabled={submitDisabled} id="btn-submit" onClick={onClick}>
          {buttonText}
        </ButtonNew>
      </Subtitle2>
    </SubmitContainer>
  )
}

Submit.defaultProps = {
  submitDisabled: false,
}

Submit.propTypes = {
  buttonText: PropTypes.string.isRequired,
  submitDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}
