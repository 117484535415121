import * as React from 'react'

function SvgNext(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 100 100"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M45.422 21.6a6.321 6.321 0 00-8.818-1.398 6.269 6.269 0 00-2.525 4.119 6.271 6.271 0 001.127 4.698L50.442 50 35.206 70.98c-2.045 2.817-1.418 6.773 1.398 8.817A6.279 6.279 0 0040.305 81a6.312 6.312 0 005.117-2.601l20.326-27.99a.7.7 0 000-.819L45.422 21.6z"
        fill="#000"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgNext
