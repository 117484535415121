const sizes = [
  '10px', // 0
  '12px', // 1
  '14px', // 2
  '16px', // 3
  '18px', // 4
  '20px', // 5
  '26px', // 6
  '32px', // 7
  '38px', // 8
  '40px', // 9
]
const lineHeights = [
  '12px', // 0
  '14px', // 1
  '16px', // 2
  '20px', // 3
  '22px', // 4
  '24px', // 5
  '30px', // 6
  '32px', // 7
  '34px', // 8
  '40px', // 9
  '42px', // 10
  '48px', // 11
]

export const fontWeights = {
  tableActionButton: 700,
}

const fontSize = {}

/* eslint-disable prefer-destructuring */
fontSize.textLargest = sizes[6]
fontSize.textLarger = sizes[5]
fontSize.textLarge = sizes[4]
fontSize.textMedium = sizes[3]
fontSize.textSmall = sizes[2]
fontSize.textSmaller = sizes[1]
fontSize.textSmallest = sizes[0]

fontSize.heading1 = sizes[8]
fontSize.heading2 = sizes[7]
fontSize.heading3 = sizes[6]
fontSize.heading4 = sizes[5]

/* eslint-enable prefer-destructuring */

const fontSizes = { fontSizes: sizes, fontSize, lineHeights }

export default fontSizes
