import { createSelector } from 'reselect'

export const getState = state => state.uiContent.search

export const getActiveTab = createSelector(getState, state => state.activeTab)

export const getSearchModalState = createSelector(
  getState,
  state => state.searchModal,
)
