import { find } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { matchRoutes, renderRoutes } from 'react-router-config'

import { Breadcrumbs } from './Breadcrumbs'
import Header from './Header'
import Sidebar from './Sidebar'
import { Container, Content, ContentContainer, Main } from './styles'

export const StructuredLayout = ({
  route,
  sidebar,
  nonStandardHeader,
  ...props
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [userToggledSidebar, setUserToggledSidebar] = useState(false)

  const toggleSidebar = useCallback(() => {
    setSidebarOpen(!sidebarOpen)
    setUserToggledSidebar(true)
  }, [sidebarOpen])
  /* eslint-disable */
  const {
    location: { pathname },
  } = props
  /* eslint-enable */
  const allRoutesMatch = matchRoutes(route.routes, pathname)
  const currentRouteProperties = find(allRoutesMatch, t => {
    return t.match.isExact
  })
  const routeId = currentRouteProperties?.route?.id || 'other'

  useEffect(() => {
    if (routeId === 'search') {
      setSidebarOpen(false)
    } else if (!userToggledSidebar) {
      setSidebarOpen(true)
    }
  }, [routeId, userToggledSidebar])

  let properties
  if (currentRouteProperties) {
    properties = currentRouteProperties?.route
  }
  return (
    <Container>
      <Header
        height="40px"
        nonStandardHeader={nonStandardHeader}
        removeMenu={properties?.removeMenu}
      />
      {!properties?.removeMenu && (
        <Main>
          <Sidebar
            closeSidebar={setSidebarOpen}
            open={sidebarOpen}
            routes={route.routes}
            staticContent={sidebar}
            onDrawerToggle={toggleSidebar}
          />
          <Content>
            {!properties?.hideBreadcrumbs && (
              <Breadcrumbs routes={route.routes} />
            )}
            <div id="top-bar" />

            <ContentContainer>{renderRoutes(route.routes)}</ContentContainer>
          </Content>
        </Main>
      )}

      {properties?.removeMenu && (
        <Content>
          <ContentContainer>{renderRoutes(route.routes)}</ContentContainer>
        </Content>
      )}
    </Container>
  )
}

StructuredLayout.defaultProps = {
  nonStandardHeader: null,
  sidebar: null,
}
StructuredLayout.propTypes = {
  nonStandardHeader: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
  route: PropTypes.object.isRequired,
  sidebar: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
}
