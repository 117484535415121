import { omit } from 'lodash'
import { all, put, select, takeLatest } from 'redux-saga/effects'

import { LOAD_LESSON_PLAN } from '../Actions/lessonPlans'
import { questTrackerData } from '../Actions/questTracker'
import { getCurrentLessonPlan } from '../Selectors/lessonPlans'
import { getActivityList, getTasks } from './lessons'

function* fireQuestTrackerData() {
  const lessonPlan = yield select(getCurrentLessonPlan)

  if (!lessonPlan) return

  const activities = getActivityList(lessonPlan, [])
  const tasks = getTasks(lessonPlan, [])
  const currentActivityId = activities?.[0]?.id

  yield put(
    questTrackerData.load({
      previousActivityId: null,
      currentActivityId,
      lessonId: 0,
      lessonPlan: omit(lessonPlan, ['user', 'activities', 'content']),
      activities,
      tasks,
    }),
  )
}
export default function* root() {
  yield all([takeLatest(LOAD_LESSON_PLAN.SUCCESS, fireQuestTrackerData)])
}
