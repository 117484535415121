import { get, map } from 'lodash'

import { createAsyncAction } from '../../helpers'
import apiCall from '../../services/Api'

export const ADD_CLASS_STUDENT = createAsyncAction('classes/ADD_CLASS_STUDENT')
export const REMOVE_CLASS_STUDENT = createAsyncAction(
  'classes/REMOVE_CLASS_STUDENT',
)
export const LOAD_CLASSES = createAsyncAction('classes/LOAD_CLASSES')
export const LOAD_CLASS = createAsyncAction('classes/LOAD_CLASS')
export const CREATE_CLASS = createAsyncAction('classes/CREATE')
export const UPDATE_CLASS = createAsyncAction('classes/UPDATE')
export const DELETE_CLASS = createAsyncAction('classes/DELETE')
export const DUPLICATE_CLASS = createAsyncAction('classes/DUPLICATE')
export const ARCHIVE_CLASS = createAsyncAction('classes/ARCHIVE')

export const loadClasses = ({
  filter,
  page,
  include = 'licensings',
  sort = 'name',
  ...props
} = {}) =>
  apiCall({
    method: 'GET',
    endpoint: '/classes',
    query: {
      include: 'organization,students',
      page,
      filter,
      sort,
    },
    types: LOAD_CLASSES,
    paged: true,
    payload: { page, filter, include, sort, ...props },
  })

export const loadClass = params => {
  let id = params
  if (typeof params === 'object') {
    id = get(params, 'id')
  }

  return apiCall({
    method: 'GET',
    endpoint: `/classes/${id}?include=students`,
    types: LOAD_CLASS,
  })
}

export const createClass = ({
  classRecord,
  redirect = true,
  customMessage,
}) => {
  return apiCall({
    method: 'POST',
    endpoint: `/classes?include=organization,students`,
    types: CREATE_CLASS,
    redirect,
    query: {
      data: {
        type: 'classes',
        attributes: {
          ...classRecord,
        },
      },
    },
    customMessage,
  })
}
export const updateClass = ({ classRecord, customMessage }) => {
  return apiCall({
    method: 'PATCH',
    endpoint: `/classes/${classRecord.id}?include=students`,
    types: UPDATE_CLASS,
    query: {
      data: {
        type: 'users',
        attributes: {
          ...classRecord,
        },
      },
    },
    customMessage,
  })
}

export const deleteClass = id =>
  apiCall({
    method: 'DELETE',
    endpoint: `/classes/${id}`,
    types: DELETE_CLASS,
    payload: {
      deletedId: id,
    },
  })

export const duplicateClass = classId =>
  apiCall({
    method: 'POST',
    customMessage: { success: { message: 'core.messages.success.duplicate' } },
    endpoint: `/classes/${classId}/clones`,
    types: DUPLICATE_CLASS,
  })

export const archiveClass = id =>
  apiCall({
    method: 'PATCH',
    customMessage: { success: { message: 'core.messages.success.archive' } },
    endpoint: `/classes/${id}/archives`,
    types: ARCHIVE_CLASS,
  })

export const addStudentToClass = ({
  classId,
  selected,
  customMessage,
  studentId,
}) => {
  return apiCall({
    method: 'POST',
    endpoint: `/classes/${classId}/relationships/students`,
    query: {
      data: map(selected, id => ({
        type: 'students',
        id,
      })),
    },
    updateRelationships: [
      {
        modelName: 'classes',
        id: classId,
        relationship: 'students',
      },
      {
        modelName: 'students',
        id: studentId,
        relationship: 'klasses',
        idToAdd: classId,
        typeToAdd: 'classes',
      },
    ],
    types: ADD_CLASS_STUDENT,
    customMessage,
  })
}

export const removeStudentFromClass = ({ classId, selected, customMessage }) =>
  apiCall({
    method: 'DELETE',
    endpoint: `/classes/${classId}/relationships/students`,
    query: {
      data: map(selected, id => ({
        type: 'students',
        id,
      })),
    },
    updateRelationships: [
      {
        modelName: 'classes',
        id: classId,
        relationship: 'students',
      },
    ],
    types: REMOVE_CLASS_STUDENT,
    customMessage,
  })
