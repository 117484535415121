import { jsx } from '@emotion/react'
/** @jsx jsx */
import { styled, themeGet } from '@lyfta/components-theme'
import PropTypes from 'prop-types'
import { components } from 'react-select'

import { Language24px } from '../../Assets/Icons'
// eslint-disable-next-line
import Icon from '../Icon'
import ReactSelectDropdown from '../ReactSelectDropdown'

const CustomControl = props => {
  const { children, selectProps } = props
  return (
    <components.Control {...props}>
      <Icon
        alignItems="center"
        className="language-icon-container"
        fill={themeGet(selectProps.fill)}
        glyph={Language24px}
        height={24}
        ml={2}
        mr={1}
        width={24}
      />
      {children}
    </components.Control>
  )
}

CustomControl.propTypes = {
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.object.isRequired,
}

const rightPadding = 4

export const ReactSelectLocale = styled(ReactSelectDropdown).attrs(props => ({
  extendComponents: {
    Control: CustomControl,
  },
  extendStyles: {
    control: (defaultStyles, state) => ({
      ...defaultStyles,
      backgroundColor: state.menuIsOpen
        ? 'rgba(255, 255, 255, 0.2)'
        : themeGet('colors.clear')(props),
      borderRadius: '0',
      marginLeft: '8px',
      padding: `4px ${rightPadding}px 4px 0`,
      transition: 'none',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
      },
    }),
    dropdownIndicator: defaultStyles => ({
      ...defaultStyles,
      '& svg > path': {
        fill: themeGet(props.fill)(props),
      },
    }),
    menu: defaultStyles => ({
      ...defaultStyles,
      left: 'initial',
      margin: '0',
      right: '0',
    }),
    option: defaultStyles => ({
      ...defaultStyles,
      padding: `8px ${rightPadding}px 8px 12px`,
    }),
    singleValue: defaultStyles => ({
      ...defaultStyles,
      color: themeGet(props.fill)(props),
      minWidth: '21px',
      textAlign: 'center',
    }),
  },
}))``
