import * as React from 'react'

function SvgThink(props) {
  return (
    <svg
      id="svg-think"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M10 22C9.4 22 9 21.5 9 21V20H15V21C15 21.5 14.6 22 14 22H10Z"
        fill="black"
      />
      <path
        d="M5 9C5 5.1 8.1 2 12 2C15.9 2 19 5.1 19 9C19 11.3 17.8 13.4 16 14.7V17C16 17.5 15.6 18 15 18H12.9375V14.3838L15.4746 12.1079L14.1391 10.6191L11.9691 12.5657L9.9885 10.6171L8.58583 12.0427L10.9375 14.3565V18H9C8.4 18 8 17.5 8 17V14.7C6.2 13.5 5 11.4 5 9Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgThink
