import React from 'react'

function SvgZoomOut(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <path d="M36 18.5H8V25.5H36V18.5Z" fill="#050B15" />
    </svg>
  )
}

export default SvgZoomOut
