import { createSelector } from 'reselect'

export const getState = state => state.ui.muted

export const getIsMuted = createSelector(getState, state => state.muted)
export const getIsTemporarilyUnmuted = createSelector(
  getState,
  state => state.temporaryUnmute,
)
export const getIsMutedDuringTemporaryUnmute = createSelector(
  getState,
  state => state.mutedDuringTemporaryUnmute,
)
