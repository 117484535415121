import { styled } from '@lyfta/components-theme'
import PT from 'prop-types'
import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Paragraph } from '../../BaseComponents'
import MarkdownStyles from './styles'

const MDComponent = ({ children, source, root, linkTarget, ...props }) => (
  <ReactMarkdown
    {...props}
    source={children || source}
    root={root}
    linkTarget={linkTarget}
  />
)

MDComponent.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]),
  linkTarget: PT.string,
  root: PT.oneOfType([PT.element, PT.object]),
  source: PT.string,
}

MDComponent.defaultProps = {
  children: null,
  root: Paragraph,
  linkTarget: '_blank',
  source: null,
}

export const Markdown = styled(MDComponent)`
  ${p => {
    const styleType = p?.type ? `${p.type}Style` : 'style'

    return MarkdownStyles[styleType]
  }}
`
