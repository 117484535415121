import { getTranslation } from '@lyfta/components-i18n'
import { Text, Title4, themeGet } from '@lyfta/components-ui'
import PT from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { questTrackerData } from '../../../../../Store/Actions/questTracker'
import { CloseButton } from '../../../Buttons'
import Content from '../Content'
import Discussion from '../Discussion'
import Information from '../Information'
import MultipleChoice from '../MultipleChoice'
import Rating from '../Rating'
import {
  BlockTitle,
  BodyContainer,
  Container,
  HeaderContainer,
  KeyboardSpacer,
} from './styles'

const getTaskBlock = ({ task: { type, id }, task, editingDisabled }) => {
  const key = `taskBlock-${id}`
  switch (type) {
    case 'content':
      return <Content key={key} task={task} />
    case 'multiple_choice':
      return <MultipleChoice key={key} task={task} />
    case 'discussion':
      return <Discussion disabled={editingDisabled} key={key} task={task} />
    case 'information':
      return <Information key={key} task={task} />
    case 'rating':
      return <Rating key={key} task={task} />
    default:
      return <Text key={key}>{type}</Text>
  }
}
getTaskBlock.propTypes = {
  editingDisabled: PT.bool,
  task: PT.object.isRequired,
}

getTaskBlock.defaultProps = {
  editingDisabled: false,
}

const TaskBlock = ({ task: currentTask, editingDisabled, animation }) => {
  const { actionType, name } = currentTask
  const dispatch = useDispatch()

  const closeTask = useCallback(() => {
    dispatch(questTrackerData.closeTask(currentTask.id))
  }, [currentTask])

  const titlePrefix =
    actionType === 'other'
      ? ''
      : `${getTranslation(`quest.tasks.${actionType}`)}: `

  const title = `${titlePrefix}${name}`

  return (
    <Container animation={animation} task={currentTask}>
      <HeaderContainer id="div-task-name">
        <Title4>
          <BlockTitle source={title} />
        </Title4>
        <CloseButton
          defaultFill={themeGet('colors.black_800')}
          id="btn-close-task"
          text={getTranslation('quest.close')}
          onClick={closeTask}
        />
      </HeaderContainer>
      <BodyContainer>
        {getTaskBlock({ task: currentTask, editingDisabled })}
      </BodyContainer>
      <KeyboardSpacer />
    </Container>
  )
}
TaskBlock.propTypes = {
  animation: PT.object.isRequired,
  editingDisabled: PT.bool,
  task: PT.object.isRequired,
}

TaskBlock.defaultProps = {
  editingDisabled: false,
}

export default TaskBlock
