import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { questTrackerData } from '../../../Store/Actions/questTracker'
import { quest } from '../../../Store/Actions/ui'
import {
  getActivityAdvanceType,
  getCurrentActivity,
  getCurrentTask,
  getLessonCompleted,
} from '../../../Store/Selectors/questTracker'
import { getIsQuest } from '../../../Store/Selectors/UI/quest'
import Tasks from './Tasks'

const selector = createStructuredSelector({
  currentActivity: getCurrentActivity,
  currentTask: getCurrentTask,
  advanceType: getActivityAdvanceType,
  menuVisible: getIsQuest,
  lessonCompleted: getLessonCompleted,
})

const actions = {
  advanceActivity: questTrackerData.advanceActivity,
  selectTask: questTrackerData.selectTask,
  openTask: questTrackerData.openTask,
  pauseWebGL: quest.pauseWebGL,
  resumeWebGL: quest.resumeWebGL,
}

export default connect(selector, actions)(Tasks)
