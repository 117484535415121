import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'

import {
  DateTime,
  Translation,
  getCurrentLanguage,
} from '@lyfta/components-i18n'
import React from 'react'
import { Calendar } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

import { PropTypes } from '../../Helpers/prop-types'
import LuxonLocalizer from './localizer'
import { Container } from './styles'

const localizer = LuxonLocalizer(DateTime, { firstDayOfWeek: 1 })

const DragAndDropCalendar = withDragAndDrop(Calendar)

export const BigCalendar = ({ events, ...props }) => {
  return (
    <Container>
      <Translation>
        {t => (
          <DragAndDropCalendar
            culture={getCurrentLanguage()}
            defaultDate={new Date()}
            defaultView="month"
            events={events}
            localizer={localizer}
            messages={{
              today: t('core.calendar.today'),
              previous: t('core.calendar.previous'),
              next: t('core.calendar.next'),
              month: t('core.calendar.month'),
              week: t('core.calendar.week'),
              day: t('core.calendar.day'),
              work_week: t('core.calendar.work_week'),
              agenda: t('core.calendar.agenda'),
            }}
            {...props}
          />
        )}
      </Translation>
    </Container>
  )
}

BigCalendar.defaultProps = {
  events: [],
}
BigCalendar.propTypes = {
  events: PropTypes.array,
}
