import React from 'react'

function SvgRotateOn(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <path
        d="M21.9718 4C12.0251 4 4 12.0943 4 22C4 31.9056 12.0816 40 21.9718 40C31.8619 40 40 31.9623 40 22C40 12.0377 31.9184 4 21.9718 4ZM21.9718 6.20755C24.1193 6.20755 26.3234 8.86793 27.6798 13.3396C25.8713 13.0566 24.0063 12.8868 21.9718 12.8868C19.9372 12.8868 18.0722 13.0566 16.2637 13.3396C17.6201 8.92453 19.8807 6.20755 21.9718 6.20755ZM28.2449 15.717C28.584 17.5849 28.8101 19.6792 28.8101 22C28.8101 24.2641 28.584 26.4151 28.2449 28.283C26.3799 28.6226 24.2889 28.849 21.9718 28.849C19.7112 28.849 17.5636 28.6226 15.6986 28.283C15.3595 26.4151 15.1334 24.3207 15.1334 22C15.1334 19.7358 15.3595 17.5849 15.6986 15.717C17.5636 15.3774 19.6546 15.151 21.9718 15.151C24.2889 15.151 26.3799 15.3774 28.2449 15.717ZM16.8289 7.11321C15.5855 8.81133 14.5683 11.1321 13.8901 13.8491C11.1774 14.5283 8.86028 15.5472 7.16483 16.7925C8.74725 12.2642 12.3077 8.69812 16.8289 7.11321ZM13.3815 16.283C13.0989 18.0943 12.9293 19.9622 12.9293 22C12.9293 23.9811 13.0989 25.9056 13.3815 27.717C8.91679 26.4151 6.2606 24.1509 6.2606 22C6.2606 19.849 8.91679 17.6415 13.3815 16.283ZM7.16483 27.2075C8.86028 28.4528 11.1774 29.4717 13.8901 30.1509C14.5683 32.8679 15.5855 35.1887 16.8289 36.8868C12.3077 35.3019 8.74725 31.7358 7.16483 27.2075ZM21.9718 37.7924C19.8242 37.7924 17.6201 35.1321 16.2637 30.6604C18.0722 30.9434 19.9372 31.1132 21.9718 31.1132C24.0063 31.1132 25.8713 30.9434 27.6798 30.6604C26.3799 35.0755 24.1193 37.7924 21.9718 37.7924ZM27.1711 36.8868C28.4144 35.1887 29.4317 32.8679 30.1099 30.1509C32.8226 29.4717 35.1397 28.4528 36.8352 27.2075C35.2528 31.7358 31.6923 35.3019 27.1711 36.8868ZM30.6185 27.717C30.9011 25.9056 31.0706 24.0377 31.0706 22C31.0706 20.0188 30.9011 18.0943 30.6185 16.283C35.0832 17.5849 37.7394 19.849 37.7394 22C37.7394 24.1509 35.0832 26.3585 30.6185 27.717ZM30.1099 13.8491C29.4317 11.1321 28.4144 8.81133 27.1711 7.11321C31.6923 8.69812 35.2528 12.2642 36.8352 16.7925C35.1397 15.5472 32.8226 14.5283 30.1099 13.8491Z"
        fill="#050B15"
      />
      <path
        d="M17.5 22C17.5 24.4545 19.487 26.5 22 26.5C24.513 26.5 26.5 24.513 26.5 22C26.5 19.487 24.513 17.5 22 17.5C19.487 17.5 17.5 19.5455 17.5 22ZM24.1039 22C24.1039 23.1688 23.1104 24.1623 21.9415 24.1623C20.7727 24.1623 19.7792 23.1688 19.7792 22C19.7792 20.8312 20.7727 19.8377 21.9415 19.8377C23.1688 19.8377 24.1039 20.8312 24.1039 22Z"
        fill="#050B15"
      />
    </svg>
  )
}

export default SvgRotateOn
