import * as React from 'react'

function SvgStoryworld(props) {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.9201 3.97002H11.525C11.2825 3.0205 10.6683 1.95442 10.1239 1.00947C9.9498 0.707279 9.78284 0.417475 9.63745 0.147834C12.3375 0.799478 13.96 2.31584 14.9201 3.97002Z"
        fill="black"
      />
      <path
        d="M7.99992 0C8.82994 1.20303 9.47995 2.5364 9.90996 3.97002H6.08988C6.51989 2.5364 7.1699 1.20303 7.99992 0Z"
        fill="black"
      />
      <path
        d="M0.349763 10.3611C0.0997579 9.67186 -0.000244141 8.67188 -0.000244141 7.98013C-0.000244141 7.28839 0.112258 6.2006 0.349763 5.59909H3.99984C3.91984 6.26075 3.83733 7.29841 3.83733 7.98013C3.83733 8.66185 3.91984 9.69943 3.99984 10.3611H0.349763Z"
        fill="black"
      />
      <path
        d="M1.04978 11.9902H4.43735C4.67479 12.9201 5.30314 13.9962 5.85669 14.9442C6.04916 15.2738 6.23258 15.5879 6.38739 15.875C5.69207 15.7229 5.05877 15.4995 4.48752 15.2253L0.799772 16.0003L2.06585 13.436C1.63591 12.9642 1.2972 12.4702 1.04978 11.9902Z"
        fill="black"
      />
      <path
        d="M4.46235 3.97002H1.07978C2.0398 2.30582 3.71233 0.73682 6.39989 0.147834C6.23741 0.449171 6.04432 0.775689 5.84275 1.11654C5.29995 2.03441 4.6957 3.0562 4.46235 3.97002Z"
        fill="black"
      />
      <path
        d="M7.99992 16.0003C7.1699 14.7973 6.51989 13.4239 6.08988 11.9902H9.90996C9.47995 13.4239 8.82994 14.7973 7.99992 16.0003Z"
        fill="black"
      />
      <path
        d="M5.58737 10.3611C5.49737 9.69943 5.42487 8.66185 5.42487 7.98013C5.42487 7.29841 5.49737 6.25073 5.58737 5.59909H10.4125C10.5025 6.25073 10.5375 7.29841 10.5375 7.98013C10.5375 8.66185 10.5025 9.69943 10.4125 10.3611H5.58737Z"
        fill="black"
      />
      <path
        d="M10.1604 14.9184C10.7138 13.9767 11.3397 12.9117 11.575 11.9902H14.9626C14.08 13.5692 12.35 15.261 9.61245 15.875C9.77128 15.5804 9.96112 15.2574 10.1604 14.9184Z"
        fill="black"
      />
      <path
        d="M11.975 10.3611C12.055 9.69943 12.1375 8.66185 12.1375 7.98013C12.1375 7.29841 12.055 6.26075 11.975 5.59909H15.6251C15.9001 6.2382 16.0001 7.28839 16.0001 7.98013C16.0001 8.67188 15.9376 9.44629 15.6751 10.3611H11.975Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgStoryworld
