import * as React from 'react'

function SvgChecked(props) {
  return (
    <svg height="1em" viewBox="0 0 40 40" width="1em" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={20} cy={20} fill="#838383" r={20} />
        <path
          d="M11.463 22.921c-.683-.667-.597-1.751.17-2.335.684-.667 1.793-.584 2.39.167l3.842 4.336L28.79 11.83c1.365-1.668 3.073-.583 1.707 1.168L19.4 28.759l-.256.25c-.768.667-1.792.584-2.475-.167l-5.207-5.92z"
          fill="#5D5D5D"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgChecked
