import * as React from 'react'

function SvgHelp(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 1000 1000"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g data-name="Layer 2">
        <path
          d="M500 0C223.86 0 0 223.86 0 500s223.86 500 500 500 500-223.86 500-500S776.14 0 500 0zm45.4 736.24q-21.64 21.64-52.14 21.64-31.23 0-52.5-21.64t-21.28-51.44q0-29.79 21.28-51.08t52.5-21.28q30.5 0 52.14 21.28T567 684.8q0 29.81-21.6 51.44zm112.09-286.61q-18.79 28.72-51.43 53.56L577 524.47q-16.32 12.07-22 21.64t-5.67 23.06v8.51H437.22v-7.09q0-34.76 10.28-56.05t40.8-41.85l33.34-22q21.29-14.18 30.5-29.44t9.23-37.25q0-25.54-15.26-40.44T506 328.67q-31.23 0-50 18.45t-18.8 55.33H323.71q0-70.92 47.17-115.63t138.7-44.7q75.18 0 121 35.83T676.29 379q0 41.91-18.8 70.63z"
          data-name="Layer 1"
        />
      </g>
    </svg>
  )
}

export default SvgHelp
