import { styled } from '@lyfta/components-theme'
import { motion } from 'framer-motion'
import { merge } from 'lodash'
import PT from 'prop-types'
import React from 'react'

// noinspection ES6PreferShortImport
import { useConditionalCallback } from '../Services/UseConditionalCallback'
import {
  createAbsolute,
  createAbsoluteFlex,
  createBox,
  createFlex,
  createText,
} from './factories'

export { Button } from './Button'

const AnimatedDiv = ({ animation, ...props }) => (
  <motion.div {...animation} {...props} />
)

AnimatedDiv.propTypes = {
  animation: PT.object,
}
AnimatedDiv.defaultProps = {
  animation: {},
}

export const Container = createBox(styled.div)
export const BasicInput = createText(styled.input)

export const Link = createText(styled.a)
export const Span = createText(styled.span)
export const Sup = createBox(styled.sup)
export const Absolute = createAbsolute(styled.div)
export const AnimatedFlex = createFlex(styled(AnimatedDiv))
export const AnimatedAbsoluteFlex = createAbsoluteFlex(styled(AnimatedDiv))
export const AbsoluteFlex = createAbsoluteFlex(styled.div)
export const Box = createBox(styled.div)
export const StyleableImage = styled.img``
export const Flex = createFlex(styled.div)
export const FlexText = createFlex(styled.div)

export const TouchableFlex = React.forwardRef(
  ({ disabled, onPress, onClick, ...props }, ref) => {
    const onFlexPress = useConditionalCallback(disabled, onClick || onPress)

    return <Flex ref={ref} onClick={onFlexPress} {...props} />
  },
)

TouchableFlex.propTypes = {
  disabled: PT.bool,
  onClick: PT.func,
  onPress: PT.func,
}
TouchableFlex.defaultProps = {
  disabled: false,
  onClick: null,
  onPress: () => {},
}

export const TouchableAbsoluteFlex = React.forwardRef(
  ({ onPress, onClick, ...props }, ref) => (
    <AbsoluteFlex ref={ref} onClick={onClick || onPress} {...props} />
  ),
)
TouchableAbsoluteFlex.propTypes = {
  onClick: PT.func,
  onPress: PT.func,
}
TouchableAbsoluteFlex.defaultProps = {
  onClick: null,
  onPress: () => {},
}
export const Paragraph = createText(styled.p)
export const Label = styled(Flex).attrs(p => merge({ as: 'label' }, p))``
export const ScrollableFlex = createFlex(styled.div)
export const Text = createText(styled.div)
export const Img = createBox(styled.img)
export const Icon = createBox(styled.img)

const ImageBg = createFlex(styled.div)
export const ImageBackground = styled(ImageBg)`
  background-image: url(${props => props.source});
`

Absolute.displayName = 'Absolute'
AbsoluteFlex.displayName = 'AbsoluteFlex'
AnimatedAbsoluteFlex.displayName = 'AnimatedAbsoluteFlex'
AnimatedFlex.displayName = 'AnimatedFlex'
BasicInput.displayName = 'BasicInput'
Box.displayName = 'Box'
Container.displayName = 'Container'
Flex.displayName = 'Flex'
FlexText.displayName = 'FlexText'
Icon.displayName = 'Icon'
ImageBackground.displayName = 'ImageBackground'
ImageBg.displayName = 'ImageBg'
Img.displayName = 'Img'
Label.displayName = 'Label'
Link.displayName = 'Link'
Paragraph.displayName = 'Paragraph'
ScrollableFlex.displayName = 'ScrollableFlex'
Span.displayName = 'Span'
Text.displayName = 'Text'
TouchableAbsoluteFlex.displayName = 'TouchableAbsoluteFlex'
TouchableFlex.displayName = 'TouchableFlex'
