import { TransLink } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'

import { Upload } from '../../Assets'
import { BrowseText, StyledDropzone, Title } from './styles'

const Dropzone = ({ onDrop, ...rest }) => {
  return (
    <StyledDropzone onDrop={onDrop} {...rest}>
      <Upload />
      <Title>
        <TransLink
          components={{
            BrowseText: <BrowseText />,
          }}
          i18nKey="files.dragDropCsv"
        />
      </Title>
    </StyledDropzone>
  )
}

Dropzone.defaultProps = {
  onDrop: () => {},
}

Dropzone.propTypes = {
  onDrop: PropTypes.func,
}

export default Dropzone
