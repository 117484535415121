import PropTypes from 'prop-types'
import React from 'react'
import { Portal } from 'react-portal'

export const ButtonPortal = ({ children, id }) => (
  <Portal node={document && document.getElementById(id)}>
    <React.Fragment key="page-title-button-portal">{children}</React.Fragment>
  </Portal>
)

ButtonPortal.defaultProps = {
  id: 'button-portal',
}

ButtonPortal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  id: PropTypes.string,
}
