import { css, styled, themeGet } from '@lyfta/components-theme'

import Arrow from '../../Arrow'
import { Box } from '../../Box'

export const Label = styled(Box).attrs(() => ({
  as: 'label',
  fontSize: 4,
}))`
  position: absolute;
  top: 18px;
  left: 16px;
  font-weight: 600;
  transition: all ${themeGet('transitionTime')} ease;
  user-select: none;
  z-index: 1;

  ${props =>
    props.active &&
    css`
      top: 6px;
      font-size: 13px;
      font-weight: normal;
      color: ${themeGet('colors.font.accent')};
    `};
`

export const Select = styled(Box).attrs(() => ({
  as: 'select',
  m: 0,
  px: 3,
  fontSize: 4,
}))`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  border: 0;
  z-index: 3;
  outline: none;
  background: ${themeGet('colors.clear')};
  ${({ hasValue }) => {
    if (hasValue) {
      return css`
        color: ${themeGet('colors.clear')};
      `
    }
    return ``
  }}
  font-weight: 600;
  transition: all ${themeGet('transitionTime')} ease;
  > option {
    color: ${themeGet('colors.black')};
    background: ${themeGet('colors.alto')};
  }
  appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';

  ${props =>
    props.active &&
    css`
      padding-top: 18px;
    `};
`

export const Description = styled(Box).attrs(() => ({
  px: 3,
  fontSize: 2,
}))`
  top: 6px;
  font-weight: normal;
  color: ${themeGet('colors.dustyGray')};
  transition: all ${themeGet('transitionTime')} ease;
  user-select: none;

  ${props =>
    props.active &&
    css`
      color: ${themeGet('colors.font.accent')};
    `};
`

export const Container = styled(Box)`
  position: relative;
  background: ${themeGet('colors.alto')};
  height: 56px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  ${props =>
    props.active &&
    css`
      border-bottom: 2px solid ${themeGet('colors.font.accent')};
    `};

  ${props =>
    props.error &&
    css`
      border-color: ${themeGet('colors.error')};
    `};
`

export const Wrapper = styled(Box)``

export const Value = styled(Box).attrs(() => ({
  m: 0,
  pl: 3,
  pr: 40,
  fontSize: 4,
}))`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  border: 0;
  // z-index: 2;
  line-height: 72px;
  outline: none;
  background: ${themeGet('colors.clear')};
  font-weight: 600;
  transition: all ${themeGet('transitionTime')} ease;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ disabled }) => {
    if (disabled) {
      return `color: gray;`
    }
    return `color: ${themeGet('colors.black')};`
  }}
`

export const Opener = styled(Arrow).attrs(({ disabled }) => ({
  size: 8,
  direction: 'down',
  color: disabled ? 'gray' : 'slateGray',
}))`
  position: absolute;
  right: 16px;
  top: 24px;
  cursor: pointer;
`
