import { css, styled, themeGet } from '@lyfta/components-theme'

import { Box } from '../../Box'

export { Span } from '../../../BaseComponents/index'

const activeCss = css`
  color: ${themeGet('colors.font.accent')};
`

export const Container = styled(Box).attrs(() => ({
  // px: 3,
  fontSize: 2,
}))`
  top: 6px;
  font-weight: normal;
  color: ${themeGet('colors.roseRed')};
  transition: all ${themeGet('transitionTime')} ease;

  ${props => props.active && activeCss};
`

Container.displayName = 'FieldErrorContainer'
