import { v4 as uuid } from 'uuid'

import { createAsyncAction } from '../../helpers'
import apiCall from '../../services/Api'

export const UPLOAD_FILE = createAsyncAction('files/UPLOAD', {
  withProgress: true,
})
export const GET_UPLOAD_URL = createAsyncAction('files/GET_UPLOAD_URL')
export const CANCEL_UPLOAD = 'files/CANCEL_UPLOAD'
export const REQUEST_FILE_UPLOAD_AND_CREATE_ASSET =
  'files/REQUEST_FILE_UPLOAD_AND_CREATE_ASSET'
export const REQUEST_FILE_UPLOAD = 'files/REQUEST_FILE_UPLOAD'
export const RESET_FILE_UPLOAD = 'files/RESET_FILE_UPLOAD'
export const REMOVE_UPLOADED_FILE_INFO = 'files/REMOVE_UPLOADED_FILE_INFO'

export const uploadFile = (
  endpoint,
  file,
  name = 'content',
  query = {},
  options = {},
) =>
  apiCall({
    endpoint,
    method: 'PUT',
    file,
    withoutAuthorization: true,
    fileFieldName: name,
    query,
    payload: {
      id: uuid(),
      file: {
        name: file.name,
        size: file.size,
      },
    },
    types: UPLOAD_FILE,
    ...options,
  })

export const uploadUserFile = ({
  endpoint,
  file,
  name = 'content',
  usersRole,
  query = {},
  customMessage,
}) =>
  apiCall({
    customMessage,
    endpoint,
    method: 'POST',
    file,
    fileFieldName: name,
    fileParams: {
      users_role: usersRole,
    },
    query,
    payload: {
      id: uuid(),
      file: {
        name: file.name,
        size: file.size,
      },
    },
    types: UPLOAD_FILE,
  })

export const getUploadUrl = attributes =>
  apiCall({
    customMessage: { success: { blockDefaultMessage: true } },
    endpoint: '/signed_urls',
    method: 'POST',
    needsNormalization: false,
    preventAutoRelationships: true,
    query: {
      data: {
        attributes,
      },
    },
    types: GET_UPLOAD_URL,
  })

export const cancelUpload = fileId => ({
  type: CANCEL_UPLOAD,
  payload: {
    id: fileId,
  },
})

export const requestFileUploadAndCreateAsset = (
  id,
  file,
  fileType,
  slug,
  language,
  parentId,
  query = {},
  bucketName = undefined,
  lyftaCampaignId,
  videoDuration,
) => ({
  type: REQUEST_FILE_UPLOAD_AND_CREATE_ASSET,
  payload: {
    id,
    file,
    fileType,
    slug,
    language,
    parentId,
    query,
    bucketName,
    lyftaCampaignId,
    videoDuration,
  },
})

export const requestFileUpload = (
  id,
  file,
  fileType,
  slug,
  language,
  parentId,
  query = {},
) => ({
  type: REQUEST_FILE_UPLOAD,
  payload: {
    id,
    file,
    fileType,
    slug,
    language,
    parentId,
    query,
  },
})

export const resetFileUpload = () => ({
  type: RESET_FILE_UPLOAD,
})

export const removeUploadedFileInfo = fileId => ({
  type: REMOVE_UPLOADED_FILE_INFO,
  fileId,
})
