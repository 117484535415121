import PropTypes from 'prop-types'
import React from 'react'
import { Trans } from 'react-i18next'

export const TransLink = ({
  i18nKey,
  defaults,
  values,
  components,
  wrapper,
  catchString,
  children,
}) => {
  // Catching a piece of string to become it in a link
  if (catchString && children && wrapper) {
    // eslint-disable-next-line
    defaults = children.replace(catchString, wrapper)
  }
  return (
    <Trans
      components={components} // optional -> fallbacks to defaults if not provided
      defaults={defaults}
      i18nKey={i18nKey}
      values={values}
    />
  )
}
// Example
/* <Trans
  i18nKey="signUp.acceptTermPrivacy" // optional -> fallbacks to defaults if not provided
  defaults="hello <Link>beautiful</Link> <Link2>{{what}}</Link2>" // optional defaultValue
  values={{ what: 'world'}}
  components={{
            Link: <LinkMailto href={getTranslation('auth.mailto')} />,
            Link2: (
                <LinkPrivacy
                  href={getTranslation('signUp.links.privacyPolicy')}
                  to={getTranslation('signUp.links.privacyPolicy')}
                  target="_blank"
                />
              ),
          }}>

  // The next 2 properties are required to catch a piece of string and replace it for a Link

  catchString='contact us'
  wrapper='<Link>contact us</Link>'

/> */

TransLink.defaultProps = {
  i18nKey: null,
  catchString: null,
  wrapper: null,
  children: null,
  defaults: {},
  values: null,
  components: null,
}
TransLink.propTypes = {
  catchString: PropTypes.string,
  children: PropTypes.string,
  components: PropTypes.object,
  defaults: PropTypes.object,
  i18nKey: PropTypes.string,
  values: PropTypes.object,
  wrapper: PropTypes.string,
}
