import * as React from 'react'

function SvgSuccess(props) {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5ZM11.3056 16.3691L16.8684 8.58124L15.241 7.41876L10.8038 13.6309L8.6094 12.1679L7.5 13.8321L11.3056 16.3691Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSuccess
