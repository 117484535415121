import { styled } from '@lyfta/components-theme'

import { Flex } from '../../BaseComponents'

export const ScrollableColumn = styled(Flex)`
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: auto;
`
