import toasts from 'Constants/toasts'
import get from 'lodash/get'
import { toast as reactToastify } from 'react-toastify'

export default () => next => action => {
  const toast = get(toasts, action.type)

  // To avoid empty toasts produced by function
  let isToastReturned = true
  if (typeof toast === 'function') {
    isToastReturned = !!toast(action)
  }
  if (toast && isToastReturned) {
    reactToastify[toast(action).type](toast(action).message)
  }

  return next(action)
}
