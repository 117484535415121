import * as React from 'react'

function SvgPresent(props) {
  return (
    <svg height="1em" viewBox="0 0 24 24" width="1em" {...props}>
      <path
        d="M11.5633 7.48804L4.84328 15.408C4.72224 15.5519 4.65932 15.7357 4.66687 15.9235C4.67442 16.1114 4.75189 16.2896 4.88408 16.4232L7.59848 19.1424C7.7332 19.2765 7.91346 19.3549 8.10337 19.362C8.29328 19.3691 8.4789 19.3044 8.62328 19.1808L16.5121 12.4368L11.5633 7.48804ZM11.5633 13.3536C11.5026 13.4143 11.4306 13.4624 11.3513 13.4953C11.272 13.5281 11.1871 13.545 11.1013 13.545C11.0155 13.545 10.9305 13.5281 10.8512 13.4953C10.772 13.4624 10.6999 13.4143 10.6393 13.3536C10.5786 13.293 10.5305 13.2209 10.4976 13.1417C10.4648 13.0624 10.4479 12.9774 10.4479 12.8916C10.4479 12.8058 10.4648 12.7209 10.4976 12.6416C10.5305 12.5623 10.5786 12.4903 10.6393 12.4296L11.5201 11.5584C11.5792 11.4919 11.6512 11.4382 11.7318 11.4006C11.8125 11.363 11.8999 11.3422 11.9888 11.3396C12.0777 11.3369 12.1663 11.3525 12.2489 11.3854C12.3316 11.4182 12.4067 11.4676 12.4696 11.5305C12.5325 11.5934 12.5819 11.6685 12.6147 11.7512C12.6476 11.8339 12.6632 11.9224 12.6605 12.0113C12.6579 12.1002 12.6372 12.1877 12.5995 12.2683C12.5619 12.3489 12.5082 12.4209 12.4417 12.48L11.5633 13.3536Z"
        fill="#D8D8D8"
      />
      <path
        d="M21.2831 9.88321C22.0519 8.90132 22.4347 7.67196 22.3593 6.42717C22.2838 5.18238 21.7553 4.00827 20.8735 3.12645C19.9916 2.24464 18.8175 1.71612 17.5727 1.64065C16.3279 1.56518 15.0986 1.94798 14.1167 2.71681L21.2831 9.88321Z"
        fill="#D8D8D8"
      />
      <path
        d="M17.7263 11.8224C18.7011 11.7322 19.6293 11.3631 20.3999 10.7592L13.2407 3.59998C12.6355 4.37254 12.2663 5.30343 12.1775 6.28078L17.7263 11.8224Z"
        fill="#D8D8D8"
      />
      <path
        d="M3.93122 17.304L3.13442 18.0984C2.76788 18.4656 2.56201 18.9632 2.56201 19.482C2.56201 20.0008 2.76788 20.4984 3.13442 20.8656V20.8656C3.50159 21.2322 3.9992 21.438 4.51802 21.438C5.03683 21.438 5.53444 21.2322 5.90162 20.8656L6.69602 20.0688L3.93122 17.304Z"
        fill="#D8D8D8"
      />
      <path
        d="M4.36501 19.9144L4.0867 19.6361C3.91612 19.4655 3.63955 19.4655 3.46897 19.6361L1.76343 21.3416C1.59285 21.5122 1.59285 21.7888 1.76343 21.9593L2.04174 22.2377C2.21232 22.4082 2.48889 22.4082 2.65947 22.2377L4.36501 20.5321C4.53559 20.3615 4.53559 20.085 4.36501 19.9144Z"
        fill="#D8D8D8"
      />
    </svg>
  )
}

export default SvgPresent
