import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'

import { ColourSelector } from './colourSelector'
import {
  PageBackIconContainer,
  PrevPageButton,
  PrevPageButtonContainer,
  SettingsContainer,
} from './styles'

export const BackgroundPage = ({ player, setCurrentPage }) => {
  return (
    <>
      <PrevPageButtonContainer>
        <PrevPageButton
          className="subtitle-customise-back-button"
          onClick={() => setCurrentPage('customise')}
        >
          <PageBackIconContainer />
          <I18n i18nKey="videoPlayer.labels.background" />
        </PrevPageButton>
      </PrevPageButtonContainer>
      <SettingsContainer>
        <ColourSelector player={player} property="backgroundColor" />
      </SettingsContainer>
      {/* <ResetButtonContainer>
        <ResetButton>
          <I18n i18nKey="videoPlayer.labels.resetAll" />
        </ResetButton>
      </ResetButtonContainer> */}
    </>
  )
}

BackgroundPage.propTypes = {
  player: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
}
