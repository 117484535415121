import { getLocale } from '@lyfta/components-data/src/Store/Selectors/persist'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Card from './Card'

const selector = createStructuredSelector({
  locale: getLocale,
})

const actions = {}

export default connect(selector, actions)(Card)
