import {
  Box,
  Flex,
  Span,
  Subtitle1,
  Subtitle2,
  styled,
} from '@lyfta/components-ui'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled(Box).attrs({
  pt: 3,
  pr: 3,
  pl: 5,
  pb: 6,
})`
  background: ${themeGet('colors.sa_gradient_a')};
  position: relative;
`

export const LeftColumnContainer = styled(Flex).attrs({
  pt: 3,
  pb: 3,
})`
  flex-direction: column;
  gap: 16px;
  justify-content: space-around;
`

export const RightColumnContainer = styled(Flex)`
  padding-bottom: 12px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`

export const PercentText = styled(Subtitle1)`
  color: ${themeGet('colors.neutral_100')};
`

export const TitleText = styled(Subtitle2)`
  color: ${themeGet('colors.neutral_100')};
`

export const ProgressBar = styled(Flex)`
  border: 1px solid ${themeGet('colors.neutral_100')};
  border-radius: 50px;
  height: 8px;
`

export const ProgressFill = styled(Flex).attrs(p => ({
  width: `${p.pct}%`,
  backgroundColor: themeGet('colors.neutral_100')(p),
}))`
  height: 100%;
`

export const QuestTitle = styled.h2.attrs(() => ({
  pt: 3,
}))`
  all: unset;
`

export const Percent = styled(Span).attrs(() => ({
  color: 'white',
  fontWeight: 'bold',
}))``

export const UpperContainer = styled(Flex)`
  justify-content: space-between;
`

Container.displayName = 'TopPanel:Container'
LeftColumnContainer.displayName = 'TopPanel:LeftColumnContainer'
Percent.displayName = 'TopPanel:Percent'
PercentText.displayName = 'TopPanel:PercentText'
ProgressBar.displayName = 'TopPanel:ProgressBar'
ProgressFill.displayName = 'TopPanel:ProgressFill'
QuestTitle.displayName = 'TopPanel:QuestTitle'
RightColumnContainer.displayName = 'TopPanel:RightColumnContainer'
TitleText.displayName = 'TopPanel:TitleText'
UpperContainer.displayName = 'TopPanel:UpperContainer'
