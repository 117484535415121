import { opacify, styled, themeGet } from '@lyfta/components-theme'
import ReactTable from 'react-table'

export const StyledTable = styled(ReactTable)`
  width: 100%;

  &.ReactTable {
    border: 0;
    display: flex;
    flex: 1;
    justify-content: space-between;
    overflow-y: hidden;
  }

  &.ReactTable .rt-table {
    flex: auto 0;
    overflow-y: hidden;
    .rt-tbody {
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-gutter: stable;
    }
  }

  &.ReactTable .rt-thead {
    &.-filters {
      // position: absolute;
      width: 100%;
    }
    &.-header {
      box-shadow: none;
    }

    .rt-tr {
      border-bottom: 1px solid ${themeGet('colors.neutral_400')};

      :hover {
        background-color: inherit;
      }
    }
    padding-right: 16px;

    & .rt-th {
      display: flex;
      outline: none;
      font-weight: bold;
      justify-content: flex-start;
      align-items: left;
      border-right: 0 !important;
      cursor: default;
      letter-spacing: 0.5px;
      color: ${themeGet('colors.black_600')};

      > div {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      & > select {
        padding-left: 0px;
        border: none;
      }

      &.-cursor-pointer {
        cursor: pointer;

        &:after {
          margin-left: 4px;
          content: '↑↓';
        }
      }
      &.-sort-asc {
        box-shadow: none !important;
        &:after {
          content: url("data:image/svg+xml;utf8,<svg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M5.00001 -0.000976562L9.3485 4.35684L7.93277 5.76954L6.00001 3.83263V12.0007H4.00001V3.83263L2.06725 5.76954L0.65152 4.35684L5.00001 -0.000976562Z' fill='%23455368'/></svg>");
          margin-left: 4px;
        }
      }

      &.-sort-desc {
        box-shadow: none !important;

        &:after {
          content: url("data:image/svg+xml;utf8,<svg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.99999 12.001L0.651502 7.64315L2.06723 6.23046L3.99999 8.16737L3.99999 -0.000692835L5.99999 -0.00069266L5.99999 8.16737L7.93275 6.23046L9.34848 7.64316L4.99999 12.001Z' fill='%23455368'/></svg>");
          margin-left: 4px;
        }
      }
    }
  }

  &.ReactTable .rt-tr-group {
    border: none;
  }

  &.ReactTable .rt-tr {
    height: ${({ variableRowHeight }) =>
      variableRowHeight ? 'unset' : '48px'};
    min-height: ${({ variableRowHeight }) =>
      variableRowHeight ? '48px' : 'unset'};
    border-bottom: 1px solid ${themeGet('colors.neutral_400')};
    cursor: ${({ hasAction }) => (hasAction ? 'pointer' : 'default')};
    :hover {
      background-color: ${p => {
        return p.hover
          ? opacify(0.12, themeGet('colors.tableRowHover')(p))
          : 'none'
      }};
    }

    &.-selected {
      background-color: ${p =>
        opacify(0.12, themeGet('colors.tableRowHover')(p))};
    }
    &.disabled {
      color:  ${themeGet('colors.black_600')};
      /* color: ${p => opacify(0.12, themeGet('colors.dustyGray')(p))}; */
      font-style: italic;
    }
    & .rt-td {
      align-items: center;
      border: none;
      display: flex;
      outline: none;
      font-weight: normal;
      white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

      > a {
        text-decoration: none;
      }

      > span {
        display: flex;
        flex-direction: row;
        flex: 1;
        > span.react-loading-skeleton {
          display: flex;
          height: 100%;
        }
      }
    }
  }

  &.ReactTable .rt-noData {
    margin: auto;
    left: unset;
    position: unset;
    top: unset;
    transform: unset;
    width: max-content;
  }
`
