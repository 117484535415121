import { combineReducers } from 'redux'

import modal from './modal'
import preview from './preview'
import search from './search'
import tags from './tags'

export default combineReducers({
  modal,
  preview,
  tags,
  search,
})
