import * as React from 'react'

function SvgStudies(props) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 117 114"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 0V114H117V0H0ZM10.3175 9.19515H21.5922V13.5574H10.3175V9.19515ZM113.065 109.756H3.93572V22.6347H113.171L113.171 109.756H113.065Z"
        fill="#3333CC"
      />
      <path
        d="M91.8981 75.0961V57.1768H86.0481C85.5162 55.0551 84.6656 53.0506 83.8143 51.1642L87.9623 46.5667L76.3688 34.0707L72.2208 38.6683C70.4123 37.6074 68.6046 36.7819 66.6896 36.1925V29.7085H50.416V36.1925C48.5017 36.7819 46.5868 37.6074 44.8848 38.6683L40.7368 34.0707L29.1433 46.6853L33.2913 51.2829C32.3342 53.1693 31.5894 55.173 30.9511 57.2954H25.1011V75.0969H30.9511C31.4829 77.2186 32.3335 79.223 33.2913 81.1094L29.1433 85.707L40.6308 98.321L44.7789 93.7234C46.5873 94.7843 48.3951 95.6098 50.31 96.1992V102.683H66.5837V96.1992C68.4979 95.6098 70.4129 94.7843 72.1148 93.7234L76.2629 98.321L87.7499 85.7064L83.6018 81.1088C84.559 79.2225 85.3038 77.2188 85.8356 75.0963L91.8981 75.0961ZM58.4999 46.9203L50.4161 66.8438V86.0599C48.1824 84.999 46.1616 83.4661 44.3531 81.5804C40.4174 77.3362 38.5031 71.7958 38.5031 66.2547C38.5031 60.7135 40.4174 55.1731 44.3531 50.9289C48.2889 46.6847 53.394 44.563 58.4995 44.563C63.6047 44.563 68.7105 46.6847 72.6458 50.9289C76.5816 55.1731 78.4958 60.7135 78.4958 66.2547C78.4958 71.7958 76.5816 77.3362 72.6458 81.5804C70.8374 83.4668 68.8166 84.9991 66.5829 86.0599L66.5839 66.8438L58.4999 46.9203ZM55.4155 66.9617L54.7772 66.4903L57.7551 59.0635H59.1376L62.1155 66.4903L61.4772 66.9617L58.4997 64.7219L55.4155 66.9617ZM56.5856 71.3239V87.8286C55.8408 87.7106 55.0966 87.5926 54.3518 87.4752V71.3242L55.5219 72.1496L56.5856 71.3239ZM60.4143 71.3239L61.5844 72.1494L62.7545 71.3239V87.4749C62.0097 87.7109 61.2655 87.8284 60.5207 87.8284V71.3237L60.4143 71.3239Z"
        fill="#3333CC"
      />
    </svg>
  )
}

export default SvgStudies
