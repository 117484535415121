// Super Admin
// Organisation Admin
// Licencing Admin
// Metadata Admin - content_manager
// Metadata Contributor - content_contributor
// Pedagogy Admin
// Default access (Read Only to everything) - blank
export const adminPermissions = [
  'super_user',
  'org_admin',
  'licensing_admin',
  'collections_manager',
  'content_manager',
  'tag_manager',
  'lesson_plan_admin',
  'meeting_admin',
  'dashboard_admin',
]
