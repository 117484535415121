import { css, styled } from '@lyfta/components-theme'
import { Flex } from '@lyfta/components-ui'

export const Container = styled(Flex).attrs(() => ({}))`
  ${props =>
    !props.disableContainerStyling &&
    css`
      width: '100%';
      height: '100%';
      justify-content: center;
      padding-top: 5px;
      padding-bottom: 5px;
    `}
`
