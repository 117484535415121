import React from 'react'

function SvgFullscreenExpand(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      {...props}
    >
      <path d="M8 36V23.6591H11.3928V30.1838L30.1838 11.3928H23.6591V8H36V20.3409H32.6072V13.8162L13.8162 32.6072H20.3409V36H8Z" />
    </svg>
  )
}

export default SvgFullscreenExpand
