import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { questTrackerData } from '../../../Store/Actions/questTracker'
import { getShowAchievement } from '../../../Store/Selectors/questTracker'
import Achievement from './Achievement'

const selector = createStructuredSelector({
  showAchievement: getShowAchievement,
})

const actions = {
  hideAchievement: questTrackerData.hideAchievement,
}

export default connect(selector, actions)(Achievement)
