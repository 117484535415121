import * as React from 'react'

function SvgLessons(props) {
  return (
    <svg height="24" viewBox="0 0 24 24" width="24" {...props}>
      <path d="M11 20V6C7.77978 4.55644 5.9015 4.12316 3.0243 4.00174C1.91139 3.95477 1 4.86592 1 5.98308V16.0664C1 17.1432 1.84749 18.0282 2.91776 18.1138C3.90086 18.1924 5.08119 18.3148 6 18.4956C8.00046 18.8892 11 20 11 20Z" />
      <path d="M13 20V6C16.2202 4.55644 18.0985 4.12316 20.9757 4.00174C22.0886 3.95477 23 4.86592 23 5.98308V16.0664C23 17.1432 22.1525 18.0282 21.0822 18.1138C20.0991 18.1924 18.9188 18.3148 18 18.4956C15.9995 18.8892 13 20 13 20Z" />
    </svg>
  )
}

export default SvgLessons
