import {
  getPermissions,
  getViewerType,
} from '@lyfta/components-data/src/Store/Selectors/viewer'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

export const PermissionWrapper = ({ children, requiredPermissions, roles }) => {
  const { permissions } = useSelector(getPermissions)
  const viewerType = useSelector(getViewerType)

  let foundParents = false

  if (roles) {
    Object.keys(roles).forEach(function (key) {
      if (roles[key] === 'parents') foundParents = true
    })
  }

  if (viewerType === 'parents' && !foundParents) return null

  if (requiredPermissions.length === 0) {
    return <>{children}</>
  }

  const fullPermissions = ['super_user', ...requiredPermissions]

  const found = fullPermissions.some(r => permissions?.includes(r))

  if (!found) return null

  return <>{children}</>
}

PermissionWrapper.defaultProps = { requiredPermissions: [], roles: {} }
PermissionWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  requiredPermissions: PropTypes.array,
  roles: PropTypes.object,
}
