import { css, styled } from '@lyfta/components-theme/src/styling/components'
import { themeGet } from '@styled-system/theme-get'
import React from 'react'

import Check2 from '../../Assets/Svg/Check'
import { ButtonNew } from '../ButtonNew'
import { Flex } from '../Flex'

export const LocationButton = styled(ButtonNew).attrs(({ inverse }) => ({
  type: 'button',
  mb: 3,
  width: ['100%', 260],
  height: 50,
  shadow: !inverse,
}))`
  border-radius: 100vw;
  border: 2px solid ${themeGet('colors.primary')};
  transition: all 0.17s;
  justify-content: center;
  font-weight: 600;

  > div {
    background-color: ${themeGet('colors.primary')};

    svg,
    path {
      fill: ${themeGet('colors.primary')};
    }
  }

  &:hover:enabled {
    background-color: ${themeGet('colors.lyftaTurquoiseLight')} !important;
    border: 2px solid ${themeGet('colors.lyftaTurquoiseLight')};
  }

  &:hover {
    > div {
      background-color: ${themeGet('colors.white')};

      svg,
      path {
        fill: ${themeGet('colors.lyftaTurquoiseLight')};
      }
    }
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${themeGet('colors.teal_100')};
      color: ${themeGet('colors.white')};
      > div {
        background-color: ${themeGet('colors.white')};

        svg,
        path {
          fill: ${themeGet('colors.teal_100')};
        }
      }
    `};
`

export const LocationCheckedIcon = styled(Flex).attrs(() => ({
  children: <Check2 />,
  width: 20,
  height: 20,
  alignItems: 'center',
  justifyContent: 'center',
}))`
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 25px;
  transition: all 0.17s;

  ${({ active }) =>
    active &&
    css`
      background-color: ${themeGet('colors.white')};
    `};
`
