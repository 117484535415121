import { opacify } from 'polished'

import baseColors from '../Main/colors'

const saPalette = {
  // DS student app
  sa_primary_60: 'hsla(261, 84%, 93%, 1)', // #E9DEFC
  sa_primary_70: 'hsla(260, 79%, 81%, 1)', // #C3AAF5
  sa_primary_90: 'hsla(262, 82%, 62%, 1)', // #884FEE
  sa_primary_110: 'hsla(262, 83%, 43%, 1)', // #5412C9
  sa_primary_130: 'hsla(265, 83%, 23%, 1)', // #330A6C

  sa_utility_a: 'hsla(26, 82%, 50%, 1)', // #E87317
  sa_utility_b: 'hsla(350, 87%, 54%, 1)', // #F02245
  sa_utility_c: 'hsla(249, 88%, 59%, 1)', // #583CF2
  sa_utility_d: 'hsla(270, 62%, 61%, 1)', // #9B5DD9
  sa_utility_e: 'hsla(144, 74%, 38%, 1)', // #19AB54
  sa_utility_f: 'hsla(188, 97%, 37%, 1)', // #03A1BA
  sa_utility_g: 'hsla(300, 97%, 41%, 1)', // #CE03CE
  sa_utility_h: 'hsla(30, 69%, 57%, 1)', // #DD9044
}

const gradients = {
  // DS student app gradients
  sa_gradient_a: `linear-gradient(92deg, ${saPalette.sa_primary_90} 7.58%, #884EEE 7.59%, #462BAB 85.46%)`,
  sa_gradient_b: `linear-gradient(270deg, ${saPalette.sa_primary_90} 0%, ${saPalette.sa_primary_90} 0.01%, hsla(253, 60%, 42%, 1) 100%)`,
  sa_gradient_c: `linear-gradient(136deg, hsla(336, 96%, 65%, 1) 0%, ${saPalette.sa_primary_90} 83.33%)`,
  sa_gradient_d:
    'linear-gradient(134deg, hsla(336, 96%, 65%, 1) 18.75%, hsla(336, 96%, 65%, 1) 51.78%, hlsa(336, 52%, 45%, 1) 100%)',
  sa_gradient_e:
    'linear-gradient(90deg, hsla(336, 52%, 45%, 1) 6.25%, hsla(336, 96%, 65%, 1) 60.94%)',
  sa_gradient_f:
    'linear-gradient(135deg, hsla(168, 51%, 62%, 1) 15.63%, hsla(167, 33%, 43%, 1) 100%)',
  sa_gradient_g:
    'linear-gradient(90deg, hsla(167, 33%, 43%, 1) 9.38%, hsla(168, 51%, 62%, 1) 82.81%)',
  sa_gradient_h:
    'linear-gradient(102.48deg, hsla(0, 0%, 100%, 1) 3.28%, hsla(0, 0%, 100%, 0.7) 99.33%)',
  sa_gradient_i:
    'linear-gradient(90deg, hsla(216, 20%, 34%, 0.5) 3.84%, hsla(218, 17%, 91%, 0.5) 99.33%)',
}

const colors = {
  ...baseColors,
  ...saPalette,
  ...gradients,
}

colors.menuButtonHover = '#DCC1FF'
colors.primary = colors.sa_primary_90
colors.tertiary = colors.teal_100
colors.buttonBG = colors.teal_100
colors.alto = '#d8d8d8'
colors.error = colors.darkGray
colors.offline = '#0094ac'
colors.tabPrimary = colors.darkGray
colors.transparentBackground = 'rgba(0, 0, 0, 0.8)'
colors.questTracker = {
  activityPanel: {
    activity: {
      border: 'rgb(206, 206, 210)',
      hover: colors.roseRed,
    },
  },
}

colors.font = {
  primary: colors.codGray,
  accent: colors.font.accent,
  secondary: 'rgb(93, 93, 93)',
  outline: '#fef8e8',
  link: colors.roseRed,
  tertiary: colors.teal_100,
}

// DS button colors

colors.black_700_50 = opacify(-(1 - 0.5), colors.black_700)
colors.neutral_100_40 = opacify(-(1 - 0.4), colors.neutral_100)

export default colors
