import { omit, pick } from '@styled-system/props'
import PropTypes from 'prop-types'
import React from 'react'

import { Description, StyledReactSelect, Wrapper } from './styles'

let emptyOptionAdded = false

const ReactSelect = ({
  description,
  emptyOption,
  focused,
  id,
  input,
  options,
  ...rest
}) => {
  if (emptyOption === true && emptyOptionAdded === false) {
    options.unshift({ value: '', label: '' })
    emptyOptionAdded = true
  }

  const styledSystemProps = pick(rest)
  const restWithoutStyledSystemProps = omit(rest)

  return (
    <Wrapper className="reactselect-wrapper" {...styledSystemProps}>
      <StyledReactSelect
        id={id}
        options={options}
        {...input}
        {...restWithoutStyledSystemProps}
        menuPortalTarget={document.body}
      />
      {description && (
        <Description focused={focused}>{description}</Description>
      )}
    </Wrapper>
  )
}

ReactSelect.defaultProps = {
  description: null,
  emptyOption: false,
  extendComponents: {},
  extendStyles: {},
  focused: false,
  id: '',
  input: null,
  isSearchable: false,
}

ReactSelect.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  emptyOption: PropTypes.bool,
  extendComponents: PropTypes.object,
  extendStyles: PropTypes.object,
  focused: PropTypes.bool,
  id: PropTypes.string,
  input: PropTypes.object,
  isSearchable: PropTypes.bool,
  options: PropTypes.array.isRequired,
}

export default ReactSelect
