import { createReducer } from '../../helpers'
import {
  createDeleteReducerHandlers,
  createEntityFields,
  createReducerHandlers,
} from '../../utils'
import {
  CREATE_LYFTA_CAMPAIGN,
  DELETE_LYFTA_CAMPAIGN,
  LOAD_LYFTA_CAMPAIGN,
  LOAD_LYFTA_CAMPAIGNS,
  UPDATE_LYFTA_CAMPAIGN,
} from '../Actions/lyftaCampaigns'

const initialState = {
  ...createEntityFields('lyftaCampaigns'),
}

const handlers = {
  ...createDeleteReducerHandlers('lyftaCampaigns', DELETE_LYFTA_CAMPAIGN, {
    mapToKey: 'lyftaCampaigns',
  }),
  ...createReducerHandlers('lyftaCampaigns', LOAD_LYFTA_CAMPAIGNS, {
    withReplace: true,
    mapToKey: 'lyftaCampaigns',
  }),
  ...createReducerHandlers('lyftaCampaigns', LOAD_LYFTA_CAMPAIGN, {
    mapToKey: 'currentLyftaCampaign',
    singular: true,
  }),
  ...createReducerHandlers('lyftaCampaigns', CREATE_LYFTA_CAMPAIGN, {
    mapToKey: 'currentLyftaCampaign',
    singular: true,
  }),
  ...createReducerHandlers('lyftaCampaigns', UPDATE_LYFTA_CAMPAIGN, {
    mapToKey: 'currentLyftaCampaign',
    singular: true,
  }),
}

export default createReducer(initialState, handlers)
