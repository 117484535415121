import { intercomTourId } from '@lyfta/components-config'
import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'
import { IntercomAPI } from 'react-intercom'

import { Help } from '../../../Assets'
import { Icon } from '../../../Components'
import { Label, MenuButton, MenuItemBox, MenuItemWrapper } from './styles'

export const HelpIntercom = ({ t, isOpen }) => {
  const openIntercom = () => {
    if (intercomTourId) IntercomAPI('startTour', intercomTourId)
  }
  return (
    <MenuItemWrapper isOpen={isOpen}>
      <MenuItemBox isOpen={isOpen}>
        <MenuButton
          data-intercom-target="Help"
          isOpen={isOpen}
          title={t('tooltips.help')}
          onClick={() => openIntercom()}
        >
          <Icon glyph={Help} height={21} width={21} />
          <Label>
            <I18n text="actions.help" />
          </Label>
        </MenuButton>
      </MenuItemBox>
    </MenuItemWrapper>
  )
}
HelpIntercom.defaultProps = {
  isOpen: undefined,
}
HelpIntercom.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
}
