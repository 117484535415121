import { darken } from 'polished'

import baseColors from '../Main/colors'

const colors = {
  ...baseColors,

  rubyRed: '#F41D1D',
  chiliPepper: '#C31717',
}

colors.primary = colors.ufoGreen
colors.secondary = colors.blue_100
colors.primaryOpacity = colors.ufoGreenOpacity
colors.primaryLightHover = colors.ufoGreen50
colors.tabPrimary = colors.darkGray
colors.buttonBG = colors.ufoGreen

colors.formLabel = colors.ufoGreen
colors.formUnderline = colors.ufoGreen

colors.SWEDeleteCopy = colors.rubyRed
colors.SWEDeleteActive = colors.chiliPepper

colors.font = {
  accent: colors.ufoGreen,
  secondary: colors.ufoGreen,
  link: colors.blue_100,
  tertiary: colors.darkGray,
  button: colors.lightGray,
}

colors.primaryHover = darken(0.01, colors.primary)
colors.primaryActive = darken(0.1, colors.primary)

export default colors
