import {
  getAllEntities,
  getEntity,
  getEntityStateField,
} from '@lyfta/components-data'
import { getTranslatedField } from '@lyfta/components-i18n'
import { filter } from 'lodash'
import { createSelector } from 'reselect'

export const getTagGroupId = (state, props) => props.tagGroupId
export const getTags = getAllEntities('tags')
export const getCurrentTag = getEntity('tags')

export const getTagName = createSelector(getCurrentTag, tag => {
  return getTranslatedField(tag, 'name')
})
export const getTagsForGroup = createSelector(
  getTags,
  getTagGroupId,
  (tags, tagGroupId) => {
    return filter(tags, t => t && t.tagGroup && t.tagGroup.id === tagGroupId)
  },
)

export const getIsLoaded = getEntityStateField('tags', 'isLoaded')
export const getIsLoading = getEntityStateField('tags', 'isLoading')
export const getPaged = getEntityStateField('tags', 'paged')
