import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'

import VideoPlayer from '../Components/VideoPlayer'

const Video = ({
  locale,
  iframeUrl,
  pageTitle,
  onProgress,
  onClose,
  posterUrl,
}) => (
  <>
    <VideoPlayer
      videoUrl={iframeUrl}
      posterUrl={posterUrl}
      userLanguage={locale}
      onVideoProgress={onProgress}
    />
    <header className="site-header">
      <div className="title">{pageTitle}</div>

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="close-button" onClick={onClose}>
        <div className="close-text">
          <I18n text="actions.close" />
        </div>
        <svg viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg">
          <path d="M231.6 256l130.1-130.1c4.7-4.7 4.7-12.3 0-17l-22.6-22.6c-4.7-4.7-12.3-4.7-17 0L192 216.4 61.9 86.3c-4.7-4.7-12.3-4.7-17 0l-22.6 22.6c-4.7 4.7-4.7 12.3 0 17L152.4 256 22.3 386.1c-4.7 4.7-4.7 12.3 0 17l22.6 22.6c4.7 4.7 12.3 4.7 17 0L192 295.6l130.1 130.1c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17L231.6 256z" />
        </svg>
      </div>
    </header>
  </>
)
Video.propTypes = {
  iframeUrl: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  posterUrl: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onProgress: PropTypes.func.isRequired,
}

Video.defaultProps = {
  posterUrl: null,
}

export default Video
