/* eslint-disable max-lines-per-function */
import videojs from 'video.js'

const Component = videojs.getComponent('Component')

const chapterMarkerClassName = 'vjs-chapter-marker'

// a map of player to clean up functions
const playerTrackCheckCache = new Map()

/**
 * The class for chapter markers
 */
class ChapterMarkersProgressBarControl extends Component {
  /**
   * @param {import('video.js').VideoJsPlayer} player player
   */
  static updateMarkers(player) {
    if (playerTrackCheckCache.has(player)) {
      const cleanup = playerTrackCheckCache.get(player)
      cleanup()

      playerTrackCheckCache.delete(player)
    }

    const playerContainer = player.el()
    const playheadWell = playerContainer.getElementsByClassName(
      'vjs-progress-holder',
    )[0]

    // Remove all existing markers
    playheadWell
      .querySelectorAll(`.${chapterMarkerClassName}`)
      .forEach(elem => {
        elem.remove()
      })

    const videoDuration = player.duration()

    // find any chapter tracks
    const textTracks = Array.from(player.remoteTextTracks())

    const chapterTracks = textTracks.filter(track => track.kind === 'chapters')

    if (chapterTracks.length === 0) {
      return
    }

    const chapterTrack = chapterTracks[0]

    const updateMarkers = () => {
      // Remove all existing markers
      playheadWell
        .querySelectorAll(`.${chapterMarkerClassName}`)
        .forEach(elem => {
          elem.remove()
        })

      const chapters = Array.from(chapterTracks[0].cues ?? [])

      const iMax = chapters.length

      // Loop over each cue point, dynamically create a div for each
      // then place in div progress bar
      for (let i = 0; i < iMax; i += 1) {
        if (chapters[i].endTime < 0 || chapters[i].startTime > videoDuration) {
          // ignore the chapter if it's outside the video duration

          // eslint-disable-next-line no-continue
          continue
        }

        const elem = document.createElement('div')

        elem.className = chapterMarkerClassName
        elem.id = `cp${i}`

        const startPercentage = Math.max(
          (chapters[i].startTime / videoDuration) * 100,
          0,
        )
        elem.style.left = `${startPercentage}%`

        const endPercentage = Math.min(
          (chapters[i].endTime / videoDuration) * 100,
          100,
        )

        elem.style.width = `${endPercentage - startPercentage}%`

        const toolTipSpan = document.createElement('span')

        toolTipSpan.className = 'tooltip-text'
        toolTipSpan.innerHTML = chapters[i].text
        elem.appendChild(toolTipSpan)

        playheadWell.appendChild(elem)
      }
    }

    if (!chapterTrack.loaded_) {
      const onLoaded = () => {
        updateMarkers()
      }

      const interval = setInterval(() => {
        if (chapterTrack.loaded_) {
          onLoaded()
          clearInterval(interval)
        }
      }, 200)

      playerTrackCheckCache.set(player, () => {
        clearInterval(interval)
      })
    } else {
      updateMarkers()
    }
  }
}

videojs.registerComponent(
  'ChapterMarkersProgressBarControl',
  ChapterMarkersProgressBarControl,
)

export default ChapterMarkersProgressBarControl
