import { BREAKPOINTS, useTheme } from '@lyfta/components-theme'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useBreakpointAnimation = (breakpoint, closeSidebar, isOpen) => {
  const [animationState, setAnimationState] = useState('closed')
  const {
    sizes: {
      sidebarSize: { open: openSize, closed: closedSize },
    },
  } = useTheme()

  const location = useLocation()
  useEffect(() => {
    if (breakpoint === BREAKPOINTS.MOBILE) closeSidebar(false)
  }, [location, closeSidebar])

  const animation =
    breakpoint === BREAKPOINTS.MOBILE
      ? {
          mobileOpen: { height: '100%' },
          mobileClosed: { height: '0px' },
        }
      : {
          open: { width: openSize, height: '100%' },
          closed: { width: closedSize, height: '100%' },
        }

  useEffect(() => {
    if (breakpoint === 'mobile') {
      setAnimationState(isOpen ? 'mobileOpen' : 'mobileClosed')
    }
    if (breakpoint !== 'mobile') {
      setAnimationState(isOpen ? 'open' : 'closed')
    }
  }, [breakpoint, isOpen])

  return [animation, animationState]
}
