const headCache = {}
export const getCachedHead = async (url, skipCache = false, signal = null) => {
  if (!skipCache && headCache[url]) {
    return headCache[url]
  }

  headCache[url] = fetch(url, {
    method: 'head',
    signal,
  })

  return headCache[url]
}
