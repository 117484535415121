import { createReducer } from '../../helpers'
import { createEntityFields, createReducerHandlers } from '../../utils'
import {
  CREATE_MEETING_INVITE,
  LOAD_MEETING_INVITE,
  LOAD_MEETING_INVITES,
  UPDATE_MEETING_INVITE,
} from '../Actions/meetingInvites'

const initialState = {
  ...createEntityFields('meetingInvites'),
}

const handlers = {
  ...createReducerHandlers('meetingInvites', LOAD_MEETING_INVITES, {
    withReplace: true,
    mapToKey: 'meetingInvites',
  }),
  ...createReducerHandlers('meetingInvites', LOAD_MEETING_INVITE, {
    mapToKey: 'currentMeetingInvite',
    singular: true,
  }),
  ...createReducerHandlers('meetingInvites', UPDATE_MEETING_INVITE, {
    mapToKey: 'currentMeetingInvite',
    singular: true,
  }),
  ...createReducerHandlers('meetingInvites', CREATE_MEETING_INVITE, {
    mapToKey: 'currentMeetingInvite',
    singular: true,
  }),
}

export default createReducer(initialState, handlers)
