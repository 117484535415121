import get from 'lodash/get'

import { createReducer } from '../../helpers'
import { AUTHENTICATE } from '../Actions/auth'
import {
  CLEAR_RESOURCE_ID,
  LOG_OUT,
  SET_EMAIL,
  SET_FIRSTNAME,
  SET_JWT,
  SET_LASTNAME,
  SET_LESSONPLAN_ID,
  SET_ORGANIZATION,
  SET_PATH,
  SET_RESOURCE_ID,
  SET_SOURCE,
  SET_USER_TYPE,
} from '../Actions/viewer'

const initialState = {
  jwt: null,
}

export const updatePersist = (state, key, value) => {
  const nextState = state.merge({ [key]: value })
  return nextState
}

const handlers = {
  [AUTHENTICATE.SUCCESS]: (state, { payload }) =>
    updatePersist(state, 'jwt', get(payload, 'data.jwt')),
  [SET_JWT]: (state, { payload: { token } }) =>
    updatePersist(state, 'jwt', token),
  [SET_RESOURCE_ID]: (state, { resourceId }) =>
    updatePersist(state, 'resourceId', resourceId),
  [SET_LESSONPLAN_ID]: (state, { lessonPlanId }) =>
    updatePersist(state, 'lessonPlanId', lessonPlanId),
  [SET_USER_TYPE]: (state, { userType }) =>
    updatePersist(state, 'userType', userType),
  [SET_EMAIL]: (state, { email }) => updatePersist(state, 'email', email),
  [SET_FIRSTNAME]: (state, { firstname }) =>
    updatePersist(state, 'firstname', firstname),
  [SET_PATH]: (state, { path }) => updatePersist(state, 'path', path),
  [SET_LASTNAME]: (state, { lastname }) =>
    updatePersist(state, 'lastname', lastname),
  [SET_ORGANIZATION]: (state, { organization }) =>
    updatePersist(state, 'organization', organization),
  [SET_SOURCE]: (state, { source }) => updatePersist(state, 'source', source),

  [CLEAR_RESOURCE_ID]: state => updatePersist(state, 'resourceId', null),
}

const clearPersist = (state, initial) => {
  return state.merge(initial)
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
  resetFn: clearPersist,
})
