import baseShadows from '../Main/shadows'
import spacing from '../Main/space'
import colors from './colors'

const { space } = spacing

const saShadows = {
  primaryInnerIndicator: `0 0 0 ${space[2]} ${colors.black_600}`,
  secondaryInnerIndicator: `0 0 0 ${space[1]} ${colors.black_600}`,
}

const shadows = {
  ...baseShadows,
  ...saShadows,
}

export default shadows
