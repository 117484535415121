import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { map, merge, uniq } from 'lodash'

import { validLanguages } from '../constants/validLanguages'
import { resources } from '../resources'
import { setDateLocale } from './date'

export const BrowserLanguageDetector = new LanguageDetector(null, {
  checkWhitelist: true,
  caches: ['localStorage', 'cookie'],
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  order: ['querystring', 'cookie', 'localStorage', 'navigator'],
})

function getInitializationOptions({ ...options }) {
  return merge(
    {
      whitelist: validLanguages,
      nonExplicitWhitelist: true,
      load: 'languageOnly',
      resources,
      fallbackLng: 'en',
      returnEmptyString: false,
      interpolation: {
        escapeValue: false, // react already safe from xss
        format(value, format) {
          if (format === 'uppercase') return value.toUpperCase()
          if (format === 'lowercase') return value.toLowerCase()
          return value
        },
      },
      ns: ['common'],
      defaultNS: 'common',
      fallbackNS: ['common'],
    },
    options,
  )
}

export const initialise = (
  additionalTranslations = [],
  languageDetector = BrowserLanguageDetector,
  options = {},
) => {
  const namespaces = uniq(map(additionalTranslations, t => t.namespace))
  const [defaultNs] = namespaces

  if (languageDetector) {
    i18next.use(languageDetector)
  }

  i18next.init(getInitializationOptions({ options }), () =>
    setDateLocale(i18next.language),
  )

  map(additionalTranslations, translation =>
    i18next.addResourceBundle(
      translation.language,
      translation.namespace,
      translation.translations,
    ),
  )

  i18next.loadNamespaces(namespaces)
  i18next.setDefaultNamespace(defaultNs)

  i18next.on('languageChanged', lng => setDateLocale(lng))
}

export { i18next }
