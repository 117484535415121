import { createSelector } from 'reselect'

import { getAllEntities, getEntity } from '../../../utils'

export const getStudents = getAllEntities('students', 'users', 'studentUsers')

export const getCurrentClass = getEntity(
  'classes',
  'classes',
  'classes',
  'class',
)

export const getStudentsActiveWithoutStudentsClass = createSelector(
  getStudents,
  getCurrentClass,
  (users = {}, klass) => {
    const idsToRemove = klass?.students?.map(student => student.id)

    if (!users) return []

    return users.filter(student => {
      return (
        idsToRemove &&
        !idsToRemove.includes(student.id) &&
        student.state === 'active'
      )
    })
  },
)

export const getCurrentStudent = getEntity('students', 'users', 'studentUsers')

export const getStudentUserName = createSelector(
  getCurrentStudent,
  (user = {}) => {
    const { firstName, lastName, email } = user

    if (firstName || lastName) {
      return `${firstName} ${lastName} `
    }

    return email
  },
)
export const getStudentClasses = createSelector(
  getCurrentStudent,
  (user = {}) => {
    const { klasses } = user

    return klasses
  },
)
