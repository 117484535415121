import { saveExperience } from '@lyfta/components-data/src/Store/Actions/experiences'
import { getJWT } from '@lyfta/components-data/src/Store/Selectors/persist'
import { getViewerId } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { StudentApp } from '../../../Assets'
import { Icon } from '../../../Components'
import { openInStudentApp, reactEvent } from '../../../Services'
import { Label, MenuButton, MenuItemBox, MenuItemWrapper } from './styles'

export const OpenInStudentApp = ({ t, isOpen }) => {
  const dispatch = useDispatch()
  const teacherId = useSelector(getViewerId)
  const jwt = useSelector(getJWT)

  const onRocketClick = () => {
    reactEvent({
      action: 'launch_rocket',
    })
    dispatch(
      openInStudentApp({
        jwt,
      }),
    )
    dispatch(
      saveExperience({
        entityType: 'Student',
        entityId: '0',
        actor: teacherId,
        verb: 'launched',
      }),
    )
  }
  return (
    <MenuItemWrapper isOpen={isOpen}>
      <MenuItemBox isOpen={isOpen}>
        <MenuButton
          data-intercom-target={t('lessonPlans.classes.openStudentApp')}
          id="menu-rocket"
          isOpen={isOpen}
          tabIndex={0}
          title={t('lessonPlans.classes.openStudentApp')}
          onClick={onRocketClick}
          onKeyDown={e => (e.keyCode === 13 ? onRocketClick() : null)}
        >
          <Icon glyph={StudentApp} />
          <Label>
            <I18n text="lessonPlans.classes.openStudentApp" />
          </Label>
        </MenuButton>
      </MenuItemBox>
    </MenuItemWrapper>
  )
}

OpenInStudentApp.defaultProps = {
  isOpen: undefined,
}
OpenInStudentApp.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
}
