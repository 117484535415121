import * as React from 'react'

function SvgCertificate(props) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 145 151"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M97.3198 26.2583H78.3281C77.2457 26.2583 76.4585 27.0844 76.4585 28.0022C76.4585 29.0118 77.3441 29.7461 78.3281 29.7461H97.4185C98.5009 29.7461 99.2881 28.92 99.2881 28.0022C99.2881 27.0844 98.4025 26.2583 97.32 26.2583H97.3198Z"
        fill="#3333CC"
      />
      <path
        d="M70.6529 26.2583H43.9863C42.9039 26.2583 42.1167 27.0844 42.1167 28.0022C42.1167 28.92 43.0023 29.7461 43.9863 29.7461H70.6529C71.7353 29.7461 72.5225 28.92 72.5225 28.0022C72.5225 27.0844 71.7353 26.2583 70.6529 26.2583Z"
        fill="#3333CC"
      />
      <path
        d="M99.2876 43.239C99.2876 42.2294 98.402 41.4951 97.4181 41.4951H78.3276C77.2452 41.4951 76.458 42.3212 76.458 43.239C76.458 44.2486 77.3436 44.9829 78.3276 44.9829H97.4181C98.4021 45.0747 99.2876 44.2486 99.2876 43.239Z"
        fill="#3333CC"
      />
      <path
        d="M72.5225 43.239C72.5225 42.2294 71.6369 41.4951 70.6529 41.4951H43.9863C42.9039 41.4951 42.1167 42.3212 42.1167 43.239C42.1167 44.2486 43.0023 44.9829 43.9863 44.9829H70.6529C71.7353 45.0747 72.5225 44.2486 72.5225 43.239Z"
        fill="#3333CC"
      />
      <path
        d="M66.8155 56.7314H43.9863C42.9039 56.7314 42.1167 57.5575 42.1167 58.4753C42.1167 59.4849 43.0023 60.2192 43.9863 60.2192H66.8155C67.8979 60.2192 68.6851 59.3931 68.6851 58.4753C68.7835 57.4657 67.8979 56.7314 66.8155 56.7314Z"
        fill="#3333CC"
      />
      <path
        d="M74.4904 56.7314C73.4081 56.7314 72.6208 57.5575 72.6208 58.4753C72.6208 59.4849 73.5065 60.2192 74.4904 60.2192H83.8386C84.9209 60.2192 85.7081 59.3931 85.7081 58.4753C85.7081 57.4657 84.8225 56.7314 83.8386 56.7314H74.4904Z"
        fill="#3333CC"
      />
      <path
        d="M91.8091 110.334C89.2507 110.701 86.6922 109.691 85.2163 107.764L84.7242 107.121L78.7217 118.87L84.5273 118.686L87.9714 123L94.6627 109.875H94.5643L91.8091 110.334Z"
        fill="#3333CC"
      />
      <path
        d="M106.566 107.945L104.401 109.78C103.122 110.79 101.547 111.433 99.9727 111.433H99.7759L105.778 122.997L109.222 118.683L115.028 118.867L109.222 107.485L106.566 107.945Z"
        fill="#3333CC"
      />
      <path
        d="M116.799 92.8957L115.619 89.4995L116.603 86.1034C116.996 84.8184 116.504 83.4415 115.323 82.5238L112.371 80.3209L111.19 76.9248C110.698 75.6397 109.518 74.7219 108.14 74.5383L104.401 74.1712L101.449 71.9683C100.366 71.1422 98.792 70.9587 97.5127 71.5094L94.0687 73.0698L90.3295 72.7026C88.8535 72.5191 87.4758 73.1616 86.6885 74.3548L84.7205 77.3838L81.2765 78.8524C79.9972 79.4031 79.21 80.6881 79.21 82.0648L79.3084 85.6445L77.3404 88.6735C76.5532 89.8667 76.6516 91.2435 77.4388 92.4368L79.6036 95.3739L79.702 98.9536C79.702 100.33 80.5876 101.524 81.9652 102.074L85.5077 103.451L87.6725 106.388C88.4597 107.49 89.9357 108.04 91.3134 107.857L95.0527 107.214L98.5951 108.591C99.9728 109.142 101.449 108.866 102.531 107.949L105.286 105.562L109.026 104.92C110.502 104.736 111.584 103.726 111.978 102.441L112.962 99.0452L115.717 96.6588C116.799 95.5574 117.193 94.1806 116.799 92.8955L116.799 92.8957Z"
        fill="#3333CC"
      />
      <path
        d="M31.4886 106.476V23.6853C31.4886 20.6563 30.5046 17.811 28.7334 15.3328H101.256C106.176 15.3328 110.21 19.0961 110.21 23.6853V70.1297C112.966 70.864 115.229 72.8833 116.114 75.4533L116.803 77.4726L117 77.6561V23.6858C117 15.6086 109.915 9 101.256 9H15.7443C7.08493 9 0 15.6086 0 23.6858V34.9753C0 36.7192 1.57443 38.1877 3.44401 38.1877H24.6988V109.689C24.6988 111.433 26.2732 112.901 28.1428 112.901H76.3602L79.6075 106.568H31.4888L31.4886 106.476ZM24.6005 31.7625H6.78951V23.6853C6.78951 19.096 10.824 15.3328 15.744 15.3328C20.664 15.3328 24.6985 19.0961 24.6985 23.6853V31.7625H24.6005Z"
        fill="#3333CC"
      />
      <path
        d="M57.1716 91.335C57.9589 92.4364 59.2381 92.4364 59.7301 92.4364H60.0253C60.7141 92.4364 61.3046 92.4364 61.9933 92.3447C62.3869 92.3447 62.8789 92.3447 63.2726 92.2529H63.7646C64.7486 92.2529 65.7326 92.2529 66.7167 92.1611C67.7007 92.1611 68.3895 91.4268 68.3895 90.5089C68.3895 89.5911 67.6023 88.9485 66.7167 88.9485C65.831 88.9485 64.9454 88.9485 64.0599 89.0403H63.3711H62.0918H61.9934C61.3046 89.0403 60.7141 89.0403 60.2221 89.1321C60.2221 88.0306 60.2221 86.9292 59.9269 85.8278V85.736C59.1397 83.7167 57.1717 82.6153 55.0068 82.9824C54.81 82.9824 54.6132 83.0742 54.5148 83.0742C54.5148 81.5138 53.9244 80.0452 52.842 78.852C51.4643 77.3834 49.4963 76.4656 47.3315 76.4656C45.9538 76.4656 44.5762 76.8327 43.3954 77.567C40.5417 79.3109 39.5578 82.7989 40.9353 85.736L42.8049 89.7746C43.1001 90.3254 43.6905 90.6925 44.3793 90.6925C44.5761 90.6925 44.8713 90.6925 45.0681 90.6007C45.9537 90.2336 46.3474 89.3157 45.9537 88.4897L43.6905 84.6347C43.0017 83.0742 43.4937 81.2385 44.9698 80.3207C45.5602 79.9536 46.3474 79.77 47.0363 79.77C48.1187 79.77 49.2011 80.229 49.89 81.055C50.5789 81.8811 50.874 82.8908 50.7756 83.9004L49.89 88.6732V88.765C49.89 88.8568 49.89 88.9486 49.7916 89.0404C49.5948 89.8665 50.0868 90.7843 50.9724 90.968C51.366 91.0597 51.858 91.0597 52.2517 90.7844C52.6453 90.6008 52.9405 90.2337 53.0389 89.8665C53.1373 89.7747 53.1373 89.5912 53.1373 89.4994V89.1322V88.9487C53.3341 88.3062 53.4325 87.7554 53.6293 87.2965C53.9245 86.7458 54.5149 86.2869 55.2038 86.1951C55.8926 86.1032 56.1878 86.7458 56.1878 86.8376C56.3846 87.4801 56.3846 88.3062 56.3846 89.1323C56.5814 89.7748 56.6798 90.6009 57.1718 91.3352L57.1716 91.335Z"
        fill="#3333CC"
      />
    </svg>
  )
}

export default SvgCertificate
