import { css, styled, themeGet } from '@lyfta/components-theme'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'

import { Close } from '../../../Assets'
import { Flex } from '../../Flex'

export const Label = styled(Flex).attrs(() => ({
  as: 'label',
}))`
  color: ${themeGet('colors.black_700')};
  position: absolute;
  z-index: 1;
  top: 18px;
  left: 16px;
  font-size: 16px; // Body 1
  font-weight: 400; // Body 1
  letter-spacing: 0.5px; // Body 1
  line-height: 24px; // Body 1
  transition: all ${themeGet('transitionTime')} ease;
  user-select: none;

  ${props =>
    props.active &&
    css`
      color: ${themeGet('colors.primary_130')};
      font-size: 13px; // Body 5
      line-height: 20px; // Body 5
      top: 6px;
    `};
`

export const Input = styled(Flex).attrs(() => ({
  as: 'input',
  m: 0,
  px: 3,
  pt: 3,
}))`
  flex: 1;
  border: 0;
  outline: none;
  background-color: ${themeGet('colors.clear')};
  color: ${themeGet('colors.black_900')};
  font-size: 16px; // Body 1
  font-weight: 400; // Body 1
  letter-spacing: 0.5px; // Body 1
  line-height: 24px; // Body 1
  transition: all ${themeGet('transitionTime')} ease;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  ${props =>
    props.meta?.touched &&
    props.meta?.error &&
    css`
      border-bottom: solid red 2px;
    `};
`

export const Description = styled(Flex).attrs(() => ({
  px: 3,
  fontSize: 2,
}))`
  top: 6px;
  font-weight: normal;
  color: ${themeGet('colors.dustyGray')};
  transition: all ${themeGet('transitionTime')} ease;
  user-select: none;

  ${props =>
    props.active &&
    css`
      color: ${themeGet('colors.font.accent')};
    `};
`

export const Container = styled(Flex)`
  flex-direction: row;
  flex: 1;
  position: relative;
  background-color: ${themeGet('colors.neutral_100')};
  border: 1px solid ${themeGet('colors.black_600')};
  border-radius: 5px;
  height: 56px;
  box-sizing: content-box;

  ${props =>
    props.open &&
    css`
      border-radius: 5px 5px 0 0;
    `}

  // focused prop will often not get expected value due to how main component
  // handles (auto)focus.
  ${props =>
    (props.focused || props.open) &&
    !props.disabled &&
    css`
      border-bottom: 2px solid ${themeGet('colors.primary_100')};
    `}

  ${props =>
    props.disabled &&
    css`
      background-color: ${themeGet('colors.black_200')};
      border: none;
    `}

  ${props =>
    props.error &&
    css`
      border-bottom: 2px solid ${themeGet('colors.error_100')};
    `};

  ${props => (props.error ? `padding-right: 18px;` : `padding-right: 16px;`)}

  ${props =>
    props.error || props.focused
      ? `margin-bottom: 0px;`
      : `margin-bottom: 1px;`}

  &:focus-within {
    ${props =>
      !props.error &&
      !props.disabled &&
      css`
        border-bottom: 2px solid ${themeGet('colors.primary_100')};
      `}
  }

  &:hover {
    box-shadow: ${props => !props.disabled && shadows.skim}
  }
`

export const Wrapper = styled(Flex)`
  flex-direction: row;
`

export const DropDown = styled(Flex).attrs(() => ({ pt: 1 }))`
  flex-direction: column;
  flex: 1;
  position: absolute;
  background-color: ${themeGet('colors.neutral_100')};
  top: calc(100% + 2px);
  left: -1px;
  width: calc(100% + 2px);
  display: ${props => (props.open ? 'flex' : 'none')};
  max-height: 240px;
  min-height: 80px;
  overflow-y: scroll;
  z-index: 100;
  border-radius: 0 0 5px 5px;
  border-color: ${themeGet('colors.black_600')};
  border-width: 1px;
  border-style: solid;
  border-top-width: 0px;
`

export const ClearIcon = styled(Close).attrs(() => ({
  size: 8,
  direction: 'down',
  color: 'slateGray',
}))`
  position: absolute;
  right: 16px;
  top: 24px;
  cursor: pointer;
  > g {
    fill: ${themeGet('colors.black')};
  }
`
export const ListItem = styled(Flex).attrs(() => ({ px: '16px', py: '8px' }))`
  cursor: pointer;
  background-color: ${p =>
    themeGet(`colors.${p.active ? 'primary_60_50' : 'neutral_100'}`)(p)};
  color: ${themeGet('colors.black_700')};
  font-size: 16px; // Body 1
  font-weight: 400; // Body 1
  letter-spacing: 0.5px; // Body 1
  line-height: 24px; // Body 1
  user-select: none;
`
