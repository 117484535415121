import { appIdentifier as baseAppIdentifier } from '@lyfta/components-config'

import admin from './Admin'
import home from './Home'
import main from './Main'
import student from './Student'

const themes = {
  admin,
  main,
  student,
  home,
}

export const configureTheme = config => {
  let { appIdentifier } = config

  if (!appIdentifier) {
    appIdentifier = baseAppIdentifier
  }

  if (appIdentifier === 'parent') {
    return home
  }

  return themes[appIdentifier] || main
}

export default themes
