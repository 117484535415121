import { intercomEnvironment } from '@lyfta/components-config'
import {
  getIsAuthenticated,
  getViewer,
} from '@lyfta/components-data/src/Store/Selectors/viewer'
import { BREAKPOINTS } from '@lyfta/components-theme'
import { get, isEmpty, isNil, omitBy } from 'lodash'
import { useEffect, useState } from 'react'
import { IntercomAPI } from 'react-intercom'
import { useSelector } from 'react-redux'

export const useIntercom = withIntercom => {
  const isAuthenticated = useSelector(getIsAuthenticated)
  const viewer = useSelector(getViewer)

  const [intercomUser, setIntercomUser] = useState(null)
  useEffect(() => {
    if (withIntercom && isAuthenticated && !isEmpty(viewer)) {
      const { firstName, lastName, email } = viewer
      const name = [firstName, lastName].filter(e => e).join(' ') || email

      setIntercomUser(
        omitBy(
          {
            user_id: `lyfta2-${intercomEnvironment}-${get(viewer, 'id')}`,
            email,
            name,
            company_id: get(viewer.organization, 'intercom_id'),
            lyftaSystemVersion: 2,
          },
          isNil,
        ),
      )
    } else {
      setIntercomUser(null)
    }
  }, [viewer])

  const resize = breakpoint => {
    IntercomAPI('update', { hide_default_launcher: false })

    if (breakpoint === BREAKPOINTS.MOBILE) {
      IntercomAPI('update', { hide_default_launcher: true })
    }
  }

  return [intercomUser, resize]
}
