import { styled } from '@lyfta/components-theme'
import { themeGet } from '@styled-system/theme-get'

import {
  BaseCloseIconTab,
  BaseContainer,
  BaseHelpMeFind,
  BaseSubmit,
} from './styles.base'

export const Container = styled(BaseContainer)``

export const CloseIconTab = styled(BaseCloseIconTab)`
  &:hover {
    stroke: transparent;
    fill: ${themeGet('colors.primary')};
  }
`

export const HelpMeFind = styled(BaseHelpMeFind)`
  &:hover {
    stroke: #000;
    fill: #000;
  }
`

export const Submit = styled(BaseSubmit)``
