import { space, styled, themeGet as theme } from '@lyfta/components-theme'

import { Information } from '../../Assets'
import { Box } from '../Box'
import { Flex } from '../Flex'

export const ButtonContainer = styled(Flex).attrs(() => ({
  mt: 5,
}))`
  gap: 16px;
  > button {
    flex-grow: 1;
  }
`

export const Container = styled(Flex).attrs(() => ({}))`
  flex-direction: column;
  max-width: 620px;
  ${space};
`

export const SubTitleContainer = styled(Box).attrs(() => ({
  mt: 3,
}))`
  font-size: 16px;
  letter-spacing: 0.5px;
  color: ${theme('colors.black_700')};

  > a {
    color: ${theme('colors.secondary_100')};
    cursor: pointer;
    text-decoration: underline;

    :hover {
      color: ${theme('colors.primary')};
    }
  }
`

export const InformationIcon = styled(Information)`
  min-width: 20px;
  > path {
    fill: ${theme('colors.black_600')};
  }
`

export const InstructionMessage = styled.li``

export const InstructionMessageContainer = styled.ul`
  padding-left: 32px;
  margin-top: 0px;
  margin-bottom: 0px;
`

export const InstructionBlock = styled(Flex)`
  color: ${theme('colors.black_600')};
`

export const Content = styled(Flex)`
  flex-grow: 1;
`

export const UploadBox = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
`

export const UploadTitle = styled(Box)`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: ${theme('colors.dustyGray')};
`
