import {
  createDeleteReducerHandlers,
  createReducerHandlers,
} from '../../../utils'
import {
  CREATE_ADMIN_USER,
  DELETE_ADMIN_USER,
  LOAD_ADMIN_USER,
  LOAD_ADMIN_USERS,
  UPDATE_ADMIN_USER,
} from '../../Actions/users'

export default {
  ...createDeleteReducerHandlers('admins', DELETE_ADMIN_USER, {
    mapToKey: 'adminUsers',
  }),
  ...createReducerHandlers('admins', LOAD_ADMIN_USERS, {
    withReplace: true,
    mapToKey: 'adminUsers',
  }),
  ...createReducerHandlers('admins', LOAD_ADMIN_USER, {
    singular: true,
    mapToKey: 'currentAdminUser',
  }),
  ...createReducerHandlers('admins', CREATE_ADMIN_USER, {
    singular: true,
    mapToKey: 'currentAdminUser',
  }),
  ...createReducerHandlers('admins', UPDATE_ADMIN_USER, {
    singular: true,
    mapToKey: 'currentAdminUser',
  }),
}
