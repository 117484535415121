import { createSelector } from 'reselect'

import { getAllEntities, getEntity } from '../../../utils'

export const getTeachers = getAllEntities('teachers', 'users', 'teacherUsers')
export const getCurrentTeacher = getEntity('teachers', 'users', 'teacherUsers')

export const getTeachersActive = createSelector(getTeachers, (users = {}) => {
  if (!users) return []
  return users.filter(user => user?.state === 'active')
})
export const getTeacherUserName = createSelector(
  getCurrentTeacher,
  (user = {}) => {
    const { firstName, lastName, email } = user

    if (firstName || lastName) {
      return `${firstName} ${lastName} `
    }

    return email
  },
)
