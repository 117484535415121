import { createAsyncAction } from '../../helpers'
import apiCall from '../../services/Api'

export const CREATE_MEETING_INVITE = createAsyncAction(
  'meetingInvites/CREATE_MEETING_INVITE',
)
export const UPDATE_MEETING_INVITE = createAsyncAction(
  'meetingInvites/UPDATE_MEETING_INVITE',
)
export const LOAD_MEETING_INVITE = createAsyncAction(
  'meetingInvites/LOAD_MEETING_INVITE',
)
export const LOAD_MEETING_INVITES = createAsyncAction(
  'meetingInvites/LOAD_MEETING_INVITES',
)

export const clearCurrent = () => ({ type: LOAD_MEETING_INVITE.CLEAR })

export const loadMeetingInvite = (id, { meetingId }) => {
  return apiCall({
    method: 'GET',
    endpoint: `/meetings/${meetingId}/invites/${id}?include=meeting,meetingOccurrence,meetingOccurrence.host`,
    types: LOAD_MEETING_INVITE,
    payload: { id },
  })
}

export const loadMeetingInviteByToken = token => {
  return apiCall({
    method: 'GET',
    endpoint: `/meeting_invites/${token}?include=meeting,invitedBy,organization`,
    types: LOAD_MEETING_INVITE,
    payload: { token },
  })
}

export const loadMeetingInvites = ({
  meetingId,
  filter,
  page,
  include = 'meeting,occurrence,occurrence.host',
  sort = 'inviteStatus',
  ...props
} = {}) =>
  apiCall({
    method: 'GET',
    endpoint: `/meetings/${meetingId}/invites`,
    query: {
      include,
      page,
      filter,
      sort,
    },
    types: LOAD_MEETING_INVITES,
    paged: true,
    payload: { page, filter, include, sort, ...props },
  })

export const createMeetingInvite = ({
  meetingId,
  relationships = {},
  ...meetingInvite
}) => {
  return apiCall({
    method: 'POST',
    endpoint: `/meetings/${meetingId}/invites?include=meeting,meetingOccurrence,meetingOccurrence.host`,
    types: CREATE_MEETING_INVITE,
    query: {
      data: {
        type: 'meetingInvites',
        attributes: {
          ...meetingInvite,
        },
        relationships,
      },
    },
  })
}

export const updateMeetingInvite = ({
  meetingId,
  relationships = {},
  ...meetingInvite
}) => {
  return apiCall({
    method: 'PATCH',
    endpoint: `/meetings/${meetingId}/invites/${meetingInvite.id}?include=meeting,meetingOccurrence,meetingOccurrence.host`,
    types: UPDATE_MEETING_INVITE,
    query: {
      data: {
        type: 'meetingInvites',
        attributes: {
          ...meetingInvite,
        },
        relationships,
      },
    },
  })
}
export const SEND_INVITE = createAsyncAction('meetings/SEND_INVITE')
export const SEND_INVITES = 'meetings/SEND_INVITES'
export const sendInvites = payload => ({
  type: SEND_INVITES,
  payload,
})

export const sendInvite = ({
  meetingId,
  teacherEmail,
  userId,
  meetingOccurrenceId,
  organizationId,
}) =>
  apiCall({
    method: 'POST',
    endpoint: `/meetings/${meetingId}/invites`,
    query: {
      data: {
        type: 'meetingInvites',
        attributes: {
          email: teacherEmail,
        },
        relationships: {
          meeting: {
            data: {
              type: 'meetings',
              id: meetingId,
            },
          },
          invited_by: {
            data: {
              type: 'users',
              id: userId,
            },
          },
          occurrence: {
            data: {
              type: 'meetingsOccurrence',
              id: meetingOccurrenceId,
            },
          },
          organization: {
            data: {
              type: 'organizations',
              id: organizationId,
            },
          },
        },
      },
    },
    types: SEND_INVITE,
  })
