import { createAsyncAction } from '../../helpers'
import apiCall from '../../services/Api'

export const LOAD_SUBJECTS = createAsyncAction('subjects/LOAD_SUBJECTS')

export const loadSubjects = () =>
  apiCall({
    method: 'GET',
    endpoint: '/subjects',
    types: LOAD_SUBJECTS,
  })
