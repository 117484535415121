import { createReducer } from '../../helpers'
import { createEntityFields, createReducerHandlers } from '../../utils'
import {
  CREATE_MEETING_OCCURRENCE,
  LOAD_MEETING_OCCURRENCE,
  LOAD_MEETING_OCCURRENCES,
  UPDATE_MEETING_OCCURRENCE,
} from '../Actions/meetingOccurrences'

const initialState = {
  ...createEntityFields('meetingOccurrences'),
}

const handlers = {
  ...createReducerHandlers('meetingOccurrences', CREATE_MEETING_OCCURRENCE, {
    withReplace: false,
    mapToKey: 'meetingOccurrences',
  }),
  ...createReducerHandlers('meetingOccurrences', LOAD_MEETING_OCCURRENCES, {
    withReplace: true,
    mapToKey: 'meetingOccurrences',
  }),
  ...createReducerHandlers('meetingOccurrences', LOAD_MEETING_OCCURRENCE, {
    mapToKey: 'currentMeetingOccurrence',
    singular: true,
  }),
  ...createReducerHandlers('meetingOccurrences', UPDATE_MEETING_OCCURRENCE, {
    mapToKey: 'currentMeetingOccurrence',
    singular: true,
  }),
}

export default createReducer(initialState, handlers)
