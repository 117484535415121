import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import Arrow from '../Arrow'
import Dropdown from '../Dropdown'
import {
  Current,
  Next,
  PageSize,
  PaginationWrapper,
  Previous,
  Size,
} from './styles'

class Pagination extends PureComponent {
  get lastPage() {
    const { total, pageSize } = this.props
    return Math.floor(total / pageSize)
  }

  getSafePage(page) {
    const { props } = this
    let nextPage = page
    if (Number.isNaN(page)) {
      nextPage = props.page
    }
    // return Math.min(Math.max(nextPage, 0), this.props.pages - 1)
    return Math.min(Math.max(nextPage, 0), this.lastPage)
  }

  handlePrevious = () => {
    const { canPrevious, page } = this.props
    if (!canPrevious) return
    this.changePage(page - 1)
  }

  handleNext = () => {
    const { /* canNext */ page } = this.props
    // if (!canNext) return
    if (page >= this.lastPage) return
    this.changePage(page + 1)
  }

  handleChangeSize = item => {
    const { onPageSizeChange } = this.props
    onPageSizeChange(item.value)
  }

  changePage(page) {
    const { props } = this
    const nextPage = this.getSafePage(page)
    if (props.page !== nextPage) {
      props.onPageChange(nextPage)
    }
  }

  render() {
    const {
      // canNext,
      canPrevious,
      data,
      page,
      pageSize,
      pageSizeOptions,
      total,
      className,
    } = this.props

    const size = total || data.length

    return (
      size > 1 && (
        <PaginationWrapper className={className}>
          <PageSize>
            <I18n i18nKey="search.rowsPerPage" />
            <Dropdown
              options={pageSizeOptions}
              right
              top
              onChange={this.handleChangeSize}
            >
              <Size>
                {pageSize} <Arrow direction="down" height={8} width={8} />
              </Size>
            </Dropdown>
          </PageSize>
          <Current>
            <I18n
              i18nKey="search.pager"
              options={{
                from: page * pageSize + 1,
                to: Math.min(page * pageSize + pageSize, size),
                total: size,
              }}
            />
          </Current>
          <Previous disabled={canPrevious} onClick={this.handlePrevious} />
          <Next disabled={page < this.lastPage} onClick={this.handleNext} />
        </PaginationWrapper>
      )
    )
  }
}

Pagination.defaultProps = {
  className: '',
  total: 0,
}

Pagination.propTypes = {
  // canNext: PropTypes.bool.isRequired,
  canPrevious: PropTypes.bool.isRequired,
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageSizeOptions: PropTypes.array.isRequired,
  // pages: PropTypes.number.isRequired,
  total: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
}

export default Pagination
