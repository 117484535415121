import colors from './colors'

export default {
  // elevation 00:
  none: '',
  // elevation 01:
  skim: `0px 0px 2px ${colors.black_600_25}, 0px 0px 10px ${colors.black_600_20}`,
  // elevation 02:
  lifted: `0px 3px 10px ${colors.black_600_14}, 0px 1px 18px ${colors.black_600_12}, 0px 3px 5px ${colors.black_600_20}`,
  // elevation 03:
  raised: `0px 10px 17px ${colors.black_600_14}, 0px 5px 22px ${colors.black_600_12}, 0px 5px 8px ${colors.black_600_20}`,
  // elevation 04:
  floating: `0px 24px 38px ${colors.black_600_14}, 0px 9px 46px ${colors.black_600_12}, 0px 11px 15px ${colors.black_600_20}`,
  focused_blue: `0px 0px 3px 0px ${colors.blue_100}`,
}
