export default {
  buttonStyle: {
    default: {
      style: {
        noborder: true,
        fontWeight: 'normal',
        backgroundColor: 'secondary',
        borderColor: 'secondary',
        borderRadius: '6px',
        borderWidth: '1px',
        color: 'white',
        textTransform: 'none',
        letterSpacing: '1.3px',
      },
      hover: {
        backgroundColor: 'white',
        borderColor: 'secondary',
        color: 'secondary',
        fontWeight: 'bold',
      },
    },
    inverse: {
      style: {
        noborder: false,
        fontWeight: 'normal',
        backgroundColor: 'white',
        borderColor: 'secondary',
        borderRadius: '6px',
        borderWidth: '1px',
        color: 'secondary',
        textTransform: 'none',
        letterSpacing: '1.3px',
      },
      hover: {
        backgroundColor: 'secondary',
        borderColor: 'secondary',
        color: 'white',
        fontWeight: 'bold',
      },
    },
    monochromeInverse: {
      style: {
        noborder: true,
        fontWeight: 'bold',
        borderRadius: '6px',
        backgroundColor: 'black',
        color: 'white',
        px: 6,
        py: 2,
        fontSize: 3,
      },
    },
    monochrome: {
      style: {
        noborder: false,
        fontWeight: 'bold',
        backgroundColor: 'whiteSmoke',
        borderColor: 'tundora',
        borderRadius: '3px',
        borderWidth: '1px',
        color: 'tundora',
        px: 6,
        py: 2,
        fontSize: 3,
      },
      hover: {
        backgroundColor: 'tundora',
        borderColor: 'whiteSmoke',
        color: 'whiteSmoke',
        fontWeight: 'bold',
      },
    },
    primary: {
      style: {
        backgroundColor: 'secondary_100',
        color: 'neutral_100',
        border: 'none',
      },
      hover: {
        backgroundColor: 'secondary_80',
        color: 'neutral_100',
      },
      active: {
        backgroundColor: 'secondary_130',
        color: 'neutral_100',
      },
      focusVisible: {
        backgroundColor: 'secondary_80',
        borderColor: 'secondary_100',
        color: 'neutral_100',
        focusRingColor: 'secondary_100',
      },
    },
    secondary: {
      style: {
        backgroundColor: 'neutral_100',
        color: 'secondary_100',
        border: 'none',
      },
      hover: {
        backgroundColor: 'secondary_100',
        color: 'neutral_100',
      },
      active: {
        color: 'neutral_100',
        backgroundColor: 'secondary_130',
      },
      focusVisible: {
        backgroundColor: 'secondary_100',
        borderColor: 'secondary_100',
        color: 'neutral_100',
        focusRingColor: 'secondary_100',
      },
    },
    primaryDelete: {
      style: {
        backgroundColor: 'error_100',
        color: 'neutral_100',
        border: 'none',
      },
      hover: {
        backgroundColor: 'error_90',
        color: 'neutral_100',
      },
      active: {
        backgroundColor: 'error_110',
        color: 'neutral_100',
      },
      focusVisible: {
        backgroundColor: 'error_90',
        borderColor: 'error_90',
        color: 'neutral_100',
        focusRingColor: 'error_90',
      },
    },
    secondaryDelete: {
      style: {
        backgroundColor: 'neutral_100',
        color: 'error_100',
        border: 'none',
      },
      hover: {
        backgroundColor: 'error_100',
        color: 'neutral_100',
      },
      active: {
        color: 'neutral_100',
        backgroundColor: 'error_110',
      },
      focusVisible: {
        backgroundColor: 'error_100',
        borderColor: 'error_100',
        color: 'neutral_100',
        focusRingColor: 'error_100',
      },
    },

    buttonShadow: {
      default: `box-shadow: #00000050 0 5px 10px 0`,
    },

    buttonShadowPressed: {
      default: `box-shadow: #00000050 0 5px 10px 0`,
    },
  },
}
