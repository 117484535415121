import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { StyledOption } from '../styles'

class Item extends PureComponent {
  handleSelect = () => {
    const { value, label, onSelect } = this.props
    if (onSelect) onSelect(value, label)
  }

  render() {
    const { selected, label, color, id } = this.props
    return (
      <StyledOption
        color={color}
        id={id}
        selected={selected}
        onClick={this.handleSelect}
      >
        {label}
      </StyledOption>
    )
  }
}

Item.defaultProps = {
  color: false,
  id: '',
  selected: false,
}

Item.propTypes = {
  color: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]).isRequired,
  selected: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default Item
