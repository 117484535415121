import { useTranslation } from '@lyfta/components-i18n'
/* eslint-disable no-alert */
import { styled } from '@lyfta/components-theme'
import { gyroLight } from '@lyfta/components-ui'
import NoSleep from 'nosleep.js'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { gyro } from '../../Store/Actions/ui'
import { getIsGyroEnabled } from '../../Store/Selectors/UI/gyro'

export const getIsDeviceLandscape = () => {
  return window.innerWidth > window.innerHeight
}

function Gyro(props) {
  const gyroState = useSelector(getIsGyroEnabled)

  const [isLandscape, setIsLandscape] = useState(getIsDeviceLandscape())

  useEffect(() => {
    if (!gyroState) {
      return undefined
    }

    const onResize = () => {
      setIsLandscape(getIsDeviceLandscape())
    }

    setIsLandscape(getIsDeviceLandscape())

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  })

  const noSleep = useMemo(() => new NoSleep(), [])

  useEffect(() => {
    if (!gyroState) {
      return undefined
    }

    return () => {
      if (noSleep.isEnabled) {
        noSleep.disable()
      }
    }
  }, [gyroState])

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const toggleAction = useCallback(async () => {
    if (gyroState) {
      dispatch(gyro.toggle())
      return
    }

    const requestPermission = async () => {
      if (!getIsDeviceLandscape()) {
        alert(t('gyro.orientToLandscape'))
        return
      }

      if ('DeviceOrientationEvent' in window) {
        if (typeof DeviceOrientationEvent.requestPermission === 'function') {
          try {
            noSleep.enable().catch(() => {})
            const permissionResult = await DeviceOrientationEvent.requestPermission().catch(
              () => 'denied',
            )

            if (permissionResult === 'granted') {
              dispatch(gyro.toggle())
            } else {
              if (noSleep.isEnabled) {
                noSleep.disable()
              }
              alert(t('gyro.permissionDenied'))
            }
          } catch (error) {
            if (noSleep.isEnabled) {
              noSleep.disable()
            }
            alert(t('gyro.failedToRequestPermission'))
          }
        } else {
          dispatch(gyro.toggle())
        }
      } else {
        alert(t('gyro.notSupported'))
      }
    }

    // eslint-disable-next-line no-console
    requestPermission().catch(e => console.error(e))
  }, [gyroState, dispatch])

  let circleFill
  if (gyroState) {
    if (isLandscape) {
      circleFill = `#0f0`
    } else {
      circleFill = `#f00`
    }
  } else {
    circleFill = `#000`
  }

  return (
    <svg onClick={toggleAction} {...props}>
      <defs>
        <style>{`
          .cls-1{opacity:0.6}
          .cls-2,.cls-3{fill:#fff;}
          .cls-3{fill-rule:evenodd;}
        `}</style>
      </defs>
      <circle cx="35" cy="35" r="34" className="cls-1" fill={circleFill} />
      <path
        className="cls-2"
        d="M35,0A35,35,0,1,0,70,35,35,35,0,0,0,35,0Zm0,67.81A32.81,32.81,0,1,1,67.81,35,32.81,32.81,0,0,1,35,67.81Z"
      />
      <g transform="translate(35, 35) scale(3) translate(-7, -7) ">
        <image href={gyroLight} width="14" height="14" />
      </g>
    </svg>
  )
}

const StyledComponent = styled(Gyro)`
  circle {
    opacity: 0.4;
  }
`

export default StyledComponent
