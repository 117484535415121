import { useCombobox } from 'downshift'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { FormField } from '../Field'
import { useInputItems } from '../hooks/useInputItems'
import { useSelectedItem } from '../hooks/useSelectedItem'
import { stateReducer } from '../StateReducer'
import { Wrapper } from '../Wrapper'

export const renderField = ({
  autoFocus,
  onInputValueChange,
  keepValueOnNoItem,
  onSelectItem,
  items,
  input,
  id,
  ...props
}) => {
  const [inputItems, filterItems] = useInputItems(items)
  const {
    selectedItem,
    onSelectedItemChange,
    value,
    setValue,
  } = useSelectedItem(input, inputItems, onSelectItem)
  const [focused, setFocused] = useState(autoFocus)
  const [active, setActive] = useState(!!selectedItem || !!value)
  useEffect(() => {
    setActive(focused || !!selectedItem || !!value)
  }, [selectedItem])

  const onFocus = e => {
    e.preventDefault()
    e.stopPropagation()
    setFocused(true)
    setActive(true)
  }

  const onFieldBlur = () => {
    setFocused(true)
    setActive(!!selectedItem || !!value)
  }

  const { getLabelProps, getComboboxProps, ...comboBoxProps } = useCombobox({
    items: inputItems,
    inputValue: value,
    stateReducer: stateReducer(onFieldBlur, keepValueOnNoItem),
    itemToString: item => get(item, 'name', item),
    onInputValueChange: ({ inputValue, ...valueChangeProps }) => {
      setValue(inputValue)
      if (onInputValueChange) {
        onInputValueChange({ inputValue, ...valueChangeProps })
      } else {
        filterItems({ inputValue, ...valueChangeProps })
      }
    },
    onSelectedItemChange,
    ...(id !== null && { id }),
  })
  return (
    <Wrapper
      {...props}
      active={active}
      autoFocus={autoFocus}
      focused={focused}
      getComboboxProps={getComboboxProps}
      getLabelProps={getLabelProps}
      items={inputItems}
      isOpen={comboBoxProps.isOpen}
      selectedItem={selectedItem}
      onFocus={onFocus}
    >
      {fieldProps => {
        return (
          <FormField
            autoFocus={autoFocus}
            items={inputItems}
            touchItem={{ setActive, onSelectItem, ...comboBoxProps }}
            {...fieldProps}
            {...comboBoxProps}
            active={active}
            focused={focused}
            selectedItem={selectedItem}
            onFieldBlur={onFieldBlur}
            onFocus={onFocus}
          />
        )
      }}
    </Wrapper>
  )
}
renderField.defaultProps = {
  autoFocus: false,
  id: null,
  onInputValueChange: null,
  onSelectItem: null,
}

renderField.propTypes = {
  autoFocus: PropTypes.bool,
  id: PropTypes.string,
  input: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  keepValueOnNoItem: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onInputValueChange: PropTypes.func,
  onSelectItem: PropTypes.func,
}
