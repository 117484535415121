/* eslint-disable */
import { appEnv } from '@lyfta/components-config'
import { getLocale } from '@lyfta/components-data/src/Store/Selectors/persist'
import {
  TranslatedField,
  getCurrentLanguage,
  getTranslation,
} from '@lyfta/components-i18n'
import {
  Bbfc,
  Bbfc12A,
  ButtonNew,
  Flex,
  Icon,
  Pg,
  capitalizeFirstLetter,
  getFilteredItems,
} from '@lyfta/components-ui'
import { filter, map } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import {
  Accordion,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { useDispatch, useSelector } from 'react-redux'

import {
  AGE_ID_MAP,
  AGE_RANGE_MAP_NAMES,
  BBFC_RATING_MAP_NAMES,
  DURATION_MAP_NAMES,
  GOOGLE_KEY_MAP,
  LESSON_PLAN_TYPE_MAP_NAMES,
  TYPE_SOUND_CLIP,
  excludeContentTypesLyftaContent,
  initialStateFilter,
} from '../../Constants/contents'
import {
  loadContentsCount,
  toggleFilters,
  toggleSearchEditorsFilters,
} from '../../Store/Actions/search'
import {
  getFilters,
  getNewSearch,
  getSearchEditorsFilters,
  getTotalResult,
} from '../../Store/Selectors/search'
import { getContentIcon, naturalSort } from '../../Utils'
import {
  AccordionItem,
  AccordionItemButton,
  CheckboxContainerGrid,
  ClearFilters,
  Container,
  FilterItem,
  FilterItemContainer,
  Footer,
  Section,
  SectionContent,
  SectionSubitle,
  SectionTitle,
  SelectAllContainer,
  Title,
} from './styles'

const ModalFilterForm = (handleFilterModalSubmit, fieldsProperties) => () => {
  const locale = useSelector(getLocale)
  const totalResult = useSelector(getTotalResult)

  const {
    okText,
    handleOpenFilterModal,
    tagGroups,
    subjects,
    lyftaContent,
    isCollectionEditor,
  } = fieldsProperties

  const isLessonPlandEditorOrCollectionEditor = () =>
    lyftaContent || isCollectionEditor

  const initialState = isLessonPlandEditorOrCollectionEditor()
    ? useSelector(getSearchEditorsFilters)
    : useSelector(getFilters)

  let clonedState = JSON.parse(JSON.stringify(initialState))

  if (lyftaContent && !isCollectionEditor) {
    //Removing options not required for LPB -> lyftacontent modal
    const {
      lesson_plan_template,
      collection,
      series,
      ...otherOptions
    } = clonedState.types.options
    clonedState.types.options = otherOptions

    delete clonedState.lessonPlanTypes
  }

  if (isCollectionEditor) {
    delete clonedState.types.options.collection
  }

  const [filtersSelected, setFiltersSelected] = useState(clonedState)

  const newSearch = useSelector(getNewSearch)

  const dispatch = useDispatch()

  const getFiltersToDisplay = newState => {
    const { options } = newState.types

    let filtersToDisplay
    const selectedTypes = Object.keys(options).filter(item => options[item])

    if (selectedTypes.length > 0) {
      const merged = selectedTypes.reduce((acc, curr) => {
        return [...acc, ...sectionOrders[curr]]
      }, [])

      filtersToDisplay = [...new Set(merged)]
    }
    return filtersToDisplay
  }

  const getHiddenFilters = newState => {
    const allFilters = sectionOrders.all_content
    const filtersToDisplay = getFiltersToDisplay(newState)
    return allFilters.filter(filter => !filtersToDisplay.includes(filter))
  }

  const filtersInfo = useMemo(() => {
    // Get total true items
    const totalTrueItems = Object.values(filtersSelected).reduce(
      (acc, group) => {
        const { default: groupDefault, options } = group
        const trueItemsCount = Object.entries(options).filter(
          ([key, value]) => value && key !== groupDefault,
        ).length

        return acc + trueItemsCount
      },
      0,
    )

    return { totalTrueItems }
  }, [filtersSelected])

  const filterDT = useMemo(() => {
    // eslint-disable-next-line
    let suppliedFilter = {
      locale,
    }

    const keywords = filter(newSearch, t => t.type === 'query')

    if (keywords.length > 0) {
      suppliedFilter[`keywords_${getCurrentLanguage()}`] = map(
        keywords,
        k => k.value,
      ).join(',')
    }

    // Filtering by tags
    if (filtersSelected?.tags?.options)
      suppliedFilter.tags = filtersSelected?.tags?.options

    // Filtering by subjects
    if (filtersSelected?.subjects?.options)
      suppliedFilter.subjects = filtersSelected?.subjects?.options

    // Filtering by ageRanges
    if (filtersSelected?.ageRanges?.options) {
      const ageRangesOptions = filtersSelected.ageRanges.options

      const ids = Object.keys(ageRangesOptions)
        .filter(key => ageRangesOptions[key] && key in AGE_ID_MAP)
        .map(key => AGE_ID_MAP[key])

      suppliedFilter.ageRanges = ids
    }
    // Filtering by lessonPlanType
    if (filtersSelected?.lessonPlanTypes?.options) {
      const lessonPlanTypesOptions = filtersSelected.lessonPlanTypes.options
      const typeIdMap = {
        lessons: 1,
        assemblies: 2,
        lyftaTimes: 3,
        accessible: 4,
      }

      const ids = Object.keys(lessonPlanTypesOptions)
        .filter(key => lessonPlanTypesOptions[key] && key in typeIdMap)
        .map(key => typeIdMap[key])

      suppliedFilter.lessonPlanTypes = ids
    }

    // Filtering by film durations
    if (filtersSelected?.filmDurations?.options) {
      const filmDurationsOptions = filtersSelected.filmDurations.options
      const durationIdMap = {
        under5: 1,
        mins510: 2,
        over10: 3,
      }

      const ids = Object.keys(filmDurationsOptions)
        .filter(key => filmDurationsOptions[key] && key in durationIdMap)
        .map(key => durationIdMap[key])

      suppliedFilter.filmDurations = ids
    }

    // Filtering by bbcf rating
    if (filtersSelected?.bbfcRatings?.options) {
      const bbfcRatingsOptions = filtersSelected.bbfcRatings.options
      const bbfcRatingsMap = {
        one: 1,
        two: 2,
        three: 3,
      }

      const ids = Object.keys(bbfcRatingsOptions)
        .filter(key => bbfcRatingsOptions[key] && key in bbfcRatingsMap)
        .map(key => bbfcRatingsMap[key])

      suppliedFilter.bbfcRatings = ids
    }

    // Filtering by types
    const itemsFiltered = getFilteredItems(filtersSelected, 'types')
    const types = itemsFiltered.map(item => item.value)
    if (filtersSelected?.types?.options) suppliedFilter.types = types

    suppliedFilter = {
      ...suppliedFilter,
      excluded_types: lyftaContent
        ? excludeContentTypesLyftaContent
        : [TYPE_SOUND_CLIP],
    }
    dispatch(loadContentsCount({ filter: suppliedFilter }))
    return suppliedFilter
  }, [filtersSelected])

  const handleFilterSubmit = items => {
    if (!isLessonPlandEditorOrCollectionEditor()) {
      dispatch(toggleFilters(items))
    } else {
      dispatch(toggleSearchEditorsFilters(items))
    }
    return handleFilterModalSubmit(items)
  }

  const googleParams = useMemo(() => {
    const { exclude, ...restOfFilterDT } = filterDT
    const renamedKeysObj = Object.entries(restOfFilterDT).reduce(
      (acc, [key, value]) => {
        let newValue = value

        // Convert the values of bbfc_rating
        if (key === 'bbfcRatings' && Array.isArray(value)) {
          newValue = value.map(v => {
            const indexValue = typeof v === 'string' ? parseInt(v, 10) : v
            return BBFC_RATING_MAP_NAMES[indexValue - 1]
          })
        }

        if (key === 'lessonPlanTypes') {
          newValue = value.map(v => {
            const indexValue = typeof v === 'string' ? parseInt(v, 10) : v
            return LESSON_PLAN_TYPE_MAP_NAMES[indexValue - 1]
          })
        }

        if (key === 'filmDurations') {
          newValue = value.map(v => {
            const indexValue = typeof v === 'string' ? parseInt(v, 10) : v
            return DURATION_MAP_NAMES[indexValue - 1]
          })
        }

        if (key === 'ageRanges') {
          newValue = value.map(v => {
            const indexValue = typeof v === 'string' ? parseInt(v, 10) : v
            return AGE_RANGE_MAP_NAMES[indexValue - 1]
          })
        }

        if (key === 'subjects') {
          newValue = value.map(v => {
            const matchingSubject = subjects.find(
              subj => subj.id.toString() === v.toString(),
            )
            return matchingSubject ? matchingSubject.nameEn : v
          })
        }

        if (GOOGLE_KEY_MAP[key]) {
          acc[GOOGLE_KEY_MAP[key]] = newValue
        } else {
          acc[key] = newValue
        }
        return acc
      },
      {},
    )
    const tagIds = renamedKeysObj.content_tag_IDs
    const tagNames = []
    const tagGroupNames = []

    if (tagIds && Array.isArray(tagIds)) {
      const stringTagIds = tagIds.map(id => id.toString())

      tagGroups.forEach(group => {
        if (group && group.tags) {
          const matchedTags = group.tags.filter(tag =>
            stringTagIds.includes(tag.id.toString()),
          )
          if (matchedTags.length > 0) {
            tagGroupNames.push(group.nameEn)
            tagNames.push(...matchedTags.map(tag => tag.nameEn))
          }
        }
      })

      renamedKeysObj.content_tag_names = tagNames
      renamedKeysObj.content_tag_group_names = tagGroupNames
    }
    const search_term = renamedKeysObj['keywords_en']
    delete renamedKeysObj['keywords_en']
    return {
      ...renamedKeysObj,
      search_result: totalResult,
      search_term: search_term,
    }
  }, [filterDT, totalResult])

  const handleOnClick = (group, item) => {
    setFiltersSelected(prevState => {
      const groupDefault = prevState[group].default
      const { source } = prevState[group]

      if (
        groupDefault &&
        item === groupDefault &&
        prevState[group].options[groupDefault]
      ) {
        return prevState
      }

      const defaultProperty = groupDefault
        ? prevState[group].options[groupDefault]
        : null
      let allFalse = true
      let allOptionsFalseExcludingDefault = true

      if (!prevState[group].options[item]) {
        allOptionsFalseExcludingDefault = false
      }

      Object.keys(prevState[group].options).some(key => {
        if ((groupDefault && key !== groupDefault) || !groupDefault) {
          if (prevState[group].options[key]) {
            allFalse = false

            if (key !== item) {
              allOptionsFalseExcludingDefault = false
            }
          }
        }
      })

      const updatedGroup = {
        ...prevState[group],
        options: {
          ...prevState[group].options,
          [item]: !prevState[group].options[item],
        },
      }
      if (source === 'api') {
        updatedGroup.options = prevState[group].options.includes(item)
          ? prevState[group].options.filter(option => option !== item)
          : [...prevState[group].options, item]
      }

      if (groupDefault) {
        if (item === groupDefault) {
          if (!defaultProperty) {
            Object.keys(updatedGroup.options).forEach(key => {
              if (key !== groupDefault) {
                updatedGroup.options[key] = false
              }
            })
          }
        } else {
          if (allFalse)
            updatedGroup.options[groupDefault] = !updatedGroup.options[item]
          if (allOptionsFalseExcludingDefault)
            updatedGroup.options[groupDefault] = true
        }
      }
      const newState = {
        ...prevState,
        [group]: updatedGroup,
      }

      const filtersHidden = getHiddenFilters(newState)
      if (filtersHidden.length > 0) {
        let newStateReseted = newState
        for (let fh of filtersHidden) {
          newStateReseted[fh] = initialStateFilter[fh]
        }
        return newStateReseted
      }

      return newState
    })
  }

  const selectUnselectAll = ({ group, subgroup = false, unselect = false }) => {
    setFiltersSelected(prevState => {
      const filterGroup = { ...prevState[group] }
      let updatedOptions = {}

      // Only tagGroup has subgroups.
      if (subgroup) {
        const tagGroup = tagGroups.find(tg => tg.id === subgroup)

        if (tagGroup) {
          const tagIds = tagGroup.tags.map(tag => tag.id)
          updatedOptions = unselect ? [] : [...filterGroup.options, ...tagIds]
        }
      } else {
        updatedOptions = { ...filterGroup.options }

        const subjectsIds = subjects.map(tag => tag.id)
        updatedOptions = unselect
          ? []
          : [...filterGroup.options, ...subjectsIds]
      }

      return {
        ...prevState,
        [group]: {
          ...prevState[group],
          options: updatedOptions,
        },
      }
    })
  }

  const bbfcRatingIcons = type => {
    switch (type) {
      case 'one':
        return Bbfc
      case 'two':
        return Pg
      case 'three':
        return Bbfc12A
      default:
        return false
    }
  }

  // HANDLE RENDERING ACCORDING TO FILTERS SELECTED

  const sectionOrders = {
    all_content: [
      'types',
      'ageRanges',
      'subjects',
      'lessonPlanTypes',
      'bbfcRatings',
      'filmDurations',
      'tags',
    ],
    story_world: ['types', 'tags'],
    video: ['types', 'bbfcRatings', 'filmDurations', 'tags'],
    '360_video': ['types', 'tags'],
    lesson_plan_template: [
      'types',
      'lessonPlanTypes',
      'tags',
      'ageRanges',
      'subjects',
    ],
    article: ['types', 'ageRanges', 'subjects', 'tags'],
    series: ['types', 'tags'],
    collection: ['types', 'ageRanges', 'subjects', 'tags'],
  }

  const renderSections = () => {
    const { options } = filtersSelected.types
    const selectedTypes = Object.keys(options).filter(item => options[item])

    const fixOrder = sectionOrders.all_content
    let typesToDisplay
    if (selectedTypes.length > 0) {
      const merged = selectedTypes.reduce((acc, curr) => {
        return [...acc, ...sectionOrders[curr]]
      }, [])

      typesToDisplay = [...new Set(merged)]
    }

    return fixOrder.map(section => {
      const displaySection = typesToDisplay.includes(section)
      switch (section) {
        case 'types':
          return renderTypeSection()
        case 'ageRanges':
          return renderAgeRangesSection(displaySection)
        case 'subjects':
          return renderSubjectsSection(displaySection)
        case 'lessonPlanTypes':
          return renderLessonPlanTypesSection(displaySection)
        case 'bbfcRatings':
          return renderbbfcRatingsSection(displaySection)
        case 'filmDurations':
          return renderFilmDurationsSection(displaySection)
        case 'tags':
          return renderTagsSection()
        default:
          return null
      }
    })
  }
  const renderSelectUnselectAllButton = ({
    optionsSelected,
    list,
    group,
    subgroup = false,
  }) => {
    return (
      <Flex>
        {optionsSelected < list.length - 3 && (
          <SelectAllContainer
            onClick={e => {
              e.stopPropagation()
              selectUnselectAll({ group, subgroup })
            }}
          >
            {getTranslation('filters.selectAll')}
          </SelectAllContainer>
        )}
        {optionsSelected > 3 && (
          <SelectAllContainer
            onClick={e => {
              e.stopPropagation()
              selectUnselectAll({ group, subgroup, unselect: true })
            }}
          >
            {getTranslation('filters.unselectAll')}
          </SelectAllContainer>
        )}
      </Flex>
    )
  }

  // RENDER SECTIONS

  const renderTypeSection = () => (
    <Section mb={5}>
      <SectionTitle>
        {getTranslation('filters.titles.contentType')}{' '}
      </SectionTitle>
      <SectionContent>
        {Object.keys(filtersSelected.types.options).map(item => (
          <ButtonNew
            key={`types-${item}`}
            buttonDiv
            buttonSelect
            id={`btn-types-${item}`}
            subtype="filter"
            type="task"
            minHeight="40px"
            borderRadius="8px"
            fontSize="14px"
            fontWeight="700"
            mb={2}
            mr={2}
            leadingIcon={getContentIcon({
              type: item,
              height: '20px',
              width: '20px',
            })}
            title={getTranslation(`filters.${item}`)}
            uppercase
            onClick={() => handleOnClick('types', item)}
            secondary={!filtersSelected.types.options[item]}
          >
            {getTranslation(`filters.${item}`)}
          </ButtonNew>
        ))}
      </SectionContent>
    </Section>
  )

  const renderAgeRangesSection = displaySection => (
    <Section mb={5}>
      <SectionTitle>{getTranslation('filters.titles.ageRanges')} </SectionTitle>
      <SectionSubitle>
        {getTranslation('filters.subtitles.ageRanges')}{' '}
      </SectionSubitle>
      <SectionContent>
        {displaySection &&
          Object.keys(filtersSelected.ageRanges.options).map(item => (
            <ButtonNew
              key={`ageRanges-${item}`}
              buttonDiv
              buttonSelect
              id={`btn-ageRanges-${item}`}
              subtype="filter"
              fontSize="16px"
              mb={2}
              mr={2}
              fontWeight="400"
              title={getTranslation(`filters.${item}`)}
              onClick={() => handleOnClick('ageRanges', item)}
              secondary={!filtersSelected.ageRanges.options[item]}
            >
              {capitalizeFirstLetter(getTranslation(`filters.${item}`))}
            </ButtonNew>
          ))}
      </SectionContent>
    </Section>
  )

  const renderSubjectsSection = displaySection => (
    <Section mb={5}>
      <SectionTitle>
        <Flex>{getTranslation('filters.titles.subjects')}</Flex>

        {renderSelectUnselectAllButton({
          optionsSelected: filtersSelected.subjects.options.length,
          list: subjects,
          group: 'subjects',
        })}
      </SectionTitle>
      <SectionSubitle>
        {getTranslation('filters.subtitles.subjects')}{' '}
      </SectionSubitle>
      <SectionContent>
        <CheckboxContainerGrid>
          {displaySection &&
            subjects.map(subject => {
              return (
                <FilterItemContainer
                  onClick={() => handleOnClick('subjects', subject.id)}
                >
                  <FilterItem
                    active={
                      filtersSelected.subjects.options.includes(subject.id)
                        ? 1
                        : 0
                    }
                    key={subject.id}
                  >
                    <TranslatedField field="name" object={subject} />
                  </FilterItem>
                </FilterItemContainer>
              )
            })}
        </CheckboxContainerGrid>
      </SectionContent>
    </Section>
  )

  const renderLessonPlanTypesSection = displaySection =>
    (filtersSelected.types.options.all_content ||
      filtersSelected.types.options.lesson_plan_template) &&
    filtersSelected.lessonPlanTypes && (
      <Section mb={6}>
        <SectionTitle>
          {getTranslation('filters.titles.lessonPlanType')}{' '}
        </SectionTitle>
        <SectionSubitle>
          {getTranslation('filters.subtitles.lessonPlanType')}{' '}
        </SectionSubitle>
        <SectionContent>
          {displaySection &&
            Object.keys(filtersSelected.lessonPlanTypes.options).map(item => (
              <ButtonNew
                key={`lessonPlanTypes-${item}`}
                buttonDiv
                buttonSelect
                id={`btn-lessonPlanTypes-${item}`}
                subtype="filter"
                fontSize="16px"
                mb={2}
                mr={2}
                fontWeight="400"
                title={getTranslation(`filters.${item}`)}
                onClick={() => handleOnClick('lessonPlanTypes', item)}
                secondary={!filtersSelected.lessonPlanTypes.options[item]}
              >
                {capitalizeFirstLetter(getTranslation(`filters.${item}`))}
              </ButtonNew>
            ))}
          {!displaySection && (
            <SectionSubitle fontSize={12}>
              {getTranslation('filters.filterNoApplied')}
            </SectionSubitle>
          )}
        </SectionContent>
      </Section>
    )

  const renderbbfcRatingsSection = displaySection =>
    (filtersSelected.types.options.all_content ||
      filtersSelected.types.options.video) && (
      <Section mb={5}>
        <SectionTitle>
          {getTranslation('filters.titles.bbfcRating')}{' '}
        </SectionTitle>
        <SectionSubitle>
          {getTranslation('filters.subtitles.bbfcRating')}{' '}
        </SectionSubitle>
        <SectionContent flexDirection="row">
          {displaySection &&
            Object.keys(filtersSelected.bbfcRatings.options).map(item => {
              return (
                <FilterItemContainer
                  onClick={() => handleOnClick('bbfcRatings', item)}
                >
                  <FilterItem
                    active={filtersSelected.bbfcRatings.options[item] ? 1 : 0}
                    key={item}
                  >
                    {bbfcRatingIcons(item) !== false && (
                      <Icon
                        title={item}
                        glyph={bbfcRatingIcons(item)}
                        ml={1}
                        width="24px"
                        height="24px"
                      />
                    )}
                    <Flex ml={2}>
                      {capitalizeFirstLetter(getTranslation(`filters.${item}`))}
                    </Flex>
                  </FilterItem>
                </FilterItemContainer>
              )
            })}
          {!displaySection && (
            <SectionSubitle fontSize={12}>
              {getTranslation('filters.filterNoApplied')}
            </SectionSubitle>
          )}
        </SectionContent>
      </Section>
    )

  const renderFilmDurationsSection = displaySection =>
    (filtersSelected.types.options.all_content ||
      filtersSelected.types.options.video) && (
      <Section mb={5}>
        <SectionTitle>
          {getTranslation('filters.titles.filmDurations')}{' '}
        </SectionTitle>
        <SectionContent>
          {displaySection &&
            Object.keys(filtersSelected.filmDurations.options).map(item => (
              <ButtonNew
                key={`filmDurations-${item}`}
                buttonDiv
                buttonSelect
                id={`btn-filmDurations-${item}`}
                subtype="filter"
                fontSize="16px"
                mb={2}
                mr={2}
                fontWeight="400"
                title={getTranslation(`filters.${item}`)}
                onClick={() => handleOnClick('filmDurations', item)}
                secondary={!filtersSelected.filmDurations.options[item]}
              >
                {capitalizeFirstLetter(getTranslation(`filters.${item}`))}
              </ButtonNew>
            ))}
          {!displaySection && (
            <SectionSubitle fontSize={12}>
              {getTranslation('filters.filterNoApplied')}
            </SectionSubitle>
          )}
        </SectionContent>
      </Section>
    )

  //hack to remove some tg for live env until we have a better solution
  const tgsToRemove = ['130', '94', '95', '169', '129', '132', '168']

  const handleExpandCollapseAll = () => {
    let elements = document.getElementsByClassName('collapse-expand-all')

    let allExpanded = Array.from(elements).every(
      element => element.getAttribute('aria-expanded') === 'true',
    )

    for (let element of elements) {
      const isExpanded = element.getAttribute('aria-expanded') === 'true'
      if (allExpanded || !isExpanded) {
        element.click()
      }
    }
  }
  const renderTagsSection = () => (
    <Section mb={7}>
      <SectionTitle>
        <Flex>{getTranslation('filters.titles.skill')}</Flex>{' '}
        <Flex cursor="pointer" onClick={handleExpandCollapseAll}>
          <SelectAllContainer> Expand / Collapse all</SelectAllContainer>
        </Flex>
      </SectionTitle>
      <SectionSubitle mt="0" secondary>
        {getTranslation('filters.subtitles.skill')}
      </SectionSubitle>
      {tagGroups.map((tg, index) => {
        const { tags } = tg

        //hack to remove some tg for live env until we have a better solution
        if (tgsToRemove.includes(tg.id) && appEnv === 'production') return null

        const tagsSelectedPerGroup = getSelectedTagsCount(
          filtersSelected.tags.options,
          tg.tags,
        )
        const tagsSorted = naturalSort(
          tags,
          `name${capitalizeFirstLetter(locale)}`,
        )

        return (
          <Accordion allowMultipleExpanded allowZeroExpanded>
            <React.Fragment key={tg.id}>
              <AccordionItem key={index} name={`accordion-${index}`}>
                <AccordionItemHeading border="2px solid green">
                  <AccordionItemButton isTag className="collapse-expand-all">
                    <SectionTitle isTag>
                      <Title width="100%">
                        <TranslatedField
                          field="name"
                          id={`cat-${tg.id}`}
                          object={tg}
                        />
                      </Title>
                      {renderSelectUnselectAllButton({
                        optionsSelected: tagsSelectedPerGroup,
                        list: tagsSorted,
                        group: 'tags',
                        subgroup: tg.id,
                      })}
                    </SectionTitle>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <SectionContent mb={5}>
                    <CheckboxContainerGrid>
                      {tagsSorted.map(tag => {
                        return (
                          <FilterItemContainer
                            mb={1}
                            onClick={() => handleOnClick('tags', tag.id)}
                          >
                            <FilterItem
                              active={
                                filtersSelected.tags.options.includes(tag.id)
                                  ? 1
                                  : 0
                              }
                              key={tag.id}
                            >
                              <TranslatedField field="name" object={tag} />
                            </FilterItem>
                          </FilterItemContainer>
                        )
                      })}
                    </CheckboxContainerGrid>
                  </SectionContent>
                </AccordionItemPanel>
              </AccordionItem>
            </React.Fragment>
          </Accordion>
        )
      })}
    </Section>
  )

  const getSelectedTagsCount = (options, list) => {
    const selectedTags = options.filter(tagId =>
      list.find(tg => tg.id === tagId),
    )
    return selectedTags.length
  }

  return (
    <Container>
      {!!filtersInfo.totalTrueItems && (
        <ClearFilters onClick={() => setFiltersSelected(initialStateFilter)}>
          {getTranslation('filters.clear', {
            number: filtersInfo.totalTrueItems,
            count: filtersInfo.totalTrueItems,
          })}{' '}
        </ClearFilters>
      )}

      {/* Rendering sections according to the order */}
      {renderSections()}

      <Footer>
        <ButtonNew
          key="cancel"
          id="btn-cancel"
          buttonDiv
          maxHeight="40px"
          fontSize="16x"
          title={getTranslation(`actions.cancel`)}
          onClick={() => handleOpenFilterModal()}
          mr={3}
          type="link"
          googleEvent={{ action: 'clicked_search_filter_cancel' }}
        >
          {getTranslation('actions.cancel')}
        </ButtonNew>
        <ButtonNew
          key="submit"
          buttonDiv
          id="btn-submit"
          maxHeight="40px"
          width="300px"
          fontSize="16px"
          title={okText}
          backgroundColor="hsla(238, 63%, 49%, 1)"
          borderColor="hsla(238, 63%, 49%, 1)"
          hover={{
            backgroundColor: 'hsla(231,62%,58%,1);',
            borderColor: 'hsla(231,62%,58%,1);',
          }}
          onClick={() => handleFilterSubmit(filtersSelected)}
          googleEvent={{
            action: 'clicked_search_filter_show_results',
            params: { ...googleParams },
          }}
        >
          {totalResult !== null
            ? getTranslation('actions.showResultsWithNumber', {
                number: totalResult,
              })
            : getTranslation('actions.showResults')}
        </ButtonNew>
      </Footer>
    </Container>
  )
}

ModalFilterForm.defaultProps = {
  subgroup: '',
}

ModalFilterForm.propTypes = {
  optionsSelected: PropTypes.number.isRequired,
  list: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  subgroup: PropTypes.string,
}

export default ModalFilterForm
