import { getLocale } from '@lyfta/components-data/src/Store/Selectors/persist'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Category from './Category'

const selector = createStructuredSelector({
  locale: getLocale,
})

export default connect(selector)(Category)
