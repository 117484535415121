import { getViewerType } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { I18n } from '@lyfta/components-i18n'
import { getCurrentLessonPlanId } from '@lyfta/components-student/src/Store/Selectors/lessonPlans'
import { getIsTeachingInFrontOfClass } from '@lyfta/components-student/src/Store/Selectors/questTracker'
import { getIsQuest } from '@lyfta/components-student/src/Store/Selectors/UI/quest'
import { getIsPreviewMode } from '@lyfta/components-ui/src/Helpers/getIsPreviewMode'
import React from 'react'
import { useSelector } from 'react-redux'

import { Container, TextContainer } from './styles'

const PreviewBanner = () => {
  const viewerType = useSelector(getViewerType)
  const isTeachingInFrontOfClass = useSelector(getIsTeachingInFrontOfClass)
  const isQuest = useSelector(getIsQuest)
  const lessonPlanId = useSelector(getCurrentLessonPlanId)

  const isPreviewMode = getIsPreviewMode({
    viewerType,
    isTeachingInFrontOfClass,
    lessonPlanId,
    isQuest,
  })

  if (!isPreviewMode) return null
  return (
    <Container>
      <TextContainer>
        <I18n i18nKey="quest.previewMode" />
      </TextContainer>
    </Container>
  )
}

export default PreviewBanner
