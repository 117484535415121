import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'

import {
  NextPageButton,
  PageBackIconContainer,
  PageRightIconContainer,
  PrevPageButton,
  PrevPageButtonContainer,
  ResetButton,
  ResetButtonContainer,
} from './styles'

export const CustomisePage = ({ setCurrentPage, player }) => {
  const setDefaultPreferences = () => {
    player.textTrackSettings.setValues(player.options_.defaultPreferences)

    player.playbackRate(player.options_.defaultPreferences.speed)
    player.textTrackSettings.setDefaults()

    player.textTrackSettings.updateDisplay()

    player.options_.updateUserPreferences(player.options_.defaultPreferences)
  }
  return (
    <>
      <PrevPageButtonContainer>
        <PrevPageButton
          className="subtitle-customise-back-button"
          onClick={() => setCurrentPage('selection')}
        >
          <PageBackIconContainer />
          <I18n i18nKey="videoPlayer.labels.customise" />
        </PrevPageButton>
      </PrevPageButtonContainer>
      <NextPageButton onClick={() => setCurrentPage('text')}>
        <I18n i18nKey="videoPlayer.labels.text" />
        <PageRightIconContainer />
      </NextPageButton>
      <NextPageButton onClick={() => setCurrentPage('background')}>
        <I18n i18nKey="videoPlayer.labels.background" />
        <PageRightIconContainer />
      </NextPageButton>
      <NextPageButton onClick={() => setCurrentPage('speed')}>
        <I18n i18nKey="videoPlayer.labels.speedLabel" />
        <PageRightIconContainer />
      </NextPageButton>
      <ResetButtonContainer>
        <ResetButton
          onClick={() => {
            setDefaultPreferences()
          }}
        >
          <I18n i18nKey="videoPlayer.labels.resetAll" />
        </ResetButton>
      </ResetButtonContainer>
    </>
  )
}

CustomisePage.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  player: PropTypes.object.isRequired,
}
