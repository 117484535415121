import '../Main/Styles/fonts.css'

import { merge } from 'lodash'

import { walsheimAndFallbackFontFamily } from '../../styling'
import { breakpoints } from '../Main'
import buttons from '../Main/buttons'
import buttonsNew from '../Main/buttonsNew'
import fontSizes from '../Main/fontSizes'
import sizes from '../Main/sizes'
import space from '../Main/space'
import zIndexes from '../Main/zIndexes'
import studentButtons from './buttons'
import buttonsSubtypes from './buttonsSubtypes'
import colors from './colors'

const buttonsMerged = merge({}, buttonsNew, studentButtons)
const theme = {
  colors,
  sizes,
  zIndexes,
  breakpoints: Object.values(breakpoints),

  ...fontSizes,
  ...space,

  name: 'Lyfta student theme',
  font: walsheimAndFallbackFontFamily,
  fontTitle: walsheimAndFallbackFontFamily,
  transitionTime: '.17s',
  ...buttons,
  ...buttonsMerged,
  ...buttonsSubtypes,
}

export { breakpoints }
export default theme
