import { first, flatten, get, map } from 'lodash'

import { validLanguages } from '../../constants/validLanguages'
import { getCurrentLanguage } from '../locale'
import { capitalizeFirstLetter } from '../utils'

export const getTranslatedField = (
  object,
  field,
  locale = getCurrentLanguage(),
) => {
  const localeParam = locale || 'en'
  const locales = map(flatten([localeParam, validLanguages]), l =>
    capitalizeFirstLetter(l),
  )
  const localizedValues = map(locales, l => get(object, `${field}${l}`)).filter(
    item => !!item,
  )

  return first(localizedValues) || get(object, field)
}
