import '../Main/Styles/fonts.css'

import { walsheimAndFallbackFontFamily } from '../../styling'
import { breakpoints } from '../Main'
import buttons from '../Main/buttons'
import buttonsNew from '../Main/buttonsNew'
import fontSizes from '../Main/fontSizes'
import space from '../Main/space'
import zIndexes from '../Main/zIndexes'
import colors from './colors'
import sizes from './sizes'

const theme = {
  colors,
  sizes,
  zIndexes,
  breakpoints: Object.values(breakpoints),

  ...fontSizes,
  ...space,

  name: 'Lyfta Admin theme',
  font: walsheimAndFallbackFontFamily,
  fontTitle: walsheimAndFallbackFontFamily,
  transitionTime: '.17s',
  ...buttons,
  ...buttonsNew,
}

export { breakpoints }
export default theme
