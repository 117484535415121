import * as React from 'react'

function SvgShare(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M15.2791 19C14.5286 19 13.8878 18.7289 13.3565 18.1866C12.8252 17.6443 12.5595 16.9921 12.5595 16.23C12.5595 16.1303 12.5703 16.0062 12.5917 15.8577C12.6131 15.7092 12.6452 15.5707 12.6881 15.4422L6.64607 11.8656C6.40768 12.1372 6.1179 12.3554 5.77674 12.5203C5.43559 12.6851 5.08523 12.7676 4.72565 12.7676C3.96853 12.7676 3.32498 12.4971 2.79499 11.956C2.265 11.415 2 10.7628 2 9.99928C2 9.23579 2.265 8.58377 2.79499 8.04323C3.32498 7.5027 3.96853 7.23243 4.72565 7.23243C5.08523 7.23243 5.42845 7.30397 5.75532 7.44704C6.0822 7.59011 6.37912 7.79745 6.64607 8.06906L12.6881 4.53597C12.6452 4.42502 12.6131 4.29908 12.5917 4.15816C12.5703 4.01725 12.5595 3.88546 12.5595 3.76282C12.5595 3.0027 12.8254 2.3522 13.3572 1.81133C13.8889 1.27044 14.53 1 15.2805 1C16.0309 1 16.6718 1.27051 17.2031 1.81152C17.7344 2.35254 18 3.00479 18 3.76829C18 4.53178 17.735 5.1838 17.205 5.72434C16.675 6.26487 16.0315 6.53514 15.2744 6.53514C14.9097 6.53514 14.5614 6.47873 14.2293 6.36593C13.8971 6.25312 13.6165 6.06065 13.3875 5.78853L7.33336 9.16623C7.36999 9.29066 7.39699 9.4354 7.41437 9.60045C7.43176 9.7655 7.44046 9.90043 7.44046 10.0053C7.44046 10.1101 7.43176 10.2231 7.41437 10.3442C7.39699 10.4653 7.36999 10.5922 7.33336 10.7248L13.3875 14.1897C13.6165 13.9611 13.8877 13.7832 14.2009 13.6559C14.5141 13.5285 14.8719 13.4649 15.2744 13.4649C16.0315 13.4649 16.675 13.7354 17.205 14.2764C17.735 14.8174 18 15.4697 18 16.2332C18 16.9966 17.7341 17.6487 17.2024 18.1892C16.6706 18.7297 16.0295 19 15.2791 19Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgShare
