import * as React from 'react'

function SvgUser(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 100 100"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#000" fillRule="evenodd">
        <path d="M50.482 4.176c12.038 0 21.887 9.85 21.887 21.887 0 12.402-9.849 22.251-21.887 22.251-12.402 0-22.25-9.849-22.25-22.251 0-12.038 9.848-21.887 22.25-21.887zM33.613 52.912h33.011c14.873 0 23.216 11.956 23.216 25.724-21.765 22.1-57.678 22.1-79.08 0 0-13.768 7.98-25.724 22.853-25.724z" />
      </g>
    </svg>
  )
}

export default SvgUser
