/* eslint-disable */
import '../../../../node_modules/react-dd-menu/dist/react-dd-menu.css'
import '../../../../node_modules/react-toastify/dist/ReactToastify.css'

import React from 'react'
import { Helmet } from 'react-helmet'
import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'

/* eslint-enable */

const GlobalStyleComponent = createGlobalStyle`
  ${styledNormalize}

  html, body, div#root {
    height: 100%;
    width: 100%;
    //height: -webkit-fill-height-available;
  }

  body {
    color: ${props => props.theme.colors.font.primary};
    background-color: ${props => props.theme.colors.white};
    font-size: 16px;
    font-family: ${props => props.theme.font};
    line-height: 24px;
    letter-spacing: .2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ::selection {
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
  }

  // input::selection {
  //   background-color: transparent !important;
  //   color: ${props => props.theme.colors.font.primary};
  // }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }


  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-internal-autofill-selected,
  input:-webkit-autofill:active {
    appearance: none !important;
    background: transparent !important;
    background-color: transparent !important;
    background-image: none !important;
    color: inherit !important;
    box-shadow: 0 0 0 30px white inset !important;
  }

  // pre {
  //   border: 1px solid #ccc;
  //   background: rgba(0,0,0,0.1);
  //   box-shadow: inset 1px 1px 3px rgba(0,0,0,0.2);
  //   border-radius: 4px;
  //   padding: 20px;
  //   margin: 20px auto;
  //   max-width: 400px;
  //   text-align: left;
  // }

  // input, textarea {
  //   display: block;
  //   max-width: 400px;
  //   margin: 10px auto;
  // }

  .collapse-transition {
    transition: height 250ms cubic-bezier(.4, 0, .2, 1);
  }

  form {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`

export default () => (
  <>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Slabo+27px"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap"
      />
    </Helmet>
    <GlobalStyleComponent />
  </>
)
