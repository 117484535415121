import * as React from 'react'

function SvgStudentApp(props) {
  return (
    <svg height="24" viewBox="0 0 24 24" width="24" {...props}>
      <path
        clipRule="evenodd"
        d="M17.658 2.00096C18.9781 1.98251 20.2885 2.22925 21.5115 2.72657C21.8952 3.97646 22.0546 5.28446 21.9821 6.58993C21.8005 9.84923 19.7766 12.461 17.6414 14.3458C17.314 15.6638 16.7461 16.9181 15.9598 18.0429C14.7182 19.8188 12.9861 21.1943 10.9769 22C11.5758 20.4054 11.9986 18.7524 12.2387 17.0705C11.6214 15.7767 10.7512 14.6165 9.67665 13.6597C8.76723 12.8571 7.72923 12.2138 6.60651 11.7568C6.2877 11.8367 5.96786 11.9182 5.64793 12.001C4.99785 12.1835 4.36467 12.3513 3.73788 12.5175C3.15611 12.6717 2.57918 12.8246 2 12.9863C2.68901 10.9374 3.96268 9.1358 5.66399 7.80345C7.01865 6.74258 8.5973 6.01616 10.2721 5.67427C12.4097 3.41105 14.9702 2.04108 17.658 2.00096ZM16.4137 9.68725C17.5183 9.68725 18.4137 8.78335 18.4137 7.66834C18.4137 6.55332 17.5183 5.64942 16.4137 5.64942C15.3091 5.64942 14.4137 6.55332 14.4137 7.66834C14.4137 8.78335 15.3091 9.68725 16.4137 9.68725Z"
        fillRule="evenodd"
      />
      <path d="M2 21.9999C2 21.9999 7.38718 20.6182 8.52167 19.4665C9.06718 18.914 9.37413 18.1641 9.375 17.3818C9.37586 16.5995 9.07057 15.849 8.52629 15.2952C7.982 14.7414 7.24331 14.4298 6.4727 14.4289C5.7021 14.4281 4.96272 14.738 4.41721 15.2905C3.28272 16.3954 2 21.9999 2 21.9999Z" />
    </svg>
  )
}

export default SvgStudentApp
