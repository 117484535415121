import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AnimatedTypeAhead from '../AnimatedTypeAhead'

const AnimatedModelLookup = ({
  id,
  lookupAction,
  lookupSelector,
  lookupItemAction,
  lookupItemSelector,
  focused,
  value: inputValue,
  ...props
}) => {
  const [listItems, setListItems] = useState([])

  const dispatch = useDispatch()
  const items = useSelector(lookupSelector)
  const item = useSelector(lookupItemSelector)

  useEffect(() => {
    if (inputValue) {
      dispatch(lookupItemAction(inputValue))
    } else {
      dispatch(lookupAction())
    }
  }, [inputValue])

  useEffect(() => {
    if (items) {
      setListItems(items)
    } else if (item) {
      setListItems([item])
    } else {
      setListItems([])
    }
  }, [items, item])

  const onInputValueChange = ({ inputValue: name }) => {
    if (!isEmpty(name)) dispatch(lookupAction({ filter: { name } }))
  }

  return (
    <AnimatedTypeAhead
      {...props}
      focused={focused}
      id={id}
      items={listItems}
      value={inputValue}
      onInputValueChange={onInputValueChange}
    />
  )
}

AnimatedModelLookup.defaultProps = {
  id: null,
  focused: false,
  requestOptions: {},
  value: null,
  onFocus: null,
}

AnimatedModelLookup.propTypes = {
  focused: PropTypes.bool,
  id: PropTypes.string,
  lookupAction: PropTypes.func.isRequired,
  lookupItemAction: PropTypes.func.isRequired,
  lookupItemSelector: PropTypes.func.isRequired,
  lookupSelector: PropTypes.func.isRequired,
  requestOptions: PropTypes.object,
  value: PropTypes.any,
  onFocus: PropTypes.func,
}

export default AnimatedModelLookup
