import React from 'react'

function SvgFullscreenMinimise(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      {...props}
    >
      <path d="M8.49057 38L6 35.5094L15.9623 25.5472H9.39623V22H22V34.6038H18.4528V28.0377L8.49057 38ZM22 22V9.35849H25.5849V15.9245L35.5094 6L38 8.49057L28.0755 18.4151H34.6415V22H22Z" />
    </svg>
  )
}

export default SvgFullscreenMinimise
