import { isTeacherApp } from '@lyfta/components-config'
import { whitelist } from '@lyfta/components-content/src/Constants/contents'
import { getTotalResult } from '@lyfta/components-content/src/Store/Selectors/contents'
import { I18n, getTranslation } from '@lyfta/components-i18n'
import {
  reactEvent,
  showNotification,
} from '@lyfta/components-ui/src/Services/Utils'
import { filter, isEmpty, map, omit } from 'lodash'
import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  Container,
  InputContainer,
  InputText,
  SearchContainer,
  SearchIcon,
  SearchLabel,
  Tag,
  TagRemove,
} from './styles'
import Tags from './Tags'

const Search = ({
  showToggle,
  onSearchChange,
  tags,
  setTags,
  toggleOpen,
  id,
  searchLabel,
  googleEvent,
  ...rest
}) => {
  const [searchText, setSearchText] = useState('')

  // Triggering reactEnvent when label or enter key are pressed
  const [searchInProgress, setSearchInProgress] = useState(false)

  const totalResult = useSelector(getTotalResult)

  const totalResultRef = useRef(totalResult)
  const searchTextRef = useRef(searchText)

  useEffect(() => {
    totalResultRef.current = totalResult
  }, [totalResult])
  useEffect(() => {
    if (searchText !== '') searchTextRef.current = searchText
  }, [searchText])

  useEffect(() => {
    if (isTeacherApp && searchInProgress) {
      setTimeout(() => {
        if (isTeacherApp) {
          // Use the latest value from totalResultRef
          reactEvent({
            ...googleEvent,
            params: {
              search_term: searchTextRef.current,
              search_result: totalResultRef.current,
            },
          })
        }
      }, 8000)
      setSearchInProgress(false)
    }
  }, [searchInProgress])
  const checkAndAdd = () => {
    setTags(wordList => {
      let isInWhitelist = false
      const normalizedSearchText = searchText.toLowerCase()
      const duplicated = wordList.find(
        item => item.value.toLowerCase() === normalizedSearchText,
      )

      if (whitelist[normalizedSearchText]) isInWhitelist = true
      if (duplicated) {
        showNotification(getTranslation('search.keywordDuplicated'), 'error')
        return [...wordList]
      }

      return [
        ...wordList,
        {
          id: nanoid(),
          type: 'query',
          value: isInWhitelist ? whitelist[normalizedSearchText] : searchText,
        },
      ]
    })
    setSearchText('')
  }

  const onClickLabel = () => {
    if (!isEmpty(searchText)) {
      checkAndAdd()
      setSearchInProgress(true)
    }
  }

  const handleRemoveTag = removeTag => () => {
    setTags(tagList => [...filter(tagList, t => t.id !== removeTag.id)])
  }

  useEffect(() => {
    onSearchChange(tags)
  }, [tags])

  const handleChange = ({ target: { value } }) => {
    setSearchText(value)
  }

  const handleKeyDown = ({ key, keyCode, target: { value } }) => {
    const searchValue = value
    const isEnterKey = key === 'Enter' // || keyCode === 32
    const isBackspaceKey = key === 'Backspace' || keyCode === 8

    if (isEmpty(searchValue) && isBackspaceKey) {
      handleRemoveTag(tags[tags.length - 1])()
      return
    }

    if (isEnterKey && !isEmpty(searchValue)) {
      checkAndAdd()
      if (isTeacherApp) setSearchInProgress(true)
    }
  }

  return (
    <Container
      {...omit(rest, [
        'searchType',
        'searchText',
        'onLoadContents',
        'onToggleTag',
        'onToggleKeyword',
        'onShowTags',
        'onClearSearch',
      ])}
    >
      <SearchContainer>
        <InputContainer>
          <SearchIcon />
          {map(tags, (tag, index) => (
            <Tag
              id={`tag-${index}`}
              key={index}
              type={tag.type}
              onClick={handleRemoveTag(tag)}
            >
              {tag.value}
              <TagRemove id={`remove-${index}`} />
            </Tag>
          ))}
          <InputText
            id={id}
            name={id}
            {...(tags.length < 1 && {
              placeholder: getTranslation(
                'core.search.placeholderThemeTopicCountry',
              ),
            })}
            value={searchText}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          {searchLabel && (
            <SearchLabel
              id="btn-search-by-theme-topic-country"
              onClick={onClickLabel}
              googleEvent={{
                ...googleEvent,
                params: {
                  search_term: searchTextRef.current,
                  search_result: totalResultRef.current,
                },
              }}
            >
              <I18n i18nKey={searchLabel} />
            </SearchLabel>
          )}
        </InputContainer>
      </SearchContainer>
    </Container>
  )
}

Search.defaultProps = {
  showToggle: true,
  id: '',
  searchLabel: '',
  googleEvent: {},
}

Search.propTypes = {
  setTags: PropTypes.func.isRequired,
  showToggle: PropTypes.bool,
  tags: PropTypes.array.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  searchLabel: PropTypes.string,
  googleEvent: PropTypes.object,
}

Search.Tags = Tags

export default Search
