import { getError } from '@lyfta/components-data/src/Store/Selectors/auth'
import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import { AnimatedInput, ButtonNew } from '../../../../Components'
import { ErrorBlock } from '../../styles'

const ChangePasswordFormFieldset = ({ handleSubmit, pristine, invalid }) => {
  const error = useSelector(getError)
  return (
    <>
      <AnimatedInput
        label={<I18n text="auth.password" />}
        mb={2}
        name="password"
        type="password"
      />

      <AnimatedInput
        autoComplete="off"
        label={<I18n text="auth.confirmPassword" />}
        mb={2}
        name="confirmPassword"
        type="password"
      />

      <ButtonNew
        disabled={pristine || invalid}
        mt={2}
        width={1}
        onClick={handleSubmit}
      >
        <I18n text="auth.resetPassword" />
      </ButtonNew>
      <ErrorBlock>{error}</ErrorBlock>
    </>
  )
}

ChangePasswordFormFieldset.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
}

export default ChangePasswordFormFieldset
