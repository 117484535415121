import { styled } from '@lyfta/components-theme'

import { Label as Base } from '../../../BaseComponents/index'

const Label = styled(Base).attrs(suppliedProps => ({
  p: suppliedProps.p || 0,
  m: suppliedProps.m || 0,
}))`
  border: none;
  width: auto;
  overflow: visible;

  background: transparent;

  color: inherit;
  font: inherit;

  line-height: normal;

  font-smoothing: inherit;

  cursor: pointer;
  appearance: none;
  outline: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`

export default Label
