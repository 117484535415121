import { getAllEntities, getEntity } from '@lyfta/components-data'
import { getTranslatedField } from '@lyfta/components-i18n'
import { filterAndSortContent } from '@lyfta/components-ui'
import { get } from 'lodash'
import { createSelector } from 'reselect'

export const getState = state => state.contents

export const getContents = getAllEntities('contents')
export const getCurrentContent = getEntity(
  'contents',
  null,
  null,
  null,
  'storyworld',
)

export const getAllContents = getContents
export const getContent = getCurrentContent

export const getNewStoryworlds = createSelector(getContents, contents => {
  const filterOptions = { type: 'story_world' }

  if (!contents[0]) return []
  // const sortField = 'createdAt'
  const storyworld = filterAndSortContent(
    contents,
    filterOptions,
    'createdAt',
    'desc',
  )

  return storyworld
})

export const getNewCollections = createSelector(getContents, contents => {
  const filterOptions = { type: 'collection' }
  const collections = filterAndSortContent(contents, filterOptions)

  return collections
})

export const getContentName = createSelector(getCurrentContent, content => {
  return getTranslatedField(content, 'name')
})

export const getContentFavoriteState = createSelector(
  getCurrentContent,
  content => {
    return get(content, 'favorites', [])[0]
  },
)

export const getContentId = createSelector(getCurrentContent, content => {
  return get(content, 'id')
})
export const getContentParentName = createSelector(
  getCurrentContent,
  content => {
    const { type } = content

    if (type === 'story_world') {
      return getTranslatedField(content, 'name')
    }

    return getTranslatedField(content, 'parent.name')
  },
)
export const getContentSeriesName = createSelector(
  getCurrentContent,
  content => {
    return (
      getTranslatedField(content, 'parent.parent.name') ||
      getTranslatedField(content, 'parent.name') ||
      getTranslatedField(content, 'name')
    )
  },
)

export const getTags = createSelector(getCurrentContent, content =>
  content ? get(content, 'tags', []).map(item => item.id) : null,
)

export const getIsLoaded = createSelector(getState, state => state.isLoaded)
export const getIsLoading = createSelector(getState, state => state.isLoading)
export const getTotal = createSelector(getState, state => state.total)
export const getPaged = createSelector(getState, state => state.paged)
export const getTotalResult = createSelector(
  getState,
  state => state.paged.totalRecords,
)
export const getFilter = createSelector(getState, state => state.filter)
export const getSort = createSelector(getState, state => state.sort)
