import PropTypes from 'prop-types'
import React from 'react'

import { Container, LeftContainer, RightContainer } from './styles'

export const TopBar = ({ leftContainer, rightContainer, height, ...rest }) => {
  return (
    <Container height={height} {...rest}>
      {leftContainer && <LeftContainer>{leftContainer}</LeftContainer>}

      {rightContainer && <RightContainer>{rightContainer}</RightContainer>}
    </Container>
  )
}

TopBar.defaultProps = {
  height: '80px',
  leftContainer: false,
  rightContainer: false,
}

TopBar.propTypes = {
  height: PropTypes.string,
  leftContainer: PropTypes.element,
  rightContainer: PropTypes.element,
}
