import { css, styled, themeGet, width } from '@lyfta/components-theme'
import DropdownMenu, { NestedDropdownMenu } from 'react-dd-menu'

import { ButtonWithIcon } from '../ButtonWithIcon'
import { Flex } from '../Flex'

export const Menu = styled(DropdownMenu)`
  cursor: pointer;

  .dd-menu {
  }

  .dd-menu-items {
    background-color: ${themeGet('colors.white')};
    box-shadow: 0 3px 19px 0 rgba(0, 0, 0, 0.37);
    min-width: 100px;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
    border-radius: 6px;

    ${width};
  }

  .dd-menu-items,
  ul {
    border-radius: 4px;

    > :last-child,
    > li:last-child > div {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    > :first-child,
    > li:first-child > div {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
`

export const OptionItem = styled(Flex)`
  border-bottom: 1px solid ${themeGet('colors.whiteHover')};
  transition: all ${themeGet('transitionTime')} ease;
  white-space: nowrap;
  text-transform: none;
  text-align: left;
  justify-content: flex-start;
  align-items: center;

  padding: 12px 16px !important;
  display: flex !important;
  font-family: inherit !important;
  line-height: 1.5 !important;
  letter-spacing: 0.5px !important;
  color: ${themeGet('colors.darkGray')} !important;

  &:hover {
    background-color: ${themeGet('colors.whiteHover')};
  }

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    border-bottom-color: ${themeGet('colors.whiteHover')};
  }
`

export const Opener = styled(ButtonWithIcon)`
  display: inline-flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  ${p => {
    if (p.backgroundColorValue && !p.rank) {
      return css`
        background-color: ${themeGet(`colors.${p.backgroundColorValue}`)};
      `
    }
    return css``
  }}
`

export const NestedMenu = styled(NestedDropdownMenu)``
