import { I18n, TranslatedField } from '@lyfta/components-i18n'
import { capitalizeFirstLetter } from '@lyfta/components-ui/src/Services/Utils'
import filter from 'lodash/filter'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import Category from '../Category'
import { Container, Header } from './styles'

class Tags extends PureComponent {
  componentDidMount() {
    const { onLoadTagGroups } = this.props
    onLoadTagGroups()
  }

  handlerChangeTag = value => {
    const { handleOnChange, name, tags } = this.props
    if (tags.includes(value)) {
      const newTags = filter(tags, tag => tag !== value)
      handleOnChange(name, newTags)
    } else {
      handleOnChange(name, [...tags, value])
    }
  }

  render() {
    const { tagGroups, locale, tags } = this.props
    return (
      <Container opened={1}>
        <Header>
          <I18n text="elements.tags" />
        </Header>
        {map(sortBy(tagGroups, `name${capitalizeFirstLetter(locale)}`), tg => (
          <Category
            categoryTags={tg.tags}
            handleOnToggleTag={this.handlerChangeTag}
            key={`tag-${tg.id}`}
            name={<TranslatedField field="name" object={tg} />}
            tags={tags}
          />
        ))}
      </Container>
    )
  }
}

Tags.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tagGroups: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  onLoadTagGroups: PropTypes.func.isRequired,
}

export default Tags
