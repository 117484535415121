import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import {
  ControlsContainer,
  MenuPageTitle,
  NextPageButton,
  PageRightIconContainer,
  SelectedIcon,
  SelectedIconContainer,
  SubtitleButton,
  SubtitleButtonContainer,
} from './styles'

export const SubtitleSelectionPage = ({ player, setCurrentPage }) => {
  const tracks = player.textTracks()

  const initialShowingTextTrack = tracks.tracks_.find(
    track => track.mode === 'showing',
  )

  const [showingTextTrack, setShowingTextTrack] = useState(
    initialShowingTextTrack,
  )

  useEffect(() => {
    for (let i = 0; i < tracks.length; i += 1) {
      const track = tracks[i]

      if (track === showingTextTrack) {
        track.mode = 'showing'
      } else {
        track.mode = 'disabled'
      }
    }
  }, [showingTextTrack])

  const subtitleButtonHandler = calledTrack => {
    // User prefences saved here
    // dispatch(requestUpdateUser({user_profile_attributes: videoPlayerPreferences: { subtitle: calledTrack} } ))
    // subtitle = [ 'off', 'en' ] ???

    setShowingTextTrack(calledTrack)
  }

  return (
    <>
      <MenuPageTitle className="subtitle-menu-page-title">
        <I18n i18nKey="videoPlayer.labels.captionsSubtitles" />
      </MenuPageTitle>
      <ControlsContainer className="subtitle-controls-container">
        <SubtitleButtonContainer
          className="subtitle-button-container"
          key="off"
          onClick={() => subtitleButtonHandler(null)}
        >
          <SelectedIconContainer className="subtitle-selected-icon-container">
            {!showingTextTrack ? <SelectedIcon /> : null}
          </SelectedIconContainer>
          <SubtitleButton
            className="subtitle-button"
            selected={!showingTextTrack}
          >
            <I18n i18nKey="videoPlayer.labels.turnOff" />
          </SubtitleButton>
        </SubtitleButtonContainer>
        {tracks.tracks_.map(track => (
          <SubtitleButtonContainer
            className="subtitle-button-container"
            key={track.language}
            onClick={() => subtitleButtonHandler(track)}
          >
            <SelectedIconContainer className="subtitle-selected-icon-container">
              {track === showingTextTrack ? <SelectedIcon /> : null}
            </SelectedIconContainer>
            <SubtitleButton
              className="subtitle-button"
              selected={track === showingTextTrack}
            >
              {track.label}
            </SubtitleButton>
          </SubtitleButtonContainer>
        ))}
      </ControlsContainer>
      <NextPageButton onClick={() => setCurrentPage('customise')}>
        <I18n i18nKey="videoPlayer.labels.customise" />
        <PageRightIconContainer />
      </NextPageButton>
    </>
  )
}

SubtitleSelectionPage.propTypes = {
  player: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
}
