import { denormalize } from '@lyfta/components-data'
import { getData } from '@lyfta/components-data/src/Store/Selectors/data'
import { createSelector } from 'reselect'

export const getState = state => state.lessonPlans

export const getLessonPlans = createSelector(
  getData,
  getState,
  (data, state) => denormalize(data, 'lessonPlans', state.lessonPlans) || [],
)

export const getLessonPlanTemplates = createSelector(
  getData,
  getState,
  (data, state) =>
    denormalize(data, 'lessonPlans', state.lessonPlanTemplates) || [],
)

export const getCurrentLessonPlan = createSelector(
  getData,
  getState,
  (data, state) =>
    state.currentLessonPlan
      ? denormalize(data, 'lessonPlans', state.currentLessonPlan)
      : null,
)

export const getIsLoaded = createSelector(getState, state => state.isLoaded)
export const getIsLoading = createSelector(getState, state => state.isLoading)
export const getError = createSelector(getState, state => state.error)

export const getNumber = createSelector(getState, state => state.number)
export const getSize = createSelector(getState, state => state.size)
export const getTotal = createSelector(getState, state => state.total)

export const getQuest = createSelector(getState, state => state.quest)

export const getIsDuplicating = createSelector(
  getState,
  state => state.isDuplicating,
)
