import { styled } from '@lyfta/components-theme'
import PropTypes from 'prop-types'
import React from 'react'

const RotatingArrow = styled.g`
  animation-name: ckw;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: ${({ rotating }) => (rotating ? 'running' : 'paused')};
  transform-origin: 20.8px 21.5px;
  @keyframes ckw {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`

function SvgRotateOff({ svgProps }, rest) {
  const { rotating } = svgProps
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      {...rest}
    >
      <path
        d="M20.9796 9C13.7959 9 8 14.8459 8 22C8 29.1541 13.8367 35 20.9796 35C28.1225 35 34 29.195 34 22C34 14.805 28.1633 9 20.9796 9ZM20.9796 10.5943C22.5306 10.5943 24.1225 12.5157 25.102 15.7453C23.7959 15.5409 22.449 15.4182 20.9796 15.4182C19.5102 15.4182 18.1633 15.5409 16.8571 15.7453C17.8367 12.5566 19.4694 10.5943 20.9796 10.5943ZM25.5102 17.4623C25.7551 18.8113 25.9184 20.3239 25.9184 22C25.9184 23.6352 25.7551 25.1887 25.5102 26.5377C24.1633 26.783 22.6531 26.9465 20.9796 26.9465C19.3469 26.9465 17.7959 26.783 16.449 26.5377C16.2041 25.1887 16.0408 23.6761 16.0408 22C16.0408 20.3648 16.2041 18.8113 16.449 17.4623C17.7959 17.217 19.3061 17.0535 20.9796 17.0535C22.6531 17.0535 24.1633 17.217 25.5102 17.4623ZM17.2653 11.2484C16.3673 12.4748 15.6326 14.1509 15.1429 16.1132C13.1837 16.6038 11.5102 17.3396 10.2857 18.239C11.4286 14.9686 14 12.3931 17.2653 11.2484ZM14.7755 17.8711C14.5714 19.1792 14.449 20.5283 14.449 22C14.449 23.4308 14.5714 24.8207 14.7755 26.1289C11.551 25.1887 9.63265 23.5534 9.63265 22C9.63265 20.4465 11.551 18.8522 14.7755 17.8711ZM10.2857 25.761C11.5102 26.6604 13.1837 27.3962 15.1429 27.8868C15.6326 29.849 16.3673 31.5251 17.2653 32.7516C14 31.6069 11.4286 29.0314 10.2857 25.761ZM20.9796 33.4057C19.4286 33.4057 17.8367 31.4843 16.8571 28.2547C18.1633 28.4591 19.5102 28.5818 20.9796 28.5818C22.449 28.5818 23.7959 28.4591 25.102 28.2547C24.1633 31.4434 22.5306 33.4057 20.9796 33.4057ZM24.7347 32.7516C25.6327 31.5251 26.3674 29.849 26.8571 27.8868C28.8163 27.3962 30.4898 26.6604 31.7143 25.761C30.5714 29.0314 28 31.6069 24.7347 32.7516ZM27.2245 26.1289C27.4286 24.8207 27.551 23.4717 27.551 22C27.551 20.5692 27.4286 19.1792 27.2245 17.8711C30.449 18.8113 32.3673 20.4465 32.3673 22C32.3673 23.5534 30.449 25.1478 27.2245 26.1289ZM26.8571 16.1132C26.3674 14.1509 25.6327 12.4748 24.7347 11.2484C28 12.3931 30.5714 14.9686 31.7143 18.239C30.4898 17.3396 28.8163 16.6038 26.8571 16.1132Z"
        fill="#050B15"
      />
      <path
        d="M17.75 22C17.75 23.7727 19.1851 25.25 21 25.25C22.8149 25.25 24.25 23.8149 24.25 22C24.25 20.1851 22.8149 18.75 21 18.75C19.1851 18.75 17.75 20.2273 17.75 22ZM22.5195 22C22.5195 22.8442 21.8019 23.5617 20.9578 23.5617C20.1136 23.5617 19.3961 22.8442 19.3961 22C19.3961 21.1558 20.1136 20.4383 20.9578 20.4383C21.8441 20.4383 22.5195 21.1558 22.5195 22Z"
        fill="#050B15"
      />
      <RotatingArrow rotating={rotating}>
        <path
          d="M41.7474 21.0448L39.4316 18.7685C39.0947 18.4374 38.5895 18.4374 38.2526 18.7685L35.9368 21.0448C35.6 21.3759 35.6 21.8725 35.9368 22.2036C36.2737 22.5347 36.7789 22.5347 37.1158 22.2036L38 21.3345V21.5C38 30.8121 30.2947 38.3859 20.8211 38.3859C11.3895 38.3859 3.68421 30.8121 3.68421 21.5C3.68421 12.1879 11.3895 4.61409 20.8211 4.61409C27.9368 4.61409 34.2105 8.83556 36.8211 15.2919C36.9895 15.7058 37.4947 15.9127 37.9158 15.7472C38.3368 15.5816 38.5474 15.085 38.3789 14.6711C35.5158 7.59395 28.6105 3 20.8211 3C10.4632 3 2 11.2774 2 21.5C2 31.7226 10.4632 40 20.8211 40C31.1789 40 39.6842 31.7226 39.6842 21.5V21.3345L40.5684 22.2036C40.7368 22.3692 40.9474 22.4519 41.1579 22.4519C41.3684 22.4519 41.5789 22.3692 41.7474 22.2036C42.0842 21.9139 42.0842 21.3759 41.7474 21.0448Z"
          fill="#050B15"
        />
      </RotatingArrow>
    </svg>
  )
}

SvgRotateOff.defaultProps = {
  rotating: false,
}

SvgRotateOff.propTypes = {
  svgProps: PropTypes.object.isRequired,
  rotating: PropTypes.bool,
}

export default SvgRotateOff
