import PT from 'prop-types'
import React from 'react'

import Component from './Component'

const Welcome = ({ currentActivity, pauseWebGL, resumeWebGL, ...props }) => {
  return <Component {...props} currentActivity={currentActivity} />
}
Welcome.defaultProps = {
  currentActivity: null,
}
Welcome.propTypes = {
  currentActivity: PT.object,
  pauseWebGL: PT.func.isRequired,
  resumeWebGL: PT.func.isRequired,
}
export default Welcome
