import { styled, themeGet } from '@lyfta/components-theme'

import { Flex } from '../Flex'

export const Container = styled(Flex).attrs(() => ({}))`
  flex-direction: column;
  overflow: hidden;
  flex: 1;

  .rbc-calendar {
    > div {
      border-left-width: 0;
    }
    > .rbc-toolbar {
      margin: 8px;
    }

    .rbc-active {
    }

    .rbc-current-time-indicator {
      background-color: ${themeGet('colors.lightGray')};
    }

    .rbc-event {
      background-color: ${themeGet('colors.primary')};
      border: 1px solid ${themeGet('colors.primary')};
    }

    .rbc-event.rbc-selected,
    .rbc-day-slot .rbc-selected.rbc-background-event {
      background-color: ${themeGet('colors.primary')};
    }

    .rbc-btn-group > button,
    .rbc-event:focus,
    .rbc-day-slot .rbc-background-event:focus {
      outline: none !important;
    }
  }
`
