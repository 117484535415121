import { styled } from '@lyfta/components-theme'
import { Flex, TouchableFlex } from '@lyfta/components-ui'

export const Container = styled(TouchableFlex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  width: 100%;
  height: 36px;
`
export const Wrapper = styled(Flex).attrs({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  p: 2,
  pl: 0,
  marginBottom: '15px',
  marginLeft: '20px',
  width: 300,
})`
  height: 36px;
`
