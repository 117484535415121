import { styled } from '@lyfta/components-theme'
import { merge } from 'lodash'

import Header from '../Header'

const Title = styled(Header).attrs(p =>
  merge(
    {
      level: 1,
      fontSize: [4, 3, 3],
      mb: 4,
    },
    p,
  ),
)`
  ${p => p.fontSize && `font-size: ${p.fontSize};`}
  ${p => p.letterSpacing && `letter-spacing: ${p.letterSpacing};`}
  width: 100%;
  font-weight: bold;
`

Title.H1 = styled(Title)``

Title.H2 = styled(Title).attrs(p =>
  merge(
    {
      level: 2,
      fontSize: [3, 2, 2],
    },
    p,
  ),
)``

Title.H4 = styled(Title).attrs(p =>
  merge(
    {
      level: 4,
      fontSize: [2, 2, 2],
    },
    p,
  ),
)``

export default Title
