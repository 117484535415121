import { I18n, getTranslation } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import {
  Body1Text,
  ColourSelectorButton,
  ColourSelectorSwatch,
  SettingSelectorContainer,
} from './styles'

const colours = [
  {
    name: getTranslation('videoPlayer.labels.colours.white'),
    themeColourName: 'subtitle_white',
  },
  {
    name: getTranslation('videoPlayer.labels.colours.yellow'),
    themeColourName: 'subtitle_yellow',
  },
  {
    name: getTranslation('videoPlayer.labels.colours.green'),
    themeColourName: 'subtitle_green',
  },
  {
    name: getTranslation('videoPlayer.labels.colours.cyan'),
    themeColourName: 'subtitle_cyan',
  },
  {
    name: getTranslation('videoPlayer.labels.colours.magenta'),
    themeColourName: 'subtitle_magenta',
  },
  {
    name: getTranslation('videoPlayer.labels.colours.red'),
    themeColourName: 'subtitle_red',
  },
  {
    name: getTranslation('videoPlayer.labels.colours.black'),
    themeColourName: 'subtitle_black',
  },
  // {
  //   name: getTranslation('videoPlayer.labels.colours.orange'),
  //   themeColourName: 'subtitle_orange',
  // },
]

export const ColourSelector = ({ player, property }) => {
  // const myTextTrackSettings = player.textTrackSettings.getValues()

  const [currentColour, setCurrentColour] = useState(
    player.options_.videoPlayerPreferences[property],
  )

  useEffect(() => {
    const newTextTrackSettingsValues = {
      [property]: currentColour,
    }

    player.textTrackSettings.setValues(newTextTrackSettingsValues)
    player.options_.updateUserPreferences({ [property]: currentColour })

    player.textTrackSettings.updateDisplay()
  }, [currentColour])

  const handleClick = themeColourName => {
    switch (themeColourName) {
      case 'subtitle_white':
        setCurrentColour('#FFF')
        break
      case 'subtitle_yellow':
        setCurrentColour('#FF0')
        break
      case 'subtitle_green':
        setCurrentColour('#0F0')
        break
      case 'subtitle_cyan':
        setCurrentColour('#0FF')
        break
      case 'subtitle_magenta':
        setCurrentColour('#F0F')
        break
      case 'subtitle_red':
        setCurrentColour('#F00')
        break
      case 'subtitle_black':
        setCurrentColour('#000')
        break
      // case 'subtitle_orange':
      //   setCurrentColour('#FA0')
      //   break
      default:
        setCurrentColour(player.options_.videoPlayerPreferences[property])
    }
  }

  return (
    <>
      <Body1Text>
        <I18n i18nKey="videoPlayer.labels.colour" />
      </Body1Text>
      <SettingSelectorContainer mb={3} className="colour-selector-container">
        {colours.map(colour => (
          <ColourSelectorButton
            key={colour.name}
            onClick={() => handleClick(colour.themeColourName)}
          >
            <ColourSelectorSwatch
              className="colour-selector-button"
              selected={(() => {
                if (
                  currentColour === '#FFF' &&
                  colour.themeColourName === 'subtitle_white'
                ) {
                  return true
                }
                if (
                  currentColour === '#FF0' &&
                  colour.themeColourName === 'subtitle_yellow'
                ) {
                  return true
                }
                if (
                  currentColour === '#0F0' &&
                  colour.themeColourName === 'subtitle_green'
                ) {
                  return true
                }
                if (
                  currentColour === '#0FF' &&
                  colour.themeColourName === 'subtitle_cyan'
                ) {
                  return true
                }
                if (
                  currentColour === '#F0F' &&
                  colour.themeColourName === 'subtitle_magenta'
                ) {
                  return true
                }
                if (
                  currentColour === '#F00' &&
                  colour.themeColourName === 'subtitle_red'
                ) {
                  return true
                }
                if (
                  currentColour === '#000' &&
                  colour.themeColourName === 'subtitle_black'
                ) {
                  return true
                }
                // if (
                //   currentColour === '#FA0' &&
                //   colour.themeColourName === 'subtitle_orange'
                // ) {
                //   return true
                // }
                return false
              })()}
              themeColourName={colour.themeColourName}
            />
            {colour.name}
          </ColourSelectorButton>
        ))}
      </SettingSelectorContainer>
    </>
  )
}

ColourSelector.propTypes = {
  player: PropTypes.object.isRequired,
  property: PropTypes.string.isRequired,
}
