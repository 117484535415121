import { styled } from '@lyfta/components-theme'
import { motion } from 'framer-motion'
import PT from 'prop-types'
import React from 'react'

import { createButton } from './factories'

const AnimatedButton = ({ animation, ...props }) => (
  <motion.button {...animation} {...props} />
)

AnimatedButton.propTypes = {
  animation: PT.object.isRequired,
}

// Button tag adds some css styles which break the design. Using div to avoid those problems
const Touchable = createButton(styled.button)
const TouchableAnimated = createButton(styled(AnimatedButton))
const TouchableDiv = createButton(styled.div)

const fixKeys = children => {
  if (React.Children.count(children) === 1) {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        children: fixKeys(children.props.children),
      })
    }

    return children
  }
  return React.Children.map(children, (child, index) => {
    if (!child || !child.props) {
      return child
    }

    if (child.props.key === undefined || child.props.key === null) {
      // eslint-disable-next-line react/no-array-index-key
      return <React.Fragment key={index}>{fixKeys(child)}</React.Fragment>
    }

    return child
  })
}

export const Button = React.forwardRef(
  (
    {
      buttonDiv,
      children,
      leadingIcon,
      motionButton,
      secondary,
      subtype,
      trailingIcon,
      type,
      onClick,
      onPress,
      ...props
    },
    ref,
  ) => {
    if (motionButton) {
      return (
        <TouchableAnimated
          ref={ref}
          onClick={onClick || onPress}
          {...(typeof children === 'string' ? { 'aria-label': children } : {})}
          {...props}
        >
          {leadingIcon}
          {type !== 'icon' && fixKeys(children)}
          {trailingIcon}
        </TouchableAnimated>
      )
    }
    if (buttonDiv) {
      return (
        <TouchableDiv
          tabIndex="0"
          ref={ref}
          onClick={onClick || onPress}
          {...(typeof children === 'string' ? { 'aria-label': children } : {})}
          {...props}
        >
          {leadingIcon}
          {type !== 'icon' && fixKeys(children)}
          {trailingIcon}
        </TouchableDiv>
      )
    }
    return (
      <Touchable
        ref={ref}
        {...(!(subtype === 'navigation' && !secondary)
          ? { onClick: onClick || onPress }
          : { 'aria-disabled': true })}
        {...(typeof children === 'string' ? { 'aria-label': children } : {})}
        {...props}
        type={type}
      >
        {leadingIcon}
        {type !== 'icon' && fixKeys(children)}
        {trailingIcon}
      </Touchable>
    )
  },
)

Button.defaultProps = {
  buttonDiv: false,
  leadingIcon: null,
  motionButton: false,
  secondary: false,
  subtype: '',
  trailingIcon: null,
  type: '',
  onClick: () => {},
  onPress: null,
}

Button.propTypes = {
  buttonDiv: PT.bool,
  children: PT.node.isRequired,
  leadingIcon: PT.oneOfType([PT.element, PT.func, PT.object]),
  motionButton: PT.bool,
  secondary: PT.bool,
  subtype: PT.string,
  trailingIcon: PT.oneOfType([PT.element, PT.func, PT.object]),
  type: PT.string,
  onClick: PT.func,
  onPress: PT.func,
}

Button.displayName = 'Button'
Touchable.displayName = 'Touchable'
TouchableAnimated.displayName = 'TouchableAnimated'
TouchableDiv.displayName = 'TouchableDiv'
