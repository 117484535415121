import PropTypes from 'prop-types'
import React from 'react'
import { renderRoutes } from 'react-router-config'
import { useHistory } from 'react-router-dom'

import { useRouteSetup } from '../../Hooks/useRouteSetup'

export const RouteContainer = ({ route, children }) => {
  const history = useHistory()
  const { accessAllowed } = useRouteSetup(route)
  if (!route) return null

  if (accessAllowed === false) history.push('/404')
  return (
    <>
      {children}
      {renderRoutes(route.routes)}
    </>
  )
}

RouteContainer.defaultProps = { children: null, route: null }
RouteContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
  route: PropTypes.object,
}
