import * as React from 'react'

function SvgLogOut(props) {
  return (
    <svg height="1.3em" viewBox="0 0 125.52 117.422" width="1.3em" {...props}>
      <defs>
        <clipPath id="SignOutNew_svg__a">
          <path
            clipRule="evenodd"
            d="M125.52 88.422h-20.245V-29h20.245zM47.609 9.528h45.519v40.366H47.609v22.332L0 29.71 47.609-12.8z"
            transform="translate(0 29)"
          />
        </clipPath>
        <clipPath id="SignOutNew_svg__b">
          <path
            className="SignOutNew_svg__b"
            d="M-60 3366.96h7774.139V-1006H-60z"
            transform="translate(60 1006)"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#SignOutNew_svg__a)">
        <g
          clipPath="url(#SignOutNew_svg__b)"
          transform="translate(-242.942 -3955.908)"
        >
          <path
            className="SignOutNew_svg__b"
            d="M222.697 4093.575h166.01v-157.912h-166.01z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgLogOut
