import { space, styled, themeGet } from '@lyfta/components-theme'
import PropTypes from 'prop-types'

import { Container } from '../../BaseComponents'

const Scrollable = styled(Container)`
  border: none;
  flex-grow: 1;
  overflow-y: auto;
  height: ${p => (p.height ? p.height : '100%')};
  width: ${p => (p.width ? p.width : '100%')};
  background-color: ${props => themeGet(`colors.${props.color || 'white'}`)};

  ::-webkit-scrollbar {
    width: 7px;
    background-color: ${themeGet('colors.silver')};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: ${themeGet('colors.dustyGray')};
  }

  ${space};
`

Scrollable.propTypes = {
  height: PropTypes.string,
}

export default Scrollable
