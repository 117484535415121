import { css, styled, themeGet, width } from '@lyfta/components-theme'

import { Box } from '../Box'
import { Flex } from '../Flex'

export const StyledOption = styled(Box)`
  padding: 12px 12px;
  border-bottom: 1px solid ${themeGet('colors.whiteHover')};
  transition: all ${themeGet('transitionTime')} ease;
  ${props =>
    props.color
      ? css`
          color: ${themeGet(`colors.${props.color}`)};
        `
      : css``};

  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:hover {
    background-color: ${themeGet('colors.whiteHover')};
  }

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    border-bottom-color: ${themeGet('colors.whiteHover')};
  }
`

export const Menu = styled(Flex)`
  flex-direction: column;
  background-color: ${themeGet('colors.white')};
  box-shadow: 0 3px 19px 0 rgba(0, 0, 0, 0.37);
  position: absolute;

  min-width: 100px;
  max-width: 135px;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  border-radius: 6px;

  ${props =>
    props.left
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `};

  ${props =>
    props.top
      ? css`
          max-height: 200px;
          overflow-y: scroll;
          bottom: 100%;
        `
      : css`
          top: 130%;
        `};

  ${width};
`

export const Opener = styled(Box)`
  display: inline-flex;
  width: 100%;
  font-size: 14px;
  justify-content: center;
  align-items: center;
`

export const StyledDropdown = styled(Box)`
  display: inline-flex;
  position: relative;
  color: ${themeGet('colors.font.primary')};
  font-family: ${themeGet('font')};
  margin: 0;
  overflow: visible;
  text-transform: none;
  box-shadow: none;
  cursor: pointer;
  transition: all ${themeGet('transitionTime')};
`

export const PickerWrapper = styled(Flex).attrs(() => ({
  p: 2,
}))`
  width: 100%;
  border-bottom: 1px solid ${themeGet('colors.whiteHover')};
  justify-content: space-between;
  align-items: center;

  > svg {
    margin-left: 4px;
    transition: all 270ms ease;
    transform: rotate(0deg);

    ${props =>
      props.opened &&
      css`
        transform: rotate(180deg);
      `};
  }

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    border-bottom-color: ${themeGet('colors.whiteHover')};
  }
`
