import * as React from 'react'

function SvgCopyToClipboard(props) {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M10 2C8.89543 2 8 2.89543 8 4V16C8 17.1046 8.89543 18 10 18H18C19.1046 18 20 17.1046 20 16V4C20 2.89543 19.1046 2 18 2H10ZM10 4H18V16H10V4ZM4 7V19C4 20.6569 5.34315 22 7 22H17V20H7C6.44772 20 6 19.5523 6 19V7H4Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCopyToClipboard
