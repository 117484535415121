import * as React from 'react'

function SvgTick(props) {
  return (
    <svg height="1em" viewBox="0 0 24 22" width="1em" {...props}>
      <path
        d="M.556 14.206c-.82-.795-.717-2.087.204-2.783.82-.795 2.152-.695 2.869.2l4.609 5.167L21.348.988c1.64-1.987 3.688-.695 2.05 1.392L10.081 21.163l-.308.298c-.922.795-2.15.696-2.97-.199L.556 14.206z"
        fill="#838383"
      />
    </svg>
  )
}

export default SvgTick
