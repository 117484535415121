import { appUrls } from '@lyfta/components-config'
import { getViewerType } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { I18n } from '@lyfta/components-i18n'
import { TabTitle, USER_TYPES } from '@lyfta/components-ui'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import Confetti from '../../Confetti/Confetti'
import {
  AchievementContainer,
  AchievementImage,
  Container,
  Content,
  Paragraph,
  Submit,
  TrophyImage,
} from './styles'

const Achievement = ({ containerAnimation, hideAchievement }) => {
  const viewerType = useSelector(getViewerType)

  const onCloseClick = useCallback(() => {
    if (viewerType === USER_TYPES.homeStudents) {
      window.location.href = appUrls.student(
        window.location,
        '',
        'lesson-selection',
      )
      return
    }
    hideAchievement()
  }, [viewerType])
  return (
    <Container animation={containerAnimation} onClick={hideAchievement}>
      <Confetti />
      <AchievementContainer>
        <AchievementImage />
        <Content>
          <TrophyImage />
          <I18n
            level={1}
            style={{
              color: 'white',
              fontSize: '36px',
              paddingBottom: '10px',
              paddingTop: '10px',
            }}
            tag={TabTitle}
            text="quest.congratulations"
          />
          <I18n tag={Paragraph} text="quest.questComplete" />
          {/* Initially Commented out, but I am worried that without a call to action the next step is unclear,
          also uncommeting as the tap on the background does nto work on native. needs a better solution if the button needs to be removed. */}

          <Submit onClick={onCloseClick}>
            {viewerType === USER_TYPES.homeStudents && (
              <I18n text="quest.backToLessons" />
            )}
            {viewerType !== USER_TYPES.homeStudents && (
              <I18n style={{ fontSize: '20px' }} text="quest.close" />
            )}
          </Submit>
        </Content>
      </AchievementContainer>
    </Container>
  )
}

Achievement.defaultProps = {
  containerAnimation: null,
}

Achievement.propTypes = {
  containerAnimation: PropTypes.object,
  hideAchievement: PropTypes.func.isRequired,
}

export default Achievement
