import { get } from 'lodash'
import { createSelector } from 'reselect'

import { getAllEntities, getEntity } from '../../../utils'

export const getUsers = getAllEntities('users')
export const getCurrentUser = getEntity('users')

export const getUserName = createSelector(getCurrentUser, user => {
  return user && `${get(user, 'firstName')} ${get(user, 'lastName')}`
})
