import { getViewerId } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { all, put, select, takeLatest } from 'redux-saga/effects'

import { REQUEST_SAVE_EXPERIENCE, saveExperience } from '../Actions/experiences'
import { getCurrentLesson } from '../Selectors/lessons'
import { getCurrentActivity, getCurrentTask } from '../Selectors/questTracker'

function* create({ payload: { type, ...payload } }) {
  const { id: activityId } = yield select(getCurrentActivity)
  const currentTask = yield select(getCurrentTask)

  const { id: entityId, answerData: storedData } = currentTask || {
    id: null,
    answerData: null,
  }

  const {
    id: lessonId,
    lessonPlan: { id: lessonPlanId },
  } = yield select(getCurrentLesson)

  const actor = yield select(getViewerId)
  let additionalContexts = []
  let experienceId = storedData?.id

  let experienceData = {}
  let verb = 'answered'

  if (type === 'helpMeFound') {
    const { contentId } = payload
    additionalContexts = [
      { entity_id: parseInt(contentId, 10), entity_type: 'Content' },
    ]
    experienceData = { contentId }
  } else if (type === 'answer') {
    const {
      data: { answer },
    } = payload
    experienceData = { answer }
  } else if (type === 'custom') {
    experienceData = payload.data
    verb = payload.verb
    // DO NOT overwrite existing results...
    experienceId = null
  }

  yield put(
    saveExperience({
      experienceId,
      lessonPlanId,
      lessonId,
      activityId,
      entityId,
      entityType: 'Task',
      actor,
      additionalContexts,
      experienceData,
      verb,
    }),
  )
}

// function* update({ id, state }) {}

export default function* root() {
  yield all([takeLatest(REQUEST_SAVE_EXPERIENCE, create)])
}
