import { styled } from '@lyfta/components-theme'
import { Flex, Markdown, PropTypes, themeGet } from '@lyfta/components-ui'
import React from 'react'

import { ActivityRow as BaseActivityRow } from './_styles'

export { ActivityContent, ActivityDetails, ActivityTime } from './_styles'

export const ActivityRow = styled(BaseActivityRow).attrs(() => ({
  borderBottomStyle: 'solid',
}))`
  cursor: ${({ canBeSelected }) => (canBeSelected ? 'pointer' : 'default')};
  &:hover {
    background-color: ${p =>
      p.canBeSelected && themeGet('colors.sa_primary_60')(p)};
  }
  &:focus-visible:not(:active) {
    background-color: ${p =>
      p.canBeSelected && themeGet('colors.sa_primary_60')(p)};
    outline-color: ${themeGet('colors.black_800')};
    outline-style: solid;
    outline-width: 2px;
    outline-offset: -8px;
  }
`

export const ActivityH3Markdown = styled(Markdown).attrs(p => ({
  source: p.source,
  type: 'inherit',
}))`
  white-space: nowrap;
  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const ActivityH3Text = styled.div`
  white-space: nowrap;
  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const DetailsTimeContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
`

DetailsTimeContainer.displayName = 'DetailsTimeContainer'

ActivityRow.displayName = 'ActivityRow'
ActivityH3Text.displayName = 'ActivityH3Text'

export const ActivityH3 = props => {
  const { source, ...rest } = props

  // If it could be mistaken for a list item, don't use markdown.
  // https://www.w3schools.io/file/markdown-list/
  if (source && source.match(/^(?:\d+\s*[.)]|[-*+]\s+)/)) {
    return <ActivityH3Text {...rest}>{source}</ActivityH3Text>
  }

  return <ActivityH3Markdown {...props} />
}

ActivityH3.propTypes = {
  source: PropTypes.string,
}

ActivityH3.defaultProps = {
  source: '',
}
