import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import { TAGS_HIDE, TAGS_SHOW, TAGS_TOGGLE } from '../../Actions/ui'

const initialState = {
  open: false,
}

const show = state => state.merge({ open: true })
const hide = state => state.merge({ open: false })
const toggle = state => state.update('open', open => !open)

const handlers = {
  [TAGS_HIDE]: hide,
  [TAGS_SHOW]: show,
  [TAGS_TOGGLE]: toggle,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
