import { getAuthorId } from '@lyfta/components-data/src/Store/Selectors/viewer'
/* eslint-disable */
import {
  I18n,
  TranslatedField,
  getTranslatedField,
  getTranslation,
  globalFormatDate,
} from '@lyfta/components-i18n'
import {
  Bin,
  ButtonNew,
  Duplicate,
  Flex,
  Globe,
  Icon,
  InfoCount,
  LyftaLogoSymbolNoBorder,
  Share,
  ShareLink,
  User,
} from '@lyfta/components-ui'
import {
  createAgeRangeLabel,
  getInitials,
  reactEvent,
} from '@lyfta/components-ui/src/Services/Utils'
import { get, take } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { TYPE_COLLECTION } from '../../Constants/contents'
import { requestDuplicateCollection } from '../../Store/Actions/collections'
import {
  addToFavorites,
  tryRemoveFromFavorites,
} from '../../Store/Actions/favorites'
import {
  Card,
  CardAuthor,
  CardAuthorIcon,
  CardDescription,
  CardFooter,
  CardTitle,
  KebabButton,
  KebabContainer,
  LeftContainer,
  RightContainer,
  ViewContainer,
} from './styles'

const Card8 = ({ onView, item, section, ga, paths }) => {
  const getFavorite = get(item, 'favorites')[0]
  const type = get(item, 'type')
  const state = get(item, 'state')
  const authorId = get(item, 'author.id')
  const createdByLyfta = get(item, 'createdByLyfta')
  const isCollection = type === TYPE_COLLECTION
  const contentId = get(item, 'id')
  const dispatch = useDispatch()
  const [showShareModal, setShowShareModal] = useState(false)
  const url = onView(item, true)
  const history = useHistory()
  const authorIdViewer = useSelector(getAuthorId)
  const preferredColour = get(item, 'authorPreferredColour')
  const profileImageUrl = get(item, 'authorProfileImageUrl')
  const collectionId = get(item, 'collection.id') || ''
  const ageRangeLabel = createAgeRangeLabel(get(item, 'ageRanges'))
  const handlePreview = () => {
    if (ga) reactEvent({ action: ga })

    reactEvent({
      action: 'clicked_content_card',
      params: {
        content_type: get(item, 'type') || false,
        content_id: get(item, 'id'),
        content_name: getTranslatedField(item, 'name'),
        content_section: section,
      },
    })

    onView(item)
  }

  const onClickFavorite = useCallback(
    e => {
      e.stopPropagation()
      if (getFavorite) {
        return new Promise(resolve =>
          dispatch(
            tryRemoveFromFavorites(
              { favorite: getFavorite, contentId },
              resolve,
              {
                success: {
                  message: !isCollection
                    ? getTranslation('toast.success.favoriteRemove')
                    : getTranslation('toast.success.unsubscribed'),
                },
              },
            ),
          ),
        ).then(() => {})
      }

      return dispatch(
        addToFavorites(contentId, {
          success: {
            message: !isCollection
              ? getTranslation('toast.success.favoriteAdd')
              : getTranslation('toast.success.subscribed'),
          },
        }),
      )
    },
    [getFavorite],
  )

  const onClickDuplicate = () => {
    new Promise(resolve =>
      dispatch(requestDuplicateCollection({ item }, resolve)),
    ).then(value => {
      return history.push(paths.collection.edit(value))
    })
  }
  let KebabButtonOptions = [
    {
      value: 0,
      label: (
        <Flex onClick={onClickFavorite}>
          <Icon
            glyph={Bin}
            width={16}
            height={16}
            mr={2}
            mt={1}
            fill="scarletRed"
          />
          <I18n i18nKey="actions.removeSubscription" />
        </Flex>
      ),
    },
    {
      value: 0,
      label: (
        <Flex onClick={onClickDuplicate}>
          <Icon
            glyph={Duplicate}
            width={16}
            height={16}
            mr={2}
            mt={1}
            // fill="scarletRed"
          />
          <I18n i18nKey="actions.duplicateCollection" />
        </Flex>
      ),
    },
    ,
  ]

  if (state === 'published') {
    KebabButtonOptions.push({
      value: 0,
      label: (
        <Flex onClick={() => setShowShareModal(true)}>
          <Icon glyph={Share} width={16} height={16} mr={2} mt={1} />
          <I18n i18nKey="actions.shareColleagues" />
        </Flex>
      ),
    })
  }

  const isOwner = String(authorIdViewer) === String(authorId)
  const filteredKebabButtonOptions = isOwner
    ? KebabButtonOptions.slice(1)
    : KebabButtonOptions

  const authorNameLabel = isOwner
    ? `${getTranslation('collections.you')} - ${globalFormatDate(
        item.createdAt,
      )}`
    : `${item.authorName} - ${globalFormatDate(item.createdAt)}`

  return (
    <Card onClick={() => handlePreview()}>
      <LeftContainer image={item.posterUrl} />

      <RightContainer width="100%">
        <CardTitle id="title" width="85%" flexWrap="wrap">
          <Icon
            glyph={item.createdByLyfta ? Globe : User}
            width={18}
            height={18}
            mr={2}
            fill="black_900"
          />
          <Flex minWidth="fit-content" flexWrap="nowrap" mr={2}>
            {ageRangeLabel}
          </Flex>
          <TranslatedField mr={2} field="name" object={item} />
          <Flex ml={2} minWidth="fit-content">
            {type === TYPE_COLLECTION && !createdByLyfta
              ? `(ID: ${take(String(collectionId), 4).join('')})`
              : ''}
          </Flex>
        </CardTitle>
        <CardDescription>
          <TranslatedField
            truncateString="255"
            field="longDescription"
            object={item}
          />
        </CardDescription>
        <CardAuthor>
          {item.createdByLyfta && (
            <Flex justifyContent="center" alignItems="center">
              <CardAuthorIcon lyfta>
                <Icon
                  glyph={LyftaLogoSymbolNoBorder}
                  width={30}
                  height={30}
                  // mr={2}
                  fill="white"
                />
              </CardAuthorIcon>
              <I18n i18nKey="collections.createdByLyfta" />
              <span style={{ marginRight: '5px' }}></span>·{' '}
              {globalFormatDate(get(item, 'createdAt'))}
            </Flex>
          )}
          {!item.createdByLyfta && (
            <Flex justifyContent="center" alignItems="center">
              <CardAuthorIcon
                preferredColour={preferredColour}
                profileImageUrl={profileImageUrl}
              >
                {!profileImageUrl && getInitials(item.authorName)}
              </CardAuthorIcon>
              <I18n
                i18nKey="collections.createdByTeacher"
                options={{ authorName: authorNameLabel }}
              />
            </Flex>
          )}
        </CardAuthor>
        <CardFooter id="footer" width="75%">
          <InfoCount
            minWidth="100%"
            type="collections"
            alignItems="center"
            subscribedCount={item.subscribedCount}
            viewedCount={item.viewedCount}
          />
        </CardFooter>

        <KebabContainer
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <KebabButton
            dataIntercomTarget="kebab-btn"
            options={filteredKebabButtonOptions}
            placeholder=""
            closeMenuOnSelect={false}
          />
        </KebabContainer>
        <ViewContainer>
          <ButtonNew
            type="link"
            default={{
              letterSpacing: '0.5px',
              paddingRight: '60px',
            }}
          >
            {item.createdByLyfta ? (
              <I18n i18nKey="actions.view" />
            ) : (
              <I18n i18nKey="actions.viewEdit" />
            )}
          </ButtonNew>
        </ViewContainer>
      </RightContainer>
      <ShareLink
        isOpen={showShareModal}
        onChooseNegative={() => setShowShareModal(false)}
        url={url}
      />
    </Card>
  )
}
Card8.defaultProps = {
  number: 0,
  icon: null,
  label: '',
  labelMouseOver: '',
  link: () => {},
  ga: '',
}

Card8.propTypes = {
  number: PropTypes.number,
  icon: PropTypes.element,
  label: PropTypes.string,
  labelMouseOver: PropTypes.string,
  link: PropTypes.func,
  ga: PropTypes.string,
}

export default Card8
