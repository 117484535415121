import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { Category, Container, Tag } from './styles'

class TagBlock extends PureComponent {
  render() {
    const { category, tag } = this.props
    return (
      <Container>
        <Tag>{tag}</Tag>
        <Category>{category}</Category>
      </Container>
    )
  }
}

TagBlock.propTypes = {
  category: PropTypes.object.isRequired,
  tag: PropTypes.object.isRequired,
}

export default TagBlock
