import { styled, themeGet } from '@lyfta/components-theme'

import { Flex } from '../Flex'

export const InfoContainer = styled(Flex)`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: ${p =>
    p.color
      ? themeGet(`colors.${p.color}`)(p)
      : themeGet('colors.secondary_130')};
`

export default InfoContainer
