import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'

import { ActivityTime } from '../styles'

const ActivityTimeIndicator = ({
  activity: { duration, selected, complete },
}) => (
  <I18n
    complete={complete}
    options={{ count: duration }}
    selected={selected}
    tag={ActivityTime}
    text="quest.duration"
  />
)

ActivityTimeIndicator.propTypes = {
  activity: PropTypes.object.isRequired,
}

export default ActivityTimeIndicator
