import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ChevronIcon from 'react-icons/lib/go/chevron-down'

import { PickerWrapper } from './styles'

// eslint-disable-next-line react/prefer-stateless-function
class Picker extends Component {
  render() {
    const { children } = this.props
    const { isOpened } = this.context

    return (
      <PickerWrapper opened={isOpened ? 1 : 0}>
        {children}
        <ChevronIcon size={10} />
      </PickerWrapper>
    )
  }
}

Picker.displayName = 'Dropdown.Picker'

Picker.contextTypes = {
  isOpened: PropTypes.bool,
}

Picker.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Picker
