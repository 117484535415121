import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { modal } from '../../../../Store/Actions/ui'
import {
  getIsLoading,
  getIsOpen,
  getProps,
} from '../../../../Store/Selectors/UI/modal'
import Component from './Modal'

const selector = createStructuredSelector({
  isLoading: getIsLoading,
  isOpen: getIsOpen,
  props: getProps,
})

const actions = {
  onChooseNegative: modal.chooseNegative,
  onChoosePositive: modal.choosePositive,
}

export default connect(selector, actions)(Component)
