import {
  formatDateHoursMinutes,
  getTranslatedField,
  getTranslation,
  globalFormatDate,
} from '@lyfta/components-i18n'
import { Lessons } from '@lyfta/components-ui/src/Assets/Sidebar'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import {
  LessonButton,
  LessonIconContainer,
  LessonInfo,
  LessonInfoText,
  LessonText,
} from '../styles'

const LessonRow = ({
  handleLessonButton,
  lesson: { id, createdAt, lessonPlan, klass, frontOfClass },
}) => {
  const name = getTranslatedField(lessonPlan, 'name')
  const klassName = useMemo(() => {
    return frontOfClass
      ? getTranslation('lessons.frontClassTime', {
          time: `${globalFormatDate(createdAt)} ${formatDateHoursMinutes(
            createdAt,
          )}`,
        })
      : get(klass, 'name', '')
  }, [frontOfClass, klass, createdAt])

  return (
    <LessonButton
      key={`lesson-${id}`}
      onClick={handleLessonButton(id, frontOfClass)}
    >
      <LessonIconContainer>
        <Lessons fill="white" height={38} width={38} />
      </LessonIconContainer>
      <LessonInfo>
        <LessonText>{name}</LessonText>
        <LessonInfoText>{klassName}</LessonInfoText>
      </LessonInfo>
    </LessonButton>
  )
}

LessonRow.propTypes = {
  handleLessonButton: PropTypes.func.isRequired,
  lesson: PropTypes.object.isRequired,
}

export default LessonRow
