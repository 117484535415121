import {
  clearErrors,
  loginToLesson,
} from '@lyfta/components-data/src/Store/Actions/auth'
import {
  getError,
  getIsLoading,
} from '@lyfta/components-data/src/Store/Selectors/auth'
import { I18n, getTranslation } from '@lyfta/components-i18n'
import {
  AnimatedInput,
  AuthLayout,
  ButtonNew,
  Locale,
  Separator,
} from '@lyfta/components-ui'
import paths from 'Constants/paths'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import validate from 'validate.js'

import {
  ButtonContainer,
  ErrorBlock,
  FormBlock,
  Link,
  Links,
  LocaleWrapper,
  Panel,
  Title,
} from './styles'

class LoginToLesson extends PureComponent {
  componentDidMount() {
    document.title = 'Lyfta student  - Log into a lesson'
  }

  handleSubmit = ({ passphrase }) => {
    const passphraseTrimmed = passphrase.trim()
    const { onSignIn } = this.props
    onSignIn(passphraseTrimmed)
    localStorage.setItem('passphrase', passphraseTrimmed)
  }

  validate = values => validate(values, {})

  renderForm = ({ handleSubmit, pristine, invalid }) => {
    const { error, isLoading } = this.props
    return (
      <FormBlock onSubmit={handleSubmit}>
        <AnimatedInput
          autoComplete="off"
          containerProps={{
            activeBottomBorderColor: 'colors.secondary',
          }}
          label={<I18n text="auth.passphrase" />}
          mb={1}
          id="input-lesson-passphrase"
          name="passphrase"
          type="text"
        />
        <ButtonContainer>
          <ErrorBlock>
            {error && <I18n text="auth.checkCorrectly" />}
          </ErrorBlock>

          <ButtonNew
            disabled={pristine || invalid || isLoading}
            id="btn-login-to-lesson"
            mt={2}
            width={1}
            type="task"
          >
            <I18n text="auth.login" />
          </ButtonNew>
        </ButtonContainer>
      </FormBlock>
    )
  }

  render() {
    const { onClearErrors } = this.props
    return (
      <AuthLayout>
        <LocaleWrapper>
          <Locale fill="colors.black_900" />
        </LocaleWrapper>
        <Panel>
          <I18n tag={Title} text="auth.loginToLesson" />
          <Form
            render={this.renderForm}
            validate={this.validate}
            onSubmit={this.handleSubmit}
          />
          <Separator label={getTranslation('auth.separatorLabel')} />
          <Links>
            <Links>
              <Link
                color="colors.teal_100"
                exact
                href={paths.loginWithPassword}
                to={() => paths.loginWithPassword}
                onClick={onClearErrors}
              >
                <I18n text="auth.loginWithPasswordLinkText" />
              </Link>
            </Links>
          </Links>
        </Panel>
      </AuthLayout>
    )
  }
}

LoginToLesson.defaultProps = {
  error: null,
}

LoginToLesson.propTypes = {
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onClearErrors: PropTypes.func.isRequired,
  onSignIn: PropTypes.func.isRequired,
}

export default connect(
  createStructuredSelector({
    error: getError,
    isLoading: getIsLoading,
  }),
  {
    onClearErrors: clearErrors,
    onSignIn: loginToLesson,
  },
)(LoginToLesson)
