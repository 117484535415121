import * as React from 'react'

function SvgFlag(props) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 117 114"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M116.948 13.5482C116.961 13.7214 116.961 13.8946 116.948 14.0679L107.121 64.3772L97.8947 111.401C97.7463 112.129 97.2925 112.788 96.613 113.264C95.934 113.74 95.0732 114 94.1843 114H93.5826C92.5965 113.862 91.7164 113.407 91.1341 112.733C90.5518 112.059 90.3147 111.222 90.474 110.403L99.3738 64.834C94.5855 61.1543 78.2894 50.7596 56.729 61.6325C49.8169 65.3091 41.8005 67.3039 33.5895 67.3911C14.3863 67.3911 1.19869 55.9156 0.998123 55.7493C0.218338 55.0494 -0.132646 54.0922 0.0454536 53.1506L9.62229 2.6339C9.71787 2.06567 10.0036 1.53079 10.4465 1.08987C10.8894 0.648962 11.4723 0.319808 12.1293 0.139204C12.8047 -0.0340373 13.525 -0.0457342 14.2076 0.10499C14.8901 0.255714 15.5081 0.563212 15.9902 0.991553C16.8175 1.71917 36.6226 18.7869 62.2451 5.8146C93.3576 -9.90187 115.821 11.2613 116.146 11.4276C116.604 11.8772 116.899 12.4246 116.998 13.0076C117.006 13.1886 116.99 13.3701 116.948 13.5481L116.948 13.5482Z"
        fill="#3333CC"
      />
    </svg>
  )
}

export default SvgFlag
