import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { ArrowHeadDown } from '../../Assets'
import { Icon } from '../Icon'
import Item from './Item'
import Picker from './Picker'
import { Menu, Opener, StyledDropdown } from './styles'

class Dropdown extends PureComponent {
  mounted = true

  ref = null

  constructor(props) {
    super(props)

    this.state = {
      selected: props.value,
      isOpened: props.defaultOpened,
    }
  }

  getChildContext() {
    const { isOpened } = this.state
    return { isOpened }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick, false)
    document.addEventListener('touchend', this.handleDocumentClick, false)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selected } = this.state
    const { value } = nextProps

    if (value && value !== selected) {
      this.setState({
        selected: value,
      })
    } else {
      this.setState({
        selected: null,
      })
    }
  }

  componentWillUnmount() {
    this.mounted = false
    document.removeEventListener('click', this.handleDocumentClick, false)
    document.removeEventListener('touchend', this.handleDocumentClick, false)
  }

  onChange = nextState => {
    const { selected } = this.state
    const { onChange } = this.props

    if (nextState.selected !== selected && onChange) {
      onChange(nextState.selected)
    }
  }

  addRef = ref => {
    this.ref = ref
  }

  handleSetValue = (value, label) => {
    const { preventCloseOnSelect } = this.props
    const nextState = {
      selected: {
        value,
        label,
      },
    }

    if (!preventCloseOnSelect) {
      nextState.isOpened = false
    }

    this.onChange(nextState)
    this.setState(nextState)
  }

  handleDocumentClick = event => {
    if (this.mounted) {
      if (!this.ref.contains(event.target)) {
        this.setState({ isOpened: false })
      }
    }
  }

  handleClick = event => {
    const { isOpened } = this.state

    if (event.type === 'click' && event.button !== 0) return
    event.stopPropagation()
    event.preventDefault()

    this.setState({
      isOpened: !isOpened,
    })
  }

  renderOption(option, index) {
    const { selected } = this.state

    const value = option.value || option.label || option
    const label = option.label || option.value || option

    const key = `${value}-${index}`

    const { id } = option

    return (
      <Item
        color="black"
        id={id}
        key={key}
        label={label}
        selected={option === selected}
        value={value}
        onSelect={this.handleSetValue}
      />
    )
  }

  render() {
    const {
      left,
      top: isTop,
      children,
      options,
      preventCloseOnSelect,
      menuWidth,
      defaultOpened,
      fill,
      choice,
      ...rest
    } = this.props
    const { isOpened } = this.state
    return (
      <StyledDropdown ref={this.addRef} {...rest}>
        <Opener id={`dropdown-open-${choice}`} onClick={this.handleClick}>
          {children}
          <Icon
            fill={fill}
            glyph={ArrowHeadDown}
            height={20}
            mr={1}
            width={20}
          />
        </Opener>
        {isOpened && (
          <Menu left={left} top={isTop} width={menuWidth}>
            {options.map((item, index) => {
              return this.renderOption(item, index)
            })}
          </Menu>
        )}
      </StyledDropdown>
    )
  }
}

Dropdown.displayName = 'Dropdown'

Dropdown.Picker = Picker
Dropdown.Menu = Menu

Dropdown.childContextTypes = {
  isOpened: PropTypes.bool,
}

Dropdown.defaultProps = {
  choice: null,
  defaultOpened: false,
  fill: 'white',
  id: '',
  left: false,
  menuWidth: '100%',
  options: [],
  preventCloseOnSelect: false,
  top: false,
  value: undefined,
  onChange: null,
}

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  choice: PropTypes.string,
  defaultOpened: PropTypes.bool,
  fill: PropTypes.string,
  id: PropTypes.string,
  left: PropTypes.bool,
  menuWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.array,
  preventCloseOnSelect: PropTypes.bool,
  top: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func,
}

export default Dropdown
