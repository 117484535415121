import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import { OVERLAY_CLOSE, OVERLAY_OPEN } from '../../Actions/ui'

const initialState = {
  open: false,
}

const show = state => state.merge({ open: true })
const hide = state => state.merge({ open: false })

const handlers = {
  [OVERLAY_OPEN]: show,
  [OVERLAY_CLOSE]: hide,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
