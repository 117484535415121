import { Flex, Markdown, styled } from '@lyfta/components-ui'

import { HelpMeFindIt as HelpIcon } from '../../../../Assets/QuestTracker'

export const InformationTextContainer = styled(Flex)`
  width: 100%;
  flex-grow: 1;
`

export const InformationTextMarkdown = styled(Markdown).attrs({
  type: 'inherit',
})`
  > p:not(:last-child) {
    padding-bottom: 16px;
  }
`

export const HelpMeFindIt = styled(HelpIcon)`
  margin-left: 8px;
`

export const SubmitContainer = styled(Flex).attrs({
  pt: 5,
})``

SubmitContainer.displayName = 'SubmitContainer'
