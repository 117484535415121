import { getStylingProps } from '@lyfta/components-theme'
import PropTypes from 'prop-types'
import React from 'react'

import FieldError from '../../FieldError'
import {
  Container,
  Description,
  Label,
  Wrapper as WrapperContainer,
} from '../styles'

export const Wrapper = ({
  active,
  autoFocus,
  focused,
  getLabelProps,
  getComboboxProps,
  isOpen,
  items,
  description,
  label,
  meta,
  children,
  selectedItem,
  onFocus,
  ...props
}) => {
  const stylingProps = getStylingProps(props)
  const hasError = meta.touched && (meta.error || meta.submitError)

  return (
    <WrapperContainer active={active} {...stylingProps}>
      <Container
        active={active}
        error={hasError ? 1 : 0}
        focused={focused}
        open={isOpen && items.length > 0}
        {...getComboboxProps()}
      >
        {label && (
          <Label active={active} onClick={onFocus} {...getLabelProps()}>
            {label}
          </Label>
        )}
        {children({
          label,
          meta,
          onFocus,
          ...stylingProps,
        })}
      </Container>
      {hasError ? (
        <FieldError meta={meta} />
      ) : (
        description !== null &&
        description.length > 0 && (
          <Description active={active}>{description}</Description>
        )
      )}
    </WrapperContainer>
  )
}

Wrapper.defaultProps = {
  autoFocus: false,
  description: null,
  label: null,
  selectedItem: null,
}

Wrapper.propTypes = {
  active: PropTypes.bool.isRequired,
  autoFocus: PropTypes.bool,
  children: PropTypes.func.isRequired,
  description: PropTypes.string,
  focused: PropTypes.bool.isRequired,
  getComboboxProps: PropTypes.func.isRequired,
  getLabelProps: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  meta: PropTypes.object.isRequired,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onFocus: PropTypes.func.isRequired,
}
