import { styled } from '@lyfta/components-theme'

import { Flex } from '../Flex'

const Grid = styled(Flex).attrs({
  mx: -3,
})`
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
`

export default Grid
