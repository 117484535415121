/* eslint-disable */
import { isTeacherApp } from '@lyfta/components-config'
import {
  I18n,
  getTranslatedField,
  getTranslation,
} from '@lyfta/components-i18n'
import {
  Arrow,
  ButtonNew,
  Constraints,
  Flex,
  Icon,
  Information,
  LyftaForm,
  Modal,
  Notification,
  NotificationPlus,
  Plus,
  ShareLink,
  TopBar,
  UnlockModal,
} from '@lyfta/components-ui'
import { get, isEmpty, map, noop } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { IntercomAPI } from 'react-intercom'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Card from '../../Components/Card'
import {
  addContentToCollection,
  deleteCollection,
  loadCollection,
  removeContentFromCollection,
  requestDuplicateCollection,
  requestSaveCollection,
  requestUpdateCollection,
} from '../../Store/Actions/collections'
import { clearSearchEditors } from '../../Store/Actions/search'
import {
  getCollectionName,
  getCurrentCollection,
} from '../../Store/Selectors/collections'
import Favorite from '../Favorite'
import myForm from './form'
import { generateInitialValues } from './form/initialValues'
import {
  AddToCollectionButton,
  CardsContainer,
  Container,
  LeftBlock,
  MenuItem,
  MenuTextBack,
  RightBlock,
  Title,
} from './styles'

const CollectionEditor = ({
  SearchComponent,
  collectionId,
  editorPath,
  viewOnly,
  paths,
}) => {
  useEffect(() => {
    if (collectionId === 'new') {
      new Promise(resolve => dispatch(requestSaveCollection({}, resolve))).then(
        value => {
          history.push(editorPath(value))
        },
      )
    }
  }, [collectionId])

  const dispatch = useDispatch()
  const collection = useSelector(getCurrentCollection)

  const favorites =
    get(collection, 'content.favorites') &&
    get(collection, 'content.favorites').length === 0
      ? false
      : get(collection, 'content.favorites')
  const [showLyftaContentModal, setShowLyftaContentModal] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showInformationModal, setShowInformationModal] = useState(false)

  const [showUnlockModal, setShowUnlockModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [itemsSelected, setItemsSelected] = useState([])
  const history = useHistory()

  const handleDuplicate = () => {
    new Promise(resolve =>
      dispatch(requestDuplicateCollection({ collection }, resolve)),
    ).then(value => {
      return history.push(paths.favorite.collectionEdit(value))
    })
  }

  const collectionContents = get(collection, 'contents')

  const handleEdit = () => {
    history.push(paths.favorite.collectionEdit(collection.id))
  }

  const handleOpenPreview = item => {
    if (isTeacherApp) {
      const isLicensed = get(item, 'isLicensed')
      if (isLicensed) {
        if (item.type === 'lesson_plan_template') {
          history.push(
            paths.searchNew.lessonPlanPreview(
              item.lessonPlanTemplate.id,
              item.type,
            ),
          )
        } else {
          history.push(paths.searchNew.resourcePreview(item.id, item.type))
        }
      } else {
        setShowUnlockModal(true)
      }
      return
    }
    // Admin app
    if (item.type === 'lesson_plan_template') {
      history.push(paths.resource.lessonPlanPreview(item.id))
      return
    }
    history.push(paths.resource.resourcePreview(item.id))
  }

  const handleRemoveContentFromCollection = content => {
    dispatch(
      removeContentFromCollection({
        collection,
        content,
        customMessage: { success: { message: 'toast.success.contentRemoved' } },
      }),
    )
  }

  const handleSelectLyftaContent = ({ submit = false, cardsSelected }) => {
    if (submit) {
      dispatch(
        addContentToCollection({
          itemsSelected,
          id: collectionId,
          customMessage: { success: { message: 'toast.success.contentAdded' } },
        }),
      )
      setShowLyftaContentModal(false)
    } else {
      setItemsSelected(cardsSelected)
    }
  }

  const handleShareModal = () => setShowShareModal(true)
  const handleInformationModal = (showInfoModal = true) =>
    setShowInformationModal(showInfoModal)

  const handleDelete = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  const doDeleteCollection = ok => {
    setShowDeleteModal(!showDeleteModal)
    dispatch(deleteCollection(collection.id))
    {
      isTeacherApp
        ? history.push(paths.favorite.root())
        : history.push(paths.collections())
    }
  }

  const handleIntercomModal = (type = 'unlock') => {
    const itemName = getTranslatedField(collection, 'name')

    const itemId = get(collection, 'id')
    const itemAuthor = getTranslatedField(collection, 'authorName')

    if (type === 'unlock') {
      const userMessageToIntercom =
        getTranslation('user.userMessageToIntercom') + itemName

      IntercomAPI('showNewMessage', userMessageToIntercom)
      setShowUnlockModal(false)
    }

    const userMessageToIntercomCollection = `${getTranslation(
      'user.userMessageToIntercomCollection',
    )}( id:${itemId}, Name: ${itemName}, Author: ${itemAuthor})`

    IntercomAPI('showNewMessage', userMessageToIntercomCollection)
    setShowInformationModal(false)
  }

  // Topbar
  const renderLeftContainer = () => {
    return <I18n i18nKey="collections.title" tag={Title} />
  }
  const renderRightContainer = () => {
    if (viewOnly) return <></>
    return (
      <ButtonNew
        leadingIcon={<Icon glyph={Plus} height={20} mr={2} width={20} />}
        id="add-content-modal-btn"
        onClick={() => {
          setShowLyftaContentModal(true)
          dispatch(clearSearchEditors())
        }}
      >
        <I18n i18nKey="actions.addMore" />
      </ButtonNew>
    )
  }
  // End Topbar

  const renderCustomeButtonInformationModal = () => (
    <Flex flexDirection="row" mt={3}>
      <Favorite
        button
        favoriteProperty={favorites}
        contentId={collection?.content?.id}
        data-intercom-target="favourite-btn"
        isCollection
        customButton={(handleOnClick, fav) => (
          <ButtonNew
            leadingIcon={
              <Icon
                glyph={fav ? Notification : NotificationPlus}
                height={20}
                mr={2}
                width={20}
              />
            }
            type="task"
            secondary
            mr={4}
            onClick={handleOnClick}
          >
            {fav ? (
              <I18n i18nKey="actions.unsubscribe" />
            ) : (
              <I18n i18nKey="actions.subscribe" />
            )}
          </ButtonNew>
        )}
      />
      <ButtonNew
        leadingIcon={<Icon glyph={Information} height={20} mr={2} width={20} />}
        type="task"
        onClick={() => {
          handleIntercomModal(true)
        }}
      >
        <I18n i18nKey="actions.shareFeedback" />
      </ButtonNew>
    </Flex>
  )

  return (
    <Container>
      <LeftBlock>
        <MenuItem mt={2} onClick={() => history.goBack()}>
          <Arrow
            color="black_900"
            direction="left"
            width={8}
            height={8}
            mr={2}
          />
          <MenuTextBack>
            <I18n i18nKey="actions.back" mr={4} />
          </MenuTextBack>
        </MenuItem>

        <LyftaForm
          constraints={{
            ...Constraints.nameCollectionConstraint,
            ...Constraints.descriptionConstraint,
          }}
          actionsForm={{
            handleShareModal,
            handleDuplicate,
            handleEdit,
            handleDelete,
            handleInformationModal,
            setShowLyftaContentModal,
            paths,
          }}
          createInitialValues={props => generateInitialValues(props, viewOnly)}
          excludeSave
          fields={formRendererProps => myForm(formRendererProps)}
          includeAutoSave
          inlineErrorMessages
          loadAction={loadCollection}
          recordNameSelector={getCollectionName}
          recordSelector={getCurrentCollection}
          recordId={collectionId}
          updateAction={requestUpdateCollection}
          wrapperProps={{ m: 0, zIndex: 1 }}
          viewOnly={viewOnly}
        />
      </LeftBlock>
      <RightBlock>
        <Flex width="100%" p={1} mb={3}>
          <TopBar
            width="100%"
            height="40px"
            mx={0}
            mt={1}
            leftContainer={renderLeftContainer()}
            rightContainer={renderRightContainer()}
          />
        </Flex>
        <CardsContainer
          id="card-container-collection"
          flexWrap="wrap"
          gap="35px"
          width="100%"
        >
          {map(collection.contents, (item, index) => {
            return (
              <Card
                controls
                favorited={!isEmpty(item.favorites)}
                id={item.id}
                index={index}
                item={item}
                key={item.id}
                deleteIconAction={
                  viewOnly
                    ? null
                    : () => handleRemoveContentFromCollection(item)
                }
                onView={() => handleOpenPreview(item)}
              />
            )
          })}
        </CardsContainer>
      </RightBlock>

      <Modal
        isOpen={showLyftaContentModal}
        minHeight="90vh"
        minWidth="90%"
        showFooter={false}
        onCallback={() => setShowLyftaContentModal(false)}
        modalStyle={{
          wrapperContainer: {
            width: '100%',
            background: 'neutral_200',
          },
          contentContainer: {
            specialOverflowSettings: true,
          },
          background: 'neutral_200',
        }}
      >
        <Flex flexDirection="column" overflow="hidden">
          <AddToCollectionButton>
            <ButtonNew
              leadingIcon={<Icon glyph={Plus} height={20} mr={2} width={20} />}
              default={{
                maxWidth: '200px',
                fontSize: '14px',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              mr={4}
              onClick={() => handleSelectLyftaContent({ submit: true })}
            >
              <I18n i18nKey="actions.addToCollection" />
            </ButtonNew>
          </AddToCollectionButton>
          <SearchComponent
            isCollectionEditor
            collectionContents={collectionContents}
            lyftaContent
            onSelect={handleSelectLyftaContent}
          />
        </Flex>
      </Modal>
      <Modal
        buttons={{
          deleteOrRemove: true,

          cancelText: <I18n text="actions.cancel" />,
          okText: <I18n text="actions.yesDelete" />,
        }}
        maxWidth="500px"
        hideClose
        isOpen={showDeleteModal}
        content={{
          title: 'core.modals.deleteTitle',
          description: 'core.deleteCollection',
          modelName: 'collections',
          sentenceCase: true,
        }}
        onCallback={doDeleteCollection}
        onDismissModal={() => handleDelete()}
      />
      <ShareLink
        item={collection}
        isOpen={showShareModal}
        onChooseNegative={() => {
          setShowShareModal(false)
          history.push(paths.favorite.collectionPreview(collectionId))
        }}
        modalStyle={{
          wrapperContainer: {
            maxWidth: '100%',
          },
        }}
      />
      <Modal
        isOpen={showInformationModal}
        onDismissModal={() => setShowInformationModal(false)}
        renderCustomButtons={renderCustomeButtonInformationModal}
        content={{
          title: 'core.modals.reportModal.title',
          description: [
            'core.modals.reportModal.text1',
            'core.modals.reportModal.text2',
            'core.modals.reportModal.text3',
          ],
        }}
        modalStyle={{
          wrapperContainer: {
            maxWidth: '500px',
          },
        }}
      />
      {showUnlockModal && (
        <UnlockModal
          isOpen={showUnlockModal}
          onCallback={handleIntercomModal}
        />
      )}
    </Container>
  )
}

CollectionEditor.defaultProps = {
  viewOnly: false,
  editorPath: noop,
}

CollectionEditor.propTypes = {
  collectionId: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool,
  paths: PropTypes.object.isRequired,
  editorPath: PropTypes.func,
  SearchComponent: PropTypes.element.isRequired,
}

export default CollectionEditor
