import { styled } from '@lyfta/components-theme'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'
import { Flex, Subtitle1, TextArea } from '@lyfta/components-ui'
import { themeGet as theme } from '@styled-system/theme-get'

export const DiscussionText = styled(TextArea).attrs(p => ({
  px: 5,
  py: 4,
  minHeight: 150,
  backgroundColor: theme('colors.neutral_100')(p),
  maxWidth: '100%',
  maxHeight: '50vh',
  width: '100%',
}))`
  border: 2px solid ${theme('colors.sa_primary_70')};
  border-radius: 8px;
  box-sizing: border-box;
  &:hover {
    box-shadow: ${shadows.lifted};
  }
  &:focus-visible {
    border: 2px solid transparent;
    outline: 2px solid ${theme('colors.sa_primary_90')};
    box-shadow: ${shadows.skim};
  }

  ${({ disabled }) => {
    if (disabled) {
      return `cursor: not-allowed`
    }
    return ``
  }}
`

export const DiscussionTextContainer = styled(Subtitle1)`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const DiscussionDesc = styled(Flex).attrs(p => ({
  py: theme('spacingSmaller')(p),
  width: '100%',
}))``
