import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'

import { Container, Content, Progress, UploadText } from './styles'

const UploadFile = ({ name, uploading }) => {
  const progress = uploading?.percent
  return (
    <Container>
      <Content>
        <UploadText>{name}</UploadText>
        <UploadText>
          {progress && (
            <I18n
              options={{ progress: progress?.toFixed(0) || 0 }}
              text="files.upload.uploadProgress"
            />
          )}
        </UploadText>
      </Content>
      <Progress value={progress} />
    </Container>
  )
}

UploadFile.defaultProps = {
  name: '',
}

UploadFile.propTypes = {
  name: PropTypes.string,
  uploading: PropTypes.object.isRequired,
}

export default UploadFile
