import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import React from 'react'

import { DropDown, Input, ListItem } from '../styles'

export const FormField = ({
  autoFocus,
  highlightedIndex,
  isOpen,
  items,
  getInputProps,
  getItemProps,
  getMenuProps,
  onFocus,
  touchItem,
  ...rest
}) => {
  const touchAction = item => {
    const isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)
    if (isIOS && touchItem.onSelectItem) {
      touchItem.selectItem(item.name)
      setTimeout(() => {
        touchItem.onSelectItem(item)
      }, 300)
      setTimeout(() => {
        rest.toggleMenu()
      }, 300)
    }
  }
  return (
    <>
      <Input
        autoComplete="off"
        autoFocus={autoFocus}
        {...getInputProps()}
        onFocus={onFocus}
      />
      <DropDown open={isOpen && items.length > 0} {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => {
            return (
              <ListItem
                active={highlightedIndex === index}
                key={`${nanoid()}`}
                onTouchStart={() => touchAction(item)}
                {...getItemProps({ item, index })}
                item={item}
              >
                {item.name}
              </ListItem>
            )
          })}
      </DropDown>
    </>
  )
}

FormField.defaultProps = {
  autoFocus: false,
  touchItem: {},
}

FormField.propTypes = {
  autoFocus: PropTypes.bool,
  getInputProps: PropTypes.func.isRequired,
  getItemProps: PropTypes.func.isRequired,
  getMenuProps: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  touchItem: PropTypes.object,
  onFieldBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
}
