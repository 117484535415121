import { find, isEmpty } from 'lodash'
import { useEffect, useState } from 'react'

export const useSelectedItem = (input, items, onSelectItem, resetField) => {
  const [value, setValue] = useState('')
  const [selectedItem, setSelectedItem] = useState({})
  const { addBtnClicked, setAddBtnClicked } = resetField

  useEffect(() => {
    if (addBtnClicked) {
      setValue('')
      setAddBtnClicked(false)
    }
  }, [addBtnClicked])

  useEffect(() => {
    if (typeof input.value === 'string' && input.value) {
      const item = find(items, itm => {
        return itm.id?.toLowerCase() === input.value.toLowerCase()
      })
      setSelectedItem(item)
      setValue(item?.name || '')
    }

    return () => setSelectedItem(null)
  }, [input.value])

  useEffect(() => {
    if (isEmpty(value)) setSelectedItem(null)
  }, [value])

  useEffect(() => {
    input.onChange(selectedItem?.id)
    if (onSelectItem) onSelectItem(selectedItem)
  }, [selectedItem])

  const onSelectedItemChange = ({ selectedItem: selected }) =>
    setSelectedItem(selected)

  return { selectedItem, onSelectedItemChange, value, setValue }
}
