import {
  Create,
  Discuss,
  Explore,
  Information,
  Present,
  Think,
} from '@lyfta/components-ui'

import { Lock, Tick as TickSelected } from '../../../../../../Assets/Functional'
import {
  Achievement,
  Other,
  Tick,
  TickWhite,
  Trophy,
} from '../../../../../Assets/QuestTracker'

export const taskIcons = {
  icon_achievement: Achievement,
  icon_create: Create,
  icon_discuss: Discuss,
  icon_explore: Explore,
  icon_info: Information,
  icon_locked: Lock,
  icon_other: Other,
  icon_present: Present,
  icon_think: Think,
  icon_tick: Tick,
  icon_tick_white: TickWhite,
  icon_tick_selected: TickSelected,
  icon_trophy: Trophy,
  icon_write: Create,
}
