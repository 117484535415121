import { space, styled } from '@lyfta/components-theme'
import { themeGet as theme } from '@styled-system/theme-get'
import { NavLink } from 'react-router-dom'

import { Link as BaseLink, Flex } from '../../BaseComponents'
import { Box, ButtonNew } from '../../Components'

export const Link = styled(NavLink)`
  font-size: 14px;
  padding-top: 2px;
  text-align: center;
  display: block;
  color: ${props =>
    props.fontColor
      ? theme(`colors.${props.fontColor}`)
      : theme('colors.primary')};
`

export const Links = styled(Flex).attrs(() => ({
  px: 3,
}))`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`
export const Panel = styled(Flex).attrs(() => ({
  mx: [2, 3],
  pt: [2, 4],
  maxWidth: [325, '100%'],
}))`
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: stretch;
  padding-top: 0;
  > form {
    display: flex;
    flex-direction: column;
  }
`

export const TextBlock = styled(Flex)`
  width: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${theme('colors.success')};
    margin: 16px 0;
  }

  p {
    margin: 8px 0;
  }
`
export const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
`
export const ErrorBlock = styled(Box).attrs(() => ({
  mb: 2,
}))`
  min-height: 20px;
  font-size: 14px;
  text-align: left;
  color: ${theme('colors.scarletRed')};

  ${space};
`
export const LinkMailto = styled(BaseLink)`
  color: ${theme('colors.error')};
`
export const LinkMylogin = styled(BaseLink)`
  text-decoration: none;
`
export const SignUpButton = styled(ButtonNew).attrs({
  rank: 'primary',
})`
  text-align: center;
  width: 100%;
  display: block;
  color: ${theme('colors.white')};
`

export const GoogleLoginContainer = styled(Flex)`
  justify-content: center;
`
