import { css, space, styled, themeGet } from '@lyfta/components-theme'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'
import { Box, Flex } from '@lyfta/components-ui'
import { Search } from '@lyfta/components-ui/src/Assets/Sidebar'
import MdCancel from 'react-icons/lib/md/cancel'

export const ContainerSearch = styled(Flex).attrs(props => ({
  m: 3,
  mb: 0,
  mx: props.mx && props.mx,
}))`
  flex-direction: column;
  margin: auto 0;
  height: unset;
  width: unset;
  border-radius: 8px;

  ${space};
`

export const SearchContainer = styled(Flex)`
  background-color: ${themeGet('colors.white')};
  justify-content: space-between;
  border-radius: 30px;
  box-shadow: ${shadows.skim};
`

export const InputContainer = styled(Flex).attrs(() => ({
  py: 1,
  pl: 44,
  pr: 3,
}))`
  flex: 1;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  min-height: 48px;
  border-radius: 30px;
  background-color: ${themeGet('colors.white')};
  /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14); */
  position: relative;
`

export const SearchIcon = styled(Box).attrs(() => ({ as: Search }))`
  fill: ${themeGet('colors.gray')};
  position: absolute;
  left: 12px;
`

export const TagsIcon = styled(Box).attrs(() => ({}))`
  color: ${themeGet('colors.primary')};
  font-weight: bold;
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
`

export const Tag = styled(Flex).attrs(() => ({
  pr: 36,
  pl: 3,
  m: 1,
  fontSize: 3,
}))`
  position: relative;
  align-items: center;
  flex-wrap: nowrap;
  height: 32px;
  border-radius: 8px;
  color: ${themeGet('colors.white')};
  background-color: ${props =>
    props.type === 'query'
      ? themeGet('colors.electricLila')
      : themeGet('colors.primary')};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const TagRemove = styled(Box).attrs(() => ({ as: MdCancel, size: 20 }))`
  position: absolute;
  top: 6px;
  right: 10px;
  color: ${themeGet('colors.white')};
`

export const InputText = styled(Flex)`
  flex-grow: 1;
  max-width: unset;
  min-width: 100px;
  margin-left: 15px;
  border: none;
  outline: none;
  color: ${themeGet('colors.primary')};
`

export const Label = styled(Flex).attrs(() => ({
  as: 'label',
  fontSize: 4,
}))`
  position: absolute;
  z-index: 1;
  top: 18px;
  left: 16px;
  color: ${themeGet('colors.offGrey')};
  font-weight: 600;
  transition: all ${themeGet('transitionTime')} ease;
  user-select: none;

  ${props =>
    props.active &&
    css`
      top: 6px;
      font-size: 13px;
      font-weight: normal;
      color: ${themeGet('colors.font.accent')};
    `};
`

export const Input = styled(Flex).attrs(() => ({
  as: 'input',
  m: 0,
  px: 3,
}))`
  font-size: 16px;
  flex: 1;
  border: 0;
  outline: none;
  color: ${themeGet('colors.black_600')};
  font-weight: 400;
  transition: all ${themeGet('transitionTime')} ease;

  ${props =>
    props.meta?.touched &&
    props.meta?.error &&
    css`
      border-bottom: solid red 2px;
    `};
`

export const Description = styled(Flex).attrs(() => ({
  px: 3,
  fontSize: 2,
}))`
  top: 6px;
  font-weight: normal;
  color: ${themeGet('colors.dustyGray')};
  transition: all ${themeGet('transitionTime')} ease;
  user-select: none;

  ${props =>
    props.active &&
    css`
      color: ${themeGet('colors.font.accent')};
    `};
`

export const Container = styled(Flex)`
  flex-direction: row;
  flex: 1;
  position: relative;
`

export const Wrapper = styled(Flex)`
  flex-direction: row;
  flex: 1;
`

export const DropDown = styled(Flex).attrs(() => ({ pt: 1 }))`
  flex-direction: column;
  flex: 1;
  position: absolute;
  background-color: white;
  top: calc(100% + 2px);
  width: 100%;
  display: ${props => (props.open ? 'flex' : 'none')};
  max-height: 240px;
  min-height: 80px;
  overflow-y: scroll;
  z-index: 100;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-color: ${themeGet('colors.neutral_400')};
  border-width: 1px;
  border-style: solid;
  border-top-width: 0px;
`

export const ListItem = styled(Flex).attrs(() => ({ mx: 1, px: 3, py: 2 }))`
  cursor: pointer;
  background-color: ${p =>
    themeGet(`colors.${p.active ? 'neutral_400' : 'white'}`)(p)};
  user-select: none;
  border-radius: 4px;
`
