import { truncate } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Translation } from 'react-i18next'

import { getTranslatedField } from '../services/translation'
import { DefaultTag } from './defaultTag'

export const TranslatedField = ({
  tag: Tag,
  object,
  field,
  translationKey,
  options,
  truncateString,
  ...props
}) => (
  <Translation>
    {t => {
      let fieldValue = getTranslatedField(object, field)

      if (translationKey != null) {
        fieldValue = t(translationKey, {
          name: fieldValue,
          ...options,
        })
      }

      if (truncateString)
        return (
          <Tag {...props}>
            {truncate(fieldValue, { length: truncateString })}
          </Tag>
        )

      return <Tag {...props}>{fieldValue}</Tag>
    }}
  </Translation>
)

TranslatedField.defaultProps = {
  translationKey: null,
  options: {},
  tag: DefaultTag,
  truncateString: '',
}
TranslatedField.propTypes = {
  field: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired,
  options: PropTypes.object,
  /** Optional: The tag to use to render the text string.
   * Defaults to `DefaultTag`, a `<span>` in HTML environments, and `<Text>` in React Native  */
  tag: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.string,
  ]),
  /** Optional: Automatically interpolate the translated field value into this key -
   * uses `{{name}}` as the interpolation key in the target string */
  translationKey: PropTypes.string,
  truncateString: PropTypes.string,
}
