import { USER_TYPES } from '@lyfta/components-ui'

export const getIsViewerWithLessons = ({ viewerType, lessonsRaw }) => {
  return (
    (viewerType === USER_TYPES.teachers ||
      viewerType === USER_TYPES.homeStudents) &&
    lessonsRaw &&
    lessonsRaw.length > 0
  )
}
