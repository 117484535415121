import { styled, themeGet as theme } from '@lyfta/components-theme'
import { Markdown } from '@lyfta/components-ui'
import React from 'react'

import { Container as BaseContainer } from './_styles'

export { Submit, BodyContainer, HeaderContainer } from './_styles'

export const Container = styled(BaseContainer)`
  pointer-events: auto;
  background: ${theme('colors.sa_gradient_h')};
  backdrop-filter: blur(20px);
  box-shadow: none;
  overflow-y: auto;
  z-index: 1;
`

export const KeyboardSpacer = () => <></>

// Note, i'm not happy that there is a non-standard font size here
// the theme needs updating so we use a standard font size here
export const BlockTitle = styled(Markdown).attrs(p => ({
  source: `## ${p.source}`,
  type: 'inherit',
}))``

Container.displayName = 'Container'
BlockTitle.displayName = 'BlockTitle'
