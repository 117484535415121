import { styled } from '@lyfta/components-theme'

import { Box, Flex } from '../../Components'

export const Container = styled(Box).attrs(() => ({
  my: 3,
}))`
  display: grid;
  grid-template-columns: repeat(6, minmax(150px, 1fr));
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: start;

  @media (max-width: 1600px) {
    grid-template-columns: repeat(5, minmax(150px, 1fr));
  }
  @media (max-width: 1400px) {
    grid-template-columns: repeat(4, minmax(150px, 1fr));
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(3, minmax(150px, 1fr));
  }
  @media (max-width: 650px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
  @media (max-width: 375px) {
    grid-template-columns: 100%;
  }
`

export const PermissionContainer = styled(Flex).attrs(() => ({
  p: 2,
  borderRadius: 2,
}))`
  cursor: pointer;
  user-select: none;
  flex-direction: row;
`

export const PermissionLabel = styled(Box).attrs(() => ({
  pl: 2,
}))``
