export const capitalizeFirstLetter = string => {
  if (!string) return string

  return string[0].toUpperCase() + string.slice(1)
}

export const makeSentenceCase = string => {
  if (typeof string !== 'string' || string.length < 1) return string
  let modifiedString = string[0].toUpperCase()
  for (let i = 1; i < string.length; i += 1) {
    if (string[i - 2] !== '.') {
      modifiedString += string[i].toLowerCase()
    } else {
      modifiedString += string[i]
    }
  }
  return modifiedString.replace('lyfta', 'Lyfta')
}

export default {}
