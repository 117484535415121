import { styled, themeGet } from '@lyfta/components-theme'

import { Box } from '../../../BaseComponents'

const Title4 = styled(Box)`
  color: ${props => (props.color ? props.color : themeGet('colors.black_900'))};
  font-size: 33px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: ${themeGet('lineHeights.9')};
`

export default Title4
