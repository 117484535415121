import mergeWith from 'lodash/mergeWith'
import reduce from 'lodash/reduce'
import set from 'lodash/set'

import { mergers } from '../../../../utils'

// import example from './Example'

const reducers = {
  /* Insert reducers here */
  // example,
}

export default (state, action) =>
  mergeWith(
    {},
    reduce(
      reducers,
      (acc, reducer, key) => set(acc, key, reducer(state[key], action)),
      state,
    ),
    mergers.latestArrayMerger,
  )
