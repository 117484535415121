/* eslint-disable */
import { isTeacherApp } from '@lyfta/components-config'
import { I18n, Translation } from '@lyfta/components-i18n'
import colors from '@lyfta/components-theme/src/themes/Main/colors'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Prompt, useHistory } from 'react-router-dom'

import Save from '../../Assets/Svg/Save'
import { ButtonNew } from '../../Components/ButtonNew'
import { Icon } from '../../Components/Icon'
import { ButtonPortal, PageTitlePortal } from '../Portals'
import { TopBar } from '../TopBar'
import { InlineErrorMessages } from './inlineErrorMessages'
import { ModalControls } from './modalControls'
import { Header } from './styles'

export const FormRenderer = ({
  excludeSave,
  buttons,
  fields,
  pageTitle,
  recordId,
  recordName,
  modelName,
  withoutHeader,
  modalProps,
  promptActivated,
  includeAutoSave,
  inlineErrorMessages,
  disableModalFormTitle,
  topBar,
}) => ({ handleSubmit, pristine, ...props }) => {
  const history = useHistory()
  // promptValue = true (prevent navigation)
  // promptValue = false (allow navigation)
  // pristine = true (no changes in the form)
  // confirmedNavegation = true (showModal)

  const [promptValue, setPromptValue] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLocation, setlastLocation] = useState('')
  const [confirmedNavegation, setconfirmedNavegation] = useState(false)

  // console.log('pristine', pristine, 'promptValue', promptValue, 'confirmedNavegation', confirmedNavegation, 'modalVisible', modalVisible );

  useEffect(() => {
    // eslint-disable-next-line
    !pristine ? setconfirmedNavegation(false) : setconfirmedNavegation(true)
    // eslint-disable-next-line
    !pristine ? setPromptValue(true) : setPromptValue(false)
  }, [pristine])

  useEffect(() => {
    if (lastLocation) history.push(lastLocation.pathname)
  }, [confirmedNavegation])

  const showModal = location => {
    setModalVisible(true)
    setlastLocation(location)
  }

  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNavegation) {
      showModal(nextLocation)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = () => {
    setconfirmedNavegation(true)
    setModalVisible(false)
    handleSubmit()
  }

  const handleCancelNavigationClick = () => {
    setModalVisible(false)
    setconfirmedNavegation(true)
  }

  // const defaultError = props.submitErrors?.statusCode === 500

  const renderInlineErrorMessages = formProps => {
    const { defaultError, submitErrors, submitting } = formProps

    if (
      inlineErrorMessages &&
      !submitting &&
      (!!submitErrors?.data?.body.errors?.length || defaultError)
    ) {
      return (
        <InlineErrorMessages
          // defaultError={defaultError}
          {...formProps}
        />
      )
    }

    return false
  }

  const { rightContainer, leftContainer, fixTopBar } = topBar || {}

  const renderLeftContainer = () => {
    return <>{leftContainer && leftContainer}</>
  }

  const renderRightContainer = () => {
    return (
      <>
        {rightContainer && rightContainer}

        {!excludeSave && (
          <ButtonNew
            disabled={pristine}
            id="btn-save"
            key="save-button"
            onClick={handleSubmit}
            type="cta"
            leadingIcon={<Icon glyph={Save} height={20} width={20} mr={2} />}
            disabledStyle={{ opacity: 0.5, marginLeft: '12px' }}
            default={{ marginLeft: '12px' }}
          >
            <I18n key="save-button-label" text="actions.save" />
          </ButtonNew>
        )}
        {includeAutoSave && (
          <ButtonNew
            disabled
            disabledStyle={{ opacity: 0.5, marginLeft: '12px' }}
            id="btn-auto-save"
            key="auto-save-button"
          >
            <I18n key="save-button-label" text="actions.autoSaveOn" />
          </ButtonNew>
        )}
      </>
    )
  }

  const renderTopBar = (id = 'top-bar', options = {}) => {
    if (isEmpty(topBar)) return <></>
    return (
      <ButtonPortal id={id}>
        <TopBar
          height="60px"
          leftContainer={renderLeftContainer()}
          rightContainer={renderRightContainer()}
          {...options}
        />
      </ButtonPortal>
    )
  }

  return (
    <Translation>
      {t => (
        <form>
          {/* OK - this looks ridiculuous, and it is. HubSpot has an issue where forms are being collected - this is a data leak issue */}
          {/* To get around this, add a hidden field called cc-num to the form., and hide it */}
          {/* I know, I know - it's dumb, but here we are */}
          {/* See https://community.hubspot.com/t5/APIs-Integrations/How-to-stop-collected-forms-from-collecting-a-form/m-p/299172#M28102 */}
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            className="hidden"
            htmlFor="credit-card"
            style={{ display: 'none' }}
          >
            HubspotCollectedFormsWorkaround
            https://community.hubspot.com/t5/APIs-Integrations/How-to-stop-collected-forms-from-collecting-a-form/m-p/299172#M28102
          </label>
          <input
            className="hidden"
            id="credit-card"
            name="cc-num"
            readOnly
            required=""
            style={{ display: 'none' }}
            value="HubspotCollectedFormsWorkaround"
          />
          {/* End HubSpot workaround absurdity */}

          {!withoutHeader && isEmpty(topBar) && (
            <>
              {!isTeacherApp && (
                <PageTitlePortal
                  title={
                    pageTitle || (recordName && recordId !== 'new')
                      ? t('mainHeader.edit', { name: recordName })
                      : t('mainHeader.create', { modelName })
                  }
                />
              )}
              {!isTeacherApp && (
                <ButtonPortal>
                  {buttons && buttons({ handleSubmit, ...props })}
                  {!excludeSave && (
                    <ButtonNew
                      backgroundColor="primary"
                      borderColor="primary"
                      default={{
                        color: 'white',
                        backgroundColor: 'primary',
                        borderColor: 'primary',
                        boxShadow: 'none',
                      }}
                      hover={{
                        color: 'white',
                        backgroundColor: 'primary',
                        borderColor: 'primary',
                        boxShadow: 'none',
                      }}
                      pressed={{
                        color: 'white',
                        backgroundColor: 'primary',
                        borderColor: 'primary',
                        boxShadow: 'none',
                      }}
                      disabledStyle={{
                        backgroundColor: 'none',
                        opacity: 0.7,
                      }}
                      disabled={pristine}
                      leadingIcon={
                        <Icon glyph={Save} height={20} width={20} mr={2} />
                      }
                      id="btn-save"
                      key="save-button"
                      onClick={handleSubmit}
                    >
                      <I18n key="save-button-label" text="actions.save" />
                    </ButtonNew>
                  )}
                  {includeAutoSave && (
                    <ButtonNew
                      default={{
                        color: 'white',
                        background: 'transparent',
                        letterSpacing: '1px',
                        fontWeight: '600',
                        padding: '0 0 0 0',
                        marginRight: '20px',
                      }}
                      hover={{
                        textDecorationLine: 'none',
                      }}
                      type="link"
                      disabledStyle={{
                        backgroundColor: 'none',
                        opacity: 0.7,
                        fill: colors.ufoGreen50,
                      }}
                      disabled
                      id="btn-auto-save"
                      key="auto-save-button"
                      leadingIcon={
                        <Icon glyph={Save} height={20} width={20} mr={2} />
                      }
                    >
                      <I18n key="save-button-label" text="actions.autoSaveOn" />
                    </ButtonNew>
                  )}
                </ButtonPortal>
              )}
            </>
          )}

          {modalProps && !disableModalFormTitle && (
            <Header>
              {pageTitle ||
                (recordName && recordId !== 'new'
                  ? t('mainHeader.edit', { name: recordName })
                  : t('mainHeader.create', { modelName }))}
            </Header>
          )}

          {!isEmpty(topBar) && fixTopBar && renderTopBar()}
          {fields &&
            fields({
              renderInlineErrorMessages,
              renderTopBar,
              handleSubmit,
              ...props,
            })}
          {modalProps && (
            <ModalControls
              handleSubmit={handleSubmit}
              invalid={props.invalid}
              modalProps={modalProps}
              pristine={pristine}
            />
          )}
          {promptActivated && (
            <Prompt message={handleBlockedNavigation} when={promptValue} />
          )}
          {/* {promptActivated && (
            <Delete
              i18nText="actions.unsaved"
              isOpen={modalVisible}
              onChooseNegative={handleCancelNavigationClick}
              onChoosePositive={handleConfirmNavigationClick}
            />
          )} */}
        </form>
      )}
    </Translation>
  )
}

FormRenderer.defaultProps = {
  inlineErrorMessages: false,
  promptActivated: false,
}

FormRenderer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  inlineErrorMessages: PropTypes.bool,
  promptActivated: PropTypes.bool,
}
