import * as React from 'react'

function SvgLyftaLogoSymbolNoBorder(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 172 174"
      fill="none"
      {...props}
    >
      <path
        d="M113.868 3.99677H101.659V70.8476H143.579V59.294H113.868V3.99677Z"
        fill="#231F20"
      />
      <path
        d="M40.6282 103.152H28.4214V170.003H70.3412V158.45H40.6282V103.152Z"
        fill="#231F20"
      />
      <path
        d="M131.37 103.152H143.577V170.003H101.659V158.45H131.37V103.152Z"
        fill="#231F20"
      />
      <path
        d="M40.6487 70.8493H28.4419V3.99854H70.36V15.5521H40.6487V70.8493Z"
        fill="#231F20"
      />
    </svg>
  )
}

export default SvgLyftaLogoSymbolNoBorder
