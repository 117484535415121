import { css, styled, themeGet } from '@lyfta/components-theme'

import { Box } from '../Box'
import { Flex } from '../Flex'

export const Container = styled(Flex)`
  width: 100%;
  height: 56px;
  justify-content: center;
  align-items: center;
  background-color: ${themeGet('colors.white')};
  color: ${themeGet('colors.slateGray')};
  border-bottom: 1px solid ${themeGet('colors.alto')};
`

export const Step = styled(Flex)`
  cursor: pointer;
  align-items: center;
  height: 20px;
`

export const StepNumber = styled(Box).attrs(() => ({
  fontSize: 3,
}))`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  background-color: ${themeGet('colors.dustyGray')};
  text-align: center;
  color: ${themeGet('colors.white')};

  ${props =>
    props.active === 'true' &&
    css`
      background-color: ${themeGet('colors.primary')};
    `};
`

export const StepLabel = styled(Box).attrs(() => ({
  fontSize: 3,
}))`
  margin-left: 5px;
  color: ${themeGet('colors.dustyGray')};

  ${props =>
    props.active === 'true' &&
    css`
      color: ${themeGet('colors.darkGray')};
    `};
`

export const StepDivider = styled(Box)`
  width: 122px;
  height: 10px;
  margin: 0 15px 5px;
  border-bottom: 2px solid ${themeGet('colors.alto')};
`
