import { styled, themeGet } from '@lyfta/components-theme'

import { Flex } from '../Flex'
import Text from '../Text'

export const Container = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  ${({ height }) => (height ? `max-height:${height}` : '')};
`
export const HorizontalLine = styled(Flex)`
  flex: 1 1 auto;

  border-top: 1px solid
    ${({ lineaColor }) =>
      lineaColor ? themeGet(`colors.${lineaColor}`) : '#e8ecee'};
  flex: 1 1 auto;
  margin-top: 8px;
  min-width: 1px;
  padding-top: 8px;
`
export const SeparatorText = styled(Text).attrs({ m: 3 })`
  color: ${({ color }) => themeGet(`colors.${color}`)};
  font-weight: 700;
`
