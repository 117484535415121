import * as React from 'react'

function SvgLocation(props) {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 12 16"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M1.73322e-07 5.45528C1.73322e-07 2.44216 2.68583 0 6 0C9.31417 0 12 2.44216 12 5.44349C12.0003 6.60128 11.5999 7.72656 10.8612 8.64356C10.7414 8.79234 10.7229 8.84379 10.6641 8.94922L7.17747 15.3435C7.05942 15.5428 6.88823 15.7086 6.68138 15.8239C6.47452 15.9392 6.2394 16 6 16C5.7606 16 5.52548 15.9392 5.31862 15.8239C5.11177 15.7086 4.94058 15.5428 4.82253 15.3435L1.35938 9.07031C1.30053 8.96488 1.26562 8.92188 1.13875 8.65535C0.400129 7.73835 -0.000304141 6.61307 1.73322e-07 5.45528ZM6 8C7.38071 8 8.5 6.88071 8.5 5.5C8.5 4.11929 7.38071 3 6 3C4.61929 3 3.5 4.11929 3.5 5.5C3.5 6.88071 4.61929 8 6 8Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLocation
