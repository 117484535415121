import { styled, themeGet } from '@lyfta/components-theme'

import { Flex } from '../../../Components'

export const Container = styled(Flex).attrs(() => {})`
  width: 100%;
  :before {
    content: '';
    background-color: ${props => themeGet(props.colorStatus)(props)};
    border-radius: 10px;
    display: block;
    height: 10px;
    margin: auto 8px auto 0;
    min-height: 10px;
    min-width: 10px;
    width: 10px;
  }
`
