import { sentryStudentWebUrl } from '@lyfta/components-config'
import { setStudentLessonID } from '@lyfta/components-data/src/Store/Actions/auth'
import {
  clearErrors,
  setJWT,
  setLessonPlanId,
  setResourceId,
  setUserType,
} from '@lyfta/components-data/src/Store/Actions/viewer'
import { updatePersist } from '@lyfta/components-data/src/Store/Reducers/persist'
import { getLocale } from '@lyfta/components-data/src/Store/Selectors/persist'
import {
  getIsAuthenticated,
  getViewer,
} from '@lyfta/components-data/src/Store/Selectors/viewer'
import { loadLesson } from '@lyfta/components-student/src/Store/Actions/lessons'
import { questTrackerData } from '@lyfta/components-student/src/Store/Actions/questTracker'
import { quest } from '@lyfta/components-student/src/Store/Actions/ui'
import * as Sentry from '@sentry/react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { createStructuredSelector } from 'reselect'

import App from './App'

Sentry.init({ dsn: sentryStudentWebUrl })

export default withRouter(
  connect(
    createStructuredSelector({
      isAuthenticated: getIsAuthenticated,
      viewer: getViewer,
      locale: getLocale,
    }),
    {
      onClearErrors: clearErrors,
      onSetJWT: setJWT,
      onSetResourceId: setResourceId,
      onSetLessonPlanId: setLessonPlanId,
      onSetUserType: setUserType,
      onUpdateCookie: updatePersist,
      onLoadLesson: loadLesson,
      onSelectLesson: quest.questSelect,
      onSetStudentLessonId: setStudentLessonID,
      onSetIsTeachingInFrontOfClass:
        questTrackerData.setIsTeachingInFrontOfClass,
    },
  )(App),
)
