import { getTranslation } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'

import {
  Container,
  Description,
  InfoContainer,
  InfoContainer2,
  Message,
  Title,
} from './styles'

const NotFound = ({
  message,
  title1,
  title2,
  description1,
  description2,
  button,
  removeBG,
}) => {
  return (
    <>
      <Container removeBG={removeBG}>
        <InfoContainer>
          <InfoContainer2>
            <Message>{message}</Message>
            <Title>{title1}</Title>
            <Title>{title2}</Title>
            <Description>{description1}</Description>
            <Description>{description2}</Description>
            {button && button()}
          </InfoContainer2>
        </InfoContainer>
      </Container>
    </>
  )
}

NotFound.defaultProps = {
  button: () => {},
  description1: getTranslation('auth.notFound.description1'),
  description2: '',
  message: getTranslation('auth.notFound.message403'),
  removeBG: false,
  title1: getTranslation('auth.notFound.title1'),
  title2: getTranslation('auth.notFound.title3'),
}

NotFound.propTypes = {
  button: PropTypes.element,
  description1: PropTypes.string,
  description2: PropTypes.string,
  message: PropTypes.string,
  removeBG: PropTypes.bool,
  title1: PropTypes.string,
  title2: PropTypes.string,
}
export default NotFound
