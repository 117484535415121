import {
  styled,
  themeGet,
  walsheimAndFallbackFontFamily,
} from '@lyfta/components-theme'
import { Box, ButtonNew, Flex, Text } from '@lyfta/components-ui'

export const Container = styled(Flex).attrs(() => ({
  mr: 3,
}))`
  flex-direction: column;
  background-color: ${themeGet('colors.white')};
  border-radius: 12px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-origin: border-box;
  background-clip: content-box, border-box;
  cursor: pointer;
  flex-shrink: 0;
  justify-content: space-between;
  min-width: 300px;
  max-height: ${p => (p.height ? p.height : '436px')};
  max-width: 300px;
  :hover {
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  }
`
export const HeaderContainer = styled(Flex).attrs({})`
  width: 100%;
  background-size: cover;
  background-position: center;
  max-height: 200px;
`

export const HeaderRelative = styled(Box).attrs({})`
  display: flex;
  /* height: fit-content; */
  width: 100%;
`

export const TemplateContainer = styled(Text).attrs(() => ({
  fontSize: 3,
}))`
  font-family: ${walsheimAndFallbackFontFamily};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.25em;
  text-transform: uppercase;
`
export const Title = styled(Text).attrs(() => ({ px: 2 }))`
  font-weight: 700;
  font-size: 19px;
  line-height: 28px;
`

export const LineClamp = styled.div`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

export const SubTitle = styled(Flex).attrs(() => ({
  px: 2,
}))`
  font-family: ${walsheimAndFallbackFontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  color: #000;
`

export const ThumbHolder = styled(Box).attrs({
  py: '28%',
})`
  background-color: ${themeGet('colors.primary_60_50')};
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 10px 10px 0px 0px;
  /* box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3); */
  ${props => props.height && `height: ${props.height}px;`}
  width: 100%;
`

export const BodyContainer = styled(Flex).attrs(() => ({
  px: 2,
  pt: 2,
}))`
  min-height: 40%;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  min-height: 140px;
  svg {
    cursor: pointer;
  }
`
export const FooterContainer = styled(Flex).attrs(() => ({}))`
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const Controls = styled(Flex).attrs(() => ({}))`
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  height: 100%;
  min-height: 60px;
  width: 90%;
  border-top: 1px solid ${themeGet('colors.black_300')};
  svg {
    cursor: pointer;
  }
`

export const StyledButton = styled(ButtonNew).attrs(() => ({ m: 2 }))`
  box-shadow: none;
  border-left: 1px solid ${themeGet('colors.black_300')};
  width: 50%;
  font-weight: 700;
  border-radius: 0;
  &:hover:enabled {
    background: none;
    font-weight: bold;
  }
`
