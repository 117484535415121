import { themeGet } from '@lyfta/components-theme'
import {
  Lessons as LessonPlanIcon,
  Icon as TypeIcon,
  reactEvent,
} from '@lyfta/components-ui'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import {
  Container,
  Description,
  IconContainer,
  Title,
  TopRow,
  TopRowLeftGroup,
} from './styles'

class LessonPlanBlock extends PureComponent {
  handleContentClick = () => {
    const { onClick, content } = this.props

    reactEvent({
      action: 'clicked_view_related_content',
      params: { content_type: content.type },
    })

    onClick(content)
  }

  render() {
    const { description, title, icon } = this.props
    return (
      <Container onClick={this.handleContentClick}>
        <TopRow>
          <TopRowLeftGroup>
            <IconContainer>
              <TypeIcon
                fill={themeGet('colors.black_700')}
                glyph={LessonPlanIcon}
                height={16}
                width={16}
              />
            </IconContainer>
            <Title>{title}</Title>
          </TopRowLeftGroup>
          <IconContainer>{icon}</IconContainer>
        </TopRow>
        <Description>{description}</Description>
      </Container>
    )
  }
}

LessonPlanBlock.propTypes = {
  content: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  title: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default LessonPlanBlock
