import { styled } from '@lyfta/components-theme'
import {
  Flex,
  ImageBackground,
  Markdown,
  ScrollableFlex,
  Text,
} from '@lyfta/components-ui'
import { themeGet as theme } from '@styled-system/theme-get'

import LessonSelectorBackground from './lesson-selector-bg.jpg'

export const Header = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
})`
  transition: all 200ms ease;
  @media (max-width: 985px) {
    margin-top: 100px;
  }
`

export const LessonIconContainer = styled(Flex).attrs({
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: 70,
  height: 70,
  minWidth: 70,
  borderRadius: 10,
})`
  padding: 15px;
  max-width: 70px;
  background: ${theme('colors.primary')};
`

export const ExploreIconContainer = styled(LessonIconContainer)`
  background: ${theme('colors.tealBlue')};
`

export const LessonsScreen = styled(ImageBackground).attrs({
  justifyContent: 'center',
  backgroundColor: 'rgb(254, 248, 233)',
  source: LessonSelectorBackground,
})`
  width: 100%;
  height: 100%;
  background-color: ${theme('colors.transparentBackground')};
  position: absolute;
`

export const LessonsBackground = styled(Flex).attrs({
  justifyContent: 'center',
})`
  width: 100%;
  height: 100%;
  background-color: ${theme('colors.transparentBackground')};
`

export const LessonsContainer = styled(ScrollableFlex).attrs({
  justifyContent: 'flex-start',
  flexDirection: 'column',
  mt: 'auto',
  mb: 'auto',
  pt: 4,
  pb: 4,
})``

export const LessonInfo = styled(Flex).attrs({
  alignItems: 'flex-start',
  flexDirection: 'column',
  flexBasis: 2,
})`
  font-weight: bold;
  margin-left: 10px;
`

export const LessonPassphrase = styled(Flex).attrs(p => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
  justifyContent: 'center',
  background: p.background || '#E0E0E0',
  width: 230,
  borderRadius: 10,
  flexDirection: 'column',
}))`
  text-align: center;
`

export const LessonButton = styled(Flex).attrs({
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignSelf: 'center',
  flexDirection: 'row',
  width: '70%',
  margin: 0,
  padding: 0,
  mb: 20,
  fontWeight: 'bold',
  background: 'white',
  textAlign: 'center',
  borderRadius: 10,
})`
  font-size: 22px;
  max-width: 750px;
  color: ${theme('colors.codGray')};
`

export const ExploreButton = styled(LessonButton).attrs({
  mb: 2,
})``

export const LessonText = styled(Markdown).attrs({
  textAlign: 'left',
  fontSize: 5,
  fontWeight: 'bold',
  justifyContent: 'flex-start',
  background: 'white',
  margin: 0,
  padding: 0,
})`
  font-weight: bold;
  width: 100%;
  color: ${theme('colors.codGray')};
  > p {
    margin: 0;
    padding: 0;
  }
`

export const LessonExploreText = styled(Text).attrs({
  textAlign: 'left',
  fontSize: 6,
  fontWeight: 'bold',
  background: 'white',
  margin: 0,
  pt: 2,
  pb: 2,
})`
  font-weight: bold;
  width: 100%;
  color: ${theme('colors.codGray')};
  > p {
    margin: 0;
    padding: 0;
  }
`

export const LessonInfoText = styled(Markdown).attrs({
  textAlign: 'left',
  background: 'white',
  margin: 0,
  padding: 0,
})`
  font-weight: normal;
  margin-top: 6px;
  color: ${theme('colors.codGray')};
  > p {
    margin: 0;
    padding: 0;
  }
`

export const MainTitle = styled(Text).attrs({
  lineHeight: '50px',
  fontSize: '44px',
  textAlign: 'center',
  color: 'white',
  mb: '10px',
  fontWeight: 'bold',
})``

export const Description = styled(Flex).attrs({
  mb: 15,
})`
  padding: 0 30px 20px 30px;
  text-align: center;
  max-width: 750px;
  color: ${theme('colors.font.outline')};
`

export const Logo = styled(Flex)`
  background: none;
  position: absolute;
  top: 45px;
  left: 45px;

  &.active {
    background: none;
  }

  img {
    height: 64px;
  }
`

Header.displayName = 'Header'
LessonIconContainer.displayName = 'LessonIconContainer'
ExploreIconContainer.displayName = 'ExploreIconContainer'
LessonsScreen.displayName = 'LessonsScreen'
LessonsBackground.displayName = 'LessonsBackground'
LessonsContainer.displayName = 'LessonsContainer'
LessonInfo.displayName = 'LessonInfo'
LessonPassphrase.displayName = 'LessonPassphrase'
LessonButton.displayName = 'LessonButton'
ExploreButton.displayName = 'ExploreButton'
LessonText.displayName = 'LessonText'
LessonExploreText.displayName = 'LessonExploreText'
LessonInfoText.displayName = 'LessonInfoText'
MainTitle.displayName = 'MainTitle'
Description.displayName = 'Description'
Logo.displayName = 'Logo'
