import { getTranslation } from '@lyfta/components-i18n'
import { validSubtitleLanguages } from '@lyfta/components-i18n/src/constants/validLanguages'
import map from 'lodash/map'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { getCachedHead } from '../../Helpers/getCachedHead'

// eslint-disable-next-line max-lines-per-function
export function useTextTracks(url, locale, useLegacy = false) {
  const [debugChapters, setDebugChapters] = useState(false)

  useEffect(() => {
    setDebugChapters(localStorage.debugChapters)

    window.toggleDebugChapters = () => {
      setDebugChapters(value => {
        localStorage.debugChapters = !value
        return !value
      })
    }

    return () => {
      delete window.toggleDebugChapters
    }
  }, [])

  const initialTracks = useMemo(() => {
    // noinspection UnnecessaryLocalVariableJS
    /**
     * @type {import('video.js').videojs.TextTrackOptions[]}
     */
    const result = map(validSubtitleLanguages, lang => {
      // noinspection UnnecessaryLocalVariableJS
      /**
       * @type {import('video.js').videojs.TextTrackOptions}
       */
      const trackOptions = {
        kind: 'subtitles',
        src: url.replace('.mp4', `-${lang}.vtt`),
        srclang: lang,
        default: locale === lang,
        label: getTranslation(`locale.${lang}`),
      }

      return trackOptions
    })

    if (debugChapters) {
      result.push({
        // example chapter file
        kind: 'chapters',
        src:
          'https://www.radiantmediaplayer.com/media/vtt/chapters/chapters.vtt',
        srclang: 'en',
      })
    }

    if (useLegacy) {
      // replace srclang with srcLang?
      // result.forEach(track => {
      //   // eslint-disable-next-line no-param-reassign
      //   track.srcLang = track.srclang
      //   // eslint-disable-next-line no-param-reassign
      //   delete track.srclang
      // })
    }

    return result
  }, [url, locale, debugChapters, useLegacy])

  const [tracks, setTracks] = useState([])
  const [ready, setReady] = useState(false)

  const validateTracks = useCallback(
    /**
     * @param {import('video.js').videojs.TextTrackOptions[]} tracksToValidate
     * @return {Promise<void>}
     */
    async tracksToValidate => {
      /**
       * @type {boolean[]}
       */
      const trackValidityList = await Promise.all(
        tracksToValidate.map(async track => {
          const trackSrc = track.src

          let remainingAttempts = track.srclang === 'en' ? 2 : 1

          while (remainingAttempts > 0) {
            try {
              // eslint-disable-next-line no-await-in-loop
              const result = await getCachedHead(trackSrc, true)
              if (result.status !== 403 && result.status !== 404) {
                return true
              }

              remainingAttempts -= 1
            } catch (error) {
              remainingAttempts -= 1
            }

            // wait for 1 second before trying again
            // eslint-disable-next-line no-await-in-loop
            await new Promise(resolve => setTimeout(resolve, 500))
          }

          return false
        }),
      )

      const filteredTracks = tracksToValidate.filter(
        (_, index) => trackValidityList[index],
      )

      setTracks(filteredTracks)
      setReady(true)
    },
    [],
  )

  useEffect(() => {
    setTracks([])
    setReady(false)

    validateTracks(initialTracks).catch()
  }, [initialTracks])

  return [tracks, ready]
}
