import { LessonSelection } from '@lyfta/components-student'
import { noop } from 'lodash'
import React from 'react'

import { Container } from './styles'

export default () => {
  return (
    <Container>
      <LessonSelection isFocused redirectHome={noop} />
    </Container>
  )
}
