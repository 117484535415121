import { map } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete'

import AnimatedTypeAhead from '../AnimatedTypeAhead'

const AnimatedPlaceInput = ({
  onFocus,
  requestOptions,
  requestOptions: {
    componentRestrictions: { country },
  },
  selectCallback,
  id,
  ...props
}) => {
  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions,
    cache: false,
    debounce: 300,
  })

  useEffect(() => {
    clearSuggestions()
  }, [country])

  const [items, setItems] = useState([])

  useEffect(() => {
    setItems(
      map(data, d => ({
        id: d.place_id,
        name: d.description,
      })),
    )
  }, [data])

  const inputChange = ({ inputValue: v }) => {
    if (v !== value) setValue(v)
  }

  const onSelectItem = item => {
    if (item?.name) {
      setValue(item.name)
      // Get latitude and longitude via utility functions
      getGeocode({ address: item.name }).then(results =>
        selectCallback(results[0]),
      )
    }
  }

  const clearValue = () => setValue('')

  const focusField = () => {
    clearSuggestions()
    setValue('')
    if (onFocus) onFocus()
  }

  return (
    <AnimatedTypeAhead
      {...props}
      clearValue={clearValue}
      id={id}
      items={items}
      overrideValue={value}
      onFocus={focusField}
      onInputValueChange={inputChange}
      onSelectItem={onSelectItem}
    />
  )
}

AnimatedPlaceInput.defaultProps = {
  id: null,
  requestOptions: {},
  onFocus: null,
}

AnimatedPlaceInput.propTypes = {
  id: PropTypes.string,
  requestOptions: PropTypes.object,
  selectCallback: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
}

export default AnimatedPlaceInput
