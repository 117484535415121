import * as React from 'react'

function SvgExplore(props) {
  return (
    <svg
      id="svg-explore"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.82861 9.70703L1.84137 11.5706C1.42255 12.2119 1.151 12.9438 1.04736 13.7107C1.02612 13.8677 1.01208 14.0249 1.00513 14.1826C1.0011 14.2739 0.999451 14.3652 1.00018 14.4565L1 14.498C1 15.8738 1.55566 17.1199 2.45483 18.0239C2.96387 18.5457 3.57739 18.946 4.25244 19.1965C4.73926 19.3772 5.24921 19.4766 5.76324 19.4924C5.84174 19.4961 5.92065 19.498 6 19.498C8.76141 19.498 11 17.2595 11 14.498C11 13.8364 10.8715 13.2046 10.638 12.6267C10.7667 12.4136 10.9409 12.2329 11.1479 12.0989C11.4032 11.9336 11.6982 11.8462 11.9992 11.8462C12.3002 11.8462 12.5952 11.9336 12.8505 12.0989C13.058 12.2332 13.2325 12.4146 13.3614 12.6282C13.1283 13.2058 13 13.8369 13 14.498C13 17.2595 15.2386 19.498 18 19.498H18.0105C18.4054 19.5046 18.8007 19.4619 19.1878 19.3701C19.8704 19.208 20.5117 18.8967 21.0685 18.457C21.1928 18.3589 21.3123 18.2549 21.4265 18.1455C21.4529 18.1201 21.4791 18.0945 21.505 18.0686C21.5814 17.9919 21.6553 17.9131 21.7267 17.8315C22.5185 16.947 23 15.7788 23 14.498L22.9998 14.4585C23.0046 13.8347 22.8983 13.2144 22.6854 12.6289L21.2338 9.70703L20.5325 8.30273C20.1509 7.55664 19.5506 6.98413 18.8351 6.68384C18.5901 6.03247 18.1797 5.47754 17.6557 5.08936C17.1316 4.70117 16.5174 4.49683 15.8905 4.5022C15.2637 4.50757 14.6523 4.72266 14.1335 5.11987C13.6149 5.51709 13.212 6.0791 12.976 6.73462C12.6729 6.5625 12.3384 6.4729 11.9991 6.4729C11.6599 6.4729 11.3253 6.5625 11.0222 6.73462C10.785 6.07471 10.3787 5.50977 9.85547 5.11206C9.33215 4.71436 8.71564 4.50195 8.08478 4.50195C7.45398 4.50195 6.83746 4.71436 6.31415 5.11206C5.79089 5.50977 5.38464 6.07471 5.1474 6.73462C4.47198 7.04688 3.90851 7.60522 3.54663 8.32056L2.82861 9.70703ZM6 17.498C7.65686 17.498 9 16.1548 9 14.498C9 12.8413 7.65686 11.498 6 11.498C4.34314 11.498 3 12.8413 3 14.498C3 16.1548 4.34314 17.498 6 17.498ZM18 17.498C19.6569 17.498 21 16.1548 21 14.498C21 12.8413 19.6569 11.498 18 11.498C16.3431 11.498 15 12.8413 15 14.498C15 16.1548 16.3431 17.498 18 17.498Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgExplore
