import { createReducer } from '../../helpers'
import { createLoadHandler } from '../../utils'
import { LOAD_EXPERIENCES } from '../Actions/experiences'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  experiences: [],
  size: 100,
  number: 1,
  total: 0,
  currentLesson: null,
}

const humanizeError = (type, error) => {
  switch (type) {
    case LOAD_EXPERIENCES.FAILURE: {
      if (error.status === 401) {
        return 'User is not authorized'
      }
      return null
    }
    default:
      return null
  }
}

const handleFailure = (state, { type, error }) =>
  state.merge({
    isLoading: false,
    isLoaded: false,
    error: humanizeError(type, error),
  })

const handleRequest = state =>
  state.merge({
    error: null,
    isLoading: true,
    isLoaded: false,
    currentLesson: {},
  })
const handleClear = state => state.merge(initialState)

const handlers = {
  [LOAD_EXPERIENCES.REQUEST]: handleRequest,
  [LOAD_EXPERIENCES.SUCCESS]: createLoadHandler('experiences'),
  [LOAD_EXPERIENCES.FAILURE]: handleFailure,
  [LOAD_EXPERIENCES.CLEAR]: handleClear,
}

export default createReducer(initialState, handlers)
