import { styled, typography } from '@lyfta/components-theme'

import { createBox } from './Box'

export function createText(StyledElement) {
  return createBox(
    styled(StyledElement`
      ${typography}
    `),
  )
}
