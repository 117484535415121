import PropTypes from 'prop-types'
import React, { Suspense } from 'react'
import { I18nextProvider } from 'react-i18next'

import { i18next } from '../services/configure'

export const I18nProvider = ({ children }) => {
  return (
    <I18nextProvider i18n={i18next}>
      <Suspense fallback="loading">{children}</Suspense>
    </I18nextProvider>
  )
}

I18nProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
