import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'

import { Tutorial } from '../../../Assets'
import { Icon } from '../../../Components'
import { openTutorial } from '../../../Services'
import { Label, MenuButton, MenuItemBox, MenuItemWrapper } from './styles'

export const TutorialLink = ({ t, isOpen }) => {
  return (
    <MenuItemWrapper isOpen={isOpen}>
      <MenuItemBox isOpen={isOpen}>
        <MenuButton
          data-intercom-target="Tutorials"
          id="menu-tutorials"
          isOpen={isOpen}
          tabIndex={0}
          title={t('actions.tutorials')}
          onClick={openTutorial()}
        >
          <Icon glyph={Tutorial} />
          <Label>
            <I18n text="actions.tutorials" />
          </Label>
        </MenuButton>
      </MenuItemBox>
    </MenuItemWrapper>
  )
}
TutorialLink.defaultProps = {
  isOpen: undefined,
}
TutorialLink.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
}
