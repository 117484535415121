import auth from './auth'
import classes from './classes'
import data from './data'
import events from './events'
import experiences from './experiences'
import files from './files'
import lyftaCampaigns from './lyftaCampaigns'
import lyftaMetadata from './lyftaMetadata'
import meetingAttendees from './meetingAttendees'
import meetingInvites from './meetingInvites'
import meetingOccurrences from './meetingOccurrences'
import meetings from './meetings'
import organizationLookups from './organizationLookups'
import organizations from './organizations'
import persist from './persist'
import settings from './settings'
import subjects from './subjects'
import users from './users'
import viewer from './viewer'

export default {
  auth,
  classes,
  data,
  events,
  experiences,
  files,
  lyftaCampaigns,
  lyftaMetadata,
  meetingAttendees,
  meetingInvites,
  meetingOccurrences,
  meetings,
  organizationLookups,
  organizations,
  settings,
  persist,
  subjects,
  users,
  viewer,
}
