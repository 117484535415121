import { getAllEntities, getEntityStateField } from '@lyfta/components-data'
import { find } from 'lodash'
import { createSelector } from 'reselect'

const getContentIdProp = (state, props) => props.contentId

export const getFavorites = getAllEntities('favorites')

export const getFavorite = createSelector(
  getFavorites,
  getContentIdProp,
  (favorites, contentId) => find(favorites, f => f.content.id === contentId),
)

export const getIsLoaded = getEntityStateField('favorites', 'isLoaded')
export const getIsLoading = getEntityStateField('favorites', 'isLoading')
export const getPaged = getEntityStateField('favorites', 'paged')
