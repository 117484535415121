import PropTypes from 'prop-types'
import React from 'react'

import { Flex } from '../Flex'
import Text from '../Text'
import { Container, SearchIcon } from './styles'

const Empty = ({ children, glyph: EmptyGlyph, glyphStyle, ...rest }) => (
  <Container {...rest}>
    <EmptyGlyph
      height={glyphStyle.height || 120}
      width={glyphStyle.width || 120}
    />
    <Flex flexDirection="column">
      <Text fontSize={18} m={5}>
        {children}
      </Text>
    </Flex>
  </Container>
)

Empty.defaultProps = {
  children: '',
  glyph: SearchIcon,
  glyphStyle: {},
}

Empty.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.element,
  ]),
  glyph: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.func,
  ]),
  glyphStyle: PropTypes.object,
}

export default Empty
