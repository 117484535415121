import * as React from 'react'

function SvgSearch(props) {
  return (
    <svg height="24" viewBox="0 0 24 24" width="24" {...props}>
      <path
        clipRule="evenodd"
        d="M14.9471 17.7453C13.6533 18.541 12.1303 19 10.5 19C5.80558 19 2 15.1944 2 10.5C2 5.80558 5.80558 2 10.5 2C15.1944 2 19 5.80558 19 10.5C19 12.1064 18.5544 13.6088 17.7799 14.8902L21.4136 18.5525C22.1959 19.341 22.1959 20.6193 21.4136 21.4077C20.6313 22.1961 19.3631 22.1961 18.5808 21.4077L14.9471 17.7453ZM17 10.5C17 14.0899 14.0899 17 10.5 17C6.91015 17 4 14.0899 4 10.5C4 6.91015 6.91015 4 10.5 4C14.0899 4 17 6.91015 17 10.5Z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgSearch
