import { createAsyncAction } from '../../helpers'
import apiCall from '../../services/Api'

export const AUTHENTICATE = createAsyncAction('auth/AUTHENTICATE')
export const PASSPHRASE = createAsyncAction('auth/PASSPHRASE')
export const PASSPHRASE_TOKEN = createAsyncAction('auth/PASSPHRASE_TOKEN')

export const CONFIRM_EMAIL = createAsyncAction('auth/CONFIRM_EMAIL')
export const RESET_PASSWORD = createAsyncAction('auth/RESET_PASSWORD')
export const CHANGE_PASSWORD = createAsyncAction('auth/CHANGE_PASSWORD')
export const CLEAR_ERRORS = createAsyncAction('auth/CLEAR_ERRORS')
export const SET_STUDENT_LESSON_ID = 'auth/SET_STUDENT_LESSON_ID'
export const SET_EMAIL = 'auth/SET_EMAIL'

export const signIn = (email, password) =>
  apiCall({
    method: 'POST',
    endpoint: '/user/tokens',
    customMessage: { success: { blockDefaultMessage: true } },
    query: {
      data: {
        type: 'tokens',
        attributes: {
          email,
          password,
        },
      },
    },
    types: AUTHENTICATE,
    needsNormalization: false,
  })

export const oAuthSignIn = (token, provider) =>
  apiCall({
    method: 'POST',
    endpoint: '/user/oauth/google',
    customMessage: { success: { blockDefaultMessage: true } },
    query: {
      data: {
        type: 'tokens',
        attributes: {
          token,
          provider,
        },
      },
    },
    types: AUTHENTICATE,
    needsNormalization: false,
  })

export const oAuthSignInMicrosoft = (token, provider) =>
  apiCall({
    method: 'POST',
    endpoint: '/user/oauth/microsoft',
    customSuccessMessage: true,
    query: {
      data: {
        type: 'tokens',
        attributes: {
          token,
          provider,
        },
      },
    },
    types: AUTHENTICATE,
    needsNormalization: false,
  })

export const oAuthSignInMylogin = token => {
  return apiCall({
    method: 'POST',
    endpoint: `/user/oauth/mylogin`,
    customMessage: { success: { blockDefaultMessage: true } },
    query: {
      data: {
        type: 'tokens',
        attributes: {
          token,
          provider: 'mylogin',
        },
      },
    },
    types: AUTHENTICATE,
    needsNormalization: false,
  })
}

export const loginToLesson = passphrase =>
  apiCall({
    method: 'GET',
    endpoint: `/user/passphrase_logins?filter[passphrase]=${passphrase}`,
    types: PASSPHRASE,
    customMessage: { success: { blockDefaultMessage: true } },
    needsNormalization: false,
  })

export const logIn = (passphrase, userToken) =>
  apiCall({
    method: 'POST',
    customMessage: { success: { blockDefaultMessage: true } },
    endpoint: '/user/passphrase_logins',
    query: {
      data: {
        type: 'tokens',
        attributes: {
          passphrase,
          userToken,
        },
      },
    },
    types: AUTHENTICATE,
    needsNormalization: false,
  })

export const loginWithPassphraseToken = (passphrase, userToken) =>
  apiCall({
    method: 'POST',
    customMessage: { success: { blockDefaultMessage: true } },
    endpoint: '/user/passphrase_logins',
    query: {
      data: {
        type: 'tokens',
        attributes: {
          passphrase,
          userToken,
        },
      },
    },
    types: AUTHENTICATE,
    needsNormalization: false,
  })

export const authenticateByToken = token =>
  apiCall({
    method: 'POST',
    customMessage: { success: { blockDefaultMessage: true } },
    endpoint: '/user/tokens',
    query: {
      data: {
        type: 'tokens',
        attributes: {
          token,
        },
      },
    },
    types: AUTHENTICATE,
    needsNormalization: false,
  })

export const confirmEmail = token =>
  apiCall({
    method: 'POST',
    customMessage: { success: { blockDefaultMessage: true } },
    endpoint: '/confirm_email',
    query: {
      data: {
        attributes: {
          token,
        },
      },
    },
    types: CONFIRM_EMAIL,
  })

export const resetPassword = email =>
  apiCall({
    method: 'POST',
    customMessage: { success: { blockDefaultMessage: true } },
    endpoint: '/user/reset_password',
    query: {
      data: {
        attributes: {
          email,
        },
      },
    },
    types: RESET_PASSWORD,
  })

export const changePassword = ({ token, password, passwordConfirmation }) =>
  apiCall({
    method: 'PATCH',
    customMessage: { success: { blockDefaultMessage: true } },
    endpoint: '/user/reset_password',
    query: {
      data: {
        attributes: {
          token,
          password,
          password_confirmation: passwordConfirmation,
        },
      },
    },
    types: CHANGE_PASSWORD,
  })

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  }
}

export const setStudentLessonID = studentLessonId => {
  return {
    type: SET_STUDENT_LESSON_ID,
    studentLessonId,
  }
}

export const setEmail = email => {
  return {
    type: SET_EMAIL,
    email,
  }
}
