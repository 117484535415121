import EventEmitter from 'eventemitter3'

import { debugLog } from '../utils/debugLog'

export class FakeUnityContent {
  /**
   * @param {string} url
   */
  constructor(url) {
    this.url = url
    this.emitter = new EventEmitter()
  }

  /**
   * @param {string} eventName
   * @param {function} handler
   */
  on = (eventName, handler) => {
    debugLog('FAKEUNITY on', eventName, handler)
    const handlerWithCallback = (callback, data) => {
      const result = handler(data)

      callback(result)
    }
    this.emitter.on(eventName, handlerWithCallback)

    return () => {
      this.emitter.off(eventName, handlerWithCallback)
    }
  }

  /**
   *
   * @param {string} obj
   * @param {string} method
   * @param {string} parameter
   */
  send = (obj, method, parameter) => {
    this.emit('WEB_APP_SEND', {
      obj,
      method,
      parameter,
    }).catch(e => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
  }

  /**
   *
   * @param {string} eventName
   * @param {any=} data
   */
  emit = async (eventName, data) => {
    return new Promise(resolve => {
      this.emitter.emit(
        eventName,
        (...responseData) => {
          resolve(responseData)
        },
        data,
      )
    })
  }
}
