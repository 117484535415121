import {
  getTranslatedField,
  globalFormatDate,
  useTranslation,
} from '@lyfta/components-i18n'
import { getCachedHead } from '@lyfta/components-ui/src/Helpers/getCachedHead'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import {
  HomeLessonButton,
  HomeLessonButtonContainer,
  HomeLessonButtonSpacer,
  HomeLessonInfo,
  HomeLessonInfoText,
  HomeLessonLastPlayedText,
  HomeLessonText,
  HomeStudentButton,
  ImageContainer,
  PosterImage,
} from '../home-styles'

const defaultPosterImage =
  'https://images.squarespace-cdn.com/content/v1/5a2557e77131a5e5ccb8ce65/1647019547045-VR6V5KBGIRXAW4155XDN/Cover+image+50%25+opacity+%28Presentation+%28169%29%29+%281%29.jpg'

const HomeStudentLessonRow = ({
  handleLessonButton,
  lesson: { id, lessonPlan, frontOfClass },
  lesson,
  isStarted,
  isCompleted,
  lastStartedTime,
}) => {
  const name = getTranslatedField(lessonPlan, 'name')

  const [posters, setPosters] = useState([])

  useEffect(() => {
    const seen = {}
    const postersToCheck = []
    if (lessonPlan?.content?.posterUrl) {
      postersToCheck.push(lessonPlan?.content?.posterUrl)
    }
    const cancelInfo = {
      canceled: false,
    }

    ;[...(lessonPlan.activities || [])]
      .sort((a, b) => a.order - b.order)
      .forEach(activity => {
        activity.tasks.forEach(task => {
          if (task.content && task.content.posterUrl) {
            const url = task.content.posterUrl
            if (!seen[url]) {
              seen[url] = true

              postersToCheck.push(url)
            }
          }
        })
      })
    ;(async () => {
      const resolvedPosters = []

      const checkedPosters = await Promise.all(
        postersToCheck.map(async url => {
          return getCachedHead(url)
            .then(result => {
              if (result.ok) {
                return url
              }

              return ''
            })
            .catch(() => '')
        }),
      )

      checkedPosters
        .filter(url => !!url)
        .forEach(url => resolvedPosters.push(url))

      if (cancelInfo.canceled) {
        return
      }

      if (resolvedPosters) {
        setPosters(resolvedPosters)
      } else {
        setPosters([defaultPosterImage])
      }
    })().catch()

    return () => {
      cancelInfo.canceled = true
    }
  }, [lesson])

  const { t } = useTranslation()

  let buttonMessage

  if (isCompleted) {
    buttonMessage = t('studentAtHome.restart')
  } else if (isStarted) {
    buttonMessage = t('studentAtHome.resume')
  } else {
    buttonMessage = t('studentAtHome.start')
  }

  let description = getTranslatedField(lessonPlan, 'description')
  const contentDescription = getTranslatedField(
    lessonPlan,
    'content.shortDescription',
  )
  if (contentDescription) {
    description = contentDescription
  }

  return (
    <HomeLessonButton
      key={`lesson-${id}`}
      onClick={handleLessonButton(id, frontOfClass)}
    >
      <ImageContainer>
        <PosterImage alt="" src={posters[0]} />
      </ImageContainer>
      <HomeLessonInfo>
        <HomeLessonText>{name}</HomeLessonText>
        <HomeLessonInfoText>{description}</HomeLessonInfoText>

        <HomeLessonButtonContainer>
          {lastStartedTime && (
            <HomeLessonLastPlayedText>
              {t('studentAtHome.lastPlayed', {
                time: globalFormatDate(lastStartedTime.toISOString()),
              })}
            </HomeLessonLastPlayedText>
          )}
          <HomeLessonButtonSpacer />
          <HomeStudentButton
            data-inverted={isStarted}
            onClick={handleLessonButton(id, frontOfClass)}
          >
            {buttonMessage}
          </HomeStudentButton>
        </HomeLessonButtonContainer>
      </HomeLessonInfo>
    </HomeLessonButton>
  )
}

HomeStudentLessonRow.propTypes = {
  handleLessonButton: PropTypes.func.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isStarted: PropTypes.bool.isRequired,
  lastStartedTime: PropTypes.instanceOf(Date),
  lesson: PropTypes.object.isRequired,
}

HomeStudentLessonRow.defaultProps = {
  lastStartedTime: null,
}

export default HomeStudentLessonRow
