import { getItemsPerPage } from '@lyfta/components-data/src/Store/Selectors/settings'
import React, { useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'

import { useSelections } from './useSelections'

export const useTableData = (
  columns,
  selector,
  loadingSelector,
  dataParser,
  defaultPageSize,
  disableRow,
  permissions,
  columnActions,
  tableFilters,
) => {
  const itemsPerPageSelector = useSelector(getItemsPerPage)
  const itemsPerPage = defaultPageSize || itemsPerPageSelector

  const [pageSize, setPageSize] = useState(itemsPerPage)
  const loading = loadingSelector ? useSelector(loadingSelector) : false
  const data = useSelector(selector)

  const tableData = useMemo(
    () => (loading ? Array(30).fill({}) : dataParser(data)),
    [loading, data],
  )

  const selections = useSelections(tableData, disableRow)
  const columnSet = columns(selections, {
    disableRow,
    permissions,
    columnActions,
    tableData,
    tableFilters,
  })

  const tableColumns = useMemo(
    () =>
      loading
        ? columnSet.map(column => ({
            ...column,
            Cell: <Skeleton />,
          }))
        : columnSet,
    [loading, columnSet],
  )

  return {
    tableData,
    tableColumns,
    loading,
    selections,
    pageSize,
    setPageSize,
  }
}
