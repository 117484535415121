import {
  TranslatedField,
  getCurrentLanguage,
  getTranslatedField,
} from '@lyfta/components-i18n'
import { capitalizeFirstLetter } from '@lyfta/components-ui/src/Services/Utils'
import { filter, map } from 'lodash'
import natsort from 'natsort'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { getTagsForGroup } from '../../../Store/Selectors/tags'
import { Container, Header, StyledCollapse, StyledMdClose, Tag } from './styles'

const naturalSort = (categoryTags, locale) => {
  const sorter = natsort()
  return categoryTags.sort((a, b) =>
    sorter(a[`name${locale}`], b[`name${locale}`]),
  )
}

const Category = ({ name, tags, setTags, tagGroupId }) => {
  const [isOpen, setIsOpen] = useState(false)
  const locale = capitalizeFirstLetter(getCurrentLanguage())
  const categoryTags = useSelector(state =>
    getTagsForGroup(state, { tagGroupId }),
  )

  const toggleTag = tag => () => {
    const tagCount = filter(tags, t => t.type === 'tag' && t.id === tag.id)
      .length
    if (tagCount > 0) {
      setTags(tagList => [...filter(tagList, t => t.id !== tag.id)])
    } else {
      setTags(tagList => [
        ...tagList,
        { type: 'tag', value: getTranslatedField(tag, 'name'), id: tag.id },
      ])
    }
  }

  return (
    <Container className={`${isOpen && 'opened'}`}>
      <Header
        data-intercom-target={`tag-group-${tagGroupId}`}
        id={`dropdown-search-options-${tagGroupId}`}
        onClick={() => setIsOpen(o => !o)}
      >
        <StyledMdClose height="18px" opened={isOpen ? 1 : 0} />
        {name}
      </Header>

      <StyledCollapse isOpen={isOpen}>
        {map(naturalSort(categoryTags, locale), tag => {
          const isSelected = !!filter(
            tags,
            t => t.type === 'tag' && t.id === tag.id,
          ).length

          return (
            <Tag
              active={isSelected}
              data-intercom-target={`tag-${tag.id}`}
              key={tag.id}
              onClick={toggleTag(tag)}
            >
              <TranslatedField
                field="name"
                id={`subcat-${tag.nameEn}`}
                object={tag}
              />
            </Tag>
          )
        })}
      </StyledCollapse>
    </Container>
  )
}

Category.defaultProps = {
  name: 'Category',
}

Category.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  setTags: PropTypes.func.isRequired,
  tagGroupId: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
}

export default Category
