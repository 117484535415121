import { I18n } from '@lyfta/components-i18n'
import { filter } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'

import { AngleRight } from '../../../Assets'
import { Container, Label } from './styles'

export const Breadcrumbs = ({ routes }) => {
  // filter out top level fallback routes
  const breadcrumbs = useBreadcrumbs(
    filter(routes, r => r.path),
    { disableDefaults: true },
  )

  const renderBreadcrumbs = useCallback(
    () =>
      breadcrumbs.map(({ match, breadcrumb }, key) => {
        let label = breadcrumb
        const matchCloned = { ...match }
        // Old search no accessible through breadcrumbs
        if (matchCloned.url === '/search') {
          matchCloned.url = '/search-content'
        }

        if (typeof breadcrumb.props?.children === 'string') {
          label = (
            <I18n i18nKey={breadcrumb.props.children} options={{ count: 10 }} />
          )
        }
        if (typeof breadcrumb.props?.children === 'function') {
          const Component = breadcrumb.props?.children
          label = <Component matchCloned={matchCloned} />
        }

        return key + 1 === breadcrumbs.length ? (
          <Label key={matchCloned.url}>{label}</Label>
        ) : (
          <React.Fragment key={`${matchCloned.url}-container`}>
            <Label key={matchCloned.url}>
              <NavLink to={matchCloned.url}>
                <>{label}</>
              </NavLink>
            </Label>
            <AngleRight key={`${matchCloned.url}-spacer`} />
          </React.Fragment>
        )
      }),

    [breadcrumbs, routes],
  )

  return <Container>{renderBreadcrumbs()}</Container>
}

Breadcrumbs.propTypes = {
  routes: PropTypes.array.isRequired,
}
