import { styled } from '@lyfta/components-theme'

import { Box, Flex, Text } from '../../BaseComponents'

export const ModalContainer = styled(Box)`
  margin-top: 40px;
`

export const ModalText = styled(Text).attrs(() => ({
  fontSize: 4,
}))``

export const ModalButtonWrapper = styled(Flex)`
  justify-content: space-between;
  margin-top: 70px;
`
