import { QuestTracker } from '@lyfta/components-student'
import React from 'react'
import { useParams } from 'react-router-dom'

import { Container } from './styles'

const Dashboard = () => {
  const params = useParams()

  return (
    <Container>
      <QuestTracker {...params} />
    </Container>
  )
}

export default Dashboard
