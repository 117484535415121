import { Modal } from '@lyfta/components-ui'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

class ModalContent extends PureComponent {
  handleCallback = cb => {
    const { onChooseNegative, onChoosePositive } = this.props

    if (cb) {
      onChoosePositive()
    } else {
      onChooseNegative()
    }
  }

  render() {
    const { isLoading, isOpen, props } = this.props

    return (
      <Modal
        buttons={{
          cancelText: get(props, 'negativeButton.text'),
          okText: get(props, 'positiveButton.text'),
        }}
        hideClose={get(props, 'hideClose')}
        isLoading={isLoading}
        isOpen={isOpen}
        maxWidth={get(props, 'maxWidth')}
        content={{
          title: get(props, 'title'),
          description: get(props, 'text'),
        }}
        onCallback={this.handleCallback}
        {...props}
      />
    )
  }
}

ModalContent.defaultProps = {
  props: {},
}

ModalContent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  props: PropTypes.object,
  onChooseNegative: PropTypes.func.isRequired,
  onChoosePositive: PropTypes.func.isRequired,
}

export default ModalContent
