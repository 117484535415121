import * as React from 'react'

function SvgPresent(props) {
  return (
    <svg
      id="svg-present"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M14.99 11C14.99 12.66 13.66 14 12 14C10.34 14 9 12.66 9 11V5C9 3.34 10.34 2 12 2C13.66 2 15 3.34 15 5L14.99 11Z"
        fill="black"
      />
      <path
        d="M12 16.1C14.76 16.1 17 14 17 11H19C19 14.42 16.28 17.52 13 18V20H17C18.1046 20 19 20.8954 19 22H5C5 20.8954 5.89543 20 7 20H11V18C7.72 17.51 5 14.41 5 11H7C7 14 9.24 16.1 12 16.1Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgPresent
