import {
  color,
  css,
  display,
  space,
  styled,
  system,
  textAlign,
  typography,
} from '@lyfta/components-theme'

import { Paragraph } from '../../BaseComponents'

export const textTransform = system({
  prop: 'textTransform',
  cssProperty: 'textTransform',
})

const Text = styled(Paragraph)`
${props => {
  return (
    props.ellipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `
  )
}};

  ${space}
  ${color}
  ${typography}
  ${textAlign}
  ${display}
  ${system({
    textTransform: true,
  })}
`

Text.propTypes = {
  ...space.propTypes,
  ...color.propTypes,
  ...typography.propTypes,
  ...textAlign.propTypes,
}

Text.defaultProps = {
  lineHeight: 2,
  fontSize: 2,
  m: 0,
}

export default Text
