import 'react-day-picker/lib/style.css'

import {
  alignSelf,
  css,
  styled,
  themeGet as theme,
} from '@lyfta/components-theme'

import { Box } from '../../Box'
import { Flex } from '../../Flex'

export const Label = styled(Box).attrs(() => ({
  as: 'label',
  fontSize: 4,
}))`
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 18px;
  left: 16px;
  font-weight: 600;
  transition: all ${theme('transitionTime')} ease;
  user-select: none;
  cursor: pointer;

  ${props =>
    props.active &&
    css`
      top: 6px;
      font-size: 13px;
      font-weight: normal;
      color: ${theme('colors.font.accent')};
    `};
`

export const Wrapper = styled(Box)`
  width: 100%;
  cursor: pointer;
`

export const Container = styled(Flex).attrs(() => ({
  mb: 3,
}))`
  flex-direction: column;
  position: relative;
  background: ${theme('colors.alto')};
  height: 56px;
  cursor: pointer;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;

  ${props =>
    props.active &&
    css`
      border-bottom: 2px solid ${theme('colors.primary')};
    `};

  ${props =>
    props.error &&
    css`
      border-bottom: 2px solid ${theme('colors.error')};
    `};

  .DayPickerInput {
    cursor: default;
    > input {
      background-color: ${theme('colors.alto')};
      width: 100%;
      cursor: default;
      margin-top: 30px;
      margin-bottom: 5px;
      font-weight: 600;
      ${props =>
        props.active &&
        css`
          display: auto;
          bottom: 0;
          padding-left: 15px;
          position: absolute;
        `};
      border-radius: 4px;
      border: none;
      box-shadow: none !important;
      appearance: none;
      outline: none;

      ::placeholder {
        color: ${theme('colors.font.placeholder')};
      }
    }

    ${props =>
      props.ranged &&
      css`
        .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
          background-color: #f0f8ff !important;
          color: #4a90e2;
        }
        .DayPicker-Day {
          border-radius: 0 !important;
        }
        .DayPicker-Day--start {
          border-top-left-radius: 50% !important;
          border-bottom-left-radius: 50% !important;
        }
        .DayPicker-Day--end {
          border-top-right-radius: 50% !important;
          border-bottom-right-radius: 50% !important;
        }
      `};
  }

  .DayPickerInput-OverlayWrapper {
    z-index: 2;
  }

  input {
    cursor: pointer;
  }

  label {
    margin-bottom: 8px;
  }

  ${alignSelf};
`
