import * as React from 'react'

function SvgHeart(props) {
  return (
    <svg
      fill="none"
      height="18"
      viewBox="0 0 20 18"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.0594 0.496399C14.5114 -0.743299 11.179 0.422654 10.0001 3.09661C8.82102 0.422654 5.48857 -0.744868 2.94062 0.496399C0.227051 1.82013 -1.07055 5.20136 1.07305 9.18945C2.59496 12.0281 5.29278 14.1665 10.0002 18C14.7072 14.1669 17.4052 12.0266 18.9269 9.18945C21.0705 5.20136 19.7729 1.82013 17.0594 0.496399Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgHeart
