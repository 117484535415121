import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'
import { COMPLETE_REFETCH, COMPLETE_REHYDRATION } from 'Store/Actions/app'

const initialState = {
  rehydrationComplete: false,
  isRefetched: false,
}

const completeRehydration = state =>
  state.merge({
    rehydrationComplete: true,
  })

const completeRefetch = state =>
  state.merge({
    isRefetched: true,
  })

const handlers = {
  [COMPLETE_REHYDRATION]: completeRehydration,
  [COMPLETE_REFETCH]: completeRefetch,
}

const resetFn = (state, initial) =>
  state.merge({ ...initial, rehydrationCompleted: state.rehydrationCompleted })

export default createReducer(initialState, handlers, {
  resetFn,
  resetOn: [LOG_OUT],
})
