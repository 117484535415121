/* eslint-disable */
import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'

import { CloseIcon } from '../../Assets/Icons'
import { NOTIFICATION_TYPE_ERROR } from '../../Constants'
import {
  Button,
  Container,
  Icon,
  IconAndTextContainer,
  IconError,
  Success,
  TextContainer,
  Warning,
} from './styles'

const ToastNewComponent = props => {
  const { message, type } = props
  return (
    <Container type={type}>
      <IconAndTextContainer>
        <Icon>
          {type === NOTIFICATION_TYPE_ERROR && (
            <IconError id="errorToast" width="24px" height="24px" />
          )}
          {type === 'success' && (
            <Success id="successToast" width="24px" height="24px" />
          )}
          {type === 'warning' && (
            <Warning id="warningToast" width="24px" height="24px" />
          )}
        </Icon>
        <TextContainer>{message}</TextContainer>
      </IconAndTextContainer>
      <Button type={type} ariaLabel={<I18n i18nKey="actions.close" />}>
        <CloseIcon width="20px" height="20px" />
      </Button>
    </Container>
  )
}
ToastNewComponent.defaultProps = {
  type: 'success',
}

ToastNewComponent.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
}

export default ToastNewComponent
