import * as React from 'react'

function SvgBalloon(props) {
  return (
    <svg
      width="150"
      height="173"
      viewBox="0 0 150 173"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.4704 76.0425C23.597 76.7519 23.0605 77.9897 22.6987 79.3111C22.3369 80.6324 22.1497 82.0372 22.0499 83.0803C22.0125 83.4698 22 83.8592 22 84.2487C22 85.3197 22.1248 86.3906 22.3244 87.4477C23.098 91.6621 24.9445 95.626 27.5397 98.8112C28.7624 100.313 30.1598 101.356 31.5697 102.344C32.9671 103.332 34.377 104.291 35.6246 105.599C38.0451 108.144 39.3926 111.162 40.241 114.417C41.077 117.685 41.4014 121.204 41.7507 124.709C42.0626 127.811 42.1749 131.914 43.747 134.682C45.7183 138.117 49.7733 140.551 52.7552 142.54C56.2986 144.905 59.9668 147.088 62.9862 150.148C66.0056 153.208 68.3886 157.311 68.5758 161.776C68.5758 161.915 68.6756 162.013 68.8004 161.999C68.9127 161.999 69 161.887 69 161.762C68.8004 157.103 66.3424 152.916 63.2732 149.801C60.1914 146.699 56.5107 144.501 52.9798 142.151C49.9854 140.162 45.9679 137.714 44.0964 134.432C42.6116 131.844 42.4744 127.783 42.1749 124.654C41.8131 121.149 41.5012 117.616 40.6403 114.292C39.7919 110.981 38.407 107.866 35.9116 105.265C34.6265 103.902 33.1917 102.942 31.7943 101.955C30.3969 100.967 29.0369 99.9517 27.8641 98.5052C25.3063 95.3757 23.4972 91.4812 22.7361 87.3503C22.549 86.3211 22.4242 85.2779 22.4242 84.2487C22.4242 83.8731 22.4367 83.4976 22.4741 83.1221C22.5739 82.0928 22.7486 80.7159 23.098 79.4362C23.4473 78.1705 23.9713 77.0161 24.7075 76.418C24.8073 76.3485 24.8322 76.1955 24.7574 76.0981C24.695 75.9869 24.5577 75.973 24.4704 76.0425Z"
        fill="#4E4970"
      />
      <path
        d="M23.0843 78.8527C22.6542 80.9882 23.9551 81.6243 25.0567 80.3369C25.4135 79.9129 25.7282 79.4131 26.1479 79.1404C27.0187 78.5952 28.0154 79.2616 28.9701 79.2919C30.5963 79.3222 30.0088 77.0503 29.1695 76.0962C28.6134 75.4601 28.0259 74.8997 27.3964 74.4302C25.9276 73.2942 24.6371 74.5665 23.8607 76.3991C23.6404 76.9292 23.2732 77.9136 23.0843 78.8527Z"
        fill="#4E4970"
      />
      <mask
        id="mask0_6633_2053"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="19"
        width="43"
        height="58"
      >
        <path d="M0 19.191H42.6094V76.3185H0V19.191Z" fill="white" />
      </mask>
      <g mask="url(#mask0_6633_2053)">
        <path
          d="M4.99109 58.4941C1.7096 52.2319 0.000234723 44.7562 1.79694 37.7408C3.25677 31.9946 7.14963 26.8481 12.3027 24.1424C17.4682 21.4506 23.8315 21.325 28.8473 24.0308C35.4228 27.5734 38.9913 35.3559 39.6151 43.0407C40.2015 50.2096 38.4922 57.3923 35.56 63.7522C33.2892 68.6616 28.9222 77.6715 22.9457 75.4818C15.7838 72.8736 8.70927 65.5932 4.99109 58.4941Z"
          fill="#4E4970"
        />
      </g>
      <path
        d="M37.752 33C39.2326 40.1447 38.0728 48.3532 35.8642 54.8366C34.1244 59.9544 31.4099 64.6983 27.3258 67.6885C23.2417 70.6786 18.4543 71.0811 14 69.3704C16.9119 72.1593 20.1447 74.4163 23.4021 75.6526C29.3123 77.9096 33.6309 68.6229 35.8765 63.5627C38.7761 57.0074 40.4665 49.6039 39.8866 42.2148C39.6398 39.0522 38.9242 35.8895 37.752 33Z"
        fill="#474367"
      />
      <path
        d="M23.3353 75.9545C23.6312 75.9848 23.9271 76 24.2229 76C25.7924 76 27.3748 75.5908 28.8027 74.7875C28.97 74.6814 29.0471 74.4389 28.97 74.2267C28.8799 74.0297 28.6612 73.9387 28.494 74.0448C27.1689 74.7875 25.6895 75.1815 24.2229 75.1815C23.9399 75.1815 23.6698 75.1664 23.3867 75.1361C23.1938 75.1209 23.0265 75.2876 23.0008 75.515C22.9879 75.7423 23.1295 75.9394 23.3353 75.9545Z"
        fill="#4E4970"
      />
      <path
        d="M15.8462 26.054C13.2934 25.5794 10.6212 28.3433 9.14325 29.9625C7.1279 32.196 5.57532 34.8342 4.5154 37.5562C3.30618 40.7388 2.78368 44.0192 3.08226 47.3973C3.21661 48.9886 3.54504 53.93 6.03811 53.9998C6.65018 54.0138 6.9189 53.0786 6.96368 52.6737C7.18761 50.552 6.41132 48.6256 6.32176 46.5318C6.21725 44.1588 6.9189 41.8835 8.03854 39.7617C8.94919 38.0587 10.1435 36.4674 11.5617 35.0994C13.159 33.55 15.1893 32.2378 16.4284 30.4232C17.0554 29.5019 17.5182 26.3611 15.8462 26.054Z"
        fill="#5B517B"
      />
      <path
        d="M118.777 80.0013C117.535 80.1263 116.453 80.8345 115.617 81.8343C114.781 82.8202 114.178 84.1117 113.895 85.4308C113.711 86.2362 113.637 87 113.637 87.736C113.649 90.0133 114.301 91.9852 114.559 94.2625C114.621 94.8874 114.658 95.5539 114.658 96.2066C114.658 98.8311 114.166 101.622 113.588 104.066C112.1 110.454 108.964 116.397 104.549 120.799C99.4205 125.909 92.8411 128.923 87.3562 133.727C82.7568 137.754 79.0797 142.934 75.6363 148.169C73.3735 151.599 71.0615 155.418 71 159.764C71 159.889 71.0861 160 71.2091 160C71.3197 160 71.4181 159.903 71.4181 159.778C71.4673 155.612 73.7055 151.863 75.9684 148.447C79.4118 143.212 83.0643 138.074 87.6145 134.088C93.0256 129.339 99.6173 126.326 104.832 121.146C109.308 116.675 112.481 110.662 113.994 104.177C114.572 101.719 115.076 98.9005 115.076 96.2066C115.076 95.5261 115.039 94.8596 114.965 94.207C114.707 91.8602 114.055 89.9022 114.055 87.736C114.055 87.0416 114.129 86.3196 114.289 85.5419C114.572 84.3061 115.137 83.0841 115.924 82.1537C116.711 81.2233 117.695 80.5845 118.814 80.4735C118.925 80.4596 119.011 80.3485 118.999 80.2096C118.987 80.0846 118.888 79.9874 118.777 80.0013Z"
        fill="#4E4970"
      />
      <path
        d="M115.6 81.8627C114.281 83.1252 115.319 84.3627 116.955 84.1002C117.479 84.0252 118.004 83.8502 118.529 83.9127C119.64 84.0502 120.36 85.1252 121.3 85.7252C122.912 86.7252 123.266 84.7627 122.826 83.5752C122.533 82.8002 122.167 82.0377 121.74 81.3252C120.739 79.6377 118.932 79.7627 117.394 80.5877C116.942 80.8377 116.161 81.3127 115.6 81.8627Z"
        fill="#4E4970"
      />
      <mask
        id="mask1_6633_2053"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="106"
        y="26"
        width="44"
        height="58"
      >
        <path d="M106.492 26.3324H150V83.4598H106.492V26.3324Z" fill="white" />
      </mask>
      <g mask="url(#mask1_6633_2053)">
        <path
          d="M109.599 54.0035C109.661 46.7649 111.632 39.3729 116.399 34.3659C120.304 30.2515 126.019 28.0199 131.696 28.6057C137.385 29.2055 142.913 32.6922 145.982 37.8666C150 44.631 149.488 53.334 146.494 60.3076C143.699 66.8069 138.92 72.0231 133.467 75.8307C129.263 78.7596 121.365 84.0456 117.222 78.7875C112.269 72.4834 109.524 62.2183 109.599 54.0035Z"
          fill="#4E4970"
        />
      </g>
      <mask
        id="mask2_6633_2053"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="109"
        y="51"
        width="41"
        height="33"
      >
        <path d="M109.686 51.3253H150V83.4595H109.686V51.3253Z" fill="white" />
      </mask>
      <g mask="url(#mask2_6633_2053)">
        <path
          d="M148.752 51.3949C146.843 58.215 142.177 64.3936 137.36 68.5359C133.555 71.8135 129.076 74.2263 124.197 74.3798C119.306 74.5332 114.964 72.1343 111.857 68.1593C113.142 72.1482 114.939 75.8861 117.222 78.7871C121.365 84.0451 129.263 78.7592 133.468 75.8303C138.92 72.0227 143.699 66.8065 146.494 60.3071C147.679 57.5316 148.478 54.4912 148.752 51.3949Z"
          fill="#474367"
        />
      </g>
      <path
        d="M118.131 80.2502C119.451 80.6751 121.04 80.9381 122.662 80.9988C122.841 81.0089 122.986 80.9533 122.998 80.8774C123.02 80.7965 122.886 80.7307 122.718 80.7257C121.208 80.665 119.72 80.4222 118.489 80.0226C118.355 79.9821 118.154 79.9973 118.053 80.058C117.964 80.1187 117.986 80.2047 118.131 80.2502Z"
        fill="#4E4970"
      />
      <path
        d="M132.285 31.9659C128.407 28.9718 121.948 33.6233 119.119 36.6174C117.398 38.4352 116.066 40.6005 115.158 43.06C114.404 45.105 113.355 48.3931 114.522 50.505C114.628 50.7055 114.805 51.0129 115.064 50.9996H115.158C115.512 50.9728 115.771 50.612 115.913 50.2778C116.608 48.6204 117.033 46.8961 117.975 45.3723C119.06 43.6347 120.368 42.1377 121.924 40.9481C123.445 39.7718 125.119 38.7961 126.887 38.208C128.643 37.6198 130.8 37.4862 132.309 36.2966C133.24 35.5614 133.228 32.6877 132.285 31.9659Z"
        fill="#5B517B"
      />
      <path
        d="M63.0711 56.0341C62.019 56.5919 61.2512 57.4704 60.7536 58.4883C60.2559 59.5063 60 60.6637 60 61.8211C60 62.2952 60.0427 62.7693 60.128 63.2295C60.6114 65.9068 62.1469 68.2356 63.5829 70.4946C65.0047 72.7536 66.3981 75.1242 66.5972 77.7039C66.6398 78.2477 66.6682 78.7776 66.6682 79.3215C66.6682 82.9889 65.7299 86.6703 64.7488 90.3795C63.7536 94.0887 62.7014 97.8259 62.4882 101.619C62.4313 102.539 62.4171 103.473 62.4171 104.394C62.4171 113.723 64.9337 122.912 66.4976 132.102C67.8341 139.911 68.5166 147.817 68.5166 155.724C68.5166 157.746 68.4739 159.754 68.3886 161.762C68.3744 161.887 68.4739 161.999 68.6161 161.999C68.7441 162.013 68.8578 161.915 68.8578 161.776C68.9431 159.768 69 157.746 69 155.724C69 147.789 68.3175 139.855 66.981 132.032C65.3886 122.815 62.8863 113.639 62.8863 104.394C62.8863 103.473 62.9147 102.553 62.9716 101.633C63.1848 97.9235 64.2227 94.2142 65.2038 90.505C66.1991 86.7818 67.1374 83.0586 67.1374 79.3215C67.1374 78.7637 67.1232 78.2199 67.0806 77.6621C66.8673 74.9429 65.4171 72.5165 63.981 70.2575C62.545 67.9846 61.0521 65.6977 60.5972 63.1458C60.5261 62.7135 60.4834 62.2673 60.4834 61.8211C60.4834 60.7334 60.7251 59.6318 61.1801 58.6836C61.6493 57.7353 62.3602 56.9405 63.2986 56.4385C63.4123 56.3827 63.455 56.2433 63.3981 56.1178C63.327 56.0062 63.1848 55.9644 63.0711 56.0341Z"
        fill="#4E4970"
      />
      <path
        d="M61.2274 57.9067C60.368 59.7623 62.1016 60.5655 63.8056 59.5684C64.3539 59.2499 64.8577 58.8345 65.48 58.6683C66.7692 58.3082 68.0879 59.0837 69.4215 59.2499C71.6886 59.5546 71.1404 57.3943 70.0883 56.3972C69.3919 55.7325 68.6214 55.1232 67.8064 54.5832C65.8801 53.323 63.9242 54.2785 62.6202 55.8018C62.235 56.2449 61.5978 57.0896 61.2274 57.9067Z"
        fill="#79CDDD"
      />
      <mask
        id="mask3_6633_2053"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="42"
        y="0"
        width="43"
        height="59"
      >
        <path d="M42.6089 0H84.1328V58.4664H42.6089V0Z" fill="white" />
      </mask>
      <g mask="url(#mask3_6633_2053)">
        <path
          d="M46.0149 35.0351C43.4696 28.3684 42.6212 20.7253 45.179 14.0168C47.2876 8.52161 51.717 3.94695 57.1445 1.96646C62.5596 -8.88705e-05 68.8855 0.753058 73.5644 4.14221C79.6907 8.56345 82.3483 16.7783 82.1113 24.4911C81.8867 31.6878 79.3913 38.5777 75.7604 44.4773C72.9531 49.038 67.6129 57.3784 61.9233 54.3798C55.1108 50.7954 48.9096 42.5945 46.0149 35.0351Z"
          fill="#79CDDD"
        />
      </g>
      <path
        d="M79.8866 14C80.5624 21.5101 78.5833 29.6842 75.7474 35.9697C73.527 40.9273 70.3894 45.3537 66.1175 47.7882C61.8335 50.2375 57.1392 49.9424 53 47.5374C55.5342 50.8129 58.4304 53.5868 61.4594 55.3279C66.9622 58.5001 72.1272 49.6768 74.8424 44.852C78.354 38.6108 80.7676 31.322 80.9848 23.7086C81.0813 20.4626 80.7193 17.128 79.8866 14Z"
        fill="#6CC4D5"
      />
      <path
        d="M61.3199 55.623C62.1503 55.8743 62.9955 56 63.8556 56C65.1604 56 66.4802 55.7068 67.7257 55.1204C67.9333 55.0157 68.052 54.7015 67.9778 54.3874C67.9037 54.0942 67.6812 53.9266 67.4588 54.0313C66.3022 54.5759 65.0715 54.8481 63.8556 54.8481C63.0548 54.8481 62.2689 54.7434 61.4979 54.513C61.2754 54.4502 61.053 54.6387 61.0085 54.9528C60.964 55.267 61.0975 55.5602 61.3199 55.623Z"
        fill="#4E4970"
      />
      <path
        d="M59.0452 4.12892C56.8186 3.3876 54.1175 6.01717 52.5966 7.58372C50.6134 9.6538 49.0073 12.0736 47.8636 14.857C46.6712 17.7803 46.075 20.9134 46.0142 24.1304C45.9777 25.7109 45.8682 30.7323 47.8758 30.998C48.2529 31.04 48.4719 30.3966 48.545 30.1029C49.056 28.1447 48.545 26.1445 48.691 24.1584C48.8735 21.7806 49.6887 19.4308 50.8567 17.4726C51.9517 15.6542 53.3023 14.0877 54.8475 12.7729C56.4292 11.4302 58.2908 10.2972 59.5197 8.50687C60.1645 7.55574 60.2984 4.54853 59.0452 4.12892Z"
        fill="#8FD8E6"
      />
      <path
        d="M50.9706 74.0552C49.6014 75.2033 49 77.0796 49 78.9558C49 79.8659 49.1408 80.7761 49.4095 81.6302C50.2156 84.2205 51.9303 86.2928 53.5298 88.3091C55.1292 90.3394 56.7031 92.4677 57.2662 95.002C57.5733 96.4302 57.6884 97.8444 57.6884 99.2446C57.6884 101.947 57.2534 104.635 56.9718 107.408C56.8695 108.332 56.8311 109.242 56.8311 110.166C56.8311 114.017 57.6116 117.853 58.7377 121.466C60.6315 127.57 63.485 133.255 65.4427 139.276C67.5157 145.633 68.5777 152.382 68.5777 159.117C68.5777 159.341 68.5777 159.551 68.5649 159.761C68.5649 159.901 68.6673 159.999 68.7825 159.999C68.9104 160.013 69 159.901 69 159.775C69 159.551 69 159.341 69 159.117C69 152.326 67.9379 145.535 65.8522 139.122C63.8816 133.045 61.0281 127.374 59.1472 121.312C58.0339 117.741 57.2534 113.947 57.2662 110.166C57.2662 109.256 57.3045 108.36 57.3941 107.464C57.6884 104.705 58.1235 102.003 58.1235 99.2446C58.1235 97.8164 58.0083 96.3602 57.6884 94.89C57.087 92.2296 55.4619 90.0313 53.8624 88.0151C52.2502 85.9848 50.5867 83.9405 49.8189 81.4762C49.563 80.6781 49.4351 79.8099 49.4351 78.9558C49.4351 77.1776 49.9981 75.4694 51.2393 74.4192C51.3289 74.3352 51.3544 74.1952 51.2777 74.0832C51.2009 73.9852 51.0729 73.9712 50.9706 74.0552Z"
        fill="#4E4970"
      />
      <path
        d="M49.0724 78.2141C48.6506 80.0413 50.1571 80.5268 51.3863 79.3896C51.772 79.0191 52.1215 78.5846 52.6035 78.3419C53.5918 77.8436 54.7487 78.3674 55.8455 78.3546C57.7135 78.3291 56.9783 76.4253 55.9901 75.6586C55.3393 75.1475 54.6403 74.7003 53.9051 74.317C52.1938 73.4225 50.7476 74.547 49.904 76.1186C49.6629 76.5786 49.2652 77.4347 49.0724 78.2141Z"
        fill="#F14959"
      />
      <path
        d="M30.6218 57.2754C26.9632 51.0801 24.9159 43.6205 26.5934 36.5121C27.967 30.6681 31.9162 25.3438 37.2919 22.4218C42.6675 19.5138 49.3904 19.1626 54.7925 21.7054C61.8588 25.0348 65.8741 32.7332 66.7722 40.4456C67.6175 47.6383 66.0458 54.9434 63.1268 61.4477C60.8814 66.4769 56.5492 75.7066 50.1565 73.7258C42.4958 71.3516 34.7823 64.2854 30.6218 57.2754Z"
        fill="#E65375"
      />
      <path
        d="M64.3224 33C66.0589 39.6853 65.0956 47.4553 63.0295 53.6524C61.3817 58.5477 58.7326 63.1175 54.6385 66.0737C50.5318 69.0434 45.6138 69.5994 41 68.1484C44.0674 70.6707 47.4517 72.6776 50.836 73.7353C56.9708 75.6473 61.1282 66.7382 63.283 61.8835C66.0842 55.6051 67.5926 48.5537 66.7814 41.6108C66.4392 38.6411 65.6153 35.685 64.3224 33Z"
        fill="#E63B50"
      />
      <path
        d="M49.2813 75.9792C49.4324 75.9792 49.5943 76 49.7454 76C51.1702 76 52.5842 75.3341 53.8471 74.0648C53.9874 73.9191 54.0413 73.5862 53.9658 73.2949C53.8902 73.0243 53.7175 72.9203 53.5664 73.066C52.3899 74.2313 51.073 74.8555 49.7454 74.8555C49.6051 74.8555 49.454 74.8555 49.3136 74.8347C49.1517 74.8139 49.0114 75.0636 49.0006 75.3757C48.9898 75.6879 49.1194 75.9584 49.2813 75.9792Z"
        fill="#4E4970"
      />
      <path
        d="M39.9669 26.0386C37.6937 25.6375 35.367 28.4586 34.1101 30.0903C32.3985 32.3306 31.1014 34.9304 30.2189 37.6269C29.1759 40.8213 28.7748 44.154 29.1224 47.5005C29.2829 49.0908 29.7108 54.0138 31.9439 54C32.6793 54 32.7729 52.7001 32.7997 52.2161C32.92 50.0312 32.0241 47.9984 31.9974 45.8273C31.9572 43.6147 32.5055 41.5404 33.4148 39.5629C34.2037 37.8482 35.2333 36.2856 36.4769 34.8889C37.8007 33.4092 39.4721 32.0817 40.4884 30.3669C41.0233 29.4681 41.4913 26.3013 39.9669 26.0386Z"
        fill="#E96483"
      />
      <path
        d="M80.0964 60.0053C78.9157 60.3233 77.9518 61.1806 77.253 62.2867C76.5542 63.3928 76.1205 64.7478 76 66.1028C75.9518 66.5314 75.9398 66.9739 75.9398 67.4163C75.9398 70.472 76.8072 73.7904 77.4578 76.5833C78.3253 80.3303 79.3012 84.0082 80 87.7828C81.1325 93.825 82.5904 100.144 82.5904 106.366C82.5904 108.315 82.4458 110.265 82.1084 112.187C80.9879 118.491 77.8554 124.077 75.0361 129.732C72.7952 134.24 70.6627 139.038 69.988 144.264C69.3976 148.993 69.2169 154.952 69 159.764C69 159.888 69.0843 159.999 69.1928 159.999C69.3133 160.013 69.3976 159.916 69.4096 159.777C69.6265 154.98 69.7952 149.02 70.3976 144.333C71.0482 139.204 73.1566 134.447 75.3976 129.954C78.2048 124.326 81.3614 118.699 82.506 112.283C82.8554 110.32 83 108.343 83 106.366C83 100.061 81.5301 93.7144 80.4096 87.686C79.6988 83.8975 78.7229 80.192 77.8554 76.4589C77.1928 73.6659 76.3373 70.3752 76.3494 67.4163C76.3494 66.9877 76.3614 66.5591 76.3976 66.1443C76.5181 64.8723 76.9277 63.5864 77.5783 62.5494C78.2289 61.5124 79.1205 60.7381 80.1928 60.4616C80.3012 60.434 80.3735 60.3095 80.3494 60.1851C80.3133 60.0606 80.2048 59.9777 80.0964 60.0053Z"
        fill="#4E4970"
      />
      <path
        d="M77.441 61.7097C76.3291 62.722 77.4888 63.4946 79.0431 63.1571C79.5452 63.0417 80.0354 62.873 80.5495 62.873C81.6375 62.8641 82.4864 63.5478 83.4787 63.8764C85.1645 64.4269 85.2601 63.0062 84.6743 62.2159C84.2917 61.692 83.8374 61.1947 83.3352 60.7329C82.1396 59.6407 80.406 59.9071 79.0192 60.6353C78.6127 60.8484 77.9192 61.2657 77.441 61.7097Z"
        fill="#F14959"
      />
      <path
        d="M68.2542 35.7394C67.4381 28.4742 68.5053 20.7866 72.6483 15.0562C76.038 10.3676 81.4615 7.27003 87.2115 7.0166C92.9489 6.76316 98.8746 9.42424 102.566 14.141C107.399 20.3079 107.952 29.0937 105.805 36.5138C103.809 43.427 99.6781 49.3687 94.7065 53.9868C90.8648 57.5631 83.6334 64.0257 78.8627 59.3794C73.1504 53.8179 69.1832 43.962 68.2542 35.7394Z"
        fill="#E65375"
      />
      <path
        d="M106.985 27C105.911 34.1516 101.967 41.0904 97.6448 46C94.2322 49.8738 90.036 52.9814 85.1447 53.8753C80.266 54.7693 75.6022 52.9956 72 49.4765C73.7695 53.2935 76.0445 56.7842 78.6861 59.3667C83.489 64.0493 90.7691 57.5363 94.6367 53.9321C99.6418 49.2779 103.8 43.2898 105.81 36.3226C106.669 33.3428 107.086 30.1501 106.985 27Z"
        fill="#E63B50"
      />
      <path
        d="M79.1533 60.3525C80.4297 60.7796 81.8576 61 83.2963 61C83.4369 61 83.5775 61 83.7181 60.9931C83.8804 60.9931 84.0102 60.9036 83.9994 60.8002C83.9994 60.6969 83.8587 60.6142 83.6965 60.6211C83.5559 60.6211 83.4261 60.628 83.2963 60.628C81.9549 60.628 80.6136 60.4145 79.4345 60.0218C79.2939 59.9736 79.11 60.0081 79.0343 60.0976C78.9586 60.1872 79.0127 60.3043 79.1533 60.3525Z"
        fill="#4E4970"
      />
      <path
        d="M88.2657 9.68494C84.0796 6.95452 77.948 13.0399 75.424 16.4819C73.688 18.8493 72.469 21.6378 71.7426 24.6441C71.2624 26.6629 70.2651 31.1071 71.9027 32.7482C72.0751 32.9225 72.2721 33.0968 72.5183 32.937C73.7249 32.1092 73.6757 29.2916 74.082 27.9264C74.7715 25.5881 76.0396 23.3806 77.5294 21.6232C78.9207 19.9966 80.5829 18.6169 82.3435 17.6002C84.2396 16.4819 86.6651 16.0172 88.2781 14.3905C89.103 13.5482 89.3739 10.4111 88.2657 9.68494Z"
        fill="#E96483"
      />
      <path
        d="M97.5794 79.1356C96.0549 82.8794 94.4916 86.7453 94.0135 90.8554C93.9489 91.3844 93.9231 91.8727 93.9231 92.3475C93.9231 94.233 94.3365 95.8336 94.6595 97.7055C94.75 98.2888 94.8017 98.8856 94.8017 99.5232C94.8017 101.436 94.4011 103.511 94.0394 105.288C93.0575 110.09 91.0161 114.675 88.0704 118.527C85.0471 122.474 81.0548 125.743 78.7421 130.288C76.3907 134.954 76.0806 140.353 75.4217 145.494C75.0858 148.166 74.6336 151.34 73.5871 154.012C73.0574 155.341 72.3726 156.549 71.494 157.512C70.6155 158.475 69.5302 159.194 68.1607 159.546C68.0444 159.587 67.9798 159.709 68.0057 159.831C68.0315 159.953 68.1478 160.021 68.277 159.994C69.724 159.614 70.8868 158.841 71.817 157.824C73.2124 156.304 74.091 154.243 74.6853 152.059C75.2925 149.875 75.6026 147.569 75.861 145.561C76.5199 140.393 76.83 135.049 79.1297 130.505C81.3778 126.069 85.3442 122.814 88.4063 118.812C91.3908 114.905 93.4709 110.253 94.4657 105.383C94.8275 103.606 95.228 101.504 95.228 99.5232C95.228 98.8721 95.1893 98.2345 95.0859 97.6241C94.7629 95.7251 94.3624 94.1516 94.3624 92.3475C94.3624 91.8863 94.3882 91.4115 94.4399 90.9232C94.9179 86.8945 96.4554 83.0558 97.98 79.3119C98.0316 79.2034 97.98 79.0678 97.8637 79.0135C97.7603 78.9728 97.6311 79.0271 97.5794 79.1356Z"
        fill="#4E4970"
      />
      <path
        d="M93.516 78.0371C92.2362 79.2811 93.5295 80.2609 95.2942 79.8646C95.86 79.7325 96.4123 79.5343 96.9916 79.5343C98.2175 79.5454 99.147 80.4151 100.252 80.8334C102.138 81.549 102.299 79.7876 101.666 78.8078C101.249 78.1472 100.75 77.5197 100.184 76.9363C98.8776 75.5712 96.9108 75.8684 95.3346 76.7491C94.8631 77.0023 94.0818 77.5087 93.516 78.0371Z"
        fill="#FBC444"
      />
      <path
        d="M84.1821 52.8888C83.4925 45.4737 84.6961 37.6418 88.9211 31.8936C92.4065 27.1801 97.8728 24.1479 103.602 24.0042C109.332 23.8749 115.212 26.7346 118.823 31.6205C123.537 38.0298 123.925 46.9969 121.656 54.5271C119.537 61.5398 115.312 67.5036 110.26 72.1021C106.36 75.666 99.0262 82.104 94.3498 77.2611C88.7582 71.4554 84.9594 61.3099 84.1821 52.8888Z"
        fill="#FBC444"
      />
      <path
        d="M122.997 44C121.843 51.4629 117.944 58.6291 113.705 63.6587C110.353 67.635 106.259 70.7804 101.559 71.6112C96.8463 72.4272 92.4009 70.4836 89 66.7003C90.6397 70.7507 92.7652 74.445 95.2552 77.2047C99.7856 82.2047 106.891 75.5578 110.668 71.8783C115.563 67.1305 119.656 60.9733 121.709 53.7329C122.584 50.632 123.045 47.3086 122.997 44Z"
        fill="#FAB939"
      />
      <path
        d="M95.1536 77.0022C96.4655 78.3145 97.9617 79 99.4579 79C99.5446 79 99.6205 79 99.7073 79C99.8699 78.9804 100 78.7454 100 78.4516C99.9892 78.1382 99.8591 77.9032 99.6964 77.9228C99.6097 77.9228 99.5338 77.9228 99.4579 77.9228C98.0593 77.9228 96.6607 77.2764 95.4464 76.0621C95.3054 75.925 95.1211 76.0229 95.0344 76.2775C94.9585 76.5321 95.0127 76.8651 95.1536 77.0022Z"
        fill="#4E4970"
      />
      <path
        d="M105.257 26.7138C101.267 24.1568 95.0457 29.0577 92.3069 32.1545C89.1097 35.7769 86.3089 41.9563 87.1516 47.2265C87.4491 49.0448 88.5024 49.8119 89.2088 47.8374C89.593 46.7435 89.6673 45.5361 90.0143 44.428C90.4604 42.9649 91.1668 41.6011 91.9723 40.3653C93.4471 38.064 95.4175 36.2883 97.6357 34.9814C100.003 33.5892 103.225 33.2483 105.27 31.3163C106.125 30.5208 106.36 27.424 105.257 26.7138Z"
        fill="#FDDC65"
      />
      <path
        d="M45 99.2293C45.3168 106.919 46.9388 113.718 51.6655 119.829C54.4406 123.417 57.596 126.723 59.5348 130.797C61.7017 135.329 62.2466 140.523 63.0576 145.582C63.8813 150.654 65.3639 156.037 68.6206 159.922C68.6966 160.016 68.836 160.03 68.9247 159.936C69.0134 159.855 69.0261 159.72 68.9501 159.625C65.7694 155.848 64.2995 150.533 63.4884 145.501C62.6648 140.469 62.1325 135.221 59.9276 130.594C57.9381 126.426 54.7574 123.107 51.9949 119.532C47.3443 113.529 45.7476 106.851 45.4308 99.2158C45.4182 99.0944 45.3295 99 45.2028 99C45.0887 99 45 99.1079 45 99.2293Z"
        fill="#4E4970"
      />
      <path
        d="M44.06 100.504C43.7259 102.024 44.8547 102.441 45.7759 101.499C46.0739 101.2 46.3358 100.836 46.697 100.633C47.4375 100.226 48.3045 100.676 49.1263 100.665C50.526 100.654 49.9932 99.0601 49.2617 98.4074C48.7741 97.9795 48.2503 97.5943 47.6994 97.2733C46.4261 96.5137 45.3333 97.4445 44.6922 98.7498C44.5025 99.135 44.2045 99.8411 44.06 100.504Z"
        fill="#FBC444"
      />
      <path
        d="M25.0572 80.4016C21.7892 74.2976 20.0121 66.9673 21.5746 59.9953C22.8627 54.2771 26.4646 49.0963 31.3427 46.2855C36.2208 43.4884 42.2916 43.199 47.1459 45.7481C53.5029 49.0688 57.069 56.6608 57.8204 64.2391C58.5361 71.3076 57.0571 78.445 54.3736 84.797C52.2983 89.7022 48.3266 98.7135 42.554 96.7156C35.6602 94.3181 28.7546 87.3185 25.0572 80.4016Z"
        fill="#FBC444"
      />
      <path
        d="M55.543 56C57.1402 62.7321 56.1722 70.5115 54.1393 76.6996C52.53 81.5957 49.9647 86.1518 46.02 89.0759C42.0753 92.0136 37.3924 92.5168 33 91.0207C35.9041 93.5912 39.1228 95.6312 42.3294 96.7192C48.1859 98.6913 52.2154 89.7967 54.3208 84.955C57.0434 78.6853 58.5438 71.6403 57.8178 64.6634C57.5153 61.6849 56.753 58.7065 55.543 56Z"
        fill="#FAB939"
      />
      <path
        d="M44.2701 98.9717C44.4532 98.9858 44.6364 99 44.8088 99C46.2096 99 47.5997 98.5607 48.8388 97.7389C48.9789 97.6397 49.0436 97.3988 48.9681 97.2146C48.8927 97.0162 48.7095 96.9453 48.5695 97.0445C47.4057 97.8097 46.1127 98.2206 44.8088 98.2206C44.6472 98.2206 44.4748 98.2206 44.3132 98.2065C44.1515 98.1923 44.0115 98.3482 44.0007 98.5607C43.9899 98.7733 44.1084 98.9575 44.2701 98.9717Z"
        fill="#4E4970"
      />
      <path
        d="M33.1213 49.0361C31.0774 48.6523 28.9416 51.4188 27.7474 53.0072C26.048 55.2574 24.7849 57.8783 23.9811 60.6978C23.1429 63.6496 22.8328 66.6809 23.0855 69.765C23.2233 71.3667 23.5448 75.9334 25.4739 75.9996C26.1054 76.0261 26.1973 74.7289 26.2087 74.292C26.3236 72.2006 25.5657 70.2548 25.5657 68.1634C25.5542 66.0587 26.025 64.0335 26.8747 62.1803C28.5168 58.527 31.7319 56.5414 33.5691 53.1793C34.0285 52.3321 34.4074 49.2744 33.1213 49.0361Z"
        fill="#FDDC65"
      />
      <path
        d="M72.3183 99.0243C71.2918 99.5698 70.5348 100.479 70.0472 101.542C69.5468 102.619 69.303 103.864 69.303 105.081C69.303 105.416 69.3159 105.766 69.3543 106.102C69.6751 108.801 70.9582 111.207 72.1515 113.542C73.3448 115.864 74.4996 118.312 74.5637 120.899C74.5766 121.039 74.5766 121.193 74.5766 121.333C74.5766 124.13 73.8195 126.662 73.0625 129.236C72.3055 131.809 71.5356 134.425 71.5356 137.334C71.5356 137.977 71.5741 138.635 71.6639 139.32C72.1258 142.984 73.024 146.579 73.024 150.188C73.024 150.523 73.0112 150.859 72.9984 151.195C72.9085 153.153 72.267 155.279 71.356 157.153C70.445 159.013 69.2645 160.636 68.0841 161.573C67.9943 161.657 67.9686 161.796 68.0456 161.908C68.1097 162.006 68.2509 162.034 68.3535 161.95C69.5981 160.943 70.8043 159.279 71.7409 157.363C72.6776 155.447 73.332 153.265 73.4346 151.223C73.4474 150.873 73.4603 150.537 73.4603 150.188C73.4603 146.495 72.5493 142.873 72.0874 139.264C72.0104 138.607 71.9719 137.963 71.9719 137.334C71.9719 134.508 72.7161 131.949 73.4731 129.375C74.2301 126.816 75 124.214 75 121.333C75 121.193 75 121.039 75 120.885C74.923 118.144 73.7297 115.654 72.5364 113.319C71.3303 110.983 70.0857 108.619 69.7906 106.046C69.7521 105.724 69.7264 105.402 69.7264 105.081C69.7264 103.934 69.9702 102.759 70.4322 101.766C70.8941 100.759 71.5869 99.9334 72.5108 99.4439C72.6134 99.388 72.6519 99.2481 72.6006 99.1362C72.5493 99.0103 72.421 98.9684 72.3183 99.0243Z"
        fill="#4E4970"
      />
      <path
        d="M71.2226 101.424C70.4537 103.617 71.814 104.664 73.2097 103.551C73.6591 103.202 74.0731 102.737 74.5817 102.571C75.6226 102.205 76.6398 103.185 77.7043 103.468C79.4903 103.949 79.1473 101.341 78.3548 100.079C77.8226 99.2483 77.2312 98.4842 76.6043 97.8031C75.114 96.1918 73.5172 97.2217 72.4172 98.9825C72.086 99.4975 71.5537 100.478 71.2226 101.424Z"
        fill="#4E4970"
      />
      <path
        d="M54.919 77.7632C52.6297 70.863 52.0858 63.056 54.9696 56.3825C57.3221 50.9133 62.0145 46.5068 67.5922 44.7924C73.1573 43.0779 79.5319 44.1689 84.1231 47.8387C90.1435 52.6561 92.496 61.129 91.9142 68.936C91.383 76.233 88.5625 83.1048 84.6416 88.8999C81.6061 93.3772 75.8513 101.553 70.2103 98.223C63.469 94.2274 57.5244 85.5844 54.919 77.7632Z"
        fill="#4E4970"
      />
      <path
        d="M91.1398 60C91.5665 67.1614 89.0969 74.8133 85.8127 80.6153C83.2267 85.198 79.6968 89.2482 75.0161 91.3223C70.3355 93.4105 65.3186 92.8779 61 90.3973C63.5601 93.6207 66.5599 96.4095 69.7278 98.2314C75.4945 101.525 81.3777 93.4385 84.4809 89.0099C88.4892 83.278 91.3726 76.481 91.9156 69.2636C92.1484 66.1944 91.9027 63.0131 91.1398 60Z"
        fill="#474367"
      />
      <path
        d="M71.2532 98.3644C72.1159 98.7881 73.004 99 73.8922 99C74.8564 99 75.8206 98.7646 76.7468 98.2702C76.9245 98.176 77.0386 97.8229 76.9879 97.4698C76.9245 97.1402 76.7341 96.9283 76.5438 97.0225C75.6811 97.4933 74.793 97.7052 73.8922 97.7052C73.0675 97.7052 72.2301 97.5169 71.4308 97.1402C71.2532 97.046 71.0502 97.2343 71.0121 97.5875C70.9614 97.9406 71.0755 98.2937 71.2532 98.3644Z"
        fill="#4E4970"
      />
      <path
        d="M70.2418 49.2424C65.5888 47.5788 60.3413 54.8987 58.5059 58.3723C57.0971 61.0607 56.2958 63.9753 56.089 67.0231C55.9856 68.6068 55.6107 73.6642 57.8338 73.9969C58.0794 74.0369 58.3508 73.6775 58.4155 73.4912C59.1393 71.6679 58.661 69.778 58.9066 67.8748C59.191 65.5857 60.1216 63.4164 61.427 61.5531C62.5385 59.9428 63.8569 58.4788 65.4079 57.3076C67.1011 56.0166 69.1432 55.0185 70.4874 53.3415C71.0432 52.6362 71.3792 49.655 70.2418 49.2424Z"
        fill="#5B517B"
      />
      <path
        d="M98.7499 106.005C97.5385 106.318 96.5595 107.162 95.8619 108.264C95.1522 109.353 94.7239 110.686 94.5893 112.02C94.5526 112.455 94.5403 112.877 94.5403 113.312C94.5403 116.877 95.7885 120.184 95.7763 123.545C95.7763 124.892 95.5805 126.253 95.0298 127.654C94.0998 129.995 92.5579 131.995 90.9181 133.832C86.7085 138.554 81.7524 142.405 77.1757 146.759C75.3523 148.501 73.4678 150.31 71.9503 152.406C70.4329 154.488 69.3071 156.869 69.0012 159.74C68.9889 159.863 69.0746 159.985 69.1847 159.999C69.2949 160.012 69.3928 159.917 69.4172 159.795C69.6987 157.032 70.7878 154.733 72.2808 152.692C73.7492 150.637 75.6215 148.841 77.4449 147.113C81.9971 142.772 86.9655 138.908 91.2118 134.159C92.8761 132.294 94.4547 130.267 95.4092 127.845C95.9843 126.375 96.1924 124.946 96.1924 123.545C96.1924 120.062 94.9442 116.755 94.9564 113.312C94.9564 112.89 94.9686 112.482 95.0053 112.06C95.1277 110.809 95.5438 109.543 96.1924 108.523C96.8532 107.502 97.7587 106.74 98.8356 106.454C98.958 106.427 99.0192 106.305 98.9947 106.182C98.9702 106.06 98.8601 105.978 98.7499 106.005Z"
        fill="#4E4970"
      />
      <path
        d="M96.4382 107.445C95.3277 109.474 96.4979 111.023 98.0503 110.328C98.5518 110.115 99.0295 109.759 99.5549 109.759C100.642 109.723 101.477 111.094 102.481 111.752C104.164 112.856 104.26 110.008 103.675 108.424C103.281 107.374 102.827 106.377 102.325 105.452C101.119 103.281 99.3877 103.814 98.0025 105.292C97.6085 105.719 96.9159 106.555 96.4382 107.445Z"
        fill="#79CDDD"
      />
      <path
        d="M85.2835 80.3358C84.399 72.9528 85.4948 65.1111 89.8382 59.2621C93.3896 54.4739 99.0928 51.3056 105.126 51.0189C111.16 50.7465 117.404 53.4274 121.299 58.2155C126.395 64.4803 127.002 73.4259 124.771 80.9809C122.685 88.0342 118.368 94.0982 113.153 98.8148C109.113 102.47 101.535 109.079 96.5053 104.363C90.4851 98.7287 86.2737 88.6936 85.2835 80.3358Z"
        fill="#79CDDD"
      />
      <path
        d="M125.982 73C124.918 79.9374 120.993 86.6549 116.676 91.4218C113.27 95.1858 109.067 98.2081 104.18 99.0873C99.2928 99.9802 94.621 98.2905 91 94.8836C92.7852 98.579 95.0768 101.958 97.7228 104.431C102.547 108.951 109.826 102.618 113.688 99.1147C118.689 94.5951 122.829 88.7842 124.829 82.0255C125.678 79.1406 126.096 76.0497 125.982 73Z"
        fill="#6CC4D5"
      />
      <path
        d="M98.1634 104.71C99.4264 105.556 100.84 106 102.265 106C102.416 106 102.568 106 102.719 105.986C102.881 105.986 103.01 105.806 102.999 105.598C102.989 105.39 102.848 105.223 102.686 105.237C102.546 105.237 102.406 105.251 102.265 105.251C100.938 105.251 99.6099 104.835 98.4333 104.044C98.293 103.947 98.1095 104.016 98.0339 104.197C97.9583 104.391 98.0123 104.613 98.1634 104.71Z"
        fill="#4E4970"
      />
      <path
        d="M106.29 56.5656C104.11 55.199 101.239 56.5935 99.2026 57.7509C96.9276 59.0478 94.9027 60.8885 93.2471 63.1198C91.5795 65.4067 90.4003 68.0842 89.7095 70.9708C89.233 72.951 88.304 77.1346 89.8762 78.7522C90.0311 78.9195 90.2455 79.1008 90.4599 78.9335C91.6391 78.041 91.5676 75.503 91.9726 74.1224C92.6277 71.8633 93.8426 69.7437 95.2839 68.0563C96.6894 66.4108 98.4165 65.1278 100.227 64.1796C102.168 63.1337 104.658 62.7153 106.302 61.0838C107.1 60.2889 107.362 57.2489 106.29 56.5656Z"
        fill="#8FD8E6"
      />
      <mask
        id="mask4_6633_2053"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="61"
        y="158"
        width="11"
        height="15"
      >
        <path d="M61.7739 158.439H71.3564V173H61.7739V158.439Z" fill="white" />
      </mask>
      <g mask="url(#mask4_6633_2053)">
        <path
          d="M68.1618 160.364C68.037 161.243 67.5878 161.982 67.0264 162.693C66.4649 163.404 65.7786 164.074 65.2172 164.827C64.3562 165.971 63.9944 167.728 63.9819 169.499C63.9819 170.657 64.1441 171.815 64.4685 172.819C64.506 172.93 64.6183 173 64.7306 172.958C64.8429 172.916 64.9052 172.777 64.8678 172.665C64.5684 171.717 64.4062 170.601 64.4062 169.499C64.4062 167.798 64.7805 166.124 65.5416 165.134C66.0781 164.409 66.7519 163.739 67.3383 163C67.9247 162.261 68.4238 161.452 68.586 160.448C68.5985 160.308 68.5236 160.197 68.4113 160.169C68.2865 160.155 68.1867 160.238 68.1618 160.364Z"
          fill="#4E4970"
        />
      </g>
      <path
        d="M68.4912 160.202C68.2456 162.209 68 164.292 68 166.36C68 167.476 68.0702 168.593 68.2544 169.68C68.4386 170.736 68.7018 171.732 68.7105 172.743C68.7105 172.879 68.7719 173 68.8596 173C68.9386 173 69 172.879 69 172.743C69 171.581 68.7105 170.555 68.5439 169.544C68.3684 168.503 68.2982 167.431 68.2982 166.36C68.2982 164.352 68.5351 162.3 68.7807 160.308C68.7982 160.172 68.7456 160.036 68.6579 160.006C68.5789 159.976 68.5 160.066 68.4912 160.202Z"
        fill="#4E4970"
      />
      <path
        d="M69.0524 160.41C70.2356 161.894 70.5413 164.039 70.5413 166.111C70.5413 166.169 70.5413 166.228 70.5413 166.302H70.7673L70.5413 166.287C70.5413 166.698 70.5281 167.124 70.5281 167.536C70.5281 168.065 70.5413 168.608 70.6344 169.137C70.8604 170.621 71.5783 171.987 72.6419 172.942C72.7349 173.03 72.8679 173.016 72.9476 172.913C73.0274 172.81 73.0141 172.648 72.921 172.56C71.9505 171.693 71.2858 170.415 71.0731 169.064C70.9934 168.564 70.9801 168.05 70.9801 167.536C70.9801 167.124 70.9801 166.713 70.9934 166.302C70.9934 166.243 70.9934 166.169 70.9934 166.111C70.9934 163.995 70.7009 161.733 69.398 160.087C69.3183 159.984 69.172 159.97 69.079 160.058C68.9859 160.146 68.9726 160.308 69.0524 160.41Z"
        fill="#4E4970"
      />
      <path
        d="M69.066 160.456C70.1935 161.775 71.2965 163.171 71.897 164.878C72.4608 166.461 72.5833 168.23 72.5833 170.029C72.5833 170.588 72.5711 171.162 72.5588 171.721C72.5588 171.876 72.6446 171.984 72.7549 172C72.8774 172 72.9755 171.891 72.9755 171.736C72.9877 171.178 73 170.604 73 170.029C73 168.214 72.8774 166.368 72.2769 164.661C71.6274 162.83 70.4876 161.402 69.3478 160.068C69.2621 159.975 69.1395 159.975 69.0537 160.084C68.9802 160.192 68.9802 160.347 69.066 160.456Z"
        fill="#4E4970"
      />
      <mask
        id="mask5_6633_2053"
        // style="mask-type:luminance"
        // maskUnits="userSpaceOnUse"
        x="64"
        y="158"
        width="8"
        height="15"
      >
        <path d="M64.9683 158.439H71.3566V173H64.9683V158.439Z" fill="white" />
      </mask>
      <g mask="url(#mask5_6633_2053)">
        <path
          d="M68.2003 161.257C68.1504 163.181 67.3768 165.022 66.6781 166.863C66.1041 168.37 65.5676 169.988 65.5676 171.647C65.5676 172.024 65.5926 172.4 65.655 172.777C65.6799 172.902 65.7922 172.986 65.9045 172.972C66.0168 172.944 66.0917 172.819 66.0667 172.693C66.0168 172.345 65.9918 171.996 65.9918 171.647C65.9918 170.099 66.4909 168.537 67.0649 167.045C67.7511 165.218 68.5621 163.335 68.6245 161.271C68.6245 161.145 68.5372 161.033 68.4249 161.033C68.3001 161.033 68.2003 161.131 68.2003 161.257Z"
          fill="#4E4970"
        />
      </g>
      <path
        d="M69.4778 160.246C69.7877 160.232 70.7075 159.69 70.8675 160.261C70.9675 160.641 71.1075 161.241 70.8675 161.534C70.7875 161.636 70.6975 161.651 70.5976 161.665C70.1277 161.709 68.418 162.441 68.0981 161.592C67.9481 161.212 67.9381 160.29 68.298 160.173C68.438 160.129 68.578 160.173 68.7179 160.202C68.9779 160.246 69.2278 160.261 69.4778 160.246Z"
        fill="#E65375"
      />
      <path
        d="M33.0158 87.759C33.1061 88.2678 33.2958 88.6749 33.5396 88.9294C33.6932 89.082 33.8829 88.9802 33.9642 88.6919C34.0454 88.4205 33.9822 88.0643 33.8377 87.9116C33.7293 87.8098 33.639 87.6063 33.6029 87.4028C33.5487 87.0974 33.377 86.9278 33.2145 87.0296C33.0519 87.1314 32.9616 87.4536 33.0158 87.759Z"
        fill="#F8A839"
      />
      <path
        d="M34.2054 88.1524C34.4205 88.3705 34.6357 88.5886 34.8704 88.8067C35.1247 89.0644 35.555 89.0644 35.8093 88.8067C36.0636 88.5291 36.0636 88.1128 35.8093 87.8551C35.5941 87.637 35.3594 87.4189 35.1443 87.2008C34.89 86.9232 34.4597 86.9431 34.2054 87.2008C33.9315 87.4585 33.9315 87.8947 34.2054 88.1524Z"
        fill="#F8A839"
      />
      <path
        d="M116.389 50.2033C116.269 50.3528 116.149 50.5103 116.029 50.6599C115.909 50.8094 116.173 50.9511 116.604 50.9905C117.036 51.0298 117.492 50.9433 117.611 50.8016C117.731 50.6441 117.851 50.4867 117.971 50.3371C118.091 50.1954 117.827 50.0458 117.396 50.0065C116.94 49.975 116.508 50.0616 116.389 50.2033Z"
        fill="#F8A839"
      />
      <path
        d="M118.374 50.1378C118.266 50.2883 118.149 50.4466 118.041 50.5971C117.953 50.7238 118.012 50.89 118.168 50.9613C118.335 51.0405 118.531 50.993 118.629 50.8663C118.737 50.7079 118.844 50.5575 118.952 50.3991C119.05 50.2724 118.991 50.1062 118.835 50.0349C118.668 49.9636 118.462 50.0032 118.374 50.1378Z"
        fill="#F8A839"
      />
      <path
        d="M119.204 49.192C119.145 49.3501 119.085 49.5003 119.014 49.6584C118.955 49.8007 119.085 49.9509 119.298 49.9904C119.511 50.03 119.736 49.943 119.795 49.8007C119.866 49.6426 119.926 49.4924 119.985 49.3422C120.044 49.192 119.926 49.0497 119.701 49.0102C119.488 48.9706 119.263 49.0497 119.204 49.192Z"
        fill="#F8A839"
      />
      <path
        d="M118.033 39.7044C118.084 39.8526 118.123 40.0156 118.123 40.1934C118.123 40.2675 118.11 40.3416 118.097 40.4157C118.058 40.6824 118.226 40.9492 118.458 40.9936C118.703 41.0381 118.936 40.8454 118.974 40.5787C118.987 40.4453 119 40.3268 119 40.1934C119 39.897 118.948 39.5858 118.845 39.3043C118.755 39.0523 118.497 38.9338 118.265 39.0375C118.046 39.1413 117.942 39.4376 118.033 39.7044Z"
        fill="#F8A839"
      />
      <path
        d="M108.78 93.9605C109.291 93.801 109.715 93.5751 109.963 93.3159C110.065 93.2029 109.948 93.07 109.7 93.0168C109.437 92.9703 109.145 93.0235 109.043 93.1364C108.868 93.3159 108.576 93.4754 108.226 93.5884C107.992 93.6548 107.934 93.7944 108.08 93.9007C108.226 94.0004 108.547 94.0336 108.78 93.9605Z"
        fill="#6CC4D5"
      />
      <path
        d="M112.244 92.9688C113.112 92.8338 113.732 92.5939 113.98 92.3165C114.079 92.1815 113.806 92.0391 113.36 92.0091C112.913 91.9716 112.442 92.054 112.318 92.189C112.219 92.324 111.872 92.4514 111.45 92.5189C111.029 92.5864 110.88 92.7438 111.103 92.8638C111.326 92.9913 111.847 93.0363 112.244 92.9688Z"
        fill="#6CC4D5"
      />
      <path
        d="M108.853 92.8465C109.373 92.2047 109.766 91.3118 109.971 90.2794C110.065 89.7771 109.924 89.247 109.64 89.0517C109.373 88.8842 109.058 89.1354 108.963 89.6376C108.822 90.2794 108.57 90.8933 108.239 91.2839C107.988 91.5629 107.925 92.1768 108.098 92.5953C108.271 93.0139 108.601 93.1255 108.853 92.8465Z"
        fill="#6CC4D5"
      />
      <path
        d="M119.399 74.2533C119.279 74.6207 119.15 74.9881 119.03 75.3555C118.957 75.5785 119.021 75.8541 119.178 75.9591C119.334 76.064 119.528 75.9591 119.601 75.736C119.721 75.3686 119.85 75.0012 119.97 74.6338C120.043 74.4107 119.979 74.1483 119.822 74.0433C119.657 73.9384 119.472 74.0302 119.399 74.2533Z"
        fill="#6CC4D5"
      />
      <path
        d="M120.907 74.5512C120.621 75.5642 120.335 76.5473 120.066 77.5305C119.915 78.037 120.033 78.6328 120.319 78.901C120.605 79.1393 120.958 78.9308 121.093 78.4541C121.379 77.4411 121.648 76.458 121.934 75.4748C122.085 74.9683 121.967 74.3427 121.681 74.1043C121.395 73.866 121.042 74.0447 120.907 74.5512Z"
        fill="#6CC4D5"
      />
      <path
        d="M92.0051 81.4581C92.0648 81.8632 92.1244 82.2563 92.184 82.6494C92.2198 82.8757 92.4225 83.0306 92.6491 82.9949C92.8756 82.9591 93.0306 82.7566 92.9949 82.5303C92.9352 82.1372 92.8756 81.7321 92.8041 81.339C92.7802 81.1127 92.5656 80.9697 92.339 81.0055C92.1244 81.0293 91.9694 81.2437 92.0051 81.4581Z"
        fill="#6CC4D5"
      />
      <path
        d="M53.0164 30.2714C53.0931 30.3843 53.1186 30.4971 53.1186 30.6163C53.1186 30.6664 53.1186 30.7103 53.1058 30.7605C53.0803 30.8796 53.2463 30.9862 53.489 30.9987C53.7317 31.0113 53.9489 30.9298 53.9745 30.8106C53.9872 30.7479 54 30.679 54 30.6163C54 30.4595 53.9489 30.3027 53.8595 30.1523C53.7956 30.0394 53.5529 29.9767 53.3102 30.0081C53.0803 30.0394 52.9525 30.1585 53.0164 30.2714Z"
        fill="#E63B50"
      />
      <path
        d="M131.87 67.2308C131.601 67.5594 131.349 67.888 131.098 68.2166C130.918 68.4556 130.99 68.7692 131.278 68.9186C131.565 69.0679 131.942 69.0082 132.122 68.7692C132.391 68.4406 132.643 68.112 132.894 67.7834C133.092 67.5444 133.002 67.2308 132.715 67.0814C132.427 66.9321 132.05 66.9918 131.87 67.2308Z"
        fill="#474367"
      />
      <path
        d="M134.409 68.9472C134.674 68.7075 134.882 68.2601 134.981 67.7327C135.039 67.4451 134.956 67.1414 134.815 67.0296C134.666 66.9337 134.5 67.0775 134.45 67.3652C134.401 67.6209 134.293 67.8606 134.16 67.9724C134.019 68.1003 133.961 68.4199 134.027 68.6915C134.094 68.9632 134.268 69.0751 134.409 68.9472Z"
        fill="#474367"
      />
      <path
        d="M134.565 66.7245C134.672 66.3974 134.789 66.0704 134.896 65.7433C135.033 65.3567 135.033 64.7026 134.906 64.316C134.78 63.8997 134.565 63.8997 134.439 64.2863C134.322 64.6134 134.214 64.9405 134.107 65.2675C133.971 65.6541 133.961 66.2785 134.098 66.6948C134.224 67.0813 134.429 67.1111 134.565 66.7245Z"
        fill="#474367"
      />
      <path
        d="M143.657 54.4359C143.597 54.6714 143.439 54.9069 143.201 55.0796C142.943 55.2837 142.923 55.6291 143.201 55.8331C143.459 56.0529 143.895 56.0529 144.153 55.8488C144.589 55.5192 144.867 55.0953 144.986 54.64C145.065 54.3417 144.807 54.0592 144.45 54.0121C144.073 53.9493 143.716 54.1377 143.657 54.4359Z"
        fill="#474367"
      />
      <path
        d="M142.49 54.1257C142.283 54.4287 142.122 54.8114 142.023 55.2579C141.96 55.529 142.032 55.848 142.184 55.9596C142.346 56.0713 142.526 55.9437 142.589 55.6726C142.652 55.4015 142.759 55.1463 142.885 54.9549C143.02 54.7636 143.038 54.4287 142.93 54.1894C142.813 53.9662 142.625 53.9343 142.49 54.1257Z"
        fill="#474367"
      />
      <path
        d="M141.304 39.3059C141.219 39.5954 141.133 39.8848 141.037 40.1572C140.952 40.4466 141.016 40.8042 141.197 40.9404C141.378 41.0766 141.602 40.9744 141.687 40.685C141.783 40.3956 141.869 40.1232 141.965 39.8337C142.05 39.5443 141.975 39.2038 141.805 39.0676C141.624 38.9143 141.4 39.0335 141.314 39.3059H141.304Z"
        fill="#474367"
      />
      <path
        d="M124.959 57.1328C124.345 57.6206 124 58.3841 124 59.19C124 59.2325 124 59.2749 124 59.3385C124.038 59.7415 124.345 60.0384 124.71 59.996C125.074 59.9748 125.343 59.6142 125.304 59.2325V59.19C125.304 58.8507 125.477 58.4902 125.726 58.2993C126.014 58.066 126.091 57.6206 125.88 57.3025C125.669 56.9844 125.247 56.8995 124.959 57.1328Z"
        fill="#474367"
      />
      <path
        d="M65.02 86.3294C65.142 86.4869 65.2396 86.6366 65.3616 86.7941C65.4592 86.9359 65.8985 87.0304 66.3621 86.991C66.8014 86.9595 67.0942 86.8177 66.9722 86.676C66.8746 86.5184 66.7525 86.3609 66.6549 86.2112C66.5329 86.0616 66.0937 85.9749 65.6301 86.0064C65.1908 86.0458 64.9224 86.1876 65.02 86.3294Z"
        fill="#474367"
      />
      <path
        d="M81.191 79.2121C81.138 79.3876 81.0584 79.5632 81.0054 79.7388C80.9523 79.8722 81.2971 79.9916 81.8011 79.9986C82.305 80.0126 82.7559 79.9213 82.809 79.7879C82.862 79.6124 82.9416 79.4368 82.9946 79.2612C83.0477 79.1278 82.7029 79.0155 82.1989 79.0014C81.695 78.9874 81.2441 79.0787 81.191 79.2121Z"
        fill="#474367"
      />
      <path
        d="M84 57.7857C84 58.2619 84 58.7143 84 59.1905C84 59.6429 84.2206 60 84.5 60C84.7794 60 85 59.6429 85 59.1905C85 58.7143 85 58.2619 85 57.7857C85 57.3571 84.7794 57 84.5 57C84.2206 57 84 57.3571 84 57.7857Z"
        fill="#474367"
      />
      <path
        d="M61 64.6375C61 65.2187 61 65.7813 61 66.3625C61 66.7188 61.6522 67 62.4783 67C63.3044 67 64 66.7188 64 66.3625C63.9565 65.7813 63.9565 65.2187 63.9565 64.6375C63.9565 64.3 63.3044 64 62.4783 64C61.6522 64 61 64.3 61 64.6375Z"
        fill="#474367"
      />
      <path
        d="M11 60.2989V60.3261C11 60.5887 11.5194 60.8242 12.3635 60.9601C12.883 61.0416 13.5647 60.9963 13.8569 60.8423C14.1491 60.6974 13.9867 60.5072 13.4348 60.4257C13.3375 60.4166 13.2076 60.3532 13.2076 60.3261H12.4934L13.2076 60.3351V60.3261H12.4934L13.2076 60.3351V60.3261C13.2401 60.154 12.7531 60.0091 12.1687 60C11.5519 60 11.0325 60.1268 11 60.2989Z"
        fill="#474367"
      />
      <path
        d="M26.1655 28.3285C26.3777 28.5904 26.5051 28.9475 26.5475 29.3047C26.6112 29.7333 26.972 30.0428 27.3753 29.9952C27.7786 29.9237 28.0545 29.519 27.9909 29.0904C27.906 28.4237 27.6725 27.8047 27.2904 27.3046C27.0357 26.9475 26.5687 26.8999 26.2716 27.1856C25.9532 27.4713 25.9107 27.9713 26.1655 28.3285Z"
        fill="#474367"
      />
    </svg>
  )
}

export default SvgBalloon
