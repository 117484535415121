import { styled, themeGet } from '@lyfta/components-theme'
import { ButtonNew, Flex, Text } from '@lyfta/components-ui'

export const Container = styled(Flex).attrs(() => ({
  mr: 3,
}))`
  flex-direction: column;
  background-color: ${themeGet('colors.white')};
  border-radius: 12px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-origin: border-box;
  background-clip: content-box, border-box;
  cursor: pointer;
  flex-shrink: 0;
  max-height: 250px;
  :hover {
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  }
`

export const BodyContainer = styled(Flex).attrs({})`
  justify-content: center;
  align-items: center;
  background-color: ${themeGet('colors.white')};
  ${props => props.height && `height: ${props.height}px`};
`

export const Controls = styled(Flex).attrs(() => ({
  px: 3,
}))`
  flex: 0;
  justify-content: center;
  align-items: center;
  min-height: 120px;

  svg {
    cursor: pointer;
  }
`
export const SubTitle = styled(Text).attrs(() => ({
  fontSize: 2,
  ml: 2,
  mr: 2,
}))`
  font-weight: 400;
  text-align: center;
  color: ${themeGet('colors.black')};
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 1.23;
`

export const StyledButton = styled(ButtonNew).attrs(() => ({
  p: 0,
}))`
  box-shadow: none;

  &:hover:enabled {
    background: none;
    font-weight: bold;
  }
`
