import { styled } from '@lyfta/components-theme'

import { Button } from '../../BaseComponents'

const Clickable = styled(Button)`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  color: inherit;
  font: inherit;

  line-height: normal;

  font-smoothing: inherit;

  cursor: pointer;
  appearance: none;
  outline: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`

export default Clickable
