import { styled } from '@lyfta/components-theme'

import { Link } from '../../BaseComponents'

export const Container = styled(Link)`
  opacity: 0.3;

  :hover {
    opacity: 1;
  }

  :before,
  :after {
    position: absolute;
    left: 50%;
    content: ' ';
    height: 100%;
    width: 2px;
  }

  :before {
    transform: rotate(45deg);
  }

  :after {
    transform: rotate(-45deg);
  }
`
