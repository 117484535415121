import { styled, themeGet } from '@lyfta/components-theme'

import { Box } from '../../../BaseComponents'

const Title3 = styled(Box)`
  color: ${props => (props.color ? props.color : themeGet('colors.black_900'))};
  font-size: ${themeGet('fontSizes.9')};
  font-weight: 700;
  letter-spacing: 1px;
  line-height: ${themeGet('lineHeights.11')};
`

export default Title3
