import { createAsyncAction } from '../../../helpers'
import {
  generateCreateUser,
  generateDeleteUser,
  generateLoadUserAction,
  generateLoadUsersAction,
  generateUpdateUser,
} from './helpers'

export const LOAD_TEACHER_USERS = createAsyncAction('users/LOAD_TEACHERS')
export const LOAD_TEACHER_USER = createAsyncAction('users/LOAD_TEACHER')
export const CREATE_TEACHER_USER = createAsyncAction(
  'users/CREATE_TEACHER_USER',
)
export const UPDATE_TEACHER_USER = createAsyncAction(
  'users/UPDATE_TEACHER_USER',
)
export const DELETE_TEACHER_USER = createAsyncAction(
  'users/DELETE_TEACHER_USER',
)
export const loadTeacherUsers = generateLoadUsersAction(
  LOAD_TEACHER_USERS,
  'teacher',
)
export const loadTeacher = generateLoadUserAction(LOAD_TEACHER_USER)
export const createTeacher = generateCreateUser({ types: CREATE_TEACHER_USER })
export const updateTeacher = ({ customMessage = null } = {}) => {
  return generateUpdateUser({
    types: UPDATE_TEACHER_USER,
    customMessage,
  })
}
export const deleteTeacher = generateDeleteUser({
  types: DELETE_TEACHER_USER,
  endpoint: '/users',
  deleteType: 'soft',
  updateData: {
    modelName: 'organizations',
  },
})
