/*
 * This is a workaround for a known issue (we should monitor and remove this
 * once the issue is resolved)
 * https://github.com/final-form/react-final-form/issues/130
 *
 * Asana ticket the workaround is trying to fix:
 * https://app.asana.com/0/1133701735540444/1179765034749968/f
 *
 * Update:
 * https://final-form.org/docs/final-form/faq#why-does-final-form-set-my--field-value-to-undefined
 * But sadly it doesn't look like a maintainer's admission that a new prop
 * would be helpful has been followed up on:
 * https://github.com/final-form/react-final-form/issues/130#issuecomment-469711902
 */
export const enforceSubmitClearedFormKeys = (initialValues, values) => {
  const _initialValues = typeof initialValues === 'object' ? initialValues : {}
  const _values = typeof values === 'object' ? values : {}

  const keys = Array.from(
    new Set(
      Object.keys(_initialValues || {}).concat(Object.keys(_values || {})),
    ),
  )

  return keys.reduce((result, key) => {
    let value
    if (_values !== null) {
      value = _values[key]
    }
    let initValue
    if (_initialValues !== null) {
      initValue = _initialValues[key]
    }
    const initDefined = typeof initValue !== 'undefined' && initValue !== null
    const valueDefined = typeof value !== 'undefined' && value !== null
    if (initDefined && valueDefined) {
      // both defined, we may need to apply same logic on this property
      if (
        typeof value === 'object' &&
        !(value instanceof Date) &&
        !(value instanceof Array)
      ) {
        value = enforceSubmitClearedFormKeys(initValue, value)
      }
    } else if (initDefined && !valueDefined) {
      // property was erased
      value = null
    }
    // eslint-disable-next-line no-param-reassign
    result[key] = value
    return result
  }, {})
}
