import { css, styled, themeGet } from '@lyfta/components-theme'
import React from 'react'

import { Next } from '../../Assets'
import { TouchableFlex } from '../../BaseComponents'
import { Box, Flex, Text } from '../../Components'

export const Container = styled(Flex).attrs(() => ({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))`
  color: ${themeGet('colors.primary')};
`

export const MonthAndYear = styled(Text)`
  font-weight: 700;
`

export const RowContainer = styled(Flex).attrs(() => ({
  mb: 2,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))`
  flex: 1;
  width: 100%;
  user-select: none;
`
export const MoveControls = styled(TouchableFlex).attrs(() => ({
  ml: 2,
}))`
  cursor: pointer;
`

export const ControlsContainer = styled(Flex).attrs(() => ({
  mb: 1,
}))`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  width: 100%;
`

export const CalendarGrid = styled(Box).attrs(() => ({ mb: 2 }))`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 2px;
  grid-row-gap: 4px;
  grid-auto-flow: row;
  align-items: center;
  justify-items: center;
  width: 100%;
  user-select: none;
`

export const DayCell = styled(Box)`
  cursor: default;
  width: 100%;
  font-weight: 700;

  ${({ gridColumn }) =>
    gridColumn &&
    css`
      grid-column: ${gridColumn};
    `};
`

export const DateCell = styled(Flex).attrs(() => ({
  width: [36, 48],
  height: [36, 48],
}))`
  cursor: default;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  ${({ active, selected }) =>
    (active || selected) &&
    css`
      background-color: ${p =>
        selected ? themeGet('colors.primary')(p) : themeGet('colors.alto')(p)};

      ${selected && themeGet('buttonShadow.default')};

      color: ${p => selected && themeGet('colors.white')(p)};
      cursor: pointer;
    `};

  ${({ gridColumn }) =>
    gridColumn &&
    css`
      grid-column: ${gridColumn};
    `};
`

export const NextIcon = styled(Flex).attrs(() => ({
  children: <Next />,
  alignItems: 'center',
  justifyContent: 'center',
}))`
  font-size: 1.5em;

  svg,
  path {
    fill: ${themeGet('colors.primary')};
  }
`

export const PrevIcon = styled(NextIcon)`
  transform: rotate(180deg);
`
