import * as React from 'react'

function SvgDiscuss(props) {
  return (
    <svg
      id="svg-discuss"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M9.57258 2.09375C4.85713 2.09375 0.999023 5.36503 0.999023 9.36326C0.999023 10.9626 1.64204 12.5618 2.78518 13.8704L2.42795 17.287C2.42795 17.5051 2.4994 17.7959 2.71374 17.9413C2.85663 18.014 2.99952 18.0867 3.14242 18.0867C3.22612 18.0867 3.28531 18.0617 3.33434 18.0411C3.36901 18.0265 3.39861 18.014 3.4282 18.014L7.42919 16.4147C8.14366 16.5601 8.85812 16.6328 9.57258 16.6328C14.288 16.6328 18.1461 13.3615 18.1461 9.36326C18.1461 5.36503 14.288 2.09375 9.57258 2.09375Z"
        fill="black"
      />
      <path
        d="M14.4343 18.6205C13.6618 18.6205 12.9402 18.5178 12.2792 18.3333C11.4 18.5422 10.4873 18.6521 9.56372 18.6521C9.21175 18.6521 8.86029 18.6372 8.50949 18.6076C10.0509 19.8646 12.1411 20.6398 14.4343 20.6398C15.148 20.6398 15.8618 20.5672 16.5755 20.4218L20.5724 22.0211C20.6019 22.0211 20.6315 22.0335 20.6661 22.0481C20.7151 22.0688 20.7742 22.0937 20.8578 22.0937C21.0006 22.0937 21.1433 22.0211 21.2861 21.9484C21.5002 21.803 21.5716 21.5122 21.5716 21.2941L21.2147 17.8774C22.3567 16.5689 22.999 14.9696 22.999 13.3703C22.999 11.1675 21.8291 9.1853 19.9906 7.84931C20.0797 8.33824 20.1264 8.84356 20.1264 9.36326C20.1264 9.80706 20.0924 10.2404 20.0269 10.6619C20.6558 11.4907 21.0011 12.4382 21.0011 13.3703C21.0011 14.4203 20.5761 15.5565 19.7164 16.5417L19.1357 17.2071L19.361 19.3645L16.7608 18.3241L16.1808 18.4423C15.5944 18.5617 15.0128 18.6205 14.4343 18.6205Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgDiscuss
