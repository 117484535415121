import * as React from 'react'

function SvgBbfc(props) {
  return (
    <svg
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.706 20.2159L13.9536 1.26138C13.5463 0.474074 12.8222 0 12.0077 0C11.1931 0 10.469 0.474074 10.0617 1.26138L0.309374 20.2159C-0.0979164 21.0032 -0.0979164 21.9513 0.309374 22.7386C0.716665 23.5259 1.44074 24 2.25532 24H21.7525C22.5671 24 23.2911 23.5259 23.6984 22.7386C24.1057 21.9429 24.1057 21.0032 23.706 20.2159Z"
        fill="black"
      />
      <path
        d="M23.3741 20.4275L13.6293 1.47302C13.2899 0.812698 12.6865 0.42328 12.0077 0.42328C11.3289 0.42328 10.7255 0.812698 10.3861 1.47302L0.633699 20.4275C0.29429 21.0878 0.29429 21.8751 0.633699 22.527C0.973108 23.1873 1.5765 23.5767 2.25532 23.5767H21.7525C22.4313 23.5767 23.0347 23.1873 23.3741 22.527C23.7135 21.8667 23.7135 21.0794 23.3741 20.4275Z"
        fill="white"
      />
      <path
        d="M12.8147 1.99788C12.6337 1.65079 12.3169 1.47302 12.0077 1.47302C11.6984 1.47302 11.3817 1.65079 11.2006 1.99788L1.44828 20.9524C1.08625 21.655 1.53879 22.527 2.25532 22.527H21.7525C22.469 22.527 22.9216 21.655 22.5595 20.9524L12.8147 1.99788Z"
        fill="#0AC700"
      />
      <path
        d="M15.0171 11.564V16.8466C15.0096 17.7947 15.0096 18.582 14.2101 19.3439C13.4483 20.0889 12.4602 20.1651 11.9549 20.1651C11.5023 20.1651 11.0573 20.1058 10.635 19.9365C9.74495 19.5894 9.26977 18.8698 9.0963 18.2349C8.99825 17.8624 8.99825 17.6085 8.9907 16.5587V11.5556H10.3861V16.7111C10.3861 17.0667 10.3785 17.4138 10.4841 17.7355C10.7707 18.5397 11.5551 18.6413 12.0001 18.6413C12.2189 18.6413 12.7845 18.6328 13.1994 18.2265C13.6142 17.8116 13.6142 17.3376 13.6142 16.6349V11.564H15.0171Z"
        fill="white"
      />
    </svg>
  )
}

export default SvgBbfc
