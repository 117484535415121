import lyftaApp from '@lyfta/components-data/src/Store/Reducers'
import studentApp from '@lyfta/components-student/src/Store/Reducers'
import { connectRouter } from 'connected-react-router/immutable'
import { combineReducers } from 'redux'

import app from './app'

export default history =>
  combineReducers({
    router: connectRouter(history),
    ...lyftaApp,
    ...studentApp,
    app,
  })
