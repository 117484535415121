import { hubSpotAppId } from '@lyfta/components-config'
import { getViewer } from '@lyfta/components-data/src/Store/Selectors/viewer'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTrackingCode } from 'react-hubspot-tracking-code-hook'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

export const useHubSpotAnalytics = withHubspot => {
  const location = useLocation()
  const viewer = useSelector(getViewer)
  const { setPathPageView, setIdentity } = useTrackingCode()

  useEffect(() => {
    if (withHubspot) {
      if (viewer.email) {
        setIdentity(viewer.email)
      }
    }
  }, [viewer])

  useEffect(() => {
    if (withHubspot) {
      setPathPageView(location.pathname)
    }
  }, [location])

  return (
    withHubspot &&
    hubSpotAppId && (
      <Helmet>
        <script
          async
          defer
          id="hs-script-loader"
          src={`//js.hs-scripts.com/${hubSpotAppId}.js`}
          type="text/javascript"
        />
      </Helmet>
    )
  )
}
