import { styled } from '@lyfta/components-theme'
import { Flex, Text } from '@lyfta/components-ui'
import { themeGet as theme } from '@styled-system/theme-get'

import {
  LessonButton as BaseLessonButton,
  LessonInfo as BaseLessonInfo,
  LessonsContainer as BaseLessonsContainer,
  LessonsScreen as BaseLessonsScreen,
} from './_styles'
import { LogoRectangle } from './LogoRectangle'

export const BaseLyftaLogo = styled(LogoRectangle)`
  position: absolute;
  left: 32px;
  top: 32px;
  transform: scale(0.4);
  transform-origin: top left;
`

export {
  Description,
  ExploreIconContainer,
  LessonIconContainer,
  LessonInfoText,
  LessonExploreText,
  LessonPassphrase,
  LessonText,
  MainTitle,
  LessonsBackground,
  Logo,
  Header,
} from './_styles'

export const LessonsScreen = styled(BaseLessonsScreen).attrs({
  justifyContent: 'center',
  backgroundSize: 'cover',
})`
  overflow: auto;
`

export const LessonInfo = styled(BaseLessonInfo)`
  & > p {
    background-color: transparent;
  }
`

export const LyftaLogo = styled(BaseLyftaLogo).attrs({})`
  background-size: contain;
`

export const LessonsContainer = styled(BaseLessonsContainer)`
  width: 100%;
  max-height: 98vh;
  overflow: auto;
  overflow-y: scroll;
`

export const LessonSelectorMenu = styled(Flex)`
  position: absolute;
  text-align: right;
  right: 20px;
  top: 25px;
`

export const LessonButton = styled(BaseLessonButton)`
  text-align: left;
  text-transform: none;
  position: relative;
  border: 5px solid white;
  cursor: pointer;

  :hover {
    background: #eee;
  }

  span {
    line-height: 20px;
    padding: 8px;
    margin: 8px;
    padding-left: 0;
    display: block;
    font-size: 24px;
  }
`

export const ExploreButton = styled(LessonButton).attrs({
  mb: 3,
})``
export const DescriptionText = styled(Text).attrs({
  mb: 15,
})`
  text-align: center;
  color: ${theme('colors.font.outline')};
`

export const Passphrase = styled.p`
  font-size: 18px;
  margin: 0;
  padding: 0;
  width: 100%;
`

LessonsScreen.displayName = 'LessonsScreen'
LessonInfo.displayName = 'LessonInfo'
LyftaLogo.displayName = 'LyftaLogo'
LessonsContainer.displayName = 'LessonsContainer'
LessonSelectorMenu.displayName = 'LessonSelectorMenu'
LessonButton.displayName = 'LessonButton'
ExploreButton.displayName = 'ExploreButton'
DescriptionText.displayName = 'DescriptionText'
Passphrase.displayName = 'Passphrase'
