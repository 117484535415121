import { getTranslatedField, getTranslation } from '@lyfta/components-i18n'
import { filter, flatten, get, map, sortBy } from 'lodash'
import { all, put, select, takeLatest } from 'redux-saga/effects'

import { loadExperiences } from '../Actions/experiences'
import {
  LOAD_LESSONS,
  LOAD_STUDENT_LESSONS,
  lessonsData,
} from '../Actions/lessons'
import { questTrackerData } from '../Actions/questTracker'
import { getCurrentLesson, getLessons } from '../Selectors/lessons'

const getButtonActionLabel = task => {
  let buttonAction = getTranslation('quest.gotIt')

  if (task && task.content) {
    if (
      task.content.type === '360_video' ||
      task.content.type === 'story_world'
    )
      buttonAction = getTranslation('quest.actions.explore')
    if (
      task.content.type === 'video' ||
      task.content.type === 'article' ||
      task.content.type === 'sound_clip'
    )
      buttonAction = getTranslation('quest.actions.view')
    if (task.content.type === 'scene')
      buttonAction = getTranslation('quest.actions.go')
    if (task.content.type === 'scene')
      buttonAction = getTranslation('quest.actions.go')
  }

  if (task.type === 'information') {
    buttonAction = getTranslation('quest.gotIt')
  }
  if (task.type === 'discussion') {
    buttonAction = getTranslation('quest.doneTask')
  }
  if (task.type === 'rating') {
    buttonAction = getTranslation('quest.doneTask')
  }
  if (task.type === 'multiple_choice') {
    buttonAction = getTranslation('quest.doneTask')
  }

  return buttonAction
}
const getLessonPlanActivity = lessonPlan => {
  const name = getTranslatedField(lessonPlan, 'name')
  const description = getTranslatedField(lessonPlan, 'description')
  const teacherNotes = getTranslatedField(lessonPlan, 'teacherNotes')

  return {
    id: `intro-${get(lessonPlan, 'id')}`,
    title: getTranslation('quest.welcome'),
    name,
    description,
    teacherNotes,
    tasks: [],
    type: get(lessonPlan, 'type'),
    duration: 0,
    order: -1,
    selected: true,
    complete: false,
    shown: false,
    intro: true,
  }
}
export const getTasks = (lessonPlan, experiences) => {
  const allTasks = map(lessonPlan.activities, a =>
    map(a.tasks, task => {
      const name = getTranslatedField(task, 'name')
      const description = getTranslatedField(task, 'description')
      const i18NProperties = getTranslatedField(task, 'i18NProperties')

      const taskExperiences = filter(
        experiences,
        experience => get(experience, 'entity.id') === task?.id,
      )

      const answerData =
        taskExperiences.length && taskExperiences[0] ? taskExperiences[0] : null

      const { content } = task

      return {
        id: get(task, 'id'),
        name,
        description,
        i18NProperties,
        type: get(task, 'type'),
        actionType: get(task, 'actionType'),
        callToAction: getButtonActionLabel(task),
        activityId: a.id,
        content,
        order: get(task, 'order'),
        properties: {
          choiceStyle: get(task.properties, 'choiceStyle', 'single'),
          ratingStyle: get(task.properties, 'ratingStyle', 'star'),
        },
        experiences: taskExperiences,
        answer: answerData,
        open: false,
        complete: false,
        selected: false,
      }
    }),
  )

  return flatten(allTasks)
}
const getActivities = lessonPlan => {
  return sortBy(
    map(get(lessonPlan, 'activities', []), activity => {
      const name = getTranslatedField(activity, 'name')
      const description = getTranslatedField(activity, 'description')
      const teacherNotes = getTranslatedField(activity, 'teacherNotes')

      return {
        id: get(activity, 'id'),
        title: name,
        description,
        teacherNotes,
        tasks: map(sortBy(activity.tasks, 'order'), t => t.id),
        duration: get(activity, 'duration'),
        type: get(activity, 'type'),
        isNew: true,
        order: get(activity, 'order'),
        selected: false,
        complete: false,
        shown: false,
        intro: false,
      }
    }),
    'order',
  )
}
export const getActivityList = lessonPlan => {
  const intro = getLessonPlanActivity(lessonPlan)
  const newActivities = getActivities(lessonPlan)
  return [intro, ...newActivities]
}

function* orderLessonsData() {
  const lessons = yield select(getLessons)
  if (!lessons) return

  lessons.sort((a, b) => {
    const nameA = getTranslatedField(a.lessonPlan, 'name') || ''
    const nameB = getTranslatedField(b.lessonPlan, 'name') || ''
    return nameA.localeCompare(nameB)
  })

  yield put(lessonsData.load(lessons.map(lesson => lesson.id)))
}

function* fireQuestTrackerData() {
  const lesson = yield select(getCurrentLesson)
  if (!lesson) return
  const { id: lessonId } = lesson
  const lessonPlan = lesson?.lessonPlan
  const activities = getActivityList(lessonPlan, [])
  const tasks = getTasks(lessonPlan, [])
  const currentActivityId = activities?.[0]?.id

  yield put(
    questTrackerData.load({
      previousActivityId: null,
      currentActivityId,
      lessonId,
      lessonPlan,
      activities,
      tasks,
    }),
  )

  yield put(loadExperiences(lessonId))
}

export default function* root() {
  yield all([takeLatest(LOAD_STUDENT_LESSONS.SUCCESS, fireQuestTrackerData)])
  yield all([takeLatest(LOAD_LESSONS.SUCCESS, orderLessonsData)])
}
