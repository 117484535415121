import React from 'react'

function SvgJournal(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <path
        d="M21.2121 25.9059C20.4147 25.9375 19.6252 26.0085 18.8516 26.1269C19.4674 27.5005 20.2963 28.4872 21.2121 28.8504V25.9059Z"
        fill="#050B15"
      />
      <path
        d="M17.7344 19.5284H21.2081V16.355C20.2212 16.3235 19.2423 16.2208 18.287 16.0551C17.987 17.105 17.7897 18.2812 17.7344 19.5284Z"
        fill="#050B15"
      />
      <path
        d="M18.2949 24.6044C19.2502 24.4466 20.2291 24.3597 21.2081 24.3282V21.1074H17.7344C17.7897 22.3625 17.9949 23.5467 18.2949 24.6044Z"
        fill="#050B15"
      />
      <path
        d="M18.8516 14.5152C19.6253 14.6336 20.4148 14.7047 21.2121 14.7362V11.7997C20.2884 12.1628 19.4673 13.1495 18.8516 14.5231V14.5152Z"
        fill="#050B15"
      />
      <path
        d="M16.7424 15.7228C16.1503 15.5728 15.566 15.3992 15.0055 15.1939C14.0976 16.4333 13.5055 17.9174 13.3555 19.5279H16.1581C16.2134 18.1622 16.4187 16.8754 16.7424 15.7228Z"
        fill="#050B15"
      />
      <path
        d="M16.168 26.7372C16.7522 27.274 17.4154 27.7239 18.1338 28.087C17.8102 27.5976 17.518 27.045 17.2654 26.4371C16.8944 26.524 16.5233 26.6266 16.168 26.7372Z"
        fill="#050B15"
      />
      <path
        d="M27.833 13.8989C27.2488 13.3621 26.5856 12.9121 25.8672 12.549C26.1909 13.0384 26.483 13.591 26.7356 14.1989C27.1067 14.112 27.4778 14.0094 27.833 13.8989Z"
        fill="#050B15"
      />
      <path
        d="M18.1299 12.549C17.4115 12.9043 16.7562 13.3621 16.1641 13.8989C16.5273 14.0094 16.8904 14.112 17.2614 14.1989C17.5141 13.591 17.8062 13.0385 18.1299 12.549Z"
        fill="#050B15"
      />
      <path
        d="M16.1581 21.1075H13.3555C13.5055 22.7258 14.0976 24.2178 15.0134 25.457C15.5739 25.2597 16.1502 25.0781 16.7423 24.936C16.4186 23.7756 16.2055 22.481 16.1502 21.1074L16.1581 21.1075Z"
        fill="#050B15"
      />
      <path
        d="M22.7889 14.7305C23.5863 14.699 24.3758 14.6279 25.1494 14.5095C24.5337 13.136 23.7047 12.1492 22.7889 11.7861V14.7305Z"
        fill="#050B15"
      />
      <path
        d="M27.2501 24.9359C27.8421 25.0859 28.4185 25.2595 28.979 25.4569C29.8948 24.2175 30.4948 22.7256 30.6369 21.1074H27.8343C27.779 22.481 27.5737 23.7756 27.2422 24.936L27.2501 24.9359Z"
        fill="#050B15"
      />
      <path
        d="M27.2617 15.723C27.5854 16.8755 27.7906 18.1622 27.8459 19.5279H30.6485C30.4985 17.9175 29.9064 16.4334 28.9985 15.1939C28.438 15.3992 27.8538 15.573 27.2617 15.723Z"
        fill="#050B15"
      />
      <path
        d="M35.4211 6.10881H7V42.4212C7 43.2896 7.71053 44 8.57895 44H35.4211C36.2895 44 37 43.2896 37 42.4212V7.68761C37 6.81927 36.2895 6.10881 35.4211 6.10881ZM31.0792 40.053H12.9213C12.4871 40.053 12.1318 39.6978 12.1318 39.2636C12.1318 38.8295 12.4871 38.4742 12.9213 38.4742H31.0792C31.5133 38.4742 31.8686 38.8295 31.8686 39.2636C31.8686 39.6978 31.5134 40.053 31.0792 40.053ZM14.8947 35.3166C14.8947 34.8825 15.25 34.5272 15.6842 34.5272H28.3158C28.75 34.5272 29.1053 34.8825 29.1053 35.3166C29.1053 35.7508 28.75 36.106 28.3158 36.106H15.6842C15.25 36.106 14.8947 35.7508 14.8947 35.3166ZM22 30.5802C16.3394 30.5802 11.7368 25.9781 11.7368 20.318C11.7368 14.6579 16.3394 10.0558 22 10.0558C27.6606 10.0558 32.2632 14.6579 32.2632 20.318C32.2632 25.9781 27.6606 30.5802 22 30.5802Z"
        fill="#050B15"
      />
      <path
        d="M22.7889 21.1074V24.3282C23.7679 24.3597 24.7468 24.4466 25.7021 24.6044C26.01 23.5466 26.2074 22.3625 26.2626 21.1074L22.7889 21.1074Z"
        fill="#050B15"
      />
      <path
        d="M22.7889 28.8424C23.7126 28.4793 24.5337 27.4925 25.1494 26.1189C24.3758 26.0005 23.5863 25.9295 22.7889 25.8979V28.8424Z"
        fill="#050B15"
      />
      <path
        d="M25.71 16.0552C24.7547 16.221 23.7758 16.3236 22.7889 16.3552V19.5286H26.2626C26.2074 18.2813 26.01 17.1051 25.71 16.0552Z"
        fill="#050B15"
      />
      <path
        d="M25.8672 28.0777C26.5856 27.7225 27.2409 27.2647 27.833 26.7279C27.4698 26.6174 27.1067 26.5147 26.7356 26.4279C26.483 27.0358 26.1909 27.5883 25.8672 28.0777Z"
        fill="#050B15"
      />
      <path
        d="M33.8424 4.52931V0.787549C33.8424 0.282333 33.3687 -0.0965795 32.8714 0.0218095L13.7109 4.52918L33.8424 4.52931Z"
        fill="#050B15"
      />
    </svg>
  )
}

export default SvgJournal
