import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'

import { Heart } from '../../Assets/Sidebar'
import Graph from '../../Assets/Svg/Graph'
import Not from '../../Assets/Svg/Notification'
import { Flex } from '../Flex'
import { Icon } from '../Icon'
import { InfoContainer } from './styles'

export const InfoCount = ({
  direction,
  type,
  subscribedCount,
  viewedCount,
  ...props
}) => {
  return (
    <Flex flexDirection={direction} flexWrap="wrap" {...props}>
      <Flex my={2} mb={2} mr={5}>
        <InfoContainer>
          {type === 'favorite' ? (
            <Icon
              glyph={Heart}
              width={16}
              height={16}
              mr={2}
              fill="secondary_130"
            />
          ) : (
            <Icon
              glyph={Not}
              width={16}
              height={16}
              mr={2}
              fill="secondary_130"
            />
          )}

          <I18n
            i18nKey={`${type}.subscribedCount`}
            options={{ count: subscribedCount || 0 }}
          />
        </InfoContainer>
      </Flex>
      <Flex>
        <InfoContainer color="secondary_60">
          <Icon
            glyph={Graph}
            width={16}
            height={16}
            mr={2}
            fill="secondary_60"
          />
          <I18n
            i18nKey={`${type}.viewedCount`}
            options={{ count: viewedCount || 0 }}
          />
        </InfoContainer>
      </Flex>
    </Flex>
  )
}

InfoCount.defaultProps = {
  subscribedCount: 0,
  viewedCount: 0,
  direction: 'row',
  type: 'favorite',
}

InfoCount.propTypes = {
  subscribedCount: PropTypes.number,
  viewedCount: PropTypes.number,
  direction: PropTypes.string,
  type: PropTypes.string,
}

export default InfoCount
