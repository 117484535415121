import { denormalize } from '@lyfta/components-data'
import { getData } from '@lyfta/components-data/src/Store/Selectors/data'
import { createSelector } from 'reselect'

export const getState = state => state.experiences

export const getExperiences = createSelector(
  getData,
  getState,
  (data, state) => denormalize(data, 'experiences', state.experiences) || [],
)

export const getIsLoaded = createSelector(getState, state => state.isLoaded)
export const getIsLoading = createSelector(getState, state => state.isLoading)
export const getError = createSelector(getState, state => state.error)
