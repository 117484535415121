import { Translation } from '@lyfta/components-i18n'
import { isArray } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { Span } from '../../../BaseComponents/index'
import { Container } from './styles'

const FieldErrorNew = ({ meta }) => {
  if (meta.touched && meta.error) {
    let error = isArray(meta.error) ? meta.error[0] : meta.error

    if (typeof error === 'object') {
      error = error.message
    }

    return (
      <Translation>
        {t => (
          <Container className="fielderrornew-container">
            <Span>{t(error)}</Span>
          </Container>
        )}
      </Translation>
    )
  }

  return null
}

FieldErrorNew.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    touched: PropTypes.bool.isRequired,
  }).isRequired,
}

export default FieldErrorNew
