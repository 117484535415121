import { useAnimation } from 'framer-motion'
import PT from 'prop-types'
import React, { useEffect, useState } from 'react'

import Component from './Component'

const Tasks = ({
  advanceType,
  currentTask,
  currentActivity,
  lessonCompleted,
  menuVisible,
  pauseWebGL,
  resumeWebGL,
  ...props
}) => {
  if (!currentTask || !menuVisible) return null
  if (lessonCompleted) return null

  const [animationComplete, setAnimationComplete] = useState(false)
  const taskNavBarControls = useAnimation()
  const navAnimation = {
    animate: taskNavBarControls,
    initial: { x: advanceType === 'forward' ? '120%' : '-120%' },
  }

  useEffect(() => {
    pauseWebGL()
    taskNavBarControls
      .start({
        x: '0%',
        transition: {
          ease: 'easeInOut',
          duration: 0.4,
          type: 'spring',
          stiffness: 120,
          bounceStiffness: 1,
          damping: 12,
          min: 0,
          max: 5,
        },
      })
      .then(() => {
        resumeWebGL()
        setAnimationComplete(true)
      })
  }, [currentActivity?.id, advanceType])

  return (
    <Component
      animation={navAnimation}
      animationComplete={animationComplete}
      {...props}
    />
  )
}
Tasks.defaultProps = {
  advanceType: null,
  currentActivity: null,
  currentTask: null,
  lessonCompleted: false,
  menuVisible: false,
}
Tasks.propTypes = {
  advanceType: PT.string,
  currentActivity: PT.object,
  currentTask: PT.object,
  lessonCompleted: PT.bool,
  menuVisible: PT.bool,
  pauseWebGL: PT.func.isRequired,
  resumeWebGL: PT.func.isRequired,
}
export default Tasks
