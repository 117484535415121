import { createAsyncAction } from '../../../helpers'
import {
  generateCreateUser,
  generateDeleteUser,
  generateLoadUserAction,
  generateLoadUsersAction,
  generateUpdateUser,
} from './helpers'

export const LOAD_PARENT_USERS = createAsyncAction('users/LOAD_PARENTS')
export const LOAD_PARENT_USER = createAsyncAction('users/LOAD_PARENT_USER')
export const CREATE_PARENT_USER = createAsyncAction('users/CREATE_PARENT_USER')
export const DELETE_PARENT_USER = createAsyncAction('users/DELETE_PARENT_USER')
export const UPDATE_PARENT_USER = createAsyncAction('users/UPDATE_PARENT_USER')

export const loadParentUsers = generateLoadUsersAction(
  LOAD_PARENT_USERS,
  'parent',
)
export const loadParentUser = generateLoadUserAction(LOAD_PARENT_USER)
export const createParent = generateCreateUser({ types: CREATE_PARENT_USER })
export const updateParent = ({ customMessage = null } = {}) => {
  return generateUpdateUser({
    types: UPDATE_PARENT_USER,
    customMessage,
    endpoint: '/users',
  })
}

export const deleteParent = generateDeleteUser({ types: DELETE_PARENT_USER })

export const updateParentUser = updateParent

export const requestCreateParent = (user, relationships) => ({
  type: CREATE_PARENT_USER.REQUEST_PERFORM,
  payload: { user, relationships },
})
