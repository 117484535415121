import { css, height, styled, themeGet, width } from '@lyfta/components-theme'

import { Arrow, Flex } from '../../../Components'

export const PaginationWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: flex-start;
  padding-right: 36px;
  align-items: center;
  margin-top: ${props => (props.isAtBottom ? '16px' : '24px')};
  min-height: 44px;
  max-height: 44px;
  border-top: 1px solid ${themeGet('colors.neutral_400')};
  ${height};
  ${width};
`

export const RightSide = styled(Flex)`
  flex: 1;
  align-items: center;
  justify-content: center;
`
export const NumberContainer = styled(Flex).attrs(() => ({
  mx: 1,
}))`
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  letter-spacing: 1.5px;
  font-weight: 700;

  color: ${themeGet('colors.secondary_100')};

  cursor: pointer;

  &:hover {
    background-color: ${themeGet('colors.secondary_100')};
    color: ${themeGet('colors.white')};
    border-radius: 50%;
  }
`
export const Current = styled(Flex).attrs(() => ({}))`
  ${props => {
    return (
      props.active &&
      css`
        background-color: ${themeGet('colors.secondary_100')};
        color: ${themeGet('colors.white')};
        border-radius: 50%;
      `
    )
  }}
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
`

export const Next = styled(Arrow).attrs({
  direction: 'right',
  size: 10,
  ml: 2,
  color: 'secondary_100',
})``

export const ArrowContainer = styled(Flex).attrs({})`
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: ${themeGet('colors.secondary_100')};
  letter-spacing: 1.5px;
  cursor: pointer;
`
export const ArrowText = styled(Flex).attrs({})`
  &:hover {
    text-decoration: underline;
  }
`

export const Previous = styled(Arrow).attrs({
  direction: 'left',
  size: 10,
  mr: 2,
  color: 'secondary_100',
})``

export const PageSize = styled(Flex).attrs(() => ({
  mr: 4,
}))`
  color: ${themeGet('colors.black_700')};
`

export const Size = styled(Flex).attrs(() => ({
  ml: 2,
}))`
  justify-content: space-between;
  align-items: center;
  color: ${themeGet('colors.dustyGray')};

  > div {
    margin-left: 7px;
    margin-bottom: 2px;
    border-color: ${themeGet('colors.dustyGray')};
  }
`
