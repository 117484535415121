import { styled, themeGet } from '@lyfta/components-theme'

import { RadioSelected, RadioUnselected } from '../../../Assets/Svg'
import { Box } from '../../Box'
import { Flex } from '../../Flex'

export const StyledRadioIcon = styled(Box).attrs(props => ({
  as: props.checked ? RadioSelected : RadioUnselected,
}))`
  flex-shrink: 0;
  margin-right: 16px;
`

export const StyledRadioInput = styled(Box).attrs(() => ({
  as: 'input',
  type: 'radio',
}))`
  appearance: none;
`

export const Label = styled(Flex).attrs(() => ({
  as: 'label',
  mb: 2,
}))`
  color: ${themeGet('colors.black_900')};
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-size: 16px; // Body 1
  font-weight: 400; // Body 1
  letter-spacing: 0.5px; // Body 1
  line-height: 24px; // Body 1
  max-width: fit-content;
`
