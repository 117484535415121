/* eslint-disable max-classes-per-file */
import { DateTime } from '@lyfta/components-i18n'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { DateUtils } from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { Field } from 'react-final-form'

import { Description } from '../AnimatedInput/styles'
import { Opener } from '../AnimatedSelect/styles'
import FieldError from '../FieldError'
import { Container, Label, Wrapper } from './styles'
import YearMonthForm from './YearMonthForm'

const FORMAT = 'dd/LL/yyyy'
const FORMAT_RANGE = 'dd/LL/yyyy'

class DayPicker extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      from: get(props, 'input.value.from'),
      to: get(props, 'input.value.to'),
      month: get(props, 'input.value', get(props, 'input.value.to')),
    }
  }

  formatDate = date => {
    const { ranged } = this.props
    if (ranged) {
      const { from, to } = DateUtils.addDayToRange(date, this.state)

      if (from && to) {
        if (from.getTime() === to.getTime()) {
          return DateTime.fromJSDate(from).toFormat(FORMAT)
        }

        return `${DateTime.fromJSDate(from).toFormat(
          FORMAT_RANGE,
        )}-${DateTime.fromJSDate(to).toFormat(FORMAT_RANGE)}`
      }

      return ''
    }

    if (typeof date === 'string') return DateTime.fromISO(date).toFormat(FORMAT)

    return DateTime.fromJSDate(date).toFormat(FORMAT)
  }

  formatValue = date => {
    const { ranged } = this.props

    if (ranged) {
      const { from, to } = date

      if (from && to) {
        if (from.getTime() === to.getTime()) {
          return DateTime.fromJSDate(from).toFormat(FORMAT)
        }

        return `${DateTime.fromJSDate(from).toFormat(
          FORMAT_RANGE,
        )}-${DateTime.fromJSDate(to).toFormat(FORMAT_RANGE)}`
      }

      return ''
    }

    if (date) {
      if (typeof date === 'string')
        return DateTime.fromISO(date).toFormat(FORMAT)
      return DateTime.fromJSDate(date).toFormat(FORMAT)
    }

    return ''
  }

  handleDayChange = input => (date, { disabled } = {}) => {
    const { ranged } = this.props

    if (disabled) {
      return
    }

    if (ranged) {
      const range = DateUtils.addDayToRange(date, this.state)
      if (
        (range.from && range.to) ||
        (range.from === null && range.to === null)
      ) {
        input.onChange(range)
      }
      this.setState(range)
    } else {
      input.onChange(date)
    }
  }

  handleYearMonthChange = month => this.setState({ month })

  dayPickerProps = () => {
    const { from, to, month } = this.state
    const {
      input,
      after,
      before,
      disabled,
      disabledDaysOfWeek,
      withYearMonthSelector,
      ranged,
    } = this.props
    const modifiers = { start: from, end: to }

    return {
      selectedDays: ranged ? [from, { from, to }] : input.value,
      modifiers,
      month,
      onDayClick: this.handleDayChange(input),
      disabledDays: { after, before, daysOfWeek: disabledDaysOfWeek },
      isDisabled: disabled,
      captionElement: withYearMonthSelector
        ? ({ date, localeUtils }) => (
            <YearMonthForm
              date={date}
              localeUtils={localeUtils}
              onChange={this.handleYearMonthChange}
            />
          )
        : undefined,
    }
  }

  inputProps = () => {
    const { fieldRef, disabled } = this.props
    return {
      ref: fieldRef,
      readOnly: true,
      autoComplete: 'off',
      placeholder: null,
      disabled,
    }
  }

  render() {
    const {
      meta,
      label,
      input,
      mt,
      focused,
      description,
      onLabelClick,
      ranged,
    } = this.props

    const hasError = meta.touched && meta.error
    const active = focused || !isEmpty(input.value.toString())

    return (
      <Wrapper mt={mt}>
        <Container active={active} error={hasError ? 1 : 0}>
          {label && (
            <Label active={active} onClick={onLabelClick}>
              {label}
            </Label>
          )}

          <DayPickerInput
            active={active}
            clickUnselectsDay={!ranged}
            dayPickerProps={this.dayPickerProps()}
            formatDate={this.formatDate}
            hideOnDayClick={!ranged}
            inputProps={this.inputProps()}
            value={this.formatValue(input.value)}
            onDayChange={ranged ? noop : this.handleDayChange(input)}
          />

          <Opener active={active} onClick={onLabelClick} />
        </Container>
        {hasError ? (
          <FieldError meta={meta} />
        ) : (
          description && (
            <Description active={active}>{description}</Description>
          )
        )}
      </Wrapper>
    )
  }
}

DayPicker.defaultProps = {
  ranged: false,
  description: null,
  disabled: false,
  label: null,
  after: undefined,
  before: undefined,
  mt: null,
  disabledDaysOfWeek: undefined,
  withYearMonthSelector: false,
  onLabelClick: () => null,
}

DayPicker.propTypes = {
  after: PropTypes.object,
  before: PropTypes.object,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  disabledDaysOfWeek: PropTypes.arrayOf(PropTypes.number),
  fieldRef: PropTypes.object.isRequired,
  focused: PropTypes.bool.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  meta: PropTypes.object.isRequired,
  mt: PropTypes.number,
  ranged: PropTypes.bool,
  withYearMonthSelector: PropTypes.bool,
  onLabelClick: PropTypes.func,
}

class AnimatedInput extends PureComponent {
  textField = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      focused: false,
    }
  }

  handleFocus = () => {
    this.setState({ focused: true })
  }

  handleBlur = () => {
    this.setState({ focused: false })
  }

  handleLabelClick = () => {
    this.textField.current.focus()
  }

  render() {
    const { label, name, placeholder, description, ...rest } = this.props
    const { focused } = this.state

    return (
      <Field
        component={DayPicker}
        description={description}
        fieldRef={this.textField}
        focused={focused}
        label={label}
        name={name}
        placeholder={placeholder}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onLabelClick={this.handleLabelClick}
        {...rest}
      />
    )
  }
}

AnimatedInput.defaultProps = {
  label: '',
  placeholder: '',
  description: '',
}

AnimatedInput.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
}

export default AnimatedInput
