import { quest } from '@lyfta/components-student/src/Store/Actions/ui'
import { useAutoRef } from '@lyfta/components-ui/src/Helpers/autoRef'
import PropTypes from 'prop-types'
import Circle from 'rc-progress/es/Circle'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { LoadingContainer, LoadingLayer } from './styles'

export const rocketFlightTimeMs = 2500
export const rocketDepartureTimeMs = 1500
export const earthRestoreTimeMs = 200

const initialTopValue = 150

function Loader(props) {
  const { progression, isRocketLoading, isRocketLoaded } = props
  const progressionPct = `${Math.ceil(progression * 100)}`

  const [topValue, setTopValue] = useState(initialTopValue)

  const autoTopValue = useAutoRef(topValue)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(quest.toggleSpecialLoading(isRocketLoading))

    return () => {
      dispatch(quest.toggleSpecialLoading(false))
    }
  }, [isRocketLoading])

  useEffect(() => {
    // stage 0
    if (!isRocketLoading) {
      setTopValue(initialTopValue)

      return undefined
    }
    if (isRocketLoaded) {
      return undefined
    }

    const animFrame = {
      value: null,
    }

    async function playAudio() {
      const audio = new Audio('/rocket.wav')
      audio.type = 'audio/wav'

      try {
        await audio.play()
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`Failed to play...${err}`)
      }
    }

    if (!props.audioShouldBeMuted) {
      playAudio().catch()
    }

    const start = performance.now()

    const startTopValue = 150
    const endTopValue = 50
    const topValueRange = endTopValue - startTopValue

    setTopValue(startTopValue)

    const duration = rocketFlightTimeMs / 1000 // seconds

    const rafHandler = () => {
      const now = performance.now()

      const elapsed = (now - start) / 1000

      const ratio = Math.min(elapsed / duration, 1)

      setTopValue(startTopValue + ratio * topValueRange)

      if (elapsed < duration) {
        animFrame.value = requestAnimationFrame(rafHandler)
      }
    }

    animFrame.value = requestAnimationFrame(rafHandler)

    return () => {
      if (animFrame.value !== null) {
        cancelAnimationFrame(animFrame.value)
      }
    }
  }, [isRocketLoading, isRocketLoaded])

  const [alpha] = useState(0)

  useEffect(() => {
    // stage 1
    if (isRocketLoaded && isRocketLoading) {
      const animFrame = {
        value: null,
      }

      const start = performance.now()

      const startTopValue = autoTopValue.current
      const endTopValue = -125
      const topValueRange = endTopValue - startTopValue

      setTopValue(startTopValue)

      const duration = rocketDepartureTimeMs / 1000 // seconds

      const rafHandler = () => {
        const now = performance.now()

        const elapsed = (now - start) / 1000

        const ratio = Math.min(elapsed / duration, 1)

        setTopValue(startTopValue + ratio * topValueRange)

        if (elapsed < duration) {
          animFrame.value = requestAnimationFrame(rafHandler)
        }
      }

      animFrame.value = requestAnimationFrame(rafHandler)

      return () => {
        if (animFrame.value !== null) {
          cancelAnimationFrame(animFrame.value)
        }
      }
    }

    return undefined
  }, [isRocketLoading, isRocketLoaded])

  return (
    <LoadingLayer alpha={alpha} isRocketLoading={isRocketLoading}>
      {isRocketLoading && (
        <LoadingContainer
          isRocketLoading={isRocketLoading}
          top={`${topValue.toFixed(2)}%`}
        >
          <img alt="Rocket" src="/Rocket.gif" />
        </LoadingContainer>
      )}
      {!isRocketLoading && (
        <LoadingContainer isRocketLoading={false}>
          <Circle
            percent={progressionPct}
            strokeColor="#FFF"
            strokeWidth="5"
            trailColor="#A1A1A1"
            trailWidth="5"
          />
        </LoadingContainer>
      )}
    </LoadingLayer>
  )
}

Loader.propTypes = {
  audioShouldBeMuted: PropTypes.bool,
  isRocketLoaded: PropTypes.bool,
  isRocketLoading: PropTypes.bool,
  progression: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number.isRequired,
  ]),
}
Loader.defaultProps = {
  progression: 0,
  isRocketLoading: false,
  isRocketLoaded: false,
  audioShouldBeMuted: false,
}
export default Loader
