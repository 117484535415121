import {
  isFullTrialing,
  isSubscribed,
  isSupplementary,
  isTrialing,
  isTrialingOrFullTrialing,
} from '@lyfta/components-ui/src/Services/Utils'
import { createSelector } from 'reselect'

import { denormalize } from '../../utils'
import { getData } from './data'
import { getJWT } from './persist'

export const getState = state => state.viewer

export const getViewerId = createSelector(getState, state => state.id)
export const getViewerType = createSelector(getState, state => state.type)
export const getViewerJobRoleHS = createSelector(
  getState,
  state => state.jobRoleHS,
)
export const getViewerDaysUntilTrialExpires = createSelector(
  getState,
  state => state.permissions.daysUntilTrialExpires,
)

export const getPermissions = createSelector(
  getState,
  state => state.permissions,
)
export const getAuthorId = createSelector(getState, state => state.authorId)

export const getAdminRoles = createSelector(
  getState,
  state => state.permissions.permissions,
)
export const getRedirectFinalStep = createSelector(
  getState,
  state => state.redirectFinalStep,
)
export const getUsageTotals = createSelector(
  getState,
  state => state.usageTotals,
)

const appendValuesToViewer = (viewer, toAppend) => ({ ...viewer, ...toAppend })

export const getViewer = createSelector(
  getData,
  getViewerId,
  getViewerType,
  getViewerDaysUntilTrialExpires,
  getViewerJobRoleHS,
  getPermissions,
  (
    data,
    viewerId,
    viewerType,
    ViewerDaysUntilTrialExpires,
    jobRoleHS,
    permissions,
  ) =>
    viewerId && viewerType
      ? appendValuesToViewer(
          denormalize(data, viewerType, viewerId, ViewerDaysUntilTrialExpires),
          {
            id: viewerId,
            type: viewerType,
            daysUntilTrialExpires: ViewerDaysUntilTrialExpires,
            jobRoleHS,
            permissions,
          },
        )
      : {},
)
export const getViewerOrganization = createSelector(
  getState,
  state => state.organization,
)
export const getViewerName = createSelector(getViewer, (user = {}) => {
  const { firstName, lastName, email } = user

  if (firstName || lastName) {
    return `${firstName} ${lastName} `
  }

  return email
})
export const getViewerOrganizationAdmin = createSelector(
  getState,
  state => state.permissions.organizationAdmin,
)
export const getNewFeatures = createSelector(
  getState,
  state => state.permissions.newFeatures,
)
export const getError = createSelector(getState, state => state.error)

export const getAuthorizationHeader = createSelector(getJWT, jwt =>
  jwt ? `Bearer ${jwt}` : null,
)

export const getIsAuthenticated = createSelector(getJWT, jwt => jwt !== null)

export const getIsAdmin = createSelector(
  getViewer,
  viewer => viewer.admin || false,
)

export const getIsDataLoaded = createSelector(
  getState,
  state => state.isDataLoaded,
)

export const getIsTrialingOrFullTrialing = createSelector(getState, state =>
  isTrialingOrFullTrialing(state.licenseType),
)
export const getIsTrialing = createSelector(getState, state =>
  isTrialing(state.licenseType),
)
export const getIsFullTrialing = createSelector(getState, state =>
  isFullTrialing(state.licenseType),
)
export const getIsSubscribed = createSelector(getState, state =>
  isSubscribed(state.licenseType),
)
export const getIsSupplementary = createSelector(getState, state =>
  isSupplementary(state.licenseType),
)
