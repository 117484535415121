import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import { PASSPHRASE_SET } from '../../Actions/ui'

const initialState = {
  passphrase: '',
}

const setPassphrase = (state, { passphrase }) => state.merge({ passphrase })

const handlers = {
  [PASSPHRASE_SET]: setPassphrase,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
