import PropTypes from 'prop-types'
import React from 'react'
import { FieldArray } from 'react-final-form-arrays'

const InputArray = ({ label, name, children, ...props }) => (
  <FieldArray label={label} name={name} {...props}>
    {children}
  </FieldArray>
)

InputArray.defaultProps = {
  children: {},
  label: '',
}

InputArray.propTypes = {
  children: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export default InputArray
