import { styled, themeGet } from '@lyfta/components-theme'

import { Flex } from '../Flex'

const Container = styled(Flex)`
  & > svg {
    justify-content: center;
    align-items: center;
  }
  flex-shrink: 0;
  path {
    fill: ${p => themeGet(`colors.${p.fill}`)(p) || p.fill};
    stroke: ${p => {
      return p.stroke ? themeGet(`colors.${p.stroke}`)(p) : ''
    }};
  }
  &:hover {
    path {
      fill: ${p => themeGet(`colors.${p.fillHover}`)(p) || ''};
      stroke: ${p => themeGet(`colors.${p.strokeHover}`)(p) || ''};
    }
  }
`

export default Container
