import {
  color,
  fontFamily,
  fontSize,
  letterSpacing,
  lineHeight,
  styled,
  textAlign,
} from '@lyfta/components-theme'

import { Span } from '../../BaseComponents'

const TextNode = styled(Span)`
  ${fontSize} ${fontFamily} ${lineHeight} ${color} ${textAlign} ${letterSpacing};
`

export default TextNode
