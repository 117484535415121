import 'react-table/react-table.css'

import React, { PureComponent } from 'react'

import PaginationNew from '../PaginationNew'
import { StyledTable } from './styles'

class Table extends PureComponent {
  render() {
    const {
      pager,
      pageSize,
      data,
      hover,
      isAtBottom,
      modelName,
      ...rest
    } = this.props
    return (
      <StyledTable
        PaginationComponent={pager ? PaginationNew : React.Fragment}
        data={data}
        hover={hover}
        pageSize={pager ? pageSize : data.length}
        pager={pager}
        resizable={false}
        modelName={modelName}
        showPagination={!!pager}
        isAtBottom={isAtBottom}
        {...rest}
      />
    )
  }
}

Table.propTypes = {
  ...StyledTable.propTypes,
}

export default Table
