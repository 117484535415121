import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import {
  MUTED_MUTE,
  MUTED_MUTED_DURING_TEMP_UNMUTE,
  MUTED_MUTED_DURING_TEMP_UNMUTE_HANDLED,
  MUTED_TEMPORARY_UNMUTE_ENDED,
  MUTED_TEMPORARY_UNMUTE_STARTED,
  MUTED_TOGGLE,
  MUTED_UNMUTE,
} from '../../Actions/ui'

const initialState = {
  muted: false,
  temporaryUnmute: false,
  mutedDuringTemporaryUnmute: false,
}

const mute = state => state.merge({ muted: false })
const unmute = state => state.merge({ muted: true })
const toggle = state => {
  return state.merge({ muted: !state.muted })
}
const startTemporaryUnmute = state => state.merge({ temporaryUnmute: true })
const endTemporaryUnmute = state => state.merge({ temporaryUnmute: false })
const mutedDuringTemporaryUnmute = state =>
  state.merge({ mutedDuringTemporaryUnmute: true })
const mutedDuringTemporaryUnmuteHandled = state =>
  state.merge({ mutedDuringTemporaryUnmute: false })

const handlers = {
  [MUTED_UNMUTE]: unmute,
  [MUTED_MUTE]: mute,
  [MUTED_TOGGLE]: toggle,
  [MUTED_TEMPORARY_UNMUTE_STARTED]: startTemporaryUnmute,
  [MUTED_TEMPORARY_UNMUTE_ENDED]: endTemporaryUnmute,
  [MUTED_MUTED_DURING_TEMP_UNMUTE]: mutedDuringTemporaryUnmute,
  [MUTED_MUTED_DURING_TEMP_UNMUTE_HANDLED]: mutedDuringTemporaryUnmuteHandled,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
