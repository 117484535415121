import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'

import { ColourSelector } from './colourSelector'
import { FontFamilySelector } from './fontFamilySelector'
import { SizeSelector } from './sizeSelector'
import {
  PageBackIconContainer,
  PrevPageButton,
  PrevPageButtonContainer,
  SettingsContainer,
} from './styles'

export const TextPage = ({ player, setCurrentPage }) => {
  return (
    <>
      <PrevPageButtonContainer>
        <PrevPageButton
          className="subtitle-customise-back-button"
          onClick={() => setCurrentPage('customise')}
        >
          <PageBackIconContainer />
          <I18n i18nKey="videoPlayer.labels.text" />
        </PrevPageButton>
      </PrevPageButtonContainer>
      <SettingsContainer className="settings-container">
        <SizeSelector player={player} />
        <ColourSelector player={player} property="color" />
        <FontFamilySelector player={player} />
      </SettingsContainer>
      {/* <ResetButtonContainer>
        <ResetButton>
          <I18n i18nKey="videoPlayer.labels.resetAll" />
        </ResetButton>
      </ResetButtonContainer> */}
    </>
  )
}

TextPage.propTypes = {
  player: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
}
