import * as React from 'react'

function SvgView(props) {
  return (
    <svg
      fill="none"
      height="14"
      viewBox="0 0 18 14"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M9 0.5C4.90909 0.5 1.41545 3.19533 0 7C1.41545 10.8047 4.90909 13.5 9 13.5C13.0909 13.5 16.5845 10.8047 18 7C16.5845 3.19533 13.0909 0.5 9 0.5ZM9 11.5C11.4853 11.5 13.5 9.48528 13.5 7C13.5 4.51472 11.4853 2.5 9 2.5C6.51472 2.5 4.5 4.51472 4.5 7C4.5 9.48528 6.51472 11.5 9 11.5Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        d="M11.5 7C11.5 8.38071 10.3807 9.5 9 9.5C7.61929 9.5 6.5 8.38071 6.5 7C6.5 5.61929 7.61929 4.5 9 4.5C10.3807 4.5 11.5 5.61929 11.5 7Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgView
