import { TranslatedField } from '@lyfta/components-i18n'
import { capitalizeFirstLetter } from '@lyfta/components-ui/src/Services/Utils'
import map from 'lodash/map'
import natsort from 'natsort'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { Container, Header, StyledCollapse, StyledMdClose, Tag } from './styles'

class Category extends PureComponent {
  constructor() {
    super()

    this.state = {
      isOpen: false,
    }
  }

  handleToggleOpen = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  handleTag = tag => () => {
    const { handleOnToggleTag } = this.props
    handleOnToggleTag(tag)
  }

  naturalSort = (categoryTags, locale) => {
    const sorter = natsort()

    return categoryTags.sort((a, b) =>
      sorter(
        a[`name${capitalizeFirstLetter(locale)}`],
        b[`name${capitalizeFirstLetter(locale)}`],
      ),
    )
  }

  render() {
    const { isOpen } = this.state
    const { tags, name, categoryTags, locale } = this.props

    return (
      <Container className={`${isOpen && 'opened'}`}>
        <Header onClick={this.handleToggleOpen}>
          <StyledMdClose opened={isOpen ? 1 : 0} />
          {name}
        </Header>

        <StyledCollapse isOpen={isOpen}>
          {map(this.naturalSort(categoryTags, locale), tag => (
            <Tag
              active={tags.includes(tag.id) ? 1 : 0}
              // TODO: This array should contain tag objects instead of tag name
              key={tag.id}
              // TODO: This method should receive tag instead of tag name
              onClick={this.handleTag(tag.id)}
            >
              <TranslatedField field="name" object={tag} />
            </Tag>
          ))}
        </StyledCollapse>
      </Container>
    )
  }
}

Category.defaultProps = {
  categoryTags: [],
  tags: [],
  name: 'Category',
}

Category.propTypes = {
  categoryTags: PropTypes.array,
  handleOnToggleTag: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  tags: PropTypes.array,
}

export default Category
