import * as React from 'react'

function SvgArrowRight(props) {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.74538 11.998L0.259888 10.5117L4.76915 6L0.259887 1.48826L1.74538 0.00195401L7.74013 6L1.74538 11.998Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgArrowRight
