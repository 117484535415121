import { createReducer } from '../../helpers'
import { createEntityFields, createReducerHandlers } from '../../utils'
import {
  CREATE_MEETING,
  LOAD_MEETING,
  LOAD_MEETINGS,
  UPDATE_MEETING,
} from '../Actions/meetings'

const initialState = {
  ...createEntityFields('meetings'),
}

const handlers = {
  ...createReducerHandlers('meetings', LOAD_MEETINGS, {
    withReplace: true,
    mapToKey: 'meetings',
  }),
  ...createReducerHandlers('meetings', LOAD_MEETING, {
    mapToKey: 'currentMeeting',
    singular: true,
  }),
  ...createReducerHandlers('meetings', CREATE_MEETING, {
    mapToKey: 'currentMeeting',
    singular: true,
  }),
  ...createReducerHandlers('meetings', UPDATE_MEETING, {
    mapToKey: 'currentMeeting',
    singular: true,
  }),
}

export default createReducer(initialState, handlers)
