import { css, styled, themeGet } from '@lyfta/components-theme'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'

import {
  Error as ErrorIcon,
  Success as SuccessIcon,
  Warning as WarningIcon,
} from '../../Assets/Icons'
import { Flex } from '../Flex'

export const Container = styled(Flex).attrs(() => ({}))`
align-items: stretch;
  box-sizing: border-box;
box-shadow: ${shadows.lifted};
border-radius: 5px;
justify-content: space-between;
width: 356px;
${props =>
  props.type === 'success' &&
  css`
    background-color: ${themeGet('colors.success_60')};
    border: 1px solid ${themeGet('colors.success_100')};
    color: ${themeGet('colors.success_130')};
  `}
${props =>
  props.type === 'error' &&
  css`
    background-color: ${themeGet('colors.error_60')};
    border: 1px solid ${themeGet('colors.error_100')};
    color: ${themeGet('colors.error_120')};
  `}
${props =>
  props.type === 'warning' &&
  css`
    background-color: ${themeGet('colors.warning_60')};
    border: 1px solid ${themeGet('colors.warning_100')};
    color: ${themeGet('colors.warning_130')};
  `}
&:hover {
  box-shadow: ${shadows.raised};
    > div > svg > path {
      fill: ${themeGet('colors.black_700')};
      ${props =>
        props.type === 'success' &&
        css`
          fill: ${themeGet('colors.success_100')};
        `}
      ${props =>
        props.type === 'warning' &&
        css`
          fill: ${themeGet('colors.warning_80')};
        `}
      ${props =>
        props.type === 'error' &&
        css`
          fill: ${themeGet('colors.error_100')};
        `}
    } 
  }
`

export const Icon = styled(Flex)``

export const Success = styled(SuccessIcon)`
  > path {
    fill: ${themeGet('colors.success_100')};
  }
`
export const Warning = styled(WarningIcon)`
  > path {
    fill: ${themeGet('colors.warning_100')};
  }
`
export const IconError = styled(ErrorIcon)`
  > path {
    fill: ${themeGet('colors.error_100')};
  }
`

export const Button = styled(Flex).attrs(() => ({
  px: 3,
  py: '18px',
}))`
  border-radius: 5px;
  display: flex;
  width: 56px;
  justify-content: center;
`

export const TextContainer = styled(Flex).attrs(() => ({
  ml: 3,
}))`
  letter-spacing: 0.5px;
  width: 248px;
`

export const IconAndTextContainer = styled(Flex).attrs(() => ({
  mt: 3,
  ml: 3,
  mb: 3,
}))``
