import { styled, themeGet } from '@lyfta/components-theme'

import { Box } from '../../../BaseComponents'

const Subtitle1 = styled(Box)`
  color: ${props => (props.color ? props.color : themeGet('colors.black_900'))};
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 32px;
`

export default Subtitle1
