import { createAsyncAction } from '../../helpers'
import apiCall from '../../services/Api'

export const CREATE_MEETING_ATTENDEE = createAsyncAction(
  'meetingAttendees/CREATE_MEETING_ATTENDEE',
)
export const UPDATE_MEETING_ATTENDEE = createAsyncAction(
  'meetingAttendees/UPDATE_MEETING_ATTENDEE',
)
export const LOAD_MEETING_ATTENDEE = createAsyncAction(
  'meetingAttendees/LOAD_MEETING_ATTENDEE',
)
export const LOAD_MEETING_ATTENDEES = createAsyncAction(
  'meetingAttendees/LOAD_MEETING_ATTENDEES',
)

export const clearCurrent = () => ({ type: LOAD_MEETING_ATTENDEE.CLEAR })

export const loadMeetingAttendee = (id, { meetingId }) => {
  return apiCall({
    method: 'GET',
    endpoint: `/meetings/${meetingId}/attendees/${id}?include=meeting,meetingOccurrence,meetingOccurrence.host`,
    types: LOAD_MEETING_ATTENDEE,
    payload: { id },
  })
}

export const loadMeetingAttendeeByToken = token => {
  return apiCall({
    method: 'GET',
    endpoint: `/meeting_attendees/${token}?include=meeting,meeting.defaultHost,occurrence,occurrence.host`,
    types: LOAD_MEETING_ATTENDEE,
    payload: { token },
  })
}

export const loadMeetingAttendees = ({
  meetingId,
  filter,
  include = 'meeting,user,user.organization,occurrence,occurrence.host',
  sort = 'attendeeStatus',
  ...props
} = {}) =>
  apiCall({
    method: 'GET',
    endpoint: `/meetings/${meetingId}/attendees`,
    query: {
      include,
      filter,
      sort,
    },
    types: LOAD_MEETING_ATTENDEES,
    paged: false,
    payload: { filter, include, sort, ...props },
  })

export const createMeetingAttendee = ({
  meetingId,
  relationships = {},
  ...meetingAttendee
}) => {
  return apiCall({
    method: 'POST',
    endpoint: `/meetings/${meetingId}/attendees?include=meeting,meetingOccurrence,meetingOccurrence.host`,
    types: CREATE_MEETING_ATTENDEE,
    query: {
      data: {
        type: 'meetingAttendees',
        attributes: {
          ...meetingAttendee,
        },
        relationships,
      },
    },
  })
}

export const updateMeetingAttendee = ({
  meetingId,
  relationships = {},
  ...meetingAttendee
}) => {
  return apiCall({
    method: 'PATCH',
    endpoint: `/meetings/${meetingId}/attendees/${meetingAttendee.id}?include=meeting,meetingOccurrence,meetingOccurrence.host`,
    types: UPDATE_MEETING_ATTENDEE,
    query: {
      data: {
        type: 'meetingAttendees',
        attributes: {
          ...meetingAttendee,
        },
        relationships,
      },
    },
  })
}

export const updateMeetingAttendeeStatus = ({
  meetingId,
  attendeeStatus,
  data,
}) =>
  apiCall({
    method: 'PATCH',
    endpoint: `/meetings/${meetingId}/attendees/status/${attendeeStatus}?include=host,meeting`,
    types: UPDATE_MEETING_ATTENDEE,
    query: {
      data,
    },
  })
