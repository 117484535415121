import { BREAKPOINTS, css, styled, themeGet } from '@lyfta/components-theme'
import { breakpoints } from '@lyfta/components-theme/src/themes/Main'

import Arrow from '../Arrow'
import { Box } from '../Box'
import { Flex } from '../Flex'
import background from './background.png'

export const Container = styled(Flex)`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  ${props =>
    props.removeBG
      ? css``
      : css`
          background-color: white;
          background-image: url(${background});
          background-position: top right;
          background-size: auto 100%;
          background-repeat: no-repeat;
          @media screen and (max-width: ${breakpoints[BREAKPOINTS.TABLET]}) {
            background-position: top left;
          }
        `};
`
export const InfoContainer = styled(Flex)`
  height: 100%;
  width: 600px;
  margin-left: 4rem;
  @media screen and (max-width: ${breakpoints[BREAKPOINTS.TABLET]}) {
    background-position: top left;
    width: 500px;
  }
`
export const InfoContainer2 = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  flex: 1;
  max-width: 80%;
`
export const Title = styled(Flex)`
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 58px;

  /* or 121% */
  letter-spacing: 0.01em;

  color: #3333cc;
`

export const Description = styled(Flex).attrs(() => ({}))`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-top: 2rem;
  /* identical to box height, or 150% */
  letter-spacing: 0.005em;
  color: #838181;
`

export const Button = styled(Flex)`
  margin-top: 2rem;
`

export const Message = styled(Flex).attrs(() => ({}))`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 1rem;

  /* identical to box height, or 138% */
  letter-spacing: 0.16em;
  text-transform: uppercase;

  color: #3333cc;
`

export const Previous = styled(Box).attrs(props => ({
  as: Arrow,
  direction: 'left',
  size: 10,
  mr: 4,
  color: props.disabled ? 'dustyGray' : 'primary',
}))``

export const Current = styled(Box).attrs(() => ({
  mr: 4,
}))`
  color: ${themeGet('colors.dustyGray')};
`

export const PageSize = styled(Flex).attrs(() => ({
  mr: 4,
}))`
  color: ${themeGet('colors.dustyGray')};
`

export const Size = styled(Flex).attrs(() => ({
  ml: 2,
}))`
  justify-content: space-between;
  align-items: center;
  color: ${themeGet('colors.dustyGray')};

  > div {
    margin-left: 7px;
    margin-bottom: 2px;
    border-color: ${themeGet('colors.dustyGray')};
  }
`
