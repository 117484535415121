import { getViewerType } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { getTranslation } from '@lyfta/components-i18n'
import { Gyro as GyroIcon } from '@lyfta/components-student'
import MenuController from '@lyfta/components-student/src/Components/QuestTracker/MenuController'
import { getCurrentLessonPlanId } from '@lyfta/components-student/src/Store/Selectors/lessonPlans'
import { getIsTeachingInFrontOfClass } from '@lyfta/components-student/src/Store/Selectors/questTracker'
import { getIsOpen } from '@lyfta/components-student/src/Store/Selectors/UI/drawer'
import { getIsQuest } from '@lyfta/components-student/src/Store/Selectors/UI/quest'
import { getIsPreviewMode } from '@lyfta/components-ui/src/Helpers/getIsPreviewMode'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  FullscreenButton,
  MuteButton,
  RotateButton,
  ZoomInButton,
  ZoomOutButton,
} from './Buttons'
import { Menu, OldStyleMenuItem, RightSidebar, ZoomControls } from './styles'

export const RightSidebarComponent = () => {
  const viewerType = useSelector(getViewerType)
  const isTeachingInFrontOfClass = useSelector(getIsTeachingInFrontOfClass)
  const isQuest = useSelector(getIsQuest)
  const lessonPlanId = useSelector(getCurrentLessonPlanId)
  const isDrawerOpen = useSelector(getIsOpen)

  const isPreviewMode = getIsPreviewMode({
    viewerType,
    isTeachingInFrontOfClass,
    lessonPlanId,
    isQuest,
  })

  const supportsGyro = useMemo(
    () =>
      'DeviceOrientationEvent' in window &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2,
    [],
  )

  return (
    <RightSidebar
      id="div-right-sidebar"
      isPreviewMode={isPreviewMode}
      display={isDrawerOpen ? 'none' : 'flex'}
    >
      {!isPreviewMode && <MenuController />}
      <Menu isPreviewMode={isPreviewMode}>
        {isPreviewMode && <MenuController />}
        <FullscreenButton />
        <MuteButton />
        <RotateButton />
        {supportsGyro ? (
          <OldStyleMenuItem title={getTranslation('header.gyro')}>
            <GyroIcon height="72px" width="72px" />
          </OldStyleMenuItem>
        ) : null}
      </Menu>
      <ZoomControls>
        <ZoomInButton />
        <ZoomOutButton />
      </ZoomControls>
    </RightSidebar>
  )
}
