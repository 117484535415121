import { getViewerType } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { getCurrentLessonPlanId } from '@lyfta/components-student/src/Store/Selectors/lessonPlans'
import { getLessonsRaw } from '@lyfta/components-student/src/Store/Selectors/lessons'
import { getIsTeachingInFrontOfClass } from '@lyfta/components-student/src/Store/Selectors/questTracker'
import { getIsQuest } from '@lyfta/components-student/src/Store/Selectors/UI/quest'
import { getIsInScene } from '@lyfta/components-student/src/Store/Selectors/UI/scene'
import { PropTypes, USER_TYPES } from '@lyfta/components-ui'
import { getIsPreviewMode } from '@lyfta/components-ui/src/Helpers/getIsPreviewMode'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getIsViewerWithLessons } from '../../../utils/getIsViewerWithLessons'
import { HomeButton, LessonSelectionButton } from './Buttons'
import { NavigationLeftSidebarComponent } from './NavigationLeftSidebar'
import { LeftSidebar, Menu } from './styles'

export const LeftSidebarComponent = ({ loadLessons }) => {
  const isInScene = useSelector(getIsInScene)
  const isQuest = useSelector(getIsQuest)
  const lessonsRaw = useSelector(getLessonsRaw)
  const viewerType = useSelector(getViewerType)
  const isTeachingInFrontOfClass = useSelector(getIsTeachingInFrontOfClass)
  const lessonPlanId = useSelector(getCurrentLessonPlanId)

  const isPreviewMode = getIsPreviewMode({
    viewerType,
    isTeachingInFrontOfClass,
    lessonPlanId,
    isQuest,
  })
  const isViewerWithLessons = getIsViewerWithLessons({ viewerType, lessonsRaw })

  useEffect(() => {
    if (
      viewerType === USER_TYPES.teachers ||
      viewerType === USER_TYPES.homeStudents
    ) {
      loadLessons()
    }
  }, [viewerType])

  if (!isInScene) return <NavigationLeftSidebarComponent />
  return (
    <LeftSidebar id="div-left-sidebar" isPreviewMode={isPreviewMode}>
      <Menu>
        <HomeButton />
        {isQuest && !isPreviewMode && isViewerWithLessons && (
          <LessonSelectionButton />
        )}
      </Menu>
    </LeftSidebar>
  )
}

LeftSidebarComponent.propTypes = {
  loadLessons: PropTypes.func.isRequired,
}
