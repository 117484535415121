export const SHOW_SEARCH_MODAL = 'ui/SHOW_SEARCH_MODAL'
export const HIDE_SEARCH_MODAL = 'ui/HIDE_SEARCH_MODAL'

export const PREVIEW_SHOW = 'ui/ADD_CONTENT_SHOW'
export const PREVIEW_HIDE = 'ui/ADD_CONTENT_HIDE'
export const PREVIEW_LESSON_PLAN_SHOW = 'ui/PREVIEW_LESSON_PLAN_SHOW'
export const PREVIEW_LESSON_PLAN_HIDE = 'ui/PREVIEW_LESSON_PLAN_HIDE'

export const TAGS_SHOW = 'ui/TAGS_SHOW'
export const TAGS_HIDE = 'ui/TAGS_HIDE'
export const TAGS_TOGGLE = 'ui/TAGS_TOGGLE'

export const SEARCH_TAB = 'ui/SEARCH_TAB'

export const MODAL_SHOW = 'ui/MODAL_SHOW'
export const MODAL_HIDE = 'ui/MODAL_HIDE'
export const MODAL_CHOOSE_NEGATIVE = 'ui/MODAL_CHOOSE_NEGATIVE'
export const MODAL_CHOOSE_POSITIVE = 'ui/MODAL_CHOOSE_POSITIVE'
export const MODAL_LOADING = 'ui/MODAL_LOADING'

export const searchModal = {
  show: id => ({ type: SHOW_SEARCH_MODAL, id }),
  hide: () => ({ type: HIDE_SEARCH_MODAL }),
}

export const tags = {
  show: () => ({ type: TAGS_SHOW }),
  hide: () => ({ type: TAGS_HIDE }),
  toggle: () => ({ type: TAGS_TOGGLE }),
}

export const search = {
  tab: index => ({ type: SEARCH_TAB, index }),
}

export const modal = {
  show: (payload, resolve) => ({ type: MODAL_SHOW, payload, resolve }),
  hide: () => ({ type: MODAL_HIDE }),
  chooseNegative: () => ({ type: MODAL_CHOOSE_NEGATIVE }),
  choosePositive: () => ({ type: MODAL_CHOOSE_POSITIVE }),
  loading: (isLoading = true) => ({ type: MODAL_LOADING, isLoading }),
}
