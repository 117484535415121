import { appEnv } from '@lyfta/components-config'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

export const useGoogleAnalytics = (useGA, id, viewer) => {
  const canUseGA = useGA && !!id && id.length > 0
  const location = useLocation()
  useEffect(() => {
    if (canUseGA) {
      ReactGA.initialize(id)
      ReactGA.gtag('config', id, {
        user_id: viewer?.id,
        debug_mode: appEnv !== 'production',
      })
      ReactGA.gtag('set', 'user_properties', {
        role: viewer?.role,
        jobRole: viewer?.jobRoleHS,
        licence_status: viewer?.licenceStatus,
        subscription_status: viewer?.subscriptionStatus,
        organization_admin: viewer?.organizationAdmin,
        lifecycle_stage: viewer?.lifecycleStage,
        lyfta_user_create_date: viewer?.createdAt,
        org_id: viewer?.organization?.id,
        org_lifecycle_stage: viewer?.organization?.lifecycleStage,
        org_country: viewer?.organization?.country,
        org_licences: viewer?.organization?.latestLicenseName,
      })

      ReactGA.send(window.location.pathname + window.location.search)
    }
  }, [])

  useEffect(() => {
    if (canUseGA) {
      ReactGA.send(window.location.pathname + window.location.search)
      ReactGA.gtag('config', id, {
        user_id: viewer?.id,
        debug_mode: true,
      })
      ReactGA.gtag('set', 'user_properties', {
        role: viewer?.role,
        jobRole: viewer?.jobRoleHS,
        licence_status: viewer?.licenceStatus,
        subscription_status: viewer?.subscriptionStatus,
        organization_admin: viewer?.organizationAdmin,
        lifecycle_stage: viewer?.lifecycleStage,
        lyfta_user_create_date: viewer?.createdAt,
        org_id: viewer?.organization?.id,
        org_lifecycle_stage: viewer?.organization?.lifecycleStage,
        org_country: viewer?.organization?.country,
        org_licences: viewer?.organization?.latestLicenseName,
      })
    }
  }, [location])
}
