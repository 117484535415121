import get from 'lodash/get'
import includes from 'lodash/includes'
import merge from 'lodash/merge'
import Immutable from 'seamless-immutable'

export const transformToImmutable = state =>
  get(state, 'withoutImmutable') === true ? state : Immutable(state)

export const createAsyncAction = (type, params) => ({
  REQUEST_PERFORM: `${type}.REQUEST_PERFORM`,
  REQUEST: `${type}.REQUEST`,
  SUCCESS: `${type}.SUCCESS`,
  FAILURE: `${type}.FAILURE`,
  CLEAR: `${type}.CLEAR`,
  ...(get(params, 'withProgress') && { PROGRESS: `${type}.PROGRESS` }),
  ...(get(params, 'withCancel') && { CANCEL: `${type}.CANCEL` }),
})

const OPTIONS = {
  resetOn: [],
  resetFn: (state, initialState) => transformToImmutable(initialState),
}

export const createReducer = (initialState, handlers, options) => (
  state = transformToImmutable(initialState),
  action,
) => {
  const opts = merge({}, OPTIONS, options)

  if (includes(opts.resetOn, action.type)) {
    return opts.resetFn(state, initialState)
  }

  return handlers[action.type] ? handlers[action.type](state, action) : state
}
