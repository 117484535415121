import { TYPE_SERIES } from '@lyfta/components-content/src/Constants/contents'
import { I18n, TranslatedField, getTranslation } from '@lyfta/components-i18n'
import { themeGet } from '@lyfta/components-theme'
import {
  ButtonNew,
  ContentWarning,
  Film,
  Flex,
  Icon,
  Lessons,
  Location as Loc,
  Lock,
  RMA,
  Separator,
  Storyworld,
  VR360,
} from '@lyfta/components-ui'
import get from 'lodash/get'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import {
  BodyContainer,
  Container,
  ContentContainer,
  Controls,
  ControlsButtons,
  HeaderContainer,
  IconContainer,
  LeftBlock,
  LowerCaseContainer,
  RightBlock,
  SubTitle,
  ThumbHolder,
  Title,
} from './styles'

// import Player from 'react-player'

class Card extends PureComponent {
  handleContactUs = () => {
    window.open(
      'https://meetings.hubspot.com/rahul95/introductory-meeting-with-lyfta?__hstc=139644076.a7e69c3f5abe5af5e48fd31129b11452.1707689133650.1712240170520.1714653746786.4&__hssc=139644076.2.1714653746786&__hsfp=382654845&uuid=9e4acdcb-0dc3-4779-bc71-1d96c0772bed ',
      '_blank',
    )
  }

  renderSubHead = item => {
    const hasParent = get(item, 'parent') !== null
    const parentObject = get(item, 'parent', {})
    const series = get(parentObject, 'parent')

    if (!hasParent) {
      return <I18n i18nKey={`content.type.${get(item, 'type')}`} />
    }

    if (!parentObject) return null

    return (
      <>
        <TranslatedField field="name" object={parentObject} />
        {series && (
          <>
            : <TranslatedField field="name" object={series} />
          </>
        )}
      </>
    )
  }

  render() {
    const { item, onDismissModal } = this.props
    const contentId = get(item, 'id')
    const contentWarningDescription = get(item, 'contentWarningDescription')
    const type = get(item, 'type') || false

    return (
      <Container data-intercom-target={contentId} id="card">
        {/* eslint-disable */}
        <HeaderContainer
        // isVideo={!!item?.trailerUrl}
        >
          {/*} {item?.trailerUrl ? (
            <Player
              controls
              playing={false}
              url={item?.trailerUrl}
              height="auto"
            />
          ) : ( */}
          <ThumbHolder image={item?.posterUrl} />
          {/* )} */}
        </HeaderContainer>
        <BodyContainer>
          <Flex alignItems="flex-start">
            <IconContainer>
              <Icon glyph={Lock} fill="black" width="20px" height="20px" />
            </IconContainer>
            <Title>
              <TranslatedField object={item} field="name" />
            </Title>
            {contentWarningDescription && (
              <Icon
                title={contentWarningDescription}
                glyph={ContentWarning}
                height="20px"
                width="20px"
                ml={2}
              />
            )}
          </Flex>

          <ContentContainer>
            <LeftBlock>
              <TranslatedField
                object={item}
                truncateString="150"
                field="shortDescription"
              />
              <Flex mt={2}>
                <Icon
                  glyph={Loc}
                  width="20px"
                  height="20px"
                  fill={themeGet('colors.black_600')}
                  mr={2}
                />
                {<TranslatedField object={item} field="country" />}
              </Flex>
            </LeftBlock>
            <RightBlock>
              {type !== TYPE_SERIES && (
                <>
                  {getTranslation('card.includedStoryworld')}
                  {item?.totalFilms !== 0 && (
                    <Flex mt={2}>
                      <Icon
                        glyph={Film}
                        width="20px"
                        height="20px"
                        fill={themeGet('colors.black_600')}
                        mr={2}
                      />
                      <Flex mr={1}>{item?.totalFilms} &times;</Flex>

                      {
                        <I18n
                          i18nKey="core.models.films"
                          options={{
                            modelName: 'films',
                            count: item?.totalFilms,
                          }}
                          tag={LowerCaseContainer}
                        />
                      }
                    </Flex>
                  )}
                  {item?.totalThreeSixty && (
                    <Flex mt={2}>
                      <Icon
                        glyph={VR360}
                        width="20px"
                        height="20px"
                        fill={themeGet('colors.black_600')}
                        mr={2}
                      />
                      <Flex mr={1}>{item?.totalThreeSixty} &times;</Flex>

                      {
                        <I18n
                          i18nKey="core.models.360videos"
                          options={{
                            modelName: '360videos',
                            count: item?.totalThreeSixty,
                          }}
                          tag={LowerCaseContainer}
                        />
                      }
                    </Flex>
                  )}
                  {item?.totalArticles && (
                    <Flex mt={2}>
                      <Icon
                        glyph={RMA}
                        width="20px"
                        height="20px"
                        fill={themeGet('colors.black_600')}
                        mr={2}
                      />
                      <Flex mr={1}>{item?.totalArticles} &times;</Flex>
                      {
                        <I18n
                          i18nKey="core.models.articles"
                          options={{
                            modelName: 'articles',
                            count: item?.totalArticles,
                          }}
                          tag={LowerCaseContainer}
                        />
                      }
                    </Flex>
                  )}
                  {item?.totalLessonPlans !== 0 && (
                    <Flex mt={2}>
                      <Icon
                        glyph={Lessons}
                        width="20px"
                        height="20px"
                        fill={themeGet('colors.black_600')}
                        mr={2}
                      />
                      <Flex mr={1}>{item?.totalLessonPlans} &times;</Flex>

                      <I18n
                        i18nKey="core.models.lessonPlans"
                        options={{
                          modelName: 'lessonPlans',
                          count: item?.totalLessonPlans,
                        }}
                        tag={LowerCaseContainer}
                      />
                    </Flex>
                  )}
                </>
              )}

              {type === TYPE_SERIES && (
                <>
                  {getTranslation('card.includedSeries')}
                  {item?.totalStoryWorlds !== 0 && (
                    <Flex mt={2}>
                      <Icon
                        glyph={Storyworld}
                        width="20px"
                        height="20px"
                        fill={themeGet('colors.black_600')}
                        mr={2}
                      />
                      <Flex mr={1}>{item?.totalStoryWorlds} &times;</Flex>
                      {
                        <I18n
                          i18nKey="core.models.storyworlds"
                          options={{
                            modelName: 'storyworlds',
                            count: item?.totalStoryWorlds,
                          }}
                          tag={LowerCaseContainer}
                        />
                      }
                    </Flex>
                  )}
                </>
              )}
            </RightBlock>
          </ContentContainer>
        </BodyContainer>
        <Controls>
          <Separator height="5px" lineaColor="black_300" />

          <SubTitle mt={3}>{getTranslation('card.accessContactUs')}</SubTitle>
          <ControlsButtons>
            <ButtonNew
              default={{
                letterSpacing: '2.5px',
                fontSize: '16px',
                fontWeight: '700',
                height: '40px',
                color: 'secondary_100',
              }}
              type="link"
              onClick={() => onDismissModal()}
            >
              <I18n text="auth.contentLocked.notNow" />
            </ButtonNew>
            <ButtonNew
              onClick={() => this.handleContactUs()}
              default={{
                width: '215px',
                height: '40px',
                backgroundColor: 'secondary_100',
                fontSize: '16px',
                fontWeight: '700',
                letterSpacing: '2.5px',
              }}
            >
              <I18n i18nKey="auth.contentLocked.contactUs" />
            </ButtonNew>
          </ControlsButtons>
        </Controls>
      </Container>
    )
  }
}

Card.defaultProps = {
  item: null,
  selectable: false,
  onSelect: noop,
  onDismissModal: noop,
}

Card.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string,
    posterUrlSmall: PropTypes.string,
  }),
  selectable: PropTypes.bool,
  onSelect: PropTypes.func,
  onDismissModal: PropTypes.func,
}

export default Card
