// import I18n to initialise internationalization
import 'I18n'

import { appEnv, googleAnalyticsStudentId } from '@lyfta/components-config'
import { I18n, I18nProvider } from '@lyfta/components-i18n'
import { BREAKPOINTS, withTheme } from '@lyfta/components-theme'
import { Text, useGoogleAnalytics } from '@lyfta/components-ui'
import RoutesConfig from 'Config/routes'
import paths from 'Constants/paths'
import jwtDecode from 'jwt-decode'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { renderRoutes } from 'react-router-config'
import { ToastContainer } from 'react-toastify'
import { AppContext } from 'Services/Context'

import { Container } from './styles'

/* eslint-disable */
function AnalyticsComponent({ viewer }) {
  if (viewer?.role) useGoogleAnalytics(true, googleAnalyticsStudentId, viewer)
  return <></>
}
/* eslint-enable */

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      breakpoint: BREAKPOINTS.DESKTOP,
      waitingForToken: true,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    const { history } = this.props

    this.prepareJWT()

    this.setState({
      unregisterHistoryListener: history.listen(this.onHistoryChange),
    })
  }

  componentWillUnmount() {
    const { unregisterHistoryListener } = this.state

    unregisterHistoryListener()
    window.removeEventListener('resize', this.handleResize)
  }

  onHistoryChange = () => {
    const queryStr = window.location.search.substr(1)
    const suppliedQueryString = qs.parse(queryStr, { parameterLimit: 3 })
    const { jwt: token, lesson_plan_id: lessonPlanId } = suppliedQueryString

    this.setState({
      token,
      lessonPlanId,
      waitingForToken: false,
    })
  }

  prepareJWT = () => {
    const {
      onSetResourceId,
      onSelectLesson,
      onLoadLesson,
      onSetStudentLessonId,
      onSetIsTeachingInFrontOfClass,
    } = this.props

    const queryStr = window.location.search.substr(1)
    const suppliedQueryString = qs.parse(queryStr, { parameterLimit: 3 })
    const {
      jwt: token,
      resource_id: resourceId,
      lesson_plan_id: lessonPlanId,
      lesson_id: lessonId,
    } = suppliedQueryString
    const validJWT = this.validateToken(token)

    if (token) {
      onSetIsTeachingInFrontOfClass(false)

      if (validJWT) {
        if (resourceId) {
          onSetResourceId(resourceId)
        }
        if (lessonId) {
          if (lessonId > 0) {
            onLoadLesson(lessonId)
            onSetStudentLessonId(lessonId)
            onSetIsTeachingInFrontOfClass(true)
          }
          onSelectLesson(lessonId)
        }
      }
    }

    this.setState({
      token,
      validJWT,
      lessonPlanId,
      waitingForToken: false,
    })
  }

  handleResize = () => {
    const {
      theme: { breakpoints },
    } = this.props
    const { breakpoint } = this.state
    const width = window.innerWidth
    const setBreakpoint = pointName => {
      if (pointName !== breakpoint) {
        this.setState({ breakpoint: pointName })
      }
    }
    if (width < parseInt(breakpoints[0], 10)) {
      setBreakpoint(BREAKPOINTS.MOBILE)
    } else if (width < parseInt(breakpoints[1], 10)) {
      setBreakpoint(BREAKPOINTS.TABLET)
    } else if (width < parseInt(breakpoints[2], 10)) {
      setBreakpoint(BREAKPOINTS.DESKTOP)
    } else {
      setBreakpoint(BREAKPOINTS.LARGE)
    }
  }

  validateToken = token => {
    const { onSetJWT } = this.props

    if (token) {
      localStorage.clear()
    }

    let decodedToken = null
    try {
      // valid token format
      decodedToken = jwtDecode(token)
    } catch (error) {
      return false
    }
    const { exp } = decodedToken
    const currentTime = Date.now() / 1000

    onSetJWT(token, null)

    return exp > currentTime
  }

  renderAppRoutes = () => {
    const { waitingForToken } = this.state

    if (waitingForToken) {
      return <I18n i18nKey="loading" tag={Text} />
    }

    const { isAuthenticated } = this.props

    const { token, validJWT, lessonPlanId } = this.state

    if (token) {
      if (!validJWT) {
        return <Redirect to={paths.root} />
      }

      if (lessonPlanId) {
        return <Redirect to={paths.lessonPlan(lessonPlanId)} />
      }

      return <Redirect to={paths.root} />
    }

    return renderRoutes(RoutesConfig.build(isAuthenticated))
  }

  render() {
    const { isAuthenticated, viewer, history } = this.props

    const { breakpoint } = this.state

    // <ConnectedRouter history={history}>
    //   <Route path="/" component={App} />
    // </ConnectedRouter>

    return (
      <AppContext.Provider
        value={{ breakpoint, history, viewer, isAuthenticated }}
      >
        {appEnv === 'production' && (
          <Helmet>
            {/* <!-- Clarity tracking code for https://student.lyfta.com/ --> */}
            <script
              id="bing-clarity-tracking-integration"
              type="text/javascript"
            >
              {`
    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i+"?ref=bwt";
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "aykujx64f7");
    `}
            </script>
          </Helmet>
        )}
        <I18nProvider>
          <ToastContainer />
          <AnalyticsComponent viewer={viewer} />
          <Container>{this.renderAppRoutes()}</Container>
        </I18nProvider>
      </AppContext.Provider>
    )
  }
}

App.propTypes = {
  history: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  viewer: PropTypes.object.isRequired,
  onLoadLesson: PropTypes.func.isRequired,
  onSelectLesson: PropTypes.func.isRequired,
  onSetIsTeachingInFrontOfClass: PropTypes.func.isRequired,
  onSetJWT: PropTypes.func.isRequired,
  onSetResourceId: PropTypes.func.isRequired,
  onSetStudentLessonId: PropTypes.func.isRequired,
}

export default withTheme(App)
