import { styled } from '@lyfta/components-theme'
import { Markdown, css } from '@lyfta/components-ui'

import {
  Checkbox as BaseCheckbox,
  Container as BaseContainer,
  Radio as BaseRadio,
} from './_styles'

export { Wrapper } from './_styles'

const conditionalCursor = ({ disabled }) => {
  if (disabled) {
    return css`
      cursor: not-allowed;
    `
  }
  return css`
    cursor: pointer;
  `
}

export const Checkbox = styled(BaseCheckbox)`
  ${conditionalCursor}
`

export const Container = styled(BaseContainer)`
  ${conditionalCursor}

  &:hover ${Checkbox} {
    #check {
      visibility: hidden;
    }
    #check-hover {
      visibility: visible;
    }
  }
`

export const Radio = styled(BaseRadio)`
  ${conditionalCursor}
`

export const Label = styled(Markdown).attrs(p => ({
  source: p.source,
  type: 'inherit',
}))`
  display: flex;
  flex: 1;
`
