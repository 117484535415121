import { capitalizeFirstLetter } from '@lyfta/components-ui/src/Services/Utils'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import build from 'redux-object'
import { createSelector } from 'reselect'

import {
  createClearHandler,
  createDeleteHandler,
  createLoadHandler,
} from './entityHandlers'

export const denormalize = (entities, type, id) =>
  build(entities, type, isArray(id) ? map(id, i => i.id || i) : id, {
    eager: true,
  })
const getEntityData = state => state.data

export const getEntitiesState = type => state => {
  return get(state, type)
}

export const getEntityStateField = (type, fieldName, stateCollection) =>
  createSelector(
    getEntitiesState(stateCollection || type),
    () => fieldName,
    (state, field) => get(state, field),
  )

export const getObjectId = (objectNameSingular, type, stateCollection, alias) =>
  createSelector(
    getEntitiesState(stateCollection || type),
    (s, p) => p,
    (state, props) => {
      return (
        get(props, `${objectNameSingular}Id`) ||
        get(state, `current${capitalizeFirstLetter(objectNameSingular)}`) ||
        get(props, `match.params.${objectNameSingular}Id`) ||
        get(props, `match.params.${alias}Id`) ||
        get(props, 'match.params.id') ||
        0
      )
    },
  )

export const getObjectIds = (typeName, fieldName, stateCollection) =>
  createSelector(
    getEntitiesState(stateCollection || typeName),
    () => typeName,
    () => fieldName,
    (state, type, field) => {
      return get(state, field || type) || []
    },
  )

export const getEntity = (
  typeName,
  stateCollection,
  field,
  singularName,
  alias,
) => {
  const singularKey = singularName || (field || typeName).slice(0, -1)
  return createSelector(
    getEntityData,
    () => typeName,
    getObjectId(
      singularKey,
      stateCollection || typeName,
      stateCollection,
      alias,
    ),
    (data, type, id) => {
      return denormalize(data, type, id) || {}
    },
  )
}
export const getAllEntities = (typeName, stateCollection, field) => {
  return createSelector(
    getEntityData,
    () => typeName,
    getObjectIds(typeName, field || typeName, stateCollection),
    (data, type, ids) => {
      return denormalize(data, type, ids) || []
    },
  )
}

export const createEntityFields = (type, field, singularKey) => {
  const currentKey = capitalizeFirstLetter(
    singularKey || (field || type).slice(0, -1),
  )

  return {
    [field || type]: [],
    [`current${currentKey}`]: null,
    isLoading: false,
    isLoaded: false,
    paged: {
      size: 10,
      number: 1,
      totalRecords: 0,
    },
  }
}

/**
 * @deprecated please use getAllEntities or getEntity instead
 */
export const getEntities = (
  getState,
  getData,
  { type, field, sorted = false, singular = false },
) =>
  createSelector(getState, getData, (state, data) => {
    const entity = field || type

    const paged = get(state, `paged`)

    const all = {
      isLoading: state.isLoading,
      isLoaded: state.isLoaded,
      paged,
    }

    const ids = state[entity]

    const entityKey = singular ? 'entity' : 'entities'

    if (!isEmpty(ids)) {
      all[entityKey] = denormalize(data, type, ids)
    } else {
      all[entityKey] = singular ? null : []
    }

    if (sorted && paged) {
      all[entityKey] = sortBy(all[entityKey], item =>
        all.paged.records.indexOf(item.id),
      )
    }

    return all[entityKey]
  })

/**
 * @deprecated please use createEntityFields instead
 */
export const createFields = (type, field, singular = false) => {
  const entity = field || type
  const addKey = !field || field === type ? '' : capitalizeFirstLetter(field)

  const fields = {
    [entity]: singular ? null : [],
    isLoading: false,
    isLoaded: false,
    paged: {
      size: 10,
      number: 1,
      totalRecords: 0,
    },
  }

  if (!singular) {
    fields[`paged${addKey}`] = null
  }

  return fields
}
const loadingHandlers = (
  baseHandlers,
  type,
  actionTypes,
  handlerOptions = null,
) => {
  const handlers = baseHandlers
  const withLoading = get(handlerOptions, 'withLoading', false)

  if (withLoading) {
    handlers[actionTypes.REQUEST] = state => {
      return state.merge({
        isLoading: true,
        isLoaded: false,
      })
    }

    handlers[actionTypes.FAILURE] = state => {
      return state.merge({
        isLoading: false,
        isLoaded: false,
      })
    }
  }

  return handlers
}

export const createReducerHandlers = (
  type,
  actionTypes,
  handlerOptions = {},
) => {
  let handlers = {
    [actionTypes.SUCCESS]: createLoadHandler(type, handlerOptions),
    [actionTypes.CLEAR]: createClearHandler(type, handlerOptions),
  }

  let { withLoading } = handlerOptions
  if (withLoading === undefined) withLoading = true

  handlers = loadingHandlers(handlers, type, actionTypes, {
    ...handlerOptions,
    withLoading,
  })

  return handlers
}

export const createDeleteReducerHandlers = (
  type,
  actionTypes,
  handlerOptions = {},
) => {
  let handlers = {
    [actionTypes.SUCCESS]: createDeleteHandler(type, handlerOptions),
  }

  let { withLoading } = handlerOptions
  if (withLoading === undefined) withLoading = false

  handlers = loadingHandlers(handlers, type, actionTypes, {
    ...handlerOptions,
    withLoading,
  })

  return handlers
}
