import { I18n, getTranslation } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import {
  Body1Text,
  SettingSelectorContainer,
  SizeSelectorButton,
} from './styles'

// import theme from '@lyfta/components-theme/src/themes/Main'

const sizes = [
  {
    label: getTranslation('videoPlayer.labels.sizes.50Percent'),
    value: 0.5,
  },
  {
    label: getTranslation('videoPlayer.labels.sizes.100Percent'),
    // Joe note: VJS code seems to use expect "null" for 100%?
    // https://github.com/videojs/video.js/blob/main/src/js/tracks/text-track-settings.js#L125
    // This actually behaves very strangely, and the best fix I've found so
    // far is to change how the fontPercent parser works in our VJS fork.
    // (See text-track-settings.js and https://github.com/videojs/video.js/issues/6143)
    value: 1.0,
  },
  {
    label: getTranslation('videoPlayer.labels.sizes.150Percent'),
    value: 1.5,
  },
  {
    label: getTranslation('videoPlayer.labels.sizes.200Percent'),
    value: 2.0,
  },
]

export const SizeSelector = ({ player }) => {
  const myTextTrackSettings = player.textTrackSettings.getValues()

  const [currentSize, setCurrentSize] = useState(
    myTextTrackSettings.fontPercent || 1.0,
  )

  useEffect(() => {
    const newTextTrackSettingsValues = {
      fontPercent: currentSize,
    }

    // Work around to tackle the issue with fontPercent which come from the library
    if (newTextTrackSettingsValues.fontPercent === 1) {
      const currentValues = player.textTrackSettings.getValues()
      player.textTrackSettings.setDefaults()
      player.textTrackSettings.setValues({ ...currentValues, fontPercent: 1.0 })
    } else {
      player.textTrackSettings.setValues(newTextTrackSettingsValues)
    }
    player.textTrackSettings.updateDisplay()
  }, [currentSize])

  const handleClick = percent => {
    player.options_.updateUserPreferences({
      fontPercent: percent,
    })
    setCurrentSize(percent)
  }

  return (
    <>
      <Body1Text>
        <I18n i18nKey="videoPlayer.labels.size" />
      </Body1Text>
      <SettingSelectorContainer className="size-selector-container" mb={3}>
        {sizes.map(percent => (
          <SizeSelectorButton
            onClick={() => handleClick(percent.value)}
            selected={percent.value === currentSize}
          >
            {percent.label}
          </SizeSelectorButton>
        ))}
      </SettingSelectorContainer>
    </>
  )
}

SizeSelector.propTypes = {
  player: PropTypes.object.isRequired,
}
