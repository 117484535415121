import { AnimatedFlex, handleKeyPress } from '@lyfta/components-ui'
import { useAnimation } from 'framer-motion'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import { ActivityIcon } from './Components/ActivityIcon'
import Details from './Components/Details'
import TimeIndicator from './Components/TimeIndicator'
import useSelectActivity from './Components/useSelectActivity'
import { ActivityContent, ActivityRow, DetailsTimeContainer } from './styles'

const Activity = ({
  activity,
  activity: { animated, intro, selected, complete, id },
  animationDelay,
  index,
  isDrawerOpen,
  canBeSelected,
}) => {
  const selectActivity = useSelectActivity(id)

  const activityContentsControls = useAnimation()
  const activityContentsAnim = {
    animate: activityContentsControls,
  }

  useEffect(() => {
    if (animated && !selected) {
      activityContentsControls.start({
        scale: [1, 1.1, 1.1, 1, 1],
        rotate: [0, 0, 5, -5, 0],
        transition: { delay: animationDelay / 1000, flip: 100 },
      })
    } else if (!animated) {
      activityContentsControls.stop()
      activityContentsControls.start({
        scale: 1,
        rotate: 0,
      })
    }
  }, [selected, animated])
  return (
    <ActivityRow
      aria-disabled={!canBeSelected}
      canBeSelected={canBeSelected}
      complete={complete}
      id={`div-select-activity-${activity.id}`}
      selected={selected}
      onClick={selectActivity}
      onKeyPress={handleKeyPress({ callback: selectActivity, key: 'Enter' })}
      tabIndex={isDrawerOpen ? '0' : '-1'}
    >
      <AnimatedFlex alignItems="center" animation={activityContentsAnim}>
        <ActivityContent>
          <ActivityIcon
            activity={activity}
            canBeSelected={canBeSelected}
            index={index}
          />
          <DetailsTimeContainer intro={intro}>
            <Details activity={activity} index={index} />
            {!intro && <TimeIndicator activity={activity} index={index} />}
          </DetailsTimeContainer>
        </ActivityContent>
      </AnimatedFlex>
    </ActivityRow>
  )
}

Activity.displayName = 'Activity'

Activity.propTypes = {
  activity: PropTypes.object.isRequired,
  animationDelay: PropTypes.number.isRequired,
  canBeSelected: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
}
export default Activity
