import { styled, themeGet as theme } from '@lyfta/components-theme'
import Dropzone from 'react-dropzone'

import { Box } from '../Box'

export const StyledDropzone = styled(Dropzone).attrs({
  activeClassName: 'docs-dropzone-active',
  acceptClassName: 'accept',
  rejectClassName: 'reject',
  disabledClassName: 'disabled',
})`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px;
  padding: 16px;
  cursor: pointer;
  border: dashed 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  &.docs-dropzone-active {
  }

  &.accept {
    border: dashed 2px ${theme('colors.tealBlue')};
    background-color: rgba(0, 157, 165, 0.2);
    color: ${theme('colors.tealBlue')};

    > svg,
    > div {
      fill: ${theme('colors.tealBlue')};
      color: ${theme('colors.tealBlue')};
    }
  }
  &.reject {
    border: dashed 2px ${theme('colors.roseRed')};
    background-color: rgba(240, 95, 82, 0.2);

    > svg,
    > div {
      fill: ${theme('colors.roseRed')};
      color: ${theme('colors.roseRed')};
    }
  }

  > svg {
    width: 90px;
    height: 90px;
    fill: ${theme('colors.dustyGray')};
  }
`

export const BrowseButton = styled('a')`
  background-color: rgba(98, 2, 238, 0);
  text-align: center;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  text-decoration: underline;
  color: ${theme('colors.tealBlue')};
`

export const Title = styled(Box)`
  letter-spacing: 0.5px;
  margin-top: 0;
  color: ${theme('colors.dustyGray')};
`
