import { styled, themeGet } from '@lyfta/components-theme'
import { Box, Flex } from '@lyfta/components-ui'

export const Container = styled(Flex).attrs(() => ({}))`
  flex-direction: column;
  background-color: ${themeGet('colors.white')};
  border-radius: 4px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  // cursor: pointer;
  flex-shrink: 0;

  min-width: 520px;
  max-width: 520px;
  min-height: min(90vh, 800px);
  :hover {
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  }
`
export const HeaderContainer = styled(Flex).attrs({})`
  @media (min-width: 1440px) and (max-width: 1540px) and (min-height: 700px) and (max-height: 800px) {
    min-height: 45%;
    max-height: 45%;
  }
  min-height: 35%;
  max-height: 35%;
`
export const BodyContainer = styled(Flex).attrs({ px: 4, pt: 4 })`
  min-height: 40%;
  max-height: 40%;
  @media (min-width: 1440px) and (max-width: 1540px) and (min-height: 700px) and (max-height: 800px) {
    min-height: 30%;
    max-height: 30%;
    padding-top: 8px;
  }
  width: 100%;
  flex-direction: column;
  background-color: ${themeGet('colors.white')};
  overflow: auto;
`
export const ContentContainer = styled(Flex).attrs({ mt: 4 })`
  flex-direction: row;
`
export const LeftBlock = styled(Flex).attrs({ pr: 2 })`
  flex-direction: column;
  width: 55%;
  border-right: 1px solid ${themeGet('colors.black_300')};
  color: ${themeGet('colors.black_800')};
`
export const RightBlock = styled(Flex).attrs({ pl: 3 })`
  flex-direction: column;
  width: 45%;
  color: ${themeGet('colors.black_600')};
`
export const Controls = styled(Box).attrs(() => ({ px: 4 }))`
  position: fixed;
  bottom: 0;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  color: ${themeGet('colors.black_800')};

  svg {
    cursor: pointer;
  }
`
export const ControlsButtons = styled(Flex).attrs(() => ({ p: 4 }))`
  width: 100%;
  justify-content: space-around;
  align-items: center;
`

export const ThumbHolder = styled(Box).attrs({})`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  border-radius: 4px 4px 0px 0px;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
`

export const IconContainer = styled(Flex).attrs(() => ({ mr: 2 }))`
  align-items: center;
  height: ${themeGet('lineHeights.7')};
  min-width: 20px;
`

export const Title = styled(Flex).attrs(() => ({}))`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: ${themeGet('lineHeights.7')};
`

export const SubTitle = styled(Flex).attrs(() => ({}))`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  /* or 150% */
  letter-spacing: 0.5px;
`
export const LowerCaseContainer = styled(Flex).attrs(() => ({}))`
  text-transform: lowercase;
`
