import { themeGet } from '@lyfta/components-theme'

export const getSubtypeStyle = p => {
  if (!p.subtype) {
    return {}
  }
  if (!p.secondary) {
    return themeGet(`subtypes.${p.subtype}.primary`)(p)
  }
  return themeGet(`subtypes.${p.subtype}.secondary`)(p)
}
