import { noop } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { GoogleIcon } from './icons'
import { darkStyle, disabledStyle, hoverStyle, lightStyle } from './styles'

const GoogleButton = ({
  style,
  disabled,
  label,
  onClick,
  type,
  ...otherProps
}) => {
  const [hovered, setHovered] = useState(false)
  const handleMouseOver = () => setHovered(true)
  const handleMouseOut = () => setHovered(false)

  const getStyle = useCallback(
    propStyles => {
      const baseStyle = type === 'dark' ? darkStyle : lightStyle
      if (hovered) {
        return { ...baseStyle, ...hoverStyle, ...propStyles }
      }
      if (disabled) {
        return { ...baseStyle, ...disabledStyle, ...propStyles }
      }
      return { ...baseStyle, ...propStyles }
    },
    [hovered, disabled, type],
  )

  return (
    <div
      {...otherProps}
      role="button"
      style={getStyle(style)}
      tabIndex={0}
      onBlur={handleMouseOut}
      onClick={disabled ? noop : onClick}
      onFocus={handleMouseOver}
      onKeyDown={disabled ? noop : onClick}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
    >
      <GoogleIcon />
      <span>{label}</span>
    </div>
  )
}

GoogleButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.object,
  tabIndex: PropTypes.number,
  type: PropTypes.oneOf(['light', 'dark']),
  onClick: PropTypes.func,
}

GoogleButton.defaultProps = {
  label: 'Sign in with Google',
  disabled: false,
  style: {},
  type: 'dark',
  tabIndex: 0,
  onClick: () => {},
}

export default GoogleButton
