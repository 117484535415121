import { styled } from '@lyfta/components-theme'
import {
  AnimatedAbsoluteFlex,
  Flex,
  Header,
  ImageBackground,
  ScrollableFlex,
  Text,
} from '@lyfta/components-ui'
import { themeGet } from '@styled-system/theme-get'

import { Achievement } from '../../../../Assets/QuestTracker'
import TrophyImg from '../trophy.png'

export { Submit } from '../../styles'

export const Container = styled(AnimatedAbsoluteFlex).attrs(p => ({
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: themeGet('colors.blackOpacity80')(p),
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1501,
}))``

export const Content = styled(ScrollableFlex).attrs({
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  pb: 2,
  zIndex: 1,
  marginTop: 30,
})`
  width: 100%;
  overflow: hidden;
`

export const TitleText = styled(Flex).attrs({
  flexDirection: 'column',
  flex: 1,
  m: 3,
})``

export const MainTitle = styled(Header).attrs({ level: 3 })``

export const AchievementContainer = styled(Flex).attrs({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1600,
})`
  height: 100%;
`

export const Paragraph = styled(Text).attrs(p => ({
  color: themeGet('colors.white')(p),
  fontWeight: 'bold',
  fontSize: '24px',
  paddingBottom: '10px',
}))``

export const AchievementImage = styled(Achievement).attrs({
  width: 713,
  height: 679,
})``

export const TrophyImage = styled(ImageBackground).attrs(() => ({
  source: TrophyImg,
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 170,
  minHeight: 170,
}))`
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;
`
