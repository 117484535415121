// import { appEnv } from '@lyfta/components-config'
import React from 'react'
import { Helmet } from 'react-helmet'

export const useTwitter = () => {
  return (
    <Helmet>
      <script src="https://apps.elfsight.com/p/platform.js" defer />
    </Helmet>
  )
}
