import {
  height,
  opacify,
  styled,
  themeGet,
  width,
} from '@lyfta/components-theme'
import ReactTable from 'react-table'

import Arrow from '../Arrow'
import { Box } from '../Box'
import { Flex } from '../Flex'

export const StyledTable = styled(ReactTable)`
  width: 100%;

  &.ReactTable {
    border: 0;
    display: flex;
    flex: 1;
    justify-content: space-between;
    overflow-y: hidden;
  }

  &.ReactTable .rt-table {
    flex: auto 0;
    overflow-y: hidden;
    .rt-tbody {
      overflow: scroll;
    }
  }

  &.ReactTable .rt-thead {
    &.-header {
      box-shadow: none;
    }

    .rt-tr {
      border-bottom: 2px solid ${themeGet('colors.alto')};

      :hover {
        background-color: inherit;
      }
    }

    & .rt-th {
      display: flex;
      outline: none;
      font-weight: bold;
      justify-content: flex-start;
      align-items: center;
      border-right: 0 !important;

      &.-sort-asc {
        box-shadow: inset 0 2px 0 0 ${themeGet('colors.primary')};
      }

      &.-sort-desc {
        box-shadow: inset 0 -2px 0 0 ${themeGet('colors.primary')};
      }
    }
  }

  &.ReactTable .rt-tr-group {
    border: none !important;
  }

  &.ReactTable .rt-tr {
    height: 48px;
    border-bottom: 1px solid ${themeGet('colors.alto')};
    cursor: pointer;

    :hover {
      background-color: ${p =>
        opacify(0.12, themeGet('colors.tableRowHover')(p))};
    }
    &.-selected {
      background-color: ${p =>
        opacify(0.12, themeGet('colors.tableRowHover')(p))};
    }

    & .rt-td {
      border: none !important;
      display: flex;
      outline: none;
      font-weight: normal;
      justify-content: flex-start;
      align-items: center;

      > a {
        text-decoration: none;
      }
    }
  }

  &.ReactTable .rt-noData {
    margin-top: 50px;
    padding: 0;
  }
`

export const PaginationWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 36px;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid ${themeGet('colors.alto')};
  ${height};
  ${width};
`

export const Next = styled(Arrow).attrs(props => ({
  direction: 'right',
  size: 10,
  mr: 4,
  color: props.disabled ? 'primary' : 'dustyGray',
}))``

export const Previous = styled(Arrow).attrs(props => ({
  direction: 'left',
  size: 10,
  mr: 4,
  color: props.disabled ? 'primary' : 'dustyGray',
}))``

export const Current = styled(Box).attrs(() => ({
  mr: 4,
}))`
  color: ${themeGet('colors.dustyGray')};
`

export const PageSize = styled(Flex).attrs(() => ({
  mr: 4,
}))`
  color: ${themeGet('colors.dustyGray')};
`

export const Size = styled(Flex).attrs(() => ({
  ml: 2,
}))`
  justify-content: space-between;
  align-items: center;
  color: ${themeGet('colors.dustyGray')};

  > div {
    margin-left: 7px;
    margin-bottom: 2px;
    border-color: ${themeGet('colors.dustyGray')};
  }
`
