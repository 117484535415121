import { createReducer } from '../../helpers'
import { createEntityFields, createReducerHandlers } from '../../utils'
import { LOAD_SUBJECTS } from '../Actions/subjects'

const initialState = {
  ...createEntityFields('subjects'),
}

const handlers = {
  ...createReducerHandlers('subjects', LOAD_SUBJECTS, {
    withReplace: true,
    mapToKey: 'subjects',
  }),
}

export default createReducer(initialState, handlers)
