import { filter, find, findIndex } from 'lodash'
import { createSelector } from 'reselect'

export const getState = state => state.questTracker

const getTaskIdProp = (state, props) => props.taskId
const getActivityIdProp = (state, props) => props.activityId

export const getCurrentActivityId = createSelector(
  getState,
  state => state.currentActivityId,
)
export const getIsTeachingInFrontOfClass = createSelector(
  getState,
  state => state.isTeachingInFrontOfClass,
)
export const getCurrentActivity = createSelector(
  getState,
  getCurrentActivityId,
  (state, id) => find(state.activities, a => a.id === id),
)

export const getActivityAdvanceType = createSelector(
  getState,
  getCurrentActivity,
  (state, currentActivity) => {
    const prevIndex = findIndex(
      state.activities,
      a => a.id === state.previousActivityId,
    )
    const currentIndex = findIndex(
      state.activities,
      a => a.id === currentActivity?.id,
    )

    return currentIndex > prevIndex ? 'forward' : 'back'
  },
)

export const getCurrentTask = createSelector(getState, state =>
  find(state.tasks, 'selected'),
)

export const getShowAchievement = createSelector(
  getState,
  state => state.showAchievement,
)

export const getLessonCompleted = createSelector(
  getState,
  state => state.lessonCompleted,
)

export const getActivities = createSelector(getState, state => state.activities)

export const getTasks = createSelector(getState, state => state.tasks)

export const getTasksForCurrentActivity = createSelector(
  getState,
  getCurrentActivity,
  (state, currentActivity) =>
    filter(state.tasks, t => t.activityId === currentActivity?.id).sort(
      (a, b) => a.order - b.order,
    ),
)

export const getActivity = createSelector(
  getState,
  getActivityIdProp,
  (state, activityId) => find(state.activities, a => a.id === activityId),
)
export const getCurrentActivityIndex = createSelector(
  getState,
  getCurrentActivityId,
  (state, activityId) => findIndex(state.activities, a => a.id === activityId),
)
export const getTask = createSelector(
  getState,
  getTaskIdProp,
  (state, taskId) => find(state.tasks, t => t.id === taskId),
)
