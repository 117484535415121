import { createLoadHandler, createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import {
  LOAD_LESSON,
  LOAD_LESSONS,
  LOAD_STUDENT_LESSONS,
} from '../Actions/lessons'
import {
  LOAD_DATA,
  LOAD_LESSONS_DATA,
  RESET_CURRENT_LESSON,
} from '../Actions/questTracker'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  lessons: [],
  lessonsRaw: [],
  size: 100,
  number: 1,
  total: 0,
  currentLesson: null,
  lessonSelected: null,
}

const handleFailure = (state, { error }) =>
  state.merge({
    isLoading: false,
    isLoaded: false,
    error,
  })

const handleRequest = state =>
  state.merge({
    error: null,
    isLoading: true,
    isLoaded: false,
    currentLesson: {},
  })

const handleLoad = (state, { lessonData }) =>
  state.merge({
    lessons: lessonData,
  })

const handleLoadLessons = (state, { lessonData }) =>
  state.merge({
    lessons: lessonData,
    lessonsRaw: lessonData,
  })

const resetCurrentLesson = state =>
  state.merge({
    currentLesson: null,
  })

const handlers = {
  [LOAD_STUDENT_LESSONS.REQUEST]: handleRequest,
  [LOAD_STUDENT_LESSONS.SUCCESS]: (state, action) =>
    createLoadHandler('lessons', {
      mapToKey: 'currentLesson',
      singular: true,
    })(state, action),
  [LOAD_STUDENT_LESSONS.FAILURE]: handleFailure,

  [LOAD_LESSON.REQUEST]: handleRequest,
  [LOAD_LESSON.SUCCESS]: (state, action) =>
    createLoadHandler('lessons', {
      mapToKey: 'currentLesson',
      singular: true,
    })(state, action),
  [LOAD_LESSON.FAILURE]: handleFailure,

  [LOAD_LESSONS.REQUEST]: handleRequest,
  [LOAD_LESSONS.SUCCESS]: (state, action) =>
    createLoadHandler('lessons', {
      mapToKey: 'lessons',
      singular: false,
      withReplace: true,
      addToState: {
        isLoaded: true,
        isLoading: false,
      },
    })(state, action),
  [LOAD_LESSONS.FAILURE]: handleFailure,
  [LOAD_DATA]: handleLoad,
  [LOAD_LESSONS_DATA]: handleLoadLessons,
  [RESET_CURRENT_LESSON]: resetCurrentLesson,
  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
