import { I18n, getTranslation } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { Body1Text, SelectorButton, SettingSelectorContainer } from './styles'

// import theme from '@lyfta/components-theme/src/themes/Main'

const fontFamily = [
  {
    label: getTranslation('videoPlayer.labels.fontFamily.pSans'),
    value: 'proportionalSansSerif',
  },
  {
    label: getTranslation('videoPlayer.labels.fontFamily.mSans'),
    value: 'monospaceSansSerif',
  },
  {
    label: getTranslation('videoPlayer.labels.fontFamily.pSerif'),
    value: 'proportionalSerif',
  },
  {
    label: getTranslation('videoPlayer.labels.fontFamily.mSerif'),
    value: 'monospaceSerif',
  },
  {
    label: getTranslation('videoPlayer.labels.fontFamily.casual'),
    value: 'casual',
  },
]

export const FontFamilySelector = ({ player }) => {
  const myTextTrackSettings = player.textTrackSettings.getValues()

  const [currentFontFamily, setCurrentFontFamily] = useState(
    myTextTrackSettings.fontFamily || 'proportionalSansSerif',
  )

  useEffect(() => {
    const newTextTrackSettingsValues = {
      fontFamily: currentFontFamily,
    }

    player.textTrackSettings.setValues(newTextTrackSettingsValues)
    player.textTrackSettings.updateDisplay()
  }, [currentFontFamily])

  const handleClick = font => {
    player.options_.updateUserPreferences({
      fontFamily: font,
    })
    setCurrentFontFamily(font)
  }

  return (
    <>
      <Body1Text>
        <I18n i18nKey="videoPlayer.labels.fontFamilyLabel" />
      </Body1Text>
      <SettingSelectorContainer className="size-selector-container" mb={3}>
        {fontFamily.map(ff => (
          <SelectorButton
            onClick={() => handleClick(ff.value)}
            selected={ff.value === currentFontFamily}
          >
            {ff.label}
          </SelectorButton>
        ))}
      </SettingSelectorContainer>
    </>
  )
}

FontFamilySelector.propTypes = {
  player: PropTypes.object.isRequired,
}
