import { styled } from '@lyfta/components-theme'

import { Box } from '../../BaseComponents'

export const Container = styled(Box).attrs(() => ({ p: 3 }))`
  width: 100%;
  iframe {
    display: flex;
  }
`
