import PT from 'prop-types'
import React from 'react'

import TaskBlock from '../Blocks'
import { TaskNavigation } from '../TaskNavigation'
import { Container } from './styles'

const TasksContainer = ({
  animation,
  animationComplete,
  editingDisabled,
  ...props
}) => {
  return (
    <Container animation={animation}>
      <TaskBlock
        animationComplete={animationComplete}
        editingDisabled={editingDisabled}
      />
      <TaskNavigation {...props} />
    </Container>
  )
}
TasksContainer.propTypes = {
  animation: PT.object.isRequired,
  animationComplete: PT.bool.isRequired,
  editingDisabled: PT.bool,
}
TasksContainer.defaultProps = {
  editingDisabled: false,
}

export default TasksContainer
