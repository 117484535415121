import {
  createDeleteReducerHandlers,
  createEntityFields,
  createReducer,
  createReducerHandlers,
} from '@lyfta/components-data'

import {
  CREATE_COLLECTION,
  DELETE_COLLECTION,
  LOAD_COLLECTION,
  LOAD_COLLECTIONS,
  UPDATE_COLLECTION,
} from '../Actions/collections'

const initialState = {
  ...createEntityFields('collections'),
}

const handlers = {
  ...createDeleteReducerHandlers('collections', DELETE_COLLECTION),
  ...createReducerHandlers('collections', LOAD_COLLECTIONS, {
    withReplace: true,
    mapToKey: 'collections',
  }),
  ...createReducerHandlers('collections', CREATE_COLLECTION, {
    mapToKey: 'currentCollection',
    singular: true,
  }),
  ...createReducerHandlers('collections', LOAD_COLLECTION, {
    mapToKey: 'currentCollection',
    singular: true,
  }),
  ...createReducerHandlers('collections', UPDATE_COLLECTION, {
    mapToKey: 'currentCollection',
    singular: true,
  }),
}

export default createReducer(initialState, handlers)
