import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import { STORYWORLD_TAB } from '../../Actions/ui'

const initialState = {
  activeTab: 0,
}

const setActiveTab = (state, { index }) => state.merge({ activeTab: index })

const handlers = {
  [STORYWORLD_TAB]: setActiveTab,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
