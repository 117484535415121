import { Api as apiCall, createAsyncAction } from '@lyfta/components-data'
import { USER_TYPES } from '@lyfta/components-ui'

import { LOAD_LESSONS_DATA } from './questTracker'

export const LOAD_LESSON = createAsyncAction('lessons/LOAD_LESSON')
export const LOAD_LESSONS = createAsyncAction('lessons/LOAD_LESSONS')
export const LOAD_STUDENT_LESSONS = createAsyncAction(
  'lessons/LOAD_STUDENT_LESSONS',
)

export const loadLesson = id =>
  apiCall({
    method: 'GET',
    endpoint: `/lessons/${id}`,
    query: {
      include:
        'lessonPlan,lessonPlan.activities,lessonPlan.activities.tasks,lessonPlan.activities.tasks.content,lessonPlan.activities.tasks.content.parent,lessonPlan.activities.tasks.content.tags,lessonPlan.activities.tasks.content.tags.tagGroup',
    },
    types: LOAD_STUDENT_LESSONS,
  })

/**
 * @param viewerType {string|null}
 */
export const loadLessons = (
  viewerType = null,
  organizationAdminTeacherId = null,
) => {
  const filter = {
    state: 'active',
    user: 'current',
  }

  if (organizationAdminTeacherId) filter.teacher_id = organizationAdminTeacherId

  return apiCall({
    method: 'GET',
    endpoint: '/lessons',
    query: {
      filter,
      include:
        viewerType === USER_TYPES.homeStudents
          ? 'lessonPlan,klass,lessonPlan.activities,lessonPlan.activities.tasks,lessonPlan.activities.tasks.content,lessonPlan.content'
          : 'lessonPlan,klass',
      sort: '-createdAt',
    },
    types: LOAD_LESSONS,
    paged: false,
  })
}

export const loadStudentLessons = id =>
  apiCall({
    method: 'GET',
    endpoint: `/lessons/${id}`,
    query: {
      filter: {
        user: 'current',
        state: 'active',
      },
      include:
        'lessonPlan,lessonPlan.activities,lessonPlan.activities.tasks,lessonPlan.activities.tasks.content,lessonPlan.activities.tasks.content.tags,lessonPlan.activities.tasks.content.parent,lessonPlan.activities.tasks.content.tags.tagGroup',
    },
    types: LOAD_STUDENT_LESSONS,
  })

export const lessonsData = {
  load: lessonData => ({ type: LOAD_LESSONS_DATA, lessonData }),
}
