import React from 'react'

function SvgVolumeOn(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 16.415L22 9.41504V33.915L15 26.915H8V16.415H15Z"
        fill="#050B15"
      />
    </svg>
  )
}

export default SvgVolumeOn
