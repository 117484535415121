import { noop } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Field } from 'react-final-form'
import InputMask from 'react-input-mask'

import FieldError from '../FieldError'
import { Container, Description, Input, Label, Wrapper } from './styles'

const identity = value => value

const renderInput = (props, active) => {
  const {
    input,
    meta,
    placeholder,
    type,
    fieldRef,
    handleBlur,
    onFocus,
    disabled,
    inputProps,
    mask,
    autoFocus,
    id,
    step,
  } = props

  if (mask) {
    return (
      <InputMask
        mask={mask}
        value=""
        {...input}
        onBlur={() => {
          input.onBlur()
          handleBlur()
        }}
        onFocus={() => {
          input.onFocus()
          onFocus()
        }}
      >
        <Input
          active={active}
          autoFocus={autoFocus}
          mask={mask}
          ref={fieldRef}
          type={type}
          {...inputProps}
          disabled={disabled}
          meta={meta}
          placeholder={placeholder}
          step={step}
        />
      </InputMask>
    )
  }

  return (
    <Input
      active={active}
      autoFocus={autoFocus}
      mask={mask}
      ref={fieldRef}
      type={type}
      {...input}
      {...inputProps}
      disabled={disabled}
      id={id}
      meta={meta}
      placeholder={placeholder}
      step={step}
      onBlur={() => {
        handleBlur()
        input.onBlur()
      }}
      onFocus={() => {
        input.onFocus()
        onFocus()
      }}
    />
  )
}
renderInput.defaultProps = {
  autoFocus: false,
  containerProps: {},
  id: '',
  inputProps: {},
  mask: null,
}
renderInput.propTypes = {
  autoFocus: PropTypes.bool,
  containerProps: PropTypes.object,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  disabled: PropTypes.bool.isRequired,
  fieldRef: PropTypes.object.isRequired,
  focused: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func.isRequired,
  id: PropTypes.string,
  input: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  mask: PropTypes.string,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onLabelClick: PropTypes.func.isRequired,
}

const renderField = props => {
  const {
    description,
    input,
    meta,
    label,
    placeholder,
    type,
    focused,
    fieldRef,
    onBlur,
    onFocus,
    onLabelClick,
    disabled,
    inputProps,
    containerProps,
    ...rest
  } = props

  const active = focused || !isEmpty(input?.value?.toString())
  const hasError = meta.touched && (meta.error || meta.submitError)

  let errorOrDescription = null

  if (hasError) {
    if (typeof meta.error !== 'string' && typeof meta.error[0] !== 'string')
      errorOrDescription = <>{meta.error[0].message} </>
    else errorOrDescription = <FieldError meta={meta} />
  } else if (description) {
    errorOrDescription = (
      <Description active={active}>{description}</Description>
    )
  }

  return (
    <Wrapper {...rest}>
      <Container active={active} error={hasError ? 1 : 0} {...containerProps}>
        {label && (
          <Label active={active} onClick={onLabelClick}>
            {label}
          </Label>
        )}
        {renderInput(props, active)}
      </Container>
      {errorOrDescription}
    </Wrapper>
  )
}
renderField.defaultProps = renderInput.defaultProps
renderField.propTypes = renderInput.propTypes

class AnimatedInput extends PureComponent {
  textField = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      focused: false,
    }
  }

  handleFocus = () => {
    this.setState({ focused: true })
  }

  handleBlur = () => {
    const { onBlur } = this.props
    if (onBlur) onBlur()
    this.setState({ focused: false })
  }

  handleLabelClick = () => {
    this.textField.current.focus()
  }

  render() {
    const {
      allowEmptyField,
      label,
      name,
      placeholder,
      type,
      description,
      id,
      ...rest
    } = this.props
    const { focused } = this.state
    return (
      <Field
        description={description}
        fieldRef={this.textField}
        focused={focused}
        handleBlur={this.handleBlur}
        id={id}
        label={label}
        name={name}
        placeholder={placeholder}
        render={renderField}
        type={type}
        onFocus={this.handleFocus}
        onLabelClick={this.handleLabelClick}
        {...(allowEmptyField && { parse: identity })}
        {...rest}
      />
    )
  }
}

AnimatedInput.defaultProps = {
  allowEmptyField: true,
  description: '',
  id: '',
  label: '',
  mask: null,
  placeholder: '',
  type: 'text',
  onBlur: noop,
}

AnimatedInput.propTypes = {
  allowEmptyField: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  mask: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onBlur: PropTypes.func,
}

export default AnimatedInput
