import { styled, walsheimAndFallbackFontFamily } from '@lyfta/components-theme'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'
import sizes from '@lyfta/components-theme/src/themes/Main/sizes'
import { ToastContainer } from 'react-toastify'

import { Box } from '../../Components'

const headerSize = parseInt(sizes.header.slice(0, -2), 10)

export const Container = styled(Box)`
  height: 100%;
`
export const StyledToast = styled(ToastContainer)`
  top: ${headerSize + 16}px;
  width: auto;
  padding: 0;
  right: 32px;
  .Toastify__toast {
    box-shadow: ${shadows.lifted};
    &:hover {
      box-shadow: ${shadows.raised};
    }
    font-family: ${walsheimAndFallbackFontFamily};
    padding: 0;
    min-height: 0;
    border-radius: 5px;
  }
`
