import {
  createDeleteReducerHandlers,
  createReducerHandlers,
} from '../../../utils'
import {
  CREATE_PARENT_USER,
  DELETE_PARENT_USER,
  LOAD_PARENT_USER,
  LOAD_PARENT_USERS,
  UPDATE_PARENT_USER,
} from '../../Actions/users'

export default {
  ...createDeleteReducerHandlers('parents', DELETE_PARENT_USER, {
    mapToKey: 'parentUsers',
  }),
  ...createReducerHandlers('parents', LOAD_PARENT_USERS, {
    withReplace: true,
    mapToKey: 'parentUsers',
  }),
  ...createReducerHandlers('parents', LOAD_PARENT_USER, {
    singular: true,
    mapToKey: 'currentParentUser',
  }),
  ...createReducerHandlers('parents', CREATE_PARENT_USER, {
    singular: true,
    mapToKey: 'currentParentUser',
  }),
  ...createReducerHandlers('parents', UPDATE_PARENT_USER, {
    singular: true,
    mapToKey: 'currentParentUser',
  }),
}
