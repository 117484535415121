import mainEn from './translations/en.json'
import mainEs from './translations/es.json'
import mainFi from './translations/fi.json'
import mainPt from './translations/pt.json'
import mainRu from './translations/ru.json'
import mainSv from './translations/sv.json'

export const additionalTranslations = [
  { language: 'en', namespace: 'student', translations: mainEn },
  { language: 'fi', namespace: 'student', translations: mainFi },
  { language: 'ru', namespace: 'student', translations: mainRu },
  { language: 'sv', namespace: 'student', translations: mainSv },
  { language: 'pt', namespace: 'student', translations: mainPt },
  { language: 'es', namespace: 'student', translations: mainEs },
]
