import baseColors from '../Main/colors'

const colors = { ...baseColors }

// Use the main theme until the new colours are signed off
// colors.primary = colors.darkRoyalBlue
// colors.alto = colors.lyftaBeige
// colors.tabPrimary = colors.darkGray
//
// colors.font = {
//   primary: colors.grey,
//   accent: colors.doveGray,
//   secondary: colors.darkRoyalBlue,
//   link: colors.doveGray,
// }

export default colors
