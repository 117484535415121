import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import {
  MENU_HIDE,
  MENU_SHOW,
  MENU_TOGGLE,
  MENU_TOGGLE_SETTINGS,
} from '../../Actions/ui'

const initialState = {
  open: true,
  settingsOpen: false,
}

const show = state => state.merge({ open: true })
const hide = state => state.merge({ open: false })
const toggle = state => state.update('open', open => !open)
const toggleSettings = state =>
  state.update('settingsOpen', settingsOpen => !settingsOpen)

const handlers = {
  [MENU_HIDE]: hide,
  [MENU_SHOW]: show,
  [MENU_TOGGLE]: toggle,
  [MENU_TOGGLE_SETTINGS]: toggleSettings,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
