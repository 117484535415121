import * as React from 'react'

function SvgBbfc12A(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3332_6713)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="black"
        />
        <path
          d="M12.0002 23.5849C18.3985 23.5849 23.5853 18.3981 23.5853 11.9998C23.5853 5.6016 18.3985 0.414795 12.0002 0.414795C5.60199 0.414795 0.415186 5.6016 0.415186 11.9998C0.415186 18.3981 5.60199 23.5849 12.0002 23.5849Z"
          fill="white"
        />
        <path
          d="M12.0001 22.5265C17.8137 22.5265 22.5266 17.8136 22.5266 12C22.5266 6.1864 17.8137 1.47354 12.0001 1.47354C6.18649 1.47354 1.47363 6.1864 1.47363 12C1.47363 17.8136 6.18649 22.5265 12.0001 22.5265Z"
          fill="#FF7D13"
        />
        <path
          d="M6.04691 7.65565V16.0819H4.51409V11.2633C4.51409 10.4672 4.51409 10.2301 3.82813 10.2301H2.44775V9.27316H2.60866C3.39624 9.26469 4.45481 9.24775 4.79355 7.66412L6.04691 7.65565Z"
          fill="white"
        />
        <path
          d="M13.16 16.0819H7.42676V14.8709C8.50227 14.1341 9.56084 13.1264 10.0605 12.6098C10.5263 12.1355 11.2969 11.3225 11.2969 10.2132C11.2969 9.56108 10.9751 8.95134 10.1875 8.95134C8.9511 8.95134 8.99344 10.2978 9.00191 10.8991L7.42676 10.7636C7.42676 9.57802 7.81631 8.81585 8.13812 8.42629C8.60389 7.89277 9.39994 7.51168 10.3061 7.51168C11.9066 7.51168 13.016 8.54485 13.016 10.1454C13.016 11.5089 12.1776 12.4827 11.2546 13.3127C11.0682 13.4736 9.94193 14.4221 9.46768 14.7693H13.16V16.0819Z"
          fill="white"
        />
        <path
          d="M18.5633 13.0755L17.4116 9.21383L16.2429 13.0755H18.5633ZM18.9952 14.4389H15.8364L15.3452 16.0819H13.6685L16.497 7.68948H18.2838L21.1462 16.0819H19.4779L18.9952 14.4389Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3332_6713">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgBbfc12A
