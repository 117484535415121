import { merge } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { ThemeProvider as BaseThemeProvider } from 'styled-components'

import GlobalStyle from '../components/GlobalStyle'
import { configureTheme } from '../themes'

export const LyftaThemeProvider = ({ config, children, theme, ...props }) => {
  const defaultTheme = configureTheme(config)
  return (
    <BaseThemeProvider theme={merge(theme, defaultTheme)} {...props}>
      <GlobalStyle />
      {children}
    </BaseThemeProvider>
  )
}
LyftaThemeProvider.defaultProps = { config: {}, theme: {} }
LyftaThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  config: PropTypes.object,
  theme: PropTypes.object,
}
