import { styled } from '@lyfta/components-theme'

import { Flex } from '../Flex'

export const CopyBox = styled(Flex)`
  padding: 2px;
  > * {
    margin: 2px;
  }
`

export const TextBox = styled(Flex).attrs(() => ({}))`
  align-items: center;
  font-weight: bold;
  cursor: pointer;
`
