import { styled } from '@lyfta/components-theme'
import {
  Flex,
  ImageBackground,
  ScrollableFlex,
  Text,
  logoWhite,
} from '@lyfta/components-ui'
import { themeGet as theme } from '@styled-system/theme-get'

import Background from './ipad-screen-background.jpg'

export const LyftaLogo = styled(ImageBackground).attrs(() => ({
  source: logoWhite,
  flex: 1,
  position: 'absolute',
  top: 32,
  left: 32,
  width: 187,
  height: 76,
  resizeMode: 'contain',
}))`
  background-size: contain;
`

export const LinkText = styled(Flex).attrs({
  alignSelf: 'center',
})`
  font-size: 14px;
  font-weight: bold;
  padding-top: 2px;
  margin-left: 14px;
  text-align: left;
  display: block;
  color: ${theme('colors.secondary')};
  text-decoration: none;
  width: 100%;
`

export const DescriptionText = styled(Text).attrs({
  mb: 15,
})`
  font-size: 18px;
  text-align: center;
  color: ${theme('colors.font.outline')};
`

export const Header = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
})``

export const NotSupportedIconContainer = styled(Flex).attrs({
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: 70,
  height: 70,
  minWidth: 70,
  borderRadius: 10,
})`
  padding: 15px;
  margin-left: 0;
  max-width: 70px;
  background: ${theme('colors.primary')};
  border-radius: 10px;
`

export const ExploreIconContainer = styled(NotSupportedIconContainer).attrs({
  width: 68,
  height: 68,
})`
  background: ${theme('colors.tealBlue')};
  borderradius: 10;
  width: 68px;
  height: 68px;
`

export const NotSupportedScreen = styled(ImageBackground).attrs({
  justifyContent: 'center',
  backgroundSize: 'cover',
  backgroundColor: 'rgb(254, 248, 233)',
  source: Background,
})`
  width: 100%;
  height: 100%;
  background-color: ${theme('colors.transparentBackground')};
  position: absolute;
  z-index: 102;
  overflow: auto;
`

export const NotSupportedBackground = styled(Flex).attrs({
  justifyContent: 'center',
})`
  width: 100%;
  height: 100%;
  background-color: ${theme('colors.transparentBackground')};
`

export const NotSupportedContainer = styled(ScrollableFlex).attrs({
  justifyContent: 'flex-start',
  flexDirection: 'column',
  mt: 'auto',
  mb: 'auto',
  pt: 4,
  pb: 4,
})`
  max-height: 98vh;
  overflow: auto;
`

export const ExploreButton = styled(Flex).attrs({
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignSelf: 'center',
  flexDirection: 'row',
  minWidth: '750',
  margin: 0,
  padding: 0,
  mb: 20,
  fontWeight: 'bold',
  background: 'white',
  textAlign: 'left',
  borderRadius: 10,
  fontSize: 4,
})`
  min-width: 670px;
  color: ${theme('colors.codGray')};
  text-align: left;
  text-transform: none;
  position: relative;
  border: 5px solid white;
  cursor: pointer;

  :hover {
    background: #eee;
  }

  span {
    line-height: 20px;
    padding: 8px;
    margin: 8px;
    padding-left: 0;
    display: block;
    font-size: 24px;
  }
`

export const ExploreLink = styled.a`
  display: flex;
  font-size: 14px;
  text-align: left;
  color: ${theme('colors.black')};
  text-decoration: none;
  width: 100%;
  height: 100%;
`

export const MainTitle = styled(Text).attrs({
  lineHeight: '50px',
  fontSize: '44px',
  textAlign: 'center',
  color: 'white',
  mb: '10px',
  fontWeight: 'bold',
})``

export const Description = styled(Flex).attrs({})`
  padding: 20px 30px 20px 30px;
  font-size: 18px;
  text-align: center;
  max-width: 750px;
  color: ${theme('colors.font.outline')};

  > p {
    line-height: 24px;
  }
`

export const Logo = styled(Flex)`
  background: none;
  position: absolute;
  top: 45px;
  left: 45px;

  &.active {
    background: none;
  }

  img {
    height: 64px;
  }
`
