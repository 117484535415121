import React from 'react'

function SvgMascotDefault(props) {
  return (
    <svg
      width="323"
      height="270"
      viewBox="0 0 323 270"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.947 132.285L62.3788 125.98L104.14 234.46L87.7085 240.764L75.6678 209.487L59.5546 167.632C44.2391 173.528 35.4823 194.172 13.6079 185.32L0 149.973C21.8748 158.825 30.6312 138.181 45.947 132.285V132.285Z"
        fill="#050B15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.1565 187.181L90.9651 233.442L96.8108 231.199L59.1227 133.301L47.9781 137.577L47.9258 137.597L53.3783 135.51L73.1565 187.181Z"
        fill="#455368"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.08594 157.792L17.9694 180.868C22.2779 182.213 26.374 182.24 30.762 180.288C34.8653 178.463 38.6437 175.268 42.0917 172.429C46.8757 168.489 51.6649 164.593 57.5182 162.34L47.9848 137.574H47.9841C43.4978 139.301 39.371 142.805 35.6931 145.834C31.3339 149.423 26.967 152.987 21.7621 155.301C17.5212 157.187 13.3075 157.965 9.08632 157.792H9.08594Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.753 75.0637C132.241 77.8326 139.225 86.6307 139.225 96.9821V141.151C139.225 153.705 128.954 163.976 116.4 163.976H96.0546C83.5008 163.976 73.2295 153.705 73.2295 141.151V96.9821C73.2295 84.842 82.835 74.8376 94.8203 74.1911L96.455 70.8929C106.026 51.5837 121.108 35.3525 139.642 24.3619C137.815 18.5059 140.589 12.3348 146.607 9.32628C151.784 6.73787 159.052 4.83581 164.677 3.54578C173.769 1.46057 183.289 0.141345 192.621 0.0105215C194.04 -0.00957599 195.458 -0.00123364 196.876 0.0385821C198.295 -0.00123364 199.713 -0.00957599 201.132 0.0105215C210.463 0.141345 219.983 1.46057 229.076 3.54578C234.701 4.83581 241.969 6.73787 247.146 9.32628C253.164 12.3352 255.937 18.5059 254.111 24.3619C272.645 35.3525 287.727 51.5837 297.298 70.8929L298.933 74.1911C310.918 74.8376 320.523 84.842 320.523 96.9821V141.151C320.523 153.705 310.252 163.976 297.698 163.976H277.352C264.799 163.976 254.528 153.705 254.528 141.151V96.9821C254.528 86.6307 261.512 77.8326 270.999 75.0637C262.783 62.8281 251.659 52.8343 238.575 45.9727L233.72 43.4268L233.21 43.379L232.155 42.9289C220.998 38.1711 209.003 35.7492 196.876 35.7492C184.749 35.7492 172.754 38.1711 161.597 42.9289L160.541 43.379L160.032 43.4268L155.177 45.9727C142.093 52.8343 130.969 62.8285 122.752 75.0637H122.753Z"
        fill="#050B15"
      />
      <path
        d="M297.699 81.5078H277.353C268.807 81.5078 261.879 87.3852 261.879 94.6353V143.498C261.879 150.748 268.807 156.626 277.353 156.626H297.699C306.245 156.626 313.173 150.748 313.173 143.498V94.6353C313.173 87.3852 306.245 81.5078 297.699 81.5078Z"
        fill="#C3AAF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M299.496 77.832H277.353C266.814 77.832 258.203 86.4421 258.203 96.9815V141.151C258.203 151.69 266.813 160.301 277.353 160.301H299.496L300.268 157.654C303.881 145.261 305.422 131.954 305.422 119.066C305.422 106.179 303.88 92.8722 300.268 80.4788L299.496 77.832Z"
        fill="#050B15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M296.739 81.5078H277.352C268.842 81.5078 261.878 88.4714 261.878 96.9821V141.151C261.878 149.663 268.842 156.626 277.352 156.626H296.739C299.91 145.747 301.747 132.871 301.747 119.067C301.747 105.263 299.911 92.3874 296.739 81.5078H296.739Z"
        fill="#455368"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M250.106 30.5312C267.747 40.9232 281.833 56.2439 290.712 74.1565H279.156C269.965 59.2312 257.062 47.3673 241.989 39.4625L250.106 30.5312H250.106Z"
        fill="#C3AAF5"
      />
      <path
        d="M116.4 81.5078H96.0544C87.5082 81.5078 80.5801 87.3852 80.5801 94.6353V143.498C80.5801 150.748 87.5082 156.626 96.0544 156.626H116.4C124.946 156.626 131.874 150.748 131.874 143.498V94.6353C131.874 87.3852 124.946 81.5078 116.4 81.5078Z"
        fill="#C3AAF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.2572 77.832H116.4C126.939 77.832 135.55 86.4421 135.55 96.9815V141.151C135.55 151.69 126.94 160.301 116.4 160.301H94.2568L93.4855 157.654C89.8725 145.261 88.3311 131.954 88.3311 119.066C88.3311 106.179 89.8733 92.8722 93.4855 80.4788L94.2572 77.832Z"
        fill="#050B15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.0139 81.5078H116.4C124.911 81.5078 131.875 88.4714 131.875 96.9821V141.151C131.875 149.663 124.911 156.626 116.4 156.626H97.0135C93.8423 145.747 92.0059 132.871 92.0059 119.067C92.0059 105.263 93.8423 92.3874 97.0135 81.5078H97.0139Z"
        fill="#455368"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.221 177.435C110.148 165.839 104.402 152.797 101.918 138.518C98.5208 118.988 101.717 98.8189 110.67 81.1623C127.184 48.5941 160.39 28.3984 196.877 28.3984C233.363 28.3984 266.57 48.5941 283.083 81.1623C292.036 98.8189 295.233 118.988 291.835 138.518C289.351 152.797 283.605 165.839 275.532 177.435C278.682 180.892 286.005 182.732 290.178 183.207C301.656 184.514 322.949 185.135 322.708 201.317C322.656 204.781 321.743 208.086 319.863 211.008C312.173 222.961 291.946 223.048 279.398 222.315C271.496 221.853 263.508 220.83 255.639 219.468C257.23 222.547 259.131 225.554 261.248 228.552C264.266 232.824 267.644 236.88 270.558 241.207C273.511 245.593 276.089 250.661 275.1 256.117C274.547 259.162 272.981 261.763 270.744 263.869C268.069 266.388 264.906 268.195 261.339 269.107C244.011 273.536 225.615 255.586 215.995 243.265C211.321 237.279 206.018 229.162 202.412 220.786C198.871 221.136 194.883 221.136 191.342 220.786C187.736 229.161 182.433 237.279 177.759 243.265C168.139 255.586 149.743 273.536 132.415 269.107C128.848 268.195 125.685 266.389 123.01 263.869C120.772 261.763 119.206 259.162 118.654 256.117C117.665 250.661 120.242 245.592 123.196 241.207C126.11 236.88 129.488 232.824 132.506 228.552C134.623 225.554 136.524 222.547 138.115 219.468C130.246 220.83 122.258 221.853 114.356 222.315C101.808 223.048 81.5809 222.961 73.8907 211.008C72.0107 208.086 71.0979 204.781 71.046 201.317C70.8044 185.136 92.0983 184.514 103.576 183.207C107.75 182.732 115.072 180.892 118.222 177.435H118.221Z"
        fill="#050B15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.877 35.75C120.819 35.75 72.8255 128.441 139.787 191.055C134.172 188.795 129.005 185.702 124.524 181.334C124.524 181.334 119.358 188.809 104.407 190.511C89.4563 192.213 78.2738 193.064 78.3955 201.207C78.7216 223.06 131.076 213.52 145.005 210.689C148.027 205.915 149.52 200.633 150.353 195.13C153.754 230.825 116.398 247.547 128.049 258.518C147.487 276.822 188.115 226.245 188.277 203.274C189.094 206.409 189.459 209.644 189.386 213.104C193.564 213.869 200.189 213.869 204.368 213.104C204.295 209.644 204.66 206.409 205.476 203.274C205.639 226.245 246.266 276.822 265.705 258.518C277.356 247.547 239.999 230.825 243.4 195.13C244.234 200.633 245.727 205.915 248.749 210.689C262.677 213.52 315.032 223.061 315.359 201.207C315.48 193.064 304.297 192.213 289.347 190.511C274.396 188.809 269.23 181.334 269.23 181.334C264.749 185.702 259.582 188.795 253.966 191.055C320.929 128.441 272.935 35.75 196.877 35.75H196.877Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.022 145.43C115.418 161.229 124.661 176.909 139.787 191.053C134.255 188.826 129.158 185.792 124.724 181.526C125.438 184.343 130.684 207.251 112.44 207.666C93.6901 208.092 86.8463 205.907 78.7979 204.15C83.6834 222.153 131.748 213.382 145.005 210.687C148.027 205.914 149.52 200.631 150.353 195.128C150.003 192.239 161.847 187.092 168.089 197.15C185.415 225.066 143.135 256.319 133.578 251.83C129.981 250.141 130.989 242.955 130.998 242.894C126.245 249.399 123.645 254.369 128.049 258.516C147.487 276.821 188.115 226.243 188.277 203.272C189.094 206.407 189.459 209.642 189.386 213.102C191.475 213.485 194.176 213.676 196.877 213.676C199.578 213.676 202.279 213.485 204.368 213.102C204.295 209.642 204.66 206.407 205.477 203.272C205.639 226.243 246.266 276.821 265.705 258.516C270.109 254.369 267.509 249.399 262.756 242.894C262.765 242.955 263.773 250.141 260.175 251.83C250.619 256.319 208.338 225.066 225.665 197.15C231.907 187.091 243.751 192.239 243.4 195.128C244.234 200.631 245.727 205.914 248.749 210.687C262.006 213.382 310.07 222.152 314.956 204.15C306.907 205.907 300.064 208.092 281.315 207.666C263.069 207.251 268.316 184.343 269.03 181.526C264.596 185.792 259.499 188.827 253.966 191.053C269.093 176.909 278.336 161.229 282.732 145.43C268.399 168.882 232.424 184.938 196.877 184.938C161.331 184.938 125.356 168.882 111.022 145.43H111.022Z"
        fill="#DAD6D4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.56 167.906C189.107 168.388 193.014 168.755 196.877 168.825C200.74 168.755 204.647 168.388 208.195 167.906C205.063 170.817 200.88 172.33 196.877 172.335C192.875 172.33 188.691 170.817 185.56 167.906Z"
        fill="#DAD6D4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.714 36.1691L158.702 36.1702L147.463 23.8023C147.463 23.8023 144.06 18.8189 149.894 15.9017C155.729 12.9845 176.821 6.78503 196.877 7.39327C216.933 6.78503 238.025 12.9845 243.86 15.9017C249.694 18.8193 246.29 23.8023 246.29 23.8023L235.051 36.1702L235.039 36.1691C223.201 31.1208 210.26 28.4001 196.877 28.4001C183.494 28.4001 170.553 31.1204 158.715 36.1691H158.714Z"
        fill="#455368"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.647 30.5312C126.006 40.9232 111.92 56.2439 103.041 74.1565H114.597C123.788 59.2312 136.691 47.3673 151.764 39.4625L143.647 30.5312H143.647Z"
        fill="#C3AAF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.979 144.215C174.616 154.994 185.696 160.066 196.876 159.758C208.057 160.066 219.137 154.994 223.774 144.215C216.088 150.842 206.52 154.123 196.876 154.059C187.232 154.122 177.664 150.842 169.979 144.215Z"
        fill="#050B15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.101 203.125C101.251 202.914 101.397 202.699 101.538 202.482C103.694 199.157 104.703 195.074 103.123 191.272C101.49 187.343 97.8091 185.296 93.7107 184.96C85.2971 184.27 75.8467 187.451 70.3317 193.991C68.2681 196.438 66.9155 199.331 66.3527 202.481C65.4537 207.517 66.6531 212.701 69.0833 217.144C72.8685 224.065 80.3702 230.252 88.7049 229.242C91.8921 228.855 94.7569 227.432 97.0685 225.216C99.0775 223.289 100.513 220.937 101.348 218.286C102.127 215.811 102.336 213.235 102.199 210.655C102.089 208.597 101.761 206.588 101.386 204.565C101.309 204.15 101.198 203.638 101.101 203.126V203.125Z"
        fill="#050B15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.7132 219.634C97.9794 213.626 91.3935 202.154 93.6646 199.964C95.9356 197.774 98.1417 193.089 93.0779 192.673C86.8018 192.158 79.9266 194.617 76.2453 198.982C67.7775 209.024 83.2688 227.731 91.7132 219.634Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.7134 219.634C97.9797 213.625 91.3937 202.153 93.6648 199.963C95.3977 198.292 97.0916 195.169 95.5494 193.598C95.1322 195.054 94.4 196.143 92.4657 197.513C90.5311 198.883 91.9261 209.232 86.7031 213.167C82.8644 216.058 79.2241 217.547 76.0586 213.766C79.6815 220.086 86.8604 224.287 91.7134 219.634Z"
        fill="#CCCCCC"
      />
      <path
        d="M149.151 131.5C161.344 131.5 171.228 120.262 171.228 106.4C171.228 92.5382 161.344 81.3008 149.151 81.3008C136.958 81.3008 127.074 92.5382 127.074 106.4C127.074 120.262 136.958 131.5 149.151 131.5Z"
        fill="#050B15"
      />
      <path
        d="M151.442 131.499C162.37 131.499 171.228 121.427 171.228 109.003C171.228 96.5794 162.37 86.5078 151.442 86.5078C140.514 86.5078 131.655 96.5794 131.655 109.003C131.655 121.427 140.514 131.499 151.442 131.499Z"
        fill="white"
      />
      <path
        d="M154.572 123.717C160.192 123.717 164.748 118.432 164.748 111.913C164.748 105.394 160.192 100.109 154.572 100.109C148.952 100.109 144.396 105.394 144.396 111.913C144.396 118.432 148.952 123.717 154.572 123.717Z"
        fill="#050B15"
      />
      <path
        d="M150.392 109.005C153.37 109.005 155.785 107.195 155.785 104.963C155.785 102.731 153.37 100.922 150.392 100.922C147.414 100.922 144.999 102.731 144.999 104.963C144.999 107.195 147.414 109.005 150.392 109.005Z"
        fill="white"
      />
      <path
        d="M244.603 131.5C256.796 131.5 266.68 120.262 266.68 106.4C266.68 92.5382 256.796 81.3008 244.603 81.3008C232.411 81.3008 222.526 92.5382 222.526 106.4C222.526 120.262 232.411 131.5 244.603 131.5Z"
        fill="#050B15"
      />
      <path
        d="M242.313 131.499C253.241 131.499 262.099 121.427 262.099 109.003C262.099 96.5794 253.241 86.5078 242.313 86.5078C231.385 86.5078 222.526 96.5794 222.526 109.003C222.526 121.427 231.385 131.499 242.313 131.499Z"
        fill="white"
      />
      <path
        d="M239.183 123.717C244.803 123.717 249.359 118.432 249.359 111.913C249.359 105.394 244.803 100.109 239.183 100.109C233.563 100.109 229.007 105.394 229.007 111.913C229.007 118.432 233.563 123.717 239.183 123.717Z"
        fill="#050B15"
      />
      <path
        d="M243.363 109.005C246.341 109.005 248.756 107.195 248.756 104.963C248.756 102.731 246.341 100.922 243.363 100.922C240.384 100.922 237.97 102.731 237.97 104.963C237.97 107.195 240.384 109.005 243.363 109.005Z"
        fill="white"
      />
    </svg>
  )
}

export default SvgMascotDefault
