import { Settings } from 'luxon'
import { DateTime } from 'luxon-business-days'

import { dateLocales } from '../../resources'
import { getTranslation } from '../translation'

export const isValidDate = dateObject => {
  return new Date(dateObject).toString() !== 'Invalid Date'
}
export const setDateLocale = language => {
  let newLanguage = language

  if (newLanguage === 'en') {
    newLanguage = 'en-gb'
  }

  Settings.defaultLocale = newLanguage
}

setDateLocale()

export const formatDate = (
  dt,
  formatString,
  keepLocal = false,
  zone = 'local',
) => {
  let luxonDate

  if (typeof dt === 'string') {
    luxonDate = DateTime.fromISO(dt, { setZone: true })
  }

  if (dt instanceof Date) {
    luxonDate = DateTime.fromJSDate(dt)
  }

  luxonDate = luxonDate.setZone(zone, { keepLocalTime: keepLocal })

  if (luxonDate) {
    return luxonDate.toLocaleString(formatString)
  }

  if (luxonDate !== null) {
    // eslint-disable-next-line no-console
    console.warn('invalid date passed')
    return null
  }

  return ''
}

const fixLocale = datetime => {
  try {
    datetime.toLocaleString(DateTime.DATE_MED)
    return datetime
  } catch (e) {
    return datetime.setLocale('en-GB')
  }
}

// Getting a date with a specific format.
export const globalFormatDate = dt => {
  let luxonDate

  if (typeof dt === 'string') {
    luxonDate = fixLocale(
      DateTime.fromISO(dt, {
        setZone: true,
      }),
    ).toLocaleString(DateTime.DATE_MED)
  }

  if (dt instanceof Date) {
    luxonDate = DateTime.fromJSDate(dt).toLocaleString(DateTime.DATE_MED)
  }

  if (luxonDate) {
    return luxonDate
  }

  return ''
}

export const formatDateHoursMinutes = dt => {
  let luxonDate

  if (typeof dt === 'string') {
    luxonDate = fixLocale(
      DateTime.fromISO(dt, {
        setZone: true,
      }),
    ).toLocaleString(DateTime.TIME_SIMPLE)
  }

  if (luxonDate) {
    return luxonDate
  }

  return ''
}

export const formatDateHoursMinutesSeconds = dt => {
  let luxonDate

  if (typeof dt === 'string') {
    luxonDate = fixLocale(
      DateTime.fromISO(dt, {
        setZone: true,
      }),
    ).toLocaleString(DateTime.TIME_WITH_SECONDS)
  }

  if (luxonDate) {
    return luxonDate
  }

  return ''
}

export const FormatUkDate = dateStr => {
  if (dateStr) {
    const dateArr = dateStr.split('/')
    return new Date(dateArr[2], dateArr[1] - 1, dateArr[0])
  }
  return false
}

export const isExpired = date => {
  let ukDateObject = FormatUkDate(date)
  if (!isValidDate(ukDateObject)) {
    ukDateObject = new Date(date)
  }
  if (!ukDateObject || !isValidDate(ukDateObject)) return ''

  return ukDateObject.getTime() < new Date().getTime()
    ? `[${getTranslation('actions.expiredAbbreviation')}]`
    : ''
}

export const daysLeft = (date1, date2, addExpired = false, addMessage) => {
  let ukDateObject1 = FormatUkDate(date1)
  let ukDateObject2 = FormatUkDate(date2)
  const nowDate = new Date()
  let result = ''
  if (!isValidDate(ukDateObject1)) {
    ukDateObject1 = new Date(date1)
  }
  if (!isValidDate(ukDateObject2)) {
    ukDateObject2 = new Date(date2)
  }

  if (
    !ukDateObject1 ||
    !isValidDate(ukDateObject1) ||
    !ukDateObject2 ||
    !isValidDate(ukDateObject2)
  )
    return ''

  const difference = ukDateObject2.getTime() - nowDate.getTime()

  const dLeft = Math.ceil(difference / (1000 * 3600 * 24))

  if (dLeft >= 0) result = dLeft

  if (addMessage) {
    const message = dLeft >= 0 ? ` ${addMessage}` : ''
    result += message
  }

  if (addExpired)
    result += dLeft < 0 ? ` [${getTranslation('actions.expired')}]` : ''

  return result
}

const DateTimeLocal = DateTime.local()
const { availableHolidayMatchers } = DateTimeLocal
const myCompanyIsNoFun = [
  availableHolidayMatchers.isNewYearsDay,
  availableHolidayMatchers.isChristmasDay,
]
DateTimeLocal.setupBusiness({ holidayMatchers: myCompanyIsNoFun })

export { DateTime, DateTimeLocal, dateLocales }
