import { styled, themeGet } from '@lyfta/components-theme'
import { Flex, Subtitle1 } from '@lyfta/components-ui'

export const Container = styled(Flex)`
  max-height: 40px;
  width: 100%;
  background-color: ${themeGet('colors.orange_100')};
  justify-content: center;
  z-index: 1;
`

export const TextContainer = styled(Subtitle1)`
  align-items: center;
  color: ${themeGet('colors.neutral_100')};
  display: flex;
`

Container.displayName = 'PreviewBanner:Container'
TextContainer.displayName = 'PreviewBanner:TextContainer'
