import * as React from 'react'

function SvgCheck(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 100 100"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.795 40.28c1.88-2.487 3.354-2.669 5.03-1.032l13.231 12.208c2.374 2.43 3.602 3.11 5.545.257C52.423 34.87 72.503 19.4 81.411 13.064c3.812-2.506 4.466.082 5.181.971 2.483 3.746 6.91 11.171 7.934 16.333.5 2.748 1.283 5.093-1.78 6.791-15.978 8.086-36.612 31.614-50.363 49.483-1.835 2.346-4.926 1.295-5.992 0-8.225-10.182-18.195-19.46-28.258-26.075-3.307-1.94-4.164-3.362-1.761-7.042 3.069-4.778 6.868-7.779 10.423-13.245z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCheck
