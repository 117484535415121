import React from 'react'

export const LogoRectangle = () => {
  return (
    <svg
      fill="none"
      height="184"
      viewBox="0 0 131 184"
      width="131"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M99.55 0.0400085H86.67V70.45H130.87V58.24H99.54L99.55 0.0400085Z"
        fill="white"
      />
      <path d="M0 113.52V183.93H44.2V171.76H12.87V113.52H0Z" fill="white" />
      <path
        d="M118 113.52H130.87V183.93H86.67V171.76H118V113.52Z"
        fill="white"
      />
      <path d="M12.89 70.4H0.0200195V0H44.23V12.17H12.89V70.4Z" fill="white" />
    </svg>
  )
}
