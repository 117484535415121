import React from 'react'
import { Portal } from 'react-portal'

import { ModalButtons } from '../../Components/Modal/ModalButtons'
import { PropTypes } from '../../Helpers/prop-types'

export const ModalControls = ({
  modalProps,
  handleSubmit,
  invalid,
  pristine,
}) => {
  const modalSubmit = () => {
    handleSubmit()
    modalProps.handleCancel()
  }
  return (
    <Portal node={document && document.getElementById('modal-buttons')}>
      <ModalButtons
        handleOk={modalSubmit}
        invalid={invalid}
        pristine={pristine}
        {...modalProps}
      />
    </Portal>
  )
}

ModalControls.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
}
