import * as React from 'react'

function SvgWonde(props) {
  return (
    <svg
      id="uuid-2eb58e17-cadd-4708-a31a-9af4497a4436"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="max-w-[12rem] sm:max-w-[70%]"
      {...props}
    >
      <path
        d="M22.99,2.37c0-.36-.14-.71-.4-.97s-.61-.4-.97-.4h-2.75c-.36,0-.71.14-.97.4s-.4.61-.4.97v2.06c0,.18-.07.36-.2.49-.13.13-.3.2-.49.2h-1.38c-.18,0-.36-.07-.49-.2-.13-.13-.2-.3-.2-.49v-2.06c0-.36-.14-.71-.4-.97-.26-.26-.61-.4-.97-.4h-2.75c-.36,0-.71.14-.97.4s-.4.61-.4.97v2.06c0,.18-.07.36-.2.49-.13.13-.3.2-.49.2h-1.38c-.18,0-.36-.07-.49-.2s-.2-.3-.2-.49v-2.06c0-.36-.14-.71-.4-.97-.26-.26-.61-.4-.97-.4h-2.75c-.36,0-.71.14-.97.4s-.4.61-.4.97v2.75c0,1.38,1.38,2.75,1.38,4.13s-1.38,5.5-1.38,11v1.38c0,.36.14.71.4.97s.61.4.97.4h4.13c.36,0,.71-.14.97-.4s.4-.61.4-.97v-2.75c0-3.44,1.38-5.5,4.13-5.5s4.12,2.06,4.12,5.5v2.75c0,.36.14.71.4.97s.61.4.97.4h4.12c.36,0,.71-.14.97-.4s.4-.61.4-.97v-1.38c0-5.5-1.38-9.62-1.38-11s1.38-2.75,1.38-4.13v-2.75h.01ZM18.65,10.25l-1,.33s-.09.04-.12.08c-.04.04-.06.08-.08.12l-.33,1c-.02.06-.06.12-.12.16-.05.04-.12.06-.19.06s-.13-.02-.19-.06c-.05-.04-.1-.1-.12-.16l-.34-1s-.04-.09-.08-.13c-.04-.04-.08-.06-.13-.08l-1-.33c-.06-.02-.12-.06-.16-.12-.04-.05-.06-.12-.06-.19s.02-.13.06-.19c.04-.05.1-.1.16-.12l1-.34s.09-.04.12-.08c.04-.03.06-.08.08-.12l.34-1.01c.02-.06.06-.12.12-.16.05-.04.12-.06.19-.06s.13.02.19.06c.05.04.1.1.12.16l.33,1.01s.04.09.08.12c.04.03.08.06.12.08l1,.34c.06.02.12.06.16.12.04.05.06.12.06.19s-.02.13-.06.19c-.04.05-.1.09-.16.11v.02Z"
        style={{ fill: '#00d37f', fillRule: 'evenodd', strokeWidth: 0 }}
      />
    </svg>
  )
}

export default SvgWonde
