import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import {
  FULLSCREEN_OFF,
  FULLSCREEN_ON,
  FULLSCREEN_TOGGLE,
} from '../../Actions/ui'

const initialState = {
  fullscreen: false,
}

const on = state => state.merge({ fullscreen: true })
const off = state => state.merge({ fullscreen: false })
const toggle = state => state.update('fullscreen', fullscreen => !fullscreen)

const handlers = {
  [FULLSCREEN_ON]: on,
  [FULLSCREEN_OFF]: off,
  [FULLSCREEN_TOGGLE]: toggle,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
