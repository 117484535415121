export const COMPLETE_REHYDRATION = 'app/COMPLETE_REHYDRATION'
export const COMPLETE_REFETCH = 'app/COMPLETE_REFETCH'
export const STARTUP = 'app/STARTUP'

export const completeRehydration = () => ({
  type: COMPLETE_REHYDRATION,
})

export const completeRefetch = () => ({
  type: COMPLETE_REFETCH,
})

export const startup = () => ({
  type: STARTUP,
})
