import { createReducer } from '../../helpers'
import { createEntityFields, createReducerHandlers } from '../../utils'
import { LOAD_EVENTS } from '../Actions/events'

const initialState = {
  ...createEntityFields('events'),
}

const handlers = {
  ...createReducerHandlers('events', LOAD_EVENTS, {
    withReplace: true,
    mapToKey: 'events',
  }),
}

export default createReducer(initialState, handlers)
