import { css, styled, themeGet } from '@lyfta/components-theme'
import PropTypes from 'prop-types'

import { Box } from '../Box'

const DIRECTIONS = {
  left: 45,
  up: 135,
  right: 225,
  down: -45,
}

const getDirections = direction => DIRECTIONS[direction]

const Arrow = styled(Box)`
  flex-shrink: 0;
  vertical-align: middle;
  border-left: 3px solid
    ${props =>
      props.color
        ? themeGet(`colors.${props.color}`)
        : themeGet('colors.primary')};
  border-bottom: 3px solid
    ${props =>
      props.color
        ? themeGet(`colors.${props.color}`)
        : themeGet('colors.primary')};
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;

  ${props =>
    props.direction &&
    css`
      transform: rotate(${getDirections(props.direction)}deg);
    `};
`

Arrow.displayName = 'Arrow'

Arrow.defaultProps = {
  direction: 'left',
  width: 14,
  height: 14,
}

Arrow.propTypes = {
  direction: PropTypes.oneOf(['left', 'up', 'right', 'down']),
  height: PropTypes.number,
  size: PropTypes.number,
  width: PropTypes.number,
}

export default Arrow
