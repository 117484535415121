import * as React from 'react'

function SvgArrowLeft(props) {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25462 0.00195306L7.74011 1.48826L3.23085 6L7.74011 10.5117L6.25462 11.998L0.259869 6L6.25462 0.00195306Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgArrowLeft
