import styledPropTypes from '@styled-system/prop-types'
import { flatten, map, pick } from 'lodash'

export { themeGet } from '@styled-system/theme-get'

export * from './components'

export const walsheimAndFallbackFontFamily = `'Walsheim', "Helvetica Neue", Arial, Helvetica, sans-serif`
export const gtWalsheimAndFallbackFontFamily = `GT Walsheim, "Helvetica Neue", Arial, Helvetica, sans-serif`
export const nativeFontFamily = `'GT Walsheim'`

export {
  adjustHue,
  complement,
  darken,
  desaturate,
  getContrast,
  getLuminance,
  grayscale,
  hsl,
  hsla,
  hslToColorString,
  invert,
  lighten,
  meetsContrastGuidelines,
  mix,
  opacify,
  parseToHsl,
  parseToRgb,
  readableColor,
  rgb,
  rgba,
  rgbToColorString,
  saturate,
  setHue,
  setLightness,
  setSaturation,
  shade,
  tint,
  toColorString,
  transparentize,
} from 'polished'

export * from 'styled-system'
export * from 'styled-map'

export { styledPropTypes }

export const getStylingProps = props => {
  const rootKeys = Object.keys(styledPropTypes)
  const keys = flatten(map(rootKeys, k => Object.keys(styledPropTypes[k])))

  return pick(props, keys)
}
