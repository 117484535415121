import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import React from 'react'

import { DropDown, Input, ListItem } from '../styles'

export const FormField = ({
  autoFocus,
  highlightedIndex,
  isOpen,
  items,
  getInputProps,
  getItemProps,
  getMenuProps,
  onFocus,
  placeholder,
}) => {
  return (
    <>
      <Input
        autoComplete="off"
        autoFocus={autoFocus}
        {...getInputProps()}
        placeholder={placeholder}
        onFocus={onFocus}
      />
      <DropDown open={isOpen && items.length > 0} {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => {
            return (
              <ListItem
                active={highlightedIndex === index}
                key={`${nanoid()}`}
                {...getItemProps({ item, index })}
                item={item}
              >
                {item.name}
              </ListItem>
            )
          })}
      </DropDown>
    </>
  )
}

FormField.defaultProps = { autoFocus: false, placeholder: '' }
FormField.propTypes = {
  autoFocus: PropTypes.bool,
  getInputProps: PropTypes.func.isRequired,
  getItemProps: PropTypes.func.isRequired,
  getMenuProps: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  onFieldBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
}
