/* eslint-disable no-nested-ternary */
import { css, styled, themeGet } from '@lyfta/components-theme'
import { fontWeights } from '@lyfta/components-theme/src/themes/Main/fontSizes'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'
import { merge } from 'lodash'
import PropTypes from 'prop-types'

import { Button as BaseButton } from '../../BaseComponents'

const getButtonStyle = p => {
  let styleKey = 'buttonStyle.default'

  if (p.monochrome) styleKey = 'buttonStyle.monochrome'
  if (p.monochromeInverse) styleKey = 'buttonStyle.monochromeInverse'
  if (p.inverse) styleKey = 'buttonStyle.inverse'
  if (p.delete && p.rank === 'primary') styleKey = 'buttonStyle.primaryDelete'
  if ((!p.delete && p.rank === 'primary') || p.rank === 'cta')
    styleKey = 'buttonStyle.primary'
  if (!p.delete && p.rank === 'secondary') styleKey = 'buttonStyle.secondary'
  if (p.delete && p.rank === 'secondary')
    styleKey = 'buttonStyle.secondaryDelete'

  const buttonStyle = themeGet(styleKey)(p)
  return buttonStyle
}

export const Button = styled(BaseButton).attrs(p => {
  const buttonStyle = getButtonStyle(p)
  return merge(
    {
      minHeight: '36px',
      lineHeight: 2,
      px: p.px ? p.px : 3,
      py: p.py ? p.py : 2,
      fontSize: p.fontSize ? p.fontSize : 3,
    },
    buttonStyle.style,
    p,
  )
})`
  position: relative;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-align: center;
  user-select: none;
  align-self: ${props => props.centered && 'center'};
  transition: all ${themeGet('transitionTime')};
  z-index: 0;
  flex-shrink: 0;
  border-width: 2px;

  ${p => {
    if (!p.rank) {
      return css`
        :disabled {
          opacity: 0.5;
          cursor: default;
          box-shadow: none;
        }
        ${props =>
          props?.backgroundColor
            ? `background-color: ${themeGet(
                `colors.${props?.backgroundColor}`,
              )};`
            : ''}
        ${props => props.shadow && themeGet('buttonShadow.default')};
        &:focus,
        &:hover,
        &:active {
          box-shadow: none;
          ${props =>
            !props.onColouredBg
              ? 'opacity: 0.7;'
              : !p.disabled
              ? 'background-color: rgba(255, 255, 255, 0.2);'
              : ''}
        }
        &:focus:enabled,
        &:active:enabled {
          outline: none;
        }
        > span {
          width: 100%;
          pointer-events: none;
        }
      `
    }
    return css``
  }}

  ${p => {
    const buttonStyle = getButtonStyle(p)
    if (!p.rank && p.noborder) {
      return css`
        border: none;
      `
    }
    if (!p.rank && !p.noborder) {
      return css`
        &:hover:enabled {
          border: 2px solid
            ${themeGet(`colors.${buttonStyle.hover.borderColor}`)(p)};
          background-color: ${themeGet(
            `colors.${buttonStyle?.hover?.backgroundColor}`,
          )(p)};
          color: ${themeGet(`colors.${buttonStyle.hover.color}`)(p)};
        }
      `
    }
    return css``
  }} 

  ${props =>
    props.light &&
    css`
      background: ${themeGet('colors.white')};
      color: ${themeGet('colors.secondary')};
    `};

  ${props =>
    props.link &&
    css`
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;
    `};
    
  ${props => {
    const buttonStyle = getButtonStyle(props)
    if (props.rank) {
      return css`
        align-items: center;
        justify-content: center;
        font-weight: ${fontWeights.tableActionButton};
        line-height: 20px;
        letter-spacing: 1.5px;
        ${
          props.rank === 'cta'
            ? css`
                border-radius: 50px;
                padding: 10px 32px 10px 32px;
              `
            : css`
                border-radius: 5px;
                padding: 8px 20px 8px 20px;
              `
        }
        ${
          navigator.maxTouchPoints > 0 &&
          css`
            padding-top: 14px;
            padding-bottom: 14px;
          `
        }
        &:disabled,
        &:disabled:hover {
          color: ${themeGet('colors.black_600')};
          cursor: default;
          svg * {
            fill:  ${themeGet('colors.black_600')};
          }
          background-color: ${themeGet('colors.neutral_300')};
        }
        &:enabled {
          box-shadow: ${shadows.lifted};
          box-shadow: ${
            props.rank === 'secondary' &&
            css`${shadows.lifted}, 0px 0px 0px 2px ${themeGet(
              `colors.${buttonStyle.style.color}`,
            )} inset`
          };
          svg * {
            fill: ${themeGet(`colors.${buttonStyle.style.color}`)};
          }
          &:hover {
            background-color: ${themeGet(
              `colors.${buttonStyle?.hover?.backgroundColor}`,
            )};
            color: ${themeGet(`colors.${buttonStyle.hover.color}`)};
            box-shadow: ${shadows.raised};
            svg * {
              fill: ${themeGet(`colors.${buttonStyle.hover.color}`)};
            }
          }
          &:active {
            background-color: ${themeGet(
              `colors.${buttonStyle?.active?.backgroundColor}`,
            )};
            color: ${themeGet(`colors.${buttonStyle.active.color}`)};
            box-shadow: ${shadows.skim};
            svg * {
              fill: ${themeGet(`colors.${buttonStyle.active.color}`)};
            }
          }
          &:focus-visible {
            background-color: ${themeGet(
              `colors.${buttonStyle?.focusVisible?.backgroundColor}`,
            )};
            color: ${themeGet(`colors.${buttonStyle.focusVisible.color}`)};
            box-shadow: ${shadows.raised};
            svg * {
              fill: ${themeGet(`colors.${buttonStyle.focusVisible.color}`)};
            }
            &::before {
              content: '';
              top: -3px;
              left: -3px;
              right: -3px;
              bottom: -3px;
              border: 1px solid
                ${themeGet(`colors.${buttonStyle.focusVisible.borderColor}`)};
                ${
                  props.rank === 'cta'
                    ? css`
                        border-radius: 50px;
                      `
                    : css`
                        border-radius: 5px;
                      `
                }
              box-shadow: 0px 0px 3px
                ${themeGet(
                  `colors.${buttonStyle.focusVisible.focusRingColor}`,
                )};
              position: absolute;
            }
          }
        }
      `
    }
    return css``
  }}
`

Button.defaultProps = {
  bg: 'buttonBG',
}

Button.propTypes = {
  bg: PropTypes.string,
  centered: PropTypes.bool,
  shadow: PropTypes.bool,
}

Button.displayName = 'Button'
