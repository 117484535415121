import { denormalize } from '@lyfta/components-data'
import { I18n, Translation, getTranslatedField } from '@lyfta/components-i18n'
import _, { map } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { loadExperiences } from '../../Store/Actions/experiences'
import {
  HomeHeader,
  HomeLessonButtonSpacer,
  HomeLessonPageHeader,
  HomeLessonRowContainer,
  HomeLessonsBackground,
  HomeLessonsContainer,
  HomeLessonsScreen,
  HomeMainTitle,
  HomeStudentLogoutButton,
  HomeStudentLyftaLogo,
  HomeSubTitle,
  LessonSelectorMenuRight,
} from './home-styles'
import HomeStudentLessonRow from './Row/HomeStudentLessonRow'

const experienceCache = {}

export function HomeLessonsScreenComponent({
  viewer,
  lessons,
  handleLessonButton,
  viewerType,
}) {
  const [sortedLessons, setSortedLessons] = useState([])

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!lessons || !lessons.length) {
      setLoading(true)
      return undefined
    }
    setLoading(true)

    const cancelInfo = {
      canceled: false,
    }
    ;(async () => {
      const experiences = await Promise.all(
        lessons.map(async lesson => {
          if (!experienceCache[lesson.id]) {
            experienceCache[lesson.id] = new Promise(resolve => {
              dispatch(
                loadExperiences(lesson.id, null, result => {
                  resolve({
                    id: lesson.id,
                    experiences:
                      denormalize(
                        result.payload.data,
                        'experiences',
                        Object.values(result.payload.data.experiences || {}),
                      ) || [],
                  })
                }),
              )
            })
          }

          return experienceCache[lesson.id]
        }),
      )
      if (cancelInfo.canceled) {
        return
      }

      const lessonsOrdered = [...lessons]
        .map(lesson => {
          const exp = experiences.find(item => item.id === lesson.id)
          const experiencesForLesson = exp.experiences

          const allTasks = _.get(lesson, 'lessonPlan.activities', []).flatMap(
            activity => activity.tasks,
          )
          const completedTasks = allTasks.filter(task => {
            return experiencesForLesson.some(
              experience =>
                experience.verb === 'answered' &&
                experience.entity &&
                experience.entity.id === task.id,
            )
          })
          /**
           * @type {Date | null}
           */
          const lastStartedTime = experiencesForLesson
            .filter(experience => experience.verb === 'started')
            .reduce((prev, experience) => {
              const expTime = new Date(experience.data.time)
              if (prev === null) {
                return expTime
              }

              if (expTime.getTime() > prev.getTime()) {
                return expTime
              }

              return prev
            }, null)

          return {
            lesson,
            isStarted: completedTasks.length > 0,
            isCompleted: completedTasks.length === allTasks.length,
            lastStartedTime,
          }
        })
        .sort((a, b) => {
          const { lesson: lessonA } = a
          const { lesson: lessonB } = b

          if (a.isCompleted !== b.isCompleted) {
            return a.isCompleted ? 1 : -1
          }

          if (a.isStarted !== b.isStarted) {
            return a.isStarted ? -1 : 1
          }

          return getTranslatedField(lessonA.lessonPlan, 'name').localeCompare(
            getTranslatedField(lessonB.lessonPlan, 'name'),
          )
        })

      setSortedLessons(lessonsOrdered)
      setLoading(false)
    })()

    return () => {
      cancelInfo.canceled = true
    }
  }, [lessons])

  return (
    <HomeLessonsScreen>
      <HomeLessonsBackground>
        <HomeLessonPageHeader>
          <HomeStudentLyftaLogo />
          <HomeLessonButtonSpacer />
          <LessonSelectorMenuRight>
            <Translation>
              {t => (
                <HomeStudentLogoutButton
                  redirectPath="/login-with-password"
                  t={t}
                />
              )}
            </Translation>
          </LessonSelectorMenuRight>
        </HomeLessonPageHeader>
        <HomeLessonsContainer>
          <HomeHeader>
            {!loading && sortedLessons.length && (
              <>
                {viewer && viewer.username ? (
                  <I18n
                    options={{ name: viewer.username }}
                    tag={HomeMainTitle}
                    text="studentAtHome.chooseLessonDescriptionWithName"
                  />
                ) : (
                  <I18n
                    tag={HomeMainTitle}
                    text="studentAtHome.chooseLessonDescription"
                  />
                )}
                <I18n tag={HomeSubTitle} text="studentAtHome.chooseJourney" />
              </>
            )}
            {loading && (
              <>
                <I18n tag={HomeSubTitle} text="loading" />
              </>
            )}
          </HomeHeader>
          <HomeLessonRowContainer>
            {!loading &&
              map(sortedLessons, lessonItem => {
                const {
                  lesson,
                  isStarted,
                  isCompleted,
                  lastStartedTime,
                } = lessonItem
                return (
                  <HomeStudentLessonRow
                    handleLessonButton={handleLessonButton}
                    isCompleted={isCompleted}
                    isStarted={isStarted}
                    key={lesson.id}
                    lastStartedTime={lastStartedTime}
                    lesson={lesson}
                    viewerType={viewerType}
                  />
                )
              })}
          </HomeLessonRowContainer>
        </HomeLessonsContainer>
      </HomeLessonsBackground>
    </HomeLessonsScreen>
  )
}

HomeLessonsScreenComponent.propTypes = {
  handleLessonButton: PropTypes.func.isRequired,
  lessons: PropTypes.array.isRequired,
  viewer: PropTypes.object.isRequired,
  viewerType: PropTypes.string.isRequired,
}
