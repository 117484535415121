import { styled } from '@lyfta/components-ui'

import { InformationTextContainer as BaseInformationTextContainer } from './_styles'

export {
  HelpMeFindIt,
  SubmitContainer,
  InformationTextMarkdown,
} from './_styles'

export const InformationTextContainer = styled(
  BaseInformationTextContainer,
).attrs({
  pb: 4,
  pt: 4,
})`
  overflow: auto;
`
