import { createAsyncAction } from '../../helpers'
import apiCall from '../../services/Api'

export const LOAD_LYFTA_METADATA = createAsyncAction(
  'lyftaMetadata/LOAD_LYFTA_METADATA',
)
export const UPDATE_LYFTA_METADATA = createAsyncAction(
  'lyftaMetadata/UPDATE_LYFTA_METADATA',
)

export const loadLyftaMetadata = () => {
  return apiCall({
    method: 'GET',
    endpoint: `/lyfta_metadata/1`,
    types: LOAD_LYFTA_METADATA,
  })
}

export const updateLyftaMetadata = lyftaMetadataRecord => {
  return apiCall({
    customMessage: { success: { message: 'storyworld.updated' } },
    method: 'PATCH',
    endpoint: `/admin/lyfta_metadata/1`,
    types: UPDATE_LYFTA_METADATA,
    query: {
      data: {
        type: 'lyfta_metadata',
        attributes: {
          ...lyftaMetadataRecord,
        },
      },
    },
  })
}
