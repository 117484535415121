import get from 'lodash/get'
import noop from 'lodash/noop'
import partial from 'lodash/partial'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { Plus } from '../../Assets'
import { Menu, NestedMenu, Opener, OptionItem } from './styles'

class DropdownMenu extends PureComponent {
  state = {
    isMenuOpen: false,
  }

  handleToggle = () => {
    this.setState(prevState => ({ isMenuOpen: !prevState.isMenuOpen }))
  }

  handleClose = () => {
    this.setState({ isMenuOpen: false })
  }

  handleOption = option => {
    const { onClick } = this.props
    onClick(option)
  }

  renderOption(option, index) {
    const value = option.value || option.label || option
    const label = option.label || option.value || option
    const icon = get(option, 'icon', null)

    const key = `${value}-${index}`

    if (get(option, 'content', null) === null) {
      return (
        <OptionItem
          id={`option-${index}`}
          key={key}
          onClick={partial(this.handleOption, option)}
        >
          {icon && icon}
          {label}
        </OptionItem>
      )
    }
    return (
      <NestedMenu
        animate={false}
        key={key}
        toggle={
          <OptionItem key={key}>
            {label}
            {icon && icon}
          </OptionItem>
        }
      >
        {option.content.map((item, idx) => this.renderOption(item, idx))}
      </NestedMenu>
    )
  }

  render() {
    const { isMenuOpen } = this.state
    const {
      align,
      backgroundColorValue,
      children,
      dataIntercomTarget,
      glyph,
      id,
      onColouredBg,
      options,
      rank,
      title,
      upwards,
    } = this.props
    const menuOptions = {
      isOpen: isMenuOpen,
      close: this.handleClose,
      toggle: (
        <Opener
          backgroundColorValue={backgroundColorValue}
          data-intercom-target={dataIntercomTarget}
          glyph={glyph}
          id={id}
          rank={rank}
          title={title}
          onClick={this.handleToggle}
          onColouredBg={onColouredBg}
        >
          {children}
        </Opener>
      ),
      align,
      animate: false,
      upwards,
    }

    return (
      <Menu {...menuOptions} width="auto">
        {options.map((item, index) => this.renderOption(item, index))}
      </Menu>
    )
  }
}

DropdownMenu.defaultProps = {
  align: 'left',
  backgroundColorValue: '',
  dataIntercomTarget: '',
  glyph: Plus,
  id: null,
  options: [],
  rank: null,
  title: '',
  upwards: false,
  onClick: noop,
  onColouredBg: false,
}

DropdownMenu.propTypes = {
  align: PropTypes.string,
  backgroundColorValue: PropTypes.string,
  children: PropTypes.node.isRequired,
  dataIntercomTarget: PropTypes.string,
  glyph: PropTypes.object,
  id: PropTypes.string,
  options: PropTypes.array,
  rank: PropTypes.string,
  title: PropTypes.string,
  upwards: PropTypes.bool,
  onClick: PropTypes.func,
  onColouredBg: PropTypes.bool,
}

export default DropdownMenu
