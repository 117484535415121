import { PASSPHRASE } from '@lyfta/components-data/src/Store/Actions/auth'
import { push } from 'connected-react-router'
import paths from 'Constants/paths'
import { all, put, takeEvery } from 'redux-saga/effects'

function* redirectAfterAuthentication() {
  yield put(push(paths.chooseStudent))
}

export default function* root() {
  yield all([takeEvery(PASSPHRASE.SUCCESS, redirectAfterAuthentication)])
}
