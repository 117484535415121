import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { questTrackerData } from '../../../Store/Actions/questTracker'
import { quest } from '../../../Store/Actions/ui'
import {
  getActivities,
  getCurrentActivity,
} from '../../../Store/Selectors/questTracker'
import Welcome from './Welcome'

const selector = createStructuredSelector({
  currentActivity: getCurrentActivity,
  activities: getActivities,
})

const actions = {
  requestAdvanceActivity: questTrackerData.requestAdvanceActivity,
  selectTask: questTrackerData.selectTask,
  pauseWebGL: quest.pauseWebGL,
  resumeWebGL: quest.resumeWebGL,
}

export default connect(selector, actions)(Welcome)
