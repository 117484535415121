import { BREAKPOINTS, styled } from '@lyfta/components-theme'
import { breakpoints } from '@lyfta/components-theme/src/themes/Main'
import { times } from 'lodash'

import { Flex } from '../../../BaseComponents'

export const Container = styled(Flex)`
  flex-direction: column;

  @media screen and (min-width: ${breakpoints[BREAKPOINTS.TABLET]}) {
    display: grid;
    grid-template-columns: ${({ columnCount }) =>
      times(columnCount, () => '1fr').join(' ')};
    grid-gap: ${props => `${props.gridGap}px`};
    justify-content: start;
    width: 100%;
  }
`
