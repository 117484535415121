import * as React from 'react'

function SvgUnlock(props) {
  return (
    <svg
      height="133"
      viewBox="0 0 114 133"
      width="114"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        d="M101.333 49.8291H31.6667V33.1949C31.6667 25.2711 36.4952 17.9491 44.3333 13.988C52.1715 10.0258 61.8285 10.0258 69.6667 13.988C77.5048 17.9496 82.3333 25.2714 82.3333 33.1949C82.3386 34.6643 83.0069 36.0718 84.1938 37.1108C85.3807 38.1498 86.9884 38.7349 88.6667 38.7396C102.79 37.2426 90.5033 14.399 83.8533 9.62908C60.8642 -11.2191 18.3667 4.1398 19 33.1949V49.8291H12.6667C9.31 49.8378 6.09385 51.0085 3.72079 53.0866C1.34706 55.1642 0.00979555 57.9798 0 60.9185V121.911C0.00989588 124.849 1.34782 127.664 3.72079 129.743C6.09453 131.82 9.31 132.991 12.6667 133H101.333C104.69 132.991 107.905 131.82 110.279 129.743C112.652 127.664 113.99 124.849 114 121.911V60.9185C113.99 57.9798 112.653 55.1642 110.279 53.0866C107.906 51.0084 104.69 49.8377 101.333 49.8291ZM63.3333 91.8581V101.395C63.3333 103.376 62.126 105.206 60.1667 106.197C58.2072 107.188 55.7928 107.188 53.8333 106.197C51.8739 105.207 50.6667 103.376 50.6667 101.395V91.8581C48.4309 90.3125 47.0335 88.0201 46.812 85.5337C46.5896 83.0473 47.5634 80.5926 49.4976 78.7616C51.4319 76.9307 54.1513 75.8893 57 75.8893C59.8486 75.8893 62.5681 76.9307 64.5024 78.7616C66.4367 80.5926 67.4105 83.0472 67.188 85.5337C66.9664 88.0201 65.5691 90.3125 63.3333 91.8581V91.8581Z"
        fill="white"
      />
    </svg>
  )
}

export default SvgUnlock
