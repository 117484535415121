import { USER_TYPES } from '../Constants/users'

export const getIsPreviewMode = ({
  viewerType,
  isTeachingInFrontOfClass,
  lessonPlanId,
  isQuest,
}) => {
  return (
    viewerType !== USER_TYPES.students &&
    !isTeachingInFrontOfClass &&
    !!lessonPlanId &&
    isQuest
  )
}
