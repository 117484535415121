import { css, styled, themeGet } from '@lyfta/components-theme'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'

import { ErrorSvg } from '../../../Assets/Icons'
import { Box } from '../../Box'
import { ButtonNew } from '../../ButtonNew'
import { Flex } from '../../Flex'

export const LabelAndInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Label = styled(Box).attrs(() => ({
  as: 'label',
}))`
  z-index: 1;
  color: ${themeGet('colors.black_700')};
  transition: all ${themeGet('transitionTime')} ease;
  user-select: none;
  font-size: 16px; // Body 1
  font-weight: 400; // Body 1
  letter-spacing: 0.5px; // Body 1
  line-height: 24px; // Body 1
  cursor: text;

  ${props =>
    props.active &&
    css`
      font-size: 13px; // Body 5
      line-height: 20px; // Body 5
      color: ${themeGet('colors.primary_130')};
    `};
`

export const Input = styled(Box).attrs(() => ({
  as: 'input',
  m: 0,
}))`
  border: 0;
  outline: none;
  background-color: ${themeGet('colors.clear')};
  transition: all ${themeGet('transitionTime')} ease;
  color: ${themeGet('colors.black_900')};
  font-size: 16px; // Body 1
  font-weight: 400; // Body 1
  letter-spacing: 0.5px; // Body 1
  line-height: 24px; // Body 1
  padding: 0;

  ${props => (props.active ? `height: 20px;` : `height: 0px;`)}

  ${props =>
    props.disabled &&
    css`
      color: ${themeGet('colors.black_700')};
    `}

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected,
  &:-webkit-autofill:active {
    appearance: none !important;
    background: transparent !important;
    background-color: transparent !important;
    background-image: none !important;
    color: inherit !important;
    box-shadow: 0 0 0 30px ${themeGet('colors.alto')} inset !important;
  }
`

export const Description = styled(Box)`
  color: ${props =>
    props.focused
      ? themeGet('colors.primary_130')
      : themeGet('colors.black_700')};
  font-size: 16px; // Body 1
  font-weight: 400; // Body 1
  letter-spacing: 0.5px; // Body 1
  line-height: 24px; // Body 1
  padding-top: 4px;
`

export const Container = styled(Box)`
  position: relative;
  background-color: ${themeGet('colors.neutral_100')};
  height: 56px;
  border-radius: 5px;
  border: 1px solid ${themeGet('colors.black_600')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  cursor: text;

  ${props =>
    props.focused &&
    !props.disabled &&
    css`
      border-bottom: 2px solid ${themeGet('colors.primary_100')};
    `}

  ${props =>
    props.disabled &&
    css`
      background-color: ${themeGet('colors.black_200')};
      border: none;
    `}

  ${props =>
    props.error &&
    css`
      border-bottom: 2px solid ${themeGet('colors.error_100')};
    `};

  ${props => (props.error ? `padding-right: 18px;` : `padding-right: 16px;`)}

  box-sizing: content-box;
  ${props =>
    props.error || props.focused
      ? `margin-bottom: 0px;`
      : `margin-bottom: 1px;`}

  &:focus-within {
    ${props =>
      !props.error &&
      !props.disabled &&
      css`
        border-bottom: 2px solid ${themeGet('colors.primary_100')};
      `}
  }

  &:hover {
    box-shadow: ${props => !props.disabled && shadows.skim}
  }
`

export const ErrorIcon = styled(ErrorSvg)`
  flex-shrink: 0;

  > path {
    fill: ${themeGet('colors.error_100')};
  }
`

export const FixedHeightContainer = styled(Box)`
  height: 59px;
`

export const ShowHidePasswordButton = (window?.location
  ? styled.button
  : styled(ButtonNew))`
  align-items: center;
  background-color: ${themeGet('colors.clear')};
  border: none;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 32px;
  justify-content: center;
  margin-left: 4px;
  padding: 0;
  width: 32px;

  &:focus-visible {
    background-color: ${themeGet('colors.black_200')};
    outline: 1px solid ${themeGet('colors.black_600')};
  }

  &:hover {
    background-color: ${themeGet('colors.black_200')};
  }

  > svg {
    height: 20px;
    width: 20px;
  }

  > svg path {
    fill: ${themeGet('colors.black_600')};
  }
`

export const Wrapper = styled(Box)``

export const ViewOnlyLabel = styled(Flex)`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
  color: ${themeGet('colors.black_800')};
`

export const ViewOnlyField = styled(Flex)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
  color: ${themeGet('colors.black_600')};
`

Label.displayName = 'Label'
Input.displayName = 'Input'
Description.displayName = 'Description'
Container.displayName = 'Container'
Wrapper.displayName = 'Wrapper'
