import { I18n, TransLink, getTranslation } from '@lyfta/components-i18n'
import React from 'react'
import validate from 'validate.js'

const isValueEmpty = (currentRow, columnNumber) => {
  return (
    currentRow.length > 1 &&
    (!currentRow[columnNumber] || currentRow[columnNumber].trim().length === 0)
  )
}

export const validateCsv = (
  data,
  headerFormat,
  userRole,
  setCustomErrors,
  remainSeatsAvailable,
) => {
  if (data[0].join(',') !== headerFormat[userRole]) {
    setCustomErrors(previousValues => [
      ...previousValues,
      <TransLink
        components={{
          italic: <i />,
        }}
        values={{
          headerFormat: headerFormat[userRole],
        }}
        i18nKey="files.upload.error.headerMismatch"
      />,
    ])
  }
  if (data.length === 1 && data[0].join(',') === headerFormat[userRole]) {
    setCustomErrors(previousValues => [
      ...previousValues,
      <I18n text="files.upload.error.missingAnyEntries" />,
    ])
  }
  let fullNameCount = 0
  let missingFirstNameCount = 0
  let missingSurnameCount = 0
  let missingClassOrEmailCount = 0
  let invalidEmail = 0
  let firstName
  let surname
  let classOrEmail
  data.forEach(row => {
    if (isValueEmpty(row, 0) && isValueEmpty(row, 1)) {
      fullNameCount += 1
      // eslint-disable-next-line prefer-destructuring
      classOrEmail = row[2]
    } else if (isValueEmpty(row, 0)) {
      missingFirstNameCount += 1
      // eslint-disable-next-line prefer-destructuring
      surname = row[1]
    } else if (isValueEmpty(row, 1)) {
      missingSurnameCount += 1
      ;[firstName] = row
    }
    if (isValueEmpty(row, 2)) {
      missingClassOrEmailCount += 1
    }
    if (
      missingFirstNameCount + missingSurnameCount + missingClassOrEmailCount ===
      1
    ) {
      ;[firstName, surname] = row
    }
    if (
      headerFormat[userRole].includes('email') &&
      validate.single(row[2], { email: true }) &&
      !isValueEmpty(row, 2)
    ) {
      invalidEmail += 1
    }
  })
  if (invalidEmail > 1) {
    setCustomErrors(previousValues => [
      ...previousValues,
      <I18n
        text="files.upload.error.invalidEmail"
        options={{
          context: invalidEmail > 2 ? 'plural' : 'singular',
          count: invalidEmail - 1,
        }}
      />,
    ])
  }
  if (missingFirstNameCount === 1 && missingSurnameCount === 0) {
    setCustomErrors(previousValues => [
      ...previousValues,
      <I18n
        text="files.upload.error.missingValue_firstName_surnamePresent"
        options={{
          surname: `${surname}`,
          userRole: getTranslation(`core.models.${userRole}`).toLowerCase(),
        }}
      />,
    ])
  }
  if (missingFirstNameCount === 0 && missingSurnameCount === 1) {
    setCustomErrors(previousValues => [
      ...previousValues,
      <I18n
        text="files.upload.error.missingValue_surname_firstNamePresent"
        options={{
          firstName: `${firstName}`,
          userRole: getTranslation(`core.models.${userRole}`).toLowerCase(),
        }}
      />,
    ])
  }
  if (
    fullNameCount === 1 &&
    missingFirstNameCount === 0 &&
    missingSurnameCount === 0
  ) {
    setCustomErrors(previousValues => [
      ...previousValues,
      <I18n
        text="files.upload.error.missingValue_name"
        options={{
          userRole: getTranslation(`core.models.${userRole}`).toLowerCase(),
          classOrEmail,
          context:
            userRole === 'students' ? 'classNamePresent' : 'emailPresent',
        }}
      />,
    ])
  }
  if (fullNameCount + missingFirstNameCount + missingSurnameCount > 1) {
    setCustomErrors(previousValues => [
      ...previousValues,
      <I18n
        text="files.upload.error.manyMissingValues_name"
        options={{
          userRole: getTranslation(`core.models.${userRole}`, { count: 0 }),
        }}
      />,
    ])
  }
  if (missingClassOrEmailCount === 1) {
    setCustomErrors(previousValues => [
      ...previousValues,
      <I18n
        text="files.upload.error.missingValue"
        options={{
          name: `${firstName} ${surname}`,
          context: userRole === 'students' ? 'className' : 'email',
        }}
      />,
    ])
  }
  if (missingClassOrEmailCount > 1) {
    setCustomErrors(previousValues => [
      ...previousValues,
      <I18n
        text="files.upload.error.manyMissingValues"
        options={{
          name: `${firstName} ${surname}`,
          userRole: getTranslation(`core.models.${userRole}`, {
            count: missingClassOrEmailCount - 1,
          }).toLowerCase(),
          count: missingClassOrEmailCount - 1,
          context: userRole === 'students' ? 'className' : 'email',
        }}
      />,
    ])
  }
  const numberRows = data.length - 1
  if (numberRows > 1000) {
    setCustomErrors(previousValues => [
      ...previousValues,
      <I18n text="files.upload.error.thousandLimit" />,
    ])
  }
  if (remainSeatsAvailable && remainSeatsAvailable < numberRows) {
    setCustomErrors(previousValues => [
      ...previousValues,
      <I18n text="files.upload.error.seatLimit" />,
    ])
  }
}
