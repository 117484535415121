import tablet from '../NotSupported'
import confirmScreen from './Confirm'
import forgot from './Forgot'
import loginToLesson from './LoginToLesson'
import loginWithPassword from './LoginWithPassword'
import { StudentSelector } from './StudentSelector'

export const Confirm = confirmScreen
export const Forgot = forgot
export const LoginToLesson = loginToLesson
export const Tablet = tablet
export const LoginWithPassword = loginWithPassword
export { StudentSelector }
