import { createAsyncAction } from '../../../helpers'
import {
  generateCreateUser,
  generateDeleteUser,
  generateLoadUserAction,
  generateLoadUsersAction,
  generateUpdateUser,
} from './helpers'

export const LOAD_ADMIN_USERS = createAsyncAction('users/LOAD_ADMINS')
export const LOAD_ADMIN_USER = createAsyncAction('users/LOAD_ADMIN_USER')
export const CREATE_ADMIN_USER = createAsyncAction('users/CREATE_ADMIN_USER')
export const DELETE_ADMIN_USER = createAsyncAction('users/DELETE_ADMIN_USER')
export const UPDATE_ADMIN_USER = createAsyncAction('users/UPDATE_ADMIN_USER')

export const loadAdminUsers = generateLoadUsersAction(
  LOAD_ADMIN_USERS,
  'admin',
  '/admin_users',
  'organization,userPermissions',
)
export const loadAdminUser = generateLoadUserAction(
  LOAD_ADMIN_USER,
  '/admin_users',
  'organization,userPermissions',
)
export const createAdmin = generateCreateUser({
  types: CREATE_ADMIN_USER,
  endpoint: '/admin_users',
})
export const updateAdmin = generateUpdateUser({
  types: UPDATE_ADMIN_USER,
  endpoint: '/admin_users',
})
export const deleteAdmin = generateDeleteUser({
  types: DELETE_ADMIN_USER,
  endpoint: '/admin_users',
})

export const updateAdminUser = updateAdmin
