import { useMemo } from 'react'

export function useVideoSources(url) {
  return useMemo(() => {
    return [
      // TODO can add more alternatives here!
      {
        src: url.replace('.mp4', '.webm'),
        type: 'video/webm',
      },
      {
        src: url.replace('.mp4', '.ts'),
        type: 'video/MP2T',
      },
      {
        src: url,
        type: 'video/mp4',
      },
    ]
  }, [url])
}
