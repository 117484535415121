import { styled, themeGet } from '@lyfta/components-theme'

import { Flex } from '../../Components'

export const Container = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  background-color: ${themeGet('colors.bg')};
  height: 100%;
  overflow: hidden;
`

export const Main = styled(Flex)`
  flex: 1;
  overflow: hidden;
`

export const Content = styled(Flex)`
  flex-direction: column;
  flex: 1;
`
export const ContentContainer = styled(Flex)`
  flex-direction: row;
  flex: 1;
  overflow-y: hidden;
`

Container.displayName = 'Container'
Main.displayName = 'Main'
Content.displayName = 'Content'
ContentContainer.displayName = 'ContentContainer'
