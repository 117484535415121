import difference from 'lodash/difference'
import get from 'lodash/get'
import head from 'lodash/head'
import isArray from 'lodash/isArray'
import map from 'lodash/map'
import set from 'lodash/set'
import uniq from 'lodash/uniq'

import { nullifyIfIncludes } from './helpers'

const defaultLoadOptions = {
  mapToKey: false,
  withLoading: true,
  singular: false,
  withReplace: false,
  addToState: {},
}

export const createClearHandler = (resourceType, options) => state => {
  const { mapToKey, withLoading, singular, addToState } = {
    ...defaultLoadOptions,
    ...options,
  }

  const mappedResourceType = mapToKey || resourceType

  const nextState = {
    [mappedResourceType]: state[mappedResourceType],
  }

  if (singular) {
    set(nextState, mappedResourceType, null)
  } else {
    set(nextState, mappedResourceType, [])
  }

  if (withLoading) {
    nextState.isLoaded = true
    nextState.isLoading = false
  }

  if (!singular) {
    nextState.paged = {
      number: 1,
      totalRecords: 0,
    }
  }

  return state.merge({ ...nextState, ...addToState })
}

export const createLoadHandler = (resourceType, options) => (
  state,
  { meta, payload },
) => {
  const { mapToKey, withLoading, singular, withReplace, addToState } = {
    ...defaultLoadOptions,
    ...options,
  }
  const endpoint = get(meta, 'endpoint')

  const data = get(payload, `data.${resourceType}`, false)
  const metaData = get(payload, `data.meta[${endpoint}].data`, false)
  const paged = get(payload, `data.meta[${endpoint}].meta.pagination`, {})

  const payloadResource = map(metaData || data, 'id')

  const mappedResourceType = mapToKey || resourceType

  const nextState = {
    [mappedResourceType]: state[mappedResourceType],
  }

  if (singular) {
    set(nextState, mappedResourceType, head(payloadResource))
  } else {
    set(
      nextState,
      mappedResourceType,
      withReplace
        ? payloadResource
        : uniq([...get(state, mappedResourceType, []), ...payloadResource]),
    )
  }

  if (withLoading) {
    nextState.isLoaded = true
    nextState.isLoading = false
  }

  if (paged && !singular) {
    nextState.paged = paged
  }

  return state.merge({ ...nextState, ...addToState })
}

export const createDeleteHandler = (resourceType, options) => (
  state,
  { payload, meta },
) => {
  const { mapToKey, withLoading, addToState } = {
    ...defaultLoadOptions,
    ...options,
  }
  const endpoint = get(meta, 'endpoint')

  const data = get(payload, `data.${resourceType}`, false)
  const metaData = get(payload, `data.meta[${endpoint}].data`, false)

  const deletedIds = map(metaData || data, 'id')

  const mappedResourceType = mapToKey || resourceType
  const paged = { ...state.paged }

  paged.totalRecords -= deletedIds.length
  paged.number = 1
  paged.count = Math.ceil(paged.totalRecords / paged.size)

  const nextState = {
    [mappedResourceType]: state[mappedResourceType],
    paged,
  }

  const stateValue = get(state, mappedResourceType, [])

  set(
    nextState,
    mappedResourceType,
    isArray(stateValue)
      ? difference(stateValue, deletedIds)
      : nullifyIfIncludes(deletedIds, stateValue),
  )

  if (withLoading) {
    nextState.isLoaded = true
    nextState.isLoading = false
  }

  return state.merge({ ...nextState, ...addToState })
}
