import { styled } from '@lyfta/components-theme'
import { Box, Flex } from '@lyfta/components-ui'

export const Container = styled(Flex)`
  width: 100%;
  display: flex;
`
export const Content = styled(Box)`
  height: 100%;
  width: calc(100% - ${props => (props.tags ? 316 : 0)}px);
`
