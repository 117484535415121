import {
  styled,
  themeGet,
  walsheimAndFallbackFontFamily,
} from '@lyfta/components-theme'
import { Box, Flex } from '@lyfta/components-ui'
import Rectangle from '@lyfta/components-ui/src/Assets/Icons/Rectangle'
import FaAngleLeft from 'react-icons/lib/fa/angle-left'
import FaAngleRight from 'react-icons/lib/fa/angle-right'
import { NavLink } from 'react-router-dom'

const svgDataURL = `url("data:image/svg+xml;base64,${btoa(Rectangle)}")`

export const ContainerWrapper = styled(Box).attrs(() => ({}))`
  position: relative;
  ${props => !props.removePadding && `margin: 20px 20px 0 20px;`};
  :hover {
    > svg {
      color: rgba(255, 255, 255, 0.5);
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`

export const EmptyLessonContainer = styled(Flex).attrs(() => ({
  px: 4,
  py: 8,
}))`
  border: 3px dashed ${themeGet('colors.black_200')};
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-image: url(${svgDataURL}) 1 round;
`
export const Container = styled(Box).attrs(() => ({}))`
  margin-left: -10px;
  width: calc(100% + 10px);
`

export const Row = styled(Box).attrs(() => ({}))`
  display: flex;
  justify-content: space-between;
`

export const Title = styled(Box).attrs(() => ({
  mb: 3,
}))`
  font-size: 28px;
  font-weight: 500;
  color: ${themeGet('colors.font.accent')};
`
export const TitleEmpty = styled(Box).attrs(() => ({ mt: 3 }))`
  font-size: 28px;
  font-weight: 700;
  color: ${themeGet('colors.black')};
`

export const DescriptionEmpty = styled(Box).attrs(() => ({ mt: 3 }))`
  font-size: 19px;
  font-weight: 400;
  color: ${themeGet('colors.black')};
`

export const Subtitle = styled(Flex).attrs(() => ({}))`
  font-family: ${walsheimAndFallbackFontFamily};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  color: #656a73;
`

export const ShowAll = styled(NavLink)`
  text-decoration: none;
  font-size: 18px;
  color: ${props => (props.color ? props.color : themeGet('colors.font.link'))};
`

export const RowContainer = styled(Flex).attrs(() => ({}))`
  overflow-x: hidden;
  flex: 0 0 auto;
  width: ${props => (props.bg ? '105%' : '100%')};
  padding-bottom: ${props => props.rowContainerPB};
  padding-top: 20px;
  padding-left: ${props => (props.bg ? '-20px' : '10px')};
  margin-left: ${props => (props.bg ? '-20px' : '0px')};

  ${props => props.rowContainerHeight && `height: ${props.rowContainerHeight};`}
  overflow-y: hidden;
  background-color: ${props => {
    return props.bg ? themeGet(`colors.${props.bg}`) : ''
  }};
  justify-content: ${props => (props.centered ? 'center' : 'flex-started')};
  align-items: center;

  ::-webkit-scrollbar {
    width: 5px;
    display: none;
  }
`
export const ButtonAngleLeft = styled(Flex)`
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  color: ${themeGet('colors.primary')};
  border-radius: 50%;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`
export const ButtonAngleRight = styled(Flex)`
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: ${themeGet('colors.primary')};
  border-radius: 50%;
  :hover {
    opacity: 0.7;
  }
`
export const StyledFaAngleLeft = styled(FaAngleLeft)`
  position: absolute;
  top: 100px;
  left: 1px;
  width: 50px;
  height: 50%;
  z-index: 1;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  color: rgba(131, 129, 129, 0);
  transition: all 0.3s;

  :hover {
    color: ${themeGet('colors.primary')} !important;
  }
`

export const StyledFaAngleRight = styled(FaAngleRight)`
  position: absolute;
  top: 100px;
  right: 10px;
  width: 50px;
  height: 50%;
  z-index: 1;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  color: rgba(131, 129, 129, 0);
  transition: all 0.3s;

  :hover {
    color: ${themeGet('colors.primary')} !important;
  }
`
export const Label = styled.span`
  white-space: nowrap;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.15;
  letter-spacing: 0.4px;
  color: ${themeGet('secondary')};
  &:hover {
    text-decoration: underline;
  }
`
