import { styled, themeGet as theme } from '@lyfta/components-theme'

import { Box } from '../../Box'
import { Flex } from '../../Flex'

export const Container = styled(Flex).attrs(() => ({
  pb: 3,
  pt: 2,
  px: 2,
}))`
  border-radius: 5px;
  border: 1px solid ${theme('colors.black_300')};
  flex-direction: column;
`

export const Content = styled(Flex).attrs(() => ({}))`
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
`

export const UploadText = styled(Box)`
  font-size: 16px;
  letter-spacing: 0.5px;
  color: ${theme('colors.black_600')};
  overflow: hidden;
  white-space: nowrap;
  max-width: 80%;
  text-overflow: ellipsis;
`
export const Progress = styled(Box).attrs(() => ({
  mt: 2,
}))`
  height: 8px;
  background-color: ${theme('colors.primary_60_50')};
  border-radius: 100px;
  overflow: hidden;
  position: relative;
  width: 100%;

  :before {
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    width: ${({ value }) => `${value}%`};
    background-color: ${theme('colors.primary_100')};
    transition: width 2s ease;
  }
`
