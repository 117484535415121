import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import {
  ROTATION_ENDED,
  ROTATION_STARTED,
  ROTATION_TOGGLE,
} from '../../Actions/ui'

const initialState = {
  enabled: true,
  active: false,
}

const toggle = state => state.update('enabled', enabled => !enabled)
const ended = state => state.merge({ active: false })
const started = state => state.merge({ active: true })

const handlers = {
  [ROTATION_TOGGLE]: toggle,
  [ROTATION_ENDED]: ended,
  [ROTATION_STARTED]: started,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
