import { TYPE_LESSON_PLAN_TEMPLATE } from '@lyfta/components-content/src/Constants/contents'
import { styled, themeGet } from '@lyfta/components-theme'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'
import { Box, ButtonNew, Flex, Text } from '@lyfta/components-ui'

export const Container = styled(Flex).attrs(() => ({ mr: 3 }))`
  flex-direction: column;
  background-color: ${themeGet('colors.neutral_100')};
  border: ${p => {
    return p.isSelected ? `5px solid ${p.theme.colors.teal_100}` : 'none'
  }};

  border-radius: 12px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  cursor: pointer;
  flex-shrink: 0;
  min-width: ${props => (props.width ? props.width : '300px')};
  max-width: ${props => (props.width ? props.width : '300px')};
  min-height: ${props => (props.height ? props.height : '410px')};
  max-height: ${props => (props.height ? props.height : '410px')};
  box-shadow: ${props =>
    props.isSelected ? shadows.focused_blue : shadows.skim};

  :hover {
    box-shadow: ${shadows.lifted};
  }
`

export const HeaderContainer = styled(Flex).attrs({})`
  min-height: 40%;
`

const bodyContainerPadding = '16px'

export const ButtonOnCard = styled(Flex).attrs({})`
  position: absolute;
  top: 10px;
  right: 10px;
  max-width: 30px;
  max-height: 30px;
  z-index: 5;
`

export const BodyContainer = styled(Flex)`
  min-height: 50%;
  width: 100%;
  flex-direction: column;
  background-color: ${themeGet('colors.white')};
  padding: ${bodyContainerPadding};
  position: relative;
`

export const FooterContainer = styled(Flex).attrs(() => ({}))`
  justify-content: center;
  align-items: center;
  min-height: 10%;
  background-color: ${themeGet('colors.white')};
  border-radius: 0 0 8px 8px;
  width: 100%;
`
export const Controls = styled(Flex).attrs(() => ({}))`
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  height: 100%;
  width: 92%;
  z-index: 100;
  border-top: 1px solid ${themeGet('colors.black_300')};
  background-color: ${themeGet('colors.white')};
  svg {
    cursor: pointer;
  }
`
export const TypeRow = styled(Flex).attrs(() => ({}))`
  align-items: center;
  height: 32px;
  margin-bottom: 10px;
`
export const TypeLabelContainer = styled(Flex).attrs(() => ({}))`
  border: 1px solid ${themeGet('colors.black_300')};
  width: fit-content;
  border-radius: 8px;
  height: 32px;
  padding-left: 8px;
  align-items: center;
  margin-right: ${props => (props.bbfcNoContentWarning ? '4px' : '0px')};
`

export const LocationContainer = styled(Flex).attrs(() => ({}))`
  color: ${themeGet('colors.black_600')};
  font-weight: 400;
  letter-spacing: 0.5px;
  align-items: flex-start;
  height: 24px;
`
export const KeywordContainer = styled(Flex).attrs(() => ({ mb: 2 }))`
  color: ${themeGet('colors.black_600')};
  font-weight: 400;
  letter-spacing: 0.5px;
  align-items: flex-start;
  /* height: 22px; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`

export const ThumbHolder = styled(Box).attrs({})`
  background-image: url(${props => props.image});
  background-size: ${props =>
    props.type === TYPE_LESSON_PLAN_TEMPLATE ? 'contain' : 'cover'};
  background-position: center;
  background-color: ${props =>
    props.type === TYPE_LESSON_PLAN_TEMPLATE &&
    themeGet('colors.primary_60_50')};
  background-repeat: ${props =>
    props.type === TYPE_LESSON_PLAN_TEMPLATE ? 'no-repeat' : ''};
  border-radius: 8px 8px 0px 0px;
  height: 100%;
  position: relative;
  width: 100%;
`
export const TitleContainer = styled(Flex).attrs(() => ({}))`
  margin-bottom: 4px;
`
export const DescriptionContainer = styled(Flex).attrs(() => ({ mb: 1 }))`
  max-height: 50px;

  font-size: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`

export const AuthorContainer = styled(Flex)`
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1px;
  padding-left: 16px;
  text-transform: uppercase;
  color: ${themeGet('colors.black_600')};
  left: ${bodyContainerPadding};
  align-items: center;
  max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`

export const DurationContainer = styled(Flex).attrs(() => ({}))`
  background-color: ${themeGet('colors.black_900')};
  color: ${themeGet('colors.neutral_100')};
  font-size: 13px;
  line-height: 20px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 2px;
  align-items: center;
  letter-spacing: 0.5px;
  width: fit-content;
  position: absolute;
  bottom: 0px;
  left: 0px;
`
export const ViewContainer = styled(Flex).attrs(() => ({}))`
  background-color: ${themeGet('colors.black_900')};
  color: ${themeGet('colors.black_300')};
  font-size: 13px;
  line-height: 20px;
  padding-left: 6px;
  padding-right: 6px;
  align-items: center;
  letter-spacing: 0.5px;
  width: fit-content;
  position: absolute;
  height: 26px;
  bottom: 0px;
  right: 0px;
`

export const ContentInfo = styled(Flex).attrs(() => ({}))`
  background-color: ${themeGet('colors.white')};
  z-index: 100;
  font-size: 13px;
  line-height: 16px;
  border-radius: 2px;
  bottom: 0;
  left: 0;
  max-height: 28px;
  min-height: 28px;
  width: 100%;
  position: absolute;
  letter-spacing: 0.5px;
`

export const Title = styled(Text).attrs(() => ({}))`
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 48px; /* 2 * line-height (24px) */
`
export const TypeLabel = styled(Text).attrs(() => ({}))`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${themeGet('colors.black_600')};
  padding: 0 8px 0 8px;
  margin: auto;
`

export const StyledButton = styled(ButtonNew).attrs(() => ({
  p: 0,
}))`
  box-shadow: none;
  border-left: ${({ isTeacherApp, theme }) =>
    isTeacherApp ? `1px solid ${theme.colors.black_300}` : `none`};

  width: 50%;
  font-weight: 700;
  border-radius: 0;
  &:hover:enabled {
    background: none;
    font-weight: bold;
  }
`

export const PublishedContainer = styled(Box)`
  bottom: 6px;
  color: ${themeGet('colors.black_600')};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1.5px;
  position: absolute;
  right: ${bodyContainerPadding};
`
