import React from 'react'

function SvgVolumeOff(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 16.4147L22 9.41466V33.9147L15 26.9147H8V16.4147H15ZM25.5 15.6011C27.5923 16.8115 29 19.0737 29 21.6647C29 24.2556 27.5923 26.5179 25.5 27.7282V15.6011ZM25.5 35.3293V31.7894C29.8051 30.4685 32.9375 26.4352 32.9375 21.6647C32.9375 16.8941 29.8051 12.8609 25.5 11.54V8C31.5383 9.56627 36 15.0904 36 21.6647C36 28.239 31.5383 33.7631 25.5 35.3293Z"
        fill="#050B15"
      />
    </svg>
  )
}

export default SvgVolumeOff
