import { getResourceId } from '@lyfta/components-data/src/Store/Selectors/persist'
import { useAnimation } from 'framer-motion'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getCurrentLessonPlanId } from '../../../Store/Selectors/lessonPlans'
import { getCurrentLesson } from '../../../Store/Selectors/lessons'
import { getIsOpen } from '../../../Store/Selectors/UI/drawer'
import {
  getIsQuest,
  getIsRichMediaOpen,
} from '../../../Store/Selectors/UI/quest'
import Component from './Component'

const MenuController = props => {
  const drawerOpen = useSelector(getIsOpen)
  const menuVisible = useSelector(getIsQuest)
  const richMediaOpen = useSelector(getIsRichMediaOpen)
  const lesson = useSelector(getCurrentLesson)
  const resourceId = useSelector(getResourceId)
  const lessonPlanId = useSelector(getCurrentLessonPlanId)

  const hideComponent =
    !menuVisible ||
    (!lesson && !lessonPlanId) ||
    resourceId ||
    (lesson && lesson.state === 'archived')
  const animControls = useAnimation()
  const animation = {
    animate: animControls,
    initial: { right: '0px' },
  }

  useEffect(() => {
    animControls.start({
      right: drawerOpen || richMediaOpen || !menuVisible ? '-100px' : '0px',
    })
  }, [drawerOpen, richMediaOpen, menuVisible])

  return <>{!hideComponent && <Component animation={animation} {...props} />}</>
}

export default MenuController
