import { logIn } from '@lyfta/components-data/src/Store/Actions/auth'
import { getStudents } from '@lyfta/components-data/src/Store/Selectors/auth'
import { I18n } from '@lyfta/components-i18n'
import { LyftaLogoSymbolNoBorder } from '@lyfta/components-ui'
import paths from 'Constants/paths'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'

import {
  LogoIcon,
  NavigationLeftSidebar,
} from '../../../../Components/Blocks/Sidebars/styles'
import {
  ContainerStudents,
  NameContainer,
  SelectionInstructions,
  StudentBlock,
  StudentNameButton,
} from './styles'

export const StudentSelector = () => {
  const dispatch = useDispatch()
  const students = useSelector(getStudents)
  const handleStudentButtonClick = (token, studentName) => () => {
    const passphrase = localStorage.getItem('passphrase')
    localStorage.setItem('usertoken', token)
    localStorage.setItem('userName', studentName)
    dispatch(logIn(passphrase, token))
  }

  const renderStudents = () => {
    return students.map(content => {
      const {
        firstName,
        lastName,
        userToken: token,
        nickname,
        state,
      } = content.attributes
      const lastInitial = lastName.substr(0, 1)
      const fullName = `${firstName} ${lastInitial}`

      if (state !== 'active') {
        return null
      }
      return (
        <StudentNameButton
          inverse={0}
          key={token}
          onClick={handleStudentButtonClick(token, fullName)}
        >
          {nickname || fullName}
        </StudentNameButton>
      )
    })
  }
  if (!students) return <Redirect to={paths.loginToLesson} />

  return (
    <ContainerStudents>
      <NavigationLeftSidebar id="div-navigation-left-sidebar">
        <LogoIcon glyph={LyftaLogoSymbolNoBorder} width="100%" />
      </NavigationLeftSidebar>
      <StudentBlock>
        <SelectionInstructions>
          <I18n text="dashboard.views.studentSelector.title" />
        </SelectionInstructions>
        <NameContainer>{renderStudents()}</NameContainer>
      </StudentBlock>
    </ContainerStudents>
  )
}
