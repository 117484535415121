import { styled, themeGet as theme } from '@lyfta/components-theme'
import colors from '@lyfta/components-theme/src/themes/Student/colors'
import { ButtonNew, Flex } from '@lyfta/components-ui'

const getBackground = (isPreviewMode, percentage) => {
  if (isPreviewMode || percentage < 0) {
    return theme('colors.sa_primary_90')
  }
  if (percentage === 0) {
    return theme('colors.black_800')
  }
  return `linear-gradient(90deg, ${colors.sa_primary_90} ${percentage}%, ${colors.black_800} ${percentage}%)}`
}

export const Navigation = styled(Flex)`
  background: ${props =>
    getBackground(props.isPreviewMode, props.completePercentage)};
  justify-content: stretch;
  z-index: 2;
`

export const NavigationTab = styled(ButtonNew)`
  flex-grow: 1;
  border-radius: ${props =>
    props.unlocked && props.nextTask ? '0% 32px 32px 0%' : '0%'};
`

export const TabContainer = styled(Flex)`
  pointer-events: auto;
  flex-grow: 1;
  background-color: transparent;
  &:has(+ div > button:hover) {
    background-color: ${props =>
      props.nextTaskUnlocked
        ? theme('colors.sa_primary_60')
        : theme('colors.black_800')};
  }
  &:has(+ div > button:active) {
    background-color: ${props =>
      props.nextTaskUnlocked
        ? theme('colors.sa_primary_90')
        : theme('colors.black_800')};
  }
`

Navigation.displayName = 'Navigation'
NavigationTab.displayName = 'NavigationTab'
