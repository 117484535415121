import { Translation, getTranslation } from '@lyfta/components-i18n'
import { omit } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React, { PureComponent, useState } from 'react'
import { Field } from 'react-final-form'
import InputMask from 'react-input-mask'

import { Hide, View } from '../../../Assets/Icons'
import { Flex } from '../../Flex'
import FieldErrorNew from '../FieldErrorNew'
import ViewOnly from '../ViewOnly'
import {
  Container,
  Description,
  ErrorIcon,
  FixedHeightContainer,
  Input,
  Label,
  LabelAndInputContainer,
  ShowHidePasswordButton,
  Wrapper,
} from './styles'

const renderInput = (props, active, showPassword) => {
  const {
    input,
    meta,
    placeholder,
    type,
    fieldRef,
    handleBlur,
    onFocus,
    disabled,
    inputProps,
    mask,
    autoFocus,
    id,
    step,
  } = props

  if (mask) {
    return (
      <InputMask
        mask={mask}
        value=""
        {...input}
        onBlur={event => {
          input.onBlur()
          handleBlur(event)
        }}
        onFocus={() => {
          input.onFocus()
          onFocus()
        }}
      >
        <Input
          active={active}
          autoFocus={autoFocus}
          mask={mask}
          ref={fieldRef}
          type={type}
          {...inputProps}
          disabled={disabled}
          meta={meta}
          placeholder={placeholder}
          step={step}
        />
      </InputMask>
    )
  }

  const inputWithoutType = omit(input, ['type'])

  return (
    <Input
      active={active}
      autoFocus={autoFocus}
      className="animatedinputnew-input"
      mask={mask}
      ref={fieldRef}
      type={(() => {
        if (input.type === 'password' && showPassword === true) {
          return 'text'
        }
        return input.type
      })()}
      {...inputWithoutType}
      {...inputProps}
      disabled={disabled}
      id={id}
      meta={meta}
      placeholder={placeholder}
      step={step}
      onBlur={event => {
        handleBlur(event)
        input.onBlur()
      }}
      onFocus={() => {
        input.onFocus()
        onFocus()
      }}
    />
  )
}
renderInput.defaultProps = {
  autoFocus: false,
  inputProps: {},
  containerProps: {},
  mask: null,
  id: null,
  placeholder: null,
}
renderInput.propTypes = {
  autoFocus: PropTypes.bool,
  containerProps: PropTypes.object,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  disabled: PropTypes.bool.isRequired,
  fieldRef: PropTypes.object.isRequired,
  focused: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func.isRequired,
  id: PropTypes.string,
  input: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  mask: PropTypes.string,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  showPassword: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onLabelClick: PropTypes.func.isRequired,
}

const renderField = props => {
  const {
    description,
    input,
    meta,
    label,
    type,
    focused,
    fieldRef,
    onBlur,
    onFocus,
    onLabelClick,
    disabled,
    inputProps,
    containerProps,
    viewOnly,
    autosave,
    ...rest
  } = props

  const active = focused || !isEmpty(input?.value?.toString())
  const hasError = meta.touched && (meta.error || meta.submitError)

  let errorOrDescription = null

  if (hasError) {
    if (typeof meta.error !== 'string' && typeof meta.error[0] !== 'string')
      errorOrDescription = <>{meta.error[0].message} </>
    else errorOrDescription = <FieldErrorNew meta={meta} />
  } else if (description) {
    errorOrDescription = (
      <Description focused={focused}>{description}</Description>
    )
  }

  delete rest.id

  const renderAutosave = () => {
    return (
      focused &&
      autosave && (
        <Flex
          fontSize="12px"
          width="100%"
          justifyContent="flex-end"
          alignItems="center"
          flex="1"
          fontStyle="italic"
        >
          {getTranslation('actions.autoSaveOn')}
        </Flex>
      )
    )
  }

  const [showPassword, setShowPassword] = useState(false)

  if (viewOnly)
    return (
      <>
        <ViewOnly {...props} />
      </>
    )

  return (
    <Wrapper className="animatedinputnew-wrapper" {...rest}>
      <FixedHeightContainer>
        <Container
          active={active}
          className="animatedinputnew-container"
          disabled={disabled}
          error={hasError ? 1 : 0}
          {...containerProps}
          focused={focused}
          tabIndex="-1"
          onClick={onLabelClick}
        >
          <LabelAndInputContainer
            active={active}
            className="animatedinputnew-labelandinputcontainer"
            focused={focused}
          >
            {label && (
              <Label
                active={active}
                className="animatedinputnew-label"
                onClick={onLabelClick}
              >
                {label}
              </Label>
            )}
            {renderInput(props, active, showPassword)}
          </LabelAndInputContainer>
          {hasError ? (
            <ErrorIcon className="animatedinputnew-erroricon" />
          ) : null}
          {input.type === 'password' && !isEmpty(input?.value?.toString()) ? (
            <Translation>
              {t => {
                const translatedLabel = t(
                  `fields.${showPassword ? 'hide' : 'show'}Password`,
                )
                return (
                  <ShowHidePasswordButton
                    aria-label={translatedLabel}
                    title={translatedLabel}
                    type="button"
                    onClick={() => {
                      setShowPassword(!showPassword)
                    }}
                  >
                    {showPassword ? <Hide /> : <View />}
                  </ShowHidePasswordButton>
                )
              }}
            </Translation>
          ) : null}
        </Container>
      </FixedHeightContainer>
      <Flex justifyContent="space-between" alignItems="center">
        {errorOrDescription}
        {renderAutosave()}
      </Flex>
    </Wrapper>
  )
}
renderField.defaultProps = renderInput.defaultProps
renderField.propTypes = renderInput.propTypes

class AnimatedInputNew extends PureComponent {
  textField = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      focused: false,
    }
  }

  handleFocus = () => {
    this.setState({ focused: true })
  }

  handleBlur = event => {
    if (event?.relatedTarget) {
      if (event.relatedTarget.contains(event.target)) {
        return
      }
    }
    this.setState({ focused: false })
  }

  handleLabelClick = () => {
    this.textField.current.focus()
  }

  render() {
    const {
      label,
      name,
      type,
      description,
      handleBlur,
      viewOnly,
      autosave,
      ...rest
    } = this.props
    const { focused } = this.state

    return (
      <Field
        description={description}
        viewOnly={viewOnly}
        fieldRef={this.textField}
        focused={focused}
        handleBlur={event => {
          this.handleBlur(event)
          if (handleBlur) {
            handleBlur()
          }
        }}
        label={label}
        name={name}
        autosave={autosave}
        render={renderField}
        type={type}
        onFocus={this.handleFocus}
        onLabelClick={this.handleLabelClick}
        {...rest}
      />
    )
  }
}

AnimatedInputNew.defaultProps = {
  label: '',
  description: '',
  mask: null,
  type: 'text',
  handleBlur: noop,
  viewOnly: false,
  autosave: false,
}

AnimatedInputNew.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  handleBlur: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  mask: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  viewOnly: PropTypes.bool,
  autosave: PropTypes.bool,
}

export default AnimatedInputNew
