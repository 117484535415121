import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'
import get from 'lodash/get'

import { MODAL_HIDE, MODAL_LOADING, MODAL_SHOW } from '../../Actions/ui'

const initialState = {
  open: false,
  props: null,
  resolve: null,
  isLoading: false,
}

const show = (state, { payload, resolve }) =>
  state.merge({ open: true, props: get(payload, 'props'), resolve })
const hide = state => state.merge({ ...initialState })
const loading = (state, { isLoading }) => state.merge({ open: true, isLoading })

const handlers = {
  [MODAL_SHOW]: show,
  [MODAL_HIDE]: hide,
  [MODAL_LOADING]: loading,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
