import * as React from 'react'

function SvgWarning(props) {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M13.5714 3.913C12.8786 2.69567 11.1214 2.69567 10.4286 3.913L2.23859 18.3042C1.55403 19.5071 2.42423 21 3.80995 21H20.1901C21.5758 21 22.446 19.5071 21.7614 18.3042L13.5714 3.913ZM12.9727 14H11.0267L11.0267 8H12.9727L12.9727 14ZM10.5 17.5C10.5 16.6716 11.1716 16 12 16C12.8284 16 13.5 16.6716 13.5 17.5C13.5 18.3284 12.8284 19 12 19C11.1716 19 10.5 18.3284 10.5 17.5Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgWarning
