import * as React from 'react'

function SvgRectangle(props) {
  return (
    <svg
      width="704"
      height="428"
      viewBox="0 0 704 428"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1.5"
        y="1.5"
        width="701"
        height="425"
        rx="4.5"
        stroke="#E4E7EC"
        strokeWidth="3"
        strokeDasharray="30 30"
      />
    </svg>
  )
}

export default SvgRectangle
