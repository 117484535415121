import { I18n, TranslatedField, getTranslation } from '@lyfta/components-i18n'
import {
  Certificate,
  Flag,
  Icon,
  NewSearch,
  Rocket,
  Studies,
  Unlock,
} from '@lyfta/components-ui'
import get from 'lodash/get'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import {
  TYPE_ADDIONAL_RESOURCES,
  TYPE_EXPLORE,
  TYPE_GET_STARTED,
  TYPE_NEW_SEARCH,
  TYPE_STUDIES,
  TYPE_UNLOCK_CONTENT,
} from '../../Constants/contents'
import { BodyContainer, Container, Controls, SubTitle } from './styles'

class Card extends PureComponent {
  handlePreview = () => {
    const { item, onView } = this.props
    onView(item)
  }

  handleSelect = () => {
    const { item, onSelect } = this.props
    onSelect(item)
  }

  renderSubHead = item => {
    const hasParent = get(item, 'parent') !== null
    const parentObject = get(item, 'parent', {})
    const series = get(parentObject, 'parent')

    if (!hasParent) {
      return <I18n i18nKey={`content.type.${get(item, 'type')}`} />
    }

    if (!parentObject) return null

    return (
      <>
        <TranslatedField field="name" object={parentObject} />
        {series && (
          <>
            : <TranslatedField field="name" object={series} />
          </>
        )}
      </>
    )
  }

  getIcon = type => {
    switch (type) {
      case TYPE_GET_STARTED:
        return Flag
      case TYPE_EXPLORE:
        return Rocket
      case TYPE_NEW_SEARCH:
        return NewSearch
      case TYPE_UNLOCK_CONTENT:
        return Unlock
      case TYPE_STUDIES:
        return Studies
      case TYPE_ADDIONAL_RESOURCES:
        return Certificate
      default:
        return Flag
    }
  }

  render() {
    const {
      width,
      height,
      onPlus,
      item,
      selectable,
      index,
      heightBody,
    } = this.props
    const contentSubtitle = get(item, 'subtitle')
    const contentId = get(item, 'id')

    return (
      <Container
        data-intercom-target={contentId}
        height={height}
        id={`card-${index}`}
        width={width}
        onClick={selectable ? this.handleSelect : this.handlePreview}
      >
        <BodyContainer height={heightBody} mt={4}>
          <Icon
            fill="primary"
            glyph={this.getIcon(contentId)}
            height="90px"
            width="90px"
            onClick={onPlus}
          />
        </BodyContainer>

        <Controls>
          <SubTitle>
            {getTranslation(`dashboard.getStartedRibbonRow.${contentSubtitle}`)}
          </SubTitle>
        </Controls>
      </Container>
    )
  }
}

Card.defaultProps = {
  height: 260,
  heightBody: 150,
  item: null,
  selectable: false,
  width: 250,
  index: '',
  onPlus: null,
  onSelect: noop,
  onView: noop,
}

Card.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  heightBody: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.string,

  item: PropTypes.shape({
    type: PropTypes.string,
    posterUrlSmall: PropTypes.string,
  }),
  selectable: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onPlus: PropTypes.func,
  onSelect: PropTypes.func,
  onView: PropTypes.func,
}

export default Card
