import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import { IS_IN_SCENE, IS_NOT_IN_SCENE } from '../../Actions/ui'

const initialState = {
  isInScene: false,
}

const handleIsInScene = state => state.merge({ isInScene: true })
const handleIsNotInScene = state => state.merge({ isInScene: false })

const handlers = {
  [IS_IN_SCENE]: handleIsInScene,
  [IS_NOT_IN_SCENE]: handleIsNotInScene,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
