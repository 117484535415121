import {
  createDeleteReducerHandlers,
  createEntityFields,
  createReducer,
  createReducerHandlers,
} from '@lyfta/components-data'

import {
  CREATE_TAG,
  DELETE_TAG,
  LOAD_TAG,
  LOAD_TAGS,
  UPDATE_TAG,
} from '../Actions/tags'

const initialState = {
  ...createEntityFields('tags'),
}

const handlers = {
  ...createDeleteReducerHandlers('tags', DELETE_TAG),
  ...createReducerHandlers('tags', LOAD_TAGS, {
    withReplace: true,
    mapToKey: 'tags',
  }),
  ...createReducerHandlers('tags', LOAD_TAG, {
    mapToKey: 'currentTag',
    singular: true,
  }),
  ...createReducerHandlers('tags', CREATE_TAG, {
    mapToKey: 'currentTag',
    singular: true,
  }),
  ...createReducerHandlers('tags', UPDATE_TAG, {
    mapToKey: 'currentTag',
    singular: true,
  }),
}

export default createReducer(initialState, handlers)
