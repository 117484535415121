import PropTypes from 'prop-types'
import React from 'react'

import {
  Tick as CheckedIcon,
  Lock as LockIcon,
} from '../../../../../../../Assets/Functional'
import { Flag as FlagIcon } from '../../../../../../Assets/QuestTracker'
import { IconContainer } from './styles'

export const ActivityIcon = ({ activity: { complete }, canBeSelected }) => {
  let activityIcon = LockIcon
  if (canBeSelected && complete) {
    activityIcon = CheckedIcon
  } else if (canBeSelected && !complete) {
    activityIcon = FlagIcon
  }
  return <IconContainer glyph={activityIcon} height={40} width={40} />
}

ActivityIcon.propTypes = {
  activity: PropTypes.object.isRequired,
  canBeSelected: PropTypes.bool.isRequired,
}
