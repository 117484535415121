import { appEnv, isTeacherApp } from '@lyfta/components-config'
import { logOut } from '@lyfta/components-data/src/Store/Actions/viewer'
import {
  getIsSubscribed,
  getViewer,
} from '@lyfta/components-data/src/Store/Selectors/viewer'
import {
  I18n,
  Translation,
  getCurrentLanguage,
  getTranslation,
} from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Help } from '../../../Assets'
import logoImage from '../../../Assets/Icons/logoWhite.png'
import { Icon } from '../../../Components'
import Locale from '../../../Components/Locale'
import { USER_TYPES } from '../../../Constants/users'
import { Connection } from '../../../Services/Connection'
import { reactEvent } from '../../../Services/Utils'
import { LogoutItem } from './logout'
import {
  AppEnvLabel,
  ButtonPortal,
  CommandPanel,
  Container,
  HomeLink,
  MenuButton,
  PageTitle,
  PageTitlePortal,
  Row,
  UserSettingsOption,
  UserSettingsWrapper,
} from './styles'

const Header = ({ nonStandardHeader, removeMenu }) => {
  const CustomHeader = nonStandardHeader
  const { email, daysUntilTrialExpires, type } = useSelector(getViewer)
  const isSubscribed = useSelector(getIsSubscribed)
  const dispatch = useDispatch()

  const onReactEvent = () => {
    reactEvent({ action: 'clicked_help_icon_header' })
  }

  if (
    daysUntilTrialExpires <= 0 &&
    daysUntilTrialExpires !== null &&
    !isSubscribed &&
    type !== USER_TYPES.admins
  ) {
    dispatch(logOut())
  }
  return (
    <Container removeMenu={removeMenu}>
      <Connection />
      <CommandPanel>
        <HomeLink exact href="/" to="/">
          <img alt="Lyfta" src={logoImage} />
        </HomeLink>
        <AppEnvLabel>
          {appEnv !== 'production' ? `${appEnv.toUpperCase()} ENVIRONMENT` : ''}
        </AppEnvLabel>
        <UserSettingsWrapper>
          {daysUntilTrialExpires &&
            daysUntilTrialExpires <= 30 &&
            type === USER_TYPES.teachers &&
            !isSubscribed && (
              <UserSettingsOption>
                <I18n
                  i18nKey="auth.daysUntilTrialExpires"
                  options={{ count: daysUntilTrialExpires }}
                />
              </UserSettingsOption>
            )}
          <UserSettingsOption id="userEmail">{email}</UserSettingsOption>
          <Locale fill="colors.neutral_200" />
          {type !== USER_TYPES.admins && (
            <MenuButton>
              <a
                href={`https://help.lyfta.com/${getCurrentLanguage()}`}
                id="link-open-help-hub"
                rel="noopener noreferrer"
                target="_blank"
                title={getTranslation('tips.openHelpCenter')}
                onClick={onReactEvent}
              >
                <Icon fill="white" glyph={Help} height={21} width={21} />
              </a>
            </MenuButton>
          )}
          <Translation>{t => <LogoutItem t={t} />}</Translation>
        </UserSettingsWrapper>
      </CommandPanel>
      {!removeMenu && !isTeacherApp && (
        <Row>
          <PageTitle ml={3}>
            {nonStandardHeader && <CustomHeader />}
            <PageTitlePortal>
              <div id="page-title" />
            </PageTitlePortal>
          </PageTitle>
          <ButtonPortal>
            <div data-intercom-target="Button Portal" id="button-portal" />
          </ButtonPortal>
        </Row>
      )}
    </Container>
  )
}

Header.defaultProps = { removeMenu: false, nonStandardHeader: null }

Header.propTypes = {
  removeMenu: PropTypes.bool,
  nonStandardHeader: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
}

export default Header
