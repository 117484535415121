import { styled, themeGet } from '@lyfta/components-theme'
import Dropzone from 'react-dropzone'

import { Box } from '../Box'

export const StyledDropzone = styled(Dropzone).attrs({
  activeClassName: 'docs-dropzone-active',
  acceptClassName: 'accept',
  rejectClassName: 'reject',
  disabledClassName: 'disabled',
})`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;
  padding-top: 24px;
  padding-bottom: 32px;
  cursor: pointer;
  border: 1px solid ${themeGet('colors.black_300')};
  border-radius: 5px;

  &.docs-dropzone-active {
  }

  &.accept {
    border: 1px solid ${themeGet('colors.primary_100')};
    background-color: ${themeGet('colors.primary_60_50')};
  }
  &.reject {
    border: 1px solid ${themeGet('colors.error_100')};
    background-color: ${themeGet('colors.error_60_50')};
  }

  > svg {
    width: 32px;
    height: 32px;
    fill: ${themeGet('colors.black_600')};
  }
`

export const Title = styled(Box).attrs(() => ({
  mt: 4,
}))`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: ${themeGet('colors.black_700')};
`

export const BrowseText = styled.div`
  text-decoration: underline;
`
