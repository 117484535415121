import * as React from 'react'

function SvgCollection(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8336 1.0415H4.16641C3.36173 1.04307 2.70937 1.6954 2.70859 2.5001V16.8297C2.70781 17.3594 2.99609 17.8485 3.45937 18.1055C3.92343 18.3625 4.48983 18.3477 4.93983 18.0664L9.88823 14.9734C9.95698 14.932 10.0421 14.9312 10.1109 14.9726L15.0601 18.0664C15.5101 18.3477 16.0765 18.3625 16.5406 18.1055C17.0038 17.8485 17.2921 17.3594 17.2913 16.8297V2.50012C17.2905 1.69544 16.6383 1.04306 15.8336 1.0415ZM13.4984 7.3165L12.0242 8.49618L12.0234 8.4954C11.9 8.59462 11.8484 8.75946 11.8937 8.9118L12.5695 11.1642C12.6179 11.3251 12.5578 11.4986 12.4195 11.5954C12.282 11.6915 12.0976 11.6884 11.9633 11.5868L10.2336 10.2892V10.29C10.0953 10.1861 9.90468 10.1861 9.7664 10.29L8.03672 11.5876C7.90234 11.6884 7.71796 11.6915 7.58048 11.5954C7.4422 11.4986 7.38204 11.3251 7.43048 11.1642L8.10626 8.9118H8.10548C8.15157 8.75946 8.10001 8.59462 7.97657 8.4954L6.50157 7.3165C6.37267 7.2126 6.32345 7.03916 6.37814 6.88368C6.43282 6.72743 6.5797 6.62274 6.74532 6.62274H8.43438C8.58984 6.62274 8.73047 6.53055 8.79142 6.3868L9.6422 4.40242C9.70391 4.25946 9.84454 4.16648 10 4.16648C10.1555 4.16648 10.2961 4.25945 10.3578 4.40242L11.2086 6.3868C11.2696 6.53055 11.4102 6.62274 11.5657 6.62274H13.2547C13.4203 6.62274 13.5672 6.72743 13.6219 6.88368C13.6766 7.03915 13.6273 7.21258 13.4984 7.3165Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgCollection
