import { css, styled, themeGet as theme } from '@lyfta/components-theme'

import { Box } from '../Box'

export const SimpleCheckbox = styled(Box).attrs({
  tabIndex: 0,
})`
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid ${theme('colors.black_600')};
  border-radius: 5px;

  ${props =>
    props.active &&
    css`
      :after {
        content: '';
        position: absolute;
        width: 11px;
        height: 8px;
        left: 3px;
        top: 2px;
        border-style: solid;
        border-width: 2px;
        border-bottom-color: ${theme('colors.black_600')};
        border-left-color: ${theme('colors.black_600')};
        border-top-color: ${theme('colors.clear')};
        border-right-color: ${theme('colors.clear')};
        transform: rotate(-45deg);
      }
    `};

  ${props =>
    !props.active &&
    props.dirty &&
    css`
      :after {
        content: '';
        position: absolute;
        width: 11px;
        height: 1px;
        left: 3px;
        top: 7px;
        border-style: solid;
        border-width: 1px;
        border-bottom-color: ${theme('colors.black_600')};
      }
    `};
`

export const Container = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100%;
  width: 100%;
`
