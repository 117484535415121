import {
  createDeleteHandler,
  createEntityFields,
  createLoadHandler,
  createReducer,
  createReducerHandlers,
} from '@lyfta/components-data'

import {
  ADD_TO_FAVORITES,
  LOAD_FAVORITES,
  REMOVE_FROM_FAVORITES,
} from '../Actions/favorites'

const initialState = {
  ...createEntityFields('favorites'),
  error: null,
  isLoading: false,
  isLoaded: false,
}

const handlers = {
  ...createReducerHandlers('favorites', LOAD_FAVORITES, {
    withReplace: true,
    mapToKey: 'favorites',
  }),

  [ADD_TO_FAVORITES.SUCCESS]: createLoadHandler('favorites'),
  [REMOVE_FROM_FAVORITES.SUCCESS]: createDeleteHandler('favorites'),
}

export default createReducer(initialState, handlers)
