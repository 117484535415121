import { BREAKPOINTS } from '@lyfta/components-theme'
import React from 'react'

export const AppContext = React.createContext({
  breakpoint: BREAKPOINTS.MOBILE,
})

export function withAppContext(Component) {
  return function ComponentWithAppContext(props) {
    return (
      <AppContext.Consumer>
        {appContext => <Component {...props} {...appContext} />}
      </AppContext.Consumer>
    )
  }
}
