import { logOut } from '@lyfta/components-data/src/Store/Actions/viewer'
import { getViewerType } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { I18n, getTranslation } from '@lyfta/components-i18n'
import { Logout as LogoutIcon } from '@lyfta/components-student'
import { back } from '@lyfta/components-student/src/Store/Actions/ui'
import { getCurrentLessonPlanId } from '@lyfta/components-student/src/Store/Selectors/lessonPlans'
import { getLessonsRaw } from '@lyfta/components-student/src/Store/Selectors/lessons'
import { getIsTeachingInFrontOfClass } from '@lyfta/components-student/src/Store/Selectors/questTracker'
import { getIsQuest } from '@lyfta/components-student/src/Store/Selectors/UI/quest'
import {
  ButtonNew,
  Flex,
  LyftaLogoSymbolNoBorder,
  Modal,
  USER_TYPES,
  shadow,
} from '@lyfta/components-ui'
import { getIsPreviewMode } from '@lyfta/components-ui/src/Helpers/getIsPreviewMode'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getIsViewerWithLessons } from '../../../utils/getIsViewerWithLessons'
import { HomeButton, LessonSelectionButton } from './Buttons'
import {
  CenteredTabs,
  LogoIcon,
  NavigationLeftSidebar,
  PrimaryButtonIcon,
  SubtitleModal,
  TitleModal,
} from './styles'

export const NavigationLeftSidebarComponent = () => {
  const dispatch = useDispatch()
  const viewerType = useSelector(getViewerType)
  const isQuest = useSelector(getIsQuest)
  const lessonsRaw = useSelector(getLessonsRaw)
  const isTeachingInFrontOfClass = useSelector(getIsTeachingInFrontOfClass)
  const lessonPlanId = useSelector(getCurrentLessonPlanId)

  const isPreviewMode = getIsPreviewMode({
    viewerType,
    isTeachingInFrontOfClass,
    lessonPlanId,
    isQuest,
  })
  const isViewerWithLessons = getIsViewerWithLessons({ viewerType, lessonsRaw })

  const [showModal, setshowModal] = useState(false)

  const confirmLogout = useCallback(() => {
    setshowModal(true)
  }, [])

  const refreshPage = useCallback(() => {
    dispatch(back.toggle())
  }, [])

  const handleLogOut = () => {
    if (viewerType === USER_TYPES.homeStudents) {
      dispatch(logOut(true, '/login-with-password'))
    } else {
      dispatch(logOut())
    }
  }
  const renderCustomButtons = () => {
    return (
      <Flex mt={5}>
        <ButtonNew
          id="btn-start-quest"
          mr={3}
          secondary
          onClick={() => setshowModal(false)}
          default={{ maxHeight: '60px' }}
        >
          <I18n text="actions.cancel" />
        </ButtonNew>
        <ButtonNew
          id="btn-start-quest"
          onClick={handleLogOut}
          default={{ maxHeight: '60px' }}
        >
          <I18n text="header.logout" />
        </ButtonNew>
      </Flex>
    )
  }

  return (
    <NavigationLeftSidebar id="div-navigation-left-sidebar">
      <LogoIcon onClick={refreshPage} glyph={LyftaLogoSymbolNoBorder} />
      {!isPreviewMode && (
        <CenteredTabs>
          <HomeButton />
          {isViewerWithLessons && <LessonSelectionButton />}
        </CenteredTabs>
      )}
      <ButtonNew
        id="btn-logout-student-app"
        leadingIcon={<PrimaryButtonIcon glyph={LogoutIcon} />}
        secondary
        subtype="navigation"
        title={getTranslation('header.logout')}
        type="icon"
        onClick={confirmLogout}
      >
        {getTranslation('header.logout')}
      </ButtonNew>
      <Modal
        hideClose
        isOpen={showModal}
        renderCustomButtons={renderCustomButtons}
        modalStyle={{
          borderRadius: '20px',
          boxShadow: shadow.floating,
          justifyContent: 'center',
          titleTag: TitleModal,
          subtitleTag: SubtitleModal,
          wrapperContainer: {
            padding: '30px 40px',
            background:
              'linear-gradient(102.48deg, #FFFFFF 3.28%, rgba(255, 255, 255, 0.7) 95.96%), linear-gradient(94.8deg, rgba(69, 83, 104, 0.5) 3.84%, rgba(228, 231, 236, 0.5) 95.49%);',
          },
          titleContainer: {
            justifyContent: 'center',
          },
          subtitleContainer: {
            justifyContent: 'center',
            marginTop: '12px',
          },
        }}
        modalText={{
          modalTitle: 'header.logout',
          modalSubtitle: 'auth.areYouSure',
        }}
        buttons={{
          okText: <I18n text="header.logout" />,
          cancelText: <I18n text="actions.cancel" />,
        }}
        content={{
          title: 'header.logout',
          subtitle: 'auth.areYouSure',
        }}
        onCallback={handleLogOut}
        onDismissModal={() => setshowModal(false)}
      />
    </NavigationLeftSidebar>
  )
}
