import { Api as apiCall, createAsyncAction } from '@lyfta/components-data'

export const TOGGLE_NEW_SEARCH_TAGS_AND_KEYWORDS =
  'search/TOGGLE_NEW_SEARCH_TAGS_AND_KEYWORDS'
export const TOGGLE_TAG = 'search/TOGGLE_TAG'
export const TOGGLE_FILTERS = 'search/TOGGLE_FILTERS'
export const TOGGLE_TOTAL_RESULT = 'search/TOGGLE_TOTAL_RESULT'
export const TOGGLE_SORT = 'search/TOGGLE_SORT'
export const SEARCH_TEXT = 'search/SEARCH_TEXT'
export const TOGGLE_KEYWORD = 'search/TOGGLE_KEYWORD'
export const UPDATE_SIZE = 'search/UPDATE_SIZE'
export const CHANGE_PAGE = 'search/CHANGE_PAGE'
export const CHANGE_TYPE = 'search/CHANGE_TYPE'
export const CHANGE_DASHBOARD = 'search/CHANGE_DASHBOARD'
export const CHANGE_TEXT_CONTENT = 'search/CHANGE_TEXT_CONTENT'
export const CLEAR = 'search/CLEAR'
export const CLEAR_SEARCHEDITORS = 'search/CLEAR_SEARCHEDITORS'

export const TOGGLE_SEARCHEDITORS = 'search/TOGGLE_SEARCHEDITORS'
export const TOGGLE_SEARCHEDITORS_FILTERS =
  'search/TOGGLE_SEARCHEDITORS_FILTERS'
export const TOGGLE_SEARCHEDITORS_SORT = 'search/TOGGLE_SEARCHEDITORS_SORT'
export const TOGGLE_SEARCHEDITORS_KEYWORDS =
  'search/TOGGLE_SEARCHEDITORS_KEYWORDS'
export const TOGGLE_SEARCHEDITORS_TOTALRESULT =
  'search/TOGGLE_SEARCHEDITORS_TOTALRESULT'

export const toggleSearchEditors = searchEditors => ({
  type: TOGGLE_SEARCHEDITORS,
  searchEditors,
})

export const toggleSearchEditorsFilters = filters => ({
  type: TOGGLE_SEARCHEDITORS_FILTERS,
  filters,
})

export const toggleSearchEditorsSort = sort => ({
  type: TOGGLE_SEARCHEDITORS_SORT,
  sort,
})

export const toggleSearchEditorsKeywords = keywords => ({
  type: TOGGLE_SEARCHEDITORS_KEYWORDS,
  keywords,
})

export const toggleSearchEditorsTotalResult = totalResult => ({
  type: TOGGLE_SEARCHEDITORS_TOTALRESULT,
  totalResult,
})

export const LOAD_CONTENTS_COUNT = createAsyncAction(
  'search/LOAD_CONTENTS_COUNT',
)

export const toggleNewSearchTagsAndKeywords = newSearch => ({
  type: TOGGLE_NEW_SEARCH_TAGS_AND_KEYWORDS,
  newSearch,
})
export const toggleTag = tag => ({
  type: TOGGLE_TAG,
  tag,
})
export const toggleFilters = filters => ({
  type: TOGGLE_FILTERS,
  filters,
})
export const toggleTotalResult = totalResult => ({
  type: TOGGLE_TOTAL_RESULT,
  totalResult,
})
export const toggleSort = sort => ({
  type: TOGGLE_SORT,
  sort,
})
export const toggleKeyword = keyword => ({
  type: TOGGLE_KEYWORD,
  keyword,
})

export const searchText = text => ({
  type: SEARCH_TEXT,
  text,
})

export const searchTextContent = () => ({
  type: CHANGE_TEXT_CONTENT,
})

export const updateSize = size => ({
  type: UPDATE_SIZE,
  size,
})

export const changePage = page => ({
  type: CHANGE_PAGE,
  page,
})

export const changeType = type => ({
  type: CHANGE_TYPE,
  contentType: type,
})

export const changeDashboard = dashboard => ({
  type: CHANGE_DASHBOARD,
  dashboard,
})

export const loadContentsCount = ({ filter } = {}) =>
  apiCall({
    method: 'GET',
    endpoint: '/contents/count',
    query: {
      filter,
      include:
        'author,tags,tags.tagGroup,favorites,favorites.content,parent,parent.parent,children,parent.children,lessonPlanTemplate,lessonPlanTemplates,lessonPlanTemplates.content,assetDetails,poster,icon,trailer,contentDetail,ageRanges,subjects',
    },
    needsNormalization: false,
    types: LOAD_CONTENTS_COUNT,
    payload: { filter },
  })

export const clearSearch = () => ({ type: CLEAR })
export const clearSearchEditors = () => {
  return { type: CLEAR_SEARCHEDITORS }
}
