import { styled } from '@lyfta/components-theme'

export const LoadingLayer = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ isRocketLoading, alpha }) => {
    if (isRocketLoading) {
      return `rgba(0, 0, 0, ${alpha})`
    }
    return `rgba(0, 0, 0, 0.92)`
  }};
  position: absolute;
  z-index: 2001;
  cursor: not-allowed;
`

export const LoadingContainer = styled.div`
  min-height: 200px;
  min-width: 200px;
  background-color: transparent;
  position: absolute;
  top: ${({ isRocketLoading, top: topValue }) => {
    if (isRocketLoading) {
      return `${topValue}`
    }

    return '50%'
  }};
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`
