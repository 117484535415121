import { I18n } from '@lyfta/components-i18n'
import React from 'react'

import {
  Description,
  DescriptionText,
  Header,
  LyftaLogo,
  MainTitle,
  NotSupportedBackground,
  NotSupportedContainer,
  NotSupportedScreen,
} from './styles'

const NotSupported = () => {
  return (
    <NotSupportedScreen>
      <NotSupportedBackground>
        <LyftaLogo />
        <NotSupportedContainer>
          <Header>
            <I18n tag={MainTitle} text="auth.notSupportedMobile" />
            <Description>
              <I18n
                tag={DescriptionText}
                text="auth.notSupportedMobileMessage"
              />
            </Description>
          </Header>
        </NotSupportedContainer>
      </NotSupportedBackground>
    </NotSupportedScreen>
  )
}

export default NotSupported
