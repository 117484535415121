import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import UploadIcon from 'react-icons/lib/go/cloud-upload'

import { BrowseButton, StyledDropzone, Title } from './styles'

class DropzoneTrailer extends PureComponent {
  handleFileInput = files => {
    const { onDrop } = this.props

    onDrop(files)
  }

  render() {
    const { ...rest } = this.props
    return (
      <StyledDropzone onDrop={this.handleFileInput} {...rest}>
        <UploadIcon />
        <Title mt={4}>
          <I18n text="files.dragDropTrailer" /> <I18n text="files.or" />
        </Title>
        <BrowseButton mt={4}>
          <I18n text="files.browseFiles" />
        </BrowseButton>
      </StyledDropzone>
    )
  }
}

DropzoneTrailer.defaultProps = {
  onDrop: () => {},
}

DropzoneTrailer.propTypes = {
  onDrop: PropTypes.func,
}

export default DropzoneTrailer
