import { createReducer } from '../../helpers'
import { createEntityFields, createReducerHandlers } from '../../utils'
import {
  LOAD_LYFTA_METADATA,
  UPDATE_LYFTA_METADATA,
} from '../Actions/lyftaMetadata'

const initialState = {
  ...createEntityFields('lyftaMetadata'),
}

const handlers = {
  ...createReducerHandlers('lyftaMetadata', LOAD_LYFTA_METADATA, {
    mapToKey: 'currentLyftaMetadatum',
    singular: true,
  }),
  ...createReducerHandlers('lyftaMetadata', UPDATE_LYFTA_METADATA, {
    mapToKey: 'currentLyftaMetadatum',
    singular: true,
  }),
}

export default createReducer(initialState, handlers)
