import { bingTracking } from '@lyfta/components-config'
import React from 'react'
import { Helmet } from 'react-helmet'

export const useBingTracking = (withBingTracking, bingTrackingId) => {
  return (
    withBingTracking &&
    bingTracking &&
    bingTrackingId && (
      <Helmet>
        <script id="bing-clarity-tracking-integration" type="text/javascript">
          {`
    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i+"?ref=bwt";
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "${bingTrackingId}");
    `}
        </script>
      </Helmet>
    )
  )
}
