import { getCurrentLanguage } from '@lyfta/components-i18n'
import { createSelector } from 'reselect'

export const getState = state => state.persist

export const getJWT = createSelector(getState, state => state.jwt)
export const getEmail = createSelector(getState, state => state.email)
export const getPath = createSelector(getState, state => state.path)
export const getFirstname = createSelector(getState, state => state.firstname)
export const getLastname = createSelector(getState, state => state.lastname)
export const getSource = createSelector(getState, state => state.source)
export const getOrganization = createSelector(
  getState,
  state => state.organization,
)
export const getUserType = createSelector(getState, state => state.userType)

export const getResourceId = createSelector(getState, state => state.resourceId)

export const getLessonPlanId = createSelector(
  getState,
  state => state.lessonPlanId,
)

export const getLocale = () => {
  return getCurrentLanguage()
}
