import { isTeacherApp } from '@lyfta/components-config'
import { opacify, styled, themeGet } from '@lyfta/components-theme'
import { NavLink } from 'react-router-dom'

import { Box, Clickable, Flex } from '../../../Components'

export const Container = styled(Flex).attrs(props => {
  if (isTeacherApp) return {}
  return {
    height: props.removeMenu ? '60px' : themeGet('sizes.header')(props),
  }
})`
  flex-direction: column;
  background-color: ${themeGet('colors.primary')};
  box-shadow: ${props => `
    0 4px 4px 0 ${opacify(0.24, themeGet('colors.clearFromBlack')(props))},
    0 0 4px 0 ${opacify(0.12, themeGet('colors.clearFromBlack')(props))}
  `};
  z-index: 100;
`

export const HomeLink = styled(NavLink)`
  > img {
    height: 26px;
    padding-left: 17px;
    margin-top: 7px;
    margin-bottom: 2px;
  }
`

export const ButtonPortal = styled(Flex)`
  flex-direction: row;
  > div {
    display: flex;
    flex-direction: row;
  }
`
export const PageTitle = styled(Flex)`
  flex-direction: row;
  flex-grow: 1;
  font-size: 20px;
  font-weight: 500;
  color: ${themeGet('colors.white')};
  overflow: hidden;
`

export const PageTitlePortal = styled(Flex)`
  flex-direction: row;
  flex-grow: 1;
  font-size: 20px;
  font-weight: 500;
  color: ${themeGet('colors.white')};
  overflow: hidden;

  > div {
    display: flex;
    flex-direction: row;
  }
`

export const CommandPanel = styled(Flex).attrs(() => ({
  width: 1,
}))`
  justify-content: space-between;
  align-items: center;
  background-color: ${isTeacherApp
    ? themeGet('colors.primary')
    : themeGet('colors.primaryActive')};
`

export const HamburgerButton = styled(Clickable)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${themeGet('sizes.sidebar')};
`

export const Row = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  height: 100%;

  > div {
    align-items: center;
    height: 100%;
  }
`

export const Title = styled(Box)`
  flex-grow: 1;
  padding-left: 10px;
  font-size: 24px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  color: ${themeGet('colors.white')};
`
export const AppEnvLabel = styled(Flex)`
  color: ${themeGet('colors.white')};
  font-weight: 600;
`

export const NextButton = styled(Flex)`
  margin-top: 32px;
  margin-right: 27px;
  align-items: center;
  cursor: pointer;
  color: ${themeGet('colors.white')};
`
export const UserSettingsWrapper = styled(Flex)`
  color: ${themeGet('colors.white')};
  align-items: center;
  margin-right: 1rem;
  line-height: 0;
`
export const UserSettingsOption = styled(Flex)`
  align-items: center;
  padding-left: 1rem;
`
export const MenuButton = styled(Flex)`
  cursor: pointer;
  padding: 5px;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  &:focus,
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`

Container.displayName = 'Container'
HomeLink.displayName = 'HomeLink'
ButtonPortal.displayName = 'ButtonPortal'
PageTitle.displayName = 'PageTitle'
PageTitlePortal.displayName = 'PageTitlePortal'
CommandPanel.displayName = 'CommandPanel'
HamburgerButton.displayName = 'HamburgerButton'
Row.displayName = 'Row'
Title.displayName = 'Title'
AppEnvLabel.displayName = 'AppEnvLabel'
NextButton.displayName = 'NextButton'
UserSettingsWrapper.displayName = 'UserSettingsWrapper'
UserSettingsOption.displayName = 'UserSettingsOption'
MenuButton.displayName = 'MenuButton'
