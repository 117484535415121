import PT from 'prop-types'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { questTrackerData } from '../../../../../Store/Actions/questTracker'
import { InformationText } from '../InformationText'
import { Submit } from '../Submit'

const Information = ({ task: { description, callToAction, id } }) => {
  const dispatch = useDispatch()

  const saveResponse = useCallback(() => {
    dispatch(questTrackerData.saveResponse(id, { answer: { viewed: true } }))
  })

  return (
    <>
      <InformationText textSource={description} />
      <Submit onClick={saveResponse} buttonText={callToAction} />
    </>
  )
}

Information.propTypes = {
  task: PT.object.isRequired,
}
export default Information
