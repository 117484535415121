import * as React from 'react'

function Language(props) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M16.4066 7.00005H20.6503C19.4503 4.93755 17.4222 3.04688 14.0472 2.23438C14.7972 3.62188 16.0066 5.43755 16.4066 7.00005ZM14.3878 7.00005C13.8503 5.21255 13.0378 3.55005 12.0003 2.05005C10.9628 3.55005 10.1503 5.21255 9.61281 7.00005H14.3878ZM2.00031 12C2.00031 12.8625 2.12531 14.1094 2.43781 14.9688H7.00031C6.90031 14.1438 6.79718 12.85 6.79718 12C6.79718 11.15 6.90031 9.85625 7.00031 9.03125H2.43781C2.14093 9.78125 2.00031 11.1375 2.00031 12ZM7.54718 17H3.31281C4.32218 18.9531 6.54718 21.0938 9.98468 21.8438C9.23468 20.4562 7.94718 18.5625 7.54718 17ZM3.35031 7.00005H7.57843C7.97843 5.43755 9.2503 3.62188 10.0003 2.23438C6.64093 2.96875 4.55031 4.92505 3.35031 7.00005ZM9.61281 17C10.1503 18.7875 10.9628 20.5 12.0003 22C13.0378 20.5 13.8503 18.7875 14.3878 17H9.61281ZM8.78156 12C8.78156 12.85 8.87218 14.1438 8.98468 14.9688H15.0159C15.1284 14.1438 15.1722 12.85 15.1722 12C15.1722 11.15 15.1284 9.84375 15.0159 9.03125H8.98468C8.87218 9.84375 8.78156 11.15 8.78156 12ZM16.4691 17C16.0691 18.5625 14.7659 20.4562 14.0159 21.8438C17.4378 21.0781 19.6003 18.9688 20.7034 17H16.4691ZM17.1722 12C17.1722 12.85 17.0691 14.1438 16.9691 14.9688H21.5941C21.9222 13.8281 22.0003 12.8625 22.0003 12C22.0003 11.1375 21.8753 9.82812 21.5316 9.03125H16.9691C17.0691 9.85625 17.1722 11.15 17.1722 12Z"
        fill="#0C0C0C"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Language
