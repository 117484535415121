import { Api as apiCall, createAsyncAction } from '@lyfta/components-data'

export const LOAD_TAGS = createAsyncAction('tags/LOAD_TAGS')
export const LOAD_TAG = createAsyncAction('tags/LOAD_TAG')
export const UPDATE_TAG = createAsyncAction('tags/UPDATE_TAG')
export const CREATE_TAG = createAsyncAction('tags/CREATE_TAG')
export const DELETE_TAG = createAsyncAction('tags/DELETE_TAG')

export const REQUEST_UPDATE_TAG = UPDATE_TAG.REQUEST_PERFORM
export const REQUEST_CREATE_TAG = CREATE_TAG.REQUEST_PERFORM

export const clearCurrent = () => ({ type: LOAD_TAG.CLEAR })
export const deleteTag = id =>
  apiCall({
    method: 'DELETE',
    endpoint: `/tags/${id}`,
    query: {},
    types: DELETE_TAG,
  })

export const createTag = attributes =>
  apiCall({
    method: 'POST',
    endpoint: '/tags',
    query: {
      data: {
        type: 'tags',
        attributes,
      },
    },
    types: CREATE_TAG,
    paged: true,
  })

export const loadTags = ({
  filter,
  page,
  include = 'tagGroup',
  sort = 'name',
} = {}) =>
  apiCall({
    method: 'GET',
    endpoint: `/tags`,
    query: {
      include,
      filter,
      page,
      sort,
    },
    types: LOAD_TAGS,
  })

export const loadTag = id =>
  apiCall({
    method: 'GET',
    endpoint: `/tags/${id}`,
    query: {
      id,
      include: 'tagGroup',
    },
    types: LOAD_TAG,
  })

export const updateTag = (attributes, relationships = {}) => {
  return apiCall({
    method: 'PATCH',
    endpoint: `/tags/${attributes.id}`,
    query: {
      data: {
        type: 'tags',
        attributes,
        relationships,
      },
    },
    types: UPDATE_TAG,
  })
}

export const requestUpdateTag = (attributes, resolve) => ({
  type: REQUEST_UPDATE_TAG,
  attributes,
  resolve,
})
export const requestCreateTag = (attributes, resolve) => ({
  type: REQUEST_CREATE_TAG,
  attributes,
  resolve,
})
