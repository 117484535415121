import {
  createDeleteReducerHandlers,
  createReducerHandlers,
} from '../../../utils'
import {
  CREATE_USER,
  DELETE_USER,
  LOAD_USER,
  LOAD_USERS,
  UPDATE_USER,
} from '../../Actions/users'

export default {
  ...createDeleteReducerHandlers('users', DELETE_USER, {
    mapToKey: 'users',
  }),
  ...createReducerHandlers('users', LOAD_USERS, {
    withReplace: true,
    mapToKey: 'users',
  }),
  ...createReducerHandlers('users', LOAD_USER, {
    singular: true,
    mapToKey: 'currentUser',
  }),
  ...createReducerHandlers('users', CREATE_USER, {
    singular: true,
    mapToKey: 'currentUser',
  }),
  ...createReducerHandlers('users', UPDATE_USER, {
    singular: true,
    mapToKey: 'currentUser',
  }),
}
