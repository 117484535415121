import { css, keyframes, styled, themeGet } from '@lyfta/components-theme'
import { ButtonNew, Flex, Icon } from '@lyfta/components-ui'

const bounce = keyframes`
  from {
    transform: translate3d(0, -10px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`

export const BouncyButton = styled(ButtonNew)`
  cursor: pointer;
  ${({ highlight }) => {
    if (highlight) {
      return css`
        animation: ${bounce} 0.5s;
        animation-direction: alternate;
        animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
        animation-iteration-count: infinite;
      `
    }
    return ''
  }}
`

export const CenteredTabs = styled(Flex)`
  flex-direction: column;
  gap: 16px;
`

export const NoPointerEventsContainer = styled(Flex)`
  height: 100%;
  position: fixed;
  z-index: 100;
  overflow: hidden;
  width: 100%;
  pointer-events: none;
`

export const LogoIcon = styled(Icon).attrs({
  px: '11px',
})`
  width: 100%;
  cursor: pointer;
  > svg > path {
    fill: ${themeGet('colors.neutral_100')};
  }
`

export const Menu = styled(Flex)`
  gap: 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 0;
`

export const SidebarsContainer = styled(Flex)`
  flex-basis: 100%;
  flex-direction: row;
  justify-content: space-between;
  * {
    pointer-events: auto;
  }
`

export const NavigationLeftSidebar = styled(Flex).attrs({
  pt: 4,
  pb: '12px',
})`
  background-color: ${themeGet('colors.sa_primary_90')};
  align-items: flex-start;
  flex-basis: 80px;
  flex-direction: column;
  justify-content: space-between;
  min-width: 80px;
`

export const OldStyleMenuItem = styled.div`
  cursor: pointer;
  height: 72px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
  text-decoration: none;
`

export const PrimaryButtonIcon = styled(Icon).attrs({
  height: '44px',
  width: '44px',
})``

export const SecondaryButtonIcon = styled(Icon).attrs({
  height: '32px',
  width: '32px',
})``

const Sidebar = styled(Flex).attrs({
  p: 6,
})`
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  padding-top: ${props => (props.isPreviewMode ? '115px' : '40px')};
`

export const LeftSidebar = styled(Sidebar)``

export const RightSidebar = styled(Sidebar)``

export const ZoomControls = styled(Flex)`
  gap: 16px;
  display: flex;
  flex-direction: column;
`

export const TitleModal = styled(Flex)`
  justify-content: center;
  font-size: 25px;
  letter-spacing: 1px;
  color: ${themeGet('colors.sa_primary_90')};
`
export const SubtitleModal = styled(Flex)`
  justify-content: center;
  font-size: 20px;
  letter-spacing: 0.5px;
  color: ${themeGet('colors.black_800')};
`
BouncyButton.displayName = 'Sidebar:BouncyButton'
CenteredTabs.displayName = 'NavigationSideBar:CenteredTabs'
NoPointerEventsContainer.displayName = 'Sidebars:NoPointerEventsContainer'
LeftSidebar.displayName = 'LeftSidebar'
LogoIcon.displayName = 'NavigationSideBar:LogoIcon'
Menu.displayName = 'Sidebar:Menu'
SidebarsContainer.displayName = 'Sidebars:SidebarsContainer'
NavigationLeftSidebar.displayName = 'NavigationLeftSidebar'
OldStyleMenuItem.displayName = 'Sidebar:OldStyleMenuItem'
PrimaryButtonIcon.displayName = 'Sidebar:PrimaryButtonIcon'
SecondaryButtonIcon.displayName = 'Sidebar:SecondaryButtonIcon'
RightSidebar.displayName = 'RightSidebar'
ZoomControls.displayName = 'ZoomControls'
