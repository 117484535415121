import { getLocale } from '@lyfta/components-data/src/Store/Selectors/persist'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { loadTagGroups } from '../../../../Store/Actions/tagGroups'
import { getTagGroups } from '../../../../Store/Selectors/tagGroups'
import Tags from './Tags'

const actions = {
  onLoadTagGroups: loadTagGroups,
}

const selectors = createStructuredSelector({
  tagGroups: getTagGroups,
  locale: getLocale,
})

export const TagContainer = connect(selectors, actions)(Tags)
