import { appUrls } from '@lyfta/components-config'
import {
  daysLeft,
  getTranslation,
  globalFormatDate,
} from '@lyfta/components-i18n'
import { BREAKPOINTS } from '@lyfta/components-theme'
import { breakpointsNumber } from '@lyfta/components-theme/src/themes/Main'
import { get } from 'lodash'
import { nanoid } from 'nanoid'
import React from 'react'
import ReactGA from 'react-ga4'
import { toast } from 'react-toastify'

import ToastNewComponent from '../Components/ToastNew'

export const capitalizeFirstLetter = string => {
  if (!string) return string

  return string[0].toUpperCase() + string.slice(1)
}
export const escapeRegExp = string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // Escape special characters
}

export const reactEvent = ({
  category = 'button',
  action,
  label = '',
  value = '',
  params = {},
}) => {
  const eventObj = {
    category,
    action,
    label,
    value,
    ...params,
  }

  ReactGA.event(action, eventObj)
}

export const getInitials = (fullName = '') => {
  const nameArray = fullName.trim().split(' ')
  const initialsArray = nameArray
    .filter(name => name)
    .map(name => name[0].toUpperCase())
    .slice(0, 2)
  return initialsArray.join('')
}

export const isMobile = () =>
  window.innerWidth < breakpointsNumber[BREAKPOINTS.MOBILE]

export const isTrialingOrFullTrialing = licenseType =>
  licenseType === 'full trial' || licenseType === 'trial'
export const isTrialing = licenseType => licenseType === 'trial'
export const isFullTrialing = licenseType => licenseType === 'full trial'
export const isSubscribed = licenseType => licenseType === 'subscribed'
export const isSupplementary = licenseType => licenseType === 'supplemantary'

export const openInStudentApp = (queryString, path = '') => e => {
  if (e.stopPropagation) e.stopPropagation()
  if (window.Cypress) {
    window.open(appUrls.student(window.location, queryString, path), '_self')
  } else {
    window.open(appUrls.student(window.location, queryString, path))
  }
}

let lastPopup = null
export const openStudentPopup = () => {
  lastPopup = window.open('about:blank', '_blank')
  lastPopup.blur()
  window.focus()
}

export const studentPopupSuccess = (queryString, path = '') => {
  if (!lastPopup) return
  lastPopup.location.href = appUrls.student(window.location, queryString, path)
  lastPopup.focus()
}

export const studentPopupFail = () => {
  if (!lastPopup) return
  lastPopup.close()
  lastPopup = null
}

export const openInStudentAppAlt = (queryString, path = '') => e => {
  if (e.stopPropagation) e.stopPropagation()
  const newTab = window.open()
  newTab.location.href = appUrls.student(window.location, queryString, path)
}

export const openBookWebinar = () => {
  window.open(appUrls.bookWebinar, '_blank')
}

export const openInTeacherApp = (queryString, path = '') => e => {
  if (e) e.stopPropagation()
  window.open(appUrls.teacher(window.location, queryString, path), '_self')
}

export const openTutorial = () => e => {
  if (e) e.stopPropagation()
  window.open(appUrls.tutorial)
}

export const signUpApp = redirect => e => {
  if (e) e.stopPropagation()
  reactEvent({
    action: 'clicked_create_an_account_CTA',
  })
  window.open(appUrls.signUp(redirect))
}

export const randomNumbers = () => {
  const array = new Uint32Array(1)
  return window.crypto.getRandomValues(array).toString()
}

export const findRole = (roles, role) => {
  if (!roles) return true
  let found = false
  Object.keys(roles).forEach(function (key) {
    if (roles[key] === role) found = true
  })
  return found
}

export const convertMinutesToHoursAndMinutes = totalMinutes => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  let formattedTime = ''

  if (hours > 0) {
    formattedTime += `${hours}hr `
  }

  formattedTime += `${minutes}min`

  return formattedTime
}

export const showNotification = (message, type, options) => {
  const defaultOptions = {
    autoClose: type === 'error' ? false : 3000,
    hideProgressBar: true,
    closeButton: false,
  }
  const newOptions = { ...defaultOptions, ...options }

  toast(<ToastNewComponent message={message} type={type} />, newOptions)
}
export const closeAllToast = () => {
  toast.dismiss()
}
/* eslint-disable */
export const printPDF = (id = 'pdfContainer') => {
  let cssStyles = ''

  // Started at index 1 for index 0 is browser's user agent stylesheet.
  for (let i = 1; i < document.styleSheets.length; i++) {
    let style = null

    try {
      if (document.styleSheets[i]) {
        const classes =
          document.styleSheets[i].cssRules || document.styleSheets[i].rules

        if (classes) style = classes
      }
      for (const item in style) {
        if (style[item].cssText != undefined) cssStyles += style[item].cssText
      }
    } catch (e) {
      continue
    }
  }

  const divContents = document.getElementById(id).innerHTML

  const printWindow = window.open('', '', 'height=400,width=800')
  printWindow.document.write('<html><head>')
  printWindow.document.write('<style>')
  printWindow.document.write('@page { margin: 40px; }') // AVOIDING HEADER AND FOOTER
  printWindow.document.write(cssStyles)

  printWindow.document.write('</style>')
  printWindow.document.write('</head><body >')
  printWindow.document.write(divContents)
  printWindow.document.write('</body></html>')
  printWindow.document.close()
  printWindow.print()
}
/* eslint-enable */

export default {}

// Formatted file size
export function formattedFileSize(value) {
  if (value == null || value === '') {
    return '0 Bytes'
  }
  const unitArr = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let index = 0
  const srcsize = parseFloat(value)
  index = Math.floor(Math.log(srcsize) / Math.log(1024))
  let size = srcsize / 1024 ** index
  size = size.toFixed(2) // Reserved decimal number
  return size + unitArr[index]
}

export const getStartEndLicenceString = (userRecord, options = {}) => {
  const startDate = get(userRecord, 'userLicensing.validityRangeStartAt')
  const endDate = get(userRecord, 'userLicensing.validityRangeFinishAt')
  const { onlyLicenceType, onlyExpriyDate } = options

  const dLeft = daysLeft(
    startDate,
    endDate,
    true,
    getTranslation('licence.daysLeft'),
  )
  let licenceText = capitalizeFirstLetter(get(userRecord, 'licenseType'))

  if (onlyLicenceType) return licenceText
  if (onlyExpriyDate) return globalFormatDate(endDate, 'P')

  licenceText += ` (${globalFormatDate(startDate, 'P')} - ${globalFormatDate(
    endDate,
    'P',
  )}) ${dLeft}`

  return licenceText
}

export const groupBy = (items, key, order) => {
  const orderedLists = {}

  const newItems = items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  )

  if (order.length > 0) {
    Object.entries(newItems)
      .sort((a, b) => {
        const aIndex = order.indexOf(a[0])
        const bIndex = order.indexOf(b[0])
        if (aIndex < bIndex) {
          return -1
        }
        if (aIndex > bIndex) {
          return 1
        }
        return 0
      })
      .forEach(([k, v]) => {
        orderedLists[k] = v
      })
    return orderedLists
  }
  return newItems
}

export const createAgeRangeLabel = ageRangeArray => {
  if (!ageRangeArray || ageRangeArray.length === 0) return ''

  const formatSingleAgeRange = name => {
    if (name === 'Ages 16+') return `${name}, `
    const parts = name.split(' ')
    if (parts.length === 2) {
      return `${parts[0]} years, `
    }
    return `${name}, `
  }

  const result =
    ageRangeArray.length > 1
      ? 'Multiple years, '
      : formatSingleAgeRange(ageRangeArray[0].name)

  return `${result}`
}

export const createStringFromArray = (arrayOfObjects, propertyName) => {
  if (!arrayOfObjects || arrayOfObjects.length === 0) return ''

  const allButLast = arrayOfObjects
    .slice(0, -1)
    .map(obj => obj[propertyName])
    .join(', ')

  const last = arrayOfObjects[arrayOfObjects.length - 1][propertyName]

  const result = arrayOfObjects.length > 1 ? `${allButLast} and ${last}` : last

  return result
}

// TODO: this function seem to have some issues.
// https://app.asana.com/0/1201176239385966/1206823082284043/f
// A new function was created. createStringFromArray
export const convertArrayToString = ({
  arr,
  objProp = 'value',
  conjunction = true,
  maxItems = 0,
}) => {
  let newArr = arr

  const getStringFromItem = item => {
    if (typeof item === 'object' && item !== null && objProp in item) {
      return String(item[objProp])
    }
    return String(item)
  }

  if (!Array.isArray(arr) || arr.length === 0) {
    return ''
  }

  if (maxItems) {
    newArr = arr.slice(0, maxItems)
  }

  if (newArr.length === 1) {
    return getStringFromItem(newArr[0])
  }

  if (!conjunction) {
    return newArr.map(getStringFromItem).join(', ')
  }

  const lastItem = newArr.pop()
  const resultString = newArr.map(getStringFromItem).join(', ')
  const lastValue = getStringFromItem(lastItem)
  return `${resultString} and ${lastValue}`
}

export const getFilteredItems = (fArray, filterGroup = false) => {
  const processItems = (group, groupDefault) => {
    return Object.entries(group.options)
      .filter(
        ([, value], index) =>
          value && groupDefault !== Object.keys(group.options)[index],
      )
      .map(([key]) => {
        if (group.source === 'api')
          return { id: nanoid(), type: group.name, value: group.options[key] }

        return { id: nanoid(), type: group.name, value: key }
      })
  }

  if (filterGroup) {
    return processItems(fArray[filterGroup], fArray[filterGroup].default)
  }

  return Object.values(fArray).flatMap(group =>
    processItems(group, group.default),
  )
}

export const handleKeyPress = ({ callback, key }) => e => {
  if (e.key === key) {
    return callback(e)
  }
  return null
}

export const filterAndSortContent = (
  data,
  filterOption,
  sortField,
  sortOrder = 'asc',
) => {
  if (!data[0] || data.length === 0) return ''

  // Filter content based on the filterOption. If filterOption is not provided, it will not filter the data.
  let filteredData = data
  if (filterOption) {
    const [filterField, filterValue] = Object.entries(filterOption)[0]
    filteredData = data.filter(item => item[filterField] === filterValue)
  }

  // Sort the filtered content based on the sortField and sortOrder.
  filteredData.sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return sortOrder === 'asc' ? -1 : 1
    }
    if (a[sortField] > b[sortField]) {
      return sortOrder === 'asc' ? 1 : -1
    }
    return 0
  })

  return filteredData
}
