import * as React from 'react'

function SvgPlus(props) {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13 2H11V11H2V13H11V22H13V13H22V11H13V2Z" fill="black" />
    </svg>
  )
}

export default SvgPlus
