import { createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import {
  BACK_HIDE,
  BACK_HIGHLIGHT,
  BACK_SHOW,
  BACK_TOGGLE,
} from '../../Actions/ui'

const initialState = {
  open: false,
  highlight: false,
}

const show = state => state.merge({ open: true })
const hide = state => state.merge({ open: false })
const toggle = state => state.update('open', open => !open)
const highlight = (state, { value }) => {
  return state.merge({ highlight: value })
}

const handlers = {
  [BACK_HIDE]: hide,
  [BACK_SHOW]: show,
  [BACK_TOGGLE]: toggle,
  [BACK_HIGHLIGHT]: highlight,
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
