import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getTask } from '../../../../../Store/Selectors/questTracker'
import { TabIcon } from './TabIcon'

const selector = createStructuredSelector({
  task: getTask,
})

const actions = {}

export default connect(selector, actions)(TabIcon)
