import { styled } from '@lyfta/components-theme'

import { Flex } from '../Flex'

export const Container = styled(Flex)`
  display: flex;
  margin-top: 4px;
  padding-top: 30px;
  width: 80%;
  height: 100%;
`
