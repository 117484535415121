import { styled, themeGet } from '@lyfta/components-theme'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'
import { Box, Flex } from '@lyfta/components-ui'

export const Container = styled(Flex).attrs(() => ({
  mb: 2,
}))`
  background: ${themeGet('colors.neutral_100')};
  border: 1px solid ${themeGet('colors.neutral_400')};
  border-radius: 8px;
  width: 100%;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  transition: all ${themeGet('transitionTime')} ease;
  &:hover {
    box-shadow: ${shadows.skim};
  }
`

export const Left = styled(Flex).attrs(() => ({
  py: 2,
  pl: 2,
}))`
  margin-right: 10px;
  width: 120px;
  flex-shrink: 0;
  align-items: center;

  img {
    border-radius: 4px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`
export const Right = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid ${themeGet('colors.bg')};
  padding-top: 10px;
  padding-bottom: 6px;
  padding-right: 12px;
  gap: 4px;
`

export const Title = styled(Box).attrs(() => ({
  fontSize: 3,
  mx: 2,
}))`
  color: ${themeGet('colors.black_700')};
  font-weight: 700;
  line-height: ${themeGet('lineHeights.5')};
  letter-spacing: 0.5px;
`
export const Description = styled(Box).attrs(() => ({
  fontSize: 3,
}))`
  color: ${themeGet('colors.black_700')};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 48px;
  line-height: ${themeGet('lineHeights.5')};
`
export const IconContainer = styled(Flex)`
  align-items: center;
  flex-shrink: 0;
  height: ${themeGet('lineHeights.5')};
`

export const TopRow = styled(Flex)`
  align-items: flex-start;
  flex-wrap: nowrap;
  justify-content: space-between;
`

export const TopRowLeftGroup = styled(Flex)`
  align-items: flex-start;
`
