import {
  Subtitle1,
  handleKeyPress,
  useConditionalCallback,
} from '@lyfta/components-ui'
import { indexOf } from 'lodash'
import PT from 'prop-types'
import React from 'react'

import { Checkbox as Check, Container, Label, Radio, Wrapper } from './styles'

const Checkbox = ({
  choice,
  choiceStyle,
  selections,
  value,
  onClick,
  disabled,
  ...props
}) => {
  const checked = indexOf(selections, value) >= 0
  const conditionalCallback = useConditionalCallback(disabled, onClick)

  return (
    <Container
      checked={checked}
      id={`div-multiple-choice-${choice.toLowerCase().replace(' ', '-')}`}
      tabIndex="0"
      onClick={conditionalCallback}
      onKeyPress={handleKeyPress({ callback: conditionalCallback, key: ' ' })}
      {...props}
    >
      <Wrapper>
        {choiceStyle === 'single' ? (
          <Radio checked={checked} disabled={disabled} />
        ) : (
          <Check checked={checked} disabled={disabled} />
        )}
        <Subtitle1>
          <Label source={choice} />
        </Subtitle1>
      </Wrapper>
    </Container>
  )
}
Checkbox.propTypes = {
  choice: PT.string.isRequired,
  choiceStyle: PT.string.isRequired,
  disabled: PT.bool,
  selections: PT.array.isRequired,
  value: PT.oneOfType([PT.number, PT.string]).isRequired,
  onClick: PT.func.isRequired,
}

Checkbox.defaultProps = {
  disabled: false,
}

export default Checkbox
