import * as React from 'react'

function SvgHide(props) {
  return (
    <svg
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M4.11816 2.88079C3.85193 2.61443 3.09509 2.49114 2.70459 2.88177C2.31409 3.27239 2.43762 4.02874 2.70386 4.29485L4.06714 5.65814C2.70984 6.77899 1.64148 8.27532 1 9.99969C2.41541 13.8044 5.90906 16.4997 10 16.4997C11.4249 16.4997 12.7773 16.1728 13.9948 15.5859L15.8804 17.4714C16.1466 17.7377 16.9041 17.862 17.2947 17.4714C17.6852 17.081 17.5609 16.3234 17.2947 16.0573L4.11816 2.88079ZM12.2858 13.8766L10.7834 12.3744C10.537 12.4557 10.2737 12.4997 10 12.4997C8.61926 12.4997 7.5 11.3803 7.5 9.99969C7.5 9.72601 7.54395 9.46258 7.62524 9.21624L6.12292 7.7138C5.72717 8.38372 5.5 9.16522 5.5 9.99969C5.5 12.485 7.51477 14.4997 10 14.4997C10.8345 14.4997 11.6158 14.2724 12.2858 13.8766Z"
        fillRule="evenodd"
      />
      <path d="M14.5 9.99969C14.5 10.1867 14.4886 10.371 14.4664 10.5522L17.1085 13.194C17.912 12.27 18.5575 11.1891 19 9.99969C17.5846 6.195 14.0909 3.49969 10 3.49969C9.20911 3.49969 8.44043 3.60052 7.70483 3.79046L9.44763 5.53314C9.62866 5.51116 9.81299 5.49969 10 5.49969C12.4852 5.49969 14.5 7.51434 14.5 9.99969Z" />
    </svg>
  )
}

export default SvgHide
