import { createSelector } from 'reselect'

export const getState = state => state.ui.rotation

export const getIsRotationEnabled = createSelector(
  getState,
  state => state.enabled,
)

export const getIsRotationActive = createSelector(
  getState,
  state => state.active,
)
