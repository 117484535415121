import * as React from 'react'

function SvgOther(props) {
  return (
    <svg height="1em" viewBox="0 0 30 28" width="1em" {...props}>
      <path
        d="M.645 19.292c4.71-.672 8.102.7 9.998 4.107 1.548 2.78 5.192 3.74 11.102 2.76.41-.068.8.202.87.602.07.4-.207.78-.617.848-6.475 1.074-10.758-.055-12.68-3.508-1.573-2.826-4.333-3.941-8.455-3.353a.751.751 0 01-.855-.621.739.739 0 01.637-.835zm18.61-13.289l6.311 4.42c.075.243.115.592.16.85.417 2.378-.667 4.7-2.601 6.067-.46.348-.979.675-1.56.992C18.277 20.13 8.277 20.1 8.277 20.1c4.222-2.638 3.842-5.947 4.667-8.827.535-2.432 4.6-4.697 5.304-4.936.65-.22.958-.318 1.001-.332l.006-.002zm1.393-2.654l6.885 4.822-1.367 1.484-6.477-4.535.959-1.77zM23.199.272a1 1 0 01.154.089l6.058 4.242a1 1 0 01.133 1.527L28.23 7.44 21.096 2.44l.776-1.68a1 1 0 011.327-.488z"
        fill="#D8D8D8"
      />
    </svg>
  )
}

export default SvgOther
