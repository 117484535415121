import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { ButtonNew } from '../ButtonNew'
import Modal from '../Modal'
import { ModalButtonWrapper, ModalContainer, ModalText } from './styles'

class DeleteModal extends PureComponent {
  render() {
    const {
      isOpen,
      i18nOptions,
      i18nText,
      count,
      onChooseNegative,
      onChoosePositive,
    } = this.props

    return (
      <Modal isOpen={isOpen} showFooter={false} onCallback={onChooseNegative}>
        <ModalContainer>
          <ModalText>
            <I18n i18nKey={i18nText} options={{ count, ...i18nOptions }} />
          </ModalText>
          <ModalButtonWrapper>
            <ButtonNew
              id="btn-modal-cancel"
              secondary
              onClick={onChooseNegative}
            >
              <I18n i18nKey="actions.no" />
            </ButtonNew>
            <ButtonNew id="btn-modal-confirm" onClick={onChoosePositive}>
              <I18n i18nKey="actions.yes" />
            </ButtonNew>
          </ModalButtonWrapper>
        </ModalContainer>
      </Modal>
    )
  }
}

DeleteModal.defaultProps = {
  i18nOptions: {},
}

DeleteModal.propTypes = {
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  i18nOptions: PropTypes.object,
  i18nText: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChooseNegative: PropTypes.func.isRequired,
  onChoosePositive: PropTypes.func.isRequired,
}

export default DeleteModal
