import { css, styled, themeGet } from '@lyfta/components-theme'
import TextareaAutosize from 'react-autosize-textarea'

import { Box } from '../../Box'

export const Label = styled(Box).attrs(() => ({
  as: 'label',
  fontSize: 4,
}))`
  position: absolute;
  // z-index: 3;
  top: 18px;
  left: 16px;
  font-weight: 600;
  transition: all ${themeGet('transitionTime')} ease;
  user-select: none;
  cursor: text;

  ${props =>
    props.active &&
    css`
      top: 6px;
      font-size: 13px;
      font-weight: normal;
      color: ${themeGet('colors.font.accent')};
    `};
`

export const TextArea = styled(TextareaAutosize)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  border: 0;
  outline: none;
  background: ${themeGet('colors.clear')};
  font-weight: 600;
  transition: all ${themeGet('transitionTime')} ease;
  padding: 30px 18px 8px;
  resize: none;
`

export const TextAreaNoResize = styled.textarea`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  border: 0;
  outline: none;
  background: ${themeGet('colors.clear')};
  font-weight: 600;
  transition: all ${themeGet('transitionTime')} ease;
  padding: 30px 18px 8px;
  resize: none;
`

export const Description = styled(Box).attrs(() => ({
  px: 3,
  fontSize: 2,
}))`
  top: 6px;
  font-weight: normal;
  color: ${themeGet('colors.dustyGray')};
  transition: all ${themeGet('transitionTime')} ease;
  user-select: none;

  ${props =>
    props.active &&
    css`
      color: ${themeGet('colors.font.accent')};
    `};
`

export const Container = styled(Box)`
  position: relative;
  background: ${themeGet('colors.alto')};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  ${props =>
    props.active &&
    css`
      border-bottom: 2px solid ${themeGet('colors.font.accent')};
    `};

  ${props =>
    props.error &&
    css`
      border-color: ${themeGet('colors.error')};
    `};
`

export const Wrapper = styled(Box).attrs(() => ({}))`
  width: 100%;
`
