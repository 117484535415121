import { css, styled, themeGet } from '@lyfta/components-theme'
import { NavLink } from 'react-router-dom'

import { AnimatedFlex, Box, Flex, Span } from '../../../Components'

const CONTENT_PY = '8px'

export const Container = styled(AnimatedFlex)`
  background-color: ${themeGet('colors.sidebarBackground')};
  border-right: 1px solid ${themeGet('colors.alto')};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  span {
    opacity: ${props => (props.open ? '1' : '0')};
    transition: opacity 0.15s;
  }

  @media (max-width: ${themeGet('breakpoints.0')}) {
    position: absolute;
    left: 0px;
    top: ${themeGet('sizes.header')};
    width: 100% !important;
    height: 0px;
    z-index: 999;
  }
`

// Reviewer: shall we use Absolute here, Box, Flex, or something else?
export const Content = styled(Box)`
  height: 100%;
  padding: ${CONTENT_PY} 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`
export const MenuBar = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`

export const Menu = styled(Flex)`
  width: 100%;
  flex-direction: column;

  & > * + * {
    margin-top: 20px;
  }
`
export const TopMenuBar = styled(Flex)`
  width: 100%;
  margin-top: 18px;
`
export const BottomMenuBar = styled(Flex)`
  color: white;
  align-items: center;
  cursor: pointer;

  margin-right: ${props => (props.isOpen === false ? '0' : '22px')};
  justify-content: ${props =>
    props.isOpen === false ? 'center' : 'flex-start'};
  margin-left: ${props => (props.isOpen === false ? '0' : '22px')};

  > div {
    height: 24px;
  }

  svg {
    fill: ${themeGet('colors.white')};
  }

  &:hover {
    span {
      color: ${themeGet('colors.sidebarAccent')} !important;
    }
    svg,
    svg > path {
      fill: ${themeGet('colors.sidebarAccent')} !important;
    }
  }
`
export const MenuButton = styled(Box)`
  height: 36px;
  display: flex;
  align-items: center;
  // 16px margin + 6px padding = 22px to the icon frame:
  padding-left: ${props => (props.isOpen === false ? '0' : '6px')};
  justify-content: ${props =>
    props.isOpen === false ? 'center;' : 'flex-start;'};
  flex-direction: row;
  position: relative;
  text-decoration: none;
  cursor: pointer;

  border-radius: 20px;
  flex: ${props => (props.isOpen === false ? '0 1 auto' : '1')};
  margin-left: ${props => (props.isOpen === false ? '0' : '16px')};
  margin-right: ${props => (props.isOpen === false ? '0' : '16px')};

  ${props =>
    props.isOpen === false &&
    css`
      width: 36px;

      > span {
        display: none;
      }
      > a {
        padding-left: 16px;
      }
    `}
  svg,
  svg > path {
    ${props =>
      props.disabled
        ? css`
            fill: ${themeGet('colors.darkGray')};
          `
        : css`
            fill: ${themeGet('colors.sidebarText')};
          `}
  }

  > div {
    align-items: center;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
  }

  &.active {
    padding-left: 0;
    > div {
      height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      border-radius: 50%;
    }
    cursor: default;
    svg,
    svg > path {
      fill: ${themeGet('colors.primary')} !important;
    }
    > span {
      margin-left: ${props => (props.isOpen === false ? '0' : '10px')};
    }
  }

  &:hover:not(.active),
  :focus-visible {
    background: ${themeGet('colors.grayHover')};
  }

  :focus-visible {
    outline: 1px solid white;
    outline-offset: 2px;
  }
`

export const MenuItem = styled(MenuButton).attrs(() => ({ as: NavLink }))`
  ${props =>
    props.isOpen === false &&
    css`
      justify-content: center;
    `}
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`
export const MenuItemBox = styled(Flex)`
  flex: ${props => (props.isOpen === false ? '0 1 auto' : '1')};

  ${props =>
    props.isOpen === false &&
    css`
      > div {
        justify-content: center;
      }
    `}
`

export const MenuItemWrapper = styled(Flex)`
  justify-content: center;

  ${props =>
    props.isOpen === false
      ? css`
          > span {
            display: none;
          }
        `
      : css``}
`
export const Label = styled(Span)`
  white-space: nowrap;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;

  margin-left: ${props => (props.isOpen === false ? '0' : '16px')};

  color: ${themeGet('colors.sidebarText')};
  ${props =>
    props.disabled &&
    css`
      color: ${themeGet('colors.darkDustyGrey')};
    `}
`

Container.displayName = 'Container'
Content.displayName = 'Content'
Menu.displayName = 'Menu'
MenuButton.displayName = 'MenuButton'
MenuItem.displayName = 'MenuItem'
Label.displayName = 'Label'
