import { createReducer } from '../../helpers'
import { createEntityFields, createReducerHandlers } from '../../utils'
import { LOOKUP_ORGANIZATION } from '../Actions/organizationLookups'

const initialState = {
  ...createEntityFields('organizationLookups'),
}

const handlers = {
  ...createReducerHandlers('organizationLookups', LOOKUP_ORGANIZATION, {
    mapToKey: 'organizationLookups',
    withReplace: true,
  }),
}

export default createReducer(initialState, handlers)
