import { createAsyncAction } from '../../helpers'
import apiCall from '../../services/Api'

export const LOOKUP_ORGANIZATION = createAsyncAction(
  'organizations/LOOKUP_ORGANIZATION',
)

export const lookupOrganization = ({ address }) =>
  apiCall({
    method: 'POST',
    endpoint: `/organizations/lookup`,
    types: LOOKUP_ORGANIZATION,
    query: {
      data: {
        type: 'organisations',
        attributes: {
          ...address,
        },
      },
    },
  })
