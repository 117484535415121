import { useRef } from 'react'

/**
 * @template T
 * @param {T} value
 * @returns {React.MutableRefObject<T>}
 */
export const useAutoRef = value => {
  const ref = useRef()
  ref.current = value
  return ref
}
