import { get } from 'lodash'

import { createAsyncAction } from '../../helpers'
import apiCall from '../../services/Api'

export const LOAD_LYFTA_CAMPAIGNS = createAsyncAction(
  'lyftaCampaigns/LOAD_LYFTA_CAMPAIGNS',
)
export const LOAD_LYFTA_CAMPAIGN = createAsyncAction(
  'lyftaCampaigns/LOAD_LYFTA_CAMPAIGN',
)
export const CREATE_LYFTA_CAMPAIGN = createAsyncAction('lyftaCampaigns/CREATE')
export const UPDATE_LYFTA_CAMPAIGN = createAsyncAction('lyftaCampaigns/UPDATE')
export const DELETE_LYFTA_CAMPAIGN = createAsyncAction('lyftaCampaigns/DELETE')

export const loadLyftaCampaigns = ({
  filter,
  page,
  sort = '-updatedAt',
  ...props
} = {}) =>
  apiCall({
    method: 'GET',
    endpoint: '/lyfta_campaigns',
    query: {
      page,
      filter,
      sort,
    },
    types: LOAD_LYFTA_CAMPAIGNS,
    paged: true,
    payload: { page, filter, sort, ...props },
  })

export const loadLyftaCampaign = params => {
  let id = params
  if (typeof params === 'object') {
    id = get(params, 'id')
  }

  return apiCall({
    method: 'GET',
    endpoint: `/lyfta_campaigns/${id}?include=photo`,
    types: LOAD_LYFTA_CAMPAIGN,
  })
}

export const createLyftaCampaign = ({
  campaignRecord,
  customMessage,
  redirect = true,
  photo,
}) => {
  return apiCall({
    customMessage,
    method: 'POST',
    endpoint: `/admin/lyfta_campaigns`,
    types: CREATE_LYFTA_CAMPAIGN,
    redirect,
    photo,
    query: {
      data: {
        type: 'lyfta_campaigns',
        attributes: {
          ...campaignRecord,
        },
      },
    },
    entityName: 'lyftaCampaigns',
  })
}

export const updateLyftaCampaign = ({
  campaignRecord,
  customMessage,
  photo,
}) => {
  const recordCopy = { ...campaignRecord }
  let relationships
  if (recordCopy.relationships) {
    relationships = recordCopy.relationships
    delete recordCopy.relationships
  }
  return apiCall({
    customMessage,
    method: 'PATCH',
    endpoint: `/admin/lyfta_campaigns/${recordCopy.id}?include=photo`,
    types: UPDATE_LYFTA_CAMPAIGN,
    photo,
    query: {
      data: {
        type: 'lyfta_campaigns',
        attributes: {
          ...recordCopy,
        },
        relationships,
      },
    },
    entityName: 'lyftaCampaigns',
  })
}

export const deleteLyftaCampaign = id =>
  apiCall({
    method: 'DELETE',
    endpoint: `/admin/lyfta_campaigns/${id}?include=photo`,
    types: DELETE_LYFTA_CAMPAIGN,
    payload: {
      deletedId: id,
    },
    entityName: 'lyftaCampaigns',
  })
