import { filter, kebabCase, map, memoize, omit, reduce, values } from 'lodash'
import { compile, parse } from 'path-to-regexp'
import pluralize from 'pluralize'

const navigateToUrl = url => {
  // eslint-disable-next-line no-console
  console.warn(`NAVIGATE TO URL: ${url} NOT YET IMPLEMENTED`)
}

const generateSystemPath = memoize(
  (types, withId = false, edit = false, custom = null, options) => {
    let generatedPath
    let customPath = custom
    let rootPath = ''
    const { avoidKebabCase } = options || ''
    if (custom && typeof custom === 'object') {
      rootPath = custom.prefix
      customPath = custom.suffix
    }

    if (Array.isArray(types)) {
      generatedPath = map(types, (t, index) => {
        const singularKey = pluralize.singular(
          typeof t === 'string' ? t : t.key,
        )
        const pathSegment = typeof t === 'string' ? t : t.name

        if (index === types.length - 1) {
          if (avoidKebabCase) return pathSegment
          return `${kebabCase(pathSegment)}`
        }

        return `${kebabCase(pathSegment)}/:${singularKey}Id`
      }).join('/')
    } else {
      generatedPath = `${kebabCase(types)}`
    }
    generatedPath = `${rootPath}${generatedPath}${withId ? '/:id' : ''}`

    let defaultPath = `/${edit ? `${generatedPath}/edit` : generatedPath}`

    if (customPath) {
      defaultPath = `${defaultPath}/${customPath}`
    }

    const newPath = `/${generatedPath.replace(':id', 'new')}`

    return (id = undefined, params = undefined) => {
      const isNew = id === 'new'

      const routeParams = params || {}
      const path = isNew ? newPath : defaultPath

      if (!id && isNew && Object.keys(routeParams).length === 0) {
        return path
      }

      const tokens = parse(path)
      const keys = getRouteKeys(tokens)
      const paramsToPass = convertFirstParamToPassedId(keys, routeParams, id)

      const toPath = compile(path)

      return toPath(paramsToPass, { validate: false })
    }
  },
  (...args) =>
    values(args)
      .map(arg => {
        return typeof arg === 'object' ? values(arg) : arg
      })
      .join('_'),
)

export const convertFirstParamToPassedId = (keys, routeParams, id) => ({
  id: id || ':id',
  ...omit(
    reduce(
      keys,
      (result, value) => {
        // eslint-disable-next-line no-param-reassign
        result[value] =
          routeParams[value] ||
          (value.endsWith('Id') && routeParams?.id) ||
          `:${value}`
        return result
      },
      {},
    ),
    ['id'],
  ),
})

const getRouteKeys = tokens =>
  map(
    filter(tokens, t => typeof t !== 'string'),
    token => token.name,
  )

export { navigateToUrl, generateSystemPath }
