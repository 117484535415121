import PropTypes from 'prop-types'
import React from 'react'
import { Translation } from 'react-i18next'

import { getTranslation } from '../services/translation/getTranslation'
import { makeSentenceCase } from '../services/utils'
import { DefaultTag } from './defaultTag'

export const I18n = ({
  as,
  i18nKey,
  options,
  sentenceCase,
  tag,
  text,
  title,
  ...restProps
}) => {
  const Tag = as || tag
  /* eslint-disable */

  const displayTitle =
    title === true
      ? getTranslation(i18nKey || text, options)
      : typeof title === 'string'
      ? title
      : ''
  /* eslint-enable */

  return (
    <Translation>
      {t => (
        <Tag
          {...restProps}
          title={displayTitle && displayTitle !== '' ? displayTitle : undefined}
        >
          {sentenceCase
            ? makeSentenceCase(t(i18nKey || text, options))
            : t(i18nKey || text, options)}
        </Tag>
      )}
    </Translation>
  )
}

I18n.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
    PropTypes.node,
  ]),
  i18nKey: PropTypes.string,
  options: PropTypes.object,
  sentenceCase: PropTypes.bool,
  tag: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
    PropTypes.node,
  ]),
  text: PropTypes.string,
  title: PropTypes.string,
}
I18n.defaultProps = {
  as: null,
  i18nKey: null,
  options: {},
  sentenceCase: false,
  tag: DefaultTag,
  text: null,
  title: '',
}
