import { DateTime, DateTimeLocal } from '@lyfta/components-i18n'
import { map, range } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

import { PropTypes } from '../../Helpers/prop-types'
import {
  CalendarGrid,
  Container,
  ControlsContainer,
  DateCell,
  DayCell,
  MonthAndYear,
  MoveControls,
  NextIcon,
  PrevIcon,
  RowContainer,
} from './styles'

/* eslint-disable max-lines-per-function */
const BookingCalendar = ({ eventsAvailable, onMonthChange, onSelect }) => {
  const [selectedDate, setSelectedDate] = useState(null)

  const [selectedMonth, setSelectedMonth] = useState(
    DateTimeLocal.startOf('month'),
  )

  useEffect(() => {
    if (onMonthChange) onMonthChange(selectedMonth)
  }, [])

  const [endOfMonth, setEndOfMonth] = useState()

  const monday = DateTimeLocal.set({ weekday: 1 })

  useEffect(() => {
    setEndOfMonth(selectedMonth.endOf('month').day)
    setSelectedDate(null)
  }, [selectedMonth])

  const moveMonth = useCallback(
    number => () => setSelectedMonth(selectedMonth.plus({ months: number })),
    [selectedMonth],
  )
  const selectDate = date => () => {
    setSelectedDate(date)
  }

  const activeDates = map(eventsAvailable, e =>
    DateTime.fromISO(e.startDate).startOf('day').toFormat('yyyy-MM-dd'),
  )

  useEffect(() => {
    if (onMonthChange) onMonthChange(selectedMonth)
  }, [selectedMonth])
  useEffect(() => {
    if (onSelect) onSelect(selectedDate)
  }, [selectedDate])

  if (!selectedMonth) return null

  return (
    <Container>
      <RowContainer>
        <MonthAndYear>{selectedMonth.toFormat('LLLL y')}</MonthAndYear>
        <ControlsContainer>
          <MoveControls onClick={moveMonth(-1)}>
            <PrevIcon />
          </MoveControls>
          <MoveControls onClick={moveMonth(1)}>
            <NextIcon />
          </MoveControls>
        </ControlsContainer>
      </RowContainer>

      <CalendarGrid>
        {map(range(0, 7), i => (
          <DayCell key={`day-${i}`}>
            {monday.plus({ days: i }).toFormat('ccc')}
          </DayCell>
        ))}
      </CalendarGrid>
      <CalendarGrid>
        {map(range(0, endOfMonth), i => {
          const dt = selectedMonth.plus({ days: i })
          const active = activeDates.includes(
            dt.startOf('day').toFormat('yyyy-MM-dd'),
          )

          return (
            <DateCell
              active={active}
              gridColumn={i === 0 && selectedMonth.weekday}
              key={dt.toFormat('yyyy-MM-dd')}
              selected={
                selectedDate &&
                dt.diff(selectedDate, 'days').toObject().days === 0
              }
              onClick={active && selectDate(dt)}
            >
              <div>{dt.toFormat('dd')}</div>
            </DateCell>
          )
        })}
      </CalendarGrid>
    </Container>
  )
}

BookingCalendar.defaultProps = {
  eventsAvailable: [],
  onMonthChange: null,
  onSelect: null,
}
BookingCalendar.propTypes = {
  eventsAvailable: PropTypes.array,
  onMonthChange: PropTypes.func,
  onSelect: PropTypes.func,
}

export default BookingCalendar
