import { useEffect, useState } from 'react'

import { getCachedHead } from './getCachedHead'

export const useLocaleAwareArticleUrl = (url, locale) => {
  const [wantedUrl, setWantedUrl] = useState('')

  const translatedContentUrl = url.replace(
    /([a-z][a-z]\.html)/,
    `${locale}.html`,
  )

  useEffect(() => {
    if (translatedContentUrl === url) {
      setWantedUrl(url)
      return undefined
    }

    setWantedUrl('')
    const abortController = new AbortController()

    ;(async () => {
      try {
        const result = await getCachedHead(
          translatedContentUrl,
          false,
          abortController.signal,
        )

        if (result.status === 200) {
          setWantedUrl(translatedContentUrl)
        } else {
          setWantedUrl(url)
        }
      } catch (e) {
        setWantedUrl(url)
      }
    })()

    return () => {
      abortController.abort()
    }
  }, [locale, url, translatedContentUrl])

  return wantedUrl
}
