import { css, styled, themeGet } from '@lyfta/components-theme'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'
import TextareaAutosize from 'react-autosize-textarea'

import { ErrorSvg } from '../../../Assets/Icons'
import { Box } from '../../Box'

export const LabelAndTextareaContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Label = styled(Box).attrs(() => ({
  as: 'label',
}))`
  color: ${themeGet('colors.black_700')};
  cursor: text;
  font-size: 16px; // Body 1
  font-weight: 400; // Body 1
  left: 16px;
  letter-spacing: 0.5px; // Body 1
  line-height: 24px; // Body 1
  position: absolute;
  top: 18px;
  transition: all ${themeGet('transitionTime')} ease;
  user-select: none;

  ${props =>
    props.active &&
    css`
      color: ${themeGet('colors.primary_130')};
      font-size: 13px; // Body 5
      line-height: 20px; // Body 5
      top: 6px;
    `};
`

export const TextArea = styled(TextareaAutosize)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  border: 0;
  outline: none;
  background-color: ${themeGet('colors.clear')};
  color: ${themeGet('colors.black_900')};
  transition: all ${themeGet('transitionTime')} ease;
  padding: ${props => (props.label ? '30px 16px 8px' : '8px 16px')};
  resize: none;

  font-size: 16px; // Body 1
  font-weight: 400; // Body 1
  letter-spacing: 0.5px; // Body 1
  line-height: 24px; // Body 1

  ${props =>
    props.disabled &&
    css`
      color: ${themeGet('colors.black_700')};
    `}
`

export const TextAreaNoResize = styled.textarea`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  border: 0;
  outline: none;
  background-color: ${themeGet('colors.clear')};
  color: ${themeGet('colors.black_900')};
  transition: all ${themeGet('transitionTime')} ease;

  padding: 30px 16px 8px;
  resize: none;

  font-size: 16px; // Body 1
  font-weight: 400; // Body 1
  letter-spacing: 0.5px; // Body 1
  line-height: 24px; // Body 1

  ${props =>
    props.disabled &&
    css`
      color: ${themeGet('colors.black_700')};
    `}
`

export const Description = styled(Box)`
  color: ${props =>
    props.focused
      ? themeGet('colors.primary_130')
      : themeGet('colors.black_700')};
  font-size: 16px; // Body 1
  font-weight: 400; // Body 1
  letter-spacing: 0.5px; // Body 1
  line-height: 24px; // Body 1
  padding-top: 4px;
  transition: all ${themeGet('transitionTime')} ease;
`

export const Container = styled(Box)`
  align-items: center;
  background-color: ${themeGet('colors.neutral_100')};
  border: 1px solid ${themeGet('colors.black_600')};
  border-radius: 5px;
  cursor: text;
  display: flex;
  justify-content: space-between;
  position: relative;

  ${props => (props.error ? `padding-right: 18px;` : `padding-right: 16px;`)}

  ${props =>
    props.focused &&
    !props.disabled &&
    css`
      border-bottom: 2px solid ${themeGet('colors.primary_100')};
    `}

  ${props =>
    props.disabled &&
    css`
      background-color: ${themeGet('colors.black_200')};
      border: none;
    `}

  ${props =>
    props.error &&
    css`
      border-bottom: 2px solid ${themeGet('colors.error_100')};
    `};

    ${props =>
      props.error || props.focused
        ? `margin-bottom: 0px;`
        : `margin-bottom: 1px;`}

  &:focus-within {
    ${props =>
      !props.error &&
      !props.disabled &&
      css`
        border-bottom: 2px solid ${themeGet('colors.primary_100')};
      `}
  }

  &:hover {
    box-shadow: ${props => !props.disabled && shadows.skim}
  }
`

export const ErrorIcon = styled(ErrorSvg)`
  flex-shrink: 0;

  > path {
    fill: ${themeGet('colors.error_100')};
  }
`

export const Wrapper = styled(Box).attrs(() => ({}))`
  width: 100%;
`
