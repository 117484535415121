import { createAsyncAction } from '../../../helpers'
import {
  generateCreateUser,
  generateDeleteUser,
  generateLoadUserAction,
  generateLoadUsersAction,
  generateUpdateUser,
} from './helpers'

export const LOAD_STUDENT_USERS = createAsyncAction('users/LOAD_STUDENTS')
export const LOAD_STUDENT_USER = createAsyncAction('users/LOAD_STUDENT')
export const CREATE_STUDENT_USER = createAsyncAction(
  'users/CREATE_STUDENT_USER',
)
export const UPDATE_STUDENT_USER = createAsyncAction(
  'users/UPDATE_STUDENT_USER',
)
export const DELETE_STUDENT_USER = createAsyncAction(
  'users/DELETE_STUDENT_USER',
)
export const loadStudentUsers = generateLoadUsersAction(
  LOAD_STUDENT_USERS,
  'student',
  '/users',
  'klasses',
)
export const loadStudent = generateLoadUserAction(LOAD_STUDENT_USER)
export const createStudent = ({ customMessage = null } = {}) => {
  return generateCreateUser({
    types: CREATE_STUDENT_USER,
    customMessage,
    entityName: 'students',
  })
}
export const updateStudent = ({ customMessage = null } = {}) => {
  return generateUpdateUser({
    types: UPDATE_STUDENT_USER,
    endpoint: '/users',
    entityName: 'students',
    customMessage,
  })
}
export const deleteStudent = ({ customMessage = null } = {}) =>
  generateDeleteUser({
    types: DELETE_STUDENT_USER,
    endpoint: '/users',
    customMessage,
    deleteType: 'soft',
    updateData: {
      modelName: 'organizations',
    },
    entityName: 'students',
  })
