import { googleAuthClientId } from '@lyfta/components-config'
import { logOut } from '@lyfta/components-data/src/Store/Actions/viewer'
import { googleLogout } from '@react-oauth/google'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'

const LogoutButton = ({ children, redirectPath }) => {
  const dispatch = useDispatch()

  const performLogout = () => {
    if (googleAuthClientId) googleLogout()
    dispatch(logOut(true, redirectPath))
  }

  return <>{children(performLogout)}</>
}

LogoutButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  redirectPath: PropTypes.string,
}

LogoutButton.defaultProps = {
  redirectPath: undefined,
}

export default LogoutButton
