import { I18n } from '@lyfta/components-i18n'
import { AuthLayout, Locale, SignInScreen, Title } from '@lyfta/components-ui'
import paths from 'Constants/paths'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import { Link, LocaleWrapper } from './styles'

class LoginWithPassword extends PureComponent {
  componentDidMount() {
    document.title = 'Lyfta student - Log in'
  }

  render() {
    const { onClearErrors } = this.props
    return (
      <AuthLayout>
        <Title style={{ marginLeft: '15px' }}>
          <I18n text="auth.loginUsernameEmail" />
        </Title>
        <LocaleWrapper>
          <Locale fill="colors.black_900" />
        </LocaleWrapper>

        <SignInScreen
          allowUsername
          backgroundColorLogin="secondary_100"
          customLink={
            <Link
              color="colors.teal_100"
              exact
              href={paths.loginToLesson}
              to={() => paths.loginToLesson}
              onClick={onClearErrors}
            >
              <I18n text="auth.loginToLesson" />
            </Link>
          }
          fontColorLink="teal_100"
          paths={paths}
          redirectPath={paths.root}
          withLinks
          // withGoogleLogin
          // signUp
        />
      </AuthLayout>
    )
  }
}

LoginWithPassword.propTypes = {
  onClearErrors: PropTypes.func.isRequired,
}

export default LoginWithPassword
