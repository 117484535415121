import {
  api,
  appBranch,
  appDeployment,
  appEnv,
  appIdentifier,
  version,
} from '@lyfta/components-config'
import { getCurrentLanguage } from '@lyfta/components-i18n'
import normalize from 'json-api-normalizer'
import {
  camelCase,
  endsWith,
  get,
  has,
  isEmpty,
  map,
  mapKeys,
  merge,
  pickBy,
} from 'lodash'
import pluralize from 'pluralize'
import qs from 'qs'
import Request from 'superagent'

import { relationshipTypeMap } from '../constants'
import {
  FAILURE_MESSAGE_ACTION,
  SUCCESS_MESSAGE_ACTION,
} from '../Store/Actions/messages'
import { getAuthorizationHeader } from '../Store/Selectors/viewer'

// import { FORM_ERROR } from 'final-form'

const sendMethod = HTTPMethod =>
  HTTPMethod === 'post' ||
  HTTPMethod === 'put' ||
  HTTPMethod === 'patch' ||
  HTTPMethod === 'delete'
    ? 'send'
    : 'query'

const sendArguments = (HTTPMethod, query) =>
  HTTPMethod === 'post' ||
  HTTPMethod === 'put' ||
  HTTPMethod === 'patch' ||
  HTTPMethod === 'delete'
    ? JSON.stringify(query)
    : qs.stringify(query, { arrayFormat: 'brackets' })

const defaultOptions = {
  url: api.url,
  endpoint: '',
  method: 'GET',
  query: {},
  headers: {},
  file: null,
  fileFieldName: 'content',
  fileParams: null,
  needsNormalization: true,
  withoutAuthorization: false,
  types: null,
  paged: false,
  onProgress: null,
}

const absoluteUrl = new RegExp('^(?:[a-z]+:)?//', 'i')

const markProgress = (dispatch, types, payload, onProgress) => event => {
  if (onProgress) {
    onProgress(event)
  }
  if (event.direction === 'upload') {
    if (has(types, 'PROGRESS') && has(event, 'percent')) {
      dispatch({
        type: types.PROGRESS,
        percent: event.percent,
        payload,
      })
    }
  }
}

const normalizeBody = (options, body) => {
  const { needsNormalization, endpoint } = options

  return needsNormalization
    ? normalize(body, {
        endpoint,
        camelizeKeys: true,
      })
    : body
}

const processError = (error, data, options, dispatch, resolve) => {
  const { payload, types, meta, handleFailure, customCallback } = options

  const failureData = {
    ok: false,
    meta,
    payload,
    error,
    data,
    statusCode: data?.statusCode,
  }

  if (customCallback) {
    customCallback(failureData)
  }

  if (has(types, 'FAILURE')) {
    dispatch({ type: types.FAILURE, ...failureData })
    if (handleFailure) handleFailure(data)
  }

  dispatch({ type: FAILURE_MESSAGE_ACTION, options, failureData })

  resolve(failureData)
}

const processPaged = (normalized, endpoint) => {
  let params = get(normalized, `meta.${endpoint}.meta`)

  params = mapKeys(params, (value, key) => camelCase(key))

  const records = map(get(normalized, `meta.${endpoint}.data`), 'id')

  return {
    ...params,
    records,
  }
}

const processSuccess = (data, options, dispatch, resolve) => {
  const {
    needsNormalization,
    endpoint,
    meta,
    payload,
    types,
    paged,
    customCallback,
  } = options
  const { statusCode } = data

  const body = get(data, 'body')

  const normalized = normalizeBody(options, body)

  const successData = {
    ok: true,
    meta,
    isRaw: !needsNormalization,
    options,
    payload: { ...payload, data: normalized },
    statusCode,
  }

  if (customCallback) {
    customCallback(successData)
  }

  if (paged) {
    successData.paged = processPaged(normalized, endpoint)
  }

  if (has(types, 'SUCCESS')) {
    dispatch({ type: types.SUCCESS, ...successData })
  }

  dispatch({
    type: SUCCESS_MESSAGE_ACTION,
    options,
    successData,
  })

  resolve(successData)
}

const buildRequest = (options, dispatch) => {
  const {
    endpoint,
    method,
    query,
    file,
    fileFieldName,
    fileParams,
    url,
    types,
    payload,
    preventAutoRelationships = false,
  } = options

  if (endpoint.endsWith('undefined') || endpoint.endsWith('new')) {
    return null
  }

  const HTTPMethod = method.toLowerCase()
  const fullUrl = absoluteUrl.test(endpoint) ? endpoint : url + endpoint

  const request = Request[HTTPMethod](fullUrl)

  if (['PATCH', 'POST'].includes(method) && !preventAutoRelationships) {
    const { data } = query

    if (data && data.attributes) {
      const parsedRelationships = pickBy(
        data.attributes,
        (attr, key) => endsWith(key, 'Id') || endsWith(key, 'Ids'),
      )
      query.data.attributes = pickBy(
        data.attributes,
        (attr, key) =>
          !endsWith(key.toLowerCase(), 'id') && !endsWith(key, 'Ids'),
      )

      map(Object.keys(parsedRelationships), k => {
        const typeName = k.replace('Id', '')
        const relationshipType = pluralize(
          relationshipTypeMap[typeName] || typeName,
        )
        if (Array.isArray(parsedRelationships[k])) {
          const newRelationships = map(parsedRelationships[k], v => {
            if (!v) return null
            return {
              type: relationshipType,
              id: v,
            }
          })

          if (newRelationships.length > 0) {
            parsedRelationships[pluralize(typeName)] = {
              data: newRelationships,
            }
          } else {
            query.data.attributes[`${typeName}Pks`] = null
          }
        } else if (parsedRelationships[k]) {
          parsedRelationships[typeName] = {
            data: {
              type: relationshipType,
              id: parsedRelationships[k],
            },
          }
        } else {
          query.data.attributes[k] = null
        }

        delete parsedRelationships[k]
      })

      query.data.relationships = merge(
        query.data.relationships || {},
        parsedRelationships,
      )

      if (Object.keys(query.data.relationships).length === 0) {
        delete query.relationships
      }
    }
  }

  if (file) {
    if (HTTPMethod === 'post') {
      request.attach(fileFieldName, file)
      request.query(fileParams)
      request.field(query)
    } else {
      request.serialize(() => file)
    }
  } else {
    request[sendMethod(HTTPMethod)](sendArguments(HTTPMethod, query))
  }

  if (has(types, 'REQUEST')) {
    dispatch({
      type: types.REQUEST,
      payload,
      request,
    })
  }

  return request
}

export default options => (dispatch, getState) => {
  if (!dispatch) {
    return options
  }

  let mergedOptions = merge({}, defaultOptions, options, {})
  const {
    file,
    endpoint,
    headers,
    payload,
    withoutAuthorization,
    types,
    onProgress,
  } = mergedOptions

  mergedOptions = merge(mergedOptions, {
    meta: {
      endpoint,
    },
  })

  const authHeader = getAuthorizationHeader(getState())

  if (authHeader && !withoutAuthorization) {
    headers.authorization = authHeader
  }

  headers['X-LYFTA-ENVIRONMENT'] = appEnv
  headers['X-LYFTA-APPLICATION'] = appIdentifier
  headers['X-LYFTA-DEPLOYMENT'] = appDeployment
  headers['X-LYFTA-DEPLOYMENT-BRANCH'] = appBranch
  headers['X-LYFTA-APPLICATION-VERSION'] = version
  headers['X-LYFTA-USER-LOCALE'] = getCurrentLanguage()

  const request = buildRequest(mergedOptions, dispatch)

  if (!request) {
    return null
  }

  return new Promise(resolve => {
    request
      .set({
        ...(!file && { 'Content-Type': 'application/vnd.api+json' }),
        ...headers,
      })
      .on('progress', markProgress(dispatch, types, payload, onProgress))
      .end((error, data) => {
        if (isEmpty(data) || data.body === null) {
          merge(data, { body: { data: [] } })
        }
        if (error) {
          processError(error, data, mergedOptions, dispatch, resolve)
        } else {
          processSuccess(data, mergedOptions, dispatch, resolve)
        }
      })
  })
}
