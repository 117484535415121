import { get, without } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const useSelections = (tableData, disableRow) => {
  const [selected, setSelected] = useState([])
  const [allSelected, setAllSelected] = useState(false)
  const [disabledRows, setDisabledRows] = useState([])
  const selectAll = useCallback(() => {
    if (allSelected) {
      setSelected([])
    } else {
      const selectedArray = []
      const disabledArray = []
      tableData.forEach(t => {
        if (disableRow) {
          const matchDisabled = Object.keys(disableRow).find(item => {
            return disableRow[item] === t[item]
          })

          if (!matchDisabled) {
            selectedArray.push(t.id)
          } else {
            disabledArray.push(t.id)
          }
        }
      })
      setSelected(selectedArray)
      setDisabledRows(disabledArray)
    }
  }, [tableData, allSelected])
  useEffect(() => {
    setAllSelected(
      selected.length > 0
        ? selected.length === tableData.length - disabledRows.length
        : false,
    )
  }, [selected.length, disabledRows])

  const selectRow = el => e => {
    const id = get(el, 'row._original.id')
    let nextSelected = selected

    if (selected.includes(id)) {
      nextSelected = without(nextSelected, id)
    } else {
      nextSelected = [...nextSelected, id]
    }

    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

    setSelected(nextSelected)
  }

  return useMemo(
    () => ({
      selected,
      allSelected,
      selectRow,
      selectAll,
      setSelected,
      setAllSelected,
    }),
    [selected, allSelected, selectRow, selectAll, setSelected, setAllSelected],
  )
}
