import { createAsyncAction } from '../../helpers'
import apiCall from '../../services/Api'

export const LOAD_ORGANIZATION = createAsyncAction(
  'organizations/LOAD_ORGANIZATION',
)
export const UPDATE_ORGANIZATION = createAsyncAction(
  'organizations/UPDATE_ORGANIZATION',
)
export const CREATE_ORGANIZATION = createAsyncAction(
  'organizations/CREATE_ORGANIZATION',
)
export const LOAD_ORGANIZATIONS = createAsyncAction(
  'organizations/LOAD_ORGANIZATIONS',
)
export const DELETE_ORGANIZATION = createAsyncAction(
  'organizations/DELETE_ORGANIZATION',
)

export const clearCurrent = () => ({ type: LOAD_ORGANIZATION.CLEAR })

export const loadOrganization = id =>
  apiCall({
    method: 'GET',
    endpoint: `/organizations/${id}`,
    query: {
      include:
        'curriculums,licensings,licensings.license,licensings.license.suggestedMeeting',
    },
    types: LOAD_ORGANIZATION,
    payload: { id },
  })

export const updateOrganization = (attributes, relationships) =>
  apiCall({
    method: 'PATCH',
    endpoint: `/organizations/${attributes.id}?include=curriculums,licensings,licensings.license,licensings.license.suggestedMeeting`,
    query: {
      data: {
        type: 'organizations',
        attributes,
        relationships,
      },
    },
    types: UPDATE_ORGANIZATION,
  })

export const createOrganization = (attributes, relationships) =>
  apiCall({
    method: 'POST',
    endpoint: '/organizations',
    query: {
      data: {
        type: 'organizations',
        attributes,
        relationships,
      },
    },
    types: CREATE_ORGANIZATION,
  })

export const loadOrganizations = ({
  filter,
  page,
  include = 'licensings',
  sort = 'name',
  ...props
} = {}) =>
  apiCall({
    method: 'GET',
    endpoint: '/organizations',
    query: {
      include,
      page,
      filter,
      sort,
    },
    types: LOAD_ORGANIZATIONS,
    paged: true,
    payload: { page, filter, include, sort, ...props },
  })

export const signUpOrganization = attributes =>
  apiCall({
    method: 'POST',
    preventAutoRelationships: true,
    endpoint:
      '/organizations/sign_up?include=licensings,licensings.license,licensings.license.suggestedMeeting',
    query: {
      data: {
        type: 'organizations',
        attributes,
      },
    },
    types: CREATE_ORGANIZATION,
  })

export const deleteOrganization = id =>
  apiCall({
    method: 'DELETE',
    endpoint: `/organizations/${id}`,
    query: {},
    types: DELETE_ORGANIZATION,
  })
