import { Api as apiCall, createAsyncAction } from '@lyfta/components-data'

export const LOAD_LESSON_PLAN = createAsyncAction(
  'lesson_plans/LOAD_LESSON_PLAN',
)

export const loadLessonPlan = id =>
  apiCall({
    method: 'GET',
    endpoint: `/lesson_plans/${id}`,
    query: {
      include:
        'content,user,activities.tasks,activities.tasks.activity,activities.tasks.content',
    },
    types: LOAD_LESSON_PLAN,
  })
