import React from 'react'

function SvgMascotHover(props) {
  return (
    <svg
      width="252"
      height="270"
      viewBox="0 0 252 270"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.7099 75.0668C61.1971 77.8359 68.1825 86.6344 68.1825 96.9861V141.155C68.1825 153.707 57.9103 163.979 45.3578 163.979H25.0132C12.4607 163.979 2.18848 153.71 2.18848 141.155V96.9861C2.18848 84.8453 11.7937 74.8428 23.7804 74.1951L25.4153 70.8962C34.9868 51.5871 50.0677 35.3552 68.6015 24.3655C66.7739 18.5094 69.5478 12.3379 75.5652 9.33036C80.7423 6.74184 88.0094 4.83957 93.6367 3.54893C102.729 1.46366 112.25 0.144118 121.581 0.0140898C122.999 -0.00517366 124.417 0.00205012 125.836 0.0429849C127.254 0.00445805 128.672 -0.00517366 130.09 0.0140898C139.421 0.144118 148.942 1.46366 158.034 3.54893C163.659 4.83957 170.926 6.74184 176.103 9.33036C182.121 12.3403 184.895 18.5094 183.067 24.3655C201.601 35.3552 216.682 51.5871 226.253 70.8962L227.888 74.1951C239.875 74.8428 249.478 84.8453 249.478 96.9861V141.155C249.478 153.707 239.206 163.979 226.653 163.979H206.308C193.756 163.979 183.484 153.71 183.484 141.155V96.9861C183.484 86.6344 190.467 77.8359 199.956 75.0668C191.74 62.8321 180.616 52.8368 167.531 45.9766L162.677 43.4314L162.166 43.3833L161.112 42.933C149.956 38.1749 137.959 35.7525 125.833 35.7525C113.707 35.7525 101.711 38.1749 90.5546 42.933L89.4999 43.3833L88.9894 43.4314L84.135 45.9766C71.0504 52.8392 59.9282 62.8321 51.7099 75.0668Z"
        fill="#050B15"
      />
      <path
        d="M226.655 81.5078H206.308C197.763 81.5078 190.835 87.3854 190.835 94.6358V143.497C190.835 150.748 197.763 156.626 206.308 156.626H226.655C235.201 156.626 242.129 150.748 242.129 143.497V94.6358C242.129 87.3854 235.201 81.5078 226.655 81.5078Z"
        fill="#C3AAF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.452 77.832H206.308C195.769 77.832 187.158 86.4428 187.158 96.9823V141.151C187.158 151.69 195.769 160.301 206.308 160.301H228.452L229.225 157.655C232.837 145.261 234.378 131.955 234.378 119.068C234.378 106.181 232.837 92.8743 229.222 80.4808L228.449 77.8344L228.452 77.832Z"
        fill="#050B15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M225.695 81.5102H206.308C197.799 81.5102 190.835 88.4739 190.835 96.9836V141.152C190.835 149.664 197.799 156.626 206.308 156.626H225.695C228.866 145.746 230.703 132.869 230.703 119.067C230.703 105.262 228.866 92.3868 225.695 81.5078V81.5102Z"
        fill="#455368"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.063 30.5352C196.703 40.9278 210.79 56.247 219.67 74.1596H208.115C198.924 59.2352 186.022 47.3714 170.948 39.4662L179.065 30.5352H179.063Z"
        fill="#C3AAF5"
      />
      <path
        d="M45.3584 81.5078H25.0114C16.4657 81.5078 9.53809 87.3854 9.53809 94.6358V143.497C9.53809 150.748 16.4657 156.626 25.0114 156.626H45.3584C53.9041 156.626 60.8317 150.748 60.8317 143.497V94.6358C60.8317 87.3854 53.9041 81.5078 45.3584 81.5078Z"
        fill="#C3AAF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.215 77.832H45.3583C55.8978 77.832 64.5085 86.4428 64.5085 96.9823V141.151C64.5085 151.69 55.8978 160.301 45.3583 160.301H23.215L22.4444 157.655C18.8325 145.261 17.2891 131.955 17.2891 119.068C17.2891 106.181 18.8301 92.8743 22.4444 80.4808L23.2174 77.8344L23.215 77.832Z"
        fill="#050B15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9724 81.5102H45.3586C53.8682 81.5102 60.8319 88.4739 60.8319 96.9836V141.152C60.8319 149.664 53.8682 156.626 45.3586 156.626H25.9724C22.8011 145.746 20.9639 132.869 20.9639 119.067C20.9639 105.262 22.8011 92.3868 25.9724 81.5078V81.5102Z"
        fill="#455368"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.1786 177.436C39.1048 165.84 33.3595 152.798 30.8745 138.519C27.4769 118.991 30.6747 98.8198 39.6273 81.1648C56.1409 48.5976 89.3462 28.4023 125.834 28.4023C162.321 28.4023 195.526 48.5976 212.04 81.1648C220.992 98.8222 224.19 118.991 220.793 138.519C218.308 152.798 212.562 165.842 204.488 177.436C207.638 180.892 214.961 182.734 219.133 183.208C230.612 184.515 251.905 185.137 251.665 201.318C251.612 204.783 250.699 208.087 248.821 211.01C241.13 222.963 220.903 223.05 208.356 222.318C200.453 221.855 192.466 220.832 184.597 219.469C186.188 222.549 188.088 225.556 190.205 228.554C193.222 232.826 196.6 236.883 199.514 241.21C202.466 245.595 205.045 250.664 204.055 256.12C203.504 259.164 201.936 261.767 199.699 263.871C197.024 266.39 193.86 268.196 190.294 269.108C172.966 273.536 154.57 255.588 144.95 243.266C140.276 237.28 134.974 229.163 131.367 220.788C127.827 221.138 123.837 221.138 120.298 220.788C116.691 229.163 111.388 237.28 106.715 243.266C97.0949 255.588 78.6983 273.536 61.3709 269.108C57.8048 268.196 54.6407 266.39 51.9655 263.871C49.7286 261.764 48.161 259.164 47.6096 256.12C46.6199 250.664 49.1988 245.595 52.151 241.21C55.0645 236.883 58.4429 232.828 61.46 228.554C63.5766 225.556 65.4788 222.549 67.0681 219.469C59.199 220.832 51.2119 221.855 43.309 222.318C30.7613 223.052 10.5348 222.963 2.84383 211.01C0.963243 208.089 0.0506382 204.783 7.17824e-05 201.318C-0.240721 185.137 21.0526 184.515 32.5312 183.208C36.7041 182.734 44.0266 180.892 47.1762 177.436H47.1786Z"
        fill="#050B15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.834 35.7539C49.7768 35.7539 1.78201 128.445 68.744 191.058C63.1288 188.797 57.9614 185.705 53.4802 181.337C53.4802 181.337 48.3152 188.811 33.3644 190.514C18.4136 192.216 7.23115 193.066 7.35155 201.21C7.67662 223.062 60.0322 213.521 73.962 210.692C76.984 205.92 78.4769 200.637 79.31 195.135C82.71 230.83 45.3559 247.553 57.0054 258.523C76.4446 276.828 117.071 226.25 117.232 203.278C118.049 206.413 118.415 209.65 118.343 213.11C122.52 213.875 129.144 213.875 133.325 213.11C133.252 209.65 133.616 206.413 134.435 203.278C134.596 226.25 175.225 276.826 194.662 258.523C206.314 247.553 168.957 230.83 172.357 195.135C173.19 200.637 174.683 205.92 177.705 210.692C191.633 213.524 243.988 223.064 244.316 201.21C244.438 193.066 233.254 192.216 218.303 190.514C203.352 188.811 198.187 181.337 198.187 181.337C193.706 185.705 188.538 188.797 182.923 191.058C249.885 128.445 201.89 35.7539 125.834 35.7539Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.9792 145.434C44.3761 161.232 53.6177 176.912 68.7443 191.057C63.2133 188.829 58.1157 185.795 53.6803 181.528C54.3954 184.346 59.6399 207.255 41.3951 207.669C22.6445 208.095 15.8012 205.911 7.75391 204.153C12.6396 222.155 60.7042 213.385 73.9598 210.691C76.9818 205.918 78.4747 200.635 79.3079 195.133C78.9563 192.244 90.8009 187.098 97.0446 197.156C114.372 225.071 72.0913 256.326 62.5342 251.837C58.9368 250.15 59.9457 242.962 59.9529 242.902C55.1997 249.405 52.5991 254.378 57.0032 258.524C76.4424 276.829 117.069 226.251 117.23 203.279C118.047 206.414 118.413 209.651 118.34 213.111C120.43 213.494 123.13 213.684 125.831 213.684C128.531 213.684 131.232 213.494 133.322 213.111C133.25 209.651 133.614 206.414 134.433 203.279C134.594 226.251 175.223 276.827 194.66 258.524C199.064 254.378 196.463 249.405 191.71 242.902C191.719 242.964 192.726 250.15 189.129 251.837C179.572 256.326 137.291 225.073 154.618 197.156C160.862 187.098 172.704 192.246 172.355 195.133C173.188 200.635 174.681 205.918 177.703 210.691C190.959 213.385 239.023 222.157 243.909 204.153C235.859 205.911 229.016 208.095 210.268 207.669C192.023 207.255 197.27 184.346 197.983 181.528C193.547 185.793 188.452 188.829 182.919 191.057C198.045 176.912 207.289 161.232 211.684 145.434C197.349 168.887 161.375 184.943 125.829 184.943C90.2832 184.943 54.3088 168.887 39.9744 145.434H39.9792Z"
        fill="#DAD6D4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.517 167.91C118.063 168.392 121.972 168.758 125.834 168.828C129.696 168.758 133.604 168.392 137.151 167.91C134.021 170.821 129.836 172.334 125.834 172.338C121.832 172.334 117.647 170.821 114.517 167.91Z"
        fill="#DAD6D4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.6701 36.1705H87.6581L76.4179 23.8034C76.4179 23.8034 73.0155 18.819 78.8499 15.903C84.6843 12.987 105.775 6.78659 125.833 7.39339C145.889 6.78418 166.982 12.9846 172.817 15.903C178.651 18.8214 175.246 23.8034 175.246 23.8034L164.006 36.1705H163.994C152.154 31.1211 139.214 28.4025 125.831 28.4025C112.448 28.4025 99.5075 31.1235 87.6677 36.1705H87.6701Z"
        fill="#455368"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.6039 30.5352C54.9634 40.9278 40.877 56.247 31.999 74.1596H43.5547C52.7457 59.2352 65.6498 47.3714 80.721 39.4662L72.6039 30.5352Z"
        fill="#C3AAF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.9365 144.219C103.574 154.999 114.653 160.07 125.833 159.762C137.013 160.07 148.094 154.999 152.73 144.219C145.044 150.845 135.477 154.127 125.833 154.062C116.189 154.125 106.62 150.845 98.9365 144.219Z"
        fill="#050B15"
      />
      <path
        d="M78.1081 131.501C90.3003 131.501 100.184 120.263 100.184 106.401C100.184 92.5385 90.3003 81.3008 78.1081 81.3008C65.9159 81.3008 56.0322 92.5385 56.0322 106.401C56.0322 120.263 65.9159 131.501 78.1081 131.501Z"
        fill="#050B15"
      />
      <path
        d="M80.3982 131.501C91.3257 131.501 100.184 121.43 100.184 109.007C100.184 96.583 91.3257 86.5117 80.3982 86.5117C69.4708 86.5117 60.6123 96.583 60.6123 109.007C60.6123 121.43 69.4708 131.501 80.3982 131.501Z"
        fill="white"
      />
      <path
        d="M83.5284 123.717C89.1484 123.717 93.7043 118.432 93.7043 111.913C93.7043 105.394 89.1484 100.109 83.5284 100.109C77.9084 100.109 73.3525 105.394 73.3525 111.913C73.3525 118.432 77.9084 123.717 83.5284 123.717Z"
        fill="#050B15"
      />
      <path
        d="M79.3479 109.003C82.3267 109.003 84.7416 107.194 84.7416 104.962C84.7416 102.731 82.3267 100.922 79.3479 100.922C76.369 100.922 73.9541 102.731 73.9541 104.962C73.9541 107.194 76.369 109.003 79.3479 109.003Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M195.637 122.256C181.259 110.322 163.57 112.942 151.482 118.675C153.079 94.8322 178.432 86.9703 195.085 91.986C181.774 92.9636 170.541 96.6357 164.04 106.446C176.833 105.692 188.042 111.411 195.637 122.256Z"
        fill="#050B15"
      />
    </svg>
  )
}

export default SvgMascotHover
