import { useNewPlayerEnv } from '@lyfta/components-config'
import { updateTeacher } from '@lyfta/components-data/src/Store/Actions/users'
import {
  getNewFeatures,
  getViewer,
} from '@lyfta/components-data/src/Store/Selectors/viewer'
/* eslint-disable max-lines-per-function */
import { VideoJSPlayer } from '@lyfta/components-ui/src/Components/Video'
import { useTextTracks } from '@lyfta/components-ui/src/Components/Video/useTextTracks'
import { useVideoSources } from '@lyfta/components-ui/src/Components/Video/useVideoSources'
import { getCachedHead } from '@lyfta/components-ui/src/Helpers/getCachedHead'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Player from 'react-player'
import { useDispatch, useSelector } from 'react-redux'

const VideoPlayer = props => {
  const { videoUrl, userLanguage, onVideoProgress, posterUrl } = props

  const [useNewPlayer, setUseNewPlayer] = useState(useNewPlayerEnv)
  const [tracks, ready] = useTextTracks(videoUrl, userLanguage, !useNewPlayer)
  const sources = useVideoSources(videoUrl)
  const viewer = useSelector(getViewer)
  const newFeatures = useSelector(getNewFeatures)

  const dispatch = useDispatch()

  const videoJsOptions = useMemo(() => {
    /**
     * @type {import('videojs-hotkeys').VideoJsHotkeysOptions}
     */
    const hotkeyPluginOptions = {
      volumeStep: 0.1,
      seekStep: 5,
      enableModifiersForNumbers: false,
      alwaysCaptureHotkeys: true,
      enableVolumeScroll: false,
    }
    const defaultPreferences = {
      fontPercent: 1,
      color: '#FFF',
      backgroundColor: '#000',
      speed: 1,
    }

    const updateUserPreferences = items => {
      dispatch(
        updateTeacher({
          customMessage: {
            blockDefaultMessages: true,
          },
        })({
          id: viewer.id,
          user_profile_attributes: {
            video_player_preferences: {
              ...items,
            },
          },
        }),
      )
    }

    // noinspection UnnecessaryLocalVariableJS
    /**
     * @type {import('@types/video.js').VideoJsPlayerOptions}
     */
    const options = {
      autoplay: true,
      controls: true,
      fluid: true,
      plugins: {
        hotkeys: hotkeyPluginOptions,
      },
      tracks,
      sources,
      poster: posterUrl,
      videoPlayerPreferences:
        viewer.type === 'teachers'
          ? viewer.videoPlayerPreferences
          : defaultPreferences,
      defaultPreferences,
      updateUserPreferences:
        viewer.type === 'teachers' ? updateUserPreferences : noop,
      controlBar: {
        children: [],

        pictureInPictureToggle: false,
        // downloadButton: false,
      },
    }

    return options
  }, [tracks])

  /**
   * @type {React.MutableRefObject<import('react-player').ReactPlayerProps>}
   */
  const playerRef = useRef()

  // allow using of old player with a switch
  useEffect(() => {
    if (useNewPlayer || newFeatures) {
      return
    }

    setTimeout(() => {
      try {
        if (!playerRef.current) {
          return
        }

        playerRef.current.wrapper.querySelectorAll('track').forEach(track => {
          const trackSrc = track.src
          getCachedHead(trackSrc)
            .then(result => {
              // If the result is forbidden, then no such file.
              if (result.status === 403 || result.status === 404) {
                track.parentNode.removeChild(track)
              }
            })
            .catch()
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }, 20)
  }, [useNewPlayer])

  useEffect(() => {
    window.togglePlayer = () => {
      setUseNewPlayer(value => {
        // eslint-disable-next-line no-console
        console.log('using new player', !value)
        return !value
      })
    }

    return () => {
      delete window.togglePlayer
    }
  }, [])

  /**
   * @type {React.VideoHTMLAttributes<HTMLVideoElement>}
   */
  const videoProps = useMemo(() => {
    return {
      width: '100%',
      height: '100%',
    }
  }, [])

  if (!ready) {
    return null
  }
  if (newFeatures && useNewPlayer)
    return (
      <div className="video">
        <VideoJSPlayer
          options={videoJsOptions}
          onProgress={onVideoProgress}
          videoProps={videoProps}
          containerProps={{
            style: {
              width: '100%',
              height: '100%',
            },
          }}
        />
      </div>
    )

  return (
    <div className="video">
      <Player
        config={{
          file: {
            attributes: {
              controls: true,
              crossOrigin: 'anonymous',
              controlsList: 'nodownload',
              disablePictureInPicture: true,
            },
            tracks,
          },
        }}
        controls
        height="100%"
        playing
        ref={playerRef}
        url={sources}
        width="100%"
        onProgress={onVideoProgress}
      />
    </div>
  )
}
VideoPlayer.propTypes = {
  posterUrl: PropTypes.string,
  userLanguage: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  onVideoProgress: PropTypes.func.isRequired,
}

VideoPlayer.defaultProps = {
  posterUrl: null,
}

export default VideoPlayer
