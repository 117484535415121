import {
  authenticateByToken,
  confirmEmail,
} from '@lyfta/components-data/src/Store/Actions/auth'
import { I18n } from '@lyfta/components-i18n'
import logoImage from '@lyfta/components-ui/src/Assets/Icons/logo.png'
import paths from 'Constants/paths'
import get from 'lodash/get'
import pick from 'lodash/pick'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { Container, Link, Logo, Panel, TextBlock, Title } from './styles'

const checkConfirmation = ({ location }) => {
  const {
    auth_token: authToken,
    confirmation_token: confirmationToken,
  } = pick(qs.parse(location.search.replace('?', '')), [
    'auth_token',
    'confirmation_token',
  ])
  if (authToken && confirmationToken) {
    return {
      authToken,
      confirmationToken,
    }
  }
  return false
}

const CONFIRMATION_STATES = {
  error: 'error',
  loading: 'loading',
  success: 'success',
}

const CONFIRMATION_TITLE = {
  [CONFIRMATION_STATES.error]: 'auth.confirmationError',
  [CONFIRMATION_STATES.loading]: 'auth.confirmationWaiting',
  [CONFIRMATION_STATES.success]: 'auth.confirmed',
}
const CONFIRMATION_TEXT = {
  [CONFIRMATION_STATES.error]: 'auth.tokenInvalid',
  [CONFIRMATION_STATES.loading]: 'auth.confirmationProgress',
  [CONFIRMATION_STATES.success]: 'auth.accountConfirmed',
}

class Confirm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      confirmation: checkConfirmation(props),
      confirmationState: CONFIRMATION_STATES.loading,
    }
  }

  componentDidMount() {
    const { confirmation } = this.state
    const { onConfirmEmail, onAuthenticateByToken } = this.props
    if (confirmation) {
      onConfirmEmail(confirmation.confirmationToken).then(data => {
        if (!data.ok) {
          this.setState({
            confirmationState: CONFIRMATION_STATES.error,
          })
        } else {
          this.setState(
            {
              confirmationState: CONFIRMATION_STATES.success,
            },
            () => {
              setTimeout(() => {
                onAuthenticateByToken(confirmation.authToken)
              }, 2000)
            },
          )
        }
      })
    }
  }

  renderCreated() {
    const { location } = this.props
    const email = get(location, 'state.email')
    return (
      <Panel flexDirection="column">
        <TextBlock>
          <Title>
            <I18n text="auth.accountCreated" />
            <I18n
              href={paths.root}
              tag={Link}
              text="auth.login"
              to={paths.root}
            />
          </Title>
          <I18n i18nKey="auth.checkEmail" options={{ email }} tag="p" />{' '}
          {email && <b>{email}</b>}
        </TextBlock>
      </Panel>
    )
  }

  renderConfirmation() {
    const { confirmationState } = this.state
    return (
      <Panel flexDirection="column">
        <Title>
          <I18n text="auth.confirmation" />
          {confirmationState !== CONFIRMATION_STATES.loading && (
            <I18n
              href={paths.root}
              tag={Link}
              text="auth.login"
              to={paths.root}
            />
          )}
        </Title>
        <TextBlock>
          <I18n tag={Title} text={CONFIRMATION_TITLE[confirmationState]} />
          <I18n tag="p" text={CONFIRMATION_TEXT[confirmationState]} />
        </TextBlock>
      </Panel>
    )
  }

  render() {
    const { confirmation } = this.state
    return (
      <Container>
        <Logo to={paths.root}>
          <img alt="6by3" src={logoImage} />
        </Logo>
        {confirmation ? this.renderConfirmation() : this.renderCreated()}
      </Container>
    )
  }
}

Confirm.propTypes = {
  location: PropTypes.object.isRequired,
  onAuthenticateByToken: PropTypes.func.isRequired,
  onConfirmEmail: PropTypes.func.isRequired,
}

export default connect(null, {
  onAuthenticateByToken: authenticateByToken,
  onConfirmEmail: confirmEmail,
})(Confirm)
