import { createReducer } from '../../helpers'
import { createEntityFields, createReducerHandlers } from '../../utils'
import {
  CREATE_MEETING_ATTENDEE,
  LOAD_MEETING_ATTENDEE,
  LOAD_MEETING_ATTENDEES,
  UPDATE_MEETING_ATTENDEE,
} from '../Actions/meetingAttendees'

const initialState = {
  ...createEntityFields('meetingAttendees'),
}

const handlers = {
  ...createReducerHandlers('meetingAttendees', LOAD_MEETING_ATTENDEES, {
    withReplace: true,
    mapToKey: 'meetingAttendees',
  }),
  ...createReducerHandlers('meetingAttendees', LOAD_MEETING_ATTENDEE, {
    mapToKey: 'currentMeetingAttendee',
    singular: true,
  }),
  ...createReducerHandlers('meetingAttendees', UPDATE_MEETING_ATTENDEE, {
    mapToKey: 'currentMeetingAttendee',
    singular: true,
  }),
  ...createReducerHandlers('meetingAttendees', CREATE_MEETING_ATTENDEE, {
    mapToKey: 'currentMeetingAttendee',
    singular: true,
  }),
}

export default createReducer(initialState, handlers)
