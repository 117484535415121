import colors from '@lyfta/components-theme/src/themes/Main/colors'

export const getDisabledStyle = (p, type) => {
  if (!p.disabled) {
    return {}
  }
  if (p?.disabledStyle) {
    return {
      default: {
        cursor: 'default',
        ...p?.disabledStyle,
      },
    }
  }
  return {
    default: {
      backgroundColor: colors.neutral_300,
      borderColor: colors.neutral_300,
      color: colors.black_600,
      boxShadow: 'none',
      cursor: 'default',
      fill: colors.black_600,
      ...type?.disabledStyle,
    },
  }
}
