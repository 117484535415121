import PropTypes from 'prop-types'
import React from 'react'

import { Container, HorizontalLine, SeparatorText } from './styles'

const SeparatorComponent = ({ label, color, lineaColor, height }) =>
  label ? (
    <Container>
      <HorizontalLine lineaColor={lineaColor} />
      <SeparatorText color={color}>{label}</SeparatorText>
      <HorizontalLine lineaColor={lineaColor} />
    </Container>
  ) : (
    <Container height={height}>
      <HorizontalLine lineaColor={lineaColor} />
    </Container>
  )

SeparatorComponent.defaultProps = {
  color: 'dustyGray',
  label: '',
  lineaColor: '',
  height: '',
}

SeparatorComponent.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  label: PropTypes.string,
  lineaColor: PropTypes.string,
}

export default SeparatorComponent
