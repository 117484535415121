import PropTypes from 'prop-types'
import React from 'react'
import validate from 'validate.js'

import * as constraints from '../../../../Constants/constraints'
import { LyftaForm } from '../../../Form'
import { Container } from '../../styles'
import formFields from './form'

const ChangePasswordForm = ({ onSubmit, initialValues }) => {
  const validateForm = values =>
    validate(values, {
      ...constraints.passwordConstraint,
      ...constraints.confirmPasswordConstraint,
    })

  return (
    <Container>
      <LyftaForm
        initialValues={initialValues}
        noWrap
        render={formFields}
        validate={validateForm}
        withoutHeader
        onSubmit={onSubmit}
      />
    </Container>
  )
}

ChangePasswordForm.defaultProps = {
  initialValues: {},
}
ChangePasswordForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}
export default ChangePasswordForm
