import { getPermissions } from '@lyfta/components-data/src/Store/Selectors/viewer'
import { I18n, useTranslation } from '@lyfta/components-i18n'
import { themeGet as theme } from '@lyfta/components-theme'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import { PermissionWrapper } from '../../../Blocks'
import { Icon } from '../../../Components'
import { reactEvent } from '../../../Services/Utils'
import { Label, MenuItem, MenuItemWrapper } from './styles'

export const SidebarLabel = ({ route, isTrialing, isOpen }) => {
  const { t } = useTranslation()
  const permissions = useSelector(getPermissions)

  const { allowMySchool } = permissions
  const labelText = route.sidebarLabel || route.breadcrumb

  let label =
    typeof route.breadcrumb === 'string' ? (
      <I18n text={route.breadcrumb} />
    ) : (
      route.breadcrumb
    )

  if (route.sidebarLabel) {
    label = <I18n text={route.sidebarLabel} />
  }

  const { onlySubscribed, allowSchoolRestriction, roles } = route
  const path = Array.isArray(route.path) ? route.path[0] : route.path
  const disabled =
    (isTrialing && onlySubscribed) || (allowSchoolRestriction && !allowMySchool)

  if (!path) {
    return false
  }
  const pathString = path.replace(/\//g, '').replace(/-/g, '_')

  const menuAction = `clicked_menu_${pathString || 'home'}`

  return (
    <PermissionWrapper requiredPermissions={route.permissions} roles={roles}>
      <MenuItemWrapper isOpen={isOpen}>
        <MenuItem
          onClick={() => reactEvent({ action: menuAction })}
          data-intercom-target={t(labelText)}
          disabled={disabled}
          exact={route.exact}
          id={`menu-${t(labelText).toLowerCase().replaceAll(' ', '-')}`}
          isOpen={isOpen}
          title={t(labelText)}
          to={path}
        >
          {route.glyph && (
            <Icon
              fill={disabled && theme('colors.darkDustyGray')}
              glyph={route.glyph}
              height={24}
              width={24}
            />
          )}

          <Label disabled={disabled}>{label}</Label>
        </MenuItem>
      </MenuItemWrapper>
    </PermissionWrapper>
  )
}
SidebarLabel.defaultProps = {
  isOpen: undefined,
}
SidebarLabel.propTypes = {
  isOpen: PropTypes.bool,
  isTrialing: PropTypes.bool.isRequired,
  route: PropTypes.object.isRequired,
}
