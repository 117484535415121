import { createSelector } from 'reselect'

import { getAllEntities, getEntity } from '../../../utils'

export const getAdminUsers = getAllEntities('admins', 'users', 'adminUsers')
export const getCurrentAdminUser = getEntity('admins', 'users', 'adminUsers')

export const getAdminUserName = createSelector(
  getCurrentAdminUser,
  (user = {}) => {
    const { firstName, lastName, email } = user

    if (firstName || lastName) {
      return `${firstName} ${lastName} `
    }

    return email
  },
)
