import * as React from 'react'

function SvgEdit(props) {
  return (
    <svg
      data-name="Layer 1"
      height="1em"
      viewBox="0 0 100 100"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M64.7 12.38L10.22 66.77a3.13 3.13 0 00-.66.94L.11 94.53a4 4 0 001 4.25A4.1 4.1 0 003.89 100a5.83 5.83 0 001.32-.19L32 90.38a2.13 2.13 0 00.94-.67l54.61-54.38M13.9 71.4a41.82 41.82 0 018.21 6.51 41.17 41.17 0 016.42 8.22L13.05 91.6a10.21 10.21 0 00-1.89-2.6 9.78 9.78 0 00-2.64-1.89zM86.043 2.344l11.59 11.59a8 8 0 010 11.313l-6.364 6.364L68.366 8.708l6.364-6.364a8 8 0 0111.313 0z" />
    </svg>
  )
}

export default SvgEdit
