import * as React from 'react'

function SvgHelp(props) {
  return (
    <svg height="1em" viewBox="0 0 36 31" width="1em" {...props}>
      <path
        d="M25.39 12.6c1.976 0 3.832.77 5.228 2.166a7.345 7.345 0 012.166 5.227 7.338 7.338 0 01-1.278 4.154c.04.032.089.047.126.084l3.953 3.951a1.417 1.417 0 01-2.003 2.004l-3.952-3.952c-.038-.037-.052-.086-.084-.127a7.337 7.337 0 01-4.155 1.279 7.348 7.348 0 01-5.229-2.166c-2.883-2.882-2.883-7.572 0-10.454A7.342 7.342 0 0125.39 12.6zm-14.047 8.824a28.488 28.488 0 006.262 6.557 14.444 14.444 0 01-13.248-3.624l6.986-2.933zm4.867-2.043a8.983 8.983 0 003.325 7.413 1.693 1.693 0 01-1.524-.3 27.068 27.068 0 01-4.972-5.15l-.35-.485 3.52-1.478zm9.18-4.893a5.468 5.468 0 00-3.893 1.612 5.51 5.51 0 000 7.785 5.47 5.47 0 003.894 1.612 5.463 5.463 0 003.892-1.611 5.463 5.463 0 001.613-3.892 5.466 5.466 0 00-1.613-3.894 5.468 5.468 0 00-3.893-1.612zM7.32 12.673a28.189 28.189 0 003.237 7.541L3.32 23.252A14.098 14.098 0 01.084 15.71zM16.78 8.7c.9 1.438 1.66 2.955 2.27 4.53a8.98 8.98 0 00-2.615 4.515l-4.545 1.908a26.787 26.787 0 01-3.084-6.973l-.153-.568L16.78 8.7zM6.566 2.263a28.049 28.049 0 00.42 9.01L0 14.205C-.01 9.378 2.465 4.878 6.566 2.263zm18.783 2.84a14.112 14.112 0 013.04 6.279A8.96 8.96 0 0025.2 10.8a8.958 8.958 0 00-4.98 1.502 28.01 28.01 0 00-1.762-3.588l-.344-.573 7.235-3.037zm-16.4-3.476c.57-.24 1.226-.15 1.71.233a27.058 27.058 0 015.322 5.636l-7.649 3.211a26.646 26.646 0 01-.402-7.701c.057-.61.448-1.14 1.018-1.38zM11.064.374c4.752-1.101 9.743.265 13.248 3.624l-6.985 2.933A28.488 28.488 0 0011.065.374z"
        fill="#EA4E47"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgHelp
