import { CoreStore } from '@lyfta/components-data'
import jwtDecode from 'jwt-decode'
import qs from 'qs'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useQSToken = history => {
  const dispatch = useDispatch()
  const queryStr = window.location.search.substr(1)

  const suppliedQueryString = qs.parse(queryStr, { parameterLimit: 3 })
  const { jwt: token } = suppliedQueryString

  const isAuthenticated = useSelector(
    CoreStore.Selectors.Viewer.getIsAuthenticated,
  )

  const validateToken = useCallback(() => {
    // skip this check if already logged in
    if (isAuthenticated) {
      return isAuthenticated
    }

    let decodedToken = null
    try {
      // valid token format
      decodedToken = jwtDecode(token)
    } catch (error) {
      return false
    }
    const { exp } = decodedToken
    const currentTime = Date.now() / 1000

    dispatch(CoreStore.Actions.Viewer.setJWT(token, null))

    return exp > currentTime
  }, [token, isAuthenticated])

  useEffect(() => {
    if (token && isAuthenticated) {
      delete suppliedQueryString.jwt
      setTimeout(
        () => history.replace({ search: qs.stringify(suppliedQueryString) }),
        100,
      )
    }
    if (token && !isAuthenticated) validateToken(token)
  }, [token, isAuthenticated])
}
