import { styled } from '@lyfta/components-theme'
import {
  PropTypes,
  css,
  handleKeyPress,
  useConditionalCallback,
} from '@lyfta/components-ui'
import React from 'react'

import SvgStar from '../../../../../../Assets/QuestTracker/StarRating'
import { StarContainer as BaseStarContainer } from './_styles'

export { Container, Wrapper } from './_styles'

export const StarContainer = styled(BaseStarContainer)`
  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: not-allowed;
      `
    }
    return css`
      cursor: pointer;
    `
  }}
`

export const Star = ({
  disabled,
  onClick,
  onMouseOver,
  onMouseOut,
  ...props
}) => {
  const onStarMouseOver = useConditionalCallback(disabled, onMouseOver)
  const onStarMouseOut = useConditionalCallback(disabled, onMouseOut)

  return (
    <StarContainer
      disabled={disabled}
      onKeyPress={handleKeyPress({
        callback: useConditionalCallback(disabled, onClick),
        key: 'Enter',
      })}
      onMouseOut={onStarMouseOut}
      onMouseOver={onStarMouseOver}
      tabIndex="0"
      {...props}
    >
      <SvgStar
        disabled={disabled}
        height="36px"
        width="36px"
        onClick={onClick}
        {...props}
      />
    </StarContainer>
  )
}

Star.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseOut: PropTypes.func,
  onMouseOver: PropTypes.func,
}

Star.defaultProps = {
  disabled: false,
  onClick: null,
  onMouseOut: null,
  onMouseOver: null,
}
