import { color, css, styled } from '@lyfta/components-theme'
import { filter, map, omit } from 'lodash'
import { compile, parse } from 'path-to-regexp'
import PropTypes from 'prop-types'
import React from 'react'
import { NavLink, useParams } from 'react-router-dom'

import { convertFirstParamToPassedId } from '../../Services'

export const Link = styled(NavLink)`
  text-decoration: none;
  transition: border-color 0.15s ease-out, color 0.15s ease-out;

  ${color};

  ${props =>
    props.underline &&
    css`
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: inherit;
    `};
`

export const ParamLink = ({ children, route, additionalParams, ...props }) => {
  const params = useParams()
  let path = (route && route?.path) || ''
  path = Array.isArray(path) ? path[0] : path

  const toPath = compile(path)
  const tokens = parse(path)

  const keys = map(
    filter(tokens, t => typeof t !== 'string'),
    token => token.name,
  )

  let { id } = params

  if (id === undefined) {
    id = params[keys[0]]
  }

  const linkPath = toPath(
    convertFirstParamToPassedId(keys, { ...params, ...additionalParams }, id),
    { validate: false },
  )

  return (
    <Link to={linkPath} {...omit(props, ['active'])}>
      {children}
    </Link>
  )
}

ParamLink.defaultProps = {
  additionalParams: {},
}

ParamLink.propTypes = {
  additionalParams: PropTypes.object,
  children: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
}
