import { getIcon } from '@lyfta/components-content/src/Utils'
import { themeGet } from '@lyfta/components-theme'
import { Icon as TypeIcon, reactEvent } from '@lyfta/components-ui'
import { noop } from 'lodash'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'

import {
  Container,
  Description,
  IconContainer,
  Left,
  Right,
  Title,
  TopRow,
  TopRowLeftGroup,
} from './styles'

class ContentBlock extends PureComponent {
  handleContentClick = () => {
    const { resourcePreviewPath, content, history, contentLocked } = this.props
    const isLicensed = get(content, 'isLicensed')

    if (isLicensed) {
      reactEvent({
        action: 'clicked_view_related_content',
        params: { content_type: content.type },
      })
      history.push(resourcePreviewPath(content.id))
    }
    contentLocked(content)
  }

  render() {
    const { image, icon, description, title, content } = this.props
    return (
      <Container onClick={this.handleContentClick}>
        <Left>
          <img alt="" src={image} />
        </Left>
        <Right>
          <TopRow>
            <TopRowLeftGroup>
              <IconContainer>
                <TypeIcon
                  fill={themeGet('colors.black_700')}
                  glyph={getIcon(content.type)}
                  height={16}
                  width={16}
                />
              </IconContainer>
              <Title>{title}</Title>
            </TopRowLeftGroup>
            <IconContainer>{icon}</IconContainer>
          </TopRow>
          <Description>{description}</Description>
        </Right>
      </Container>
    )
  }
}

ContentBlock.defaultProps = {
  image: 'http://via.placeholder.com/120x80',
  contentLocked: noop,
}

ContentBlock.propTypes = {
  content: PropTypes.object.isRequired,
  contentLocked: PropTypes.func,
  description: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  image: PropTypes.string,
  resourcePreviewPath: PropTypes.func.isRequired,
  title: PropTypes.object.isRequired,
}

export default withRouter(ContentBlock)
