import { css, styled, themeGet } from '@lyfta/components-theme'

import { Box } from '../../Box'

export const Label = styled(Box).attrs(() => ({
  as: 'label',
  fontSize: 4,
}))`
  position: absolute;
  z-index: 1;
  top: 18px;
  left: 16px;
  color: ${themeGet('colors.slateGray')};
  font-weight: 600;
  transition: all ${themeGet('transitionTime')} ease;
  user-select: none;

  ${props =>
    props.active &&
    css`
      top: 6px;
      font-size: 13px;
      font-weight: normal;
      color: ${themeGet('colors.font.accent')};
    `};
`

export const Input = styled(Box).attrs(() => ({
  as: 'input',
  m: 0,
  px: 3,
  fontSize: 4,
}))`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
  border: 0;
  outline: none;
  background: ${themeGet('colors.clear')};
  font-weight: 600;
  transition: all ${themeGet('transitionTime')} ease;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  ${props =>
    props.active &&
    css`
      padding-top: 18px;
    `};
  ${props =>
    props.meta.touched &&
    props.meta.error &&
    !props.active &&
    css`
      border-bottom: solid red 2px;
    `};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected,
  &:-webkit-autofill:active {
    appearance: none !important;
    background: transparent !important;
    background-color: transparent !important;
    background-image: none !important;
    color: inherit !important;
    box-shadow: 0 0 0 30px ${themeGet('colors.alto')} inset !important;
  }
`

export const Description = styled(Box).attrs(() => ({}))`
  top: 6px;
  font-weight: normal;
  color: ${themeGet('colors.dustyGray')};
  transition: all ${themeGet('transitionTime')} ease;
  user-select: none;

  ${props =>
    props.active &&
    css`
      color: ${themeGet('colors.font.accent')};
    `};
`

export const Container = styled(Box)`
  position: relative;
  background: ${themeGet('colors.alto')};
  height: 56px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  ${props =>
    props.active &&
    css`
      border-bottom: 2px solid
        ${themeGet(props.activeBottomBorderColor || 'colors.font.accent')};
    `};

  ${props =>
    !props.active &&
    props.error &&
    css`
      border-color: ${themeGet('colors.error')};
    `};
`

export const Wrapper = styled(Box)`
  width: 100%;
`

Label.displayName = 'Label'
Input.displayName = 'Input'
Description.displayName = 'Description'
Container.displayName = 'Container'
Wrapper.displayName = 'Wrapper'
