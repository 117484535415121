import * as React from 'react'

function SvgUpload(props) {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.41003 9.41003L7 8L12 3L17 8L15.58 9.41003L13 6.82996V17H11V6.82996L8.41003 9.41003Z"
        fill="black"
      />
      <path
        d="M5 16V19H19V16H21V19C21 20.1 20.1 21 19 21H5C3.90002 21 3 20.1 3 19V16H5Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgUpload
