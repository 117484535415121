import { styled } from '@lyfta/components-theme'
import { merge } from 'lodash'

import { Box } from '../../BaseComponents'

const HorizontalRule = styled(Box).attrs(p => merge({ as: 'hr', mb: 3 }, p))`
  border: 0;
  border-top: 1px dashed ${p => p.dashColor || '#000'};
`

export default HorizontalRule
