import { styled, themeGet } from '@lyfta/components-theme'
import {
  AnimatedAbsoluteFlex,
  AnimatedFlex,
  Flex,
  Header,
  ImageBackground,
  Markdown,
  ScrollableFlex,
  Text,
} from '@lyfta/components-ui'

import { Submit as BaseSubmit } from '../../styles'
import WelcomePattern from '../welcome-globe-pattern.png'

export const Container = styled(AnimatedAbsoluteFlex).attrs(() => ({
  justifyContent: 'center',
  alignItems: 'center',
}))`
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1002;
`
export const TitleModal = styled(Flex)`
  justify-content: center;
  text-align: center;
  font-size: 33px;
  line-height: 40px;

  font-weight: 700;
  margin-bottom: 16px;
  letter-spacing: 1px;
  color: ${themeGet('colors.black_800')};
`
export const SubtitleModal = styled(Flex)`
  justify-content: center;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 16px;
  letter-spacing: 1px;
  color: ${themeGet('colors.sa_primary_90')};
`
export const DescriptionModal = styled(Flex)`
  font-size: 18px;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${themeGet('colors.black_800')};
  padding: 0 30px;
`

export const WelcomeScreen = styled(AnimatedFlex)`
  width: 600px;
  height: 540px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: grey;
  border-radius: 16px;
  overflow: hidden;
`

export const WelcomeMessage = styled(ImageBackground).attrs(() => ({
  backgroundColor: 'rgb(254, 248, 233)',
  source: WelcomePattern,
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))`
  display: flex;
  width: 100%;
  height: 400px;
  resize-mode: cover;
`

export const Content = styled(Flex).attrs({
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  pb: 2,
})`
  width: 100%;
`

export const TitleText = styled(ScrollableFlex).attrs({
  flexDirection: 'column',
  flex: 1,
  m: 3,
})``

export const MainTitle = styled(Header).attrs({
  level: 1,
  fontSize: 4,
  maxWidth: '300px',
  textAlign: 'center',
})`
  text-shadow: -2px -2px 0 ${themeGet('colors.font.outline')},
    2px -2px 0 ${themeGet('colors.font.outline')},
    -2px 2px 0 ${themeGet('colors.font.outline')},
    2px 2px 0 ${themeGet('colors.font.outline')};
`

export const MainTitleText = styled(Markdown)``

export const DescriptionText = styled(Markdown).attrs(p => ({
  textAlign: 'center',
  fontSize: 4,
  color: themeGet('colors.font.secondary')(p),
}))`
  text-align: center;
  color: #000;
`

export const Description = styled(Flex).attrs({
  fontSize: 2,
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  px: 3,
})``

export const Paragraph = styled(Text)`
  color: grey;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: 16px;
  color: #5d5d5d;
`
export const Submit = styled(BaseSubmit)`
  margin-top: 0;
`

Container.displayName = 'WelcomeContainer'
WelcomeScreen.displayName = 'WelcomeScreen'
WelcomeMessage.displayName = 'WelcomeMessage'
Content.displayName = 'WelcomeContent'
TitleText.displayName = 'WelcomeTitleText'
MainTitle.displayName = 'WelcomeMainTitle'
MainTitleText.displayName = 'WelcomeMainTitleText'
DescriptionText.displayName = 'WelcomeDescriptionText'
Description.displayName = 'WelcomeDescription'
Paragraph.displayName = 'WelcomeParagraph'
Submit.displayName = 'WelcomeSubmit'
