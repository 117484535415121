import * as React from 'react'

function SvgRma(props) {
  return (
    <svg height="1em" viewBox="0 0 212.555 247.837" width="1em" {...props}>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <path d="M53.092 194.811H13.473l39.619 39.619z" fill="#0094ac" />
        <path
          d="M204.5.05a5.9 5.9 0 00-1.534 0H10.405C3.468.05 0 3.385 0 10.322v166.814h60.763c6.737 0 10.2 3.4 10.2 10.2v35.417c-.067 8.2-.067 16.475-.067 25.079H203.1a11.608 11.608 0 003-.4c4.335-1.2 6.4-4.536 6.4-10V9.655a8.746 8.746 0 00-8-9.605zm-54.627 30.015a16.208 16.208 0 11-16.208 16.21 16.225 16.225 0 0116.208-16.21zM39.619 134.982a4.617 4.617 0 01-4-6.87L82.24 53.943a4.626 4.626 0 017.4-.2l28.48 38.552a4.747 4.747 0 006.67.534l11.81-11.273a4.319 4.319 0 016.67.534l33.149 46.156a4.383 4.383 0 01-3.668 6.87H39.619zM146.6 229.961H97.38a8.865 8.865 0 01-1.4-17.675 4.93 4.93 0 011.4 0c8.537 0 15.141.067 23.745.067H146.6c5.936 0 9.938 3.6 9.871 8.8-.067 5.269-4 8.8-9.871 8.8zm51.3-40.619a8.8 8.8 0 01-8.671 7.2H97.447c-7 0-11.272-5.936-9.071-12.006a9 9 0 019-5.669H133.2V179h54.093a16.331 16.331 0 013.6.2 8.594 8.594 0 017.007 10.142z"
          fill="#0094ac"
        />
      </g>
    </svg>
  )
}

export default SvgRma
