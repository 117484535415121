import { I18n } from '@lyfta/components-i18n'
import { filter, map } from 'lodash'
import { parse } from 'path-to-regexp'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { Tab } from './styles'

export const TabLabel = ({ route, ...props }) => {
  const [labelText, setLabelText] = useState(null)
  const [modelName, setModelName] = useState('')

  useEffect(() => {
    let path = (route && route?.path) || ''
    path = Array.isArray(path) ? path[0] : path

    let label = route.tabTitle || route.breadcrumb
    const tokens = parse(path)

    if (!label || typeof label !== 'string' || label === '') {
      const modelNameTokens = filter(
        map(
          // filter tokens for strings, i.e. not :id type tokens
          filter(tokens, t => typeof t === 'string'),
          // and then remove leading / to make a proabable model name
          t => t.replace('/', ''),
        ),
        t => !['new', 'edit'].includes(t),
      )

      setModelName(modelNameTokens[modelNameTokens.length - 1])
      const editing = path.endsWith('/edit')

      label = editing ? 'core.edit' : 'core.create'
    }

    setLabelText(label)
  }, [route?.tabTitle, route?.breadcrumb])

  return (
    <I18n
      options={{ modelName, count: 10 }}
      route={route}
      sentenceCase
      tag={Tab}
      text={labelText}
      {...props}
    />
  )
}

TabLabel.defaultProps = { route: {} }
TabLabel.propTypes = {
  route: PropTypes.object,
}
