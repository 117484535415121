import * as React from 'react'

function SvgDoubleArrowLeft(props) {
  return (
    <svg height="24" viewBox="0 0 24 24" width="24" {...props}>
      <path
        clipRule="evenodd"
        d="M4.82845 12L12.4338 19.5889L11.0196 21L2.00002 12L11.0196 3L12.4338 4.41114L4.82845 12Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M14.3909 12L21.9963 19.5889L20.5821 21L11.5625 12L20.5821 3L21.9963 4.41114L14.3909 12Z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgDoubleArrowLeft
