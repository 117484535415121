import { createAsyncAction } from '../../helpers'
import apiCall from '../../services/Api'

export const CREATE_MEETING = createAsyncAction('meetings/CREATE_MEETING')
export const UPDATE_MEETING = createAsyncAction('meetings/UPDATE_MEETING')
export const LOAD_MEETING = createAsyncAction('meetings/LOAD_MEETING')
export const LOAD_MEETINGS = createAsyncAction('meetings/LOAD_MEETINGS')

export const clearCurrent = () => ({ type: LOAD_MEETING.CLEAR })

export const loadMeeting = id => {
  return apiCall({
    method: 'GET',
    endpoint: `/meetings/${id}?include=occurrences,defaultHost`,
    types: LOAD_MEETING,
    payload: { id },
  })
}

export const loadMeetings = ({
  filter,
  page,
  include = 'occurrences,defaultHost',
  sort = 'name',
  ...props
} = {}) =>
  apiCall({
    method: 'GET',
    endpoint: '/meetings',
    query: {
      include,
      page,
      filter,
      sort,
    },
    types: LOAD_MEETINGS,
    paged: true,
    payload: { page, filter, include, sort, ...props },
  })

export const createMeeting = meeting =>
  apiCall({
    method: 'POST',
    endpoint: `/meetings?include=occurrences,defaultHost`,
    types: CREATE_MEETING,
    query: {
      data: {
        type: 'meetings',
        attributes: {
          ...meeting,
        },
      },
    },
  })

export const updateMeeting = meeting =>
  apiCall({
    method: 'PATCH',
    endpoint: `/meetings/${meeting.id}?include=occurrences,defaultHost`,
    types: UPDATE_MEETING,
    query: {
      data: {
        type: 'meetings',
        attributes: {
          ...meeting,
        },
      },
    },
  })
