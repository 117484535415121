import * as React from 'react'

function SvgDuplicate(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 107.85 107.48"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path d="M92.85 0H38.59a15 15 0 00-15 15v8.32h9.57v-6.76a7 7 0 017-7h51.12a7 7 0 017 7v51.13a7 7 0 01-7 7h-7.19v9.56h8.76a15 15 0 0015-15V15a15 15 0 00-15-15z" />
          <path d="M69.25 23.23H15a15 15 0 00-15 15v54.25a15 15 0 0015 15h54.25a15 15 0 0015-15V38.23a15 15 0 00-15-15zm-7.77 44.91a2 2 0 01-2 2H46.91v12.57a2 2 0 01-2 2h-5.57a2 2 0 01-2-2V70.14H24.77a2 2 0 01-2-2v-5.57a2 2 0 012-2h12.57V48a2 2 0 012-2h5.57a2 2 0 012 2v12.57h12.57a2 2 0 012 2z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgDuplicate
