import { useAnimation } from 'framer-motion'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import Component from './Component'

const Achievement = ({ showAchievement, ...props }) => {
  if (!showAchievement) return null

  const containerControls = useAnimation()

  const containerAnimation = {
    animate: containerControls,
    initial: { opacity: 0, display: 'none' },
  }

  // as per Welcome component
  const animateIn = async () => {
    await containerControls.start({
      opacity: 1,
      display: 'block',
      transition: { duration: 0.25 },
    })
  }

  const animateOut = async () => {
    return containerControls.start({
      opacity: 0,
      display: 'none',
      transition: { duration: 0.1 },
    })
  }

  useEffect(() => {
    if (showAchievement) {
      animateIn().then(() => {})
    }

    if (!showAchievement) {
      animateOut().then(() => {})
    }
  }, [showAchievement])

  return (
    <Component
      {...props}
      containerAnimation={containerAnimation}
      showAchievement={showAchievement}
    />
  )
}

Achievement.defaultProps = {}

Achievement.propTypes = {
  hideAchievement: PropTypes.func.isRequired,
  showAchievement: PropTypes.bool.isRequired,
}

export default Achievement
