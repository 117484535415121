import { styled } from '@lyfta/components-theme'
import { Flex, TouchableFlex } from '@lyfta/components-ui'
import React from 'react'

import SvgStar from '../../../../../../Assets/QuestTracker/StarRating'

export const Container = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  width: 100%;
  height: 36px;
`
export const Wrapper = styled(Flex).attrs({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  p: 2,
  pt: 0,
  pl: 0,
})`
  height: 36px;
`

export const StarContainer = styled(TouchableFlex).attrs({
  py: 3,
  px: 1,
})``

export const Star = props => (
  <StarContainer tabIndex="0" {...props}>
    <SvgStar height="36px" width="36px" {...props} />
  </StarContainer>
)
