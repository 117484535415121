import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'

import { LogOut } from '../../../Assets'
import { LogoutButton } from '../../../Blocks'
import { Icon } from '../../../Components'
import { Label, MenuButton } from './styles'

export const LogoutItem = ({ t, className, redirectPath }) => {
  return (
    <LogoutButton redirectPath={redirectPath}>
      {performLogout => (
        <MenuButton
          className={className}
          title={t('actions.logout')}
          onClick={performLogout}
        >
          <Icon glyph={LogOut} />
          <Label>
            <I18n text="actions.logout" />
          </Label>
        </MenuButton>
      )}
    </LogoutButton>
  )
}

LogoutItem.defaultProps = {
  className: '',
  redirectPath: undefined,
}

LogoutItem.propTypes = {
  className: PropTypes.string,
  redirectPath: PropTypes.string,
  t: PropTypes.func.isRequired,
}
