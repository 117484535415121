import { LyftaThemeProvider } from '@lyfta/components-theme'
import { ConnectedRouter } from 'connected-react-router/immutable'
import App from 'Containers/App'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import createStore from 'Store'

const { store, history, persistor } = createStore()

delete window.INITIAL_STATE

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate

renderMethod(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LyftaThemeProvider>
        <ConnectedRouter history={history}>
          <App history={history} />
        </ConnectedRouter>
      </LyftaThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)
