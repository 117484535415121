import {
  getAllEntities,
  getEntity,
  getEntityStateField,
} from '@lyfta/components-data'
import { getTranslatedField } from '@lyfta/components-i18n'
import get from 'lodash/get'
import { createSelector } from 'reselect'

export const getState = state => state.collections

export const getCollections = getAllEntities('collections')
export const getCurrentCollection = getEntity('collections')
export const getCollectionContent = createSelector(
  getCurrentCollection,
  collection => {
    return get(collection, 'content')
  },
)

export const getCollectionName = createSelector(
  getCurrentCollection,
  collection => {
    return getTranslatedField(collection, 'name')
  },
)

export const getIsLoaded = getEntityStateField('collections', 'isLoaded')
export const getIsLoading = getEntityStateField('collections', 'isLoading')
export const getPaged = getEntityStateField('collections', 'paged')
