import { appIdentifier } from '@lyfta/components-config'
import { getError } from '@lyfta/components-data/src/Store/Selectors/auth'
import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import { AnimatedInput, ButtonNew } from '../../../../Components'
import { ErrorBlock } from '../../styles'

const ResetFormFieldset = ({ handleSubmit, pristine, invalid }) => {
  const error = useSelector(getError)
  return (
    <>
      <AnimatedInput
        autoComplete="off"
        label={
          appIdentifier === 'student' ? (
            <I18n text="auth.email_or_username" />
          ) : (
            <I18n text="auth.email" />
          )
        }
        mb={2}
        name="email"
        type="email"
      />
      <ButtonNew
        disabled={pristine || invalid}
        mt={2}
        rank={appIdentifier === 'teacher' ? 'primary' : null}
        width={1}
        onClick={handleSubmit}
      >
        <I18n text="auth.resetPassword" />
      </ButtonNew>
      <ErrorBlock>{error}</ErrorBlock>
    </>
  )
}

ResetFormFieldset.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
}

export default ResetFormFieldset
