import { createAsyncAction } from '../../helpers'
import apiCall from '../../services/Api'

export const LOAD_VIEWER = createAsyncAction('viewer/LOAD')
export const SET_JWT = 'viewer/SET_JWT'
export const SET_USER_TYPE = 'viewer/SET_USER_TYPE'
export const SET_SOURCE = 'viewer/SET_SOURCE'
export const SET_FIRSTNAME = 'viewer/SET_FIRSTNAME'
export const SET_REDIRECT_FINAL_STEP = 'viewer/SET_REDIRECT_FINAL_STEP'
export const SET_LASTNAME = 'viewer/SET_LASTNAME'
export const SET_PATH = 'viewer/SET_PATH'

export const SET_EMAIL = 'viewer/SET_EMAIL'
export const SET_ORGANIZATION = 'viewer/SET_ORGANIZATION'
export const CLEAR_ERRORS = 'viewer/CLEAR_ERRORS'
export const LOG_OUT = 'viewer/LOG_OUT'
export const SET_RESOURCE_ID = 'viewer/SET_RESOURCE_ID'
export const CLEAR_RESOURCE_ID = 'viewer/CLEAR_RESOURCE_ID'
export const SET_LESSONPLAN_ID = 'viewer/SET_LESSONPLAN_ID'
export const CLEAR_LESSONPLAN_ID = 'viewer/CLEAR_LESSONPLAN_ID'

export const loadViewer = () =>
  apiCall({
    endpoint:
      '/user?include=user_permissions,organization,organization.licensings,organization.licensings.license',
    query: {},
    types: LOAD_VIEWER,
  })

export const setJWT = (token, route) => {
  return {
    type: SET_JWT,
    payload: {
      token,
      route,
    },
  }
}
export const logOut = (redirect, redirectPath) => {
  return {
    type: LOG_OUT,
    redirect,
    redirectPath,
  }
}

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  }
}

export const setResourceId = resourceId => ({
  type: SET_RESOURCE_ID,
  resourceId,
})

export const clearResourceId = () => ({
  type: CLEAR_RESOURCE_ID,
})

export const setLessonPlanId = lessonId => ({
  type: SET_LESSONPLAN_ID,
  lessonId,
})

export const setUserType = userType => ({
  type: SET_USER_TYPE,
  userType,
})
export const setEmail = (email, redirect = true) => ({
  type: SET_EMAIL,
  email,
  redirect,
})
export const setFirstname = firstname => ({
  type: SET_FIRSTNAME,
  firstname,
})
export const setRedirectFinalStep = redirectFinalStep => ({
  type: SET_REDIRECT_FINAL_STEP,
  redirectFinalStep,
})
export const setLastname = lastname => ({
  type: SET_LASTNAME,
  lastname,
})
export const setPath = path => {
  return {
    type: SET_PATH,
    path,
  }
}
export const setSource = source => ({
  type: SET_SOURCE,
  source,
})
export const setOrganization = organization => ({
  type: SET_ORGANIZATION,
  organization,
})
export const clearLessonPlanId = () => ({
  type: CLEAR_LESSONPLAN_ID,
})
