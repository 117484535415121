import * as React from 'react'

function SvgLock(props) {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 16 20"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M13 6V5C13 2.23999 10.76 0 8 0C5.23999 0 3 2.23999 3 5V6H2C0.895431 6 0 6.89543 0 8V18C0 19.1046 0.895431 20 2 20H14C15.1046 20 16 19.1046 16 18V8C16 6.89543 15.1046 6 14 6H13ZM11.1 6V5C11.1 3.29004 9.70999 1.8999 8 1.8999C6.29001 1.8999 4.89999 3.29004 4.89999 5V6H11.1ZM10 13C10 14.1046 9.10457 15 8 15C6.89543 15 6 14.1046 6 13C6 11.8954 6.89543 11 8 11C9.10457 11 10 11.8954 10 13Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgLock
