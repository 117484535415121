import { getTranslation } from '@lyfta/components-i18n'
import { Subtitle2 } from '@lyfta/components-ui'
import PropTypes from 'prop-types'
import React from 'react'

import { ActivityH3 } from '../styles'

const Details = ({ activity: { id, intro, title } }) => {
  return (
    <Subtitle2 id={`div-activity-name-id-${id}`}>
      <ActivityH3
        source={intro ? getTranslation('quest.introduction') : title}
      />
    </Subtitle2>
  )
}

Details.propTypes = {
  activity: PropTypes.object.isRequired,
}

export default Details
