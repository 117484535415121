import { I18n } from '@lyfta/components-i18n'
import { ButtonNew, Modal } from '@lyfta/components-ui'
import { findIndex } from 'lodash'
import PT from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MascotFlag } from '../../../../Assets/Menu'
import { requestSave } from '../../../../Store/Actions/experiences'
import {
  getCurrentLesson,
  getCurrentLessonClassName,
} from '../../../../Store/Selectors/lessons'
import { DescriptionModal, SubtitleModal, TitleModal } from './_styles'

const Welcome = ({
  currentActivity,
  requestAdvanceActivity,
  selectTask,
  activities,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const description = currentActivity?.description
  const titleActivity = currentActivity?.title

  const dispatch = useDispatch()

  const currentLesson = useSelector(getCurrentLesson)
  const currentLessonClassName = useSelector(getCurrentLessonClassName)
  useEffect(() => {
    if (currentActivity) {
      if (!currentActivity.shown) {
        setIsOpen(true)
      }
    }
  }, [currentActivity])
  const onLetsGoClick = useCallback(async () => {
    setIsOpen(false)
    if (currentActivity) {
      if (window.sendUnity) {
        window.sendUnity('LyftaPlayer', 'TryResumeAudio')
      }

      // If there's no currentLesson then we cannot save anything
      if (currentLesson) {
        dispatch(
          requestSave({
            type: 'custom',
            verb: 'started',
            data: {
              time: new Date().toISOString(),
            },
          }),
        )
      }

      if (currentActivity?.tasks?.length > 0) {
        selectTask(currentActivity.tasks[0], true)
      } else {
        const currentActivityIndex = findIndex(
          activities,
          a => a.id === currentActivity.id,
        )
        requestAdvanceActivity(activities[currentActivityIndex + 1]?.id)
      }
    }
  }, [currentActivity, currentLesson])

  const renderCustomButtons = () => {
    return (
      <>
        <ButtonNew
          id="btn-start-quest"
          mt={3}
          mb={4}
          onClick={onLetsGoClick}
          default={{ maxHeight: '60px' }}
        >
          <I18n text="quest.letsGo" />
        </ButtonNew>
      </>
    )
  }
  return (
    <Modal
      animation={{
        startAnimation: {
          opacity: 1,
          top: '-100%',
          transform: 'scale(1)',
        },
        closeAnimation: {
          transitionDuration: 300,
          transitionTimingFunction: 'ease-in-out',
          top: '-100%',
        },
      }}
      hideClose
      isOpen={isOpen}
      showDefaultButtons={false}
      renderCustomButtons={renderCustomButtons}
      modalStyle={{
        wrapperContainer: {
          width: '600px',
          borderRadius: '30px',
          background:
            'linear-gradient(102.48deg, #FFFFFF 3.28%, rgba(255, 255, 255, 0.7) 95.96%), linear-gradient(94.8deg, rgba(69, 83, 104, 0.5) 3.84%, rgba(228, 231, 236, 0.5) 95.49%);',
        },
        titleTag: TitleModal,
        subtitleTag: SubtitleModal,
        descriptionTag: DescriptionModal,
        headerContainer: {
          paddingTop: '20px',
        },
        titleContainer: {
          justifyContent: 'center',
        },
        subtitleContainer: {
          justifyContent: 'center',
        },
      }}
      iconChatBubble={<MascotFlag width="220px" heigh="220px" />}
      shouldReturnFocus={false}
      content={{
        title: currentLessonClassName,
        subtitle: titleActivity,
        description,
      }}
    />
  )
}

Welcome.propTypes = {
  activities: PT.array.isRequired,
  currentActivity: PT.object.isRequired,
  requestAdvanceActivity: PT.func.isRequired,
  selectTask: PT.func.isRequired,
}

export default Welcome
