import * as React from 'react'

function SvgRocket(props) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 117 114"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M53.625 26.0774C43.0022 27.689 33.0955 32.294 25.126 39.3248C17.1566 46.3556 11.4727 55.5049 8.77502 65.6449C14.625 63.6024 20.4263 61.7499 26.52 59.6124C30.8588 58.1874 35.1975 56.8099 39.4388 55.5274C42.6862 45.0959 47.4644 35.1766 53.625 26.0774Z"
        fill="#3333CC"
      />
      <path
        d="M90.3826 61.75C88.7641 72.0896 84.0885 81.7457 76.9276 89.5375C69.6665 97.3426 60.2319 102.921 49.7738 105.592C51.8701 99.9875 53.9663 94.24 55.9651 88.35C57.4276 84.075 58.8413 79.8475 60.1576 75.715C65.3627 74.177 70.4462 72.2719 75.3676 70.015C80.5829 67.6397 85.6027 64.8765 90.3826 61.75Z"
        fill="#3333CC"
      />
      <path
        d="M43.2412 92.1974C37.245 98.0399 10.335 103.122 10.335 103.122C10.335 103.122 15.5512 76.8549 21.5475 71.2499C24.4307 68.4469 28.3386 66.8747 32.4116 66.8792C36.4845 66.8836 40.3888 68.4644 43.2656 71.2737C46.1424 74.083 47.7559 77.8907 47.7514 81.8592C47.7468 85.8277 46.1244 89.6319 43.2412 92.4349V92.1974Z"
        fill="#3333CC"
      />
      <path
        d="M105.788 11.9225C99.7071 9.51339 93.1921 8.31811 86.6288 8.4075C63.765 8.74 42.7538 27.93 30.9563 55.385C36.8169 57.6133 42.2295 60.829 46.9463 64.885C53.2583 70.361 58.1526 77.2131 61.2301 84.8825C65.8613 82.2225 106.275 63.0325 108.128 30.6375C108.488 24.3135 107.696 17.9772 105.788 11.9225ZM77.3176 47.88C75.2193 47.9083 73.1598 47.3279 71.401 46.2126C69.6422 45.0973 68.2636 43.4975 67.4404 41.6168C66.6173 39.736 66.3868 37.6591 66.7783 35.6504C67.1698 33.6416 68.1656 31.7917 69.6391 30.3359C71.1126 28.8801 72.9971 27.8841 75.0531 27.4747C77.1091 27.0653 79.2436 27.261 81.1851 28.0367C83.1267 28.8125 84.7875 30.1334 85.9565 31.8314C87.1255 33.5294 87.7498 35.5278 87.75 37.5725C87.7503 40.2817 86.6559 42.8821 84.7035 44.8111C82.7511 46.7401 80.0978 47.8425 77.3176 47.88Z"
        fill="#3333CC"
      />
    </svg>
  )
}

export default SvgRocket
