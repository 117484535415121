import React from 'react'

import ReactSelectForm from '../ReactSelectForm'

const ReactSelectFormMulti = props => {
  return (
    <ReactSelectForm
      extendStyles={{
        container: defaultStyles => ({
          ...defaultStyles,
          height: 'unset',
        }),
        multiValueLabel: defaultStyles => ({
          ...defaultStyles,
          fontSize: '100%',
        }),
      }}
      isMulti
      labelMargin="0 0 0 2px"
      wrapperMargin="auto 8px auto 14px"
      {...props}
    />
  )
}

export default ReactSelectFormMulti
