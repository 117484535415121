import { styled } from '@lyfta/components-theme'
import { Box, Flex } from '@lyfta/components-ui'

export const Container = styled(Box)`
  height: 100vh;
  background-color: rgb(25, 25, 25);
`

export const Main = styled(Flex)`
  height: 100%;
`

export const Content = styled(Box)`
  flex: 1;
  overflow-y: auto;
  height: 100vh;
  max-width: 100vw;
  align-self: stretch;
`
