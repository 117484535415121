import { createLoadHandler, createReducer } from '@lyfta/components-data'
import { LOG_OUT } from '@lyfta/components-data/src/Store/Actions/viewer'

import { LOAD_EXPERIENCES, SAVE_EXPERIENCE } from '../Actions/experiences'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  experiences: [],
  size: 100,
  number: 1,
  total: 0,
  currentLesson: null,
}

const humanizeError = (type, error) => {
  switch (type) {
    case LOAD_EXPERIENCES.FAILURE: {
      if (error.status === 401) {
        return 'User is not authorized'
      }
      return null
    }

    default:
      return null
  }
}

const handleFailure = (state, { type, error }) =>
  state.merge({
    isLoading: false,
    isLoaded: false,
    error: humanizeError(type, error),
  })

const handleRequest = state =>
  state.merge({
    error: null,
    isLoading: true,
    isLoaded: false,
    currentLesson: {},
  })

const handlers = {
  [LOAD_EXPERIENCES.REQUEST]: handleRequest,
  [LOAD_EXPERIENCES.SUCCESS]: createLoadHandler('experiences'),
  [LOAD_EXPERIENCES.FAILURE]: handleFailure,
  [SAVE_EXPERIENCE.REQUEST]: handleRequest,
  [SAVE_EXPERIENCE.SUCCESS]: createLoadHandler('experiences'),
  [SAVE_EXPERIENCE.FAILURE]: handleFailure,
  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers, {
  resetOn: [LOG_OUT],
})
