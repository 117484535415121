import * as React from 'react'

function SvgSettings(props) {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.25001 20L7.85001 16.8C7.63335 16.7167 7.42935 16.6167 7.23801 16.5C7.04601 16.3833 6.85835 16.2583 6.67501 16.125L3.70001 17.375L0.950012 12.625L3.52501 10.675C3.50835 10.5583 3.50001 10.4457 3.50001 10.337C3.50001 10.229 3.50001 10.1167 3.50001 10C3.50001 9.88333 3.50001 9.77067 3.50001 9.662C3.50001 9.554 3.50835 9.44167 3.52501 9.325L0.950012 7.375L3.70001 2.625L6.67501 3.875C6.85835 3.74167 7.05001 3.61667 7.25001 3.5C7.45001 3.38333 7.65001 3.28333 7.85001 3.2L8.25001 0H13.75L14.15 3.2C14.3667 3.28333 14.571 3.38333 14.763 3.5C14.9543 3.61667 15.1417 3.74167 15.325 3.875L18.3 2.625L21.05 7.375L18.475 9.325C18.4917 9.44167 18.5 9.554 18.5 9.662C18.5 9.77067 18.5 9.88333 18.5 10C18.5 10.1167 18.5 10.229 18.5 10.337C18.5 10.4457 18.4833 10.5583 18.45 10.675L21.025 12.625L18.275 17.375L15.325 16.125C15.1417 16.2583 14.95 16.3833 14.75 16.5C14.55 16.6167 14.35 16.7167 14.15 16.8L13.75 20H8.25001ZM11.05 13.5C12.0167 13.5 12.8417 13.1583 13.525 12.475C14.2083 11.7917 14.55 10.9667 14.55 10C14.55 9.03333 14.2083 8.20833 13.525 7.525C12.8417 6.84167 12.0167 6.5 11.05 6.5C10.0667 6.5 9.23735 6.84167 8.56201 7.525C7.88735 8.20833 7.55001 9.03333 7.55001 10C7.55001 10.9667 7.88735 11.7917 8.56201 12.475C9.23735 13.1583 10.0667 13.5 11.05 13.5Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgSettings
