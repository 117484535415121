import { createLoadHandler, createReducer } from '@lyfta/components-data'

import { LOAD_LESSON_PLAN } from '../Actions/lessonPlans'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  lessonPlans: [],
  lessonPlanTemplates: [],
  size: 100,
  number: 1,
  total: 0,
  currentLessonPlan: null,
  isDuplicating: false,
}

// this should be refactored out when new @lyfta/components-lesson-plan is available
// this is MVP for lessonPlan preview
const humanizeError = () => null

const handleFailure = (state, { type, error }) =>
  state.merge({
    isLoading: false,
    isLoaded: false,
    error: humanizeError(type, error),
  })

const handleRequest = state =>
  state.merge({
    error: null,
    isLoading: true,
    isLoaded: false,
    currentLessonPlan: {},
  })

const handlers = {
  [LOAD_LESSON_PLAN.REQUEST]: handleRequest,
  [LOAD_LESSON_PLAN.SUCCESS]: (state, action) =>
    createLoadHandler('lessonPlans', {
      mapToKey: 'currentLessonPlan',
      singular: true,
      withReplace: true,
    })(state, action),
  [LOAD_LESSON_PLAN.FAILURE]: handleFailure,
}

export default createReducer(initialState, handlers)
