import { find, map } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Field } from 'react-final-form'

import {
  Container,
  Description,
  Label,
  Opener,
  Select,
  Value,
  Wrapper,
} from './styles'

const renderField = ({
  options,
  description,
  disabled,
  input,
  meta,
  label,
  placeholder,
  focused,
  onBlur,
  onFocus,
  emptyOption,
  id,
  defaultOption,
  ...rest
}) => {
  const active = true
  const optionsIsArray = Array.isArray(options)

  let valueDisplay = null

  if (optionsIsArray) {
    if (input?.value) {
      valueDisplay = find(options, ['id', input?.value])?.value
    } else {
      valueDisplay = find(options, ['id', defaultOption])?.value
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (defaultOption) {
      if (input?.value) {
        valueDisplay =
          options[
            Object.keys(options).find(key => options[key] === input?.value)
          ]
      } else {
        valueDisplay =
          options[
            Object.keys(options).find(key => options[key] === defaultOption)
          ]
      }
    } else {
      valueDisplay = options[input?.value]
    }
  }

  return (
    <Wrapper {...rest}>
      <Container
        active={active}
        disabled={disabled}
        error={meta.touched && meta.error ? 1 : 0}
      >
        {label && <Label active={active}>{label}</Label>}
        <Select
          {...input}
          active={active}
          disabled={disabled}
          hasValue={!!valueDisplay}
          id={id}
          placeholder={placeholder}
          onBlur={onBlur}
          onFocus={onFocus}
        >
          {
            // eslint-disable-next-line
            emptyOption && <option key="default" value="" />
          }
          {!optionsIsArray &&
            map(options, (value, key) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          {optionsIsArray &&
            map(options, ({ id: key, value, props = {} }) => (
              <option key={key} value={key} {...props}>
                {value}
              </option>
            ))}
        </Select>
        <Value disabled={disabled}>{valueDisplay}</Value>
        <Opener active={active} disabled={disabled} />
      </Container>
      {description && <Description active={active}>{description}</Description>}
    </Wrapper>
  )
}

renderField.defaultProps = {
  emptyOption: false,
  disabled: false,
  id: '',
  defaultOption: '',
}

renderField.propTypes = {
  defaultOption: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  disabled: PropTypes.bool,
  emptyOption: PropTypes.bool,
  focused: PropTypes.bool.isRequired,
  id: PropTypes.string,
  input: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
}

class AnimatedSelect extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      focused: false,
    }
  }

  handleFocus = () => {
    this.setState({ focused: true })
  }

  handleBlur = () => {
    this.setState({ focused: false })
  }

  render() {
    const {
      label,
      name,
      placeholder,
      options,
      description,
      ...rest
    } = this.props
    const { focused } = this.state
    return (
      <Field
        description={description}
        focused={focused}
        label={label}
        name={name}
        options={options}
        placeholder={placeholder}
        render={renderField}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        {...rest}
      />
    )
  }
}

AnimatedSelect.defaultProps = {
  label: '',
  placeholder: '',
  description: '',
}

AnimatedSelect.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  placeholder: PropTypes.string,
}

export default AnimatedSelect
