import {
  getAllEntities,
  getEntity,
  getEntityStateField,
} from '@lyfta/components-data'
import { getTranslatedField } from '@lyfta/components-i18n'
import { createSelector } from 'reselect'

export const getTagGroups = getAllEntities('tagGroups')
export const getCurrentTagGroup = getEntity('tagGroups')

export const getTagGroupName = createSelector(getCurrentTagGroup, tagGroup => {
  return getTranslatedField(tagGroup, 'name')
})

export const getIsLoaded = getEntityStateField('tagGroups', 'isLoaded')
export const getIsLoading = getEntityStateField('tagGroups', 'isLoading')
export const getPaged = getEntityStateField('tagGroups', 'paged')
