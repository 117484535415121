import 'react-table/react-table.css'

import React, { PureComponent } from 'react'

import Pagination from './Pagination'
import { StyledTable } from './styles'

class Table extends PureComponent {
  render() {
    const { ...rest } = this.props
    return (
      <StyledTable
        PaginationComponent={Pagination}
        minRows={0}
        resizable={false}
        {...rest}
      />
    )
  }
}

export default Table
