import indexOf from 'lodash/indexOf'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'

export const nullifyIfIncludes = (ids, value) =>
  indexOf(ids, value) !== -1 ? null : value

export const getFirstEntity = entities => {
  const entityId = Object.keys(entities)[0]
  return entities[entityId]
}

export const keepSortByKey = (meta, data, key) => {
  const endpoint = getFirstEntity(meta)
  const idsOrder = map(endpoint.data, key)
  return sortBy(data, item => idsOrder.indexOf(item.id))
}

// Getting the highest or lowest date of an array of object and return the object
// target = max || min
export const getMaxMinDateObject = ({
  items,
  column = 'date',
  target = 'max',
}) => {
  let objectSelected = {}
  if (items) {
    items.forEach(item => {
      const d1 = Date.parse(item[column])
      const d2 = Date.parse(objectSelected[column])
      if (target === 'max') {
        if (!objectSelected[column]) {
          objectSelected = item
        } else if (d2 < d1) {
          objectSelected = item
        }
      } else if (!objectSelected[column]) {
        objectSelected = item
      } else if (d2 > d1) {
        objectSelected = item
      }
    })
  }
  return objectSelected
}

export const truncate = (str, max = 3) => {
  const array = str.trim().split(' ')
  const ellipsis = array.length > max ? '...' : ''

  return array.slice(0, max).join(' ') + ellipsis
}

export const getCustomSuccessMessage = ({
  customSuccessMessage,
  initialValues,
  name,
  state,
}) => {
  if (typeof customSuccessMessage === 'string') {
    return customSuccessMessage
  }
  if (!initialValues) {
    return null
  }
  let updatedMessage = {}

  if (name && initialValues.name !== name) {
    updatedMessage = {
      ...updatedMessage,
      saveMessage: customSuccessMessage.saveMessage,
    }
  }
  if (state && initialValues.state?.value !== state) {
    updatedMessage = {
      ...updatedMessage,
      statusMessage: customSuccessMessage[`${state}Message`],
    }
  }
  return updatedMessage
}
