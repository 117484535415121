import {
  Box,
  ButtonNew,
  Flex,
  Text,
  gtWalsheimAndFallbackFontFamily,
  styled,
} from '@lyfta/components-ui'
import { StyleableImage } from '@lyfta/components-ui/src/BaseComponents'
import { LogoutItem } from '@lyfta/components-ui/src/Layouts/StructuredLayout/Sidebar/logout'

import {
  LessonsScreen as BaseLessonsScreen,
  LessonInfoText,
  LessonText,
} from './_styles'
import { LogoRectangle } from './LogoRectangle'
import { LessonButton, LessonInfo, LessonsContainer } from './styles'

export const HomeHeader = styled(Flex).attrs({
  flexDirection: 'column',
})`
  margin-left: 78px;
  margin-right: 78px;
  font-family: ${gtWalsheimAndFallbackFontFamily};
`
export const HomeStudentLogoutItem = styled(LogoutItem)`
  height: 52px;
  width: 163.64537048339844px;
  left: 1196px;
  top: 49px;
  border-radius: 50px;
  padding: 16px, 32px, 16px, 32px;
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 4px 0px #ffffff40;
`
export const HomeMainTitle = styled(Text).attrs({
  lineHeight: '45px',
  fontSize: '30px',
  color: 'white',
  textAlign: 'left',
  mb: '15px',
  fontWeight: 'bold',
})`
  margin-top: 1em;
`
export const HomeStudentLyftaLogo = styled(LogoRectangle).attrs(() => ({
  viewBox: '0 0 131 184',
}))`
  height: 58px;
  width: 44px;
  border-radius: 0px;
  transform-origin: left top;
  transform: scale(106%, 100%);
`
export const HomeSubTitle = styled(Text).attrs({
  lineHeight: '40px',
  fontSize: '25px',
  color: 'white',
  textAlign: 'left',
  mb: '10px',
})`
  margin-top: 1em;
  margin-bottom: 1em;
`
export const HomeLessonsBackground = styled(Flex).attrs({})`
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: #050b15;
  flex-direction: column;
`
export const HomeLessonsScreen = styled(BaseLessonsScreen).attrs({})`
  max-height: 100%;
`
export const HomeLessonsContainer = styled(LessonsContainer)`
  margin-top: initial;
  margin-bottom: initial;
  overflow: initial;
  overflow-y: initial;
`

const imageScale = 1.4

export const ImageContainer = styled(Box)`
  width: ${300 * imageScale}px;
  max-width: 45%;
  position: relative;
  margin: 0;
  min-height: 100%;
`

export const HomeLessonButton = styled(LessonButton)`
  width: 100%;
  max-width: initial;
  display: flex;
  height: ${190 * imageScale}px;
  overflow: hidden;
  border: 0;
  align-items: center;
  min-width: 360px;
  transition: all 0.2s;
  ${ImageContainer} {
    transition: all 0.2s;
  }

  font-family: ${gtWalsheimAndFallbackFontFamily};
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;

  &:hover {
    width: 105%;
    margin-left: -2.5%;
  }
`
export const PosterImage = styled(StyleableImage)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`
export const HomeLessonInfo = styled(LessonInfo)`
  flex: 1;
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
`
export const HomeLessonInfoText = styled(LessonInfoText)`
  flex: 1;
  overflow: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 15px;
`

export const HomeLessonTextContainer = styled(Box)`
  flex: 1;
  width: 100%;
`

export const HomeLessonButtonContainer = styled(Box)`
  display: flex;
  width: 100%;
`

export const HomeLessonLastPlayedText = styled(Box)`
  font-size: 0.8em;
  margin-top: auto;
`

export const HomeLessonPageHeader = styled(Box)`
  display: flex;
  width: 100%;
  padding-left: 78px;
  padding-right: 78px;
  padding-top: 49px;
`

export const HomeLessonButtonSpacer = styled(Box)`
  flex: 1;
`

export const LessonSelectorMenuRight = styled(Flex)``

export const HomeLessonText = styled(LessonText)``

export const HomeStudentLogoutButton = styled(LogoutItem)`
  border: 2px solid white;
  height: 52px;
  border-radius: 26px;
  width: 170px;

  span {
    font-weight: bold;
  }
`

export const HomeStudentButton = styled(ButtonNew)`
  height: 52px;
  border-radius: 26px;
  width: 170px;
  text-align: center;
  align-content: center;
  align-items: center;
  font-weight: bold;
  display: block;
  transition: none;
  background: #2e34ce;
  box-shadow: 0px 4px 4px 0px #3333cc40;

  &[data-inverted='true'] {
    border: 2px solid #2e34ce;
    box-shadow: 0px 8px 24px 0px #2e34ce40;
    background: white;
    color: #2e34ce;

    &:hover {
      background: #5164d6;
      box-shadow: 0px 8px 24px 0px #5164d640;
      color: #fafafa;
    }

    &:active {
      background: #5164d6;
      border: 2px solid #2e34ce;
      color: #fafafa;
      box-shadow: none;
    }
  }

  &:hover {
    border: 2px solid #2e34ce;
    background: #5164d6;
    box-shadow: 0px 8px 24px 0px #5164d640;
  }

  &:active {
    background: #5164d6;
    border: 2px solid #2e34ce;
    box-shadow: none;
  }
`

export const HomeLessonRowContainer = styled(Box)`
  width: 100%;
  padding-left: 78px;
  padding-right: 78px;
`

HomeHeader.displayName = 'HomeHeader'
HomeMainTitle.displayName = 'HomeMainTitle'
HomeLessonsScreen.displayName = 'HomeLessonsScreen'
HomeLessonsContainer.displayName = 'HomeLessonsContainer'
HomeLessonButton.displayName = 'HomeLessonButton'
ImageContainer.displayName = 'ImageContainer'
PosterImage.displayName = 'PosterImage'
HomeLessonInfo.displayName = 'HomeLessonInfo'
HomeLessonTextContainer.displayName = 'HomeLessonTextContainer'
HomeLessonButtonContainer.displayName = 'HomeLessonButtonContainer'
HomeLessonButtonSpacer.displayName = 'HomeLessonButtonSpacer'
LessonSelectorMenuRight.displayName = 'LessonSelectorMenuRight'
HomeLessonText.displayName = 'HomeLessonText'
