import { Iframe as IframeComm } from '@lyfta/components-ui'
import { useLocaleAwareArticleUrl } from '@lyfta/components-ui/src/Helpers/useLocaleAwareArticleUrl'
import PropTypes from 'prop-types'
import React from 'react'

export function LocaleAwareIframe({
  attributes,
  handleReady,
  handleReceiveMessage,
  postMessageData,
  locale,
}) {
  const wantedUrl = useLocaleAwareArticleUrl(attributes.src, locale)

  if (!wantedUrl) {
    return null
  }

  return (
    <IframeComm
      attributes={{ ...attributes, src: wantedUrl }}
      handleReady={handleReady}
      handleReceiveMessage={handleReceiveMessage}
      postMessageData={postMessageData}
      targetOrigin="*"
    />
  )
}

LocaleAwareIframe.propTypes = {
  attributes: PropTypes.shape({
    frameBorder: PropTypes.number,
    src: PropTypes.any,
    width: PropTypes.string,
    className: PropTypes.string,
    height: PropTypes.string,
  }).isRequired,
  handleReady: PropTypes.func.isRequired,
  handleReceiveMessage: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  postMessageData: PropTypes.string.isRequired,
}
