import * as React from 'react'

function SvgTrophy(props) {
  return (
    <svg height="1em" viewBox="0 0 110 106" width="1em" {...props}>
      <defs>
        <filter
          filterUnits="objectBoundingBox"
          height="110.7%"
          id="trophy_svg__b"
          width="105.5%"
          x="-2.7%"
          y="-5.4%"
        >
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner1"
            stdDeviation={3}
          />
          <feOffset in="shadowBlurInner1" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2={-1}
            k3={1}
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.674847147 0"
          />
        </filter>
        <filter
          filterUnits="objectBoundingBox"
          height="308.2%"
          id="trophy_svg__d"
          width="182.4%"
          x="-41.2%"
          y="-104.1%"
        >
          <feGaussianBlur in="SourceGraphic" stdDeviation={4} />
        </filter>
        <filter
          filterUnits="objectBoundingBox"
          height="114.8%"
          id="trophy_svg__h"
          width="116.6%"
          x="-8.3%"
          y="-7.4%"
        >
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner1"
            stdDeviation={6}
          />
          <feOffset in="shadowBlurInner1" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2={-1}
            k3={1}
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.876585145 0"
          />
        </filter>
        <filter
          filterUnits="objectBoundingBox"
          height="307%"
          id="trophy_svg__i"
          width="153.2%"
          x="-26.6%"
          y="-103.5%"
        >
          <feGaussianBlur in="SourceGraphic" stdDeviation={8} />
        </filter>
        <filter
          filterUnits="objectBoundingBox"
          height="160%"
          id="trophy_svg__n"
          width="107.1%"
          x="-3.6%"
          y="-30%"
        >
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner1"
            stdDeviation={2}
          />
          <feOffset dy={2} in="shadowBlurInner1" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2={-1}
            k3={1}
            operator="arithmetic"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            in="shadowInnerInner1"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.791751585 0"
          />
        </filter>
        <linearGradient
          id="trophy_svg__g"
          x1="10.43%"
          x2="78.96%"
          y1="13.21%"
          y2="81.49%"
        >
          <stop offset="0%" stopColor="#FFF" stopOpacity={0.89} />
          <stop offset="100%" stopOpacity={0.5} />
        </linearGradient>
        <linearGradient
          id="trophy_svg__k"
          x1="53.81%"
          x2="42.09%"
          y1="21.37%"
          y2="63.34%"
        >
          <stop offset="0%" stopColor="#FFF" />
          <stop offset="100%" stopColor="#FFF" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="trophy_svg__m"
          x1="0%"
          x2="100%"
          y1="49.29%"
          y2="50%"
        >
          <stop offset="0%" stopColor="#CCC" stopOpacity={0.5} />
          <stop offset="61.06%" stopColor="#FFF" stopOpacity={0.5} />
          <stop offset="100%" stopOpacity={0.5} />
        </linearGradient>
        <linearGradient
          id="trophy_svg__o"
          x1="49.92%"
          x2="49.92%"
          y1=".01%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#FAC989" />
          <stop offset="100%" stopColor="#E77307" />
        </linearGradient>
        <path
          d="M0 19h110c0 30.928-24.624 56-55 56C24.928 75 .493 50.427.007 19.926L0 19h110zm8.925 7.723C11.593 49.267 31.204 66.77 55 66.77c23.796 0 43.407-17.503 46.075-40.047z"
          id="trophy_svg__a"
        />
        <path d="M0 0h12v28H0z" id="trophy_svg__c" />
        <path
          d="M72.854.74v45.075c0 19.754-15.844 35.805-35.521 36.124l-.598.005c-19.948 0-36.12-16.17-36.12-36.129V.74h72.24z"
          id="trophy_svg__f"
        />
        <path
          d="M13 5c0-2.761 2.247-5 5.003-5h73.994A5.005 5.005 0 0197 5c0 2.761-2.247 5-5.003 5H18.003A5.005 5.005 0 0113 5z"
          id="trophy_svg__l"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#FBA021" xlinkHref="#trophy_svg__a" />
        <use
          fill="#000"
          filter="url(#trophy_svg__b)"
          xlinkHref="#trophy_svg__a"
        />
        <g transform="translate(49 78)">
          <mask fill="#fff" id="trophy_svg__e">
            <use xlinkHref="#trophy_svg__c" />
          </mask>
          <use fill="#FF8E16" xlinkHref="#trophy_svg__c" />
          <path
            d="M-6.86 11.53h29.14v11.53H-6.86z"
            fill="#F07D42"
            filter="url(#trophy_svg__d)"
            mask="url(#trophy_svg__e)"
            style={{
              mixBlendMode: 'overlay',
            }}
          />
        </g>
        <g transform="translate(18 2)">
          <mask fill="#fff" id="trophy_svg__j">
            <use xlinkHref="#trophy_svg__f" />
          </mask>
          <use fill="#FFB820" xlinkHref="#trophy_svg__f" />
          <use
            fill="url(#trophy_svg__g)"
            fillOpacity={0.8}
            style={{
              mixBlendMode: 'overlay',
            }}
            xlinkHref="#trophy_svg__f"
          />
          <use
            fill="#000"
            filter="url(#trophy_svg__h)"
            xlinkHref="#trophy_svg__f"
          />
          <path
            d="M-6.56-8.28h90.22v23.19H-6.56z"
            fill="#D2691B"
            filter="url(#trophy_svg__i)"
            mask="url(#trophy_svg__j)"
            opacity={0.3}
            style={{
              mixBlendMode: 'overlay',
            }}
          />
          <path
            d="M70.05-6.6L-35.81 118.82l9.3-143.67z"
            fill="url(#trophy_svg__k)"
            mask="url(#trophy_svg__j)"
            opacity={0.2}
            style={{
              mixBlendMode: 'overlay',
            }}
          />
        </g>
        <use fill="#FFC155" xlinkHref="#trophy_svg__l" />
        <use
          fill="url(#trophy_svg__m)"
          fillOpacity={0.6}
          style={{
            mixBlendMode: 'overlay',
          }}
          xlinkHref="#trophy_svg__l"
        />
        <use
          fill="#000"
          filter="url(#trophy_svg__n)"
          xlinkHref="#trophy_svg__l"
        />
        <path
          d="M42.815 91c-1.115 0-2.605.657-3.351 1.467l-9.076 9.854c-1.87 2.032-1.152 3.68 1.607 3.68h46.01c2.765 0 3.557-1.717 1.783-3.833l-8.069-9.632C71.005 91.684 69.537 91 68.431 91H42.815z"
          fill="url(#trophy_svg__o)"
        />
        <path
          d="M41.327 59.924l.37-2.184 1.547-9.014c.085-.49.184-.98.243-1.473a.58.58 0 00-.145-.427c-2.162-2.101-4.334-4.193-6.504-6.286-.788-.76-1.578-1.52-2.365-2.281-.387-.375-.586-.814-.406-1.35.186-.553.612-.811 1.177-.891 3.92-.557 7.839-1.128 11.763-1.66.525-.07.799-.257 1.034-.733 1.794-3.631 3.626-7.244 5.44-10.865.214-.427.488-.75 1.012-.76.438-.008.776.168.975.559.448.879.888 1.762 1.33 2.644 1.475 2.955 2.949 5.91 4.431 8.862.046.09.168.187.266.202 2.892.424 5.785.838 8.677 1.255 1.224.176 2.446.359 3.67.536.55.08.933.375 1.098.895.16.504-.011.94-.384 1.3a7996.612 7996.612 0 01-5.45 5.262c-1.148 1.108-2.294 2.219-3.452 3.318-.158.15-.151.298-.121.473l1.026 5.98c.364 2.115.73 4.23 1.092 6.346.078.458-.061.84-.44 1.125-.404.303-.827.313-1.267.084l-4.868-2.53c-2.104-1.095-4.21-2.187-6.31-3.289-.19-.1-.332-.091-.516.005-3.686 1.928-7.374 3.849-11.062 5.772-.69.36-1.324.234-1.672-.352-.102-.172-.14-.381-.19-.523"
          fill="#FFEEB4"
        />
      </g>
    </svg>
  )
}

export default SvgTrophy
