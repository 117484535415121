import { styled } from '@lyfta/components-theme'
import { Flex, TextArea } from '@lyfta/components-ui'
import { themeGet as theme } from '@styled-system/theme-get'

export const DiscussionText = styled(TextArea)`
  width: 100%;
  border: 2px solid red;
  padding: ${theme('spacingSmaller')};
  border: 1px solid #979797;
`
export const CheckContainer = styled(Flex).attrs({
  flex: 1,
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
  mt: 3,
})``
