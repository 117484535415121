import {
  color,
  layout,
  space,
  styled,
  typography,
} from '@lyfta/components-theme'
import noop from 'lodash/noop'
import PT from 'prop-types'
import React from 'react'

const TextArea = styled.textarea`
  ${typography}
  ${color}
  ${layout}
  ${space}
`

const NormalizedTextArea = ({ onChange, onChangeText, ...rest }) => {
  const handleChange = e => {
    const { value } = e.target
    if (onChange) onChange(e)
    if (onChangeText) onChangeText(value)
  }

  return <TextArea {...rest} onChange={handleChange} />
}

NormalizedTextArea.defaultProps = {
  onChange: noop,
  onChangeText: noop,
}
NormalizedTextArea.propTypes = {
  onChange: PT.func,
  onChangeText: PT.func,
}

export default NormalizedTextArea
