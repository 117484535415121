import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Portal } from 'react-portal'

import { Flex } from '../../../BaseComponents'

export const PageTitlePortal = ({ title, icon }) => (
  <>
    {/* TODO: Re-enable Page title setting */}
    {/* Disabling Helmet temporarily to maintain teacher app state */}
    {false && (
      <Helmet>
        <title>Lyfta | {title}</title>
      </Helmet>
    )}
    <Portal node={document && document.getElementById('page-title')}>
      <React.Fragment key="page-title-portal">
        <Flex alignItems="center" height="100%">
          <span>{title}</span>
          {icon && <span>{icon}</span>}
        </Flex>
      </React.Fragment>
    </Portal>
  </>
)
PageTitlePortal.defaultProps = {
  icon: <></>,
}
PageTitlePortal.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string.isRequired,
}
