import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'

import { SpeedSelector } from './speedSelector'
import {
  PageBackIconContainer,
  PrevPageButton,
  PrevPageButtonContainer,
  SettingsContainer,
} from './styles'

export const SpeedPage = ({ player, setCurrentPage }) => {
  return (
    <>
      <PrevPageButtonContainer>
        <PrevPageButton
          className="subtitle-customise-back-button"
          onClick={() => setCurrentPage('customise')}
        >
          <PageBackIconContainer />
          <I18n i18nKey="videoPlayer.labels.speedLabel" />
        </PrevPageButton>
      </PrevPageButtonContainer>
      <SettingsContainer className="settings-container">
        <SpeedSelector player={player} />
      </SettingsContainer>
    </>
  )
}

SpeedPage.propTypes = {
  player: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
}
