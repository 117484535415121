import {
  createDeleteReducerHandlers,
  createReducerHandlers,
} from '../../../utils'
import {
  CREATE_TEACHER_USER,
  DELETE_TEACHER_USER,
  LOAD_TEACHER_USER,
  LOAD_TEACHER_USERS,
  UPDATE_TEACHER_USER,
} from '../../Actions/users'

export default {
  ...createDeleteReducerHandlers('teachers', DELETE_TEACHER_USER, {
    mapToKey: 'teacherUsers',
  }),
  ...createReducerHandlers('teachers', LOAD_TEACHER_USERS, {
    withReplace: true,
    mapToKey: 'teacherUsers',
  }),
  ...createReducerHandlers('teachers', LOAD_TEACHER_USER, {
    singular: true,
    mapToKey: 'currentTeacherUser',
  }),
  ...createReducerHandlers('teachers', CREATE_TEACHER_USER, {
    singular: true,
    mapToKey: 'currentTeacherUser',
  }),
  ...createReducerHandlers('teachers', UPDATE_TEACHER_USER, {
    singular: true,
    mapToKey: 'currentTeacherUser',
  }),
}
