import { styled } from '@lyfta/components-theme'
import { AnimatedFlex, Icon } from '@lyfta/components-ui'
import { useAnimation } from 'framer-motion'
import PT from 'prop-types'
import React, { useEffect } from 'react'

import { taskIcons } from './taskIcons'

const CssTabIcon = styled(Icon).attrs(p => {
  let glyph = taskIcons.icon_locked
  if (p.task.complete && p.unlocked) {
    glyph = taskIcons.icon_tick_selected
  }
  if (!p.task.complete && p.unlocked) {
    glyph = taskIcons[`icon_${p.task.actionType.toLowerCase()}`]
  }
  return {
    glyph,
    width: 32,
    height: 32,
  }
})``

export const TabIcon = ({
  task,
  task: { selected, open },
  taskId,
  ...props
}) => {
  const iconControls = useAnimation()
  const iconAnimation = {
    animate: iconControls,
  }
  useEffect(() => {
    if (selected && !open) {
      iconControls.start({
        scale: [1, 1.2, 1.2, 1, 1],
        rotate: [0, 0, 20, -20, 0],
        transition: { flip: 3 },
      })
    } else if (!open) {
      iconControls.stop()
      iconControls.start({
        scale: 1,
        rotate: 0,
      })
    }
  }, [selected, open])

  return (
    <AnimatedFlex animation={iconAnimation}>
      <CssTabIcon task={task} {...props} />
    </AnimatedFlex>
  )
}

TabIcon.propTypes = {
  first: PT.bool.isRequired,
  last: PT.bool.isRequired,
  task: PT.object.isRequired,
  taskId: PT.oneOfType([PT.number, PT.string]).isRequired,
}
