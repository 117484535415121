import { get, noop, trim } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import validate from 'validate.js'

import {
  emailConstraint,
  passwordConstraint,
  usernameConstraint,
} from '../../../../Constants/constraints'
import { LyftaForm } from '../../../Form'
import { Container } from '../../styles'
import emailFormFields from './formWithEmail'
import usernameFormFields from './formWithUsername'

const handleEmailEntry = (args, state, utils) => {
  const email = get(state, 'formState.values.email', '')
  utils.changeValue(state, 'email', () => trim(email.toLowerCase()))
}

const SignInForm = ({
  onSubmit,
  initialValues,
  customButton,
  allowUsername,
  backgroundColorLogin,
}) => {
  const validateForm = values =>
    validate(values, {
      ...(allowUsername ? usernameConstraint : emailConstraint),
      ...passwordConstraint,
    })

  const mutators = useMemo(() => {
    return allowUsername ? {} : { handleEmailEntry }
  }, [allowUsername])

  return (
    <Container>
      <LyftaForm
        backgroundColorLogin={backgroundColorLogin}
        customButton={customButton}
        fields={allowUsername ? usernameFormFields : emailFormFields}
        initialValues={initialValues}
        mutators={mutators}
        noWrap
        validate={validateForm}
        withoutHeader
        onSubmit={onSubmit}
      />
    </Container>
  )
}

SignInForm.defaultProps = {
  allowUsername: false,
  backgroundColorLogin: false,
  customButton: noop,
  initialValues: {},
}

SignInForm.propTypes = {
  allowUsername: PropTypes.bool,
  backgroundColorLogin: PropTypes.string,
  customButton: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
}
export default SignInForm
