import React from 'react'

function SvgZoomIn(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <path d="M36 18.5H8V25.5H36V18.5Z" fill="#050B15" />
      <path d="M25.5 8H18.5V36H25.5V22V8Z" fill="#050B15" />
    </svg>
  )
}

export default SvgZoomIn
