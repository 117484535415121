import { styled, themeGet } from '@lyfta/components-theme'
import { AnimatedAbsoluteFlex, Flex, TabTitle } from '@lyfta/components-ui'

export const Container = styled(AnimatedAbsoluteFlex).attrs({
  flexDirection: 'column',
  left: 'auto',
  zIndex: 1001,
})`
  border-radius: 30px 0 0 30px;
  width: 450px;
  overflow: scroll;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
`

export const ActivityList = styled(Flex).attrs({
  flex: 1,
  flexDirection: 'column',
})`
  backdrop-filter: blur(20px);
  background: ${themeGet('colors.sa_gradient_h')};
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`

ActivityList.displayName = 'ActivityList'

export { TabTitle }
