/* eslint-disable */
import { SEARCH_TABS } from '@lyfta/components-content/src/Constants/contents'
import { I18n } from '@lyfta/components-i18n'
import { ArrowLeft, ArrowRight, Flex, Icon } from '@lyfta/components-ui'
import { reactEvent } from '@lyfta/components-ui/src/Services/Utils'
import paths from 'Constants/paths'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import Skeleton from 'react-loading-skeleton'

import { RibbonRowTitle } from './RibbonRowTitle'
import {
  ButtonAngleLeft,
  ButtonAngleRight,
  Container,
  ContainerWrapper,
  DescriptionEmpty,
  EmptyLessonContainer,
  Label,
  Row,
  RowContainer,
  ShowAll,
  StyledFaAngleLeft,
  StyledFaAngleRight,
  Subtitle,
  Title,
  TitleEmpty,
} from './styles'

class RibbonRow extends PureComponent {
  constructor(props) {
    super(props)
    this.scrollRef = null
  }

  onReactGAEventShowAll = () => {
    const { showAllGAEvent } = this.props

    if (showAllGAEvent) reactEvent({ action: showAllGAEvent })
  }

  addScrollRef = ref => {
    this.scrollRef = ref
  }

  sideScroll = direction => e => {
    e.stopPropagation()

    const speed = 10
    const distance = 312
    const step = distance / 30
    let scrollAmount = 0

    const slideTimer = setInterval(() => {
      if (direction === 'left') {
        this.scrollRef.scrollLeft -= step
      } else {
        this.scrollRef.scrollLeft += step
      }
      scrollAmount += step
      if (scrollAmount >= distance) {
        clearInterval(slideTimer)
      }
    }, speed)
  }

  getPathByType = () => {
    const { showAllTitle } = this.props
    const searchTab = SEARCH_TABS.find(obj => {
      return obj.type === showAllTitle
    })
    return searchTab?.path
  }

  renderTitle = () => {
    const { title, anchor, titleTag, isLoading } = this.props

    if (title && isLoading)
      return (
        <Skeleton
          width={400}
          height={22}
          style={{ marginLeft: '16px', marginTop: '10px' }}
        />
      )
    return (
      title && (
        <RibbonRowTitle tag={titleTag} id={anchor}>
          {title}
        </RibbonRowTitle>
      )
    )
  }

  renderRibbonRowHeader = () => {
    const {
      description,
      descriptionElement,
      title,
      titleColor,
      subtitle,
      showAllTitle,
      showAllTag,
      customLink,
      children,
      removeArrowsHeader,
      showAllAction,
      isLoading,
      showAllGAEvent,
      hideShowAll,
      headerComponent,
    } = this.props
    const ShowAllLabel = showAllTag || Label
    const link = !isEmpty(customLink)
      ? customLink.link
      : paths.search.searchByType(this.getPathByType())

    const DescriptionElement = descriptionElement

    return (
      title && (
        <>
          <Row>
            {this.renderTitle()}
            <Flex>
              {headerComponent && headerComponent}

              {!isLoading && children.length > 1 && (
                <Flex>
                  {!showAllAction && !hideShowAll && (
                    <ShowAll
                      onClick={() =>
                        showAllGAEvent ? this.onReactGAEventShowAll() : ''
                      }
                      exact
                      href={paths.root}
                      to={link}
                    >
                      {isEmpty(customLink) ? (
                        <I18n
                          text="content.ribbon.showAll"
                          options={{
                            title: title.toLowerCase(),
                          }}
                          tag={ShowAllLabel}
                        />
                      ) : (
                        <ShowAllLabel>{customLink.label}</ShowAllLabel>
                      )}
                    </ShowAll>
                  )}
                  {showAllAction && !hideShowAll && (
                    <Flex onClick={showAllAction}>
                      {isEmpty(customLink) ? (
                        <I18n
                          id={`show-${title}`}
                          text="content.ribbon.showAll"
                          options={{
                            title: title.toLowerCase(),
                          }}
                          tag={ShowAllLabel}
                        />
                      ) : (
                        <ShowAllLabel>{customLink.label}</ShowAllLabel>
                      )}
                    </Flex>
                  )}

                  {!removeArrowsHeader && children.length > 1 && (
                    <Flex>
                      <ButtonAngleLeft onClick={this.sideScroll('left')}>
                        {' '}
                        <Icon
                          glyph={ArrowLeft}
                          ml={1}
                          fill="black"
                          width="16px"
                          height="16px"
                          mr={1}
                        />
                      </ButtonAngleLeft>
                      <ButtonAngleRight onClick={this.sideScroll('right')}>
                        {' '}
                        <Icon
                          glyph={ArrowRight}
                          ml={1}
                          fill="black"
                          width="16px"
                          height="16px"
                          mr={1}
                        />
                      </ButtonAngleRight>
                    </Flex>
                  )}
                </Flex>
              )}
            </Flex>
          </Row>
          {subtitle && (
            <Flex>
              <Subtitle color={titleColor}>{subtitle}</Subtitle>
            </Flex>
          )}
          {description && (
            <DescriptionElement>{description}</DescriptionElement>
          )}
        </>
      )
    )
  }

  render() {
    const {
      children,
      centered,
      rowContainerMT,
      rowContainerBG,
      rowContainerHeight,
      removeArrows,
      removePadding,
      removeRibbonHeader,
      noDataContainer,
      rowContainerPB,
    } = this.props

    return (
      <ContainerWrapper removePadding={removePadding}>
        {!removeRibbonHeader && this.renderRibbonRowHeader()}
        {!removeArrows && !!children.length && (
          <StyledFaAngleLeft onClick={this.sideScroll('left')} />
        )}
        {!removeArrows && !!children.length && (
          <StyledFaAngleRight onClick={this.sideScroll('right')} />
        )}
        {children.length ? (
          <Container>
            <RowContainer
              rowContainerHeight={rowContainerHeight}
              rowContainerPB={rowContainerPB}
              mt={rowContainerMT}
              bg={rowContainerBG}
              centered={centered}
              ref={this.addScrollRef}
            >
              {children}
            </RowContainer>
          </Container>
        ) : (
          noDataContainer && (
            <EmptyLessonContainer>
              <Icon
                glyph={noDataContainer.iconEmpty}
                ml={1}
                fill="black_200"
                width="77px"
                height="56px"
                mr={1}
              />
              <Flex>
                <I18n
                  text={noDataContainer.titleEmpty}
                  // options={{
                  //   title,
                  // }}
                  tag={TitleEmpty}
                />
              </Flex>
              <Flex mt={3}>{noDataContainer.descriptionEmpty}</Flex>
            </EmptyLessonContainer>
          )
        )}
      </ContainerWrapper>
    )
  }
}

RibbonRow.defaultProps = {
  anchor: '',
  children: '',
  description: '',
  descriptionElement: null,
  titleColor: '',
  showAllTitle: '',
  subtitle: '',
  customLink: {},
  titleTag: Title,
  centered: false,
  rowContainerMT: 0,
  rowContainerPB: '20px',
  rowContainerBG: '',
  rowContainerHeight: '',
  removeArrows: false,
  removeArrowsHeader: false,
  removePadding: false,
  removeRibbonHeader: false,
  noDataContainer: false,
  showAllAction: false,
  isLoading: false,
  hideShowAll: false,
}

RibbonRow.propTypes = {
  anchor: PropTypes.string,
  centered: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  customLink: PropTypes.object,
  description: PropTypes.string,
  descriptionElement: PropTypes.element,
  titleTag: PropTypes.number,
  showAllTitle: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  titleColor: PropTypes.string,
  rowContainerBG: PropTypes.string,
  rowContainerPB: PropTypes.string,
  removeArrows: PropTypes.bool,
  removeRibbonHeader: PropTypes.bool,
  removeArrowsHeader: PropTypes.bool,
  removePadding: PropTypes.bool,
  isLoading: PropTypes.bool,
  rowContainerMT: PropTypes.number,
  rowContainerHeight: PropTypes.string,
  noDataContainer: PropTypes.bool,
  showAllAction: PropTypes.func,
  hideShowAll: PropTypes.bool,
}

export default RibbonRow
