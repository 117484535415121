import { useCallback, useLayoutEffect, useMemo } from 'react'
import { v4 } from 'uuid'

import { useListener } from './useListener'

export class MessageHandler {
  constructor(id) {
    this.seqNum = 0
    this.connections = {}
    this.eventTarget = new EventTarget()

    if (id !== null) {
      this.id = id
    } else {
      this.id = v4()
    }
  }

  /**
   * @param {Window} targetWindow
   * @returns {(function(): void)}
   */
  register(targetWindow) {
    this.targetWindow = targetWindow
    window.addEventListener('message', this.receiveMessageRaw)

    return () => {
      window.removeEventListener('message', this.receiveMessageRaw)
    }
  }

  /**
   *
   * @param {MessageEvent} message
   */
  receiveMessageRaw = message => {
    if (message.source !== this.targetWindow) {
      return
    }

    this.receiveMessage(message.data)
  }

  sendMessage(payload, targetId = null) {
    const messageToSend = {
      seq: this.seqNum,
      payload,
      id: this.id,
      targetId,
    }

    this.targetWindow.postMessage(messageToSend, '*')

    this.seqNum += 1
  }

  /**
   * @param {{
   *   seq: number;
   *   id: string;
   *   targetId: string | null;
   *   payload: {
   *     type: number;
   *   };
   * }} message
   * @private
   */
  receiveMessage(message) {
    const { id, seq, payload, targetId } = message

    if (targetId !== null && targetId !== this.id) {
      // it was for another target.
      return
    }

    if (!this.connections[id]) {
      this.connections[id] = {
        id,
        lastSeenSeqNum: -1,
      }
    }

    const connection = this.connections[id]
    if (seq <= connection.lastSeenSeqNum) {
      return
    }

    connection.lastSeenSeqNum = seq

    this.handleMessage(payload, message)
  }

  /**
   * @param {{
   *   type: number;
   * }} payload,
   * @param message
   * @private
   */
  handleMessage = (payload, message) => {
    const state = {
      handled: false,
    }

    const next = () => {
      state.handled = true
    }

    this.eventTarget.dispatchEvent(
      new CustomEvent(`message.${payload.type}`, {
        detail: {
          payload,
          message,
          next,
        },
      }),
    )

    if (!state.handled) {
      // eslint-disable-next-line no-console
      console.warn(`Unhandled message type ${payload.type}.`)
    }
  }
}

/**
 * @param {Window} windowTarget
 * @param {any=} id
 *
 * @return {MessageHandler}
 */
export const useMessageHandler = (windowTarget, id = null) => {
  const messageHandler = useMemo(() => {
    return new MessageHandler(id)
  }, [id])

  useLayoutEffect(() => {
    if (!windowTarget) {
      return undefined
    }

    return messageHandler.register(windowTarget)
  }, [messageHandler, windowTarget])

  return messageHandler
}

export const useMessageListener = (messageHandler, messageType, callback) => {
  useListener(
    messageHandler.eventTarget,
    `message.${messageType}`,
    useCallback(
      event => {
        const { payload, next } = event.detail

        try {
          callback(payload)
        } finally {
          next()
        }
      },
      [callback],
    ),
  )
}
