import * as React from 'react'

function SvgPrinter(props) {
  return (
    <svg height="1em" viewBox="0 0 20 20" width="1em" {...props}>
      <path
        d="M18.571 4.286H1.43A1.43 1.43 0 000 5.714v8.572c0 .788.64 1.428 1.429 1.428h1.428v2.857c0 .789.64 1.429 1.429 1.429h11.428a1.43 1.43 0 001.429-1.429v-2.857h1.428A1.43 1.43 0 0020 14.286V5.714a1.43 1.43 0 00-1.429-1.428zM15.714 18.57H4.286v-7.14h11.428v7.142zm1.429-10h-2.857V7.143h2.857V8.57zm0-7.142v1.428H2.857V1.43c0-.79.64-1.43 1.429-1.43h11.428c.789 0 1.429.64 1.429 1.429zM5.714 15.714H10v1.429H5.714v-1.429zm0-2.857h8.572v1.429H5.714v-1.429z"
        fill="#FFF"
      />
    </svg>
  )
}

export default SvgPrinter
