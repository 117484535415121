import { uniqBy } from 'lodash'
import { useEffect, useState } from 'react'

export const useInputItems = items => {
  const [inputItems, setInputItems] = useState([])
  useEffect(() => {
    setInputItems(uniqBy(items, 'id') || [])
  }, [items])

  const filterItems = ({ inputValue }) => {
    setInputItems(
      items.filter(item =>
        item?.name?.toLowerCase().startsWith(inputValue.toLowerCase()),
      ) || [],
    )
  }

  return [inputItems, filterItems]
}
