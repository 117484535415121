import { createSelector } from 'reselect'

const getState = state => state.settings

export const getItemsPerPage = createSelector(
  getState,
  state => state.itemsPerPage,
)

export const getLocaleSetting = createSelector(getState, state => state.locale)
export const getFilterStudentSetting = createSelector(
  getState,
  state => state.filterStudent,
)

export const getLocaleEditingSetting = createSelector(
  getState,
  state => state.localeEditing,
)
