import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React from 'react'
import UploadIcon from 'react-icons/lib/go/cloud-upload'

import { BrowseButton, StyledDropzone, Title } from './styles'

const DropzonePhoto = ({ onDrop, ...rest }) => {
  const handleFileInput = files => {
    onDrop(files)
  }

  return (
    <StyledDropzone onDrop={handleFileInput} {...rest}>
      <UploadIcon />
      <Title mt={4}>
        <I18n text="files.dragDropPhoto" /> <I18n text="files.or" />
      </Title>
      <BrowseButton mt={4}>
        <I18n text="files.browseFiles" />
      </BrowseButton>
    </StyledDropzone>
  )
}

DropzonePhoto.defaultProps = {
  onDrop: () => {},
}

DropzonePhoto.propTypes = {
  onDrop: PropTypes.func,
}

export default DropzonePhoto
