import { createReducer } from '../../helpers'
import {
  CANCEL_UPLOAD,
  REMOVE_UPLOADED_FILE_INFO,
  RESET_FILE_UPLOAD,
  UPLOAD_FILE,
} from '../Actions/files'
import { LOG_OUT } from '../Actions/viewer'

const initialState = {
  uploading: {},
}

const removeUploadingFile = (state, { payload }) =>
  state.merge({
    uploading: state.uploading.without(payload.id),
  })

const resetUploadingFile = state =>
  state.merge({
    uploading: {},
  })

const removeUploadedFileInfo = (state, payload) =>
  state.uploading.without(payload.fileId)

const handleUploadProgress = (state, { payload, percent }) =>
  state.getIn(['uploading', payload.id])
    ? state.setIn(['uploading', payload.id, 'percent'], percent)
    : state

const handlers = {
  [UPLOAD_FILE.REQUEST]: (state, { payload }) =>
    state.merge({
      uploading: {
        ...state.uploading,
        [payload.id]: {
          id: payload.id,
          file: payload.file,
          percent: 0,
          cancelled: false,
        },
      },
    }),

  [UPLOAD_FILE.PROGRESS]: handleUploadProgress,

  [CANCEL_UPLOAD]: removeUploadingFile,
  [RESET_FILE_UPLOAD]: resetUploadingFile,

  [UPLOAD_FILE.FAILURE]: removeUploadingFile,
  // [UPLOAD_FILE.SUCCESS]: removeUploadingFile,
  [REMOVE_UPLOADED_FILE_INFO]: removeUploadedFileInfo,

  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers)
