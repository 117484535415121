import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import {
  seamlessImmutableReconciler,
  seamlessImmutableTransformCreator,
} from 'redux-persist-seamless-immutable'

import addContent from './addContent'
import back from './back'
import drawer from './drawer'
import fullscreen from './fullscreen'
import gyro from './gyro'
import menu from './menu'
import muted from './muted'
import overlay from './overlay'
import passphrase from './passphrase'
import preview from './preview'
import quest from './quest'
import rotation from './rotation'
import scene from './scene'
import storage from './storage'
import storyworld from './storyworld'
import zoom from './zoom'

const transformerConfig = {
  blacklistPerReducer: {
    muted: ['temporaryUnmute', 'mutedDuringTemporaryUnmute'],
    rotation: ['active'],
  },
}

const persistConfig = {
  key: 'student',
  storage,
  whitelist: ['muted', 'rotation'],
  stateReconciler: seamlessImmutableReconciler,
  transforms: [seamlessImmutableTransformCreator(transformerConfig)],
}

export default persistReducer(
  persistConfig,
  combineReducers({
    addContent,
    overlay,
    back,
    drawer,
    fullscreen,
    menu,
    muted,
    passphrase,
    preview,
    quest,
    rotation,
    scene,
    gyro,
    storyworld,
    zoom,
  }),
)
