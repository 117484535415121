import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import UploadIcon from 'react-icons/lib/go/cloud-upload'

import { BrowseButton, StyledDropzone, Title } from './styles'

class DropzoneAsset extends PureComponent {
  handleFileInput = files => {
    const { onDrop } = this.props

    onDrop(files)
  }

  render() {
    const { label, ...rest } = this.props
    return (
      <StyledDropzone onDrop={this.handleFileInput} {...rest}>
        <UploadIcon />
        <Title mt={4}>
          {label} <I18n text="files.or" />
          <BrowseButton mt={4}>
            <I18n text="files.browseFiles" />
          </BrowseButton>
        </Title>
      </StyledDropzone>
    )
  }
}

DropzoneAsset.defaultProps = {
  onDrop: () => {},
  label: '',
}

DropzoneAsset.propTypes = {
  label: PropTypes.string,
  onDrop: PropTypes.func,
}

export default DropzoneAsset
