import { getTranslation } from '@lyfta/components-i18n'
import { ZoomOut as ZoomOutIcon } from '@lyfta/components-student'
import { zoom } from '@lyfta/components-student/src/Store/Actions/ui'
import { ButtonNew } from '@lyfta/components-ui'
import React from 'react'

import { callbackDispatch } from '../../../../utils/callbackDispatch'
import { SecondaryButtonIcon } from '../styles'

export const ZoomOutButton = () => {
  return (
    <ButtonNew
      id="btn-zoom-out-student-app"
      leadingIcon={<SecondaryButtonIcon glyph={ZoomOutIcon} />}
      title={getTranslation('header.zoomOut')}
      type="icon"
      secondary
      onClick={callbackDispatch(zoom, 'outPressed')}
    >
      {getTranslation('header.zoomOut')}
    </ButtonNew>
  )
}
